<template>
    <tbody class="text-sm" role="button" @click="showDetails = !showDetails">
        <tr>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ advertisementType.advertisement.title }}
            </td>
            <td v-if="cappingEnabled" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ displays.toFixed(0) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ grossCpm.toFixed(2) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ netRate.toFixed(2) }}
            </td>
        </tr>
        <FormatAirlineTableRow v-if="showDetails" v-for="airline in airlines" :capping-enabled="cappingEnabled" :lines="lines" :airline="airline" :advertisement-type="advertisementType" />
    </tbody>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';

import FormatAirlineTableRow from './FormatAirlineTableRow.vue';

import type { PropType } from 'vue';
import type { line, advertisementType as adType, route } from '../../pages/offers/Create.vue'
import type { section, advertisement } from '@/stores/airline'

const props = defineProps({
    advertisementType: {
        type: Object as PropType<adType>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    airlines: {
        type: Array<string>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    }
})

const showDetails = ref(false)

const displays = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (route.users == undefined) return advertisementSum;
                if (props.advertisementType.advertisement.id != advertisement.type ||
                    !props.advertisementType.enabled) return advertisementSum;

                let cap = line.advertisementTypes.find(a => a.advertisement.id == advertisement.type && a.enabled)!.capping
                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(cap, line.airline.impressions) *
                        route.users;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const netRate = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (route.users == undefined) return advertisementSum;
                if (props.advertisementType.advertisement.id != advertisement.type ||
                    !props.advertisementType.enabled) return advertisementSum;

                let cap = line.advertisementTypes.find(a => a.advertisement.id == advertisement.type)!.capping
                let discount = (line.discount != undefined) ? line.discount : 0;
                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage * Math.min(cap, line.airline.impressions) * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage * line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const grossCpm = computed(() => (displays.value == 0) ? 0 : netRate.value / (displays.value / 1000))

defineExpose({
    displays,
    netRate,
    grossCpm
})

</script>