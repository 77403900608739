<template>
    <div>
        <CreateOfferProgressBar v-bind:current-step="currentStep" @step="gotoPage" />

        <div class="px-2 py-8">
            <div class="max-w-3xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Advertisement information</h1>
                <h3 class="text-xl text-slate-800 font-bold mb-2">Advertisement options</h3>
                <div class="flex items-center justify-between space-x-6 mb-6">
                    <div>
                        <div class="block text-sm font-medium mb-2">Show the advertisements
                            with a capping limit?</div>
                        <div class="text-xs">When the capping limit is enabled, the
                            advertisements are shown a limited amount of times to each
                            unique user.</div>
                    </div>
                    <div class="flex items-center">
                        <div class="form-switch">
                            <input type="checkbox" v-model="cappingEnabled" id="switch" class="sr-only" />
                            <label class="bg-slate-400" for="switch">
                                <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                <span class="sr-only">Switch label</span>
                            </label>
                        </div>
                    </div>
                </div>
                <h3 class="text-xl text-slate-800 font-bold">Advertisement formats</h3>
                <div v-if="loading">
                    Loading ...
                </div>
                <div v-if="!loading && selectedAdvertisementTypes.length == 0">
                    <p class="text-md text-rose-500">No advertisements types available.</p>
                </div>
                <ul v-if="!loading && selectedAdvertisementTypes.length > 0" class="mb-4">
                    <li v-for="advertisementType in selectedAdvertisementTypes"
                        :class="(selectedAdvertisementTypes.indexOf(advertisementType) == selectedAdvertisementTypes.length - 1) ? '' : 'border-b'">
                        <div class="flex justify-between items-center py-3 border-slate-200">
                            <!-- Left -->
                            <div>
                                <div class="text-slate-800 font-semibold">{{
                                    advertisementType.advertisement.title
                                }}</div>
                                <div class="text-sm">{{
                                    advertisementType.advertisement.description
                                }}
                                </div>
                            </div>
                            <!-- Right -->
                            <div class="flex items-center ml-4">
                                <div class="form-switch">
                                    <input type="checkbox" :id="advertisementType.advertisement.id" class="sr-only"
                                        v-model="advertisementType.enabled" />
                                    <label class="bg-slate-400" :for="advertisementType.advertisement.id">
                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between items-center py-3 border-slate-200">
                            <div v-if="cappingEnabled">
                                <label class="block text-sm font-medium mb-1" for="offer-name">Advertisement capping
                                    <span class="text-rose-500">*</span></label>
                                <input id="offer-name" v-model="advertisementType.capping"
                                    placeholder="The advertisement capping" class="form-input w-full"
                                    :class="(showError && cappingEnabled && advertisementType.enabled && (advertisementType.capping == undefined || advertisementType.capping < 1)) ? 'border-rose-300' : ''"
                                    type="number" />
                                <div v-if="showError && cappingEnabled && advertisementType.enabled && (advertisementType.capping == undefined || advertisementType.capping < 1)"
                                    class="text-xs mt-1 text-rose-500">Advertisement capping is
                                    required!</div>
                            </div>
                        </div>
                    </li>
                </ul>

                <p v-if="showError && selectedAdvertisementTypes.find(a => a.enabled) == undefined"
                    class="text-rose-500 text-sm">You must at least select one
                    advertisement type!</p>

                <div class="flex items-center justify-between">
                    <button class="text-sm underline hover:no-underline" @click="gotoPage(2)">&lt;-
                        Back</button>
                    <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto" @click="gotoPage(4)">Next
                        Step -&gt;</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useAdvertisementStore } from '@/stores/advertisement';
import { storeToRefs } from 'pinia'

import type { advertisementType } from '@/pages/offers/Create.vue';

import CreateOfferProgressBar from './CreateOfferProgressBar.vue';

const props = defineProps({
    currentStep: {
        type: Number,
        required: true,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    }
})

const advertisementStore = useAdvertisementStore()

const { loading } = storeToRefs(advertisementStore)

const selectedAdvertisementTypes = ref([] as Array<advertisementType>)

onMounted(async () => {
    await advertisementStore.fetchAdvertisements()

    selectedAdvertisementTypes.value = advertisementStore.getAdvertisements.map<advertisementType>((a) => {
        let adType = props.advertisementTypes.find(t => t.advertisement.id == a.id)
        if (adType == undefined) {
            return {
                advertisement: a,
                capping: undefined,
                enabled: true,
            };
        } else {
            return {
                advertisement: a,
                capping: adType.capping,
                enabled: adType.enabled,
            };
        }
    }).sort((a, b) => a.advertisement.title.localeCompare(b.advertisement.title))

    emit('update:advertisementTypes', selectedAdvertisementTypes.value)
})

const showError = ref(false)
const cappingEnabled = ref(false)

watch(selectedAdvertisementTypes, (newValue) => {
    cappingEnabled.value = selectedAdvertisementTypes.value.find(t => t.capping != undefined) != undefined
})

watch(cappingEnabled, (newValue: boolean) => {
    if (!newValue) {
        selectedAdvertisementTypes.value = selectedAdvertisementTypes.value.map(t => {
            t.capping = undefined
            return t
        })
    }
})

const emit = defineEmits<{
    (e: 'step', id: number): void
    (e: 'update:advertisementTypes', advertisementTypes: Array<advertisementType>): void
}>()

const gotoPage = function (step: number) {
    emit('update:advertisementTypes', selectedAdvertisementTypes.value)

    if (step > 3) {
        if (selectedAdvertisementTypes.value.filter(t => t.enabled).length < 1 || selectedAdvertisementTypes.value.filter(t => t.enabled && cappingEnabled.value && (t.capping == undefined || t.capping < 1)).length > 0) {
            showError.value = true
            return;
        }

        showError.value = false

        emit('step', step)
    } else {
        emit('step', step)
    }
}

</script>