<template>
    <div>
        <CreateOfferProgressBar v-if="currentStep != undefined" v-bind:current-step="currentStep" @step="gotoPage" />

        <div class="px-2 py-8">
            <div v-if="!loaded" class="max-w-4xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Offer summary</h1>
                <p>Loading ...</p>
            </div>
            <div v-if="loaded" class="max-w-4xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Offer summary</h1>
                <div class="space-y-4 mb-8">
                    <div class="grid xl:grid-cols-3 gap-1">
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Name</h3>
                            <div>{{ offerName }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Advertiser</h3>
                            <div>{{ advertiserName }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Campaign Period</h3>
                            <div>{{ moment(campaignStartDate).format('MMM D, YYYY') }} - {{
                                moment(campaignEndDate).format('MMM D, YYYY')
                            }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Total</h3>
                            <div>&euro; {{ costs.toFixed(2) }}</div>
                        </div>
                        <div class="text-l" v-if="campaignBudget != undefined">
                            <h3 class="font-medium text-slate-800">Budget</h3>
                            <div>&euro; {{ campaignBudget.toFixed(2) }}</div>
                        </div>
                        <div class="text-l" v-if="campaignBudget != undefined && campaignBudget -
                            costs != 0">
                            <h3 class="font-medium text-slate-800">Budget Difference</h3>
                            <div><span style="color: green" v-if="(campaignBudget - costs) > 0">&euro; {{
                                (campaignBudget
                                    -
                                    costs).toFixed(2) }}</span><span style="color: red"
                                    v-if="(campaignBudget - costs) < 0">&euro; {{ (campaignBudget -
                                        costs).toFixed(2) }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!loaded" class="max-w-4xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Breakdown information</h1>
                <p>Loading ...</p>
            </div>
            <div v-if="loaded" class="max-w-4xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Breakdown information</h1>
                <ul class="flex flex-wrap -m-1">
                    <li class="m-1">
                        <button @click="section = 'format'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'format') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Format</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'airline'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'airline') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Airline</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'media'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'media') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Media</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'route'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'route') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Route</button>
                    </li>
                </ul>
                <div v-if="section == 'airline'">
                    <div class="space-y-4 mb-8">
                        <AirlineTable :capping-enabled="cappingEnabled" v-bind:lines="lines" :types="advertisementTypes"
                            :airlines="airlines" @updated:lines="(l) => emit('update:lines', l)" />
                    </div>
                </div>
                <div v-if="section == 'format'">
                    <div class="space-y-4 mb-8">
                        <FormatTable :airlines="airlines" :capping-enabled="cappingEnabled" :lines="lines"
                            :types="advertisementTypes.filter(t => t.enabled)" />
                    </div>
                </div>
                <div v-if="section == 'media'">
                    <div class="space-y-4 mb-8">
                        <MediaTable ref="mediaTable" :airlines="airlines" :types="advertisementTypes"
                            :capping-enabled="cappingEnabled" :lines="lines" :media-types="mediaTypes" />
                    </div>
                </div>
                <div v-if="section == 'route'">
                    <div class="space-y-4 mb-8">
                        <RouteTable :airlines="airlines" :types="advertisementTypes" :capping-enabled="cappingEnabled"
                            v-bind:lines="lines" :routes="routes" @updated:lines="(l) => emit('update:lines', l)" />
                    </div>
                </div>
                <div v-if="discountEnabled" class="max-w-3xl mx-auto">
                    <h1 class="text-3xl text-slate-800 font-bold mb-6">Discount</h1>
                    <div class="space-y-4 mb-8">
                        <DiscountTable :lines="lines.filter(l => l.discount != undefined)" />
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button class="text-sm underline hover:no-underline" @click="gotoPage(3)">&lt;-
                        Back</button>
                    <button @click="gotoPage(5)" class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">{{ edit ?
                        "Update" : "Create" }}-&gt;</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import moment from 'moment'
import { ref, computed, onMounted } from 'vue';
import type { PropType } from 'vue';

import type { advertisementType, line, route, targetRoute } from '@/pages/offers/Create.vue';

import CreateOfferProgressBar from './CreateOfferProgressBar.vue';
import FormatTable from './FormatTable.vue'
import MediaTable from './MediaTable.vue'
import AirlineTable from './AirlineTable.vue'
import RouteTable from './RouteTable.vue'
import DiscountTable from './DiscountTable.vue'

import { storeToRefs } from 'pinia';

import type { section, advertisement as sectionAdvertisement } from '@/stores/airline';
import type { route as storeRouteRoute } from '@/stores/route';
import { useAirlineStore } from '@/stores/airline';
import { useRouteStore } from '@/stores/route';


const props = defineProps({
    currentStep: {
        type: Number,
        required: false,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    offerName: {
        type: String,
        required: true,
    },
    advertiserName: {
        type: String,
        required: true,
    },
    campaignBudget: {
        type: undefined as unknown as PropType<number | undefined>,
        required: true,
    },
    campaignStartDate: {
        type: Date,
        required: true,
    },
    campaignEndDate: {
        type: Date,
        required: true,
    },
    routes: {
        type: Array<storeRouteRoute>,
        required: true,
    },
    targetRoutes: {
        type: Array<targetRoute>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    edit: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const showError = ref(false)
const discountEnabled = ref(false)
const section = ref('format')

const airlineStore = useAirlineStore();
var routeStore = useRouteStore()

const { targetCapacity } = storeToRefs(useRouteStore())


const emit = defineEmits<{
    (e: 'update:lines', lines: Array<line>): void
    (e: 'step', id: number): void
}>()

const gotoPage = function (step: number) {
    if (step > 4) {
        emit('step', step)
    } else {
        emit('step', step)
    }
}

const loaded = ref(false)

const targetedRoutes = ref([] as Array<storeRouteRoute>)

onMounted(async () => {
    if (props.lines.length > 0) {
        props.lines.splice(0, props.lines.length)
        emit('update:lines', [])
    }

    await airlineStore.fetchAirlines();

    let targets = [] as Array<string>;

    for (const r of props.targetRoutes) {
        let dep = ""
        if (r.departure != undefined) {
            dep = r.departure
        }

        let arr = ""
        if (r.arrival != undefined) {
            arr = r.arrival
        }

        targets.push(dep + "-" + arr)
    }

    await routeStore.fetchRouteTargetCapacity(props.campaignStartDate, props.campaignEndDate, targets, [])

    for (const cap of targetCapacity.value) {
        if (targetedRoutes.value.find(r => r.id == cap.routeID) == undefined) {
            targetedRoutes.value.push(cap.route)
        }


        let a = airlineStore.getAirlineById(cap.airline);
        if (a == undefined) {
            return;
        }

        let line = { routes: [] as Array<route> } as line;

        line.routes.push({
            id: cap.routeID,
            arrival: cap.route.arrival,
            departure: cap.route.departure,
            users: cap.users,
            maxUsers: cap.users,
        })


        line.airline = a
        line.advertisementTypes = JSON.parse(JSON.stringify(props.advertisementTypes))
        line.discount = 0;

        props.lines.push(line);
    }

    loaded.value = true
})

const airlines = computed(() => {
    var names = [] as Array<string>;
    props.lines.forEach(l => {
        if (names.find(n => n == l.airline.name) == undefined) {
            names.push(l.airline.name)
        }
    })
    return names;
})

const mediaTypes = computed(() => {
    var mediaTypes = [] as Array<string>;
    props.lines.forEach(l => {
        if (mediaTypes.find(n => n == l.airline.mediaType) == undefined) {
            mediaTypes.push(l.airline.mediaType)
        }
    })
    return mediaTypes;
})

const routes = computed(() => {
    var routes = [] as Array<route>;
    targetedRoutes.value.forEach(l => {
        routes.push({
            id: l.id,
            arrival: l.arrival,
            departure: l.departure,
            users: undefined,
            maxUsers: undefined,
        })
    })
    return routes;
})

const cappingEnabled = computed(() => props.lines.find(l => l.advertisementTypes.find(a => a.enabled && a.capping != undefined) != undefined) != undefined)

const costs = computed(() => {
    return props.lines.reduce((totalSum: number, line: line) => {
        return totalSum + line.routes.reduce((totalSum: number, route: route) => {
            return totalSum + line.airline.sections.reduce((sectionSum: number, section: section) => {
                return sectionSum + section.advertisements.reduce((advertisementSum: number, advertisement: sectionAdvertisement) => {
                    if (route.users == undefined) return advertisementSum;
                    let adType = line.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)
                    if (adType == undefined || !adType.enabled) return advertisementSum;

                    let discount = (line.discount != undefined) ? line.discount : 0;
                    if (adType.capping != undefined) {
                        return advertisementSum + section.percentage * advertisement.percentage
                            *
                            Math.min(adType.capping, line.airline.impressions) *
                            route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                    } else {
                        return advertisementSum + section.percentage * advertisement.percentage
                            *
                            line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                    }
                }, 0)
            }, 0)
        }, 0)
    }, 0)
});

</script>