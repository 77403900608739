<template>
    <div>
        <CreateOfferProgressBar v-bind:current-step="currentStep" @step="gotoPage" />
        <div class="px-4 py-8">
            <div class="max-w-3xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Offer information</h1>
                <div class="space-y-4 mb-8">
                    <div>
                        <label class="block text-sm font-medium mb-1" for="offer-name">Name
                            <span class="text-rose-500">*</span></label>
                        <input :value="offerName"
                            @input="(e) => emit('update:offerName', (<HTMLInputElement>e.target).value)"
                            placeholder="The offer name" class="form-input w-full"
                            :class="(showError && offerName == '') ? 'border-rose-300' : ''" type="text" />
                        <div v-if="showError && offerName == ''" class="text-xs mt-1 text-rose-500">
                            Name is required!</div>
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="advertiser-name">Advertiser <span
                                class="text-rose-500">*</span></label>
                        <input id="advertiser-name" placeholder="The advertiser name" :value="advertiserName"
                            class="form-input w-full"
                            @input="(e) => emit('update:advertiserName', (<HTMLInputElement>e.target).value)"
                            :class="(showError && advertiserName == '') ? 'border-rose-300' : ''" type="text" />
                        <div v-if="showError && advertiserName == ''" class="text-xs mt-1 text-rose-500">Advertiser is
                            required!</div>
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="prefix">Campaign
                            Budget</label>
                        <div class="relative">
                            <input id="prefix" :value="campaignBudget" min="0" placeholder="Campaign budget"
                                @input="(e) => emit('update:campaignBudget', Number.parseFloat((<HTMLInputElement>e.target).value))"
                                class="form-input w-full pl-12" type="number" />
                            <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                                <span class="text-sm text-slate-400 font-medium px-3">EUR</span>
                            </div>
                        </div>
                        <div class="text-xs mt-1">The campaign budget can be used in order to tailor
                            the advertisement campaign to the given budget.</div>
                        <div>
                            <label class="block text-sm font-medium mb-1" for="capping">Campaign
                                Period
                                <span class="text-rose-500">*</span></label>
                            <Datepicker v-model="campaignPeriod"
                                :class="(showError && campaignPeriod.length != 2) ? 'border-rose-300' : ''" />
                            <div v-if="showError && (campaignPeriod.length != 2)" class="text-xs mt-1 text-rose-500">
                                Must select a valid campaign period!
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <router-link :to="{ name: 'Offers' }" class="text-sm underline hover:no-underline">&lt;-
                            Back</router-link>
                        <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto"
                            @click="gotoPage(2)">Next Step -&gt;</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { PropType } from 'vue';

import Datepicker from '@/components/Datepicker.vue';
import CreateOfferProgressBar from './CreateOfferProgressBar.vue';

const props = defineProps({
    currentStep: {
        type: Number,
        required: true,
    },
    offerName: {
        type: String,
        required: true,
    },
    advertiserName: {
        type: String,
        required: true,
    },
    campaignBudget: {
        type: undefined as unknown as PropType<number | undefined>,
        required: true,
    },
    campaignStartDate: {
        type: Date,
        required: true,
    },
    campaignEndDate: {
        type: Date,
        required: true,
    }
})

const emit = defineEmits<{
    (e: 'step', id: number): void
    (e: 'update:offerName', offerName: string): void
    (e: 'update:advertiserName', advertiserName: string): void
    (e: 'update:campaignBudget', campaignBudget: number): void
    (e: 'update:campaignStartDate', campaignStartDate: Date): void
    (e: 'update:campaignEndDate', campaignEndDate: Date): void
}>()

const showError = ref(false)
const campaignPeriod = ref([props.campaignStartDate, props.campaignEndDate])

watch(campaignPeriod, (newValue) => {
    emit('update:campaignStartDate', new Date(newValue[0]))
    emit('update:campaignEndDate', new Date(newValue[1]))
})

const gotoPage = function (step: number) {
    if (step > 1) {
        if (props.offerName == '' || props.advertiserName == '') {
            showError.value = true;
            return;
        }

        showError.value = false;

        emit('step', step)
    } else {
        emit('step', step)
    }
}

</script>