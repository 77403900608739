<template>
    <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-4">
        <header class="px-5 py-4">
            <h2 class="font-semibold text-slate-800">Airline Breakdown</h2>
        </header>
        <div>
            <div class="overflow-x-auto">
                <table class="table-auto w-full divide-y divide-slate-200">
                    <!-- Table header -->
                    <thead class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Airline</div>
                            </th>
                            <th v-if="cappingEnabled" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Capping</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Proposed Displays</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Gross CPM</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Discount &percnt;</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Net Rate</div>
                            </th>
                        </tr>
                    </thead>
                    <BreakdownAirlineTableRow :advertisementTypes="advertisementTypes"  ref="items" v-for="airline in airlines" :capping-enabled="cappingEnabled" v-bind:lines="lines" :airline="airline" />
                    <tfoot class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Total</td>
                            <td v-if="cappingEnabled" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{{ displays.toFixed(0) }}</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">&euro; {{
                                grossCpm.toFixed(2)
                            }}</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">&euro; {{ netRate.toFixed(2) }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { line, advertisementType } from '../../pages/offers/Create.vue'
import BreakdownAirlineTableRow from './BreakdownAirlineTableRow.vue'
import { ref, computed } from 'vue';

const props = defineProps({
    lines: {
        type: Array<line>,
        required: true,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    },
})

const items = ref([] as Array<InstanceType<typeof BreakdownAirlineTableRow>>)

const airlines = computed(() => {
    return props.lines.reduce((airlines, line) => {
        if (airlines.find(a => a == line.airline.name) == undefined)
        {
            airlines.push(line.airline.name)
        }

        return airlines
    }, [] as Array<string>)
})

const displays = computed(() => {
    return items.value.reduce((sum, row) => sum + row.displays, 0)
})

const netRate = computed(() => {
    return items.value.reduce((sum, row) => sum + row.netRate, 0)
})

const grossCpm = computed(() => {
    return (displays.value == 0) ? 0 : netRate.value / (displays.value / 1000)
})

</script>