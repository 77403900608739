import { defineStore } from 'pinia'
import axios from 'axios'

export type offer = {
  id: string | undefined;
  name: string;
  budget: number | undefined;
  advertiser: string;
  createdAt: string | undefined;
  startAt: string;
  endAt: string;
  lines: Array<offer_line>;
}

export type offer_line = {
  airline: string;
  discount: number | undefined;
  advertisements: Array<offer_advertisement>;
  routes: Array<offer_route>;
}

export type offer_advertisement = {
  id: string;
  capping: number | undefined;
}

export type offer_route = {
  id: string;
  users: number;
}

export const useOfferStore = defineStore('offer', {
  state: () => ({
    offers: [] as offer[],
    offer: undefined as offer | undefined,
  }),
  actions: {
    async fetchOffers(ignoreLines: boolean) {
      try {
        const data = await axios.get(`/api/offers/?ignoreLines=${ignoreLines}`)

        this.offers = data.data;

        } catch (error) {
          alert(error)
          console.log(error)
      }
    },
    async fetchOffer(id: string) {
      try {
        const data = await axios.get(`/api/offers/${id}`)

        this.offer = data.data;

        } catch (error) {
          alert(error)
          console.log(error)
      }
    },
    async deleteOffer(id: string) {
      try {
          await fetch(`/api/offers/${id}`, { method: 'DELETE' })
        } catch (error) {
          alert(error)
          console.log(error)
      }
    },
    async createOffer(offer: offer) {
      try {
          await axios.post('/api/offers/', offer)
        } catch (error) {
          alert(error)
          console.log(error)
      }
    },
    async updateOffer(offer: offer) {
      try {
          await axios.put(`/api/offers/${offer.id}`, offer)
        } catch (error) {
          alert(error)
          console.log(error)
      }
    }
  },
  getters: {
    getOffers(state) {
      return state.offers;
    },
    getOfferById(state) {
      return (id:string) => state.offers.find(o => o.id == id)
    }
  }
})
