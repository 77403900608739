<template>
  <div class="flex h-screen overflow-hidden">
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    <div class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <Header :sidebarOpen="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />
      <main>
        <div class="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto">
          <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <div class="mb-2 sm:mb-0">
              <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">Offer summary</h1>
            </div>
            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              <button @click.stop="pdfModalOpen = true" v-if="offer != undefined"
                class="btn bg-indigo-500 hover:bg-indigo-600 text-white">Generate PDF</button>
            </div>
          </div>
          <div class="relative flex">
            <div class="w-full">
              <div class="flex flex-col after:flex-1">
                <div v-if="offer == undefined" class="flex-1">
                  <p>Loading ...</p>
                </div>
                <div v-if="offer != undefined" class="flex-1">
                  <Breakdown :url="url" :readonly="true" v-model:advertiser-name="offer.advertiser"
                    v-model:offer-name="offer.name" v-model:campaign-budget="offer.budget"
                    v-model:campaign-start-date="offer.period.start" v-model:campaign-end-date="offer.period.end"
                    :advertisement-types="offer.advertisementTypes" :lines="offer.lines" :routes="offer.routes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <ModalBlank4xl id="danger-modal" :modalOpen="pdfModalOpen" @close-modal="pdfModalOpen = false">
      <div class="p-5" v-if="offer != undefined">
        <form method="POST" target="_blank" action="https://imd-pdf-generator-api.proudtree-cde06854.westeurope.azurecontainerapps.io">
          <input type="hidden" name="line-price" :value="costs" />
          <input type="hidden" name="line-description" :value="offer.name" />
          <input type="hidden" name="offer-url" :value="url" />
          <input type="hidden" name="airlines" :value="airlines.join(',')" />

          <div class="text-sm mb-10">
            <h1 class="text-2xl text-slate-800 font-bold mb-6">Generic information</h1>
            <div class="mb-8 flex flex-col">
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Language
                    <span class="text-rose-500">*</span></label>
                  <select class="form-input w-full" name="language">
                    <option value="nl">NL</option>
                  </select>
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">VAT
                    <span class="text-rose-500">*</span></label>
                  <input name="line-vat" placeholder="VAT %" type="number" class="form-input w-full" required />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Number<span
                      class="text-rose-500">*</span></label>
                  <input name="offer-number" placeholder="Offer number" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Reference Number</label>
                  <input name="offer-reference-number" placeholder="Reference number" class="form-input w-full" />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Created At
                    <span class="text-rose-500">*</span></label>
                  <input name="offer-date" placeholder="dd-mm-yyyy" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Valid Until
                    <span class="text-rose-500">*</span></label>
                  <input name="offer-valid-until-date" placeholder="dd-mm-yyyy" class="form-input w-full" required />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Description
                    <span class="text-rose-500">*</span></label>
                  <input name="offer-description" placeholder="Description" class="form-input w-full" required />
                </div>
              </div>
            </div>

            <h1 class="text-2xl text-slate-800 font-bold mb-6">Seller information</h1>
            <div class="mb-8 flex flex-col">
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Company name
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-company-name" placeholder="Company name" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">COC
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-coc" placeholder="Chamber of Commerce No." class="form-input w-full" required />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Address Line 1
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-address-line-1" placeholder="Address line 1" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Address Line 2
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-address-line-2" placeholder="Address line 2" class="form-input w-full" required />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Phonenumber
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-phone" placeholder="Phonenumber" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">E-mail
                    <span class="text-rose-500">*</span></label>
                  <input name="seller-email" placeholder="Emailaddress" class="form-input w-full" required />
                </div>
              </div>
            </div>

            <h1 class="text-2xl text-slate-800 font-bold mb-6">Advertiser information</h1>
            <div class="mb-8 flex flex-col">
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Company name
                    <span class="text-rose-500">*</span></label>
                  <input name="buyer-company-name" placeholder="Company name" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Contact Name
                    <span class="text-rose-500">*</span></label>
                  <input name="buyer-contact-name" placeholder="Contact name" class="form-input w-full" required />
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Address Line 1
                    <span class="text-rose-500">*</span></label>
                  <input name="buyer-address-line-1" placeholder="Address line 1" class="form-input w-full" required />
                </div>
                <div class="w-full p-1">
                  <label class="block text-sm font-medium mb-1" for="offer-name">Address Line 2
                    <span class="text-rose-500">*</span></label>
                  <input name="buyer-address-line-2" placeholder="Address line 2" class="form-input w-full" required />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-end space-x-2">
              <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white" type="submit">Generate PDF</button>
            </div>
          </div>
        </form>
      </div>
    </ModalBlank4xl>
  </div>
</template>

<script setup lang="ts">
import Sidebar from '../../partials/Sidebar.vue'
import Header from '../../partials/Header.vue'
import ModalBlank4xl from '../../components/ModalBlank4xl.vue'
import type { section, advertisement as sectionAdvertisement } from '@/stores/airline';
import type { advertisementType, line, offer as createOffer, route } from "./Create.vue"
import Breakdown from '@/partials/offers/Breakdown.vue'

import { ref, onMounted, computed, pushScopeId } from 'vue'
import { useOfferStore } from '@/stores/offer'
import { useAirlineStore, type airline } from '@/stores/airline'
import { useAdvertisementStore } from '@/stores/advertisement'
import { useRouteStore } from '@/stores/route'
import { __isOptionsFunction } from '@tailwindcss/forms'

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const offerStore = useOfferStore()
const airlineStore = useAirlineStore()
const advertisementStore = useAdvertisementStore()
const routeStore = useRouteStore()

const sidebarOpen = ref(false)
const pdfModalOpen = ref(false)

const loaded = ref(false)
const offer = ref(undefined as createOffer | undefined);

const url = ref(`https://portal.imd.aero/offers/view/${props.id}`)

const costs = computed(() => {
  return offer.value!.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
      return totalSum + line.airline.sections.reduce((sectionSum: number, section: section) => {
        return sectionSum + section.advertisements.reduce((advertisementSum: number, advertisement: sectionAdvertisement) => {
          if (route.users == undefined) return advertisementSum;
          let adType = line.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)
          if (adType == undefined || !adType.enabled) return advertisementSum;

          let discount = (line.discount != undefined) ? line.discount : 0;
          if (adType.capping != undefined) {
            return advertisementSum + section.percentage * advertisement.percentage
              *
              Math.min(adType.capping, line.airline.impressions) *
              route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
          } else {
            return advertisementSum + section.percentage * advertisement.percentage
              *
              line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
          }
        }, 0)
      }, 0)
    }, 0)
  }, 0)
});

const airlines = computed(() => {
  return offer.value!.lines.reduce((totalSum: Array<string>, line: line) => {

    if (!totalSum.includes(line.airline.id)) {
      totalSum.push(line.airline.id)
    }

    return totalSum
  }, [])
});

onMounted(async () => {
  await offerStore.fetchOffer(props.id);
  await advertisementStore.fetchAdvertisements();

  let o = offerStore.offer

  await airlineStore.fetchAirlines();

  let adTypeIDs = [] as Array<string>;

  o!.lines.forEach(line => {
    line.advertisements.forEach(ad => {
      if (adTypeIDs.find(a => a == ad.id) == undefined) {
        adTypeIDs.push(ad.id)
      }
    })
  });

  let adTypes = adTypeIDs.map(id => {
    return {
      advertisement: advertisementStore.getAdvertisementById(id),
      capping: undefined,
      enabled: true,
    } as advertisementType
  })

  let routeIDs = [] as Array<string>;

  o!.lines.forEach(line => {
    line.routes.forEach(r => {
      if (routeIDs.find(a => a == r.id) == undefined) {
        routeIDs.push(r.id)
      }
    })
  });

  await routeStore.fetchRoutes()

  let routes2 = await Promise.all(routeIDs.map(async id => {
    let route = routeStore.getRouteById(id)

    return {
      id: id,
      arrival: (route?.arrival == undefined || route?.arrival == "") ? undefined : route?.arrival,
      departure: (route?.departure == undefined || route?.departure == "") ? undefined : route?.departure,
    } as route;
  }))

  let lines = await Promise.all(o!.lines.map(async (l) => {
    let adTypes = l.advertisements.map((a) => {
      let ad = advertisementStore.getAdvertisementById(a.id)

      return {
        advertisement: ad,
        capping: a.capping,
        enabled: true,
      } as advertisementType;
    })

    let routes = await Promise.all(l.routes.map(async (r) => {
      let ro = routeStore.getRouteById(r.id);

      if (ro == undefined) {
        return undefined
      }

      return {
        id: r.id,
        users: r.users,
        arrival: ro.arrival,
        departure: ro.departure,
      } as route;
    }))

    return {
      airline: airlineStore.getAirlineById(l.airline),
      discount: l.discount,
      routes: routes,
      advertisementTypes: adTypes.filter(a => a != undefined),
    } as line;
  }))

  offer.value = {
    name: o!.name,
    advertiser: o!.advertiser,
    budget: o?.budget,
    targetRoutes: [],
    period: {
      start: new Date(o!.startAt),
      end: new Date(o!.endAt),
    },
    routes: routes2,
    advertisementTypes: adTypes,
    lines: lines,
  } as createOffer;

  loaded.value = true;
})

</script>