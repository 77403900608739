<template>
    <tbody class="text-sm" role="button" @click="showDetails = !showDetails">
        <tr>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ mediaType }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ displays.toFixed(0) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ grossCpm.toFixed(2) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ netRate.toFixed(2) }}
            </td>
        </tr>
        <MediaAirlineTableRow v-if="showDetails" v-for="airline in airlines" :capping-enabled="cappingEnabled" :lines="lines" :advertisement-types="advertisementTypes" :airline="airline" :media-type="mediaType" />
    </tbody>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import type { line, advertisementType, route } from '../../pages/offers/Create.vue'
import type { section, advertisement } from '@/stores/airline'
import MediaAirlineTableRow from './MediaAirlineTableRow.vue';

const props = defineProps({
    mediaType: {
        type: String,
        required: true,
    },
    airlines: {
        type: Array<string>,
        required: true,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    }
})

const showDetails = ref(false)

const displays = ref(props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (line.airline.mediaType != props.mediaType) return advertisementSum;

                let adType = props.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)

                if (adType == undefined) return advertisementSum;

                if (route.users == undefined) return advertisementSum;
                if (adType.advertisement.id != advertisement.type ||
                    !adType.enabled) return advertisementSum;

                let cap = line.advertisementTypes.find(a => a.advertisement.id == advertisement.type)!.capping;

                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(cap, line.airline.impressions) * route.users;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const netRate = ref(props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (line.airline.mediaType != props.mediaType) return advertisementSum;

                let adType = props.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)

                if (adType == undefined) return advertisementSum;

                if (route.users == undefined) return advertisementSum;
                if (adType.advertisement.id != advertisement.type ||
                    !adType.enabled) return advertisementSum;

                let cap = line.advertisementTypes.find(a => a.advertisement.id == advertisement.type)!.capping;
                let discount = (line.discount != undefined) ? line.discount : 0;
                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage * Math.min(cap, line.airline.impressions) * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage * line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const grossCpm = ref((displays.value == 0) ? 0 : netRate.value / (displays.value / 1000))

defineExpose({
    displays,
    netRate,
    grossCpm
})

</script>