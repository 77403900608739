import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
  state: () => ({
      authenticated: false,
      username: "",
  }),
  getters: {},
  actions: {
    async fetchUser() {
      try {
        const data = await axios.get('/.auth/me')

        if (data.data.clientPrincipal != null)
        {
          this.username = data.data.clientPrincipal.userDetails;
          this.authenticated = true;
        }

        } catch (error) {
          alert(error)
          console.log(error)
      }
    }
  },
})
