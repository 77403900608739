<template>
  <tbody class="text-sm">
    <tr>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{{ offer.name }}</div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div class="font-medium text-slate-800">{{ offer.advertiser }}</div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div class="font-medium text-slate-800">{{ moment(offer.startAt).format('MMM D, YYYY') }} - {{
          moment(offer.endAt).format('MMM D, YYYY')
        }}</div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div class="font-medium text-slate-800">{{ moment(offer.createdAt).format('MMM D, YYYY') }}</div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div class="space-x-1">
          <!-- <div role="button" @click="openOffer()" style="display: inline-block; width: 32px; height: 32px; align-items: flex-start;"
            class="text-slate-400 hover:text-slate-500 rounded-full">
            <span class="sr-only">Open</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 fill-current mt-1" viewBox="0 0 32 32"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
              <line x1="9" y1="17" x2="9" y2="12" />
              <line x1="12" y1="17" x2="12" y2="16" />
              <line x1="15" y1="17" x2="15" y2="14" />
            </svg>
          </div> -->
          <router-link style="display: inline-block; width: 32px; height: 32px; align-items: flex-start;"
            class="text-slate-400 hover:text-slate-500 rounded-full"
            :to="{ name: 'OfferDetails', params: { id: offer.id } }">
            <span class="sr-only">Open</span>
            <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path
                d="M16 20c.3 0 .5-.1.7-.3l5.7-5.7-1.4-1.4-4 4V8h-2v8.6l-4-4L9.6 14l5.7 5.7c.2.2.4.3.7.3zM9 22h14v2H9z">
              </path>
            </svg>
          </router-link>
          <button class="text-rose-500 hover:text-rose-600 rounded-full" @click.stop="deleteItem()">
            <span class="sr-only">Delete</span>
            <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
              <path
                d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
            </svg>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
import type { PropType } from 'vue'
import type { offer } from '@/stores/offer'
import moment from 'moment'

const props = defineProps({
  offer: {
    type: Object as PropType<offer>,
    required: true,
  }
})

const openOffer = () => {
  window.open('/api/offers/' + props.offer.id + '/document', '_blank');
}

const emit = defineEmits(['delete'])

const deleteItem = () => {
  emit('delete', props.offer)
};

</script>