<template>
  <div class="flex h-screen overflow-hidden">
    <!-- <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" /> -->
    <div class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <!-- <Header :sidebarOpen="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" /> -->
      <main>
        <div class="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto">
          <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <div class="mb-2 sm:mb-0">
              <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">Offer summary</h1>
            </div>
            <!-- <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              <button @click.stop="deleteModalOpen = true"
                class="btn border-slate-200 hover:border-slate-300 text-rose-500">Delete</button>
            </div> -->
          </div>
          <div class="relative flex">
            <div class="w-full">
              <div class="flex flex-col after:flex-1">
                <div v-if="offer == undefined" class="flex-1">
                  <p>Loading ...</p>
                </div>
                <div v-if="offer != undefined" class="flex-1">
                  <Breakdown :readonly="true" v-model:advertiser-name="offer.advertiser" v-model:offer-name="offer.name"
                    v-model:campaign-budget="offer.budget" v-model:campaign-start-date="offer.period.start"
                    v-model:campaign-end-date="offer.period.end" :advertisement-types="offer.advertisementTypes"
                    :lines="offer.lines" :routes="offer.routes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <ModalBlank id="danger-modal" :modalOpen="deleteModalOpen" @close-modal="deleteModalOpen = false">
      <div class="p-5 flex space-x-4" v-if="offer != undefined">
        <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
          <svg class="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
          </svg>
        </div>
        <div>
          <div class="mb-2">
            <div class="text-lg font-semibold text-slate-800">Delete offer {{ offer.name }}?</div>
          </div>
          <div class="text-sm mb-10">
            <div class="space-y-2">
              <p>Do you want to delete the offer?</p>
            </div>
          </div>
          <div class="flex flex-wrap justify-end space-x-2">
            <button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="deleteModalOpen = false">Cancel</button>
            <button class="btn-sm bg-rose-500 hover:bg-rose-600 text-white" @click.stop="deleteOffer()">Yes, Delete
              it</button>
          </div>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>

<script setup lang="ts">
import Sidebar from '../../partials/Sidebar.vue'
import Header from '../../partials/Header.vue'
import ModalBlank from '../../components/ModalBlank.vue'
import type { advertisementType, line, offer as createOffer, route } from "./Create.vue"
import Breakdown from '@/partials/offers/Breakdown.vue'

import { ref, onMounted } from 'vue'
import { useOfferStore } from '@/stores/offer'
import { useAirlineStore, type airline } from '@/stores/airline'
import { useAdvertisementStore } from '@/stores/advertisement'
import { useRouteStore } from '@/stores/route'

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const offerStore = useOfferStore()
const airlineStore = useAirlineStore()
const advertisementStore = useAdvertisementStore()
const routeStore = useRouteStore()

const sidebarOpen = ref(false)
const deleteModalOpen = ref(false)

const loaded = ref(false)
const offer = ref(undefined as createOffer | undefined);

onMounted(async () => {
  await offerStore.fetchOffer(props.id);
  await advertisementStore.fetchAdvertisements();

  let o = offerStore.offer

  await airlineStore.fetchAirlines();

  let adTypeIDs = [] as Array<string>;

  o!.lines.forEach(line => {
    line.advertisements.forEach(ad => {
      if (adTypeIDs.find(a => a == ad.id) == undefined) {
        adTypeIDs.push(ad.id)
      }
    })
  });

  let adTypes = adTypeIDs.map(id => {
    return {
      advertisement: advertisementStore.getAdvertisementById(id),
      capping: undefined,
      enabled: true,
    } as advertisementType
  })

  let routeIDs = [] as Array<string>;

o!.lines.forEach(line => {
  line.routes.forEach(r => {
    if (routeIDs.find(a => a == r.id) == undefined) {
      routeIDs.push(r.id)
    }
  })
});

await routeStore.fetchRoutes()

let routes2 = await Promise.all(routeIDs.map(async id => {
  let route = routeStore.getRouteById(id)

  return {
    id: id,
    arrival: (route?.arrival == undefined || route?.arrival == "") ? undefined : route?.arrival,
    departure: (route?.departure == undefined || route?.departure == "") ? undefined : route?.departure,
  } as route;
}))

  let lines = await Promise.all(o!.lines.map(async (l) => {
    let adTypes = l.advertisements.map((a) => {
      let ad = advertisementStore.getAdvertisementById(a.id)

      return {
        advertisement: ad,
        capping: a.capping,
        enabled: true,
      } as advertisementType;
    })

    let routes = await Promise.all(l.routes.map(async (r) => {
      let ro = routeStore.getRouteById(r.id);

      if (ro == undefined) {
        return undefined
      }

      return {
        id: r.id,
        users: r.users,
        arrival: ro.arrival,
        departure: ro.departure,
      } as route;
    }))

    return {
      airline: airlineStore.getAirlineById(l.airline),
      discount: l.discount,
      routes: routes,
      advertisementTypes: adTypes.filter(a => a != undefined),
    } as line;
  }))

  offer.value = {
    name: o!.name,
    advertiser: o!.advertiser,
    targetRoutes: [],
    budget: o?.budget,
    period: {
      start: new Date(o!.startAt),
      end: new Date(o!.endAt),
    },
    routes: routes2,
    advertisementTypes: adTypes,
    lines: lines,
  } as createOffer;

  loaded.value = true;
})

const deleteOffer = () => {
  // if (offer.value != undefined && offer.value.id != undefined) {
  //   offerStore.deleteOffer(offer.value.id)
  //   router.replace({ name: 'Offers' });
  // }
}

</script>