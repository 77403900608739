<template>
    <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-4">
        <header class="px-5 py-4">
            <h2 class="font-semibold text-slate-800">Discount breakdown</h2>
        </header>
        <div>
            <div class="overflow-x-auto">
                <table class="table-auto w-full divide-y divide-slate-200">
                    <!-- Table header -->
                    <thead class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Airline</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Discount</div>
                            </th>
                        </tr>
                    </thead>
                    <DiscountTableRow ref="items" v-for="line in lines" :line="line" />
                    <tfoot class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Total</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">&euro; {{ totalDiscount.toFixed(2) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { line } from '../../pages/offers/Create.vue'
import DiscountTableRow from './DiscountTableRow.vue'
import { ref, computed } from 'vue';

defineProps({
    lines: {
        type: Array<line>,
        required: true,
    },
})

const items = ref([] as Array<InstanceType<typeof DiscountTableRow>>)

const totalDiscount = computed(() => {
    return items.value.reduce((sum, row) => {
        if (row.discount == undefined) return sum;
        return sum + row.discount;
    }, 0)
})

</script>