<template>
        <tr class="text-sm italic" v-if="grossCpm != 0">
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ advertisementType.advertisement.title }}
            </td>
            <td v-if="cappingEnabled" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ capping }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ displays.toFixed(0) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ grossCpm.toFixed(2) }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <span>&euro; {{ netRate.toFixed(2) }}</span>
            </td>
        </tr>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import type { PropType } from 'vue'; 
import type { line, advertisementType, route } from '../../pages/offers/Create.vue'
import type { section, advertisement } from '@/stores/airline'

const props = defineProps({
    airline: {
        type: String,
        required: true,
    },
    advertisementType: {
        type: Object as PropType<advertisementType>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    }
})

const capping = ref(props.lines.find(line => line.airline.name == props.airline)!.advertisementTypes.find(a => a.advertisement.id == props.advertisementType.advertisement.id)!.capping)

watch(capping, (newValue) => {
    if (capping.value != undefined && capping.value < 0)
    {
        capping.value = 0;
        return
    } 
    
    props.lines.filter(line => line.airline.name == props.airline).forEach(l => l.advertisementTypes.find(a => a.advertisement.id == props.advertisementType.advertisement.id)!.capping = newValue)
    emit('updated:lines', props.lines)
})

const emit = defineEmits<{
    (e: 'updated:lines', lines: Array<line>): void
}>()

const displays = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((lineSum: number, route: route) => {
        if (line.airline.name != props.airline) return lineSum;
        return lineSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => { 
                if (route.users == undefined) return advertisementSum;
                if (advertisement.type != props.advertisementType.advertisement.id) return advertisementSum;
                
                let cap = props.lines.find(line => line.airline.name == props.airline)!.advertisementTypes.find(a => a.advertisement.id == props.advertisementType.advertisement.id)!.capping

                if (props.cappingEnabled && cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(cap, line.airline.impressions) * route.users;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0));

const netRate = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    return totalSum + line.routes.reduce((lineSum: number, route: route) => {
        if (line.airline.name != props.airline) return lineSum;
        return lineSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (route.users == undefined) return advertisementSum;
                if (advertisement.type != props.advertisementType.advertisement.id) return advertisementSum;

                let cap = props.lines.find(line => line.airline.name == props.airline)!.advertisementTypes.find(a => a.advertisement.id == props.advertisementType.advertisement.id)!.capping

                let discount = (line.discount != undefined) ? line.discount : 0;
                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage * Math.min(cap, line.airline.impressions) * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage * line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                }
            }, 0)
        }, 0)
    }, 0)
}, 0));

const grossCpm = computed(() => (displays.value == 0) ? 0 : netRate.value / (displays.value / 1000))

defineExpose({
    displays,
    netRate,
    grossCpm
})

</script>