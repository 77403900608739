<template>
    <tr class="text-sm italic" v-if="displays > 0">
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            {{ airline }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            {{ displays.toFixed(0) }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            &euro; {{ grossCpm.toFixed(2) }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            &euro; {{ netRate.toFixed(2) }}
        </td>
    </tr>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import type { line, advertisementType, route } from '../../pages/offers/Create.vue'
import type { section, advertisement } from '@/stores/airline'

const props = defineProps({
    airline: {
        type: String,
        required: true,
    },
    mediaType: {
        type: String,
        required: true,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    }
})

const displays = ref(props.lines.reduce((totalSum: number, line: line) => {
    if (line.airline.name != props.airline) return totalSum;
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (line.airline.mediaType != props.mediaType) return advertisementSum;

                let adType = props.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)

                if (adType == undefined) return advertisementSum;

                if (route.users == undefined) return advertisementSum;
                if (adType.advertisement.id != advertisement.type ||
                    !adType.enabled) return advertisementSum;

                if (props.cappingEnabled && adType.capping != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(adType.capping, line.airline.impressions) * route.users;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const netRate = ref(props.lines.reduce((totalSum: number, line: line) => {
    if (line.airline.name != props.airline) return totalSum;
    return totalSum + line.routes.reduce((totalSum: number, route: route) => {
        return totalSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (line.airline.mediaType != props.mediaType) return advertisementSum;

                let adType = props.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)

                if (adType == undefined) return advertisementSum;

                if (route.users == undefined) return advertisementSum;
                if (adType.advertisement.id != advertisement.type ||
                    !adType.enabled) return advertisementSum;

                if (props.cappingEnabled && adType.capping != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(adType.capping, line.airline.impressions) *
                        route.users * advertisement.cpm / 1000;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users * advertisement.cpm / 1000;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0))

const grossCpm = ref((displays.value == 0) ? 0 : netRate.value / (displays.value / 1000))

defineExpose({
    displays,
    netRate,
    grossCpm
})

</script>