<template>
    <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-4">
        <header class="px-5 py-4">
            <h2 class="font-semibold text-slate-800">Media Breakdown</h2>
        </header>
        <div>
            <div class="overflow-x-auto">
                <table class="table-auto w-full divide-y divide-slate-200">
                    <!-- Table header -->
                    <thead class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Media Type</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Proposed Displays</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Gross CPM</div>
                            </th>
                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div class="font-semibold text-left">Net Rate</div>
                            </th>
                        </tr>
                    </thead>
                    <MediaTableRow ref="items" v-for="mediaType in mediaTypes" :airlines="airlines" :capping-enabled="cappingEnabled" :lines="lines" :advertisement-types="types" :media-type="mediaType" />
                    <tfoot class="text-sm font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                        <tr>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Total</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{{ displays.toFixed(0) }}</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">&euro; {{ grossCpm.toFixed(2) }}</td>
                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">&euro; {{ netRate.toFixed(2) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { line, advertisementType } from '../../pages/offers/Create.vue'
import MediaTableRow from './MediaTableRow.vue'
import { ref, computed } from 'vue';

defineProps({
    lines: {
        type: Array<line>,
        required: true,
    },
    airlines: {
        type: Array<string>,
        required: true,
    },
    types: {
        type: Array<advertisementType>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    },
    mediaTypes: {
        type: Array<string>,
        required: true,
    }
})

const items = ref([] as Array<InstanceType<typeof MediaTableRow>>)

const displays = computed(() => {
    return items.value.reduce((sum, row) => sum + row.displays, 0)
})

const netRate = computed(() => {
    return items.value.reduce((sum, row) => sum + row.netRate, 0)
})

const grossCpm = computed(() => {
    return (displays.value == 0) ? 0 : netRate.value / (displays.value / 1000)
})

</script>