import { defineStore } from 'pinia'
import axios from 'axios'

export type airline = {
  id: string;
  name: string;
  sections: Array<section>;
  impressions: number;
  mediaType: string;
}

export type section = {
  name: string;
  percentage: number;
  advertisements: Array<advertisement>;
}

export type advertisement = {
  type: string;
  percentage: number;
  cpm: number;
  provider: string;
}

export const useAirlineStore = defineStore('airline', {
  state: () => ({
    error: undefined as any | undefined,
    loading: false,
    airlines: [] as Array<airline>,
    // airlines: [
    //   {
    //     name: 'SmartWings',
    //     mediaType: 'Wireless IFE',
    //     impressions: 6,
    //     sections: [
    //       {
    //         name: "video",
    //         percentage: 0.2442,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 0.81,
    //             cpm: 35,
    //             provider: "IFD"
    //           },
    //           {
    //             type: "video-pre-roll",
    //             percentage: 0.19,
    //             cpm: 95,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "gaminggames",
    //         percentage: 0.1729,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "map",
    //         percentage: 0.1436,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "gaminghome",
    //         percentage: 0.1264,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 45,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "home",
    //         percentage: 0.0801,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 45,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "tv",
    //         percentage: 0.0779,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 0.92,
    //             cpm: 35,
    //             provider: "IFD"
    //           },
    //           {
    //             type: "video-pre-roll",
    //             percentage: 0.08,
    //             cpm: 95,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "highlights",
    //         percentage: 0.0761,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "music",
    //         percentage: 0.0554,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "IFD"
    //           }
    //         ]
    //       },
    //       {
    //         name: "gamingstreams",
    //         percentage: 0.013,
    //         advertisements: [
    //           {
    //             type: "video-pre-roll",
    //             percentage: 1.00,
    //             cpm: 95,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "gamingnews",
    //         percentage: 0.01,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "gamingshop",
    //         percentage: 0.0004,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.00,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     name: 'EuroWings',
    //     impressions: 6,
    //     mediaType: 'Seatback',
    //     sections: [
    //       {
    //         name: "games",
    //         percentage: 0.5189,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.0,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "home",
    //         percentage: 0.4171,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.0,
    //             cpm: 45,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "streams",
    //         percentage: 0.0340,
    //         advertisements: [
    //           {
    //             type: "video-pre-roll",
    //             percentage: 1.0,
    //             cpm: 95,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "news",
    //         percentage: 0.02,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.0,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       },
    //       {
    //         name: "news",
    //         percentage: 0.01,
    //         advertisements: [
    //           {
    //             type: "iab-leaderboard",
    //             percentage: 1.0,
    //             cpm: 35,
    //             provider: "Gladi8tor"
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ] as Array<airline>,
  }),
  actions: {
    async fetchAirlines() {
      try {
        this.loading = true;
        this.airlines = [];

        const data = await axios.get(`/api/airlines/`)

        var loadStats = async (data: any, airlines: Array<airline>) => {
          const adData = await axios.get(`/api/airlines/${data.id}/advertisement-statistics`)

          let lastAdData = undefined as any | undefined;
          for (var adDataKey in adData.data)
          {
            if (lastAdData == undefined)
            {
              lastAdData = adData.data[adDataKey]
            } else {
              lastAdData = (new Date(lastAdData.createdAt) > new Date(adData.data[adDataKey])) ? lastAdData : adData.data[adDataKey]
            }
          }

          if (lastAdData == undefined)
          {
            return
          }

          airlines.push({
            id: data.id,
            name: data.name,
            impressions: lastAdData.impressions,
            mediaType: lastAdData.mediaType,
            sections: lastAdData.sections.map((s: any) => {
              return {
                name: s.section,
                percentage: s.percentage,
                advertisements: s.advertisements.map((a: any) => {
                  return {
                    cpm: a.cpm,
                    percentage: a.percentage,
                    provider: a.provider,
                    type: a.type,
                  } as advertisement
                })
              } as section
            })
          })
        }

        let statsLoaded = [] as Array<Promise<any>>;
        
        for (var key in data.data)
        {
          statsLoaded.push(loadStats(data.data[key], this.airlines))
        }

        await Promise.all(statsLoaded)
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    }
  },
  getters: {
    getAirlines(state) {
      return state.airlines
    },
    getAirlineByName(state) {
      return (name: string) => {
        return state.airlines.find(a => a.name == name)
      }
    },
    getAirlineById(state) {
      return (id: string) => {
        return state.airlines.find(a => a.id == id)
      }
    }
  }
})
