import { defineStore } from 'pinia'
import axios from 'axios'
import moment from 'moment'

export type route = {
  id: string
  arrival: string | undefined;
  departure: string | undefined;
  // airlines: Array<route_airline>;
}

export type targets = {
  arrivals: Array<string>;
  departures: Array<string>;
  countries: {
    departures: Array<string>;
    arrivals: Array<string>;
  };
}

export type capacity = {
  users: number;
  route: route;
  routeID: string;
  airline: string;
}

export type route_airline = {
  id: string;
  name: string;
  users: number;
}

export const useRouteStore = defineStore('route', {
  state: () => ({
    capacities: [] as Array<capacity>,
    routes: [] as Array<route>,
    route: undefined as route | undefined,
    routeCapacities: [] as Array<capacity>,
    error: undefined as any | undefined,
    targets: undefined as targets | undefined,
    targetCapacity: [] as Array<capacity>,
    loading: false,
  }),
  actions: {
    // async fetchRoutes(startDate: Date, endDate: Date) {
    //   try {
    //     this.loading = true
    //     const data = await axios.get(`/api/routes/capacities?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`)

    //     let routes = [] as Array<string>;

    //     this.routes = [];

    //     this.capacities = data.data;

    //     for (var capacity in data.data) {
    //       if (routes.find(f => f == data.data[capacity].routeID) == undefined) {
    //         this.routes.push(data.data[capacity].route)
    //       }
    //     }
    //   } catch (error) {
    //     this.error = error;
    //   } finally {
    //     this.loading = false
    //   }
    // },
    async fetchRoutes() {
      try {
        const data = await axios.get(`/api/routes/`)
        this.routes = data.data;  
      }catch (error) {
        this.error = error;
      } finally {
        this.loading = false
      }
    },
    async fetchTargets(startDate: Date, endDate: Date) {
      try {
        this.loading = true
        const data = await axios.get(`/api/routes/targets?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`)
        this.targets = data.data;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false
      }
    },
    async fetchRouteTargetCapacity(startDate: Date, endDate: Date, route: Array<string>, countries: Array<string>) {
      try {
        this.loading = true

        let url = `/api/routes/capacities?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`

        for (const r of route) {
          url = url + `&routeTarget=${r}`
        }

        for (const r of countries) {
          url = url + `&countryRouteTarget=${r}`
        }

        const data = await axios.get(url)
        this.targetCapacity = data.data;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false
      }
    },
    async fetchRouteById(id: string) {
      try {
        this.loading = true

        const data = await axios.get(`/api/routes/${id}`)

        this.route = {
          id: data.data.id,
          arrival: data.data.arrival,
          departure: data.data.departure,
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false
      }
    }
  },
  getters: {
    getRoutes(state) {
      return state.routes
    },
    getRouteById(state) {
      return (id: string) => {
        return state.routes.find(a => a.id == id)
      }
    }
  }
})
