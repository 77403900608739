import { defineStore } from 'pinia'
import axios from 'axios'

export type advertisement = {
  id: string;
  title: string;
  description: string;
}

export const useAdvertisementStore = defineStore('advertisement', {
  state: () => ({
    advertisements: [] as Array<advertisement>,
    loading: false,
    error: undefined as any | undefined,
  }),
  actions: {
    async fetchAdvertisements() {
      try {
        this.loading = true;
        this.advertisements = [];

        const data = await axios.get(`/api/advertisements/formats`)
        for (var key in data.data)
        {
          this.advertisements.push({
            id: data.data[key].id,
            title: data.data[key].title,
            description: data.data[key].description,
          })
        }
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    }
  },
  getters: {
    getAdvertisements(state) {
      return state.advertisements
    },
    getAdvertisementById(state) {
      return (id: string) => {
        return state.advertisements.find(a => a.id == id)
      }
    }
  }
})
