<template>
    <div>
        <div>
            <div v-if="loaded" class="mx-auto">
                <div class="space-y-4 mb-8">
                    <div class="grid xl:grid-cols-3 gap-1">
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Name</h3>
                            <div>{{ offerName }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Advertiser</h3>
                            <div>{{ advertiserName }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Campaign Period</h3>
                            <div>{{ moment(campaignStartDate).format('MMM D, YYYY') }} - {{
                                moment(campaignEndDate).format('MMM D, YYYY')
                            }}</div>
                        </div>
                        <div class="text-l">
                            <h3 class="font-medium text-slate-800">Total</h3>
                            <div>&euro; {{ costs.toFixed(2) }}</div>
                        </div>
                        <div class="text-l" v-if="campaignBudget != undefined">
                            <h3 class="font-medium text-slate-800">Budget</h3>
                            <div>&euro; {{ campaignBudget.toFixed(2) }}</div>
                        </div>
                        <div class="text-l" v-if="campaignBudget != undefined && campaignBudget -
                            costs != 0">
                            <h3 class="font-medium text-slate-800">Budget Difference</h3>
                            <div><span style="color: green" v-if="(campaignBudget - costs) > 0">&euro; {{
                                (campaignBudget
                                    -
                                    costs).toFixed(2) }}</span><span style="color: red"
                                    v-if="(campaignBudget - costs) < 0">&euro; {{ (campaignBudget -
                                        costs).toFixed(2) }}</span></div>
                        </div>
                    </div>
                    <div class="text-l" v-if="url != undefined && url != ''">
                        <h3 class="font-medium text-slate-800" style="word-break: keep-all;">Public URL</h3>
                        <div>{{ url }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!loaded" class="mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Breakdown information</h1>
                <p>Loading ...</p>
            </div>
            <div v-if="loaded" class="mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Breakdown information</h1>
                <ul class="flex flex-wrap -m-1">
                    <li class="m-1">
                        <button @click="section = 'format'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'format') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Format</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'airline'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'airline') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Airline</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'media'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'media') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Media</button>
                    </li>
                    <li class="m-1">
                        <button @click="section = 'route'"
                            class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border"
                            :class="(section == 'route') ? 'border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out' : 'border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out'">Route</button>
                    </li>
                </ul>
                <div v-if="section == 'airline'">
                    <div class="space-y-4 mb-8">
                        <BreakdownAirlineTable :advertisementTypes="advertisementTypes" :capping-enabled="cappingEnabled"
                            v-bind:lines="lines" />
                    </div>
                </div>
                <div v-if="section == 'format'">
                    <div class="space-y-4 mb-8">
                        <BreakdownFormatTable :airlines="airlines" :capping-enabled="cappingEnabled" :lines="lines"
                            :types="advertisementTypes.filter(t => t.enabled)" />
                    </div>
                </div>
                <div v-if="section == 'media'">
                    <div class="space-y-4 mb-8">
                        <BreakdownMediaTable ref="mediaTable" :airlines="airlines" :types="advertisementTypes"
                            :capping-enabled="cappingEnabled" :lines="lines" :media-types="mediaTypes" />
                    </div>
                </div>
                <div v-if="section == 'route'">
                    <div class="space-y-4 mb-8">
                        <BreakdownRouteTable :airlines="airlines" :types="advertisementTypes"
                            :capping-enabled="cappingEnabled" v-bind:lines="lines" :routes="routes" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import moment from 'moment'
import { ref, computed, onMounted } from 'vue';
import type { PropType } from 'vue';

import type { advertisementType, line, route } from '@/pages/offers/Create.vue';

import BreakdownFormatTable from './BreakdownFormatTable.vue'
import BreakdownMediaTable from './BreakdownMediaTable.vue'
import BreakdownAirlineTable from './BreakdownAirlineTable.vue'
import BreakdownRouteTable from './BreakdownRouteTable.vue'

import type { section, advertisement as sectionAdvertisement } from '@/stores/airline';
import type { route as storeRouteRoute } from '@/stores/route';
import { useAirlineStore } from '@/stores/airline';
import { useRouteStore } from '@/stores/route';


const props = defineProps({
    currentStep: {
        type: Number,
        required: false,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    offerName: {
        type: String,
        required: true,
    },
    advertiserName: {
        type: String,
        required: true,
    },
    campaignBudget: {
        type: undefined as unknown as PropType<number | undefined>,
        required: true,
    },
    campaignStartDate: {
        type: Date,
        required: true,
    },
    campaignEndDate: {
        type: Date,
        required: true,
    },
    routes: {
        type: Array<storeRouteRoute>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    url: {
        type: undefined as unknown as PropType<String | undefined>,
        required: false,
    }
})

const section = ref('format')

const airlineStore = useAirlineStore();
var routeStore = useRouteStore()

const loaded = ref(true)

const airlines = computed(() => {
    var names = [] as Array<string>;
    props.lines.forEach(l => {
        if (names.find(n => n == l.airline.name) == undefined) {
            names.push(l.airline.name)
        }
    })
    return names;
})

const mediaTypes = computed(() => {
    var mediaTypes = [] as Array<string>;
    props.lines.forEach(l => {
        if (mediaTypes.find(n => n == l.airline.mediaType) == undefined) {
            mediaTypes.push(l.airline.mediaType)
        }
    })
    return mediaTypes;
})

const routes = computed(() => {
    var routes = [] as Array<route>;
    props.routes.forEach(l => {
        routes.push({
            id: l.id,
            arrival: l.arrival,
            departure: l.departure,
            users: undefined,
            maxUsers: undefined,
        })
    })
    return routes;
})

const cappingEnabled = computed(() => props.lines.find(l => l.advertisementTypes.find(a => a.enabled && a.capping != undefined) != undefined) != undefined)

const costs = computed(() => {
    return props.lines.reduce((totalSum: number, line: line) => {
        return totalSum + line.routes.reduce((totalSum: number, route: route) => {
            return totalSum + line.airline.sections.reduce((sectionSum: number, section: section) => {
                return sectionSum + section.advertisements.reduce((advertisementSum: number, advertisement: sectionAdvertisement) => {
                    if (route.users == undefined) return advertisementSum;
                    let adType = line.advertisementTypes.find(adType => adType.enabled && adType.advertisement.id == advertisement.type)
                    if (adType == undefined || !adType.enabled) return advertisementSum;

                    let discount = (line.discount != undefined) ? line.discount : 0;
                    if (adType.capping != undefined) {
                        return advertisementSum + section.percentage * advertisement.percentage
                            *
                            Math.min(adType.capping, line.airline.impressions) *
                            route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                    } else {
                        return advertisementSum + section.percentage * advertisement.percentage
                            *
                            line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                    }
                }, 0)
            }, 0)
        }, 0)
    }, 0)
});

</script>