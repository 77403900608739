<template>
    <tr class="text-sm italic" v-if="users != undefined">
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            {{ airline }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            {{ users }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            {{ displays.toFixed(0) }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            &euro; {{ grossCpm.toFixed(2) }}
        </td>
        <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            &euro; {{ netRate.toFixed(2) }}
        </td>
    </tr>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import type { PropType } from 'vue'
import type { line, advertisementType, route as routeObj } from '../../pages/offers/Create.vue'
import type { section, advertisement } from '@/stores/airline'

const props = defineProps({
    airline: {
        type: String,
        required: true,
    },
    route: {
        type: Object as PropType<routeObj>,
        required: true,
    },
    advertisementTypes: {
        type: Array<advertisementType>,
        required: true,
    },
    lines: {
        type: Array<line>,
        required: true,
    },
    cappingEnabled: {
        type: Boolean,
        required: true,
    }
})

const users = ref(undefined as number | undefined)

let airlineLine = props.lines.find(l => l.airline.name == props.airline && l.routes.find(r => r.id == props.route.id) != undefined);
if (airlineLine != undefined) {
    let airlineRoute = airlineLine.routes.find(r => r.id == props.route.id);
    if (airlineRoute != undefined) {
        users.value = airlineRoute.users as number | undefined;
    }
}

const displays = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    if (line.airline.name != props.airline) return totalSum;
    return totalSum + line.routes.reduce((lineSum: number, route: routeObj) => {
        if (route.id != props.route.id) return lineSum;
        return lineSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (route.users == undefined) return advertisementSum;
                let adType = props.advertisementTypes.find(adType => adType.enabled &&
                    adType.advertisement.id
                    == advertisement.type)
                if (adType == undefined) return advertisementSum;

                let cap = props.lines.find(line => line.airline.name == props.airline)!.advertisementTypes.find(a => a.advertisement.id == adType!.advertisement.id)!.capping;

                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        Math.min(cap, line.airline.impressions) * route.users;
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage
                        *
                        line.airline.impressions * route.users;
                }
            }, 0)
        }, 0)
    }, 0)
}, 0));

const netRate = computed(() => props.lines.reduce((totalSum: number, line: line) => {
    if (line.airline.name != props.airline) return totalSum;
    return totalSum + line.routes.reduce((lineSum: number, route: routeObj) => {
        if (route.id != props.route.id) return lineSum;
        return lineSum + line.airline.sections.reduce((sectionSum: number,
            section:
                section) => {
            return sectionSum + section.advertisements.reduce((advertisementSum:
                number,
                advertisement: advertisement) => {
                if (route.users == undefined) return advertisementSum;
                let adType = props.advertisementTypes.find(adType => adType.enabled &&
                    adType.advertisement.id
                    == advertisement.type)
                if (adType == undefined) return advertisementSum;

                let cap = props.lines.find(line => line.airline.name == props.airline)!.advertisementTypes.find(a => a.advertisement.id == adType!.advertisement.id)!.capping;
                let discount = (line.discount != undefined) ? line.discount : 0;
                if (cap != undefined) {
                    return advertisementSum + section.percentage * advertisement.percentage * Math.min(cap, line.airline.impressions) * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                } else {
                    return advertisementSum + section.percentage * advertisement.percentage * line.airline.impressions * route.users * advertisement.cpm / 1000 * ((100 - discount) / 100);
                }
            }, 0)
        }, 0)
    }, 0)
}, 0));

const grossCpm = computed(() => (displays.value == 0) ? 0 : netRate.value / (displays.value / 1000))

defineExpose({
    displays,
    netRate,
    grossCpm,
    users,
})

</script>