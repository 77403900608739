import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../pages/Dashboard.vue'
import Offers from '../pages/offers/Offers.vue'
import OfferView from '../pages/offers/View.vue'
import OfferDetails from '../pages/offers/Details.vue'
import CreateOffer from '../pages/offers/Create.vue'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/offers',
    },
    {
      name: 'Offers',
      path: '/offers',
      component: Offers
    },
    {
      path: '/offers/:id',
      component: OfferDetails,
      name: 'OfferDetails',
      props: true
    },
    {
      path: '/offers/view/:id',
      component: OfferView,
      name: 'OfferView',
      props: true
    },
    {
      path: '/offers/create',
      component: CreateOffer,
      name: 'CreateOffer',
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name == "OfferView") 
  {
    next();
    return
  }

  const userStore = useUserStore();

  if (!userStore.authenticated)
  {
    await userStore.fetchUser();
  }

  if (!userStore.authenticated) {
    window.location.href = "/.auth/login/aadb2c";
  } else {
    next()
  }
})

export default router
