<template>
    <tbody class="text-sm">
        <tr>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {{ line.airline.name }}
            </td>
            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                &euro; {{ line.discount?.toFixed(2) }}
            </td>
        </tr>
    </tbody>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import type { line } from '../../pages/offers/Create.vue'
import type { PropType } from 'vue';

const props = defineProps({
    line: {
        type: Object as PropType<line>,
        required: true,
    }
})

const discount = ref((props.line.discount != undefined)?props.line.discount : undefined)

defineExpose({
    discount
})

</script>