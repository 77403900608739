<template>
    <main class="bg-white">

        <div class="relative flex">

            <!-- Content -->
            <div class="w-full md:w-100">

                <div class="min-h-screen h-full flex flex-col after:flex-1">

                    <div class="flex-1">

                        <!-- Header -->
                        <div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                            <!-- Logo -->
                            <!-- <router-link class="block" to="/">
                                <svg width="32" height="32" viewBox="0 0 32 32">
                                    <defs>
                                        <linearGradient x1="28.538%" y1="20.229%" x2="100%" y2="108.156%" id="logo-a">
                                            <stop stop-color="#A5B4FC" stop-opacity="0" offset="0%" />
                                            <stop stop-color="#A5B4FC" offset="100%" />
                                        </linearGradient>
                                        <linearGradient x1="88.638%" y1="29.267%" x2="22.42%" y2="100%" id="logo-b">
                                            <stop stop-color="#38BDF8" stop-opacity="0" offset="0%" />
                                            <stop stop-color="#38BDF8" offset="100%" />
                                        </linearGradient>
                                    </defs>
                                    <rect fill="#6366F1" width="32" height="32" rx="16" />
                                    <path
                                        d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                                        fill="#4F46E5" />
                                    <path
                                        d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                                        fill="url(#logo-a)" />
                                    <path
                                        d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                                        fill="url(#logo-b)" />
                                </svg>
                            </router-link> -->
                            <h1 style="color: black; font-size: 25px; font-weight: bold;">IMD</h1>
                        </div>

                        <div v-if="page == 1">
                            <Step1 @step="gotoPage" :current-step="page" v-model:advertiser-name="offer.advertiser"
                                v-model:offer-name="offer.name" v-model:campaign-budget="offer.budget"
                                v-model:campaign-start-date="offer.period.start"
                                v-model:campaign-end-date="offer.period.end" />
                        </div>

                        <div v-if="page == 2">
                            <Step2 v-model:target-routes="offer.targetRoutes" @step="gotoPage" :current-step="page" :campaign-start-date="offer.period.start" :campaign-end-date="offer.period.end" v-model:routes="offer.routes" />
                        </div>

                        <div v-if="page == 3">
                            <Step3 @step="gotoPage" :current-step="page" v-model:advertisement-types="offer.advertisementTypes" />
                        </div>

                        <div v-if="page == 4 && !creating">
                            <Step4 
                                v-model:targetRoutes="offer.targetRoutes"
                                @step="gotoPage" 
                                :current-step="page" 
                                v-model:advertiser-name="offer.advertiser"
                                v-model:offer-name="offer.name" v-model:campaign-budget="offer.budget"
                                v-model:campaign-start-date="offer.period.start"
                                v-model:campaign-end-date="offer.period.end" 
                                :advertisement-types="offer.advertisementTypes" 
                                :lines="offer.lines" 
                                :routes="offer.routes" 
                            />
                        </div>
                        <div v-if="page == 4 && creating">
                            <div class="px-4 py-8 mt-40">
                                <div class="max-w-2xl mx-auto">
                                    <div class="text-center">
                                        <div class="text-center">
                                        <h1 class="text-3xl text-slate-800 font-bold mb-8">Creating offer {{ offer.name }}, please wait ...</h1>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-if="page == 5">
                            <div class="px-4 py-8 mt-40">
                                <div class="max-w-2xl mx-auto">
                                    <div class="text-center" v-if="!creating">
                                        <svg class="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
                                            <circle class="text-emerald-100" cx="32" cy="32" r="32" />
                                            <path class="text-emerald-500" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
                                        </svg>
                                        <h1 class="text-3xl text-slate-800 font-bold mb-8">Offer {{ offer.name }}
                                            created
                                            🙌</h1>
                                        <router-link class="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                                            :to="{ name: 'Offers' }">Go
                                            To Overview -&gt;</router-link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';

import type { route as storeRoute } from '@/stores/route';
import { useOfferStore, type offer_line, type offer as offer_offer, type offer_route, type offer_advertisement } from '@/stores/offer';
import type { airline, section, advertisement as sectionAdvertisement } from '@/stores/airline';
import type { advertisement } from '@/stores/advertisement';

import moment from 'moment';

import Step1 from '@/partials/offers/Step1.vue';
import Step2 from '@/partials/offers/Step2.vue';
import Step3 from '@/partials/offers/Step3.vue';
import Step4 from '@/partials/offers/Step4.vue';

export type targetRoute = {
    departure: string | undefined;
    arrival: string | undefined;
    users: number;
}

export type offer = {
    name: string;
    advertiser: string;
    budget: number | undefined;
    period: {
        start: Date;
        end: Date;
    },
    targetRoutes: Array<targetRoute>;
    routes: Array<storeRoute>;
    advertisementTypes: Array<advertisementType>;
    lines: Array<line>;
};

export type line = {
    airline: airline;
    discount: number | undefined;
    routes: Array<route>;
    advertisementTypes: Array<advertisementType>;
}

export type route = {
    id: string;
    departure: string | undefined;
    arrival: string | undefined;
    users: number | undefined;
    maxUsers: number | undefined;
}

export type advertisementType = {
    enabled: boolean;
    capping: number | undefined;
    advertisement: advertisement;
}

const page = ref(1 as number)

const offer = ref({
    name: '',
    advertiser: '',
    budget: undefined,
    period: {
        start: new Date(),
        end: new Date(new Date().setDate(new Date().getDate() + 7)),
    },
    routes: [],
    targetRoutes: [],
    advertisementTypes: [],
    lines: [],
} as offer);

const gotoPage = async (pageNumber: number) => {

    if (pageNumber == 5)
    {
        await create()
    }

    page.value = pageNumber;
}

const offerStore = useOfferStore();

const creating = ref(false)

const create = async () => {
    creating.value = true

    let offerLines = [] as Array<offer_line>;

    offer.value.lines.forEach(line => {
        offerLines.push({
            airline: line.airline.id,
            discount: line.discount,
            advertisements: line.advertisementTypes.map(a => {
                return {
                    id: a.advertisement.id,
                    capping: a.capping,
                } as offer_advertisement;
            }),
            routes: line.routes.map(r => {
                return {
                    id: r.id,
                    users: r.users,
                } as offer_route;
            })
        } as offer_line)
    })

    await offerStore.createOffer({
        advertiser: offer.value.advertiser,
        name: offer.value.name,
        startAt: moment(offer.value.period.start).format("YYYY-MM-DD"),
        endAt: moment(offer.value.period.end).format("YYYY-MM-DD"),
        budget: offer.value.budget,
        lines: offerLines,
        targetRoutes: offer.value.targetRoutes,
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    } as offer_offer)

    creating.value = false
}
</script>