<template>
    <div>
        <CreateOfferProgressBar v-bind:current-step="currentStep" @step="gotoPage" />
        <div class="px-4 py-8">
            <div class="max-w-5xl mx-auto">
                <h1 class="text-3xl text-slate-800 font-bold mb-6">Target routes</h1>
                <div class="space-y-4 mb-8">
                    <div v-if="loading">
                        Loading ...
                    </div>
                    <div v-if="!loading && (departures.length == 0 && arrivals.length == 0)">
                        <p class="text-md text-rose-500">No routes available for the campaign period.</p>
                    </div>
                    <div v-if="!loading">
                        <div class="grid gap-5 md:grid-cols-2">
                            <div>
                                <VueMultiselect :disabled="allCountryDepartures" v-model="selectedCountryDeparture"
                                    :options="countryDepartures">
                                </VueMultiselect>
                                <label class="flex items-center mb-2">
                                    <input type="checkbox" class="form-checkbox" v-model="allCountryDepartures" />
                                    <span class="text-sm ml-2">Select all departure countries</span>
                                </label>
                            </div>
                            <div>
                                <VueMultiselect :disabled="allCountryArrivals" v-model="selectedCountryArrival"
                                    :options="countryArrivals">
                                </VueMultiselect>
                                <label class="flex items-center mb-2">
                                    <input type="checkbox" class="form-checkbox" v-model="allCountryArrivals" />
                                    <span class="text-sm ml-2">Select all arrival countries</span>
                                </label>
                            </div>
                            <div>
                                <VueMultiselect :disabled="allDepartures" v-model="selectedDeparture" :options="departures">
                                </VueMultiselect>
                                <label class="flex items-center mb-2">
                                    <input type="checkbox" class="form-checkbox" v-model="allDepartures" />
                                    <span class="text-sm ml-2">Select all departures</span>
                                </label>
                            </div>
                            <div>
                                <VueMultiselect :disabled="allArrivals" v-model="selectedArrival" :options="arrivals">
                                </VueMultiselect>
                                <label class="flex items-center mb-2">
                                    <input type="checkbox" class="form-checkbox" v-model="allArrivals" />
                                    <span class="text-sm ml-2">Select all arrivals</span>
                                </label>
                            </div>
                            <div>
                                <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full"
                                    @click.stop="addRoute">Add</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!loading && targetRoutes.length > 0"
                        class="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-4">
                        <header class="px-5 py-4">
                            <h2 class="font-semibold text-slate-800">Routes</h2>
                        </header>
                        <div>
                            <div class="overflow-x-auto">
                                <table class="table-auto w-full divide-y divide-slate-200">
                                    <thead
                                        class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
                                        <tr>
                                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div class="font-semibold text-left">Departure
                                                </div>
                                            </th>
                                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div class="font-semibold text-left">Arrival
                                                </div>
                                            </th>
                                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div class="font-semibold text-left">Users
                                                </div>
                                            </th>
                                            <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm" v-for="route in targetRoutes">
                                        <tr>
                                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div v-if="route.departure != undefined">{{
                                                    isoToAirport(route.departure)
                                                }}</div>
                                                <div v-if="route.departure == undefined">All
                                                </div>
                                            </td>
                                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div v-if="route.arrival != undefined">{{
                                                    isoToAirport(route.arrival)
                                                }}</div>
                                                <div v-if="route.arrival == undefined">All</div>
                                            </td>
                                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                ~ {{ route.users }}
                                            </td>
                                            <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <button class="text-rose-500 hover:text-rose-600 rounded-full"
                                                    @click.stop="deleteTargetRoute(route)">
                                                    <span class="sr-only">Delete</span>
                                                    <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                                        <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                                                        <path
                                                            d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div v-if="showError">
                        <p class="text-md text-rose-500">No routes selected.</p>
                    </div>
                    <div class="flex items-center justify-between">
                        <button class="text-sm underline hover:no-underline" @click="gotoPage(1)">&lt;-
                            Back</button>
                        <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto" @click="gotoPage(3)">Next
                            Step -&gt;</button>
                    </div>

                    <!-- <div v-if="!loading && availableRoutes.length > 0"
                        class="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-4">
                        <header class="px-5 py-4">
                            <h2 class="font-semibold text-slate-800">Routes</h2>
                        </header>
                        <div>
                            <div class="overflow-x-auto">
                                <SelectRouteTable :routes="availableRoutes" v-bind:selected-routes="routes"
                                    @update:selected-routes="(r) => emit('update:routes', r)" />
                            </div>
                        </div>
                    </div>
                    <div v-if="showError">
                        <p class="text-md text-rose-500">No routes selected.</p>
                    </div>
                    <div class="flex items-center justify-between">
                        <button class="text-sm underline hover:no-underline" @click="gotoPage(1)">&lt;-
                            Back</button>
                        <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto" @click="gotoPage(3)">Next
                            Step -&gt;</button>
                    </div>  -->
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
import { ref, onMounted } from 'vue';
import { useRouteStore } from '@/stores/route';
import type { route, capacity } from '@/stores/route';
import { storeToRefs } from 'pinia'

import CreateOfferProgressBar from './CreateOfferProgressBar.vue';
import type { targetRoute } from '@/pages/offers/Create.vue';

const routeStore = useRouteStore();

const props = defineProps({
    currentStep: {
        type: Number,
        required: true,
    },
    campaignStartDate: {
        type: Date,
        required: true,
    },
    campaignEndDate: {
        type: Date,
        required: true,
    },
    routes: {
        type: Array<route>,
        required: true,
    },
    targetRoutes: {
        type: Array<targetRoute>,
        required: true,
    }
})

const { loading, targets, targetCapacity } = storeToRefs(useRouteStore())

const showError = ref(false)

const allArrivals = ref(false)
const allDepartures = ref(false)

const allCountryArrivals = ref(false)
const allCountryDepartures = ref(false)

const selectedArrival = ref(undefined as string | undefined)
const selectedDeparture = ref(undefined as string | undefined)

const selectedCountryArrival = ref(undefined as string | undefined)
const selectedCountryDeparture = ref(undefined as string | undefined)

const deleteTargetRoute = (route: targetRoute) => {
    props.targetRoutes.splice(props.targetRoutes.indexOf(route, 0), 1)
}

const addRoute = async () => {
    let dep = ""
    if (!allDepartures.value && selectedDeparture.value != undefined) {
        dep = airportToIso(selectedDeparture.value)
    }

    let arr = ""
    if (!allArrivals.value && selectedArrival.value != undefined) {
        arr = airportToIso(selectedArrival.value)
    }

    let depCountry = ""
    if (!allCountryDepartures.value && selectedCountryDeparture.value != undefined) {
        depCountry = countryToIso(selectedCountryDeparture.value)
    }

    let arrCountry = ""
    if (!allCountryArrivals.value && selectedCountryArrival.value != undefined) {
        arrCountry = countryToIso(selectedCountryArrival.value)
    }

    if (depCountry != "" || arrCountry != "") {
        await routeStore.fetchRouteTargetCapacity(props.campaignStartDate, props.campaignEndDate, [], [depCountry + "-" + arrCountry])
    } else {
        await routeStore.fetchRouteTargetCapacity(props.campaignStartDate, props.campaignEndDate, [dep + "-" + arr], [])
    }

    // let users = targetCapacity.value.reduce((u, c) => {
    //     return u + c.users
    // }, 0)

    // if (dep == "" && arr == "") {
    //     props.targetRoutes.splice(0, props.targetRoutes.length)
    // } else {
    //     for (const targetRoute of props.targetRoutes) {
    //         if (targetRoute.arrival == undefined && targetRoute.departure == undefined) {
    //             props.targetRoutes.splice(props.targetRoutes.indexOf(targetRoute, 0), 1)
    //         }
    //     }
    // }

    for (const cap of targetCapacity.value) {
        props.targetRoutes.push({
            arrival: cap.route.arrival == "" ? undefined : cap.route.arrival,
            departure: cap.route.departure == "" ? undefined : cap.route.departure,
            users: cap.users,
        })
    }

    emit('update:targetRoutes', props.targetRoutes)
}

const isoToCountry = (iso: string) => {
    let countries: { [index: string]: string } = {
  "AF": "AF | Afghanistan",
  "AX": "AX | Aland Islands",
  "AL": "AL | Albania",
  "DZ": "DZ | Algeria",
  "AS": "AS | American Samoa",
  "AD": "AD | Andorra",
  "AO": "AO | Angola",
  "AI": "AI | Anguilla",
  "AQ": "AQ | Antarctica",
  "AG": "AG | Antigua And Barbuda",
  "AR": "AR | Argentina",
  "AM": "AM | Armenia",
  "AW": "AW | Aruba",
  "AU": "AU | Australia",
  "AT": "AT | Austria",
  "AZ": "AZ | Azerbaijan",
  "BS": "BS | Bahamas",
  "BH": "BH | Bahrain",
  "BD": "BD | Bangladesh",
  "BB": "BB | Barbados",
  "BY": "BY | Belarus",
  "BE": "BE | Belgium",
  "BZ": "BZ | Belize",
  "BJ": "BJ | Benin",
  "BM": "BM | Bermuda",
  "BT": "BT | Bhutan",
  "BO": "BO | Bolivia",
  "BA": "BA | Bosnia And Herzegovina",
  "BW": "BW | Botswana",
  "BV": "BV | Bouvet Island",
  "BR": "BR | Brazil",
  "IO": "IO | British Indian Ocean Territory",
  "BN": "BN | Brunei Darussalam",
  "BG": "BG | Bulgaria",
  "BF": "BF | Burkina Faso",
  "BI": "BI | Burundi",
  "KH": "KH | Cambodia",
  "CM": "CM | Cameroon",
  "CA": "CA | Canada",
  "CV": "CV | Cape Verde",
  "KY": "KY | Cayman Islands",
  "CF": "CF | Central African Republic",
  "TD": "TD | Chad",
  "CL": "CL | Chile",
  "CN": "CN | China",
  "CX": "CX | Christmas Island",
  "CC": "CC | Cocos (Keeling) Islands",
  "CO": "CO | Colombia",
  "KM": "KM | Comoros",
  "CG": "CG | Congo",
  "CD": "CD | Congo, Democratic Republic",
  "CK": "CK | Cook Islands",
  "CR": "CR | Costa Rica",
  "CI": "CI | Cote D\"Ivoire",
  "HR": "HR | Croatia",
  "CU": "CU | Cuba",
  "CY": "CY | Cyprus",
  "CZ": "CZ | Czech Republic",
  "DK": "DK | Denmark",
  "DJ": "DJ | Djibouti",
  "DM": "DM | Dominica",
  "DO": "DO | Dominican Republic",
  "EC": "EC | Ecuador",
  "EG": "EG | Egypt",
  "SV": "SV | El Salvador",
  "GQ": "GQ | Equatorial Guinea",
  "ER": "ER | Eritrea",
  "EE": "EE | Estonia",
  "ET": "ET | Ethiopia",
  "FK": "FK | Falkland Islands (Malvinas)",
  "FO": "FO | Faroe Islands",
  "FJ": "FJ | Fiji",
  "FI": "FI | Finland",
  "FR": "FR | France",
  "GF": "GF | French Guiana",
  "PF": "PF | French Polynesia",
  "TF": "TF | French Southern Territories",
  "GA": "GA | Gabon",
  "GM": "GM | Gambia",
  "GE": "GE | Georgia",
  "DE": "DE | Germany",
  "GH": "GH | Ghana",
  "GI": "GI | Gibraltar",
  "GR": "GR | Greece",
  "GL": "GL | Greenland",
  "GD": "GD | Grenada",
  "GP": "GP | Guadeloupe",
  "GU": "GU | Guam",
  "GT": "GT | Guatemala",
  "GG": "GG | Guernsey",
  "GN": "GN | Guinea",
  "GW": "GW | Guinea-Bissau",
  "GY": "GY | Guyana",
  "HT": "HT | Haiti",
  "HM": "HM | Heard Island & Mcdonald Islands",
  "VA": "VA | Holy See (Vatican City State)",
  "HN": "HN | Honduras",
  "HK": "HK | Hong Kong",
  "HU": "HU | Hungary",
  "IS": "IS | Iceland",
  "IN": "IN | India",
  "ID": "ID | Indonesia",
  "IR": "IR | Iran, Islamic Republic Of",
  "IQ": "IQ | Iraq",
  "IE": "IE | Ireland",
  "IM": "IM | Isle Of Man",
  "IL": "IL | Israel",
  "IT": "IT | Italy",
  "JM": "JM | Jamaica",
  "JP": "JP | Japan",
  "JE": "JE | Jersey",
  "JO": "JO | Jordan",
  "KZ": "KZ | Kazakhstan",
  "KE": "KE | Kenya",
  "KI": "KI | Kiribati",
  "KR": "KR | Korea",
  "KP": "KP | North Korea",
  "KW": "KW | Kuwait",
  "KG": "KG | Kyrgyzstan",
  "LA": "LA | Lao People\"s Democratic Republic",
  "LV": "LV | Latvia",
  "LB": "LB | Lebanon",
  "LS": "LS | Lesotho",
  "LR": "LR | Liberia",
  "LY": "LY | Libyan Arab Jamahiriya",
  "LI": "LI | Liechtenstein",
  "LT": "LT | Lithuania",
  "LU": "LU | Luxembourg",
  "MO": "MO | Macao",
  "MK": "MK | Macedonia",
  "MG": "MG | Madagascar",
  "MW": "MW | Malawi",
  "MY": "MY | Malaysia",
  "MV": "MV | Maldives",
  "ML": "ML | Mali",
  "MT": "MT | Malta",
  "MH": "MH | Marshall Islands",
  "MQ": "MQ | Martinique",
  "MR": "MR | Mauritania",
  "MU": "MU | Mauritius",
  "YT": "YT | Mayotte",
  "MX": "MX | Mexico",
  "FM": "FM | Micronesia, Federated States Of",
  "MD": "MD | Moldova",
  "MC": "MC | Monaco",
  "MN": "MN | Mongolia",
  "ME": "ME | Montenegro",
  "MS": "MS | Montserrat",
  "MA": "MA | Morocco",
  "MZ": "MZ | Mozambique",
  "MM": "MM | Myanmar",
  "NA": "NA | Namibia",
  "NR": "NR | Nauru",
  "NP": "NP | Nepal",
  "NL": "NL | Netherlands",
  "AN": "AN | Netherlands Antilles",
  "NC": "NC | New Caledonia",
  "NZ": "NZ | New Zealand",
  "NI": "NI | Nicaragua",
  "NE": "NE | Niger",
  "NG": "NG | Nigeria",
  "NU": "NU | Niue",
  "NF": "NF | Norfolk Island",
  "MP": "MP | Northern Mariana Islands",
  "NO": "NO | Norway",
  "OM": "OM | Oman",
  "PK": "PK | Pakistan",
  "PW": "PW | Palau",
  "PS": "PS | Palestinian Territory, Occupied",
  "PA": "PA | Panama",
  "PG": "PG | Papua New Guinea",
  "PY": "PY | Paraguay",
  "PE": "PE | Peru",
  "PH": "PH | Philippines",
  "PN": "PN | Pitcairn",
  "PL": "PL | Poland",
  "PT": "PT | Portugal",
  "PR": "PR | Puerto Rico",
  "QA": "QA | Qatar",
  "RE": "RE | Reunion",
  "RO": "RO | Romania",
  "RU": "RU | Russian Federation",
  "RW": "RW | Rwanda",
  "BL": "BL | Saint Barthelemy",
  "SH": "SH | Saint Helena",
  "KN": "KN | Saint Kitts And Nevis",
  "LC": "LC | Saint Lucia",
  "MF": "MF | Saint Martin",
  "PM": "PM | Saint Pierre And Miquelon",
  "VC": "VC | Saint Vincent And Grenadines",
  "WS": "WS | Samoa",
  "SM": "SM | San Marino",
  "ST": "ST | Sao Tome And Principe",
  "SA": "SA | Saudi Arabia",
  "SN": "SN | Senegal",
  "RS": "RS | Serbia",
  "SC": "SC | Seychelles",
  "SL": "SL | Sierra Leone",
  "SG": "SG | Singapore",
  "SK": "SK | Slovakia",
  "SI": "SI | Slovenia",
  "SB": "SB | Solomon Islands",
  "SO": "SO | Somalia",
  "ZA": "ZA | South Africa",
  "GS": "GS | South Georgia And Sandwich Isl.",
  "ES": "ES | Spain",
  "LK": "LK | Sri Lanka",
  "SD": "SD | Sudan",
  "SR": "SR | Suriname",
  "SJ": "SJ | Svalbard And Jan Mayen",
  "SZ": "SZ | Swaziland",
  "SE": "SE | Sweden",
  "CH": "CH | Switzerland",
  "SY": "SY | Syrian Arab Republic",
  "TW": "TW | Taiwan",
  "TJ": "TJ | Tajikistan",
  "TZ": "TZ | Tanzania",
  "TH": "TH | Thailand",
  "TL": "TL | Timor-Leste",
  "TG": "TG | Togo",
  "TK": "TK | Tokelau",
  "TO": "TO | Tonga",
  "TT": "TT | Trinidad And Tobago",
  "TN": "TN | Tunisia",
  "TR": "TR | Turkey",
  "TM": "TM | Turkmenistan",
  "TC": "TC | Turks And Caicos Islands",
  "TV": "TV | Tuvalu",
  "UG": "UG | Uganda",
  "UA": "UA | Ukraine",
  "AE": "AE | United Arab Emirates",
  "GB": "GB | United Kingdom",
  "US": "US | United States",
  "UM": "UM | United States Outlying Islands",
  "UY": "UY | Uruguay",
  "UZ": "UZ | Uzbekistan",
  "VU": "VU | Vanuatu",
  "VE": "VE | Venezuela",
  "VN": "VN | Vietnam",
  "VG": "VG | Virgin Islands, British",
  "VI": "VI | Virgin Islands, U.S.",
  "WF": "WF | Wallis And Futuna",
  "EH": "EH | Western Sahara",
  "YE": "YE | Yemen",
  "ZM": "ZM | Zambia",
  "ZW": "ZW | Zimbabwe",
  "XK": "XK | Kosovo"
};
    return countries[iso];
}

const countryToIso = (iso: string) => {
    let countries: { [index: string]: string } = {
  "AF | Afghanistan": "AF",
  "AX | Aland Islands": "AX",
  "AL | Albania": "AL",
  "DZ | Algeria": "DZ",
  "AS | American Samoa": "AS",
  "AD | Andorra": "AD",
  "AO | Angola": "AO",
  "AI | Anguilla": "AI",
  "AQ | Antarctica": "AQ",
  "AG | Antigua And Barbuda": "AG",
  "AR | Argentina": "AR",
  "AM | Armenia": "AM",
  "AW | Aruba": "AW",
  "AU | Australia": "AU",
  "AT | Austria": "AT",
  "AZ | Azerbaijan": "AZ",
  "BS | Bahamas": "BS",
  "BH | Bahrain": "BH",
  "BD | Bangladesh": "BD",
  "BB | Barbados": "BB",
  "BY | Belarus": "BY",
  "BE | Belgium": "BE",
  "BZ | Belize": "BZ",
  "BJ | Benin": "BJ",
  "BM | Bermuda": "BM",
  "BT | Bhutan": "BT",
  "BO | Bolivia": "BO",
  "BA | Bosnia And Herzegovina": "BA",
  "BW | Botswana": "BW",
  "BV | Bouvet Island": "BV",
  "BR | Brazil": "BR",
  "IO | British Indian Ocean Territory": "IO",
  "BN | Brunei Darussalam": "BN",
  "BG | Bulgaria": "BG",
  "BF | Burkina Faso": "BF",
  "BI | Burundi": "BI",
  "KH | Cambodia": "KH",
  "CM | Cameroon": "CM",
  "CA | Canada": "CA",
  "CV | Cape Verde": "CV",
  "KY | Cayman Islands": "KY",
  "CF | Central African Republic": "CF",
  "TD | Chad": "TD",
  "CL | Chile": "CL",
  "CN | China": "CN",
  "CX | Christmas Island": "CX",
  "CC | Cocos (Keeling) Islands": "CC",
  "CO | Colombia": "CO",
  "KM | Comoros": "KM",
  "CG | Congo": "CG",
  "CD | Congo, Democratic Republic": "CD",
  "CK | Cook Islands": "CK",
  "CR | Costa Rica": "CR",
  "CI | Cote D\"Ivoire": "CI",
  "HR | Croatia": "HR",
  "CU | Cuba": "CU",
  "CY | Cyprus": "CY",
  "CZ | Czech Republic": "CZ",
  "DK | Denmark": "DK",
  "DJ | Djibouti": "DJ",
  "DM | Dominica": "DM",
  "DO | Dominican Republic": "DO",
  "EC | Ecuador": "EC",
  "EG | Egypt": "EG",
  "SV | El Salvador": "SV",
  "GQ | Equatorial Guinea": "GQ",
  "ER | Eritrea": "ER",
  "EE | Estonia": "EE",
  "ET | Ethiopia": "ET",
  "FK | Falkland Islands (Malvinas)": "FK",
  "FO | Faroe Islands": "FO",
  "FJ | Fiji": "FJ",
  "FI | Finland": "FI",
  "FR | France": "FR",
  "GF | French Guiana": "GF",
  "PF | French Polynesia": "PF",
  "TF | French Southern Territories": "TF",
  "GA | Gabon": "GA",
  "GM | Gambia": "GM",
  "GE | Georgia": "GE",
  "DE | Germany": "DE",
  "GH | Ghana": "GH",
  "GI | Gibraltar": "GI",
  "GR | Greece": "GR",
  "GL | Greenland": "GL",
  "GD | Grenada": "GD",
  "GP | Guadeloupe": "GP",
  "GU | Guam": "GU",
  "GT | Guatemala": "GT",
  "GG | Guernsey": "GG",
  "GN | Guinea": "GN",
  "GW | Guinea-Bissau": "GW",
  "GY | Guyana": "GY",
  "HT | Haiti": "HT",
  "HM | Heard Island & Mcdonald Islands": "HM",
  "VA | Holy See (Vatican City State)": "VA",
  "HN | Honduras": "HN",
  "HK | Hong Kong": "HK",
  "HU | Hungary": "HU",
  "IS | Iceland": "IS",
  "IN | India": "IN",
  "ID | Indonesia": "ID",
  "IR | Iran, Islamic Republic Of": "IR",
  "IQ | Iraq": "IQ",
  "IE | Ireland": "IE",
  "IM | Isle Of Man": "IM",
  "IL | Israel": "IL",
  "IT | Italy": "IT",
  "JM | Jamaica": "JM",
  "JP | Japan": "JP",
  "JE | Jersey": "JE",
  "JO | Jordan": "JO",
  "KZ | Kazakhstan": "KZ",
  "KE | Kenya": "KE",
  "KI | Kiribati": "KI",
  "KR | Korea": "KR",
  "KP | North Korea": "KP",
  "KW | Kuwait": "KW",
  "KG | Kyrgyzstan": "KG",
  "LA | Lao People\"s Democratic Republic": "LA",
  "LV | Latvia": "LV",
  "LB | Lebanon": "LB",
  "LS | Lesotho": "LS",
  "LR | Liberia": "LR",
  "LY | Libyan Arab Jamahiriya": "LY",
  "LI | Liechtenstein": "LI",
  "LT | Lithuania": "LT",
  "LU | Luxembourg": "LU",
  "MO | Macao": "MO",
  "MK | Macedonia": "MK",
  "MG | Madagascar": "MG",
  "MW | Malawi": "MW",
  "MY | Malaysia": "MY",
  "MV | Maldives": "MV",
  "ML | Mali": "ML",
  "MT | Malta": "MT",
  "MH | Marshall Islands": "MH",
  "MQ | Martinique": "MQ",
  "MR | Mauritania": "MR",
  "MU | Mauritius": "MU",
  "YT | Mayotte": "YT",
  "MX | Mexico": "MX",
  "FM | Micronesia, Federated States Of": "FM",
  "MD | Moldova": "MD",
  "MC | Monaco": "MC",
  "MN | Mongolia": "MN",
  "ME | Montenegro": "ME",
  "MS | Montserrat": "MS",
  "MA | Morocco": "MA",
  "MZ | Mozambique": "MZ",
  "MM | Myanmar": "MM",
  "NA | Namibia": "NA",
  "NR | Nauru": "NR",
  "NP | Nepal": "NP",
  "NL | Netherlands": "NL",
  "AN | Netherlands Antilles": "AN",
  "NC | New Caledonia": "NC",
  "NZ | New Zealand": "NZ",
  "NI | Nicaragua": "NI",
  "NE | Niger": "NE",
  "NG | Nigeria": "NG",
  "NU | Niue": "NU",
  "NF | Norfolk Island": "NF",
  "MP | Northern Mariana Islands": "MP",
  "NO | Norway": "NO",
  "OM | Oman": "OM",
  "PK | Pakistan": "PK",
  "PW | Palau": "PW",
  "PS | Palestinian Territory, Occupied": "PS",
  "PA | Panama": "PA",
  "PG | Papua New Guinea": "PG",
  "PY | Paraguay": "PY",
  "PE | Peru": "PE",
  "PH | Philippines": "PH",
  "PN | Pitcairn": "PN",
  "PL | Poland": "PL",
  "PT | Portugal": "PT",
  "PR | Puerto Rico": "PR",
  "QA | Qatar": "QA",
  "RE | Reunion": "RE",
  "RO | Romania": "RO",
  "RU | Russian Federation": "RU",
  "RW | Rwanda": "RW",
  "BL | Saint Barthelemy": "BL",
  "SH | Saint Helena": "SH",
  "KN | Saint Kitts And Nevis": "KN",
  "LC | Saint Lucia": "LC",
  "MF | Saint Martin": "MF",
  "PM | Saint Pierre And Miquelon": "PM",
  "VC | Saint Vincent And Grenadines": "VC",
  "WS | Samoa": "WS",
  "SM | San Marino": "SM",
  "ST | Sao Tome And Principe": "ST",
  "SA | Saudi Arabia": "SA",
  "SN | Senegal": "SN",
  "RS | Serbia": "RS",
  "SC | Seychelles": "SC",
  "SL | Sierra Leone": "SL",
  "SG | Singapore": "SG",
  "SK | Slovakia": "SK",
  "SI | Slovenia": "SI",
  "SB | Solomon Islands": "SB",
  "SO | Somalia": "SO",
  "ZA | South Africa": "ZA",
  "GS | South Georgia And Sandwich Isl.": "GS",
  "ES | Spain": "ES",
  "LK | Sri Lanka": "LK",
  "SD | Sudan": "SD",
  "SR | Suriname": "SR",
  "SJ | Svalbard And Jan Mayen": "SJ",
  "SZ | Swaziland": "SZ",
  "SE | Sweden": "SE",
  "CH | Switzerland": "CH",
  "SY | Syrian Arab Republic": "SY",
  "TW | Taiwan": "TW",
  "TJ | Tajikistan": "TJ",
  "TZ | Tanzania": "TZ",
  "TH | Thailand": "TH",
  "TL | Timor-Leste": "TL",
  "TG | Togo": "TG",
  "TK | Tokelau": "TK",
  "TO | Tonga": "TO",
  "TT | Trinidad And Tobago": "TT",
  "TN | Tunisia": "TN",
  "TR | Turkey": "TR",
  "TM | Turkmenistan": "TM",
  "TC | Turks And Caicos Islands": "TC",
  "TV | Tuvalu": "TV",
  "UG | Uganda": "UG",
  "UA | Ukraine": "UA",
  "AE | United Arab Emirates": "AE",
  "GB | United Kingdom": "GB",
  "US | United States": "US",
  "UM | United States Outlying Islands": "UM",
  "UY | Uruguay": "UY",
  "UZ | Uzbekistan": "UZ",
  "VU | Vanuatu": "VU",
  "VE | Venezuela": "VE",
  "VN | Vietnam": "VN",
  "VG | Virgin Islands, British": "VG",
  "VI | Virgin Islands, U.S.": "VI",
  "WF | Wallis And Futuna": "WF",
  "EH | Western Sahara": "EH",
  "YE | Yemen": "YE",
  "ZM | Zambia": "ZM",
  "ZW | Zimbabwe": "ZW",
  "XK | Kosovo": "XK"
};
    return countries[iso];
}

const isoToAirport = (iso: string) => {
    let airports: { [index: string]: string } = {
        "UTK": "UTK | Utirik Airport",
        "OCA": "OCA | Ocean Reef Club Airport",
        "PQS": "PQS | Pilot Station Airport",
        "CSE": "CSE | Crested Butte Airpark",
        "JCY": "JCY | LBJ Ranch Airport",
        "WLR": "WLR | Loring Seaplane Base",
        "NUP": "NUP | Nunapitchuk Airport",
        "PTC": "PTC | Port Alice Seaplane Base",
        "ICY": "ICY | Icy Bay Airport",
        "PPV": "PPV | Port Protection Seaplane Base",
        "KKK": "KKK | Kalakaket Creek AS Airport",
        "MHS": "MHS | Dunsmuir Muni-Mott Airport",
        "NIR": "NIR | Chase Field Industrial Airport",
        "GCT": "GCT | Grand Canyon Bar Ten Airstrip",
        "ELW": "ELW | Ellamar Seaplane Base",
        "LVD": "LVD | Lime Village Airport",
        "HGZ": "HGZ | Hog River Airport",
        "OTN": "OTN | Ed-Air Airport",
        "TLF": "TLF | Telida Airport",
        "BZT": "BZT | Eagle Air Park",
        "HBH": "HBH | Entrance Island Seaplane Base",
        "FAK": "FAK | False Island Seaplane Base",
        "BYW": "BYW | Blakely Island Airport",
        "DRF": "DRF | Drift River Airport",
        "BDF": "BDF | Rinkenberger Restricted Landing Area",
        "VRS": "VRS | Roy Otten Memorial Airfield",
        "GDH": "GDH | Golden Horn Lodge Seaplane Base",
        "ATT": "ATT | Atmautluak Airport",
        "LIV": "LIV | Livengood Camp Airport",
        "PDB": "PDB | Pedro Bay Airport",
        "KOZ": "KOZ | Ouzinkie Airport",
        "TNK": "TNK | Tununak Airport",
        "WHD": "WHD | Hyder Seaplane Base",
        "MNT": "MNT | Minto Al Wright Airport",
        "TKI": "TKI | Tokeen Seaplane Base",
        "WKK": "WKK | Aleknagik / New Airport",
        "WFB": "WFB | Ketchikan Harbor Seaplane Base",
        "NNK": "NNK | Naknek Airport",
        "BKF": "BKF | Lake Brooks Seaplane Base",
        "BCS": "BCS | Southern Seaplane Airport",
        "QNY": "QNY | New York Skyports Inc Seaplane Base",
        "BWL": "BWL | Earl Henry Airport",
        "WYB": "WYB | Yes Bay Lodge Seaplane Base",
        "CWS": "CWS | Center Island Airport",
        "TEK": "TEK | Tatitlek Airport",
        "DUF": "DUF | Pine Island Airport",
        "SSW": "SSW | Stuart Island Airpark",
        "FOB": "FOB | Fort Bragg Airport",
        "PUL": "PUL | Port of Poulsbo Marina Moorage Seaplane Base",
        "WMK": "WMK | Meyers Chuck Seaplane Base",
        "AXB": "AXB | Maxson Airfield",
        "REE": "REE | Reese Airpark",
        "WDN": "WDN | Waldron Airstrip",
        "WWP": "WWP | Whale Pass Seaplane Float Harbor Facility",
        "CHU": "CHU | Chuathbaluk Airport",
        "UGS": "UGS | Ugashik Airport",
        "PSQ": "PSQ | Philadelphia Seaplane Base",
        "KLL": "KLL | Levelock Airport",
        "SGW": "SGW | Saginaw Seaplane Base",
        "WTL": "WTL | Tuntutuliak Airport",
        "TWA": "TWA | Twin Hills Airport",
        "KCQ": "KCQ | Chignik Lake Airport",
        "AAD": "AAD | Adado Airport",
        "ABP": "ABP | Atkamba Airport",
        "ADC": "ADC | Andakombe Airport",
        "ADV": "ADV | El Daein Airport",
        "DJH": "DJH | Jebel Ali Seaplane Base",
        "AEE": "AEE | Adareil Airport",
        "AEK": "AEK | Aseki Airport",
        "AEQ": "AEQ | Ar Horqin Airport",
        "KHT": "KHT | Khost International Airport",
        "URZ": "URZ | Orūzgān Airport",
        "OLR": "OLR | Salerno Airport",
        "AFK": "AFK | Kondavattavana Tank Seaplane Base",
        "AFR": "AFR | Afore Airstrip",
        "AFT": "AFT | Afutara Aerodrome",
        "RNA": "RNA | Ulawa Airport",
        "ATD": "ATD | Uru Harbour Airport",
        "VEV": "VEV | Barakoma Airport",
        "GEF": "GEF | Geva Airport",
        "AGG": "AGG | Angoram Airport",
        "AKS": "AKS | Gwaunaru'u Airport",
        "BNY": "BNY | Bellona/Anua Airport",
        "CHY": "CHY | Choiseul Bay Airport",
        "BAS": "BAS | Ballalae Airport",
        "FRE": "FRE | Fera/Maringe Airport",
        "HIR": "HIR | Honiara International Airport",
        "MBU": "MBU | Babanakira Airport",
        "AVU": "AVU | Avu Avu Airport",
        "IRA": "IRA | Ngorangora Airport",
        "SCZ": "SCZ | Santa Cruz/Graciosa Bay/Luova Airport",
        "MUA": "MUA | Munda Airport",
        "GZO": "GZO | Nusatupe Airport",
        "MNY": "MNY | Mono Airport",
        "PRS": "PRS | Parasi Airport",
        "RNL": "RNL | Rennell/Tingoa Airport",
        "EGM": "EGM | Sege Airport",
        "NNB": "NNB | Santa Ana Airport",
        "RUS": "RUS | Marau Airport",
        "VAO": "VAO | Suavanao Airport",
        "XYA": "XYA | Yandina Airport",
        "AGK": "AGK | Kagua Airport",
        "KGE": "KGE | Kaghau Airport",
        "KUE": "KUE | Kukudu Airport",
        "KWS": "KWS | Kwailabesi Airport",
        "AGL": "AGL | Wanigela Airport",
        "NAZ": "NAZ | Nana Airport",
        "GTA": "GTA | Gatokae Aerodrome",
        "RIN": "RIN | Ringi Cove Airport",
        "RBV": "RBV | Ramata Airport",
        "AGY": "AGY | Argyle Downs Airport",
        "AHJ": "AHJ | Hongyuan Airport",
        "AHY": "AHY | Ambatolhy Airport",
        "AIE": "AIE | Aiome Airport",
        "AIH": "AIH | Aiambak Airport",
        "AIC": "AIC | Ailinglaplap Airok Airport",
        "CEX": "CEX | Chena Hot Springs Airport",
        "SOL": "SOL | Solomon State Field",
        "HED": "HED | Herendeen Bay Airport",
        "TWE": "TWE | Taylor Airport",
        "KTH": "KTH | Tikchik Lodge Seaplane Base",
        "NKI": "NKI | Naukati Bay Seaplane Base",
        "LNI": "LNI | Point Lonely Short Range Radar Site Airfield",
        "CDL": "CDL | Candle 2 Airport",
        "AOS": "AOS | Amook Bay Seaplane Base",
        "BSW": "BSW | Boswell Bay Airport",
        "AKM": "AKM | Zakuoma Airport",
        "TGE": "TGE | Sharpe Field",
        "ALZ": "ALZ | Alitak Seaplane Base",
        "PPE": "PPE | Mar de Cortés International Airport",
        "AME": "AME | Alto Molocue Airport",
        "AMF": "AMF | Ama Airport",
        "AMU": "AMU | Amanab Airport",
        "AMY": "AMY | Ambatomainty Airport",
        "INU": "INU | Nauru International Airport",
        "ANZ": "ANZ | Angus Downs Airport",
        "CTV": "CTV | Catoca Airport",
        "ANL": "ANL | Andulo Airport",
        "CNZ": "CNZ | Cangamba Airport",
        "DRC": "DRC | Dirico Airport",
        "JMB": "JMB | Jamba Airport",
        "KNP": "KNP | Capanda Airport",
        "NDF": "NDF | N'dalatando Airport",
        "AOB": "AOB | Annanberg Airport",
        "AOD": "AOD | Abou-Deïa Airport",
        "APP": "APP | Asapa Airport",
        "APR": "APR | April River Airport",
        "QET": "QET | Troll Airfield",
        "AQY": "AQY | Girdwood Airport",
        "QRF": "QRF | Bragado Airport",
        "CVI": "CVI | Caleta Olivia Airport",
        "CNT": "CNT | Charata Airport",
        "VGS": "VGS | General Villegas Airport",
        "LMD": "LMD | Los Menucos Airport",
        "VCF": "VCF | Valcheta Airport",
        "NCJ": "NCJ | Sunchales Aeroclub Airport",
        "CPG": "CPG | Carmen de Patagones Airport",
        "PRQ": "PRQ | Termal Airport",
        "OLN": "OLN | Colonia Sarmiento Airport",
        "ARP": "ARP | Aragip Airport",
        "ASZ": "ASZ | Asirim Airport",
        "ATN": "ATN | Namatanai Airport",
        "ATP": "ATP | Aitape Airport",
        "LYT": "LYT | Lady Elliot Island Airstrip",
        "AGW": "AGW | Agnew Airport",
        "AYD": "AYD | Alroy Downs Airport",
        "BYX": "BYX | Baniyala Airport",
        "COB": "COB | Coolibah Airport",
        "CRJ": "CRJ | Coorabie Airport",
        "CRY": "CRY | Carlton Hill Airport",
        "CSD": "CSD | Cresswell Downs Airport",
        "DYM": "DYM | Diamantina Lakes Airport",
        "HIS": "HIS | Hayman Island Resort Seaplane Base",
        "HLV": "HLV | Helenvale Airport",
        "KBD": "KBD | Kimberley Downs Airport",
        "KGR": "KGR | Kulgera Airport",
        "MWY": "MWY | Miranda Downs Airport",
        "MYO": "MYO | Camballin Airport",
        "OKB": "OKB | Orchid Beach Airport",
        "PEP": "PEP | Peppimenarti Airport",
        "RDA": "RDA | Rockhampton Downs Airport",
        "SSK": "SSK | Sturt Creek Airport",
        "SWB": "SWB | Shaw River Airport",
        "TPR": "TPR | Tom Price Airport",
        "TWP": "TWP | Torwood Airport",
        "ZVG": "ZVG | Springvale Airport",
        "AUI": "AUI | Aua Island Airport",
        "AUJ": "AUJ | Ambunti Airport",
        "AUP": "AUP | Agaun Airport",
        "AUV": "AUV | Aumo Airport",
        "AWE": "AWE | Alowe Airport",
        "AXF": "AXF | Alxa Left Banner Bayanhot Airport",
        "KPM": "KPM | Kompiam Airport",
        "BUA": "BUA | Buka Airport",
        "BRP": "BRP | Biaru Airport",
        "CMU": "CMU | Chimbu Airport",
        "MDM": "MDM | Munduku Airport",
        "KPF": "KPF | Kondobol Airport",
        "DNU": "DNU | Dinangat Airport",
        "DOI": "DOI | Doini Airport",
        "DAU": "DAU | Daru Airport",
        "EMS": "EMS | Embessa Airport",
        "XYR": "XYR | Edwaki Airport",
        "EPT": "EPT | Eliptamin Airport",
        "EGA": "EGA | Engati Airstrip",
        "EMO": "EMO | Emo River Airstrip",
        "ERU": "ERU | Erume Airport",
        "MFZ": "MFZ | Meselia Airport",
        "FRQ": "FRQ | Feramin Airport",
        "FAQ": "FAQ | Frieda River Airport",
        "FUM": "FUM | Fuma Airport",
        "GKA": "GKA | Goroka Airport",
        "GUG": "GUG | Guari Airport",
        "GRL": "GRL | Garasa Airport",
        "GUR": "GUR | Gurney Airport",
        "GAP": "GAP | Gusap Airport",
        "PNP": "PNP | Girua Airport",
        "GBC": "GBC | Gasuke Airport",
        "HBD": "HBD | Habi Airport",
        "HNI": "HNI | Heiweni Airport",
        "HNN": "HNN | Honinabi Airport",
        "HKN": "HKN | Kimbe Airport",
        "HIT": "HIT | Haivaro Airport",
        "IMN": "IMN | Imane Airport",
        "KGM": "KGM | Kungim Airport",
        "IMD": "IMD | Imonda Airport",
        "KIE": "KIE | Aropa Airport",
        "IAL": "IAL | Ialibu Airport",
        "WIU": "WIU | Witu Airport",
        "KGH": "KGH | Yongai Airport",
        "LSA": "LSA | Losuia Airport",
        "KPA": "KPA | Kopiago Airport",
        "UNG": "UNG | Kiunga Airport",
        "KNE": "KNE | Kanainj Airport",
        "KRI": "KRI | Kikori Airport",
        "KMA": "KMA | Kerema Airport",
        "KRX": "KRX | Kar Kar Airport",
        "KUQ": "KUQ | Kuri Airport",
        "KVG": "KVG | Kavieng Airport",
        "LNV": "LNV | Londolovit Airport",
        "LAB": "LAB | Lab Lab Airport",
        "LWI": "LWI | Lowai Airport",
        "LPN": "LPN | Leron Plains Airport",
        "LNG": "LNG | Lese Airport",
        "LSJ": "LSJ | Long Island Airport",
        "AYM": "AYM | Yas Island Seaplane Base",
        "MRM": "MRM | Manari Airport",
        "OBM": "OBM | Morobe Airport",
        "MAG": "MAG | Madang Airport",
        "HGU": "HGU | Mount Hagen Kagamuga Airport",
        "GUV": "GUV | Mougulu Airport",
        "MDU": "MDU | Mendi Airport",
        "MAS": "MAS | Momote Airport",
        "MXH": "MXH | Moro Airport",
        "MIS": "MIS | Misima Island Airport",
        "MWG": "MWG | Marawaka Airport",
        "NKN": "NKN | Nankina Airport",
        "GBF": "GBF | Negarbo(Negabo) Airport",
        "MFO": "MFO | Manguna Airport",
        "KSB": "KSB | Kasonombe Airport",
        "NMN": "NMN | Nomane Airport",
        "NBA": "NBA | Nambaiyufa Airport",
        "LAE": "LAE | Nadzab Airport",
        "KGB": "KGB | Konge Airport",
        "OKP": "OKP | Oksapmin Airport",
        "HOC": "HOC | Komako Airport",
        "KCJ": "KCJ | Komaio Airport",
        "KDE": "KDE | Koroba Airport",
        "OPB": "OPB | Open Bay Airport",
        "PGB": "PGB | Pangoa Airport",
        "PGN": "PGN | Pangia Airport",
        "MPF": "MPF | Mapoda Airport",
        "PMN": "PMN | Pumani Airport",
        "POM": "POM | Port Moresby Jacksons International Airport",
        "SPH": "SPH | Sopu Airport",
        "SXA": "SXA | Sialum Airport",
        "RMN": "RMN | Rumginae Airport",
        "KMR": "KMR | Karimui Airport",
        "MWI": "MWI | Maramuni Airport",
        "MRH": "MRH | May River Airstrip",
        "SBE": "SBE | Suabi Airport",
        "NIS": "NIS | Simberi Airport",
        "SIL": "SIL | Sila Airport",
        "SBV": "SBV | Sabah Airport",
        "SIM": "SIM | Simbai Airport",
        "SBC": "SBC | Selbang Airport",
        "SPV": "SPV | Sepik Plains Airport",
        "SXW": "SXW | Sauren Airport",
        "MBV": "MBV | Masa Airport",
        "TIZ": "TIZ | Tari Airport",
        "TBG": "TBG | Tabubil Airport",
        "TPI": "TPI | Tapini Airport",
        "RAB": "RAB | Tokua Airport",
        "TKW": "TKW | Tekin Airport",
        "TEP": "TEP | Tep Tep Airport",
        "TSW": "TSW | Tsewi Airport",
        "TRJ": "TRJ | Tarakbits Airport",
        "TWY": "TWY | Tawa Airport",
        "TKB": "TKB | Tekadu Airport",
        "AYU": "AYU | Aiyura Airport",
        "UMC": "UMC | Umba Airport",
        "URU": "URU | Uroubi Airport",
        "UPR": "UPR | Upiara Airport",
        "UVO": "UVO | Uvol Airport",
        "TLW": "TLW | Talasea Airport",
        "TCJ": "TCJ | Torembi Airport",
        "VAI": "VAI | Vanimo Airport",
        "TON": "TON | Tonu Airport",
        "WAO": "WAO | Wabo Airport",
        "WBM": "WBM | Wapenamanda Airport",
        "WAJ": "WAJ | Wawoi Falls Airport",
        "WWK": "WWK | Wewak International Airport",
        "WOA": "WOA | Wonenara Airport",
        "WKN": "WKN | Wakunai Airport",
        "WSU": "WSU | Wasu Airport",
        "WTP": "WTP | Woitape Airport",
        "WUG": "WUG | Wau Airport",
        "YVD": "YVD | Yeva Airport",
        "SMJ": "SMJ | Sim Airport",
        "WEP": "WEP | Weam Airport",
        "AYY": "AYY | Arugam Bay SPB",
        "KYX": "KYX | Yalumet Airport",
        "KSX": "KSX | Yasuru Airport",
        "WUM": "WUM | Wasum Airport",
        "ZXT": "ZXT | Zabrat Airport",
        "AZB": "AZB | Amazon Bay Airport",
        "AZJ": "AZJ | Dalu Airport",
        "BAJ": "BAJ | Bali Airport",
        "BKG": "BKG | Branson Airport",
        "BCP": "BCP | Bambu Airport",
        "BCW": "BCW | Benguera Island Airport",
        "BCZ": "BCZ | Milyakburra Airport",
        "ILL": "ILL | Willmar Municipal -John L Rice Field",
        "BDZ": "BDZ | Baindoung Airport",
        "HKV": "HKV | Malevo Airport",
        "JAM": "JAM | Bezmer Air Base",
        "JEG": "JEG | Aasiaat Airport",
        "UAK": "UAK | Narsarsuaq Airport",
        "CNP": "CNP | Neerlerit Inaat Airport",
        "GOH": "GOH | Godthaab / Nuuk Airport",
        "JAV": "JAV | Ilulissat Airport",
        "KUS": "KUS | Kulusuk Airport",
        "JSU": "JSU | Maniitsoq Airport",
        "JFR": "JFR | Paamiut Airport",
        "NAQ": "NAQ | Qaanaaq Airport",
        "SFJ": "SFJ | Kangerlussuaq Airport",
        "JHS": "JHS | Sisimiut Airport",
        "THU": "THU | Thule Air Base",
        "JUV": "JUV | Upernavik Airport",
        "JQA": "JQA | Qaarsut Airport",
        "BHL": "BHL | Bahía de los Ángeles Airport",
        "BHT": "BHT | Brighton Downs Airport",
        "AEY": "AEY | Akureyri Airport",
        "BIU": "BIU | Bildudalur Airport",
        "BGJ": "BGJ | Borgarfjörður eystri Airport",
        "BJD": "BJD | Bakkafjörður Airport",
        "BLO": "BLO | Hjaltabakki Airport",
        "BQD": "BQD | Búðardalur Airport",
        "BXV": "BXV | Breiðdalsvík Airport",
        "DJU": "DJU | Djúpivogur Airport",
        "EGS": "EGS | Egilsstaðir Airport",
        "FAS": "FAS | Fáskrúðsfjörður Airport",
        "FAG": "FAG | Fagurhólsmýri Airport",
        "GUU": "GUU | Grundarfjörður Airport",
        "GJR": "GJR | Gjögur Airport",
        "GRY": "GRY | Grímsey Airport",
        "HVK": "HVK | Hólmavík Airport",
        "HFN": "HFN | Hornafjörður Airport",
        "FLI": "FLI | Holt Airport",
        "HZK": "HZK | Húsavík Airport",
        "HVM": "HVM | Krókstaðarmelar Airport",
        "HLO": "HLO | Ingjaldssanður Airport",
        "IFJ": "IFJ | Ísafjörður Airport",
        "KEF": "KEF | Keflavik International Airport",
        "OPA": "OPA | Kópasker Airport",
        "SAK": "SAK | Sauðárkrókur Airport",
        "NOR": "NOR | Norðfjörður Airport",
        "OFJ": "OFJ | Ólafsfjörður Airport",
        "PFJ": "PFJ | Patreksfjörður Airport",
        "RHA": "RHA | Reykhólar Airport",
        "OLI": "OLI | Rif Airport",
        "RFN": "RFN | Raufarhöfn Airport",
        "RKV": "RKV | Reykjavik Airport",
        "MVA": "MVA | Reykjahlíð Airport",
        "SIJ": "SIJ | Siglufjörður Airport",
        "SYK": "SYK | Stykkishólmur Airport",
        "TEY": "TEY | Þingeyri Airport",
        "THO": "THO | Þórshöfn Airport",
        "VEY": "VEY | Vestmannaeyjar Airport",
        "VPN": "VPN | Vopnafjörður Airport",
        "BJE": "BJE | Baleela Airport",
        "BJQ": "BJQ | Bahja Airport",
        "BJT": "BJT | Bentota River Waterdrome",
        "PRN": "PRN | Priština Adem Jashari International Airport",
        "BMH": "BMH | Bomai Airport",
        "BMQ": "BMQ | Bamburi Airport",
        "BMZ": "BMZ | Bamu Airport",
        "BNF": "BNF | Baranof Warm Springs Float and Seaplane Base",
        "BNM": "BNM | Bodinumu Airport",
        "BNT": "BNT | Bundi Airport",
        "RBQ": "RBQ | Rurenabaque Airport",
        "BVL": "BVL | Baures Airport",
        "BOK": "BOK | Brookings Airport",
        "BOT": "BOT | Bosset Airport",
        "BOV": "BOV | Boang Airport",
        "BPF": "BPF | Batuna Aerodrome",
        "BQV": "BQV | Bartlett Cove Seaplane Base",
        "VDC": "VDC | Glauber de Andrade Rocha Airport",
        "ALT": "ALT | Alenquer Airport",
        "NSB": "NSB | Bimini North Seaplane Base",
        "BSP": "BSP | Bensbach Airport",
        "BSV": "BSV | Besakoa Airport",
        "BUL": "BUL | Bulolo Airport",
        "BVR": "BVR | Esperadinha Airport",
        "HUK": "HUK | Hukuntsi Airport",
        "BWJ": "BWJ | Bawan Airport",
        "BWP": "BWP | Bewani Airport",
        "BXL": "BXL | Blue Lagoon Seaplane Base",
        "BXZ": "BXZ | Bunsil Airport",
        "BYA": "BYA | Boundary Airport",
        "BYL": "BYL | Bella Yella Airport",
        "BYV": "BYV | Beira Lake Seaplane Base",
        "BCV": "BCV | Hector Silva Airstrip",
        "BGK": "BGK | Big Creek Airport",
        "CUK": "CUK | Caye Caulker Airport",
        "CYC": "CYC | Caye Chapel Airport",
        "CZH": "CZH | Corozal Municipal Airport",
        "DGA": "DGA | Dangriga Airport",
        "INB": "INB | Independence Airport",
        "MDB": "MDB | Melinda Airport",
        "ORZ": "ORZ | Orange Walk Airport",
        "PLJ": "PLJ | Placencia Airport",
        "PND": "PND | Punta Gorda Airport",
        "SJX": "SJX | Sartaneja Airport",
        "SPR": "SPR | John Greif II Airport",
        "SQS": "SQS | Matthew Spain Airport",
        "STU": "STU | Santa Cruz Airport",
        "SVK": "SVK | Silver Creek Airport",
        "TZA": "TZA | Sir Barry Bowen Municipal Airport",
        "BZB": "BZB | Bazaruto Island Airport",
        "BZM": "BZM | Bemolanga Airport",
        "YRR": "YRR | Stuart Island Airstrip",
        "YMV": "YMV | Mary River Aerodrome",
        "YBW": "YBW | Bedwell Harbour Seaplane Base",
        "YTP": "YTP | Tofino Harbour Seaplane Base",
        "ZNA": "ZNA | Nanaimo Harbour Water Airport",
        "YZZ": "YZZ | Trail Airport",
        "YMB": "YMB | Merritt Airport",
        "YHH": "YHH | Campbell River Seaplane Base",
        "YWS": "YWS | Whistler/Green Lake Water Aerodrome",
        "YBF": "YBF | Bamfield Seaplane Base",
        "YIG": "YIG | Big Bay Seaplane Base",
        "CJH": "CJH | Chilko Lake (Tsylos Park Lodge) Airport",
        "YPT": "YPT | Pender Harbour Seaplane Base",
        "ZOF": "ZOF | Ocean Falls Seaplane Base",
        "YCA": "YCA | Courtenay Airpark",
        "CFQ": "CFQ | Creston Valley Regional Airport - Art Sutcliffe Field",
        "YAA": "YAA | Anahim Lake Airport",
        "DGF": "DGF | Douglas Lake Airport",
        "JHL": "JHL | Fort MacKay/Albian Aerodrome",
        "ZTS": "ZTS | Tahsis Seaplane Base",
        "DUQ": "DUQ | Duncan Airport",
        "YHS": "YHS | Sechelt-Gibsons Airport",
        "YKK": "YKK | Kitkatla Seaplane Base",
        "WPL": "WPL | Powell Lake Seaplane Base",
        "XQU": "XQU | Qualicum Beach Airport",
        "YMP": "YMP | Port Mcneill Airport",
        "YRN": "YRN | Rivers Inlet Seaplane Base",
        "YTG": "YTG | Sullivan Bay Seaplane Base",
        "YMU": "YMU | Mansons Landing Seaplane Base",
        "YSX": "YSX | Bella Bella/Shearwater Seaplane Base",
        "YGG": "YGG | Ganges Seaplane Base",
        "YTB": "YTB | Hartley Bay Seaplane Base",
        "YZA": "YZA | Cache Creek-Ashcroft Regional Airport",
        "ZEL": "ZEL | Bella Bella (Campbell Island) Airport",
        "CBC": "CBC | Cherrabun Airport",
        "YPB": "YPB | Alberni Valley Regional Airport",
        "YBO": "YBO | Bob Quinn Lake Airport",
        "TNS": "TNS | Tungsten (Cantung) Airport",
        "TUX": "TUX | Tumbler Ridge Airport",
        "YWM": "YWM | Williams Harbour Airport",
        "YSO": "YSO | Postville Airport",
        "YBI": "YBI | Black Tickle Airport",
        "YFX": "YFX | St. Lewis (Fox Harbour) Airport",
        "YHA": "YHA | Port Hope Simpson Airport",
        "YRG": "YRG | Rigolet Airport",
        "DVK": "DVK | Diavik Airport",
        "JOJ": "JOJ | Doris Lake",
        "ZFW": "ZFW | Fairview Airport",
        "YJP": "YJP | Hinton/Jasper-Hinton Airport",
        "YLE": "YLE | Whatì Airport",
        "YDC": "YDC | Drayton Valley Industrial Airport",
        "NML": "NML | Fort McMurray / Mildred Lake Airport",
        "CFM": "CFM | Conklin (Leismer) Airport",
        "ZSP": "ZSP | St. Paul Airport",
        "GSL": "GSL | Taltheilei Narrows Airport",
        "XMP": "XMP | Macmillan Pass Airport",
        "DAS": "DAS | Great Bear Lake Airport",
        "YFI": "YFI | Fort Mackay / Firebag",
        "YFJ": "YFJ | Wekweètì Airport",
        "YOE": "YOE | Donnelly Airport",
        "TIL": "TIL | Cheadle Airport",
        "OKG": "OKG | Okoyo Airport",
        "CGA": "CGA | Craig Seaplane Base",
        "CGC": "CGC | Cape Gloucester Airport",
        "CGG": "CGG | Casiguran Airport",
        "GHK": "GHK | Gahcho Kue",
        "CHP": "CHP | Circle Hot Springs Airport",
        "CIV": "CIV | Chomley Seaplane Base",
        "LRQ": "LRQ | Laurie River Airport",
        "YDJ": "YDJ | Hatchet Lake Airport",
        "YDU": "YDU | Kasba Lake Airport",
        "YFL": "YFL | Fort Reliance Seaplane Base",
        "XCL": "XCL | Cluff Lake Airport",
        "YKE": "YKE | Knee Lake Airport",
        "SUR": "SUR | Summer Beaver Airport",
        "CKD": "CKD | Crooked Creek Airport",
        "YTT": "YTT | Tisdale Airport",
        "YAX": "YAX | Wapekeka Airport",
        "WNN": "WNN | Wunnumin Lake Airport",
        "YBS": "YBS | Opapimiskan Lake Airport",
        "YNO": "YNO | North Spirit Lake Airport",
        "CKR": "CKR | Crane Island Airstrip",
        "CKU": "CKU | Cordova Municipal Airport",
        "YDW": "YDW | North of Sixty Airport",
        "CKX": "CKX | Chicken Airport",
        "CMT": "CMT | New Cametá Airport",
        "CMZ": "CMZ | Caia Airport",
        "TVS": "TVS | Tangshan Sannühe Airport",
        "YUA": "YUA | Yuanmou Air Base",
        "ZQZ": "ZQZ | Zhangjiakou Ningyuan Airport",
        "BSD": "BSD | Baoshan Yunrui Airport",
        "DZU": "DZU | Dazu Air Base",
        "LNJ": "LNJ | Lincang Airport",
        "RKZ": "RKZ | Shigatse Air Base",
        "PZI": "PZI | Bao'anying Airport",
        "ZKL": "ZKL | Zigong Airport",
        "FUO": "FUO | Foshan Shadi Airport",
        "BZX": "BZX | Bazhong Enyang Airport",
        "XAI": "XAI | Xinyang Minggang Airport",
        "HUZ": "HUZ | Huizhou Pingtan Airport",
        "HSC": "HSC | Shaoguan Guitou Airport",
        "JGS": "JGS | Jinggangshan Airport",
        "AEB": "AEB | Baise Youjiang Airport",
        "DOY": "DOY | Dongying Shengli Airport",
        "XEN": "XEN | Xingcheng Air Base",
        "AAT": "AAT | Altay Airport",
        "THQ": "THQ | Tianshui Maijishan Airport",
        "YZY": "YZY | Zhangye Ganzhou Airport",
        "DWS": "DWS | Morin Dawa Banner Airport",
        "LCT": "LCT | Shijiazhuang Luancheng Airport",
        "NAY": "NAY | Beijing Nanjiao Airport",
        "DDG": "DDG | Dandong Langtou Airport",
        "NTG": "NTG | Nantong Airport",
        "XBE": "XBE | Bearskin Lake Airport",
        "YNP": "YNP | Natuashish Airport",
        "YWR": "YWR | White River Seaplane Base",
        "YPD": "YPD | Parry Sound Area Municipal Airport",
        "XBR": "XBR | Brockville - Thousand Islands Regional Tackaberry Airport",
        "KIF": "KIF | Kingfisher Lake Airport",
        "YOG": "YOG | Ogoki Post Airport",
        "ARQ": "ARQ | El Troncal Airport",
        "LCR": "LCR | La Chorrera Airport",
        "SNT": "SNT | Las Cruces Airport",
        "TCD": "TCD | Tarapacá Airport",
        "YEB": "YEB | Bar River Airport",
        "CPI": "CPI | Cape Orford Airport",
        "YSI": "YSI | Parry Sound/Frying Pan Island-Sans Souci Seaplane Base",
        "YHP": "YHP | Poplar Hill Airport",
        "KEW": "KEW | Keewaywin Airport",
        "KFM": "KFM | Kirby Lake Airport",
        "YSA": "YSA | Sable Island Landing Strip",
        "YLS": "YLS | Lebel-sur-Quevillon Airport",
        "YNX": "YNX | Snap Lake Airport",
        "SSQ": "SSQ | La Sarre Airport",
        "YKU": "YKU | Chisasibi Airport",
        "ZTB": "ZTB | Tête-à-la-Baleine Airport",
        "ZKG": "ZKG | Kegaska Airport",
        "YWQ": "YWQ | Chute-Des-Passes/Lac Margane Seaplane Base",
        "YAU": "YAU | Donaldson Airport",
        "TR7": "TR7 | Ottawa / Rockcliffe Seaplane Base",
        "ZGS": "ZGS | La Romaine Airport",
        "YFG": "YFG | Fontanges Airport",
        "ZLT": "ZLT | La Tabatière Airport",
        "CUJ": "CUJ | Culion Airport",
        "HLI": "HLI | Hollister Municipal Airport",
        "CVL": "CVL | Cape Vogel Airport",
        "CXC": "CXC | Chitina Airport",
        "GEC": "GEC | Lefkoniko Airport / Geçitkale Air Base",
        "YAB": "YAB | Arctic Bay Airport",
        "YAC": "YAC | Cat Lake Airport",
        "YAR": "YAR | La Grande-3 Airport",
        "YAG": "YAG | Fort Frances Municipal Airport",
        "YAH": "YAH | La Grande-4 Airport",
        "YAL": "YAL | Alert Bay Airport",
        "YAM": "YAM | Sault Ste Marie Airport",
        "XKS": "XKS | Kasabonika Airport",
        "YKG": "YKG | Kangirsuk Airport",
        "YAT": "YAT | Attawapiskat Airport",
        "YAY": "YAY | St. Anthony Airport",
        "YAZ": "YAZ | Tofino / Long Beach Airport",
        "YBA": "YBA | Banff Airport",
        "YBB": "YBB | Kugaaruk Airport",
        "YBC": "YBC | Baie-Comeau Airport",
        "QBC": "QBC | Bella Coola Airport",
        "YBE": "YBE | Uranium City Airport",
        "YBY": "YBY | Bonnyville Airport",
        "YBG": "YBG | CFB Bagotville",
        "YBK": "YBK | Baker Lake Airport",
        "YBL": "YBL | Campbell River Airport",
        "XTL": "XTL | Tadoule Lake Airport",
        "YBR": "YBR | Brandon Municipal Airport",
        "YBT": "YBT | Brochet Airport",
        "YBV": "YBV | Berens River Airport",
        "YBX": "YBX | Lourdes de Blanc Sablon Airport",
        "YRF": "YRF | Cartwright Airport",
        "YCB": "YCB | Cambridge Bay Airport",
        "YCC": "YCC | Cornwall Regional Airport",
        "YCD": "YCD | Nanaimo Airport",
        "YCE": "YCE | Centralia / James T. Field Memorial Aerodrome",
        "YCG": "YCG | Castlegar/West Kootenay Regional Airport",
        "YCH": "YCH | Miramichi Airport",
        "XCM": "XCM | Chatham Kent Airport",
        "YCL": "YCL | Charlo Airport",
        "YCN": "YCN | Cochrane Airport",
        "YCO": "YCO | Kugluktuk Airport",
        "YCQ": "YCQ | Chetwynd Airport",
        "YCR": "YCR | Cross Lake (Charlie Sinclair Memorial) Airport",
        "YCS": "YCS | Chesterfield Inlet Airport",
        "YCT": "YCT | Coronation Airport",
        "YCW": "YCW | Chilliwack Airport",
        "YCY": "YCY | Clyde River Airport",
        "YCZ": "YCZ | Fairmont Hot Springs Airport",
        "YDA": "YDA | Dawson City Airport",
        "YDB": "YDB | Burwash Airport",
        "YDF": "YDF | Deer Lake Airport",
        "YDL": "YDL | Dease Lake Airport",
        "XRR": "XRR | Ross River Airport",
        "YDN": "YDN | Dauphin Barker Airport",
        "YDO": "YDO | Dolbeau St Felicien Airport",
        "YDP": "YDP | Nain Airport",
        "YDQ": "YDQ | Dawson Creek Airport",
        "YEG": "YEG | Edmonton International Airport",
        "YEK": "YEK | Arviat Airport",
        "YEL": "YEL | Elliot Lake Municipal Airport",
        "YEM": "YEM | Manitoulin East Municipal Airport",
        "YEN": "YEN | Estevan Airport",
        "YER": "YER | Fort Severn Airport",
        "YET": "YET | Edson Airport",
        "YEU": "YEU | Eureka Airport",
        "YEV": "YEV | Inuvik Mike Zubko Airport",
        "YEY": "YEY | Amos/Magny Airport",
        "YFA": "YFA | Fort Albany Airport",
        "YFB": "YFB | Iqaluit Airport",
        "YFC": "YFC | Fredericton Airport",
        "YFE": "YFE | Forestville Airport",
        "YFH": "YFH | Fort Hope Airport",
        "YTM": "YTM | La Macaza / Mont-Tremblant International Inc Airport",
        "YFO": "YFO | Flin Flon Airport",
        "YFR": "YFR | Fort Resolution Airport",
        "YFS": "YFS | Fort Simpson Airport",
        "YMN": "YMN | Makkovik Airport",
        "YGB": "YGB | Texada Gillies Bay Airport",
        "YGH": "YGH | Fort Good Hope Airport",
        "YGK": "YGK | Kingston Norman Rogers Airport",
        "YGL": "YGL | La Grande Rivière Airport",
        "YGM": "YGM | Gimli Industrial Park Airport",
        "YGO": "YGO | Gods Lake Narrows Airport",
        "YGP": "YGP | Gaspé (Michel-Pouliot) Airport",
        "YGQ": "YGQ | Geraldton Greenstone Regional Airport",
        "YGR": "YGR | Îles-de-la-Madeleine Airport",
        "YGT": "YGT | Igloolik Airport",
        "YGV": "YGV | Havre-Saint-Pierre Airport",
        "YGW": "YGW | Kuujjuarapik Airport",
        "YGX": "YGX | Gillam Airport",
        "YGZ": "YGZ | Grise Fiord Airport",
        "YQC": "YQC | Quaqtaq Airport",
        "YHB": "YHB | Hudson Bay Airport",
        "CXH": "CXH | Vancouver Harbour Water Aerodrome",
        "YHD": "YHD | Dryden Regional Airport",
        "YHE": "YHE | Hope Airport",
        "YHF": "YHF | Hearst René Fontaine Municipal Airport",
        "YNS": "YNS | Nemiscau Airport",
        "YHI": "YHI | Ulukhaktok Holman Airport",
        "YHK": "YHK | Gjoa Haven Airport",
        "YHM": "YHM | John C. Munro Hamilton International Airport",
        "YHN": "YHN | Hornepayne Municipal Airport",
        "YHO": "YHO | Hopedale Airport",
        "YHR": "YHR | Chevery Airport",
        "YHT": "YHT | Haines Junction Airport",
        "YHU": "YHU | Montréal / Saint-Hubert Airport",
        "YHY": "YHY | Hay River / Merlyn Carter Airport",
        "YHZ": "YHZ | Halifax / Stanfield International Airport",
        "YIB": "YIB | Atikokan Municipal Airport",
        "YDG": "YDG | Digby / Annapolis Regional Airport",
        "YIF": "YIF | St Augustin Airport",
        "YIK": "YIK | Ivujivik Airport",
        "YIO": "YIO | Pond Inlet Airport",
        "YIV": "YIV | Island Lake Airport",
        "YJA": "YJA | Jasper Airport",
        "YJF": "YJF | Fort Liard Airport",
        "YJN": "YJN | St Jean Airport",
        "YJT": "YJT | Stephenville Airport",
        "YKA": "YKA | Kamloops Airport",
        "YKC": "YKC | Collins Bay Airport",
        "LAK": "LAK | Aklavik/Freddie Carmichael Airport",
        "YKF": "YKF | Waterloo Airport",
        "YWB": "YWB | Kangiqsujuaq (Wakeham Bay) Airport",
        "YKJ": "YKJ | Key Lake Airport",
        "YKL": "YKL | Schefferville Airport",
        "YKD": "YKD | Kincardine Municipal Airport",
        "AKV": "AKV | Akulivik Airport",
        "YKQ": "YKQ | Waskaganish Airport",
        "YKX": "YKX | Kirkland Lake Airport",
        "YKY": "YKY | Kindersley Airport",
        "YKZ": "YKZ | Buttonville Municipal Airport",
        "YPJ": "YPJ | Aupaluk Airport",
        "YLB": "YLB | Lac La Biche Airport",
        "YLC": "YLC | Kimmirut Airport",
        "YLD": "YLD | Chapleau Airport",
        "YLH": "YLH | Lansdowne House Airport",
        "YLJ": "YLJ | Meadow Lake Airport",
        "YSG": "YSG | Lutselk'e Airport",
        "YLL": "YLL | Lloydminster Airport",
        "YLQ": "YLQ | La Tuque Airport",
        "YLR": "YLR | Leaf Rapids Airport",
        "YLK": "YLK | Barrie-Orillia (Lake Simcoe Regional Airport)",
        "YLT": "YLT | Alert Airport",
        "XGR": "XGR | Kangiqsualujjuaq (Georges River) Airport",
        "YLW": "YLW | Kelowna International Airport",
        "CYM": "CYM | Chatham Seaplane Base",
        "YMA": "YMA | Mayo Airport",
        "YME": "YME | Matane Airport",
        "YMG": "YMG | Manitouwadge Airport",
        "YMH": "YMH | Mary's Harbour Airport",
        "YMJ": "YMJ | Moose Jaw Air Vice Marshal C. M. McEwen Airport",
        "YML": "YML | Charlevoix Airport",
        "YMM": "YMM | Fort McMurray Airport",
        "YMO": "YMO | Moosonee Airport",
        "YMT": "YMT | Chapais Airport",
        "YUD": "YUD | Umiujaq Airport",
        "YMW": "YMW | Maniwaki Airport",
        "YMX": "YMX | Montreal International (Mirabel) Airport",
        "YNA": "YNA | Natashquan Airport",
        "YNC": "YNC | Wemindji Airport",
        "YND": "YND | Ottawa / Gatineau Airport",
        "YNE": "YNE | Norway House Airport",
        "YNH": "YNH | Hudsons Hope Airport",
        "YLY": "YLY | Langley Airport",
        "YNL": "YNL | Points North Landing Airport",
        "YNM": "YNM | Matagami Airport",
        "HZP": "HZP | Fort Mackay / Horizon Airport",
        "YOA": "YOA | Ekati Airport",
        "YOC": "YOC | Old Crow Airport",
        "YOD": "YOD | CFB Cold Lake",
        "YOH": "YOH | Oxford House Airport",
        "YOJ": "YOJ | High Level Airport",
        "YOO": "YOO | Toronto/Oshawa Executive Airport",
        "YOP": "YOP | Rainbow Lake Airport",
        "YOS": "YOS | Owen Sound / Billy Bishop Regional Airport",
        "YOW": "YOW | Ottawa Macdonald-Cartier International Airport",
        "YPA": "YPA | Prince Albert Glass Field",
        "YPC": "YPC | Paulatuk (Nora Aliqatchialuk Ruben) Airport",
        "YPS": "YPS | Port Hawkesbury Airport",
        "YPE": "YPE | Peace River Airport",
        "YPG": "YPG | Portage-la-Prairie / Southport Airport",
        "YPH": "YPH | Inukjuak Airport",
        "YPL": "YPL | Pickle Lake Airport",
        "YPM": "YPM | Pikangikum Airport",
        "YPN": "YPN | Port Menier Airport",
        "YPO": "YPO | Peawanuck Airport",
        "YPQ": "YPQ | Peterborough Airport",
        "YPR": "YPR | Prince Rupert Airport",
        "YPW": "YPW | Powell River Airport",
        "YPX": "YPX | Puvirnituq Airport",
        "YPY": "YPY | Fort Chipewyan Airport",
        "YPZ": "YPZ | Burns Lake Airport",
        "YQA": "YQA | Muskoka Airport",
        "YQB": "YQB | Quebec Jean Lesage International Airport",
        "YQD": "YQD | The Pas Airport",
        "YQF": "YQF | Red Deer Regional Airport",
        "YQG": "YQG | Windsor Airport",
        "YQH": "YQH | Watson Lake Airport",
        "YQI": "YQI | Yarmouth Airport",
        "YQK": "YQK | Kenora Airport",
        "YQL": "YQL | Lethbridge County Airport",
        "YQM": "YQM | Greater Moncton Roméo LeBlanc International Airport",
        "YQN": "YQN | Nakina Airport",
        "YQQ": "YQQ | Comox Valley Airport / CFB Comox",
        "YQR": "YQR | Regina International Airport",
        "YQS": "YQS | St Thomas Municipal Airport",
        "YQT": "YQT | Thunder Bay Airport",
        "YQU": "YQU | Grande Prairie Airport",
        "YQV": "YQV | Yorkton Municipal Airport",
        "YQW": "YQW | North Battleford Airport",
        "YQX": "YQX | Gander International Airport",
        "YQY": "YQY | Sydney / J.A. Douglas McCurdy Airport",
        "YQZ": "YQZ | Quesnel Airport",
        "YRA": "YRA | Rae Lakes Airport",
        "YRB": "YRB | Resolute Bay Airport",
        "YRI": "YRI | Rivière-du-Loup Airport",
        "YRJ": "YRJ | Roberval Airport",
        "YRL": "YRL | Red Lake Airport",
        "YRM": "YRM | Rocky Mountain House Airport",
        "YRO": "YRO | Ottawa / Rockcliffe Airport",
        "YRQ": "YRQ | Trois-Rivières Airport",
        "YRS": "YRS | Red Sucker Lake Airport",
        "YRT": "YRT | Rankin Inlet Airport",
        "YRV": "YRV | Revelstoke Airport",
        "YSB": "YSB | Sudbury Airport",
        "YSC": "YSC | Sherbrooke Airport",
        "YSE": "YSE | Squamish Airport",
        "YSF": "YSF | Stony Rapids Airport",
        "YSH": "YSH | Smiths Falls-Montague (Russ Beach) Airport",
        "YSJ": "YSJ | Saint John Airport",
        "YSK": "YSK | Sanikiluaq Airport",
        "YSL": "YSL | St Leonard Airport",
        "YSM": "YSM | Fort Smith Airport",
        "YCM": "YCM | Niagara District Airport",
        "YSP": "YSP | Marathon Airport",
        "YST": "YST | St. Theresa Point Airport",
        "YSU": "YSU | Summerside Airport",
        "YSY": "YSY | Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
        "YTA": "YTA | Pembroke Airport",
        "YTE": "YTE | Cape Dorset Airport",
        "YTF": "YTF | Alma Airport",
        "YTH": "YTH | Thompson Airport",
        "YTL": "YTL | Big Trout Lake Airport",
        "YTQ": "YTQ | Tasiujaq Airport",
        "YTR": "YTR | CFB Trenton",
        "YTS": "YTS | Timmins/Victor M. Power",
        "YTZ": "YTZ | Billy Bishop Toronto City Centre Airport",
        "YUB": "YUB | Tuktoyaktuk Airport",
        "YUL": "YUL | Montreal / Pierre Elliott Trudeau International Airport",
        "YUT": "YUT | Naujaat Airport",
        "YUX": "YUX | Hall Beach Airport",
        "YUY": "YUY | Rouyn Noranda Airport",
        "YVB": "YVB | Bonaventure Airport",
        "YVC": "YVC | La Ronge Airport",
        "YVG": "YVG | Vermilion Airport",
        "YVE": "YVE | Vernon Airport",
        "YCK": "YCK | Tommy Kochon Airport",
        "YVM": "YVM | Qikiqtarjuaq Airport",
        "YVO": "YVO | Val-d'Or Airport",
        "YVP": "YVP | Kuujjuaq Airport",
        "YVQ": "YVQ | Norman Wells Airport",
        "YVR": "YVR | Vancouver International Airport",
        "YVT": "YVT | Buffalo Narrows Airport",
        "YVV": "YVV | Wiarton Airport",
        "YVZ": "YVZ | Deer Lake Airport",
        "YWA": "YWA | Petawawa Airport",
        "YWG": "YWG | Winnipeg / James Armstrong Richardson International Airport",
        "YWH": "YWH | Victoria Harbour Seaplane Base",
        "YWJ": "YWJ | Déline Airport",
        "YWK": "YWK | Wabush Airport",
        "YWL": "YWL | Williams Lake Airport",
        "YWP": "YWP | Webequie Airport",
        "YWY": "YWY | Wrigley Airport",
        "YXC": "YXC | Cranbrook/Canadian Rockies International Airport",
        "YXE": "YXE | Saskatoon John G. Diefenbaker International Airport",
        "YXH": "YXH | Medicine Hat Airport",
        "YXJ": "YXJ | Fort St John Airport",
        "YXK": "YXK | Rimouski Airport",
        "YXL": "YXL | Sioux Lookout Airport",
        "YXN": "YXN | Whale Cove Airport",
        "YXP": "YXP | Pangnirtung Airport",
        "YXQ": "YXQ | Beaver Creek Airport",
        "YXR": "YXR | Earlton (Timiskaming Regional) Airport",
        "YXS": "YXS | Prince George Airport",
        "YXT": "YXT | Northwest Regional Airport Terrace-Kitimat",
        "YXU": "YXU | London Airport",
        "YXX": "YXX | Abbotsford International Airport",
        "YXY": "YXY | Whitehorse / Erik Nielsen International Airport",
        "YXZ": "YXZ | Wawa Airport",
        "YYB": "YYB | North Bay Jack Garland Airport",
        "YYC": "YYC | Calgary International Airport",
        "YYD": "YYD | Smithers Airport",
        "YYE": "YYE | Fort Nelson Airport",
        "YYF": "YYF | Penticton Airport",
        "YYG": "YYG | Charlottetown Airport",
        "YYH": "YYH | Taloyoak Airport",
        "YYJ": "YYJ | Victoria International Airport",
        "YYL": "YYL | Lynn Lake Airport",
        "YYM": "YYM | Cowley Airport",
        "YYN": "YYN | Swift Current Airport",
        "YYQ": "YYQ | Churchill Airport",
        "YYR": "YYR | Goose Bay Airport",
        "YYT": "YYT | St. John's International Airport",
        "YYU": "YYU | Kapuskasing Airport",
        "YYW": "YYW | Armstrong Airport",
        "YYY": "YYY | Mont Joli Airport",
        "YYZ": "YYZ | Lester B. Pearson International Airport",
        "YZE": "YZE | Gore Bay Manitoulin Airport",
        "YZF": "YZF | Yellowknife International Airport",
        "YZG": "YZG | Salluit Airport",
        "YZH": "YZH | Slave Lake Airport",
        "YZP": "YZP | Sandspit Airport",
        "YZR": "YZR | Chris Hadfield Airport",
        "YZS": "YZS | Coral Harbour Airport",
        "YZT": "YZT | Port Hardy Airport",
        "YZU": "YZU | Whitecourt Airport",
        "YZV": "YZV | Sept-Îles Airport",
        "YZW": "YZW | Teslin Airport",
        "YZX": "YZX | CFB Greenwood",
        "ZAC": "ZAC | York Landing Airport",
        "YSN": "YSN | Shuswap Regional Airport",
        "YDT": "YDT | Boundary Bay Airport",
        "ILF": "ILF | Ilford Airport",
        "ZBF": "ZBF | Bathurst Airport",
        "ZBM": "ZBM | Bromont (Roland Desourdy) Airport",
        "KES": "KES | Kelsey Airport",
        "ZEM": "ZEM | Eastmain River Airport",
        "ZFA": "ZFA | Faro Airport",
        "ZFD": "ZFD | Fond-Du-Lac Airport",
        "XPK": "XPK | Pukatawagan Airport",
        "ZFM": "ZFM | Fort Mcpherson Airport",
        "ZFN": "ZFN | Tulita Airport",
        "ZGF": "ZGF | Grand Forks Airport",
        "ZGI": "ZGI | Gods River Airport",
        "ZGR": "ZGR | Little Grand Rapids Airport",
        "ZHP": "ZHP | High Prairie Airport",
        "CZJ": "CZJ | Corazón de Jesús Airport",
        "ZJG": "ZJG | Jenpeg Airport",
        "ZJN": "ZJN | Swan River Airport",
        "CZK": "CZK | Cascade Locks State Airport",
        "ZKE": "ZKE | Kashechewan Airport",
        "YTD": "YTD | Thicket Portage Airport",
        "MSA": "MSA | Muskrat Dam Airport",
        "ZMH": "ZMH | South Cariboo Region / 108 Mile Airport",
        "PIW": "PIW | Pikwitonei Airport",
        "ZMT": "ZMT | Masset Airport",
        "CZN": "CZN | Chisana Airport",
        "XPP": "XPP | Poplar River Airport",
        "CZO": "CZO | Chistochina Airport",
        "ZPB": "ZPB | Sachigo Lake Airport",
        "WPC": "WPC | Pincher Creek Airport",
        "ZPO": "ZPO | Pinehouse Lake Airport",
        "ZRJ": "ZRJ | Round Lake (Weagamow Lake) Airport",
        "ZSJ": "ZSJ | Sandy Lake Airport",
        "XSI": "XSI | South Indian Lake Airport",
        "ZST": "ZST | Stewart Airport",
        "ZSW": "ZSW | Prince Rupert/Seal Cove Seaplane Base",
        "YDV": "YDV | Bloodvein River Airport",
        "ZTM": "ZTM | Shamattawa Airport",
        "ZUC": "ZUC | Ignace Municipal Airport",
        "ZUM": "ZUM | Churchill Falls Airport",
        "XLB": "XLB | Lac Brochet Airport",
        "ZWL": "ZWL | Wollaston Lake Airport",
        "IUA": "IUA | Canandaigua Airport",
        "DJN": "DJN | Delta Junction Airport",
        "MQV": "MQV | Mostaganem Airport",
        "QLD": "QLD | Blida Airport",
        "BUJ": "BUJ | Bou Saada Airport",
        "BJA": "BJA | Soummam – Abane Ramdane Airport",
        "ALG": "ALG | Houari Boumediene Airport",
        "DJG": "DJG | Djanet Inedbirene Airport",
        "VVZ": "VVZ | Illizi Takhamalt Airport",
        "QSF": "QSF | Ain Arnat Airport",
        "TMR": "TMR | Aguenar – Hadj Bey Akhamok Airport",
        "GJL": "GJL | Jijel Ferhat Abbas Airport",
        "MZW": "MZW | Mecheria Airport",
        "AAE": "AAE | Rabah Bitat Airport",
        "CZL": "CZL | Mohamed Boudiaf International Airport",
        "QMH": "QMH | Oum El Bouaghi Air Base",
        "TEE": "TEE | Cheikh Larbi Tébessi Airport",
        "BLJ": "BLJ | Batna Mostefa Ben Boulaid Airport",
        "DAF": "DAF | Daup Airport",
        "HRM": "HRM | Hassi R'Mel Airport",
        "QDJ": "QDJ | Tsletsi Airport",
        "DAH": "DAH | Dathina Airport",
        "DAO": "DAO | Dabo Airport",
        "TID": "TID | Abdelhafid Boussouf Bou Chekif Airport",
        "TIN": "TIN | Tindouf Airport",
        "CFK": "CFK | Chlef Aboubakr Belkaid International Airport",
        "TAF": "TAF | Oran Tafraoui Airport",
        "TLM": "TLM | Zenata – Messali El Hadj Airport",
        "ORN": "ORN | Oran International Airport Ahmed Ben Bella",
        "CBH": "CBH | Béchar Boudghene Ben Ali Lotfi Airport",
        "BFW": "BFW | Sidi Bel Abbes Airport",
        "MUW": "MUW | Ghriss Airport",
        "EBH": "EBH | El Bayadh Airport",
        "INF": "INF | In Guezzam Airport",
        "BMW": "BMW | Bordj Badji Mokhtar Airport",
        "AZR": "AZR | Touat Cheikh Sidi Mohamed Belkebir Airport",
        "BSK": "BSK | Biskra - Mohamed Khider Airport",
        "ELG": "ELG | El Golea Airport",
        "GHA": "GHA | Noumérat - Moufdi Zakaria Airport",
        "HME": "HME | Hassi Messaoud-Oued Irara Krim Belkacem Airport",
        "INZ": "INZ | In Salah Airport",
        "TGR": "TGR | Touggourt Sidi Madhi Airport",
        "LOO": "LOO | Laghouat - Molay Ahmed Medeghri Airport",
        "ELU": "ELU | Guemar Airport",
        "TMX": "TMX | Timimoun Airport",
        "OGX": "OGX | Ain Beida Airport",
        "IAM": "IAM | Zarzaitine - In Aménas Airport",
        "COO": "COO | Cadjehoun Airport",
        "DJA": "DJA | Djougou Airport",
        "KDC": "KDC | Kandi Airport",
        "NAE": "NAE | Natitingou Airport",
        "PKO": "PKO | Parakou Airport",
        "SVF": "SVF | Savé Airport",
        "DBC": "DBC | Baicheng Chang'an Airport",
        "DBK": "DBK | Dutch Bay SPB",
        "DBP": "DBP | Debepare Airport",
        "DBU": "DBU | Dambulu Oya Tank Seaplane Base",
        "DCG": "DCG | Dubai Creek SPB",
        "DCK": "DCK | Dahl Creek Airport",
        "DDM": "DDM | Dodoima Airport",
        "DEQ": "DEQ | Deqing Moganshan Airport",
        "DER": "DER | Derim Airport",
        "DEX": "DEX | Nop Goliat Airport",
        "XKY": "XKY | Kaya Airport",
        "OUG": "OUG | Ouahigouya Airport",
        "XDJ": "XDJ | Djibo Airport",
        "XLU": "XLU | Leo Airport",
        "PUP": "PUP | Po Airport",
        "XBO": "XBO | Boulsa Airport",
        "XBG": "XBG | Bogande Airport",
        "DIP": "DIP | Diapaga Airport",
        "DOR": "DOR | Dori Airport",
        "FNG": "FNG | Fada N'gourma Airport",
        "XGG": "XGG | Gorom-Gorom Airport",
        "XKA": "XKA | Kantchari Airport",
        "TMQ": "TMQ | Tambao Airport",
        "XPA": "XPA | Pama Airport",
        "ARL": "ARL | Arly Airport",
        "XSE": "XSE | Sebba Airport",
        "TEG": "TEG | Tenkodogo Airport",
        "XZA": "XZA | Zabré Airport",
        "OUA": "OUA | Ouagadougou Airport",
        "BNR": "BNR | Banfora Airport",
        "DGU": "DGU | Dedougou Airport",
        "XGA": "XGA | Gaoua Airport",
        "XNU": "XNU | Nouna Airport",
        "BOY": "BOY | Bobo Dioulasso Airport",
        "TUQ": "TUQ | Tougan Airport",
        "XDE": "XDE | Diebougou Airport",
        "XAR": "XAR | Aribinda Airport",
        "ACC": "ACC | Kotoka International Airport",
        "TML": "TML | Tamale Airport",
        "DGM": "DGM | Dandugama Seaplane Base",
        "KMS": "KMS | Kumasi Airport",
        "NYI": "NYI | Sunyani Airport",
        "TKD": "TKD | Takoradi Airport",
        "DHB": "DHB | Deer Harbor SPB",
        "DHG": "DHG | Dalnegorsk Airport",
        "DHL": "DHL | Ad-Dali Airport",
        "ABO": "ABO | Aboisso Airport",
        "ABJ": "ABJ | Félix-Houphouët-Boigny International Airport",
        "OGO": "OGO | Abengourou Airport",
        "BXI": "BXI | Boundiali Airport",
        "BYK": "BYK | Bouaké Airport",
        "BQO": "BQO | Bouna Airport",
        "BDK": "BDK | Soko Airport",
        "DIM": "DIM | Dimbokro Airport",
        "DJO": "DJO | Daloa Airport",
        "FEK": "FEK | Ferkessedougou Airport",
        "GGN": "GGN | Gagnoa Airport",
        "GGO": "GGO | Guiglo Airport",
        "BBV": "BBV | Nero-Mer Airport",
        "HGO": "HGO | Korhogo Airport",
        "MJC": "MJC | Man Airport",
        "KEO": "KEO | Odienne Airport",
        "OFI": "OFI | Ouango Fitini Airport",
        "SEO": "SEO | Seguela Airport",
        "SPY": "SPY | San Pedro Airport",
        "ZSS": "ZSS | Sassandra Airport",
        "TXU": "TXU | Tabou Airport",
        "TOZ": "TOZ | Mahana Airport",
        "DIW": "DIW | Mawella Lagoon Seaplane Base",
        "ASK": "ASK | Yamoussoukro Airport",
        "DLR": "DLR | Dalnerechensk Airport",
        "DKA": "DKA | Katsina Airport",
        "ABV": "ABV | Nnamdi Azikiwe International Airport",
        "QUO": "QUO | Akwa Ibom International Airport",
        "AKR": "AKR | Akure Airport",
        "ABB": "ABB | Asaba International Airport",
        "BCU": "BCU | Sir Abubakar Tafawa Balewa International Airport",
        "BNI": "BNI | Benin Airport",
        "CBQ": "CBQ | Margaret Ekpo International Airport",
        "ENU": "ENU | Akanu Ibiam International Airport",
        "IBA": "IBA | Ibadan Airport",
        "ILR": "ILR | Ilorin International Airport",
        "QOW": "QOW | Sam Mbakwe International Airport",
        "JOS": "JOS | Yakubu Gowon Airport",
        "KAD": "KAD | Kaduna Airport",
        "KAN": "KAN | Mallam Aminu International Airport",
        "MIU": "MIU | Maiduguri International Airport",
        "MDI": "MDI | Makurdi Airport",
        "LOS": "LOS | Murtala Muhammed International Airport",
        "MXJ": "MXJ | Minna Airport",
        "PHC": "PHC | Port Harcourt International Airport",
        "SKO": "SKO | Sadiq Abubakar III International Airport",
        "YOL": "YOL | Yola Airport",
        "ZAR": "ZAR | Zaria Airport",
        "DOO": "DOO | Dorobisoro Airport",
        "DPT": "DPT | Deputatskiy Airport",
        "DQA": "DQA | Saertu Airport",
        "MFQ": "MFQ | Maradi Airport",
        "NIM": "NIM | Diori Hamani International Airport",
        "THZ": "THZ | Tahoua Airport",
        "AJY": "AJY | Mano Dayak International Airport",
        "RLT": "RLT | Arlit Airport",
        "ZND": "ZND | Zinder Airport",
        "DSG": "DSG | Dilasag Airport",
        "TBJ": "TBJ | Tabarka 7 Novembre Airport",
        "MIR": "MIR | Monastir Habib Bourguiba International Airport",
        "TUN": "TUN | Tunis Carthage International Airport",
        "GAF": "GAF | Gafsa Ksar International Airport",
        "GAE": "GAE | Gabès Matmata International Airport",
        "DJE": "DJE | Djerba Zarzis International Airport",
        "EBM": "EBM | El Borma Airport",
        "SFA": "SFA | Sfax Thyna International Airport",
        "TOE": "TOE | Tozeur Nefta International Airport",
        "DVD": "DVD | Andavadoaka Airport",
        "DWO": "DWO | Diyawanna Oya Seaplane Base",
        "DWR": "DWR | Dwyer Airport",
        "LRL": "LRL | Niamtougou International Airport",
        "LFW": "LFW | Lomé–Tokoin International Airport",
        "EAL": "EAL | Elenak Airport",
        "ANR": "ANR | Antwerp International Airport (Deurne)",
        "BRU": "BRU | Brussels Airport",
        "CRL": "CRL | Brussels South Charleroi Airport",
        "KJK": "KJK | Wevelgem Airport",
        "LGG": "LGG | Liège Airport",
        "EBO": "EBO | Ebon Airport",
        "OST": "OST | Ostend-Bruges International Airport",
        "MZD": "MZD | Méndez Airport",
        "EDA": "EDA | Edna Bay Seaplane Base",
        "AOC": "AOC | Altenburg-Nobitz Airport",
        "HDF": "HDF | Heringsdorf Airport",
        "IES": "IES | Riesa-Göhlis Airport",
        "REB": "REB | Rechlin-Lärz Airport",
        "CSO": "CSO | Cochstedt Airport",
        "BBH": "BBH | Barth Airport",
        "ZMG": "ZMG | Magdeburg City Airport",
        "FNB": "FNB | Neubrandenburg Airport",
        "GTI": "GTI | Flugplatz Güttin / Rügen",
        "KOQ": "KOQ | Köthen Airport",
        "PEF": "PEF | Peenemünde Airport",
        "BER": "BER | Berlin Brandenburg Airport",
        "DRS": "DRS | Dresden Airport",
        "ERF": "ERF | Erfurt Airport",
        "FRA": "FRA | Frankfurt am Main Airport",
        "FMO": "FMO | Münster Osnabrück Airport",
        "HAM": "HAM | Hamburg Helmut Schmidt Airport",
        "CGN": "CGN | Cologne Bonn Airport",
        "DUS": "DUS | Düsseldorf Airport",
        "MUC": "MUC | Munich Airport",
        "NUE": "NUE | Nuremberg Airport",
        "LEJ": "LEJ | Leipzig/Halle Airport",
        "SCN": "SCN | Saarbrücken Airport",
        "STR": "STR | Stuttgart Airport",
        "HAJ": "HAJ | Hannover Airport",
        "BRE": "BRE | Bremen Airport",
        "HHN": "HHN | Frankfurt-Hahn Airport",
        "MHG": "MHG | Mannheim-City Airport",
        "EIB": "EIB | Eisenach-Kindel Airport",
        "SGE": "SGE | Siegerland Airport",
        "XFW": "XFW | Hamburg-Finkenwerder Airport",
        "KEL": "KEL | Kiel-Holtenau Airport",
        "LBC": "LBC | Lübeck Blankensee Airport",
        "EUM": "EUM | Neumünster Airport",
        "FMM": "FMM | Memmingen Allgau Airport",
        "AAH": "AAH | Aachen-Merzbrück Airport",
        "BNJ": "BNJ | Bonn-Hangelar Airport",
        "ESS": "ESS | Essen Mülheim Airport",
        "BFE": "BFE | Bielefeld Airport",
        "MGL": "MGL | Mönchengladbach Airport",
        "PAD": "PAD | Paderborn Lippstadt Airport",
        "NRN": "NRN | Weeze Airport",
        "DTM": "DTM | Dortmund Airport",
        "AGB": "AGB | Augsburg Airport",
        "OBF": "OBF | Oberpfaffenhofen Airport",
        "RBM": "RBM | Straubing Airport",
        "FDH": "FDH | Friedrichshafen Airport",
        "SZW": "SZW | Schwerin Parchim Airport",
        "BYU": "BYU | Bayreuth Airport",
        "URD": "URD | Burg Feuerstein Airport",
        "GHF": "GHF | Giebelstadt Airport",
        "HOQ": "HOQ | Hof-Plauen Airport",
        "BBJ": "BBJ | Bitburg Airport",
        "ZQW": "ZQW | Zweibrücken Airport",
        "FKB": "FKB | Karlsruhe Baden-Baden Airport",
        "LHA": "LHA | Lahr Airport",
        "BWE": "BWE | Braunschweig-Wolfsburg Airport",
        "KSF": "KSF | Kassel-Calden Airport",
        "EME": "EME | Emden Airport",
        "AGE": "AGE | Wangerooge Airport",
        "WVN": "WVN | Wilhelmshaven-Mariensiel Airport",
        "JUI": "JUI | Juist Airport",
        "LGO": "LGO | Langeoog Airport",
        "BMK": "BMK | Borkum Airport",
        "NOD": "NOD | Norden-Norddeich Airport",
        "VAC": "VAC | Varrelbusch Airport",
        "NRD": "NRD | Norderney Airport",
        "BMR": "BMR | Baltrum Airport",
        "HEI": "HEI | Heide-Büsum Airport",
        "FLF": "FLF | Flensburg-Schäferhaus Airport",
        "HGL": "HGL | Helgoland-Düne Airport",
        "QHU": "QHU | Husum-Schwesing Airport",
        "PSH": "PSH | St. Peter-Ording Airport",
        "GWT": "GWT | Westerland Sylt Airport",
        "OHR": "OHR | Wyk auf Föhr Airport",
        "KDL": "KDL | Kärdla Airport",
        "URE": "URE | Kuressaare Airport",
        "EPU": "EPU | Pärnu Airport",
        "TLL": "TLL | Lennart Meri Tallinn Airport",
        "TAY": "TAY | Tartu Airport",
        "ENF": "ENF | Enontekio Airport",
        "EFG": "EFG | Efogi Airport",
        "KEV": "KEV | Halli Airport",
        "HEL": "HEL | Helsinki Vantaa Airport",
        "HYV": "HYV | Hyvinkää Airfield",
        "KTQ": "KTQ | Kitee Airport",
        "IVL": "IVL | Ivalo Airport",
        "JOE": "JOE | Joensuu Airport",
        "JYV": "JYV | Jyväskylä Airport",
        "KAU": "KAU | Kauhava Airfield",
        "KEM": "KEM | Kemi-Tornio Airport",
        "KAJ": "KAJ | Kajaani Airport",
        "KHJ": "KHJ | Kauhajoki Airfield",
        "KOK": "KOK | Kokkola-Pietarsaari Airport",
        "KAO": "KAO | Kuusamo Airport",
        "KTT": "KTT | Kittilä Airport",
        "KUO": "KUO | Kuopio Airport",
        "LPP": "LPP | Lappeenranta Airport",
        "MHQ": "MHQ | Mariehamn Airport",
        "MIK": "MIK | Mikkeli Airport",
        "OUL": "OUL | Oulu Airport",
        "POR": "POR | Pori Airport",
        "RVN": "RVN | Rovaniemi Airport",
        "SVL": "SVL | Savonlinna Airport",
        "SJY": "SJY | Seinäjoki Airport",
        "SOT": "SOT | Sodankyla Airport",
        "TMP": "TMP | Tampere-Pirkkala Airport",
        "TKU": "TKU | Turku Airport",
        "UTI": "UTI | Utti Air Base",
        "VAA": "VAA | Vaasa Airport",
        "VRK": "VRK | Varkaus Airport",
        "YLI": "YLI | Ylivieska Airfield",
        "AUE": "AUE | Abu Rudeis Airport",
        "BFS": "BFS | Belfast International Airport",
        "ENK": "ENK | Enniskillen/St Angelo Airport",
        "BHD": "BHD | George Best Belfast City Airport",
        "LDY": "LDY | City of Derry Airport",
        "BHX": "BHX | Birmingham International Airport",
        "CVT": "CVT | Coventry Airport",
        "GLO": "GLO | Gloucestershire Airport",
        "ORM": "ORM | Sywell Aerodrome",
        "NQT": "NQT | Nottingham Airport",
        "GBA": "GBA | Cotswold Airport",
        "MAN": "MAN | Manchester Airport",
        "DSA": "DSA | Robin Hood Doncaster Sheffield Airport",
        "UPV": "UPV | Upavon Aerodrome",
        "YEO": "YEO | RNAS Yeovilton",
        "CAL": "CAL | Campbeltown Airport",
        "EOI": "EOI | Eday Airport",
        "FIE": "FIE | Fair Isle Airport",
        "WHS": "WHS | Whalsay Airport",
        "COL": "COL | Coll Airport",
        "NRL": "NRL | North Ronaldsay Airport",
        "OBN": "OBN | Oban Airport",
        "PPW": "PPW | Papa Westray Airport",
        "SOY": "SOY | Stronsay Airport",
        "NDY": "NDY | Sanday Airport",
        "LWK": "LWK | Lerwick / Tingwall Airport",
        "WRY": "WRY | Westray Airport",
        "CSA": "CSA | Colonsay Airstrip",
        "HAW": "HAW | Haverfordwest Airport",
        "CWL": "CWL | Cardiff International Airport",
        "SWS": "SWS | Swansea Airport",
        "BRS": "BRS | Bristol Airport",
        "LPL": "LPL | Liverpool John Lennon Airport",
        "LTN": "LTN | London Luton Airport",
        "LEQ": "LEQ | Land's End Airport",
        "ISC": "ISC | St. Mary's Airport",
        "BOH": "BOH | Bournemouth Airport",
        "SOU": "SOU | Southampton Airport",
        "BBP": "BBP | Bembridge Airport",
        "NQY": "NQY | Newquay Cornwall Airport",
        "QUG": "QUG | Goodwood Aerodrome",
        "ACI": "ACI | Alderney Airport",
        "GCI": "GCI | Guernsey Airport",
        "JER": "JER | Jersey Airport",
        "ESH": "ESH | Shoreham Airport",
        "BQH": "BQH | London Biggin Hill Airport",
        "LGW": "LGW | London Gatwick Airport",
        "KRH": "KRH | Redhill Aerodrome",
        "LCY": "LCY | London City Airport",
        "FAB": "FAB | Farnborough Airport",
        "BBS": "BBS | Blackbushe Airport",
        "LHR": "LHR | London Heathrow Airport",
        "SEN": "SEN | Southend Airport",
        "LYX": "LYX | Lydd Airport",
        "CAX": "CAX | Carlisle Airport",
        "BLK": "BLK | Blackpool International Airport",
        "HUY": "HUY | Humberside Airport",
        "BWF": "BWF | Barrow Walney Island Airport",
        "LBA": "LBA | Leeds Bradford Airport",
        "WRT": "WRT | Warton Aerodrome",
        "CEG": "CEG | Hawarden Airport",
        "IOM": "IOM | Isle of Man Airport",
        "NCL": "NCL | Newcastle Airport",
        "MME": "MME | Teesside International Airport",
        "EMA": "EMA | East Midlands Airport",
        "VLY": "VLY | Anglesey Airport",
        "KOI": "KOI | Kirkwall Airport",
        "LSI": "LSI | Sumburgh Airport",
        "WIC": "WIC | Wick Airport",
        "ABZ": "ABZ | Aberdeen Dyce Airport",
        "INV": "INV | Inverness Airport",
        "GLA": "GLA | Glasgow International Airport",
        "EDI": "EDI | Edinburgh Airport",
        "ILY": "ILY | Islay Airport",
        "PIK": "PIK | Glasgow Prestwick Airport",
        "BEB": "BEB | Benbecula Airport",
        "SCS": "SCS | Scatsta Airport",
        "DND": "DND | Dundee Airport",
        "SYY": "SYY | Stornoway Airport",
        "BRR": "BRR | Barra Airport",
        "PSL": "PSL | Perth/Scone Airport",
        "TRE": "TRE | Tiree Airport",
        "UNT": "UNT | Unst Airport",
        "FSS": "FSS | RAF Kinloss",
        "ADX": "ADX | Leuchars Station Airfield",
        "LMO": "LMO | RAF Lossiemouth",
        "CBG": "CBG | Cambridge Airport",
        "NWI": "NWI | Norwich International Airport",
        "STN": "STN | London Stansted Airport",
        "HYC": "HYC | Wycombe Air Park",
        "EXT": "EXT | Exeter International Airport",
        "OXF": "OXF | Oxford (Kidlington) Airport",
        "RCS": "RCS | Rochester Airport",
        "BEX": "BEX | RAF Benson",
        "LKZ": "LKZ | RAF Lakenheath",
        "MHZ": "MHZ | RAF Mildenhall",
        "QUY": "QUY | RAF Wyton",
        "FFD": "FFD | RAF Fairford",
        "BZZ": "BZZ | RAF Brize Norton",
        "ODH": "ODH | RAF Odiham",
        "NHT": "NHT | RAF Northolt",
        "QCY": "QCY | RAF Coningsby",
        "BEQ": "BEQ | RAF Honington",
        "SQZ": "SQZ | RAF Scampton",
        "HRT": "HRT | RAF Linton-On-Ouse",
        "WTN": "WTN | RAF Waddington",
        "KNF": "KNF | RAF Marham",
        "MPN": "MPN | Mount Pleasant Airport",
        "AMS": "AMS | Amsterdam Airport Schiphol",
        "MST": "MST | Maastricht Aachen Airport",
        "EIN": "EIN | Eindhoven Airport",
        "GRQ": "GRQ | Eelde Airport",
        "GLZ": "GLZ | Gilze Rijen Air Base",
        "DHR": "DHR | De Kooy Airport",
        "LEY": "LEY | Lelystad Airport",
        "LWR": "LWR | Leeuwarden Air Base",
        "RTM": "RTM | Rotterdam The Hague Airport",
        "ENS": "ENS | Twente Airport",
        "EHU": "EHU | Ezhou Huahu Airport",
        "WOE": "WOE | Woensdrecht Air Base",
        "BYT": "BYT | Bantry Aerodrome",
        "BLY": "BLY | Belmullet Aerodrome",
        "NNR": "NNR | Connemara Regional Airport",
        "ORK": "ORK | Cork Airport",
        "GWY": "GWY | Galway Airport",
        "CFN": "CFN | Donegal Airport",
        "DUB": "DUB | Dublin Airport",
        "IOR": "IOR | Inishmore Aerodrome",
        "INQ": "INQ | Inisheer Aerodrome",
        "KKY": "KKY | Kilkenny Airport",
        "NOC": "NOC | Ireland West Knock Airport",
        "KIR": "KIR | Kerry Airport",
        "LTR": "LTR | Letterkenny Airfield",
        "IIA": "IIA | Inishmaan Aerodrome",
        "SNN": "SNN | Shannon Airport",
        "SXL": "SXL | Sligo Airport",
        "WAT": "WAT | Waterford Airport",
        "EJN": "EJN | Ejin Banner-Taolai Airport",
        "EJT": "EJT | Enejit Airport",
        "AAR": "AAR | Aarhus Airport",
        "BLL": "BLL | Billund Airport",
        "CPH": "CPH | Copenhagen Kastrup Airport",
        "EBJ": "EBJ | Esbjerg Airport",
        "KRP": "KRP | Karup Airport",
        "BYR": "BYR | Læsø Airport",
        "MRW": "MRW | Lolland Falster Maribo Airport",
        "ODE": "ODE | Odense Airport",
        "RKE": "RKE | Copenhagen Roskilde Airport",
        "RNN": "RNN | Bornholm Airport",
        "SGD": "SGD | Sønderborg Airport",
        "CNL": "CNL | Sindal Airport",
        "SKS": "SKS | Skrydstrup Air Base",
        "SQW": "SQW | Skive Airport",
        "TED": "TED | Thisted Airport",
        "FAE": "FAE | Vágar Airport",
        "STA": "STA | Stauning Airport",
        "AAL": "AAL | Aalborg Airport",
        "LUX": "LUX | Luxembourg-Findel International Airport",
        "AES": "AES | Ålesund Airport Vigra",
        "ANX": "ANX | Andøya Airport Andenes",
        "ALF": "ALF | Alta Airport",
        "FDE": "FDE | Førde Airport Bringeland",
        "BNN": "BNN | Brønnøysund Airport Brønnøy",
        "BOO": "BOO | Bodø Airport",
        "BGO": "BGO | Bergen Airport Flesland",
        "BJF": "BJF | Båtsfjord Airport",
        "BVG": "BVG | Berlevåg Airport",
        "KRS": "KRS | Kristiansand Airport Kjevik",
        "DLD": "DLD | Geilo Airport Dagali",
        "BDU": "BDU | Bardufoss Airport",
        "EVE": "EVE | Harstad/Narvik Airport Evenes",
        "VDB": "VDB | Fagernes Airport Leirin",
        "FRO": "FRO | Florø Airport",
        "OSL": "OSL | Oslo Airport Gardermoen",
        "HMR": "HMR | Hamar Lufthavn Stavsberg",
        "HAU": "HAU | Haugesund Airport Karmøy",
        "HFT": "HFT | Hammerfest Airport",
        "HAA": "HAA | Hasvik Airport",
        "HVG": "HVG | Honningsvåg Airport Valan",
        "KSU": "KSU | Kristiansund Airport Kvernberget",
        "GLL": "GLL | Gol Airport",
        "KKN": "KKN | Kirkenes Airport Høybuktmoen",
        "FAN": "FAN | Lista Airport",
        "LKN": "LKN | Leknes Airport",
        "MEH": "MEH | Mehamn Airport",
        "MOL": "MOL | Molde Airport Årø",
        "MJF": "MJF | Mosjøen Airport Kjærstad",
        "LKL": "LKL | Lakselv Airport Banak",
        "OSY": "OSY | Namsos Airport",
        "NTB": "NTB | Notodden Airport",
        "OLA": "OLA | Ørland Airport",
        "HOV": "HOV | Ørsta-Volda Airport Hovden",
        "MQN": "MQN | Mo i Rana Airport Røssvoll",
        "RVK": "RVK | Rørvik Airport Ryum",
        "RRS": "RRS | Røros Airport",
        "RET": "RET | Røst Airport",
        "RYG": "RYG | Moss Airport Rygge",
        "LYR": "LYR | Svalbard Airport Longyear",
        "SDN": "SDN | Sandane Airport Anda",
        "SOG": "SOG | Sogndal Airport Haukåsen",
        "SVJ": "SVJ | Svolvær Airport Helle",
        "SKN": "SKN | Stokmarknes Airport Skagen",
        "SKE": "SKE | Skien Airport",
        "SRP": "SRP | Stord Airport Sørstokken",
        "SOJ": "SOJ | Sørkjosen Airport",
        "VAW": "VAW | Vardø Airport Svartnes",
        "SSJ": "SSJ | Sandnessjøen Airport Stokka",
        "TOS": "TOS | Tromsø Airport Langnes",
        "TRF": "TRF | Sandefjord Airport Torp",
        "TRD": "TRD | Trondheim Airport Værnes",
        "VDS": "VDS | Vadsø Airport",
        "ENY": "ENY | Yan'an Nanniwan Airport",
        "SVG": "SVG | Stavanger Airport Sola",
        "BZG": "BZG | Bydgoszcz Ignacy Jan Paderewski Airport",
        "CZW": "CZW | Częstochowa-Rudniki",
        "GDN": "GDN | Gdańsk Lech Wałęsa Airport",
        "KRK": "KRK | Kraków John Paul II International Airport",
        "KTW": "KTW | Katowice International Airport",
        "LCJ": "LCJ | Łódź Władysław Reymont Airport",
        "WMI": "WMI | Modlin Airport",
        "POZ": "POZ | Poznań-Ławica Airport",
        "RDO": "RDO | Radom Airport",
        "RZE": "RZE | Rzeszów-Jasionka Airport",
        "SZZ": "SZZ | Szczecin-Goleniów Solidarność Airport",
        "SZY": "SZY | Olsztyn-Mazury Airport",
        "WAW": "WAW | Warsaw Chopin Airport",
        "WRO": "WRO | Copernicus Wrocław Airport",
        "IEG": "IEG | Zielona Góra-Babimost Airport",
        "ERT": "ERT | Erdenet Airport",
        "RNB": "RNB | Ronneby Airport",
        "GOT": "GOT | Gothenburg-Landvetter Airport",
        "JKG": "JKG | Jönköping Airport",
        "LDK": "LDK | Lidköping-Hovby Airport",
        "GSE": "GSE | Gothenburg City Airport",
        "KVB": "KVB | Skövde Airport",
        "THN": "THN | Trollhättan-Vänersborg Airport",
        "KSK": "KSK | Karlskoga Airport",
        "MXX": "MXX | Mora Airport",
        "NYO": "NYO | Stockholm Skavsta Airport",
        "SCR": "SCR | Scandinavian Mountains Airport",
        "KID": "KID | Kristianstad Airport",
        "OSK": "OSK | Oskarshamn Airport",
        "KLR": "KLR | Kalmar Airport",
        "MMX": "MMX | Malmö Sturup Airport",
        "HAD": "HAD | Halmstad Airport",
        "VXO": "VXO | Växjö Kronoberg Airport",
        "EVG": "EVG | Sveg Airport",
        "GEV": "GEV | Gällivare Airport",
        "KRF": "KRF | Kramfors-Sollefteå Höga Kusten Airport",
        "LYC": "LYC | Lycksele Airport",
        "SDL": "SDL | Sundsvall-Härnösand Airport",
        "OER": "OER | Örnsköldsvik Airport",
        "KRN": "KRN | Kiruna Airport",
        "SFT": "SFT | Skellefteå Airport",
        "UME": "UME | Umeå Airport",
        "VHM": "VHM | Vilhelmina South Lapland Airport",
        "AJR": "AJR | Arvidsjaur Airport",
        "SOO": "SOO | Söderhamn Airport",
        "OSD": "OSD | Åre Östersund Airport",
        "ORB": "ORB | Örebro Airport",
        "HFS": "HFS | Hagfors Airport",
        "KSD": "KSD | Karlstad Airport",
        "VST": "VST | Stockholm Västerås Airport",
        "LLA": "LLA | Luleå Airport",
        "ARN": "ARN | Stockholm-Arlanda Airport",
        "BMA": "BMA | Stockholm-Bromma Airport",
        "BLE": "BLE | Dala Airport",
        "HLF": "HLF | Hultsfred Airport",
        "GVX": "GVX | Gävle Sandviken Airport",
        "LPI": "LPI | Linköping City Airport",
        "NRK": "NRK | Norrköping Airport",
        "TYF": "TYF | Torsby Airport",
        "EKT": "EKT | Eskilstuna Airport",
        "VBY": "VBY | Visby Airport",
        "VVK": "VVK | Västervik Airport",
        "AGH": "AGH | Ängelholm-Helsingborg Airport",
        "SQO": "SQO | Storuman Airport",
        "IDB": "IDB | Idre Airport",
        "PJA": "PJA | Pajala Airport",
        "HMV": "HMV | Hemavan Airport",
        "GLC": "GLC | Geladi Airport",
        "SHC": "SHC | Shire Inda Selassie Airport",
        "SPM": "SPM | Spangdahlem Air Base",
        "RMS": "RMS | Ramstein Air Base",
        "FRZ": "FRZ | Fritzlar Army Airfield",
        "KZG": "KZG | Flugplatz Kitzingen",
        "FCN": "FCN | Nordholz Naval Airbase",
        "GKE": "GKE | Geilenkirchen Air Base",
        "RLG": "RLG | Rostock-Laage Airport",
        "WBG": "WBG | Schleswig Air Base",
        "WIE": "WIE | Wiesbaden Army Airfield",
        "IGS": "IGS | Ingolstadt Manching Airport",
        "DGP": "DGP | Daugavpils Intrenational Airport",
        "LPX": "LPX | Liepāja International Airport",
        "RIX": "RIX | Riga International Airport",
        "VNT": "VNT | Ventspils International Airport",
        "EXI": "EXI | Excursion Inlet Seaplane Base",
        "KUN": "KUN | Kaunas International Airport",
        "PLQ": "PLQ | Palanga International Airport",
        "PNV": "PNV | Panevėžys Air Base",
        "SQQ": "SQQ | Šiauliai International Airport",
        "VNO": "VNO | Vilnius International Airport",
        "RGR": "RGR | Ranger Municipal Airport",
        "ALJ": "ALJ | Alexander Bay Airport",
        "AGZ": "AGZ | Aggeneys Airport",
        "ADY": "ADY | Alldays Airport",
        "BIY": "BIY | Bisho Airport",
        "BFN": "BFN | Bram Fischer International Airport",
        "UTE": "UTE | Bultfontein Airport",
        "ASS": "ASS | Arathusa Safari Lodge Airport",
        "CDO": "CDO | Cradock Airport",
        "CPT": "CPT | Cape Town International Airport",
        "DUK": "DUK | Mubatuba Airport",
        "PZL": "PZL | Zulu Inyala Airport",
        "ELS": "ELS | Ben Schoeman Airport",
        "EMG": "EMG | Empangeni Airport",
        "ELL": "ELL | Ellisras Matimba Airport",
        "FCB": "FCB | Ficksburg Sentraoes Airport",
        "GCJ": "GCJ | Grand Central Airport",
        "GRJ": "GRJ | George Airport",
        "GIY": "GIY | Giyani Airport",
        "QRA": "QRA | Rand Airport",
        "HLW": "HLW | Hluhluwe Airport",
        "HRS": "HRS | Harrismith Airport",
        "HDS": "HDS | Hoedspruit Air Force Base Airport",
        "KXE": "KXE | P C Pelser Airport",
        "KIM": "KIM | Kimberley Airport",
        "MQP": "MQP | Kruger Mpumalanga International Airport",
        "KOF": "KOF | Komatipoort Airport",
        "KMH": "KMH | Johan Pienaar Airport",
        "KLZ": "KLZ | Kleinsee Airport",
        "HLA": "HLA | Lanseria Airport",
        "LMR": "LMR | Lime Acres Finsch Mine Airport",
        "LDZ": "LDZ | Londolozi Airport",
        "DUR": "DUR | King Shaka International Airport",
        "LUJ": "LUJ | Lusikisiki Airport",
        "LCD": "LCD | Louis Trichardt Airport",
        "SDB": "SDB | Langebaanweg Airport",
        "LAY": "LAY | Ladysmith Airport",
        "AAM": "AAM | Malamala Airport",
        "MGH": "MGH | Margate Airport",
        "MEZ": "MEZ | Musina(Messina) Airport",
        "MBD": "MBD | Mmabatho International Airport",
        "LLE": "LLE | Riverside Airport",
        "MZY": "MZY | Mossel Bay Airport",
        "MZQ": "MZQ | Mkuze Airport",
        "NCS": "NCS | Newcastle Airport",
        "NGL": "NGL | Ngala Airport",
        "NLP": "NLP | Nelspruit Airport",
        "OVG": "OVG | Overberg Airport",
        "OUH": "OUH | Oudtshoorn Airport",
        "JNB": "JNB | OR Tambo International Airport",
        "AFD": "AFD | Port Alfred Airport",
        "PLZ": "PLZ | Port Elizabeth International Airport",
        "PBZ": "PBZ | Plettenberg Bay Airport",
        "PHW": "PHW | Hendrik Van Eck Airport",
        "JOH": "JOH | Port St Johns Airport",
        "PRK": "PRK | Prieska Airport",
        "PZB": "PZB | Pietermaritzburg Airport",
        "NTY": "NTY | Pilanesberg International Airport",
        "PTG": "PTG | Polokwane International Airport",
        "PCF": "PCF | Potchefstroom Airport",
        "UTW": "UTW | Queenstown Airport",
        "RCB": "RCB | Richards Bay Airport",
        "RVO": "RVO | Reivilo Airport",
        "ROD": "ROD | Robertson Airport",
        "SBU": "SBU | Springbok Airport",
        "ZEC": "ZEC | Secunda Airport",
        "GSS": "GSS | Sabi Sabi Airport",
        "SIS": "SIS | Sishen Airport",
        "SZK": "SZK | Skukuza Airport",
        "THY": "THY | Thohoyandou Airport",
        "TCU": "TCU | Thaba Nchu Tar Airport",
        "LTA": "LTA | Tzaneen Airport",
        "ULD": "ULD | Prince Mangosuthu Buthelezi Airport",
        "UTN": "UTN | Pierre Van Ryneveld Airport",
        "UTT": "UTT | K. D. Matanzima Airport",
        "VRU": "VRU | Vryburg Airport",
        "VIR": "VIR | Virginia Airport",
        "VRE": "VRE | Vredendal Airport",
        "VYD": "VYD | Vryheid Airport",
        "PRY": "PRY | Wonderboom Airport",
        "WKF": "WKF | Waterkloof Air Force Base",
        "FRW": "FRW | Francistown Airport",
        "GNZ": "GNZ | Ghanzi Airport",
        "JWA": "JWA | Jwaneng Airport",
        "BBK": "BBK | Kasane Airport",
        "KHW": "KHW | Khwai River Lodge Airport",
        "LOQ": "LOQ | Lobatse Airport",
        "MUB": "MUB | Maun Airport",
        "ORP": "ORP | Orapa Airport",
        "QPH": "QPH | Palapye Airport",
        "GBE": "GBE | Sir Seretse Khama International Airport",
        "SXN": "SXN | Sua Pan Airport",
        "PKW": "PKW | Selebi Phikwe Airport",
        "SVT": "SVT | Savuti Airport",
        "SWX": "SWX | Shakawe Airport",
        "TLD": "TLD | Limpopo Valley Airport",
        "TBY": "TBY | Tshabong Airport",
        "BZV": "BZV | Maya-Maya Airport",
        "DJM": "DJM | Djambala Airport",
        "KNJ": "KNJ | Kindamba Airport",
        "LCO": "LCO | Lague Airport",
        "MUY": "MUY | Mouyondzi Airport",
        "SIB": "SIB | Sibiti Airport",
        "NKY": "NKY | Yokangassi Airport",
        "ANJ": "ANJ | Zanaga Airport",
        "MSX": "MSX | Mossendjo Airport",
        "BOE": "BOE | Boundji Airport",
        "OLL": "OLL | Oyo Ollombo Airport",
        "EWO": "EWO | Ewo Airport",
        "GMM": "GMM | Gamboma Airport",
        "ION": "ION | Impfondo Airport",
        "KEE": "KEE | Kelle Airport",
        "MKJ": "MKJ | Makoua Airport",
        "FTX": "FTX | Owando Airport",
        "SOE": "SOE | Souanke Airport",
        "BTB": "BTB | Betou Airport",
        "OUE": "OUE | Ouesso Airport",
        "KMK": "KMK | Makabana Airport",
        "DIS": "DIS | Ngot Nzoungou Airport",
        "PNR": "PNR | Antonio Agostinho-Neto International Airport",
        "MTS": "MTS | Matsapha Airport",
        "SHO": "SHO | King Mswati III International Airport",
        "FEA": "FEA | Fetlar Airport",
        "CRF": "CRF | Carnot Airport",
        "BGF": "BGF | Bangui M'Poko International Airport",
        "BGU": "BGU | Bangassou Airport",
        "IRO": "IRO | Birao Airport",
        "BBY": "BBY | Bambari Airport",
        "NDL": "NDL | N'Délé Airport",
        "BOP": "BOP | Bouar Airport",
        "BIV": "BIV | Bria Airport",
        "BSN": "BSN | Bossangoa Airport",
        "BBT": "BBT | Berbérati Airport",
        "ODA": "ODA | Ouadda Airport",
        "AIG": "AIG | Yalinga Airport",
        "IMO": "IMO | Zemio Airport",
        "MKI": "MKI | M'Boki Airport",
        "BTG": "BTG | Batangafo Airport",
        "GDI": "GDI | Gordil Airport",
        "BMF": "BMF | Bakouma Airport",
        "ODJ": "ODJ | Ouanda Djallé Airport",
        "RFA": "RFA | Rafaï Airport",
        "BCF": "BCF | Bouca Airport",
        "BOZ": "BOZ | Bozoum Airport",
        "NBN": "NBN | Annobón Airport",
        "BSG": "BSG | Bata Airport",
        "GEM": "GEM | President Obiang Nguema International Airport",
        "SSG": "SSG | Malabo Airport",
        "ASI": "ASI | RAF Ascension Island",
        "HLE": "HLE | Saint Helena Airport",
        "MRU": "MRU | Sir Seewoosagur Ramgoolam International Airport",
        "RRG": "RRG | Sir Charles Gaetan Duval Airport",
        "FIN": "FIN | Finschhafen Airport",
        "NKW": "NKW | Diego Garcia Naval Support Facility",
        "KBI": "KBI | Kribi Airport",
        "TKC": "TKC | Tiko Airport",
        "DLA": "DLA | Douala International Airport",
        "MMF": "MMF | Mamfe Airport",
        "BLC": "BLC | Bali Airport",
        "KLE": "KLE | Kaélé Airport",
        "OUR": "OUR | Batouri Airport",
        "GXX": "GXX | Yagoua Airport",
        "MVR": "MVR | Salak Airport",
        "FOM": "FOM | Foumban Nkounja Airport",
        "NGE": "NGE | N'Gaoundéré Airport",
        "BTA": "BTA | Bertoua Airport",
        "GOU": "GOU | Garoua International Airport",
        "DSC": "DSC | Dschang Airport",
        "BFX": "BFX | Bafoussam Airport",
        "BPC": "BPC | Bamenda Airport",
        "EBW": "EBW | Ebolowa Airport",
        "YAO": "YAO | Yaoundé Airport",
        "NSI": "NSI | Yaoundé Nsimalen International Airport",
        "MMQ": "MMQ | Mbala Airport",
        "CIP": "CIP | Chipata Airport",
        "JEK": "JEK | Jeki Airport",
        "CGJ": "CGJ | Kasompe Airport",
        "KLB": "KLB | Kalabo Airport",
        "KMZ": "KMZ | Kaoma Airport",
        "KAA": "KAA | Kasama Airport",
        "ZKB": "ZKB | Kasaba Bay Airport",
        "LVI": "LVI | Harry Mwanga Nkumbula International Airport",
        "LXU": "LXU | Lukulu Airport",
        "LUN": "LUN | Kenneth Kaunda International Airport",
        "MNS": "MNS | Mansa Airport",
        "MFU": "MFU | Mfuwe Airport",
        "MNR": "MNR | Mongu Airport",
        "ZGM": "ZGM | Ngoma Airport",
        "NLA": "NLA | Simon Mwansa Kapwepwe International Airport",
        "RYL": "RYL | Royal Zambesi Lodge Airstrip",
        "SXG": "SXG | Senanga Airport",
        "KIW": "KIW | Southdowns Airport",
        "SJQ": "SJQ | Sesheke Airport",
        "SLI": "SLI | Solwesi Airport",
        "FLT": "FLT | Flat Airport",
        "BBZ": "BBZ | Zambezi Airport",
        "ULI": "ULI | Ulithi Airport",
        "HAH": "HAH | Prince Said Ibrahim International Airport",
        "NWA": "NWA | Mohéli Bandar Es Eslam Airport",
        "AJN": "AJN | Ouani Airport",
        "DZA": "DZA | Dzaoudzi Pamandzi International Airport",
        "RUN": "RUN | Roland Garros Airport",
        "ZSE": "ZSE | Pierrefonds Airport",
        "WML": "WML | Malaimbandy Airport",
        "ATJ": "ATJ | Antsirabe Airport",
        "WAQ": "WAQ | Antsalova Airport",
        "VVB": "VVB | Mahanoro Airport",
        "TNR": "TNR | Ivato Airport",
        "JVA": "JVA | Ankavandra Airport",
        "BMD": "BMD | Belo sur Tsiribihina Airport",
        "ZVA": "ZVA | Miandrivazo Airport",
        "MXT": "MXT | Maintirano Airport",
        "ILK": "ILK | Atsinanana Airport",
        "TVA": "TVA | Morafenobe Airport",
        "SMS": "SMS | Sainte Marie Airport",
        "TMM": "TMM | Toamasina Ambalamanasy Airport",
        "WTA": "WTA | Tambohorano Airport",
        "MOQ": "MOQ | Morondava Airport",
        "WTS": "WTS | Tsiroanomandidy Airport",
        "VAT": "VAT | Vatomandry Airport",
        "WAM": "WAM | Ambatondrazaka Airport",
        "DIE": "DIE | Arrachart Airport",
        "WBE": "WBE | Ankaizina Airport",
        "WMR": "WMR | Mananara Nord Airport",
        "ZWA": "ZWA | Andapa Airport",
        "AMB": "AMB | Ambilobe Airport",
        "WBD": "WBD | Avaratra Airport",
        "WPB": "WPB | Port Bergé Airport",
        "ANM": "ANM | Antsirabe Airport",
        "HVA": "HVA | Analalava Airport",
        "MJN": "MJN | Amborovy Airport",
        "NOS": "NOS | Fascene Airport",
        "DWB": "DWB | Soalala Airport",
        "WMP": "WMP | Mampikony Airport",
        "BPY": "BPY | Besalampy Airport",
        "WMN": "WMN | Maroantsetra Airport",
        "SVB": "SVB | Sambava Airport",
        "TTS": "TTS | Tsaratanana Airport",
        "VOH": "VOH | Vohemar Airport",
        "WAI": "WAI | Ambalabe Airport",
        "WMA": "WMA | Mandritsara Airport",
        "IVA": "IVA | Ampampamena Airport",
        "WBO": "WBO | Antsoa Airport",
        "WMD": "WMD | Mandabe Airport",
        "FTU": "FTU | Tôlanaro Airport",
        "WFI": "WFI | Fianarantsoa Airport",
        "RVA": "RVA | Farafangana Airport",
        "IHO": "IHO | Ihosy Airport",
        "MJA": "MJA | Manja Airport",
        "WVK": "WVK | Manakara Airport",
        "OVA": "OVA | Bekily Airport",
        "MNJ": "MNJ | Mananjary Airport",
        "TDV": "TDV | Samangoky Airport",
        "MXM": "MXM | Morombe Airport",
        "TLE": "TLE | Toliara Airport",
        "VND": "VND | Vangaindrano Airport",
        "BKU": "BKU | Betioky Airport",
        "AMP": "AMP | Ampanihy Airport",
        "WAK": "WAK | Ankazoabo Airport",
        "AZZ": "AZZ | Ambriz Airport",
        "SSY": "SSY | Mbanza Congo Airport",
        "BUG": "BUG | Benguela 17th of September Airport",
        "GGC": "GGC | Lumbala Airport",
        "CAB": "CAB | Cabinda Airport",
        "CFF": "CFF | Cafunfo Airport",
        "PGI": "PGI | Chitato Airport",
        "CBT": "CBT | Catumbela Airport",
        "CTI": "CTI | Cuito Cuanavale Airport",
        "CXM": "CXM | Camaxilo Airport",
        "CAV": "CAV | Cazombo Airport",
        "FND": "FND | Funadhoo Airport",
        "DUE": "DUE | Dundo Airport",
        "FNE": "FNE | Fane Airport",
        "VPE": "VPE | Ngjiva Pereira Airport",
        "NOV": "NOV | Nova Lisboa Airport",
        "SVP": "SVP | Kuito Airport",
        "LLT": "LLT | Lobito Airport",
        "LBZ": "LBZ | Lucapa Airport",
        "LAD": "LAD | Quatro de Fevereiro Airport",
        "LZM": "LZM | Luzamba Airport",
        "MEG": "MEG | Malanje Airport",
        "SPP": "SPP | Menongue Airport",
        "MSZ": "MSZ | Welwitschia Mirabilis International Airport",
        "GXG": "GXG | Negage Airport",
        "PBN": "PBN | Porto Amboim Airport",
        "VHC": "VHC | Saurimo Airport",
        "SZA": "SZA | Soyo Airport",
        "NDD": "NDD | Sumbe Airport",
        "UAL": "UAL | Luau Airport",
        "SDD": "SDD | Lubango Airport",
        "LUO": "LUO | Luena Airport",
        "UGO": "UGO | Uige Airport",
        "CEO": "CEO | Waco Kungo Airport",
        "XGN": "XGN | Xangongo Airport",
        "ARZ": "ARZ | N'zeto Airport",
        "NZA": "NZA | Nzagi Airport",
        "BGB": "BGB | Booue Airport",
        "KDN": "KDN | Ndende Airport",
        "FOU": "FOU | Fougamou Airport",
        "MBC": "MBC | M'Bigou Airport",
        "MGX": "MGX | Moabi Airport",
        "KDJ": "KDJ | Ville Airport",
        "KOU": "KOU | Koulamoutou Mabimbi Airport",
        "MJL": "MJL | Mouilla Ville Airport",
        "OYE": "OYE | Oyem Airport",
        "OKN": "OKN | Okondja Airport",
        "LBQ": "LBQ | Lambarene Airport",
        "MVX": "MVX | Minvoul Airport",
        "BMM": "BMM | Bitam Airport",
        "MFF": "MFF | Moanda Airport",
        "MKB": "MKB | Mekambo Airport",
        "POG": "POG | Port Gentil Airport",
        "OMB": "OMB | Omboue Hospital Airport",
        "IGE": "IGE | Tchongorove Airport",
        "MKU": "MKU | Makokou Airport",
        "LBV": "LBV | Libreville Leon M'ba International Airport",
        "MZC": "MZC | Mitzic Airport",
        "MVB": "MVB | M'Vengue El Hadj Omar Bongo Ondimba International Airport",
        "LTL": "LTL | Lastourville Airport",
        "ZKM": "ZKM | Sette Cama Airport",
        "TCH": "TCH | Tchibanga Airport",
        "MYB": "MYB | Mayumba Airport",
        "FOY": "FOY | Foya Airport",
        "PCP": "PCP | Principe Airport",
        "TMS": "TMS | São Tomé International Airport",
        "ANO": "ANO | Angoche Airport",
        "BEW": "BEW | Beira Airport",
        "FXO": "FXO | Cuamba Airport",
        "VPY": "VPY | Chimoio Airport",
        "IHC": "IHC | Inhaca Airport",
        "INH": "INH | Inhambane Airport",
        "VXC": "VXC | Lichinga Airport",
        "LFB": "LFB | Lumbo Airport",
        "MPM": "MPM | Maputo Airport",
        "MUD": "MUD | Mueda Airport",
        "MZB": "MZB | Mocímboa da Praia Airport",
        "MNC": "MNC | Nacala Airport",
        "APL": "APL | Nampula Airport",
        "POL": "POL | Pemba Airport",
        "PDD": "PDD | Ponta do Ouro Airport",
        "UEL": "UEL | Quelimane Airport",
        "TET": "TET | Chingozi Airport",
        "VNX": "VNX | Vilankulo Airport",
        "VJB": "VJB | Xai-Xai Airport",
        "DES": "DES | Desroches Airport",
        "SEZ": "SEZ | Seychelles International Airport",
        "FSL": "FSL | Fossil Downs Airport",
        "PRI": "PRI | Praslin Airport",
        "BDI": "BDI | Bird Island Airport",
        "DEI": "DEI | Denis Island Airport",
        "FRK": "FRK | Frégate Island Airport",
        "SRH": "SRH | Sarh Airport",
        "OGR": "OGR | Bongor Airport",
        "AEH": "AEH | Abeche Airport",
        "MQQ": "MQQ | Moundou Airport",
        "LTC": "LTC | Lai Airport",
        "ATV": "ATV | Ati Airport",
        "NDJ": "NDJ | N'Djamena International Airport",
        "BKR": "BKR | Bokoro Airport",
        "OTC": "OTC | Bol Airport",
        "MVO": "MVO | Mongo Airport",
        "AMC": "AMC | Am Timan Airport",
        "PLF": "PLF | Pala Airport",
        "OUT": "OUT | Bousso Airport",
        "AMO": "AMO | Mao Airport",
        "FYT": "FYT | Faya Largeau Airport",
        "FUB": "FUB | Fulleborn Airport",
        "BZH": "BZH | Bumi Airport",
        "BUQ": "BUQ | Joshua Mqabuko Nkomo International Airport",
        "CHJ": "CHJ | Chipinge Airport",
        "BFO": "BFO | Buffalo Range Airport",
        "VFA": "VFA | Victoria Falls International Airport",
        "HRE": "HRE | Robert Gabriel Mugabe International Airport",
        "KAB": "KAB | Kariba International Airport",
        "MJW": "MJW | Mahenye Airport",
        "UTA": "UTA | Mutare Airport",
        "MVZ": "MVZ | Masvingo International Airport",
        "GWE": "GWE | Thornhill Air Base",
        "HWN": "HWN | Hwange National Park Airport",
        "WKI": "WKI | Hwange (Town) Airport",
        "CEH": "CEH | Chelinda Malawi Airport",
        "BLZ": "BLZ | Chileka International Airport",
        "CMK": "CMK | Club Makokola Airport",
        "DWA": "DWA | Dwangwa Airport",
        "KGJ": "KGJ | Karonga Airport",
        "KBQ": "KBQ | Kasungu Airport",
        "LLW": "LLW | Lilongwe International Airport",
        "LIX": "LIX | Likoma Island Airport",
        "MAI": "MAI | Mangochi Airport",
        "MYZ": "MYZ | Monkey Bay Airport",
        "LMB": "LMB | Salima Airport",
        "ZZU": "ZZU | Mzuzu Airport",
        "LEF": "LEF | Lebakeng Airport",
        "LRB": "LRB | Leribe Airport",
        "LES": "LES | Lesobeng Airport",
        "MFC": "MFC | Mafeteng Airport",
        "MKH": "MKH | Mokhotlong Airport",
        "MSU": "MSU | Moshoeshoe I International Airport",
        "NKU": "NKU | Nkaus Airport",
        "PEL": "PEL | Pelaneng Airport",
        "UTG": "UTG | Quthing Airport",
        "UNE": "UNE | Qacha's Nek Airport",
        "SHK": "SHK | Sehonghong Airport",
        "SKQ": "SKQ | Sekakes Airport",
        "SOK": "SOK | Semonkong Airport",
        "SHZ": "SHZ | Seshutes Airport",
        "THB": "THB | Thaba-Tseka Airport",
        "TKO": "TKO | Tlokoeng Airport",
        "AIW": "AIW | Ai-Ais Airport",
        "ADI": "ADI | Arandis Airport",
        "GOG": "GOG | Gobabis Airport",
        "GFY": "GFY | Grootfontein Airport",
        "HAL": "HAL | Halali Airport",
        "KAS": "KAS | Karasburg Airport",
        "MPA": "MPA | Katima Mulilo Airport",
        "KMP": "KMP | Keetmanshoop Airport",
        "LHU": "LHU | Lianshulu Airport",
        "LUD": "LUD | Luderitz Airport",
        "MJO": "MJO | Mount Etjo Airport",
        "MQG": "MQG | Midgard Airport",
        "OKU": "OKU | Mokuti Lodge Airport",
        "NNI": "NNI | Namutoni Airport",
        "OND": "OND | Ondangwa Airport",
        "OMG": "OMG | Omega Airport",
        "OMD": "OMD | Oranjemund Airport",
        "OKF": "OKF | Okaukuejo Airport",
        "OPW": "OPW | Opuwa Airport",
        "OHI": "OHI | Oshakati Airport",
        "OTJ": "OTJ | Otjiwarongo Airport",
        "NDU": "NDU | Rundu Airport",
        "RHN": "RHN | Skorpion Mine Airport",
        "SWP": "SWP | Swakopmund Municipal Aerodrome",
        "SZM": "SZM | Sesriem Airstrip",
        "TCY": "TCY | Terrace Bay Airport",
        "TSB": "TSB | Tsumeb Airport",
        "WVB": "WVB | Walvis Bay Airport",
        "ERS": "ERS | Eros Airport",
        "WDH": "WDH | Hosea Kutako International Airport",
        "FIH": "FIH | Ndjili International Airport",
        "NLO": "NLO | Ndolo Airport",
        "MNB": "MNB | Muanda Airport",
        "BOA": "BOA | Boma Airport",
        "LZI": "LZI | Luozi Airport",
        "MAT": "MAT | Tshimpi Airport",
        "NKL": "NKL | N'Kolo-Fuma Airport",
        "INO": "INO | Inongo Airport",
        "NIO": "NIO | Nioki Airport",
        "FDU": "FDU | Bandundu Airport",
        "KRZ": "KRZ | Basango Mboliasa Airport",
        "KKW": "KKW | Kikwit Airport",
        "IDF": "IDF | Idiofa Airport",
        "LUS": "LUS | Lusanga Airport",
        "MSM": "MSM | Masi Manimba Airport",
        "MDK": "MDK | Mbandaka Airport",
        "BSU": "BSU | Basankusu Airport",
        "LIE": "LIE | Libenge Airport",
        "BDT": "BDT | Gbadolite Airport",
        "GMA": "GMA | Gemena Airport",
        "KLI": "KLI | Kotakoli Airport",
        "BMB": "BMB | Bumbar Airport",
        "LIQ": "LIQ | Lisala Airport",
        "BNB": "BNB | Boende Airport",
        "IKL": "IKL | Ikela Airport",
        "FKI": "FKI | Bangoka International Airport",
        "YAN": "YAN | Yangambi Airport",
        "IRP": "IRP | Matari Airport",
        "BUX": "BUX | Bunia Airport",
        "BZU": "BZU | Buta Zega Airport",
        "BKY": "BKY | Bukavu Kavumu Airport",
        "RUE": "RUE | Rughenda Airfield",
        "GOM": "GOM | Goma International Airport",
        "BNC": "BNC | Beni Airport",
        "KND": "KND | Kindu Airport",
        "KLY": "KLY | Kinkungwa Airport",
        "PUN": "PUN | Punia Airport",
        "FBM": "FBM | Lubumbashi International Airport",
        "PWO": "PWO | Pweto Airport",
        "KEC": "KEC | Kasenga Airport",
        "KWZ": "KWZ | Kolwezi Airport",
        "MNO": "MNO | Manono Airport",
        "BDV": "BDV | Moba Airport",
        "FMI": "FMI | Kalemie Airport",
        "KBO": "KBO | Kabalo Airport",
        "KOO": "KOO | Kongolo Airport",
        "KMN": "KMN | Kamina Base Airport",
        "KAP": "KAP | Kapanga Airport",
        "KNM": "KNM | Kaniama Airport",
        "KGA": "KGA | Kananga Airport",
        "LZA": "LZA | Luiza Airport",
        "TSH": "TSH | Tshikapa Airport",
        "LJA": "LJA | Lodja Airport",
        "LBO": "LBO | Lusambo Airport",
        "MEW": "MEW | Mweka Airport",
        "BAN": "BAN | Basongo Airport",
        "PFR": "PFR | Ilebo Airport",
        "MJM": "MJM | Mbuji Mayi Airport",
        "GDJ": "GDJ | Gandajika Airport",
        "KBN": "KBN | Tunta Airport",
        "AKE": "AKE | Akieni Airport",
        "GAX": "GAX | Gamba Airport",
        "GAB": "GAB | Gabbs Airport",
        "BKO": "BKO | Modibo Keita International Airport",
        "GUD": "GUD | Goundam Airport",
        "GAQ": "GAQ | Gao Airport",
        "KNZ": "KNZ | Kenieba Airport",
        "KTX": "KTX | Koutiala Airport",
        "KYS": "KYS | Kayes Dag Dag Airport",
        "MZI": "MZI | Mopti Airport",
        "NRM": "NRM | Nara Airport",
        "NIX": "NIX | Nioro du Sahel Airport",
        "KSS": "KSS | Sikasso Airport",
        "TOM": "TOM | Timbuktu Airport",
        "EYL": "EYL | Yélimané Airport",
        "GAZ": "GAZ | Guasopa Airport",
        "DOC": "DOC | Dornoch Airfield",
        "FLH": "FLH | Flotta Isle Airport",
        "FOA": "FOA | Foula Airfield",
        "OUK": "OUK | Outer Skerries Airport",
        "PSV": "PSV | Papa Stour Airport",
        "GBM": "GBM | Garbaharey Airport",
        "BJL": "BJL | Banjul International Airport",
        "FUE": "FUE | Fuerteventura Airport",
        "GMZ": "GMZ | La Gomera Airport",
        "VDE": "VDE | El Hierro Airport",
        "SPC": "SPC | La Palma Airport",
        "LPA": "LPA | Gran Canaria Airport",
        "ACE": "ACE | César Manrique-Lanzarote Airport",
        "TFS": "TFS | Tenerife Sur Airport",
        "GCV": "GCV | Gravatai Airport",
        "TFN": "TFN | Tenerife Norte-Ciudad de La Laguna Airport",
        "MLN": "MLN | Melilla Airport",
        "GEW": "GEW | Gewoia Airport",
        "OYC": "OYC | Camopi Airport",
        "KBS": "KBS | Bo Airport",
        "GFD": "GFD | Pope Field",
        "GBK": "GBK | Gbangbatok Airport",
        "HGS": "HGS | Hastings Airport",
        "KBA": "KBA | Kabala Airport",
        "KEN": "KEN | Kenema Airport",
        "FNA": "FNA | Lungi International Airport",
        "WYE": "WYE | Yengema Airport",
        "BQE": "BQE | Bubaque Airport",
        "OXB": "OXB | Osvaldo Vieira International Airport",
        "GHE": "GHE | Garachiné Airport",
        "UCN": "UCN | Buchanan Airport",
        "CPA": "CPA | Cape Palmas Airport",
        "SNI": "SNI | Greenville/Sinoe Airport",
        "MLW": "MLW | Spriggs Payne Airport",
        "NIA": "NIA | Nimba Airport",
        "GLP": "GLP | Gulgubip Airport",
        "ROB": "ROB | Roberts International Airport",
        "SAZ": "SAZ | Sasstown Airport",
        "THC": "THC | Tchien Airport",
        "VOI": "VOI | Voinjama Airport",
        "AGA": "AGA | Al Massira Airport",
        "TTA": "TTA | Tan Tan Airport",
        "OZG": "OZG | Zagora Airport",
        "UAR": "UAR | Bouarfa Airport",
        "FEZ": "FEZ | Saïss Airport",
        "ERH": "ERH | Moulay Ali Cherif Airport",
        "MEK": "MEK | Bassatine Airport",
        "OUD": "OUD | Angads Airport",
        "SMW": "SMW | Smara Airport",
        "GMD": "GMD | Ben Slimane Airport",
        "BEM": "BEM | Beni Mellal Airport",
        "RBA": "RBA | Rabat-Salé Airport",
        "VIL": "VIL | Dakhla Airport",
        "ESU": "ESU | Essaouira-Mogador Airport",
        "EUN": "EUN | Hassan I Airport",
        "CMN": "CMN | Mohammed V International Airport",
        "NDR": "NDR | Nador International Airport",
        "RAK": "RAK | Menara Airport",
        "NNA": "NNA | Kenitra Air Base",
        "OZZ": "OZZ | Ouarzazate Airport",
        "GMQ": "GMQ | Golog Maqin Airport",
        "AHU": "AHU | Cherif Al Idrissi Airport",
        "TTU": "TTU | Saniat R'mel Airport",
        "TNG": "TNG | Tangier Ibn Battouta Airport",
        "GNU": "GNU | Goodnews Airport",
        "DSS": "DSS | Blaise Diagne International Airport",
        "GOC": "GOC | Gora Airstrip",
        "KDA": "KDA | Kolda North Airport",
        "ZIG": "ZIG | Ziguinchor Airport",
        "CSK": "CSK | Cap Skirring Airport",
        "KLC": "KLC | Kaolack Airport",
        "DKR": "DKR | Léopold Sédar Senghor International Airport",
        "MAX": "MAX | Ouro Sogui Airport",
        "POD": "POD | Podor Airport",
        "RDT": "RDT | Richard Toll Airport",
        "XLS": "XLS | Saint Louis Airport",
        "BXE": "BXE | Bakel Airport",
        "KGG": "KGG | Kédougou Airport",
        "SMY": "SMY | Simenti Airport",
        "TUD": "TUD | Tambacounda Airport",
        "AEO": "AEO | Aioun el Atrouss Airport",
        "OTL": "OTL | Boutilimit Airport",
        "THI": "THI | Tichitt Airport",
        "TIY": "TIY | Tidjikja Airport",
        "BGH": "BGH | Abbaye Airport",
        "KFA": "KFA | Kiffa Airport",
        "TMD": "TMD | Timbedra Airport",
        "EMN": "EMN | Néma Airport",
        "AJJ": "AJJ | Akjoujt Airport",
        "KED": "KED | Kaédi Airport",
        "MOM": "MOM | Letfotar Airport",
        "NKC": "NKC | Nouakchott–Oumtounsy International Airport",
        "SEY": "SEY | Sélibaby Airport",
        "THT": "THT | Tamchakett Airport",
        "ATR": "ATR | Atar International Airport",
        "FGD": "FGD | Fderik Airport",
        "NDB": "NDB | Nouadhibou International Airport",
        "OUZ": "OUZ | Tazadit Airport",
        "GRC": "GRC | Grand Cess Airport",
        "GMT": "GMT | Granite Mountain Air Station",
        "CIQ": "CIQ | Chiquimula Airport",
        "DON": "DON | Dos Lagunas Airport",
        "ENJ": "ENJ | El Naranjo Airport",
        "PCG": "PCG | Paso Caballos Airport",
        "TKM": "TKM | El Petén Airport",
        "UAX": "UAX | Uaxactun Airport",
        "PKJ": "PKJ | Playa Grande Airport",
        "GTZ": "GTZ | Kirawira B Aerodrome",
        "CKY": "CKY | Conakry International Airport",
        "GUE": "GUE | Guriaso (Keraso) Airport",
        "FIG": "FIG | Fria Airport",
        "FAA": "FAA | Faranah Airport",
        "KSI": "KSI | Kissidougou Airport",
        "LEK": "LEK | Tata Airport",
        "MCA": "MCA | Macenta Airport",
        "NZE": "NZE | Nzérékoré Airport",
        "BKJ": "BKJ | Boké Baralande Airport",
        "SBI": "SBI | Sambailo Airport",
        "GII": "GII | Siguiri Airport",
        "KNN": "KNN | Kankan Airport",
        "SID": "SID | Amílcar Cabral International Airport",
        "NTO": "NTO | Agostinho Neto Airport",
        "BVC": "BVC | Rabil Airport",
        "GVE": "GVE | Gordonsville Municipal Airport",
        "MMO": "MMO | Maio Airport",
        "MTI": "MTI | Mosteiros Airport",
        "RAI": "RAI | Praia International Airport",
        "SFL": "SFL | São Filipe Airport",
        "SNE": "SNE | Preguiça Airport",
        "VXE": "VXE | São Pedro Airport",
        "BCG": "BCG | Bemichi Airport",
        "BTO": "BTO | Botopasi Airport",
        "DOE": "DOE | Djumu-Djomoe Airport",
        "LDO": "LDO | Ladouanie Airport",
        "WSO": "WSO | Washabo Airport",
        "GZI": "GZI | Ghazni Airport",
        "ADD": "ADD | Addis Ababa Bole International Airport",
        "AMH": "AMH | Arba Minch Airport",
        "AXU": "AXU | Axum Airport",
        "BCO": "BCO | Baco Airport",
        "BJR": "BJR | Bahir Dar Airport",
        "BEI": "BEI | Beica Airport",
        "DSE": "DSE | Combolcha Airport",
        "DEM": "DEM | Dembidollo Airport",
        "DBM": "DBM | Debra Marcos Airport",
        "DIR": "DIR | Aba Tenna Dejazmach Yilma International Airport",
        "DBT": "DBT | Debre Tabor Airport",
        "FNH": "FNH | Fincha Airport",
        "GOB": "GOB | Robe Airport",
        "GNN": "GNN | Ghinnir Airport",
        "GMB": "GMB | Gambella Airport",
        "GDQ": "GDQ | Gonder Airport",
        "GDE": "GDE | Gode Airport",
        "GOR": "GOR | Gore Airport",
        "QHR": "QHR | Harar Meda Airport",
        "HUE": "HUE | Humera Airport",
        "JIJ": "JIJ | Wilwal International Airport",
        "JIM": "JIM | Jimma Airport",
        "ABK": "ABK | Kebri Dahar Airport",
        "LFO": "LFO | Kelafo East Airport",
        "AWA": "AWA | Awasa Airport",
        "LLI": "LLI | Lalibella Airport",
        "MKS": "MKS | Mekane Selam Airport",
        "MQX": "MQX | Mekele Airport",
        "ETE": "ETE | Metema Airport",
        "NDM": "NDM | Mendi Airport",
        "MUJ": "MUJ | Mui River Airport",
        "MTF": "MTF | Mizan Teferi Airport",
        "EGL": "EGL | Negele Airport",
        "NEJ": "NEJ | Nejjo Airport",
        "NEK": "NEK | Nekemte Airport",
        "PWI": "PWI | Beles Airport",
        "SXU": "SXU | Soddu Airport",
        "SKR": "SKR | Shakiso Airport",
        "SZE": "SZE | Semera Airport",
        "ASO": "ASO | Asosa Airport",
        "TIE": "TIE | Tippi Airport",
        "WAC": "WAC | Waca Airport",
        "WRA": "WRA | Warder Airport",
        "HAY": "HAY | Haycock Airport",
        "HAZ": "HAZ | Hatzfeldhaven Airport",
        "BJM": "BJM | Bujumbura Melchior Ndadaye International Airport",
        "GID": "GID | Gitega Airport",
        "KRE": "KRE | Kirundo Airport",
        "HBQ": "HBQ | Haibei Qilian Airport",
        "HBT": "HBT | Hambantota Seaplane Base",
        "ALU": "ALU | Alula Airport",
        "BIB": "BIB | Baidoa Airport",
        "CXN": "CXN | Candala Airport",
        "BSY": "BSY | Bardera Airport",
        "HCM": "HCM | Eil Airport",
        "BSA": "BSA | Bosaso / Bender Qassim International Airport",
        "GSR": "GSR | Gardo Airport",
        "HGA": "HGA | Egal International Airport",
        "BBO": "BBO | Berbera Airport",
        "LGX": "LGX | Lugh Ganane Airport",
        "KMU": "KMU | Kismayo Airport",
        "MGQ": "MGQ | Aden Adde International Airport",
        "BLW": "BLW | Beledweyne Airport",
        "CMO": "CMO | Hobyo Airport",
        "GLK": "GLK | Galcaio Airport",
        "CMS": "CMS | Iskushuban Airport",
        "ERA": "ERA | Erigavo Airport",
        "BUO": "BUO | Burao Airport",
        "GGR": "GGR | Garowe Airport",
        "JIB": "JIB | Djibouti-Ambouli Airport",
        "AII": "AII | Ali-Sabieh Airport",
        "MHI": "MHI | Moucha Airport",
        "OBC": "OBC | Obock Airport",
        "TDJ": "TDJ | Tadjoura Airport",
        "EMY": "EMY | El Minya Airport",
        "SQK": "SQK | Sidi Barrani Airport",
        "DBB": "DBB | El Alamein International Airport",
        "AAC": "AAC | El Arish International Airport",
        "ATZ": "ATZ | Asyut International Airport",
        "ALY": "ALY | El Nuzha Airport",
        "HBE": "HBE | Borg El Arab International Airport",
        "ABS": "ABS | Abu Simbel Airport",
        "CAI": "CAI | Cairo International Airport",
        "CCE": "CCE | Capital International Airport",
        "DAK": "DAK | Dakhla Airport",
        "HRG": "HRG | Hurghada International Airport",
        "EGH": "EGH | El Gora Airport",
        "UVL": "UVL | El Kharga Airport",
        "LXR": "LXR | Luxor International Airport",
        "RMF": "RMF | Marsa Alam International Airport",
        "MUH": "MUH | Marsa Matruh Airport",
        "HEO": "HEO | Haelogo Airport",
        "GSQ": "GSQ | Sharq El Owainat International Airport",
        "PSD": "PSD | Port Said Airport",
        "SKV": "SKV | Saint Catherine International Airport",
        "HMB": "HMB | Sohag International Airport",
        "SSH": "SSH | Sharm El Sheikh International Airport",
        "ASW": "ASW | Aswan International Airport",
        "SEW": "SEW | Siwa Oasis North Airport",
        "SPX": "SPX | Sphinx International Airport",
        "TCP": "TCP | Taba International Airport",
        "ELT": "ELT | El Tor Airport",
        "ASM": "ASM | Asmara International Airport",
        "MSW": "MSW | Massawa International Airport",
        "ASA": "ASA | Assab International Airport",
        "TES": "TES | Tessenei Airport",
        "HPV": "HPV | Princeville Airport",
        "HIA": "HIA | Lianshui Airport",
        "HIL": "HIL | Shilavo Airport",
        "JUB": "JUB | Juba International Airport",
        "ASV": "ASV | Amboseli Airport",
        "HKB": "HKB | Healy Lake Airport",
        "EDL": "EDL | Eldoret International Airport",
        "EYS": "EYS | Eliye Springs Airport",
        "KLK": "KLK | Kalokol Airport",
        "GAS": "GAS | Garissa Airport",
        "HOA": "HOA | Hola Airport",
        "NBO": "NBO | Jomo Kenyatta International Airport",
        "KEU": "KEU | Keekorok Airport",
        "GGM": "GGM | Kakamega Airport",
        "KIS": "KIS | Kisumu Airport",
        "ILU": "ILU | Kilaguni Airport",
        "KEY": "KEY | Kericho Airport",
        "KTL": "KTL | Kitale Airport",
        "LKG": "LKG | Lokichoggio Airport",
        "LOK": "LOK | Lodwar Airport",
        "LAU": "LAU | Manda Airstrip",
        "LOY": "LOY | Loyengalani Airport",
        "NDE": "NDE | Mandera Airport",
        "RBT": "RBT | Marsabit Airport",
        "JJM": "JJM | Mulika Lodge Airport",
        "MYD": "MYD | Malindi Airport",
        "MBA": "MBA | Moi International Airport",
        "MRE": "MRE | Mara Serena Lodge Airstrip",
        "OYL": "OYL | Moyale Airport",
        "NYE": "NYE | Nyeri Airport",
        "NUU": "NUU | Nakuru Airport",
        "WIL": "WIL | Nairobi Wilson Airport",
        "NYK": "NYK | Nanyuki Airport",
        "UAS": "UAS | Buffalo Spring",
        "UKA": "UKA | Ukunda Airstrip",
        "WJR": "WJR | Wajir Airport",
        "SRX": "SRX | Gardabya Airport",
        "TOB": "TOB | Gamal Abdel Nasser Airport",
        "GHT": "GHT | Ghat Airport",
        "AKF": "AKF | Kufra Airport",
        "BEN": "BEN | Benina International Airport",
        "MJI": "MJI | Mitiga Airport",
        "LAQ": "LAQ | Al Abraq International Airport",
        "SEB": "SEB | Sabha Airport",
        "TIP": "TIP | Tripoli International Airport",
        "LMQ": "LMQ | Marsa Brega Airport",
        "NFR": "NFR | Nafurah 1 Airport",
        "HUQ": "HUQ | Hon Airport",
        "LTD": "LTD | Ghadames East Airport",
        "ZIS": "ZIS | Alzintan Airport",
        "WAX": "WAX | Zwara Airport",
        "EDQ": "EDQ | Erandique Airport",
        "HOO": "HOO | Nhon Co Airfield",
        "HRC": "HRC | Sary Su Airport",
        "GYI": "GYI | Gisenyi Airport",
        "BTQ": "BTQ | Butare Airport",
        "KGL": "KGL | Kigali International Airport",
        "RHG": "RHG | Ruhengeri Airport",
        "KME": "KME | Kamembe Airport",
        "ATB": "ATB | Atbara Airport",
        "EDB": "EDB | El Debba Airport",
        "DOG": "DOG | Dongola Airport",
        "RSS": "RSS | Damazin Airport",
        "HGI": "HGI | Paloich Airport Heliport",
        "ELF": "ELF | El Fasher Airport",
        "DNX": "DNX | Galegu Airport",
        "EGN": "EGN | Geneina Airport",
        "HEG": "HEG | Heglig Airport",
        "HSJ": "HSJ | Shangjie Airport",
        "KSL": "KSL | Kassala Airport",
        "GBU": "GBU | Khashm El Girba Airport",
        "KDX": "KDX | Kadugli Airport",
        "RBX": "RBX | Rumbek Airport",
        "MWE": "MWE | Merowe New Airport",
        "NUD": "NUD | En Nahud Airport",
        "UYL": "UYL | Nyala Airport",
        "NHF": "NHF | New Halfa Airport",
        "EBD": "EBD | El Obeid Airport",
        "PZU": "PZU | Port Sudan New International Airport",
        "KRT": "KRT | Khartoum International Airport",
        "MAK": "MAK | Malakal Airport",
        "WHF": "WHF | Wadi Halfa Airport",
        "DNI": "DNI | Wad Medani Airport",
        "WUU": "WUU | Wau Airport",
        "ZLX": "ZLX | Zalingei Airport",
        "ARK": "ARK | Arusha Airport",
        "BKZ": "BKZ | Bukoba Airport",
        "DAR": "DAR | Julius Nyerere International Airport",
        "DOD": "DOD | Dodoma Airport",
        "MBI": "MBI | Songwe Airport",
        "IRI": "IRI | Iringa Airport",
        "TKQ": "TKQ | Kigoma Airport",
        "KIY": "KIY | Kilwa Airport",
        "JRO": "JRO | Kilimanjaro International Airport",
        "LDI": "LDI | Lindi Airport",
        "LKY": "LKY | Lake Manyara Airport",
        "HTM": "HTM | Khatgal Airport",
        "MFA": "MFA | Mafia Airport",
        "MWN": "MWN | Mwadui Airport",
        "XMI": "XMI | Masasi Airport",
        "NPY": "NPY | Mpanda Airport",
        "QSI": "QSI | Moshi Airport",
        "MYW": "MYW | Mtwara Airport",
        "MUZ": "MUZ | Musoma Airport",
        "MWZ": "MWZ | Mwanza Airport",
        "NCH": "NCH | Nachingwea Airport",
        "JOM": "JOM | Njombe Airport",
        "PMA": "PMA | Pemba Airport",
        "SEU": "SEU | Seronera Airport",
        "SGX": "SGX | Songea Airport",
        "SUT": "SUT | Sumbawanga Airport",
        "SHY": "SHY | Shinyanga Airport",
        "TBO": "TBO | Tabora Airport",
        "TGT": "TGT | Tanga Airport",
        "ZNZ": "ZNZ | Abeid Amani Karume International Airport",
        "RUA": "RUA | Arua Airport",
        "EBB": "EBB | Entebbe International Airport",
        "ULU": "ULU | Gulu Airport",
        "JIN": "JIN | Jinja Airport",
        "PAF": "PAF | Pakuba Airfield",
        "KSE": "KSE | Kasese Airport",
        "MBQ": "MBQ | Mbarara Airport",
        "KCU": "KCU | Masindi Airport",
        "SRT": "SRT | Soroti Airport",
        "TRY": "TRY | Tororo Airport",
        "HWA": "HWA | Hawabango Airport",
        "HYL": "HYL | Hollis Clark Bay Seaplane Base",
        "IBI": "IBI | Iboki Airport",
        "IBL": "IBL | Indigo Bay Lodge Airport",
        "ICO": "ICO | Sicogon Airstrip",
        "PPJ": "PPJ | Pulau Panjang Airport",
        "JBB": "JBB | Notohadinegoro Airport",
        "BWX": "BWX | Banyuwangi International Airport",
        "BXW": "BXW | Bawean - Harun Thohir Airport",
        "AAS": "AAS | Apalapsili Airport",
        "AGD": "AGD | Anggi Airport",
        "AKQ": "AKQ | Gunung Batin Airport",
        "AYW": "AYW | Ayawasi Airport",
        "BJG": "BJG | Boalang Airport",
        "BXM": "BXM | Batom Airport",
        "DRH": "DRH | Dabra Airport",
        "ELR": "ELR | Elelim Airport",
        "EWE": "EWE | Ewer Airport",
        "FOO": "FOO | Kornasoren Airfield",
        "GAV": "GAV | Gag Island Airport",
        "IUL": "IUL | Ilu Airport",
        "KBF": "KBF | Karubaga Airport",
        "KBX": "KBX | Kambuaya Airport",
        "KCD": "KCD | Kamur Airport",
        "KCI": "KCI | Kon Airport",
        "KOD": "KOD | Kotabangun Airport",
        "KRC": "KRC | Departi Parbo Airport",
        "LLN": "LLN | Kelila Airport",
        "LWE": "LWE | Lewoleba Airport",
        "LYK": "LYK | Lunyuk Airport",
        "MSI": "MSI | Masalembo Airport",
        "MUF": "MUF | Muting Airport",
        "NAF": "NAF | Banaina Airport",
        "OBD": "OBD | Obano Airport",
        "PUM": "PUM | Pomala Airport",
        "PWL": "PWL | Purwokerto Airport",
        "RAQ": "RAQ | Sugimanuru Airport",
        "RKI": "RKI | Rokot Airport",
        "RUF": "RUF | Yuruf Airport",
        "SAE": "SAE | Sangir Airport",
        "TBM": "TBM | Tumbang Samba Airport",
        "TMY": "TMY | Tiom Airport",
        "ZEG": "ZEG | Senggo Airport",
        "UGU": "UGU | Bilogai-Sugapa Airport",
        "IDN": "IDN | Indagen Airport",
        "IMA": "IMA | Iamalele Airport",
        "IMG": "IMG | Inhaminga Airport",
        "PYG": "PYG | Pakyong Airport",
        "VDY": "VDY | Jindal Vijaynagar Airport",
        "JGB": "JGB | Jagdalpur Airport",
        "NVY": "NVY | Neyveli Airport",
        "RJI": "RJI | Rajouri Airport",
        "TEI": "TEI | Tezu Airport",
        "INE": "INE | Chinde Airport",
        "IOK": "IOK | Iokea Airport",
        "IOP": "IOP | Ioma Airport",
        "KHA": "KHA | Khaneh Airport",
        "IRU": "IRU | Iranamadu Seaplane Base",
        "ISG": "ISG | New Ishigaki Airport",
        "ITK": "ITK | Itokama Airport",
        "IVI": "IVI | Viveros Island Airport",
        "JGD": "JGD | Jiagedaqi Airport",
        "JIC": "JIC | Jinchuan Airport",
        "JIO": "JIO | Jos Orno Imsula Airport",
        "JIQ": "JIQ | Qianjiang Wulingshan Airport",
        "JJD": "JJD | Jericoacoara - Comandante Ariston Pessoa Regional Airport",
        "JLA": "JLA | Quartz Creek Airport",
        "JNZ": "JNZ | Jinzhou Bay Airport",
        "JOP": "JOP | Josephstaal Airport",
        "JUH": "JUH | Chizhou Jiuhuashan Airport",
        "AMK": "AMK | Animas Air Park",
        "BDX": "BDX | Broadus Airport",
        "EUE": "EUE | Eureka Airport",
        "KPT": "KPT | Jackpot Airport/Hayden Field",
        "RLA": "RLA | Rolla Downtown Airport",
        "FID": "FID | Elizabeth Field",
        "HUD": "HUD | Humboldt Municipal Airport",
        "TWD": "TWD | Jefferson County International Airport",
        "MVM": "MVM | Kayenta Airport",
        "HCC": "HCC | Columbia County Airport",
        "AHD": "AHD | Ardmore Downtown Executive Airport",
        "GCW": "GCW | Grand Canyon West Airport",
        "CKE": "CKE | Lampson Field",
        "ROF": "ROF | Montague-Yreka Rohrer Field",
        "CNE": "CNE | Fremont County Airport",
        "RSX": "RSX | Rouses Point Seaplane Base",
        "COP": "COP | Cooperstown-Westville Airport",
        "CIL": "CIL | Council Airport",
        "IRB": "IRB | Iraan Municipal Airport",
        "GNF": "GNF | Gansner Field",
        "CHZ": "CHZ | Chiloquin State Airport",
        "LTW": "LTW | St. Mary's County Regional Airport",
        "AHF": "AHF | Arapahoe Municipal Airport",
        "PCT": "PCT | Princeton Airport",
        "CTO": "CTO | Calverton Executive Airpark",
        "NRI": "NRI | Grand Lake Regional Airport",
        "GTP": "GTP | Grants Pass Airport",
        "NLE": "NLE | Jerry Tyler Memorial Airport",
        "GCD": "GCD | Grand Coulee Dam Airport",
        "VLE": "VLE | Valle Airport",
        "FPY": "FPY | Perry-Foley Airport",
        "NTJ": "NTJ | Manti-Ephraim Airport",
        "SBO": "SBO | Salina Gunnison Airport",
        "JVI": "JVI | Central Jersey Regional Airport",
        "UCE": "UCE | Eunice Airport",
        "GOL": "GOL | Gold Beach Municipal Airport",
        "KKT": "KKT | Kentland Municipal Airport",
        "PRW": "PRW | Prentice Airport",
        "EGP": "EGP | Maverick County Memorial International Airport",
        "BLD": "BLD | Boulder City-Lake Mead International Airport",
        "MFH": "MFH | Mesquite International Airport",
        "ECA": "ECA | Iosco County Airport",
        "FMU": "FMU | Florence Municipal Airport",
        "OTS": "OTS | Anacortes Airport",
        "ROL": "ROL | Roosevelt Municipal Airport",
        "CTK": "CTK | Canton Municipal Airport",
        "WPO": "WPO | North Fork Valley Airport",
        "ATE": "ATE | Antlers Municipal Airport",
        "ASQ": "ASQ | Austin Airport",
        "AAF": "AAF | Apalachicola Regional Airport",
        "ABE": "ABE | Lehigh Valley International Airport",
        "ABI": "ABI | Abilene Regional Airport",
        "ABQ": "ABQ | Albuquerque International Sunport",
        "ABR": "ABR | Aberdeen Regional Airport",
        "ABY": "ABY | Southwest Georgia Regional Airport",
        "ACB": "ACB | Antrim County Airport",
        "ACK": "ACK | Nantucket Memorial Airport",
        "ACT": "ACT | Waco Regional Airport",
        "ACV": "ACV | California Redwood Coast-Humboldt County Airport",
        "ACY": "ACY | Atlantic City International Airport",
        "ADG": "ADG | Lenawee County Airport",
        "ADT": "ADT | Ada Regional Airport",
        "ADM": "ADM | Ardmore Municipal Airport",
        "ADS": "ADS | Addison Airport",
        "ADW": "ADW | Joint Base Andrews",
        "KAE": "KAE | Kake Seaplane Base",
        "AEL": "AEL | Albert Lea Municipal Airport",
        "AEX": "AEX | Alexandria International Airport",
        "KAF": "KAF | Karato Airport",
        "AFF": "AFF | USAF Academy Airfield",
        "WSG": "WSG | Washington County Airport",
        "AFN": "AFN | Jaffrey Airport Silver Ranch Airport",
        "AFO": "AFO | Afton Municipal Airport",
        "AFW": "AFW | Fort Worth Alliance Airport",
        "AGC": "AGC | Allegheny County Airport",
        "AGO": "AGO | Magnolia Municipal Airport / Ralph C Weiser Field",
        "AGS": "AGS | Augusta Regional At Bush Field",
        "AHC": "AHC | Amedee Army Air Field",
        "AHH": "AHH | Amery Municipal Airport",
        "AHN": "AHN | Athens Ben Epps Airport",
        "AIA": "AIA | Alliance Municipal Airport",
        "AID": "AID | Anderson Municipal Darlington Field",
        "AIK": "AIK | Aiken Regional Airport",
        "AIO": "AIO | Atlantic Municipal Airport",
        "AIV": "AIV | George Downer Airport",
        "AIZ": "AIZ | Lee C Fine Memorial Airport",
        "KAK": "KAK | Kar Airport",
        "AKO": "AKO | Colorado Plains Regional Airport",
        "AKC": "AKC | Akron Executive Airport",
        "ALB": "ALB | Albany International Airport",
        "ALI": "ALI | Alice International Airport",
        "ALM": "ALM | Alamogordo White Sands Regional Airport",
        "ALN": "ALN | St Louis Regional Airport",
        "ALO": "ALO | Waterloo Regional Airport",
        "ALS": "ALS | San Luis Valley Regional Airport/Bergman Field",
        "ALW": "ALW | Walla Walla Regional Airport",
        "ALX": "ALX | Thomas C Russell Field",
        "AMA": "AMA | Rick Husband Amarillo International Airport",
        "AMN": "AMN | Gratiot Community Airport",
        "AMW": "AMW | Ames Municipal Airport",
        "ANB": "ANB | Anniston Regional Airport",
        "AND": "AND | Anderson Regional Airport",
        "SLT": "SLT | Salida Airport Harriett Alexander Field",
        "ANP": "ANP | Lee Airport",
        "ANQ": "ANQ | Tri State Steuben County Airport",
        "ANW": "ANW | Ainsworth Regional Airport",
        "ANY": "ANY | Anthony Municipal Airport",
        "AOH": "AOH | Lima Allen County Airport",
        "AOO": "AOO | Altoona Blair County Airport",
        "APA": "APA | Centennial Airport",
        "APC": "APC | Napa County Airport",
        "APF": "APF | Naples Municipal Airport",
        "APG": "APG | Phillips Army Air Field",
        "APH": "APH | A P Hill AAF (Fort A P Hill) Airport",
        "APN": "APN | Alpena County Regional Airport",
        "APT": "APT | Marion County Brown Field",
        "APV": "APV | Apple Valley Airport",
        "KAQ": "KAQ | Kamulai Airport",
        "ARA": "ARA | Acadiana Regional Airport",
        "ARB": "ARB | Ann Arbor Municipal Airport",
        "ARG": "ARG | Walnut Ridge Regional Airport",
        "WHT": "WHT | Wharton Regional Airport",
        "AUZ": "AUZ | Aurora Municipal Airport",
        "ART": "ART | Watertown International Airport",
        "ARV": "ARV | Lakeland-Noble F. Lee Memorial field",
        "BFT": "BFT | Beaufort County Airport",
        "ASE": "ASE | Aspen-Pitkin Co/Sardy Field",
        "SPZ": "SPZ | Springdale Municipal Airport",
        "ASH": "ASH | Nashua Airport / Boire Field",
        "ASL": "ASL | Harrison County Airport",
        "ASN": "ASN | Talladega Municipal Airport",
        "AST": "AST | Astoria Regional Airport",
        "ASX": "ASX | John F Kennedy Memorial Airport",
        "ASY": "ASY | Ashley Municipal Airport",
        "ATL": "ATL | Hartsfield Jackson Atlanta International Airport",
        "ATS": "ATS | Artesia Municipal Airport",
        "ATW": "ATW | Appleton International Airport",
        "ATY": "ATY | Watertown Regional Airport",
        "AUG": "AUG | Augusta State Airport",
        "AUM": "AUM | Austin Municipal Airport",
        "AUN": "AUN | Auburn Municipal Airport",
        "AUO": "AUO | Auburn University Regional Airport",
        "AUS": "AUS | Austin Bergstrom International Airport",
        "AUW": "AUW | Wausau Downtown Airport",
        "AVL": "AVL | Asheville Regional Airport",
        "AVO": "AVO | Avon Park Executive Airport",
        "AVP": "AVP | Wilkes Barre Scranton International Airport",
        "AVW": "AVW | Marana Regional Airport",
        "AVX": "AVX | Catalina Airport",
        "AWM": "AWM | West Memphis Municipal Airport",
        "AXG": "AXG | Algona Municipal Airport",
        "AXN": "AXN | Chandler Field",
        "AXS": "AXS | Altus Quartz Mountain Regional Airport",
        "AXV": "AXV | Neil Armstrong Airport",
        "AXX": "AXX | Angel Fire Airport",
        "AYS": "AYS | Waycross Ware County Airport",
        "TUH": "TUH | Arnold Air Force Base",
        "AZO": "AZO | Kalamazoo Battle Creek International Airport",
        "BAB": "BAB | Beale Air Force Base",
        "BAD": "BAD | Barksdale Air Force Base",
        "BAF": "BAF | Westfield-Barnes Regional Airport",
        "CLU": "CLU | Columbus Municipal Airport",
        "BAM": "BAM | Battle Mountain Airport",
        "BBB": "BBB | Benson Municipal Airport",
        "BBD": "BBD | Curtis Field",
        "BTN": "BTN | Marlboro County Jetport H.E. Avent Field",
        "BBW": "BBW | Broken Bow Municipal Airport",
        "BCB": "BCB | Virginia Tech Montgomery Executive Airport",
        "BCE": "BCE | Bryce Canyon Airport",
        "BCT": "BCT | Boca Raton Airport",
        "BDE": "BDE | Baudette International Airport",
        "BDG": "BDG | Blanding Municipal Airport",
        "BDL": "BDL | Bradley International Airport",
        "BDR": "BDR | Igor I Sikorsky Memorial Airport",
        "WBU": "WBU | Boulder Municipal Airport",
        "KBE": "KBE | Bell Island Hot Springs Seaplane Base",
        "BEC": "BEC | Beech Factory Airport",
        "BED": "BED | Laurence G Hanscom Field",
        "BEH": "BEH | Southwest Michigan Regional Airport",
        "BFD": "BFD | Bradford Regional Airport",
        "BFF": "BFF | Western Neb. Rgnl/William B. Heilig Airport",
        "BFI": "BFI | Boeing Field King County International Airport",
        "BFL": "BFL | Meadows Field",
        "BFM": "BFM | Mobile Downtown Airport",
        "BFR": "BFR | Virgil I Grissom Municipal Airport",
        "BGD": "BGD | Hutchinson County Airport",
        "BGE": "BGE | Decatur County Industrial Air Park",
        "BGM": "BGM | Greater Binghamton/Edwin A Link field",
        "BGR": "BGR | Bangor International Airport",
        "KBH": "KBH | Kahama Airstrip",
        "BHB": "BHB | Hancock County-Bar Harbor Airport",
        "BHM": "BHM | Birmingham-Shuttlesworth International Airport",
        "BID": "BID | Block Island State Airport",
        "BIE": "BIE | Beatrice Municipal Airport",
        "BIF": "BIF | Biggs Army Air Field (Fort Bliss)",
        "BIH": "BIH | Eastern Sierra Regional Airport",
        "BIL": "BIL | Billings Logan International Airport",
        "BIS": "BIS | Bismarck Municipal Airport",
        "BIX": "BIX | Keesler Air Force Base",
        "BJC": "BJC | Rocky Mountain Metropolitan Airport",
        "BJI": "BJI | Bemidji Regional Airport",
        "BJJ": "BJJ | Wayne County Airport",
        "BKD": "BKD | Stephens County Airport",
        "BKE": "BKE | Baker City Municipal Airport",
        "BFK": "BFK | Buckley Space Force Base",
        "BKL": "BKL | Burke Lakefront Airport",
        "BKT": "BKT | Allen C Perkinson Blackstone Army Air Field",
        "BKW": "BKW | Raleigh County Memorial Airport",
        "BKX": "BKX | Brookings Regional Airport",
        "BLF": "BLF | Mercer County Airport",
        "BLH": "BLH | Blythe Airport",
        "BLI": "BLI | Bellingham International Airport",
        "BLM": "BLM | Monmouth Executive Airport",
        "BLU": "BLU | Blue Canyon Nyack Airport",
        "BLV": "BLV | Scott AFB/Midamerica Airport",
        "KBM": "KBM | Kabwum",
        "BMC": "BMC | Brigham City Regional Airport",
        "BMG": "BMG | Monroe County Airport",
        "BMI": "BMI | Central Illinois Regional Airport at Bloomington-Normal",
        "BML": "BML | Berlin Regional Airport",
        "BMT": "BMT | Beaumont Municipal Airport",
        "BNA": "BNA | Nashville International Airport",
        "BNG": "BNG | Banning Municipal Airport",
        "BNL": "BNL | Barnwell Regional Airport",
        "BNO": "BNO | Burns Municipal Airport",
        "BNW": "BNW | Boone Municipal Airport",
        "BOI": "BOI | Boise Air Terminal/Gowen Field",
        "BOS": "BOS | Logan International Airport",
        "BOW": "BOW | Bartow Executive Airport",
        "HCA": "HCA | Big Spring Mc Mahon-Wrinkle Airport",
        "BPI": "BPI | Miley Memorial Field",
        "WMH": "WMH | Ozark Regional Airport",
        "BPT": "BPT | Jack Brooks Regional Airport",
        "BQK": "BQK | Brunswick Golden Isles Airport",
        "BRD": "BRD | Brainerd Lakes Regional Airport",
        "BRL": "BRL | Southeast Iowa Regional Airport",
        "BRO": "BRO | Brownsville South Padre Island International Airport",
        "BRY": "BRY | Samuels Field",
        "KBT": "KBT | Kaben Airport",
        "BTF": "BTF | Skypark Airport",
        "BTL": "BTL | Battle Creek Executive Airport at Kellogg Field",
        "BTM": "BTM | Bert Mooney Airport",
        "TTO": "TTO | Britton Municipal Airport",
        "BTP": "BTP | Pittsburgh/Butler Regional Airport",
        "BTR": "BTR | Baton Rouge Metropolitan Airport",
        "BTV": "BTV | Burlington International Airport",
        "BTY": "BTY | Beatty Airport",
        "BUB": "BUB | Cram Field",
        "BUF": "BUF | Buffalo Niagara International Airport",
        "BUM": "BUM | Butler Memorial Airport",
        "BUR": "BUR | Bob Hope Airport",
        "BFP": "BFP | Beaver County Airport",
        "BVO": "BVO | Bartlesville Municipal Airport",
        "MVW": "MVW | Skagit Regional Airport",
        "BVX": "BVX | Batesville Regional Airport",
        "BVY": "BVY | Beverly Regional Airport",
        "BWC": "BWC | Brawley Municipal Airport",
        "BWD": "BWD | Brownwood Regional Airport",
        "BWG": "BWG | Bowling Green Warren County Regional Airport",
        "BWI": "BWI | Baltimore/Washington International Thurgood Marshall Airport",
        "WAH": "WAH | Harry Stern Airport",
        "BWM": "BWM | Bowman Regional Airport",
        "BXA": "BXA | George R Carr Memorial Air Field",
        "BXK": "BXK | Buckeye Municipal Airport",
        "BYG": "BYG | Johnson County Airport",
        "BYH": "BYH | Arkansas International Airport",
        "BYI": "BYI | Burley Municipal Airport",
        "BYS": "BYS | Bicycle Lake Army Air Field",
        "BBC": "BBC | Bay City Regional Airport",
        "BZN": "BZN | Gallatin Field",
        "XES": "XES | Grand Geneva Resort Airport",
        "PLY": "PLY | Plymouth Municipal Airport",
        "CLG": "CLG | New Coalinga Municipal Airport",
        "CAD": "CAD | Wexford County Airport",
        "CAE": "CAE | Columbia Metropolitan Airport",
        "CIG": "CIG | Craig Moffat Airport",
        "CAK": "CAK | Akron Canton Regional Airport",
        "CAO": "CAO | Clayton Municipal Airpark",
        "CAR": "CAR | Caribou Municipal Airport",
        "CBE": "CBE | Greater Cumberland Regional Airport",
        "CBF": "CBF | Council Bluffs Municipal Airport",
        "CBK": "CBK | Shalz Field",
        "CBM": "CBM | Columbus Air Force Base",
        "KCC": "KCC | Coffman Cove Seaplane Base",
        "CCB": "CCB | Cable Airport",
        "CCR": "CCR | Buchanan Field",
        "CCY": "CCY | Northeast Iowa Regional Airport",
        "LLX": "LLX | Caledonia County Airport",
        "CDC": "CDC | Cedar City Regional Airport",
        "CDH": "CDH | Harrell Field",
        "CDN": "CDN | Woodward Field",
        "CDR": "CDR | Chadron Municipal Airport",
        "CDS": "CDS | Childress Municipal Airport",
        "CDW": "CDW | Essex County Airport",
        "CEA": "CEA | Cessna Aircraft Field",
        "CEC": "CEC | Jack Mc Namara Field Airport",
        "CEF": "CEF | Westover ARB/Metropolitan Airport",
        "CEU": "CEU | Oconee County Regional Airport",
        "CEV": "CEV | Mettel Field",
        "CEW": "CEW | Bob Sikes Airport",
        "CEY": "CEY | Kyle Oakley Field",
        "CEZ": "CEZ | Cortez Municipal Airport",
        "CFD": "CFD | Coulter Field",
        "TZC": "TZC | Tuscola Area Airport",
        "CFT": "CFT | Greenlee County Airport",
        "CFV": "CFV | Coffeyville Municipal Airport",
        "CGE": "CGE | Cambridge Dorchester Airport",
        "CGF": "CGF | Cuyahoga County Airport",
        "CGI": "CGI | Cape Girardeau Regional Airport",
        "CGS": "CGS | College Park Airport",
        "CGZ": "CGZ | Casa Grande Municipal Airport",
        "CHA": "CHA | Chattanooga Metropolitan Airport (Lovell Field)",
        "CHK": "CHK | Chickasha Municipal Airport",
        "CHO": "CHO | Charlottesville Albemarle Airport",
        "CHS": "CHS | Charleston International Airport",
        "CIC": "CIC | Chico Municipal Airport",
        "CID": "CID | The Eastern Iowa Airport",
        "CIN": "CIN | Arthur N Neu Airport",
        "CIR": "CIR | Cairo Regional Airport",
        "CIU": "CIU | Chippewa County International Airport",
        "CKA": "CKA | Kegelman AF Aux Field",
        "CKB": "CKB | North Central West Virginia Airport",
        "GRM": "GRM | Grand Marais Cook County Airport",
        "CKM": "CKM | Fletcher Field",
        "CKN": "CKN | Crookston Municipal Kirkwood Field",
        "CKV": "CKV | Clarksville–Montgomery County Regional Airport",
        "KCL": "KCL | Chignik Lagoon Airport",
        "CLE": "CLE | Cleveland Hopkins International Airport",
        "CLI": "CLI | Clintonville Municipal Airport",
        "CLK": "CLK | Clinton Regional Airport",
        "CLL": "CLL | Easterwood Field",
        "CLM": "CLM | William R Fairchild International Airport",
        "CLR": "CLR | Cliff Hatfield Memorial Airport",
        "CLS": "CLS | Chehalis Centralia Airport",
        "CLT": "CLT | Charlotte Douglas International Airport",
        "CLW": "CLW | Clearwater Air Park",
        "CMH": "CMH | John Glenn Columbus International Airport",
        "CMI": "CMI | University of Illinois Willard Airport",
        "CMX": "CMX | Houghton County Memorial Airport",
        "CMY": "CMY | Sparta Fort Mc Coy Airport",
        "KCN": "KCN | Chernofski Harbor Seaplane Base",
        "CNH": "CNH | Claremont Municipal Airport",
        "CNK": "CNK | Blosser Municipal Airport",
        "CNM": "CNM | Cavern City Air Terminal",
        "CNO": "CNO | Chino Airport",
        "CNU": "CNU | Chanute Martin Johnson Airport",
        "CNW": "CNW | TSTC Waco Airport",
        "CNY": "CNY | Canyonlands Field",
        "COD": "COD | Yellowstone Regional Airport",
        "COE": "COE | Coeur D'Alene - Pappy Boyington Field",
        "COF": "COF | Patrick Air Force Base",
        "COI": "COI | Merritt Island Airport",
        "COM": "COM | Coleman Municipal Airport",
        "CON": "CON | Concord Municipal Airport",
        "COS": "COS | City of Colorado Springs Municipal Airport",
        "COT": "COT | Cotulla-La Salle County Airport",
        "COU": "COU | Columbia Regional Airport",
        "CPM": "CPM | Compton Woodley Airport",
        "CPR": "CPR | Casper-Natrona County International Airport",
        "CPS": "CPS | St Louis Downtown Airport",
        "HCW": "HCW | Cheraw Municipal Airport/Lynch Bellinger Field",
        "KCR": "KCR | Colorado Creek Airport",
        "CRE": "CRE | Grand Strand Airport",
        "CRG": "CRG | Jacksonville Executive at Craig Airport",
        "CRO": "CRO | Corcoran Airport",
        "CRP": "CRP | Corpus Christi International Airport",
        "CLD": "CLD | McClellan-Palomar Airport",
        "CRS": "CRS | C David Campbell Field Corsicana Municipal Airport",
        "CRT": "CRT | Z M Jack Stell Field",
        "CRW": "CRW | Yeager Airport",
        "CRX": "CRX | Roscoe Turner Airport",
        "CSG": "CSG | Columbus Metropolitan Airport",
        "CSM": "CSM | Clinton Sherman Airport",
        "CSQ": "CSQ | Creston Municipal Airport",
        "CSV": "CSV | Crossville Memorial Whitson Field",
        "CTB": "CTB | Cut Bank International Airport",
        "CTY": "CTY | Cross City Airport",
        "CTZ": "CTZ | Sampson County Airport",
        "CUB": "CUB | Jim Hamilton L.B. Owens Airport",
        "CUH": "CUH | Cushing Municipal Airport",
        "CVG": "CVG | Cincinnati Northern Kentucky International Airport",
        "CKK": "CKK | Sharp County Regional Airport",
        "CVN": "CVN | Clovis Municipal Airport",
        "CVO": "CVO | Corvallis Municipal Airport",
        "CVS": "CVS | Cannon Air Force Base",
        "CWA": "CWA | Central Wisconsin Airport",
        "KIP": "KIP | Kickapoo Downtown Airport",
        "CWF": "CWF | Chennault International Airport",
        "CWI": "CWI | Clinton Municipal Airport",
        "CXL": "CXL | Calexico International Airport",
        "CXO": "CXO | Conroe-North Houston Regional Airport",
        "CSN": "CSN | Carson Airport",
        "HAR": "HAR | Capital City Airport",
        "CYS": "CYS | Cheyenne Regional Jerry Olson Field",
        "CZT": "CZT | Dimmit County Airport",
        "VEX": "VEX | Tioga Municipal Airport",
        "DAA": "DAA | Davison Army Air Field",
        "DAB": "DAB | Daytona Beach International Airport",
        "DAG": "DAG | Barstow Daggett Airport",
        "DAL": "DAL | Dallas Love Field",
        "DAN": "DAN | Danville Regional Airport",
        "DAY": "DAY | James M Cox Dayton International Airport",
        "DBN": "DBN | W H 'Bud' Barron Airport",
        "DBQ": "DBQ | Dubuque Regional Airport",
        "DCA": "DCA | Ronald Reagan Washington National Airport",
        "DCU": "DCU | Pryor Field Regional Airport",
        "DDC": "DDC | Dodge City Regional Airport",
        "DEC": "DEC | Decatur Airport",
        "DEH": "DEH | Decorah Municipal Airport",
        "DEN": "DEN | Denver International Airport",
        "DET": "DET | Coleman A. Young Municipal Airport",
        "DFI": "DFI | Defiance Memorial Airport",
        "DFW": "DFW | Dallas Fort Worth International Airport",
        "DGL": "DGL | Douglas Municipal Airport",
        "DGW": "DGW | Converse County Airport",
        "DHN": "DHN | Dothan Regional Airport",
        "DHT": "DHT | Dalhart Municipal Airport",
        "DIK": "DIK | Dickinson Theodore Roosevelt Regional Airport",
        "DKK": "DKK | Chautauqua County-Dunkirk Airport",
        "DLL": "DLL | Dillon County Airport",
        "DLF": "DLF | Laughlin Air Force Base",
        "DLH": "DLH | Duluth International Airport",
        "DLN": "DLN | Dillon Airport",
        "DLS": "DLS | Columbia Gorge Regional Airport",
        "DMA": "DMA | Davis Monthan Air Force Base",
        "DMN": "DMN | Deming Municipal Airport",
        "DMO": "DMO | Sedalia Memorial Airport",
        "DNL": "DNL | Daniel Field",
        "DNN": "DNN | Dalton Municipal Airport",
        "DNS": "DNS | Denison Municipal Airport",
        "DNV": "DNV | Vermilion Regional Airport",
        "DOV": "DOV | Dover Air Force Base",
        "KDP": "KDP | Kandep Airport",
        "DPA": "DPA | Dupage Airport",
        "DPG": "DPG | Michael AAF (Dugway Proving Ground) Airport",
        "KDQ": "KDQ | Kamberatoro Airport",
        "DRA": "DRA | Desert Rock Airport",
        "DRI": "DRI | Beauregard Regional Airport",
        "DRE": "DRE | Drummond Island Airport",
        "DRO": "DRO | Durango La Plata County Airport",
        "DRT": "DRT | Del Rio International Airport",
        "KDS": "KDS | Kamaran Downs Airport",
        "DSM": "DSM | Des Moines International Airport",
        "DSV": "DSV | Dansville Municipal Airport",
        "DTA": "DTA | Delta Municipal Airport",
        "DTL": "DTL | Detroit Lakes Airport - Wething Field",
        "DTN": "DTN | Shreveport Downtown Airport",
        "DSI": "DSI | Destin Executive Airport",
        "DTW": "DTW | Detroit Metropolitan Wayne County Airport",
        "DUA": "DUA | Eaker Field",
        "DUC": "DUC | Halliburton Field",
        "DUG": "DUG | Bisbee Douglas International Airport",
        "DUJ": "DUJ | DuBois Regional Airport",
        "DVL": "DVL | Devils Lake Regional Airport",
        "DVN": "DVN | Davenport Municipal Airport",
        "NOT": "NOT | Marin County Airport - Gnoss Field",
        "NSL": "NSL | Slayton Municipal Airport",
        "DVT": "DVT | Phoenix Deer Valley Airport",
        "KDW": "KDW | Victoria Reservoir Seaplane Base",
        "DWH": "DWH | David Wayne Hooks Memorial Airport",
        "DXR": "DXR | Danbury Municipal Airport",
        "DYL": "DYL | Doylestown Airport",
        "DYS": "DYS | Dyess Air Force Base",
        "KDZ": "KDZ | Polgolla Reservoir Seaplane Base",
        "VPG": "VPG | Vipingo Estate Airport",
        "KTJ": "KTJ | Kichwa Tembo Airport",
        "OSJ": "OSJ | Ol Seki Airstrip",
        "OLG": "OLG | Olare Orok Airfield",
        "HKR": "HKR | Mara North Conservancy Airstrip",
        "ANA": "ANA | Angama Airport",
        "OLX": "OLX | Olkiombo Airport",
        "KRV": "KRV | Kerio Valley Airport",
        "KIU": "KIU | Kiunga Airport",
        "LBK": "LBK | Liboi Airport",
        "LBN": "LBN | Lake Baringo Airport",
        "LKU": "LKU | Lake Rudolf Airport",
        "MUM": "MUM | Mumias Airport",
        "MIF": "MIF | Roy Hurd Memorial Airport",
        "CCG": "CCG | Crane County Airport",
        "ESO": "ESO | Ohkay Owingeh Airport",
        "WTR": "WTR | Whiteriver Airport",
        "ALE": "ALE | Alpine Casparis Municipal Airport",
        "BGT": "BGT | Bagdad Airport",
        "EAN": "EAN | Phifer Airfield",
        "EAR": "EAR | Kearney Regional Airport",
        "EAT": "EAT | Pangborn Memorial Airport",
        "EAU": "EAU | Chippewa Valley Regional Airport",
        "KEB": "KEB | Nanwalek Airport",
        "EBS": "EBS | Webster City Municipal Airport",
        "ECG": "ECG | Elizabeth City Regional Airport & Coast Guard Air Station",
        "ECS": "ECS | Mondell Field",
        "EDE": "EDE | Northeastern Regional Airport",
        "ETS": "ETS | Enterprise Municipal Airport",
        "EDW": "EDW | Edwards Air Force Base",
        "EED": "EED | Needles Airport",
        "EEN": "EEN | Dillant Hopkins Airport",
        "EFD": "EFD | Ellington Airport",
        "EFK": "EFK | Northeast Kingdom International Airport",
        "EFW": "EFW | Jefferson Municipal Airport",
        "KEG": "KEG | Keglsugl Airport",
        "EGE": "EGE | Eagle County Regional Airport",
        "EGI": "EGI | Duke Field",
        "EGV": "EGV | Eagle River Union Airport",
        "KEK": "KEK | Ekwok Airport",
        "EKA": "EKA | Murray Field",
        "EKI": "EKI | Elkhart Municipal Airport",
        "EKN": "EKN | Elkins-Randolph Co-Jennings Randolph Field",
        "EKO": "EKO | Elko Regional Airport",
        "EKX": "EKX | Addington Field / Elizabethtown Regional Airport",
        "ELA": "ELA | Eagle Lake Airport",
        "ELD": "ELD | South Arkansas Regional At Goodwin Field",
        "ELK": "ELK | Elk City Regional Business Airport",
        "ELM": "ELM | Elmira Corning Regional Airport",
        "ELN": "ELN | Bowers Field",
        "LYU": "LYU | Ely Municipal Airport",
        "ELP": "ELP | El Paso International Airport",
        "ELY": "ELY | Ely Airport Yelland Field",
        "ELZ": "ELZ | Wellsville Municipal ArptTarantine Field",
        "EMM": "EMM | Kemmerer Municipal Airport",
        "EMP": "EMP | Emporia Municipal Airport",
        "EMT": "EMT | San Gabriel Valley Airport",
        "END": "END | Vance Air Force Base",
        "ENL": "ENL | Centralia Municipal Airport",
        "ENV": "ENV | Wendover Airport",
        "ENW": "ENW | Kenosha Regional Airport",
        "EOK": "EOK | Keokuk Municipal Airport",
        "EPH": "EPH | Ephrata Municipal Airport",
        "EDK": "EDK | Captain Jack Thomas El Dorado Airport",
        "ERI": "ERI | Erie International Tom Ridge Field",
        "ERR": "ERR | Errol Airport",
        "ERV": "ERV | Kerrville Municipal Louis Schreiner Field",
        "ESC": "ESC | Delta County Airport",
        "ESF": "ESF | Esler Army Airfield / Esler Regional Airport",
        "ESN": "ESN | Easton Airport / Newnam Field",
        "EST": "EST | Estherville Municipal Airport",
        "ESW": "ESW | Easton State Airport",
        "ETB": "ETB | West Bend Municipal Airport",
        "ETN": "ETN | Eastland Municipal Airport",
        "EUF": "EUF | Weedon Field",
        "EUG": "EUG | Mahlon Sweet Field",
        "EVM": "EVM | Eveleth Virginia Municipal Airport",
        "EVV": "EVV | Evansville Regional Airport",
        "EVW": "EVW | Evanston-Uinta County Airport-Burns Field",
        "EWB": "EWB | New Bedford Regional Airport",
        "EWK": "EWK | Newton City-County Airport",
        "EWN": "EWN | Coastal Carolina Regional Airport",
        "EWR": "EWR | Newark Liberty International Airport",
        "KEX": "KEX | Kanabea Airport",
        "EYW": "EYW | Key West International Airport",
        "KEZ": "KEZ | Kelani-Peliyagoda Seaplane Base",
        "WIB": "WIB | Wilbarger County Airport",
        "RBK": "RBK | French Valley Airport",
        "FAF": "FAF | Felker Army Air Field",
        "FAM": "FAM | Farmington Regional Airport",
        "FAR": "FAR | Hector International Airport",
        "FAT": "FAT | Fresno Yosemite International Airport",
        "FAY": "FAY | Fayetteville Regional Airport - Grannis Field",
        "FBG": "FBG | Simmons Army Air Field",
        "FBL": "FBL | Faribault Municipal Airport-Liz Wall Strohfus Field",
        "FBR": "FBR | Fort Bridger Airport",
        "FBY": "FBY | Fairbury Municipal Airport",
        "FCH": "FCH | Fresno Chandler Executive Airport",
        "FCM": "FCM | Flying Cloud Airport",
        "FCS": "FCS | Butts AAF (Fort Carson) Air Field",
        "FCY": "FCY | Forrest City Municipal Airport",
        "FDK": "FDK | Frederick Municipal Airport",
        "FDR": "FDR | Frederick Regional Airport",
        "FDY": "FDY | Findlay Airport",
        "FEP": "FEP | Albertus Airport",
        "FET": "FET | Fremont Municipal Airport",
        "FFA": "FFA | First Flight Airport",
        "FFL": "FFL | Fairfield Municipal Airport",
        "FFM": "FFM | Fergus Falls Municipal Airport - Einar Mickelson Field",
        "FFO": "FFO | Wright-Patterson Air Force Base",
        "FFT": "FFT | Capital City Airport",
        "MSC": "MSC | Falcon Field",
        "FRD": "FRD | Friday Harbor Airport",
        "FHU": "FHU | Sierra Vista Municipal Airport / Libby Army Air Field",
        "FKL": "FKL | Venango Regional Airport",
        "FKN": "FKN | Franklin Regional Airport",
        "FLD": "FLD | Fond du Lac County Airport",
        "FLG": "FLG | Flagstaff Pulliam International Airport",
        "FLL": "FLL | Fort Lauderdale Hollywood International Airport",
        "FLO": "FLO | Florence Regional Airport",
        "FLP": "FLP | Marion County Regional Airport",
        "FLV": "FLV | Sherman Army Air Field",
        "FLX": "FLX | Fallon Municipal Airport",
        "FME": "FME | Tipton Airport",
        "FMH": "FMH | Cape Cod Coast Guard Air Station",
        "FMN": "FMN | Four Corners Regional Airport",
        "FMY": "FMY | Page Field",
        "FNL": "FNL | Northern Colorado Regional Airport",
        "FNT": "FNT | Bishop International Airport",
        "FOD": "FOD | Fort Dodge Regional Airport",
        "FOE": "FOE | Topeka Regional Airport",
        "FOK": "FOK | Francis S Gabreski Airport",
        "FIL": "FIL | Fillmore Municipal Airport",
        "FPR": "FPR | St Lucie County International Airport",
        "FRG": "FRG | Republic Airport",
        "FRH": "FRH | French Lick Municipal Airport",
        "FRI": "FRI | Marshall Army Air Field",
        "FRM": "FRM | Fairmont Municipal Airport",
        "FRR": "FRR | Front Royal Warren County Airport",
        "FSD": "FSD | Sioux Falls Regional Airport / Joe Foss Field",
        "FSI": "FSI | Henry Post Army Air Field (Fort Sill)",
        "FSK": "FSK | Fort Scott Municipal Airport",
        "FSM": "FSM | Fort Smith Regional Airport",
        "FST": "FST | Fort Stockton Pecos County Airport",
        "FSU": "FSU | Fort Sumner Municipal Airport",
        "FMS": "FMS | Fort Madison Municipal Airport",
        "FTK": "FTK | Godman Army Air Field",
        "FTW": "FTW | Fort Worth Meacham International Airport",
        "FTY": "FTY | Fulton County Airport Brown Field",
        "FUL": "FUL | Fullerton Municipal Airport",
        "WFK": "WFK | Northern Aroostook Regional Airport",
        "FWA": "FWA | Fort Wayne International Airport",
        "FXE": "FXE | Fort Lauderdale Executive Airport",
        "FXY": "FXY | Forest City Municipal Airport",
        "FYM": "FYM | Fayetteville Municipal Airport",
        "FYV": "FYV | Drake Field",
        "GAD": "GAD | Northeast Alabama Regional Airport",
        "GAG": "GAG | Gage Airport",
        "GAI": "GAI | Montgomery County Airpark",
        "GBD": "GBD | Great Bend Municipal Airport",
        "GBG": "GBG | Galesburg Municipal Airport",
        "GBR": "GBR | Walter J. Koladza Airport",
        "GCC": "GCC | Gillette Campbell County Airport",
        "JDA": "JDA | Grant Co Regional/Ogilvie Field",
        "GCK": "GCK | Garden City Regional Airport",
        "GCN": "GCN | Grand Canyon National Park Airport",
        "GCY": "GCY | Greeneville Municipal Airport",
        "GDM": "GDM | Gardner Municipal Airport",
        "GDV": "GDV | Dawson Community Airport",
        "GDW": "GDW | Gladwin Zettel Memorial Airport",
        "GED": "GED | Delaware Coastal Airport",
        "GEG": "GEG | Spokane International Airport",
        "GEY": "GEY | South Big Horn County Airport",
        "GFK": "GFK | Grand Forks International Airport",
        "GFL": "GFL | Floyd Bennett Memorial Airport",
        "GGE": "GGE | Georgetown County Airport",
        "GGG": "GGG | East Texas Regional Airport",
        "GGW": "GGW | Wokal Field/Glasgow-Valley County Airport",
        "GHM": "GHM | Centerville Municipal Airport",
        "GIF": "GIF | Winter Haven Regional Airport - Gilbert Field",
        "GJT": "GJT | Grand Junction Regional Airport",
        "MEJ": "MEJ | Port Meadville Airport",
        "GKT": "GKT | Gatlinburg-Pigeon Forge Airport",
        "GLD": "GLD | Renner Field-Goodland Municipal Airport",
        "GLE": "GLE | Gainesville Municipal Airport",
        "GLH": "GLH | Mid Delta Regional Airport",
        "GLR": "GLR | Gaylord Regional Airport",
        "GLS": "GLS | Scholes International At Galveston Airport",
        "GLW": "GLW | Glasgow Municipal Airport",
        "GMU": "GMU | Greenville Downtown Airport",
        "GNG": "GNG | Gooding Municipal Airport",
        "GNT": "GNT | Grants-Milan Municipal Airport",
        "GNV": "GNV | Gainesville Regional Airport",
        "GOK": "GOK | Guthrie-Edmond Regional Airport",
        "GON": "GON | Groton New London Airport",
        "FCA": "FCA | Glacier Park International Airport",
        "GPT": "GPT | Gulfport Biloxi International Airport",
        "GPZ": "GPZ | Grand Rapids Itasca Co-Gordon Newstrom field",
        "GQQ": "GQQ | Galion Municipal Airport",
        "GRB": "GRB | Austin Straubel International Airport",
        "GRD": "GRD | Greenwood County Airport",
        "GRE": "GRE | Greenville Airport",
        "GRF": "GRF | Gray Army Air Field",
        "GRI": "GRI | Central Nebraska Regional Airport",
        "GRK": "GRK | Killeen-Fort Hood Regional Airport / Robert Gray Army Air Field",
        "GRN": "GRN | Gordon Municipal Airport",
        "GRR": "GRR | Gerald R. Ford International Airport",
        "GSB": "GSB | Seymour Johnson Air Force Base",
        "GSH": "GSH | Goshen Municipal Airport",
        "GSO": "GSO | Piedmont Triad International Airport",
        "GSP": "GSP | Greenville Spartanburg International Airport",
        "GTF": "GTF | Great Falls International Airport",
        "GTG": "GTG | Grantsburg Municipal Airport",
        "GTR": "GTR | Golden Triangle Regional Airport",
        "GUC": "GUC | Gunnison Crested Butte Regional Airport",
        "GUP": "GUP | Gallup Municipal Airport",
        "GUS": "GUS | Grissom Air Reserve Base",
        "GUY": "GUY | Guymon Municipal Airport",
        "GVL": "GVL | Lee Gilmer Memorial Airport",
        "GVT": "GVT | Majors Airport",
        "KGW": "KGW | Kagi Airport",
        "GWO": "GWO | Greenwood–Leflore Airport",
        "GWS": "GWS | Glenwood Springs Municipal Airport",
        "KGX": "KGX | Grayling Airport",
        "GXY": "GXY | Greeley–Weld County Airport",
        "GDC": "GDC | Donaldson Field Airport",
        "PNX": "PNX | North Texas Regional Airport/Perrin Field",
        "GYR": "GYR | Phoenix Goodyear Airport",
        "GYY": "GYY | Gary Chicago International Airport",
        "KGZ": "KGZ | Glacier Creek Airport",
        "HAB": "HAB | Marion County Rankin Fite Airport",
        "HAF": "HAF | Half Moon Bay Airport",
        "HAI": "HAI | Three Rivers Municipal Dr Haines Airport",
        "HAO": "HAO | Butler Co Regional Airport - Hogan Field",
        "HBG": "HBG | Hattiesburg Bobby L Chain Municipal Airport",
        "HBR": "HBR | Hobart Regional Airport",
        "HDE": "HDE | Brewster Field",
        "HDN": "HDN | Yampa Valley Airport",
        "HEE": "HEE | Thompson-Robbins Airport",
        "MNZ": "MNZ | Manassas Regional Airport/Harry P. Davis Field",
        "HEZ": "HEZ | Hardy-Anders Field / Natchez-Adams County Airport",
        "HFD": "HFD | Hartford Brainard Airport",
        "HFF": "HFF | Mackall Army Air Field",
        "HGR": "HGR | Hagerstown Regional Richard A Henson Field",
        "HHR": "HHR | Jack Northrop Field Hawthorne Municipal Airport",
        "HUJ": "HUJ | Stan Stamper Municipal Airport",
        "HIB": "HIB | Range Regional Airport",
        "HIE": "HIE | Mount Washington Regional Airport",
        "HIF": "HIF | Hill Air Force Base",
        "HII": "HII | Lake Havasu City International Airport",
        "HIO": "HIO | Portland Hillsboro Airport",
        "HKA": "HKA | Blytheville Municipal Airport",
        "HKS": "HKS | Hawkins Field",
        "HKY": "HKY | Hickory Regional Airport",
        "HLB": "HLB | Hillenbrand Industries Airport",
        "HLC": "HLC | Hill City Municipal Airport",
        "HLG": "HLG | Wheeling Ohio County Airport",
        "HLM": "HLM | Park Township Airport",
        "HLN": "HLN | Helena Regional Airport",
        "HLR": "HLR | Hood Army Air Field",
        "HMN": "HMN | Holloman Air Force Base",
        "HMT": "HMT | Hemet Ryan Airport",
        "HNB": "HNB | Huntingburg Airport",
        "HSH": "HSH | Henderson Executive Airport",
        "HOB": "HOB | Lea County Regional Airport",
        "HON": "HON | Huron Regional Airport",
        "HOP": "HOP | Campbell AAF (Fort Campbell) Air Field",
        "HOT": "HOT | Memorial Field",
        "HOU": "HOU | William P Hobby Airport",
        "HPN": "HPN | Westchester County Airport",
        "HPT": "HPT | Hampton Municipal Airport",
        "HPY": "HPY | Baytown Airport",
        "HQM": "HQM | Bowerman Airport",
        "HES": "HES | Hermiston Municipal Airport",
        "HRL": "HRL | Valley International Airport",
        "HRO": "HRO | Boone County Airport",
        "HSB": "HSB | Harrisburg-Raleigh Airport",
        "HNC": "HNC | Billy Mitchell Airport",
        "THP": "THP | Hot Springs County Airport",
        "HSI": "HSI | Hastings Municipal Airport",
        "HSP": "HSP | Ingalls Field",
        "HST": "HST | Homestead ARB Airport",
        "HSV": "HSV | Huntsville International Carl T Jones Field",
        "HTH": "HTH | Hawthorne Industrial Airport",
        "HTL": "HTL | Roscommon County - Blodgett Memorial Airport",
        "HTO": "HTO | East Hampton Airport",
        "HTS": "HTS | Tri-State/Milton J. Ferguson Field",
        "HTW": "HTW | Lawrence County Airpark",
        "HUA": "HUA | Redstone Army Air Field",
        "HUF": "HUF | Terre Haute Regional Airport Hulman Field",
        "HUL": "HUL | Houlton International Airport",
        "HUM": "HUM | Houma Terrebonne Airport",
        "HUT": "HUT | Hutchinson Municipal Airport",
        "HVE": "HVE | Hanksville Airport",
        "HVN": "HVN | Tweed New Haven Airport",
        "HVR": "HVR | Havre City County Airport",
        "HVS": "HVS | Hartsville Regional Airport",
        "HWD": "HWD | Hayward Executive Airport",
        "HWO": "HWO | North Perry Airport",
        "WSH": "WSH | Brookhaven Airport",
        "HHH": "HHH | Hilton Head Airport",
        "HYA": "HYA | Barnstable Municipal Boardman Polando Field",
        "HYR": "HYR | Sawyer County Airport",
        "HYS": "HYS | Hays Regional Airport",
        "HZL": "HZL | Hazleton Municipal Airport",
        "JFN": "JFN | Northeast Ohio Regional Airport",
        "IAB": "IAB | Mc Connell Air Force Base",
        "IAD": "IAD | Washington Dulles International Airport",
        "IAG": "IAG | Niagara Falls International Airport",
        "IAH": "IAH | George Bush Intercontinental Houston Airport",
        "KIB": "KIB | Ivanof Bay Seaplane Base",
        "ICL": "ICL | Schenck Field",
        "ICT": "ICT | Wichita Eisenhower National Airport",
        "IDA": "IDA | Idaho Falls Regional Airport",
        "IDI": "IDI | Indiana County/Jimmy Stewart Fld/ Airport",
        "IDP": "IDP | Independence Municipal Airport",
        "XPR": "XPR | Pine Ridge Airport",
        "IFA": "IFA | Iowa Falls Municipal Airport",
        "IFP": "IFP | Laughlin Bullhead International Airport",
        "IGM": "IGM | Kingman Airport",
        "IKK": "IKK | Greater Kankakee Airport",
        "KIL": "KIL | Kilwa Airport",
        "ILE": "ILE | Skylark Field",
        "ILG": "ILG | New Castle Airport",
        "ILM": "ILM | Wilmington International Airport",
        "ILN": "ILN | Wilmington Airpark",
        "IML": "IML | Imperial Municipal Airport",
        "IMM": "IMM | Immokalee Regional Airport",
        "MDN": "MDN | Madison Municipal Airport",
        "IMT": "IMT | Ford Airport",
        "IND": "IND | Indianapolis International Airport",
        "INK": "INK | Winkler County Airport",
        "INL": "INL | Falls International Airport",
        "INS": "INS | Creech Air Force Base",
        "INT": "INT | Smith Reynolds Airport",
        "INW": "INW | Winslow Lindbergh Regional Airport",
        "IOW": "IOW | Iowa City Municipal Airport",
        "IPL": "IPL | Imperial County Airport",
        "IPT": "IPT | Williamsport Regional Airport",
        "KIQ": "KIQ | Kira Airport",
        "IRK": "IRK | Kirksville Regional Airport",
        "IRS": "IRS | Kirsch Municipal Airport",
        "ISM": "ISM | Kissimmee Gateway Airport",
        "ISO": "ISO | Kinston Regional Jetport At Stallings Field",
        "ISP": "ISP | Long Island Mac Arthur Airport",
        "ISQ": "ISQ | Schoolcraft County Airport",
        "ISW": "ISW | Alexander Field South Wood County Airport",
        "ITH": "ITH | Ithaca Tompkins Regional Airport",
        "AZA": "AZA | Phoenix–Mesa Gateway Airport",
        "IWD": "IWD | Gogebic Iron County Airport",
        "ISS": "ISS | Wiscasset Airport",
        "IWS": "IWS | West Houston Airport",
        "JCI": "JCI | New Century Aircenter Airport",
        "IYK": "IYK | Inyokern Airport",
        "SQA": "SQA | Santa Ynez Airport",
        "FRY": "FRY | Eastern Slopes Regional Airport",
        "JAC": "JAC | Jackson Hole Airport",
        "JAN": "JAN | Jackson-Medgar Wiley Evers International Airport",
        "JAS": "JAS | Jasper County Airport Bell Field",
        "JAX": "JAX | Jacksonville International Airport",
        "JBR": "JBR | Jonesboro Municipal Airport",
        "JCT": "JCT | Kimble County Airport",
        "JDN": "JDN | Jordan Airport",
        "JEF": "JEF | Jefferson City Memorial Airport",
        "JFK": "JFK | John F Kennedy International Airport",
        "JHW": "JHW | Chautauqua County-Jamestown Airport",
        "GUF": "GUF | Jack Edwards National Airport",
        "JLN": "JLN | Joplin Regional Airport",
        "JMS": "JMS | Jamestown Regional Airport",
        "JOT": "JOT | Joliet Regional Airport",
        "USA": "USA | Concord-Padgett Regional Airport",
        "JKV": "JKV | Cherokee County Airport",
        "JST": "JST | John Murtha Johnstown Cambria County Airport",
        "JVL": "JVL | Southern Wisconsin Regional Airport",
        "KJX": "KJX | Blang Pidie Airport",
        "JXN": "JXN | Jackson County Reynolds Field",
        "KKB": "KKB | Kitoi Bay Seaplane Base",
        "KIC": "KIC | Mesa Del Rey Airport",
        "KKL": "KKL | Karluk Lake Seaplane Base",
        "KLS": "KLS | Southwest Washington Regional Airport",
        "KKU": "KKU | Ekuk Airport",
        "DTH": "DTH | Furnace Creek Airport",
        "BXS": "BXS | Borrego Valley Airport",
        "RBF": "RBF | Big Bear City Airport",
        "TRH": "TRH | Trona Airport",
        "LAA": "LAA | Southeast Colorado Regional Airport",
        "LAF": "LAF | Purdue University Airport",
        "LAL": "LAL | Lakeland Linder International Airport",
        "LAM": "LAM | Los Alamos Airport",
        "LAN": "LAN | Capital City Airport",
        "LAR": "LAR | Laramie Regional Airport",
        "LAS": "LAS | McCarran International Airport",
        "LAW": "LAW | Lawton Fort Sill Regional Airport",
        "LAX": "LAX | Los Angeles International Airport",
        "LBB": "LBB | Lubbock Preston Smith International Airport",
        "LBE": "LBE | Arnold Palmer Regional Airport",
        "LBF": "LBF | North Platte Regional Airport Lee Bird Field",
        "LBL": "LBL | Liberal Mid-America Regional Airport",
        "LBT": "LBT | Lumberton Regional Airport",
        "LJN": "LJN | Texas Gulf Coast Regional Airport",
        "LCH": "LCH | Lake Charles Regional Airport",
        "LCI": "LCI | Laconia Municipal Airport",
        "LCK": "LCK | Rickenbacker International Airport",
        "LCQ": "LCQ | Lake City Gateway Airport",
        "LDJ": "LDJ | Linden Airport",
        "LDM": "LDM | Mason County Airport",
        "LEB": "LEB | Lebanon Municipal Airport",
        "LEE": "LEE | Leesburg International Airport",
        "LEM": "LEM | Lemmon Municipal Airport",
        "LEW": "LEW | Auburn Lewiston Municipal Airport",
        "LEX": "LEX | Blue Grass Airport",
        "LFI": "LFI | Langley Air Force Base",
        "LFK": "LFK | Angelina County Airport",
        "LFT": "LFT | Lafayette Regional Airport",
        "LGA": "LGA | La Guardia Airport",
        "LGB": "LGB | Long Beach Airport (Daugherty Field)",
        "LGC": "LGC | LaGrange Callaway Airport",
        "LGD": "LGD | La Grande/Union County Airport",
        "LGF": "LGF | Laguna Army Airfield",
        "LGU": "LGU | Logan-Cache Airport",
        "LHV": "LHV | William T. Piper Memorial Airport",
        "LIY": "LIY | Wright AAF (Fort Stewart)/Midcoast Regional Airport",
        "LFN": "LFN | Triangle North Executive Airport",
        "LIC": "LIC | Limon Municipal Airport",
        "LIT": "LIT | Bill & Hillary Clinton National Airport/Adams Field",
        "LKP": "LKP | Lake Placid Airport",
        "LOW": "LOW | Louisa County Airport/Freeman Field",
        "LKV": "LKV | Lake County Airport",
        "CHL": "CHL | Challis Airport",
        "LMS": "LMS | Louisville Winston County Airport",
        "LMT": "LMT | Crater Lake-Klamath Regional Airport",
        "LNA": "LNA | Palm Beach County Park Airport",
        "LND": "LND | Hunt Field",
        "LNK": "LNK | Lincoln Airport",
        "LNN": "LNN | Lake County Executive Airport",
        "LNP": "LNP | Lonesome Pine Airport",
        "LNR": "LNR | Tri-County Regional Airport",
        "LNS": "LNS | Lancaster Airport",
        "LOL": "LOL | Derby Field",
        "BBX": "BBX | Wings Field",
        "LOT": "LOT | Lewis University Airport",
        "LOU": "LOU | Bowman Field",
        "LOZ": "LOZ | London-Corbin Airport/Magee Field",
        "LPC": "LPC | Lompoc Airport",
        "LQK": "LQK | Pickens County Airport",
        "LRD": "LRD | Laredo International Airport",
        "LRF": "LRF | Little Rock Air Force Base",
        "LRJ": "LRJ | Le Mars Municipal Airport",
        "LRU": "LRU | Las Cruces International Airport",
        "LSB": "LSB | Lordsburg Municipal Airport",
        "LSE": "LSE | La Crosse Municipal Airport",
        "LSF": "LSF | Lawson Army Air Field (Fort Benning)",
        "LSK": "LSK | Lusk Municipal Airport",
        "LSN": "LSN | Los Banos Municipal Airport",
        "LSV": "LSV | Nellis Air Force Base",
        "LTS": "LTS | Altus Air Force Base",
        "LUF": "LUF | Luke Air Force Base",
        "LUK": "LUK | Cincinnati Municipal Airport Lunken Field",
        "LUL": "LUL | Hesler Noble Field",
        "LVK": "LVK | Livermore Municipal Airport",
        "LVL": "LVL | Brunswick Municipal Airport",
        "LVM": "LVM | Mission Field",
        "LVS": "LVS | Las Vegas Municipal Airport",
        "LWB": "LWB | Greenbrier Valley Airport",
        "LWC": "LWC | Lawrence Municipal Airport",
        "LWL": "LWL | Wells Municipal Airport/Harriet Field",
        "LWM": "LWM | Lawrence Municipal Airport",
        "LWS": "LWS | Lewiston Nez Perce County Airport",
        "LWT": "LWT | Lewistown Municipal Airport",
        "LWV": "LWV | Lawrenceville Vincennes International Airport",
        "LXN": "LXN | Jim Kelly Field",
        "LXV": "LXV | Lake County Airport",
        "LYH": "LYH | Lynchburg Regional Preston Glenn Field",
        "LYO": "LYO | Lyons-Rice County Municipal Airport",
        "LZU": "LZU | Gwinnett County Briscoe Field",
        "PCU": "PCU | Poplarville Pearl River County Airport",
        "MLK": "MLK | Malta Airport",
        "MAC": "MAC | Macon Downtown Airport",
        "MAE": "MAE | Madera Municipal Airport",
        "MAF": "MAF | Midland International Airport",
        "MAW": "MAW | Malden Regional Airport",
        "KMB": "KMB | Koinambe Airport",
        "MBG": "MBG | Mobridge Municipal Airport",
        "MBL": "MBL | Manistee County Blacker Airport",
        "DXE": "DXE | Bruce Campbell Field",
        "MBS": "MBS | MBS International Airport",
        "MBY": "MBY | Omar N Bradley Airport",
        "MCB": "MCB | McComb-Pike County Airport / John E Lewis Field",
        "MCC": "MCC | Mc Clellan Airfield",
        "MCD": "MCD | Mackinac Island Airport",
        "MCE": "MCE | Merced Regional Macready Field",
        "MCF": "MCF | Mac Dill Air Force Base",
        "MCI": "MCI | Kansas City International Airport",
        "MCK": "MCK | Mc Cook Ben Nelson Regional Airport",
        "MCN": "MCN | Middle Georgia Regional Airport",
        "MCO": "MCO | Orlando International Airport",
        "MCW": "MCW | Mason City Municipal Airport",
        "MDD": "MDD | Midland Airpark",
        "MDH": "MDH | Southern Illinois Airport",
        "XMD": "XMD | Madison Municipal Airport",
        "MDT": "MDT | Harrisburg International Airport",
        "MDW": "MDW | Chicago Midway International Airport",
        "MDF": "MDF | Taylor County Airport",
        "MXE": "MXE | Laurinburg Maxton Airport",
        "MEI": "MEI | Key Field / Meridian Regional Airport",
        "MEM": "MEM | Memphis International Airport",
        "MER": "MER | Castle Airport",
        "MEV": "MEV | Minden-Tahoe Airport",
        "KMF": "KMF | Kamina Airport",
        "MFD": "MFD | Mansfield Lahm Regional Airport",
        "MFE": "MFE | McAllen Miller International Airport",
        "MFI": "MFI | Marshfield Municipal Airport",
        "MFR": "MFR | Rogue Valley International Medford Airport",
        "MFV": "MFV | Accomack County Airport",
        "MGC": "MGC | Michigan City Municipal Airport",
        "MGE": "MGE | Dobbins Air Reserve Base",
        "MGJ": "MGJ | Orange County Airport",
        "MGM": "MGM | Montgomery Regional (Dannelly Field) Airport",
        "MGR": "MGR | Moultrie Municipal Airport",
        "MGW": "MGW | Morgantown Municipal Walter L. Bill Hart Field",
        "MGY": "MGY | Dayton-Wright Brothers Airport",
        "MHE": "MHE | Mitchell Municipal Airport",
        "MHK": "MHK | Manhattan Regional Airport",
        "MHL": "MHL | Marshall Memorial Municipal Airport",
        "MHR": "MHR | Sacramento Mather Airport",
        "MHT": "MHT | Manchester-Boston Regional Airport",
        "MHV": "MHV | Mojave Airport",
        "MIA": "MIA | Miami International Airport",
        "MIB": "MIB | Minot Air Force Base",
        "MIE": "MIE | Delaware County Johnson Field",
        "MIT": "MIT | Shafter Airport - Minter Field",
        "MIV": "MIV | Millville Municipal Airport",
        "MJX": "MJX | Ocean County Airport",
        "MKC": "MKC | Charles B. Wheeler Downtown Airport",
        "MKE": "MKE | General Mitchell International Airport",
        "MKG": "MKG | Muskegon County Airport",
        "MKL": "MKL | McKellar-Sipes Regional Airport",
        "MRK": "MRK | Marco Island Executive Airport",
        "MLB": "MLB | Melbourne Orlando International Airport",
        "MLC": "MLC | Mc Alester Regional Airport",
        "MLI": "MLI | Quad City International Airport",
        "MLS": "MLS | Frank Wiley Field",
        "MLU": "MLU | Monroe Regional Airport",
        "KMM": "KMM | Kimaam Airport",
        "MMH": "MMH | Mammoth Yosemite Airport",
        "MMI": "MMI | McMinn County Airport",
        "MML": "MML | Southwest Minnesota Regional Airport - Marshall/Ryan Field",
        "MMS": "MMS | Selfs Airport",
        "MMT": "MMT | Mc Entire Joint National Guard Base",
        "MMU": "MMU | Morristown Municipal Airport",
        "MNM": "MNM | Menominee Regional Airport",
        "MNN": "MNN | Marion Municipal Airport",
        "MOB": "MOB | Mobile Regional Airport",
        "MOD": "MOD | Modesto City Co-Harry Sham Field",
        "MOT": "MOT | Minot International Airport",
        "RMY": "RMY | Mariposa Yosemite Airport",
        "MPJ": "MPJ | Petit Jean Park Airport",
        "MPO": "MPO | Pocono Mountains Municipal Airport",
        "MPV": "MPV | Edward F Knapp State Airport",
        "MPZ": "MPZ | Mount Pleasant Municipal Airport",
        "MQB": "MQB | Macomb Municipal Airport",
        "MEO": "MEO | Dare County Regional Airport",
        "CTH": "CTH | Chester County G O Carlson Airport",
        "MQY": "MQY | Smyrna Airport",
        "MRB": "MRB | Eastern WV Regional Airport/Shepherd Field",
        "MRC": "MRC | Maury County Airport",
        "MRF": "MRF | Marfa Municipal Airport",
        "MRN": "MRN | Foothills Regional Airport",
        "MRY": "MRY | Monterey Peninsula Airport",
        "MSL": "MSL | Northwest Alabama Regional Airport",
        "MSN": "MSN | Dane County Regional Truax Field",
        "MSO": "MSO | Missoula International Airport",
        "MSP": "MSP | Minneapolis–Saint Paul International Airport / Wold–Chamberlain Field",
        "MSS": "MSS | Massena International Richards Field",
        "MSV": "MSV | Sullivan County International Airport",
        "MSY": "MSY | Louis Armstrong New Orleans International Airport",
        "MTC": "MTC | Selfridge Air National Guard Base Airport",
        "MTH": "MTH | The Florida Keys Marathon Airport",
        "MTJ": "MTJ | Montrose Regional Airport",
        "MTN": "MTN | Martin State Airport",
        "MTO": "MTO | Coles County Memorial Airport",
        "MTP": "MTP | Montauk Airport",
        "MTW": "MTW | Manitowoc County Airport",
        "MUI": "MUI | Muir Army Air Field (Fort Indiantown Gap) Airport",
        "MUO": "MUO | Mountain Home Air Force Base",
        "MUT": "MUT | Muscatine Municipal Airport",
        "MVC": "MVC | Monroe County Aeroplex Airport",
        "MVE": "MVE | Montevideo Chippewa County Airport",
        "MVL": "MVL | Morrisville Stowe State Airport",
        "MVY": "MVY | Martha's Vineyard Airport",
        "MWA": "MWA | Veterans Airport of Southern Illinois",
        "MWC": "MWC | Lawrence J Timmerman Airport",
        "MWH": "MWH | Grant County International Airport",
        "MWL": "MWL | Mineral Wells Regional Airport",
        "MWO": "MWO | Middletown Regional Airport",
        "MXA": "MXA | Manila Municipal Airport",
        "MXF": "MXF | Maxwell Air Force Base",
        "KMY": "KMY | Moser Bay Seaplane Base",
        "MYF": "MYF | Montgomery-Gibbs Executive Airport",
        "MYL": "MYL | McCall Municipal Airport",
        "MYR": "MYR | Myrtle Beach International Airport",
        "MYV": "MYV | Yuba County Airport",
        "MZJ": "MZJ | Pinal Airpark",
        "MZZ": "MZZ | Marion Municipal Airport",
        "CTX": "CTX | Cortland County Chase Field",
        "SXY": "SXY | Sidney Municipal Airport",
        "ESP": "ESP | Stroudsburg Pocono Airport",
        "NBG": "NBG | New Orleans NAS JRB/Alvin Callender Field",
        "NHX": "NHX | Naval Outlying Field Barin",
        "DGN": "DGN | Dahlgren Naval Surface Warfare Center Airport",
        "NEL": "NEL | Lakehurst Maxfield Field Airport",
        "NEN": "NEN | Whitehouse Naval Outlying Field",
        "NEW": "NEW | Lakefront Airport",
        "NFL": "NFL | Fallon Naval Air Station",
        "FWH": "FWH | NAS Fort Worth JRB / Carswell Field",
        "NGU": "NGU | Norfolk Naval Station (Chambers Field)",
        "NHK": "NHK | Patuxent River Naval Air Station (Trapnell Field)",
        "NHZ": "NHZ | Brunswick Executive Airport",
        "NIP": "NIP | Jacksonville Naval Air Station (Towers Field)",
        "NJK": "NJK | El Centro NAF Airport (Vraciu Field)",
        "NKX": "NKX | Miramar Marine Corps Air Station - Mitscher Field",
        "NLC": "NLC | Lemoore Naval Air Station (Reeves Field) Airport",
        "NPA": "NPA | Naval Air Station Pensacola Forrest Sherman Field",
        "NQA": "NQA | Millington-Memphis Airport",
        "NQI": "NQI | Kingsville Naval Air Station",
        "NQX": "NQX | Naval Air Station Key West/Boca Chica Field",
        "NRB": "NRB | Naval Station Mayport (Admiral David L. Mcdonald Field)",
        "NRS": "NRS | Naval Outlying Field Imperial Beach (Ream Field)",
        "NSE": "NSE | Whiting Field Naval Air Station - North",
        "NTD": "NTD | Point Mugu Naval Air Station (Naval Base Ventura Co)",
        "NTU": "NTU | Oceana Naval Air Station",
        "NUQ": "NUQ | Moffett Federal Airfield",
        "NUW": "NUW | Whidbey Island Naval Air Station (Ault Field)",
        "YUM": "YUM | Yuma International Airport / Marine Corps Air Station Yuma",
        "NZY": "NZY | North Island Naval Air Station-Halsey Field",
        "NVN": "NVN | Nervino Airport",
        "COA": "COA | Columbia Airport",
        "ODC": "ODC | Oakdale Airport",
        "EYR": "EYR | Yerington Municipal Airport",
        "OAJ": "OAJ | Albert J Ellis Airport",
        "OAK": "OAK | Metropolitan Oakland International Airport",
        "OAR": "OAR | Marina Municipal Airport",
        "OBE": "OBE | Okeechobee County Airport",
        "OCF": "OCF | Ocala International Airport - Jim Taylor Field",
        "OCH": "OCH | A L Mangham Jr. Regional Airport",
        "OCW": "OCW | Warren Field",
        "OEA": "OEA | O'Neal Airport",
        "OEO": "OEO | L O Simenstad Municipal Airport",
        "OFF": "OFF | Offutt Air Force Base",
        "OFK": "OFK | Karl Stefan Memorial Airport",
        "OGA": "OGA | Searle Field",
        "OGB": "OGB | Orangeburg Municipal Airport",
        "OGD": "OGD | Ogden Hinckley Airport",
        "OGS": "OGS | Ogdensburg International Airport",
        "OIC": "OIC | Lt Warren Eaton Airport",
        "OJC": "OJC | Johnson County Executive Airport",
        "OCN": "OCN | Oceanside Municipal Airport",
        "OKC": "OKC | Will Rogers World Airport",
        "ODW": "ODW | AJ Eisenberg Airport",
        "OKK": "OKK | Kokomo Municipal Airport",
        "OKM": "OKM | Okmulgee Regional Airport",
        "OKS": "OKS | Garden County Airport/King Rhiley Field",
        "WGO": "WGO | Winchester Regional Airport",
        "OLD": "OLD | Dewitt Field - Old Town Municipal Airport",
        "OLF": "OLF | L M Clayton Airport",
        "OLM": "OLM | Olympia Regional Airport",
        "OLS": "OLS | Nogales International Airport",
        "OLV": "OLV | Olive Branch Airport",
        "KOM": "KOM | Komo-Manda Airport",
        "OMA": "OMA | Eppley Airfield",
        "OMK": "OMK | Omak Airport",
        "ONL": "ONL | The O'Neill Municipal John L Baker Field",
        "ONO": "ONO | Ontario Municipal Airport",
        "ONP": "ONP | Newport Municipal Airport",
        "ONT": "ONT | Ontario International Airport",
        "OPF": "OPF | Miami-Opa Locka Executive Airport",
        "NCO": "NCO | Quonset State Airport",
        "KOR": "KOR | Kakoro(Koroko) Airstrip",
        "ORD": "ORD | Chicago O'Hare International Airport",
        "ORF": "ORF | Norfolk International Airport",
        "ORH": "ORH | Worcester Regional Airport",
        "ORL": "ORL | Orlando Executive Airport",
        "ESD": "ESD | Orcas Island Airport",
        "OSC": "OSC | Oscoda Wurtsmith Airport",
        "OSH": "OSH | Wittman Regional Airport",
        "OSU": "OSU | The Ohio State University Airport - Don Scott Field",
        "OTH": "OTH | Southwest Oregon Regional Airport",
        "OTM": "OTM | Ottumwa Regional Airport",
        "OUN": "OUN | University of Oklahoma Westheimer Airport",
        "OVE": "OVE | Oroville Municipal Airport",
        "OWA": "OWA | Owatonna Degner Regional Airport",
        "OWB": "OWB | Owensboro Daviess County Airport",
        "OWD": "OWD | Norwood Memorial Airport",
        "OWK": "OWK | Central Maine Airport of Norridgewock",
        "OCE": "OCE | Ocean City Municipal Airport",
        "OXC": "OXC | Waterbury Oxford Airport",
        "OXD": "OXD | Miami University Airport",
        "OXR": "OXR | Oxnard Airport",
        "KOY": "KOY | Olga Bay Seaplane Base",
        "STQ": "STQ | St Marys Municipal Airport",
        "OZA": "OZA | Ozona Municipal Airport",
        "OZR": "OZR | Cairns AAF (Fort Rucker) Air Field",
        "YJS": "YJS | Samjiyŏn Airport",
        "RGO": "RGO | Orang (Chongjin) Airport",
        "BSQ": "BSQ | Bisbee Municipal Airport",
        "PXL": "PXL | Polacca Airport",
        "GLB": "GLB | San Carlos Apache Airport",
        "HBK": "HBK | Holbrook Municipal Airport",
        "CWX": "CWX | Cochise County Airport",
        "PAE": "PAE | Snohomish County (Paine Field) Airport",
        "PAH": "PAH | Barkley Regional Airport",
        "PAM": "PAM | Tyndall Air Force Base",
        "PJB": "PJB | Payson Airport",
        "PAO": "PAO | Palo Alto Airport of Santa Clara County",
        "KPB": "KPB | Point Baker Seaplane Base",
        "PBF": "PBF | Pine Bluff Regional Airport Grider Field",
        "PBG": "PBG | Plattsburgh International Airport",
        "PBI": "PBI | Palm Beach International Airport",
        "PVL": "PVL | Pike County-Hatcher Field",
        "PCD": "PCD | Prairie Du Chien Municipal Airport",
        "PDK": "PDK | DeKalb Peachtree Airport",
        "PDT": "PDT | Eastern Oregon Regional At Pendleton Airport",
        "PDX": "PDX | Portland International Airport",
        "KPE": "KPE | Yapsiei Airport",
        "PEQ": "PEQ | Pecos Municipal Airport",
        "PGA": "PGA | Page Municipal Airport",
        "PGD": "PGD | Charlotte County Airport",
        "PGR": "PGR | Kirk Field",
        "PGV": "PGV | Pitt Greenville Airport",
        "PHD": "PHD | Harry Clever Field",
        "PHF": "PHF | Newport News Williamsburg International Airport",
        "ADR": "ADR | Robert F Swinnie Airport",
        "PHK": "PHK | Palm Beach County Glades Airport",
        "PHL": "PHL | Philadelphia International Airport",
        "PHN": "PHN | St Clair County International Airport",
        "PHP": "PHP | Philip Airport",
        "PHT": "PHT | Henry County Airport",
        "PHX": "PHX | Phoenix Sky Harbor International Airport",
        "PIA": "PIA | General Wayne A. Downing Peoria International Airport",
        "PIB": "PIB | Hattiesburg Laurel Regional Airport",
        "PIE": "PIE | St Petersburg Clearwater International Airport",
        "PIH": "PIH | Pocatello Regional Airport",
        "PIM": "PIM | Harris County Airport",
        "PIR": "PIR | Pierre Regional Airport",
        "PIT": "PIT | Pittsburgh International Airport",
        "PKB": "PKB | Mid Ohio Valley Regional Airport",
        "PKD": "PKD | Park Rapids Municipal Konshok Field",
        "PKF": "PKF | Park Falls Municipal Airport",
        "KPL": "KPL | Kapal Airport",
        "PLK": "PLK | M. Graham Clark Downtown Airport",
        "PLN": "PLN | Pellston Regional Airport of Emmet County Airport",
        "PLR": "PLR | St Clair County Airport",
        "PMB": "PMB | Pembina Municipal Airport",
        "PMD": "PMD | Palmdale Regional Airport / USAF Plant 42 Airport",
        "PMH": "PMH | Greater Portsmouth Regional Airport",
        "PPM": "PPM | Pompano Beach Airpark",
        "PWY": "PWY | Ralph Wenz Field",
        "PNC": "PNC | Ponca City Regional Airport",
        "PNE": "PNE | Northeast Philadelphia Airport",
        "PNN": "PNN | Princeton Municipal Airport",
        "PNS": "PNS | Pensacola International Airport",
        "POB": "POB | Pope Field",
        "POC": "POC | Brackett Field",
        "POE": "POE | Polk Army Air Field",
        "POF": "POF | Poplar Bluff Municipal Airport",
        "POU": "POU | Dutchess County Airport",
        "POY": "POY | Powell Municipal Airport",
        "PPA": "PPA | Perry Lefors Field",
        "PPF": "PPF | Tri-City Airport",
        "LPO": "LPO | La Porte Municipal Airport",
        "PQI": "PQI | Presque Isle International Airport",
        "PGL": "PGL | Trent Lott International Airport",
        "KPR": "KPR | Port Williams Seaplane Base",
        "PRB": "PRB | Paso Robles Municipal Airport",
        "PRC": "PRC | Prescott International Airport - Ernest A. Love Field",
        "PRO": "PRO | Perry Municipal Airport",
        "PRX": "PRX | Cox Field",
        "PSC": "PSC | Tri Cities Airport",
        "PSF": "PSF | Pittsfield Municipal Airport",
        "PSK": "PSK | New River Valley Airport",
        "PSM": "PSM | Portsmouth International at Pease Airport",
        "PSN": "PSN | Palestine Municipal Airport",
        "PGO": "PGO | Stevens Field",
        "PSP": "PSP | Palm Springs International Airport",
        "PSX": "PSX | Palacios Municipal Airport",
        "PTB": "PTB | Dinwiddie County Airport",
        "PTK": "PTK | Oakland County International Airport",
        "PTN": "PTN | Harry P Williams Memorial Airport",
        "PTT": "PTT | Pratt Regional Airport",
        "PTV": "PTV | Porterville Municipal Airport",
        "PTW": "PTW | Heritage Field",
        "PUB": "PUB | Pueblo Memorial Airport",
        "PUC": "PUC | Carbon County Regional/Buck Davis Field",
        "PUW": "PUW | Pullman Moscow Regional Airport",
        "PVC": "PVC | Provincetown Municipal Airport",
        "PVD": "PVD | Theodore Francis Green State Airport",
        "PVF": "PVF | Placerville Airport",
        "PVU": "PVU | Provo-Utah Lake International Airport",
        "PVW": "PVW | Hale County Airport",
        "PWA": "PWA | Wiley Post Airport",
        "PWD": "PWD | Sher-Wood Airport",
        "PWK": "PWK | Chicago Executive Airport",
        "PWM": "PWM | Portland International Jetport",
        "PWT": "PWT | Bremerton National Airport",
        "KPY": "KPY | Port Bailey Seaplane Base",
        "PYM": "PYM | Plymouth Municipal Airport",
        "KQL": "KQL | Kol Airport",
        "RAC": "RAC | John H Batten Airport",
        "RAL": "RAL | Riverside Municipal Airport",
        "RAP": "RAP | Rapid City Regional Airport",
        "RBD": "RBD | Dallas Executive Airport",
        "RBG": "RBG | Roseburg Regional Airport",
        "RBL": "RBL | Red Bluff Municipal Airport",
        "RBW": "RBW | Lowcountry Regional Airport",
        "RCA": "RCA | Ellsworth Air Force Base",
        "RCK": "RCK | H H Coffield Regional Airport",
        "RCR": "RCR | Fulton County Airport",
        "RCT": "RCT | Nartron Field",
        "RDD": "RDD | Redding Municipal Airport",
        "RDG": "RDG | Reading Regional Airport (Carl A Spaatz Field)",
        "RDM": "RDM | Roberts Field",
        "RDR": "RDR | Grand Forks Air Force Base",
        "RDU": "RDU | Raleigh Durham International Airport",
        "REO": "REO | Rome State Airport",
        "RFD": "RFD | Chicago Rockford International Airport",
        "RFG": "RFG | Rooke Field",
        "RHI": "RHI | Rhinelander Oneida County Airport",
        "RHV": "RHV | Reid-Hillview Airport of Santa Clara County",
        "RIC": "RIC | Richmond International Airport",
        "RIL": "RIL | Garfield County Regional Airport",
        "RIV": "RIV | March Air Reserve Base",
        "RIW": "RIW | Riverton Regional Airport",
        "KRJ": "KRJ | Karawari Airstrip",
        "RKD": "RKD | Knox County Regional Airport",
        "RKP": "RKP | Aransas County Airport",
        "RKS": "RKS | Southwest Wyoming Regional Airport",
        "RKW": "RKW | Rockwood Municipal Airport",
        "RME": "RME | Griffiss International Airport",
        "RMG": "RMG | Richard B Russell Airport",
        "RNC": "RNC | Warren County Memorial Airport",
        "RND": "RND | Randolph Air Force Base",
        "RNO": "RNO | Reno Tahoe International Airport",
        "RNT": "RNT | Renton Municipal Airport",
        "ROA": "ROA | Roanoke–Blacksburg Regional Airport",
        "ROC": "ROC | Greater Rochester International Airport",
        "ROG": "ROG | Rogers Municipal Airport-Carter Field",
        "ROW": "ROW | Roswell Air Center Airport",
        "ROX": "ROX | Roseau Municipal Rudy Billberg Field",
        "RIE": "RIE | Rice Lake Regional Airport - Carl's Field",
        "RPX": "RPX | Roundup Airport",
        "WBR": "WBR | Roben Hood Airport",
        "RQO": "RQO | El Reno Regional Airport",
        "RRL": "RRL | Merrill Municipal Airport",
        "RRT": "RRT | Warroad International Memorial Airport",
        "RSL": "RSL | Russell Municipal Airport",
        "RSN": "RSN | Ruston Regional Airport",
        "RST": "RST | Rochester International Airport",
        "RSW": "RSW | Southwest Florida International Airport",
        "RTN": "RTN | Raton Municipal Airport / Crews Field",
        "KRU": "KRU | Kerau Airport",
        "SRW": "SRW | Mid-Carolina Regional Airport",
        "RUT": "RUT | Rutland - Southern Vermont Regional Airport",
        "RED": "RED | Mifflin County Airport",
        "RVS": "RVS | Richard Lloyd Jones Jr Airport",
        "RWF": "RWF | Redwood Falls Municipal Airport",
        "RWI": "RWI | Rocky Mount Wilson Regional Airport",
        "RWL": "RWL | Rawlins Municipal Airport/Harvey Field",
        "RXE": "RXE | Rexburg Madison County Airport",
        "RNZ": "RNZ | Jasper County Airport",
        "AHM": "AHM | Ashland Municipal Sumner Parker Field",
        "BDY": "BDY | Bandon State Airport",
        "SUO": "SUO | Sunriver Airport",
        "MDJ": "MDJ | Madras Municipal Airport",
        "PRZ": "PRZ | Prineville Airport",
        "IDH": "IDH | Idaho County Airport",
        "SAA": "SAA | Shively Field",
        "SAC": "SAC | Sacramento Executive Airport",
        "SAD": "SAD | Safford Regional Airport",
        "SAF": "SAF | Santa Fe Municipal Airport",
        "SAN": "SAN | San Diego International Airport",
        "SAR": "SAR | Sparta Community Hunter Field",
        "SAT": "SAT | San Antonio International Airport",
        "SAV": "SAV | Savannah Hilton Head International Airport",
        "MQT": "MQT | Sawyer International Airport",
        "SBA": "SBA | Santa Barbara Municipal Airport",
        "SBD": "SBD | San Bernardino International Airport",
        "SBM": "SBM | Sheboygan County Memorial Airport",
        "SBN": "SBN | South Bend Regional Airport",
        "SBP": "SBP | San Luis County Regional Airport",
        "SBS": "SBS | Steamboat Springs Bob Adams Field",
        "SBX": "SBX | Shelby Airport",
        "SBY": "SBY | Salisbury Ocean City Wicomico Regional Airport",
        "SCB": "SCB | Scribner State Airport",
        "SCH": "SCH | Schenectady County Airport",
        "SCK": "SCK | Stockton Metropolitan Airport",
        "SDF": "SDF | Louisville Muhammad Ali International Airport",
        "SCF": "SCF | Scottsdale Airport",
        "SDM": "SDM | Brown Field Municipal Airport",
        "SDY": "SDY | Sidney - Richland Regional Airport",
        "SEA": "SEA | Seattle Tacoma International Airport",
        "SEE": "SEE | Gillespie Field",
        "SEF": "SEF | Sebring Regional Airport",
        "SEG": "SEG | Penn Valley Airport",
        "SEM": "SEM | Craig Field",
        "SEP": "SEP | Stephenville Clark Regional Airport",
        "SER": "SER | Freeman Municipal Airport",
        "SDX": "SDX | Sedona Airport",
        "SFB": "SFB | Orlando Sanford International Airport",
        "SFF": "SFF | Felts Field",
        "SFM": "SFM | Sanford Seacoast Regional Airport",
        "SFO": "SFO | San Francisco International Airport",
        "SFZ": "SFZ | North Central State Airport",
        "SGF": "SGF | Springfield Branson National Airport",
        "SGH": "SGH | Springfield-Beckley Municipal Airport",
        "UST": "UST | Northeast Florida Regional Airport",
        "SGR": "SGR | Sugar Land Regional Airport",
        "SGT": "SGT | Stuttgart Municipal Airport / Carl Humphrey Field",
        "SGU": "SGU | Saint George-Southwest Utah International Airport",
        "SHD": "SHD | Shenandoah Valley Regional Airport",
        "SHN": "SHN | Sanderson Field",
        "SHR": "SHR | Sheridan County Airport",
        "SHV": "SHV | Shreveport Regional Airport",
        "SIK": "SIK | Sikeston Memorial Municipal Airport",
        "SIV": "SIV | Sullivan County Airport",
        "SJC": "SJC | Norman Y. Mineta San Jose International Airport",
        "SJN": "SJN | St Johns Industrial Air Park",
        "SJT": "SJT | San Angelo Regional Mathis Field",
        "SKA": "SKA | Fairchild Air Force Base",
        "SKF": "SKF | Lackland Air Force Base",
        "TSM": "TSM | Taos Regional Airport",
        "SLB": "SLB | Storm Lake Municipal Airport",
        "SLC": "SLC | Salt Lake City International Airport",
        "SLE": "SLE | Salem Municipal Airport/McNary Field",
        "SLG": "SLG | Smith Field",
        "SLK": "SLK | Adirondack Regional Airport",
        "SLN": "SLN | Salina Municipal Airport",
        "SLO": "SLO | Salem Leckrone Airport",
        "SLR": "SLR | Sulphur Springs Municipal Airport",
        "SMD": "SMD | Smith Field",
        "SME": "SME | Lake Cumberland Regional Airport",
        "SMF": "SMF | Sacramento International Airport",
        "SMN": "SMN | Lemhi County Airport",
        "SMO": "SMO | Santa Monica Municipal Airport",
        "SUM": "SUM | Sumter Airport",
        "SMX": "SMX | Santa Maria Public Airport Captain G Allan Hancock Field",
        "SNA": "SNA | John Wayne Airport-Orange County Airport",
        "SNK": "SNK | Winston Field",
        "SNL": "SNL | Shawnee Regional Airport",
        "SNS": "SNS | Salinas Municipal Airport",
        "SNY": "SNY | Sidney Municipal-Lloyd W Carr Field",
        "SOP": "SOP | Moore County Airport",
        "SOW": "SOW | Show Low Regional Airport",
        "KSP": "KSP | Kosipe Airport",
        "SPA": "SPA | Spartanburg Downtown Memorial Airport",
        "SPF": "SPF | Black Hills Airport-Clyde Ice Field",
        "SPG": "SPG | Albert Whitted Airport",
        "SPI": "SPI | Abraham Lincoln Capital Airport",
        "SPS": "SPS | Sheppard Air Force Base-Wichita Falls Municipal Airport",
        "SPW": "SPW | Spencer Municipal Airport",
        "SQI": "SQI | Whiteside County Airport-Joseph H Bittorf Field",
        "SQL": "SQL | San Carlos Airport",
        "SRQ": "SRQ | Sarasota Bradenton International Airport",
        "RUI": "RUI | Sierra Blanca Regional Airport",
        "SSC": "SSC | Shaw Air Force Base",
        "SSF": "SSF | Stinson Municipal Airport",
        "SSI": "SSI | St Simons Island Airport",
        "STC": "STC | Saint Cloud Regional Airport",
        "STE": "STE | Stevens Point Municipal Airport",
        "STJ": "STJ | Rosecrans Memorial Airport",
        "STK": "STK | Sterling Municipal Airport",
        "STL": "STL | St Louis Lambert International Airport",
        "STP": "STP | St Paul Downtown Holman Field",
        "STS": "STS | Charles M. Schulz Sonoma County Airport",
        "SUA": "SUA | Witham Field",
        "SUD": "SUD | Stroud Municipal Airport",
        "SUE": "SUE | Door County Cherryland Airport",
        "SUN": "SUN | Friedman Memorial Airport",
        "SUS": "SUS | Spirit of St Louis Airport",
        "SUU": "SUU | Travis Air Force Base",
        "SUW": "SUW | Richard I Bong Airport",
        "SUX": "SUX | Sioux Gateway Airport / Brigadier General Bud Day Field",
        "SVC": "SVC | Grant County Airport",
        "SVE": "SVE | Susanville Municipal Airport",
        "SVH": "SVH | Statesville Regional Airport",
        "SVN": "SVN | Hunter Army Air Field",
        "SWF": "SWF | New York Stewart International Airport",
        "SWO": "SWO | Stillwater Regional Airport",
        "SWW": "SWW | Avenger Field",
        "SYI": "SYI | Bomar Field Shelbyville Municipal Airport",
        "SYR": "SYR | Syracuse Hancock International Airport",
        "SYV": "SYV | Sylvester Airport",
        "SZL": "SZL | Whiteman Air Force Base",
        "TBC": "TBC | Tuba City Airport",
        "TAD": "TAD | Perry Stokes Airport",
        "KTB": "KTB | Thorne Bay Seaplane Base",
        "TBN": "TBN | Waynesville-St. Robert Regional Forney field",
        "TBR": "TBR | Statesboro Bulloch County Airport",
        "KTC": "KTC | Katiola Airport",
        "TCC": "TCC | Tucumcari Municipal Airport",
        "TCL": "TCL | Tuscaloosa Regional Airport",
        "TCM": "TCM | McChord Air Force Base",
        "TCS": "TCS | Truth or Consequences Municipal Airport",
        "TDO": "TDO | Ed Carlson Memorial Field South Lewis County Airport",
        "TDW": "TDW | Tradewind Airport",
        "TDZ": "TDZ | Toledo Executive Airport",
        "TEB": "TEB | Teterboro Airport",
        "TEX": "TEX | Telluride Regional Airport",
        "THA": "THA | Tullahoma Regional Arpt/Wm Northern Field",
        "THM": "THM | Thompson Falls Airport",
        "THV": "THV | York Airport",
        "TIK": "TIK | Tinker Air Force Base",
        "TIW": "TIW | Tacoma Narrows Airport",
        "TIX": "TIX | Space Coast Regional Airport",
        "KNT": "KNT | Kennett Memorial Airport",
        "TLH": "TLH | Tallahassee Regional Airport",
        "TLR": "TLR | Mefford Field",
        "TMA": "TMA | Henry Tift Myers Airport",
        "TMB": "TMB | Miami Executive Airport",
        "OTK": "OTK | Tillamook Airport",
        "TNP": "TNP | Twentynine Palms Airport",
        "TNT": "TNT | Dade Collier Training and Transition Airport",
        "TNU": "TNU | Newton Municipal Airport",
        "XSD": "XSD | Tonopah Test Range Airport",
        "TOA": "TOA | Zamperini Field",
        "TOC": "TOC | Toccoa Airport - R.G. Letourneau Field",
        "TOI": "TOI | Troy Municipal Airport at N Kenneth Campbell Field",
        "TOL": "TOL | Eugene F. Kranz Toledo Express Airport",
        "TOP": "TOP | Philip Billard Municipal Airport",
        "TOR": "TOR | Torrington Municipal Airport",
        "TPA": "TPA | Tampa International Airport",
        "TPF": "TPF | Peter O Knight Airport",
        "TPH": "TPH | Tonopah Airport",
        "TPL": "TPL | Draughon Miller Central Texas Regional Airport",
        "TRI": "TRI | Tri-Cities Regional TN/VA Airport",
        "TKF": "TKF | Truckee Tahoe Airport",
        "TRL": "TRL | Terrell Municipal Airport",
        "TRM": "TRM | Jacqueline Cochran Regional Airport",
        "TSP": "TSP | Tehachapi Municipal Airport",
        "TTD": "TTD | Portland Troutdale Airport",
        "TTN": "TTN | Trenton Mercer Airport",
        "TUL": "TUL | Tulsa International Airport",
        "TUP": "TUP | Tupelo Regional Airport",
        "TUS": "TUS | Tucson International Airport / Morris Air National Guard Base",
        "TVC": "TVC | Cherry Capital Airport",
        "TVF": "TVF | Thief River Falls Regional Airport",
        "TVI": "TVI | Thomasville Regional Airport",
        "TVL": "TVL | Lake Tahoe Airport",
        "TWF": "TWF | Joslin Field Magic Valley Regional Airport",
        "TXK": "TXK | Texarkana Regional Webb Field",
        "TYZ": "TYZ | Taylor Airport",
        "TYR": "TYR | Tyler Pounds Regional Airport",
        "TYS": "TYS | McGhee Tyson Airport",
        "BFG": "BFG | Bullfrog Basin Airport",
        "NPH": "NPH | Nephi-Central Utah International Airport",
        "RVR": "RVR | Green River Municipal Airport",
        "PNU": "PNU | Panguitch Municipal Airport",
        "ICS": "ICS | Cascade Airport",
        "UBS": "UBS | Columbus Lowndes County Airport",
        "UCY": "UCY | Everett-Stewart Regional Airport",
        "UDD": "UDD | Bermuda Dunes Airport",
        "UES": "UES | Waukesha County Airport",
        "UGN": "UGN | Waukegan National Airport",
        "UIL": "UIL | Quillayute Airport",
        "UIN": "UIN | Quincy Regional Baldwin Field",
        "IKB": "IKB | Wilkes County Airport",
        "UKI": "UKI | Ukiah Municipal Airport",
        "UKT": "UKT | Quakertown Airport",
        "ULM": "ULM | New Ulm Municipal Airport",
        "ATO": "ATO | Ohio University Snyder Field",
        "UNU": "UNU | Dodge County Airport",
        "SCE": "SCE | University Park Airport",
        "UOS": "UOS | Franklin County Airport",
        "UOX": "UOX | University Oxford Airport",
        "KUP": "KUP | Kupiano Airport",
        "UTM": "UTM | Tunica Municipal Airport",
        "HTV": "HTV | Huntsville Regional Airport",
        "NPT": "NPT | Newport State Airport",
        "UVA": "UVA | Garner Field",
        "KUX": "KUX | Kuyol Airport",
        "RKH": "RKH | Rock Hill - York County Airport",
        "VAD": "VAD | Moody Air Force Base",
        "LLY": "LLY | South Jersey Regional Airport",
        "VBG": "VBG | Vandenberg Air Force Base",
        "VCT": "VCT | Victoria Regional Airport",
        "VCV": "VCV | Southern California Logistics Airport",
        "VDI": "VDI | Vidalia Regional Airport",
        "KVE": "KVE | Kitava Airport",
        "VEL": "VEL | Vernal Regional Airport",
        "VGT": "VGT | North Las Vegas Metropolitan International Airport",
        "VHN": "VHN | Culberson County Airport",
        "VIH": "VIH | Rolla National Airport",
        "VIS": "VIS | Visalia International Airport",
        "VJI": "VJI | Virginia Highlands Airport",
        "VKS": "VKS | Vicksburg Municipal Airport",
        "VLA": "VLA | Vandalia Municipal Airport",
        "VLD": "VLD | Valdosta Regional Airport",
        "VNC": "VNC | Venice Municipal Airport",
        "VNY": "VNY | Van Nuys Airport",
        "VOK": "VOK | Volk Field",
        "VPS": "VPS | Destin-Fort Walton Beach Airport",
        "VPZ": "VPZ | Porter County Municipal Airport",
        "VQQ": "VQQ | Cecil Airport",
        "VRB": "VRB | Vero Beach Regional Airport",
        "VSF": "VSF | Hartness State (Springfield) Airport",
        "VTN": "VTN | Miller Field",
        "VYS": "VYS | Illinois Valley Regional Airport-Walter A Duncan Field",
        "GTY": "GTY | Gettysburg Regional Airport",
        "SQV": "SQV | Sequim Valley Airport",
        "PGC": "PGC | Grant County Airport",
        "WAL": "WAL | Wallops Flight Facility Airport",
        "WAY": "WAY | Greene County Airport",
        "WBW": "WBW | Wilkes Barre Wyoming Valley Airport",
        "WDG": "WDG | Enid Woodring Regional Airport",
        "WDR": "WDR | Barrow County Airport",
        "KWF": "KWF | Waterfall Seaplane Base",
        "WHP": "WHP | Whiteman Airport",
        "WJF": "WJF | General WM J Fox Airfield",
        "WLD": "WLD | Strother Field",
        "WLW": "WLW | Willows Glenn County Airport",
        "WMC": "WMC | Winnemucca Municipal Airport",
        "KWP": "KWP | West Point Village Seaplane Base",
        "WRB": "WRB | Robins Air Force Base",
        "WRI": "WRI | Mc Guire Air Force Base",
        "WRL": "WRL | Worland Municipal Airport",
        "WSD": "WSD | Condron Army Air Field",
        "WST": "WST | Westerly State Airport",
        "WVI": "WVI | Watsonville Municipal Airport",
        "WVL": "WVL | Waterville Robert Lafleur Airport",
        "WWD": "WWD | Cape May County Airport",
        "WWR": "WWR | West Woodward Airport",
        "KWY": "KWY | Kiwayu Airport",
        "WYS": "WYS | Yellowstone Airport",
        "KYO": "KYO | Tampa North Aero Park Airport",
        "KXA": "KXA | Kasaan Seaplane Base",
        "XNA": "XNA | Northwest Arkansas Regional Airport",
        "WBK": "WBK | West Branch Community Airport",
        "YIP": "YIP | Willow Run Airport",
        "YKM": "YKM | Yakima Air Terminal McAllister Field",
        "YKN": "YKN | Chan Gurney Municipal Airport",
        "YNG": "YNG | Youngstown Warren Regional Airport",
        "TDK": "TDK | Taldykorgan Airport",
        "BXY": "BXY | Krainiy Airport",
        "USJ": "USJ | Usharal Airport",
        "ATX": "ATX | Atbasar Airport",
        "KZB": "KZB | Zachar Bay Seaplane Base",
        "KZF": "KZF | Kaintiba Airport",
        "ZPH": "ZPH | Zephyrhills Municipal Airport",
        "ZZV": "ZZV | Zanesville Municipal Airport",
        "LAC": "LAC | Layang-Layang Airport",
        "KFZ": "KFZ | Kukës International Airport",
        "TIA": "TIA | Tirana International Airport Mother Teresa",
        "BOJ": "BOJ | Burgas Airport",
        "GOZ": "GOZ | Gorna Oryahovitsa Airport",
        "LBH": "LBH | Palm Beach Seaplane Base",
        "LBM": "LBM | Luabo Airport",
        "PDV": "PDV | Plovdiv International Airport",
        "ROU": "ROU | Ruse Airport",
        "SOF": "SOF | Sofia Airport",
        "SZR": "SZR | Stara Zagora Airport",
        "TGV": "TGV | Targovishte Airport / Buhovtsi Airfield",
        "VAR": "VAR | Varna Airport",
        "ECN": "ECN | Ercan International Airport",
        "LCA": "LCA | Larnaca International Airport",
        "LCP": "LCP | Loncopue Airport",
        "PFO": "PFO | Paphos International Airport",
        "AKT": "AKT | RAF Akrotiri",
        "DBV": "DBV | Dubrovnik Airport",
        "LSZ": "LSZ | Lošinj Island Airport",
        "OSI": "OSI | Osijek Airport",
        "PUY": "PUY | Pula Airport",
        "RJK": "RJK | Rijeka Airport",
        "BWK": "BWK | Brač Airport",
        "SPU": "SPU | Split Airport",
        "LDW": "LDW | Lansdowne Airport",
        "ZAG": "ZAG | Zagreb Airport",
        "ZAD": "ZAD | Zadar Airport",
        "ABC": "ABC | Albacete Airport / Los Llanos Air Base",
        "ALC": "ALC | Alicante-Elche Miguel Hernández Airport",
        "LEI": "LEI | Almería Airport",
        "OVD": "OVD | Asturias Airport",
        "ODB": "ODB | Córdoba Airport",
        "BIO": "BIO | Bilbao Airport",
        "RGS": "RGS | Burgos Airport",
        "BCN": "BCN | Josep Tarradellas Barcelona-El Prat Airport",
        "BJZ": "BJZ | Badajoz Airport",
        "CDT": "CDT | Castellón-Costa Azahar Airport",
        "LCG": "LCG | A Coruña Airport",
        "ECV": "ECV | Madrid-Cuatro Vientos Airport",
        "ILD": "ILD | Lleida-Alguaire Airport",
        "GRO": "GRO | Girona-Costa Brava Airport",
        "GRX": "GRX | F.G.L. Airport Granada-Jaén Airport",
        "HSK": "HSK | Huesca-Pirineos Airport",
        "IBZ": "IBZ | Ibiza Airport",
        "XRY": "XRY | Jerez Airport",
        "MJV": "MJV | San Javier Airport",
        "QSA": "QSA | Sabadell Airfield",
        "LEN": "LEN | León Airport",
        "RJL": "RJL | Logroño-Agoncillo Airport",
        "MAD": "MAD | Adolfo Suárez Madrid–Barajas Airport",
        "AGP": "AGP | Málaga-Costa del Sol Airport",
        "MAH": "MAH | Menorca Airport",
        "OZP": "OZP | Moron Air Base",
        "LEO": "LEO | Lekoni Airport",
        "PMI": "PMI | Palma de Mallorca Airport",
        "PNA": "PNA | Pamplona Airport",
        "CQM": "CQM | Ciudad Real International Airport",
        "REU": "REU | Reus Airport",
        "ROZ": "ROZ | Rota Naval Station Airport",
        "SLM": "SLM | Salamanca Airport",
        "EAS": "EAS | San Sebastián Airport",
        "SCQ": "SCQ | Santiago-Rosalía de Castro Airport",
        "LEU": "LEU | Pirineus - la Seu d'Urgel Airport",
        "TEV": "TEV | Teruel Airport",
        "TOJ": "TOJ | Madrid–Torrejón Airport / Torrejón Air Base",
        "VLC": "VLC | Valencia Airport",
        "VLL": "VLL | Valladolid Airport",
        "VIT": "VIT | Vitoria Airport",
        "VGO": "VGO | Vigo Airport",
        "SDR": "SDR | Seve Ballesteros-Santander Airport",
        "ZAZ": "ZAZ | Zaragoza Airport",
        "SVQ": "SVQ | Sevilla Airport",
        "DPE": "DPE | St Aubin Airport",
        "CQF": "CQF | Calais-Dunkerque Airport",
        "BYF": "BYF | Albert-Bray Airport",
        "LTQ": "LTQ | Le Touquet-Côte d'Opale Airport",
        "AGF": "AGF | Agen-La Garenne Airport",
        "BOD": "BOD | Bordeaux-Mérignac Airport",
        "EGC": "EGC | Bergerac-Roumanière Airport",
        "CNG": "CNG | Cognac-Châteaubernard (BA 709) Air Base",
        "LRH": "LRH | La Rochelle-Île de Ré Airport",
        "PIS": "PIS | Poitiers-Biard Airport",
        "MCU": "MCU | Montluçon-Guéret Airport",
        "LIG": "LIG | Limoges Airport",
        "NIT": "NIT | Niort-Souché Airport",
        "TLS": "TLS | Toulouse-Blagnac Airport",
        "PUF": "PUF | Pau Pyrénées Airport",
        "LDE": "LDE | Tarbes-Lourdes-Pyrénées Airport",
        "ANG": "ANG | Angoulême-Brie-Champniers Airport",
        "PGX": "PGX | Périgueux-Bassillac Airport",
        "BIQ": "BIQ | Biarritz-Anglet-Bayonne Airport",
        "ZAO": "ZAO | Aérodrome de Cahors-Lalbenque",
        "LBI": "LBI | Albi-Le Séquestre Airport",
        "DCM": "DCM | Castres-Mazamet Airport",
        "RDZ": "RDZ | Rodez-Marcillac Airport",
        "RYN": "RYN | Royan-Médis Airport",
        "RCO": "RCO | Rochefort-Saint-Agnant (BA 721) Airport",
        "BIC": "BIC | Aérodrome de Belle Île",
        "OUI": "OUI | Ouessant Airport",
        "IDY": "IDY | Île d'Yeu Airport",
        "CMR": "CMR | Colmar-Houssen Airport",
        "DLE": "DLE | Dole-Tavaux Airport",
        "MVV": "MVV | Megève Altiport",
        "OBS": "OBS | Aubenas-Ardèche Méridional Airport",
        "LPY": "LPY | Le Puy-Loudes Airport",
        "AHZ": "AHZ | Altiport L'Alpe d'Huez - Henri GIRAUD",
        "ETZ": "ETZ | Metz-Nancy-Lorraine Airport",
        "ANE": "ANE | Angers-Loire Airport",
        "BIA": "BIA | Bastia-Poretta Airport",
        "CLY": "CLY | Calvi-Sainte-Catherine Airport",
        "FSC": "FSC | Figari Sud-Corse Airport",
        "AJA": "AJA | Ajaccio-Napoléon Bonaparte Airport",
        "PRP": "PRP | Propriano Airport",
        "SOZ": "SOZ | Solenzara (BA 126) Air Base",
        "MFX": "MFX | Méribel Altiport",
        "AUF": "AUF | Auxerre-Branches Airport",
        "CMF": "CMF | Chambéry-Savoie Airport",
        "CFE": "CFE | Clermont-Ferrand Auvergne Airport",
        "BOU": "BOU | Bourges Airport",
        "CVF": "CVF | Courchevel Airport",
        "LYS": "LYS | Lyon Saint-Exupéry Airport",
        "SYT": "SYT | Saint-Yan Airport",
        "RNE": "RNE | Roanne-Renaison Airport",
        "NCY": "NCY | Annecy-Haute-Savoie-Mont Blanc Airport",
        "GNB": "GNB | Grenoble-Isère Airport",
        "VAF": "VAF | Valence-Chabeuil Airport",
        "VHY": "VHY | Vichy-Charmeil Airport",
        "AUR": "AUR | Aurillac Airport",
        "CHR": "CHR | Châteauroux-Déols Marcel Dassault Airport",
        "LYN": "LYN | Lyon-Bron Airport",
        "CEQ": "CEQ | Cannes-Mandelieu Airport",
        "EBU": "EBU | Saint-Étienne-Bouthéon Airport",
        "CCF": "CCF | Carcassonne Airport",
        "MRS": "MRS | Marseille Provence Airport",
        "NCE": "NCE | Nice-Côte d'Azur Airport",
        "PGF": "PGF | Perpignan-Rivesaltes (Llabanère) Airport",
        "CTT": "CTT | Le Castellet Airport",
        "BAE": "BAE | Barcelonnette - Saint-Pons Airport",
        "MPL": "MPL | Montpellier-Méditerranée Airport",
        "BZR": "BZR | Béziers-Vias Airport",
        "AVN": "AVN | Avignon-Caumont Airport",
        "GAT": "GAT | Gap - Tallard Airfield",
        "MEN": "MEN | Mende-Brenoux Airfield",
        "SCP": "SCP | Mont-Dauphin - St-Crépin Airfield",
        "BVA": "BVA | Paris Beauvais Tillé Airport",
        "EVX": "EVX | Évreux-Fauville (BA 105) Air Base",
        "LEH": "LEH | Le Havre Octeville Airport",
        "XCR": "XCR | Châlons-Vatry Airport",
        "LSO": "LSO | Les Sables-d'Olonne Talmont Airport",
        "URO": "URO | Rouen Airport",
        "TUF": "TUF | Tours-Val-de-Loire Airport",
        "CET": "CET | Cholet Le Pontreau Airport",
        "LVA": "LVA | Laval-Entrammes Airport",
        "ORE": "ORE | Orléans – Saint-Denis-de-l'Hôtel Airport",
        "LBG": "LBG | Paris-Le Bourget Airport",
        "CSF": "CSF | Creil Air Base",
        "CDG": "CDG | Charles de Gaulle International Airport",
        "TNF": "TNF | Toussus-le-Noble Airport",
        "ORY": "ORY | Paris-Orly Airport",
        "POX": "POX | Aérodrome de Pontoise - Cormeilles en Vexin",
        "VIY": "VIY | Villacoublay-Vélizy (BA 107) Air Base",
        "NVS": "NVS | Nevers-Fourchambault Airport",
        "LIL": "LIL | Lille-Lesquin Airport",
        "HZB": "HZB | Merville-Calonne Airport",
        "BES": "BES | Brest Bretagne Airport",
        "CER": "CER | Cherbourg-Maupertus Airport",
        "DNR": "DNR | Dinard-Pleurtuit-Saint-Malo Airport",
        "LBY": "LBY | La Baule-Escoublac Airport",
        "GFR": "GFR | Granville Airport",
        "DOL": "DOL | Deauville-Saint-Gatien Airport",
        "LRT": "LRT | Lorient South Brittany (Bretagne Sud) Airport",
        "EDM": "EDM | La Roche-sur-Yon Airport",
        "LDV": "LDV | Landivisiau Air Base",
        "CFR": "CFR | Caen-Carpiquet Airport",
        "LME": "LME | Le Mans-Arnage Airport",
        "RNS": "RNS | Rennes-Saint-Jacques Airport",
        "LAI": "LAI | Lannion-Côte de Granit Airport",
        "UIP": "UIP | Quimper-Cornouaille Airport",
        "NTE": "NTE | Nantes Atlantique Airport",
        "SBK": "SBK | Saint-Brieuc-Armor Airport",
        "MXN": "MXN | Morlaix-Ploujean Airport",
        "VNE": "VNE | Vannes-Meucon Airport",
        "SNR": "SNR | Saint-Nazaire-Montoir Airport",
        "BSL": "BSL | EuroAirport Basel-Mulhouse-Freiburg Airport",
        "DIJ": "DIJ | Dijon-Bourgogne Airport",
        "EPL": "EPL | Épinal-Mirecourt Airport",
        "BVE": "BVE | Brive-Souillac",
        "ENC": "ENC | Nancy-Essey Airport",
        "SXB": "SXB | Strasbourg Airport",
        "TLN": "TLN | Toulon-Hyères Airport",
        "FNI": "FNI | Nîmes-Arles-Camargue Airport",
        "LTT": "LTT | La Môle Airport",
        "MQC": "MQC | Miquelon Airport",
        "FSP": "FSP | St Pierre Airport",
        "PYR": "PYR | Andravida Air Base",
        "AXD": "AXD | Alexandroupoli Democritus Airport",
        "ATH": "ATH | Athens Eleftherios Venizelos International Airport",
        "VOL": "VOL | Nea Anchialos National Airport",
        "LGE": "LGE | Mulan Airport",
        "JKH": "JKH | Chios Island National Airport",
        "JIK": "JIK | Ikaria Airport",
        "IOA": "IOA | Ioannina Airport",
        "HER": "HER | Heraklion International Nikos Kazantzakis Airport",
        "KSO": "KSO | Kastoria National Airport",
        "KIT": "KIT | Kithira Airport",
        "EFL": "EFL | Kefallinia Airport",
        "KZS": "KZS | Kastelorizo Airport",
        "KLX": "KLX | Kalamata Airport",
        "KGS": "KGS | Kos Airport",
        "AOK": "AOK | Karpathos Airport",
        "CFU": "CFU | Ioannis Kapodistrias International Airport",
        "KSJ": "KSJ | Kasos Airport",
        "KVA": "KVA | Kavala Alexander the Great International Airport",
        "JKL": "JKL | Kalymnos Airport",
        "KZI": "KZI | Filippos Airport",
        "LRS": "LRS | Leros Airport",
        "LXS": "LXS | Limnos Airport",
        "LRA": "LRA | Larisa Air Base",
        "JMK": "JMK | Mikonos Airport",
        "MLO": "MLO | Milos Airport",
        "MJT": "MJT | Mytilene International Airport",
        "LGN": "LGN | Linga Linga Airport",
        "JNX": "JNX | Naxos Apollon Airport",
        "PAS": "PAS | Paros National Airport",
        "JTY": "JTY | Astypalaia Airport",
        "PVK": "PVK | Aktion National Airport",
        "RHO": "RHO | Diagoras Airport",
        "GPA": "GPA | Patras Araxos Agamemnon Airport",
        "CHQ": "CHQ | Chania International Airport",
        "JSI": "JSI | Skiathos Island National Airport",
        "SMI": "SMI | Samos Airport",
        "JSY": "JSY | Syros Airport",
        "SPJ": "SPJ | Sparti Airport",
        "JTR": "JTR | Santorini Airport",
        "JSH": "JSH | Sitia Airport",
        "SKU": "SKU | Skiros Airport",
        "SKG": "SKG | Thessaloniki Macedonia International Airport",
        "ZTH": "ZTH | Zakynthos International Airport Dionysios Solomos",
        "BUD": "BUD | Budapest Liszt Ferenc International Airport",
        "DEB": "DEB | Debrecen International Airport",
        "MCQ": "MCQ | Miskolc Airport",
        "PEV": "PEV | Pécs-Pogány Airport",
        "SOB": "SOB | Hévíz–Balaton Airport",
        "TZR": "TZR | Taszár Air Base",
        "QAQ": "QAQ | L'Aquila–Preturo Airport",
        "CRV": "CRV | Crotone Airport",
        "BRI": "BRI | Bari Karol Wojtyła Airport",
        "FOG": "FOG | Foggia Gino Lisa Airport",
        "TAR": "TAR | Taranto-Grottaglie Marcello Arlotta Airport",
        "LCC": "LCC | Lecce Galatina Air Base",
        "PSR": "PSR | Abruzzo Airport",
        "BDS": "BDS | Brindisi Airport",
        "SUF": "SUF | Lamezia Terme Airport",
        "CIY": "CIY | Comiso Airport",
        "CTA": "CTA | Catania-Fontanarossa Airport",
        "LMP": "LMP | Lampedusa Airport",
        "PNL": "PNL | Pantelleria Airport",
        "PMO": "PMO | Falcone–Borsellino Airport",
        "REG": "REG | Reggio Calabria Airport",
        "TPS": "TPS | Vincenzo Florio Airport Trapani-Birgi",
        "NSY": "NSY | Sigonella Navy Air Base",
        "BLX": "BLX | Belluno Airport",
        "RAN": "RAN | Ravenna Airport",
        "AHO": "AHO | Alghero-Fertilia Airport",
        "DCI": "DCI | Decimomannu Air Base",
        "CAG": "CAG | Cagliari Elmas Airport",
        "OLB": "OLB | Olbia Costa Smeralda Airport",
        "FNU": "FNU | Oristano-Fenosu Airport",
        "TTB": "TTB | Tortolì Airport",
        "MXP": "MXP | Malpensa International Airport",
        "BGY": "BGY | Milan Bergamo Airport",
        "TRN": "TRN | Turin Airport",
        "ALL": "ALL | Villanova D'Albenga International Airport",
        "GOA": "GOA | Genoa Cristoforo Colombo Airport",
        "LIN": "LIN | Milano Linate Airport",
        "PMF": "PMF | Parma Airport",
        "AOT": "AOT | Aosta Airport",
        "CUF": "CUF | Cuneo International Airport",
        "AVB": "AVB | Aviano Air Base",
        "BZO": "BZO | Bolzano Airport",
        "UDN": "UDN | Udine-Campoformido Air Base",
        "BLQ": "BLQ | Bologna Guglielmo Marconi Airport",
        "TSF": "TSF | Treviso-Sant'Angelo Airport",
        "FRL": "FRL | Forlì Airport",
        "VBS": "VBS | Brescia Airport",
        "TRS": "TRS | Trieste–Friuli Venezia Giulia Airport",
        "RMI": "RMI | Federico Fellini International Airport",
        "VRN": "VRN | Verona Villafranca Airport",
        "AOI": "AOI | Marche Airport",
        "VCE": "VCE | Venice Marco Polo Airport",
        "LCV": "LCV | Lucca-Tassignano Airport",
        "SAY": "SAY | Siena-Ampugnano Airport",
        "CIA": "CIA | Ciampino–G. B. Pastine International Airport",
        "FCO": "FCO | Rome–Fiumicino Leonardo da Vinci International Airport",
        "QSR": "QSR | Salerno Costa d'Amalfi Airport",
        "EBA": "EBA | Marina Di Campo Airport",
        "NAP": "NAP | Naples International Airport",
        "PSA": "PSA | Pisa International Airport",
        "FLR": "FLR | Peretola Airport",
        "GRS": "GRS | Grosseto Air Base",
        "PEG": "PEG | Perugia San Francesco d'Assisi – Umbria International Airport",
        "LJU": "LJU | Ljubljana Jože Pučnik Airport",
        "MBX": "MBX | Maribor Airport",
        "POW": "POW | Portorož Airport",
        "LKC": "LKC | Lekana Airport",
        "UHE": "UHE | Kunovice Airport",
        "KLV": "KLV | Karlovy Vary International Airport",
        "MKA": "MKA | Mariánské Lázně Airport",
        "OSR": "OSR | Ostrava Leos Janáček Airport",
        "OLO": "OLO | Olomouc Airport",
        "PED": "PED | Pardubice Airport",
        "PRV": "PRV | Přerov Air Base",
        "PRG": "PRG | Václav Havel Airport Prague",
        "BRQ": "BRQ | Brno-Tuřany Airport",
        "VOD": "VOD | Vodochody Airport",
        "ZBE": "ZBE | Zabreh Ostrava Airport",
        "TLV": "TLV | Ben Gurion International Airport",
        "BEV": "BEV | Beersheba (Teyman) Airport",
        "ETM": "ETM | Ramon Airport",
        "EIY": "EIY | Ein Yahav Airfield",
        "LLH": "LLH | Reginaldo Hammer Airport",
        "HFA": "HFA | Haifa International Airport",
        "RPN": "RPN | Ben Ya'akov Airport",
        "KSW": "KSW | Kiryat Shmona Airport",
        "LLL": "LLL | Lissadell Airport",
        "MTZ": "MTZ | Bar Yehuda Airfield",
        "VTM": "VTM | Nevatim Air Base",
        "VDA": "VDA | Ovda Airport",
        "MIP": "MIP | Ramon Air Base",
        "YOT": "YOT | Yotvata Airfield",
        "LMC": "LMC | La Macarena Airport",
        "MLA": "MLA | Malta International Airport",
        "LMV": "LMV | Madivaru Airport",
        "LMZ": "LMZ | Palma Airport",
        "LNC": "LNC | Lengbati Airport",
        "LNF": "LNF | Munbil Airport",
        "LNM": "LNM | Langimar Airport",
        "HOH": "HOH | Hohenems-Dornbirn Airfield",
        "LOM": "LOM | Francisco Primo de Verdad y Ramos Airport",
        "GRZ": "GRZ | Graz Airport",
        "INN": "INN | Innsbruck Airport",
        "KLU": "KLU | Klagenfurt Airport",
        "LNZ": "LNZ | Linz-Hörsching Airport / Vogler Air Base",
        "SZG": "SZG | Salzburg Airport",
        "VIE": "VIE | Vienna International Airport",
        "AVR": "AVR | Alverca Air Base",
        "SMA": "SMA | Santa Maria Airport",
        "BGC": "BGC | Bragança Airport",
        "BYJ": "BYJ | Beja Airport / Airbase",
        "BGZ": "BGZ | Braga Municipal Aerodrome",
        "CHV": "CHV | Aerodromo de Chaves",
        "CVU": "CVU | Corvo Airport",
        "CAT": "CAT | Cascais Airport",
        "FLW": "FLW | Flores Airport",
        "FAO": "FAO | Faro Airport",
        "GRW": "GRW | Graciosa Airport",
        "HOR": "HOR | Horta Airport",
        "TER": "TER | Lajes Airport",
        "FNC": "FNC | Madeira Airport",
        "QLR": "QLR | Monte Real Air Base",
        "PDL": "PDL | João Paulo II Airport",
        "PIX": "PIX | Pico Airport",
        "PRM": "PRM | Portimão Airport",
        "OPO": "OPO | Francisco de Sá Carneiro Airport",
        "PXO": "PXO | Porto Santo Airport",
        "LIS": "LIS | Humberto Delgado Airport (Lisbon Portela Airport)",
        "SJZ": "SJZ | São Jorge Airport",
        "VRL": "VRL | Vila Real Airport",
        "VSE": "VSE | Aerodromo Goncalves Lobato (Viseu Airport)",
        "BNX": "BNX | Banja Luka International Airport",
        "OMO": "OMO | Mostar International Airport",
        "SJJ": "SJJ | Sarajevo International Airport",
        "TZL": "TZL | Tuzla International Airport",
        "ARW": "ARW | Arad International Airport",
        "BCM": "BCM | Bacău Airport",
        "BAY": "BAY | Maramureș International Airport",
        "BBU": "BBU | Băneasa International Airport",
        "CND": "CND | Mihail Kogălniceanu International Airport",
        "CLJ": "CLJ | Cluj-Napoca International Airport",
        "CSB": "CSB | Caransebeş Airport",
        "CRA": "CRA | Craiova Airport",
        "IAS": "IAS | Iaşi Airport",
        "OMR": "OMR | Oradea International Airport",
        "OTP": "OTP | Henri Coandă International Airport",
        "SBZ": "SBZ | Sibiu International Airport",
        "SUJ": "SUJ | Satu Mare Airport",
        "SCV": "SCV | Suceava Stefan cel Mare Airport",
        "TCE": "TCE | Tulcea Danube Delta Airport",
        "TGM": "TGM | Transilvania Târgu Mureş International Airport",
        "TSR": "TSR | Timişoara Traian Vuia Airport",
        "GVA": "GVA | Geneva Cointrin International Airport",
        "SIR": "SIR | Sion Airport",
        "EML": "EML | Emmen Air Base",
        "LUG": "LUG | Lugano Airport",
        "BRN": "BRN | Bern Belp Airport",
        "BXO": "BXO | Buochs Airport",
        "ZHI": "ZHI | Grenchen Airport",
        "ZRH": "ZRH | Zürich Airport",
        "ZJI": "ZJI | Locarno Airport",
        "ACH": "ACH | St Gallen Altenrhein Airport",
        "SMV": "SMV | Samedan Airport",
        "GKD": "GKD | Gökçeada Airport",
        "ESB": "ESB | Esenboğa International Airport",
        "ANK": "ANK | Etimesgut Air Base",
        "ADA": "ADA | Adana Şakirpaşa Airport",
        "UAB": "UAB | İncirlik Air Base",
        "AFY": "AFY | Afyon Air Base",
        "AYT": "AYT | Antalya International Airport",
        "GZT": "GZT | Gaziantep International Airport",
        "KFS": "KFS | Kastamonu Airport",
        "KYA": "KYA | Konya Airport",
        "MZH": "MZH | Amasya Merzifon Airport",
        "VAS": "VAS | Sivas Nuri Demirağ Airport",
        "ONQ": "ONQ | Zonguldak Çaycuma Airport",
        "MLX": "MLX | Malatya Erhaç Airport",
        "ASR": "ASR | Kayseri Erkilet Airport",
        "TJK": "TJK | Tokat Airport",
        "DNZ": "DNZ | Çardak Airport",
        "NAV": "NAV | Nevşehir Kapadokya Airport",
        "ISL": "ISL | İstanbul Atatürk Airport",
        "CII": "CII | Çıldır Airport",
        "BTZ": "BTZ | Bursa Airport",
        "BZI": "BZI | Balıkesir Merkez Airport",
        "BDM": "BDM | Bandırma Airport",
        "CKZ": "CKZ | Çanakkale Airport",
        "ESK": "ESK | Eskişehir Air Base",
        "ADB": "ADB | Adnan Menderes International Airport",
        "IGL": "IGL | Çiğli Airport",
        "USQ": "USQ | Uşak Airport",
        "KCO": "KCO | Cengiz Topel Airport",
        "YEI": "YEI | Bursa Yenişehir Airport",
        "DLM": "DLM | Dalaman International Airport",
        "TEQ": "TEQ | Tekirdağ Çorlu Airport",
        "BXN": "BXN | Bodrum-Imsık Airport",
        "AOE": "AOE | Anadolu Airport",
        "KZR": "KZR | Zafer Airport",
        "EZS": "EZS | Elazığ Airport",
        "DIY": "DIY | Diyarbakır Airport",
        "ERC": "ERC | Erzincan Airport",
        "ERZ": "ERZ | Erzurum International Airport",
        "KSY": "KSY | Kars Airport",
        "TZX": "TZX | Trabzon International Airport",
        "SFQ": "SFQ | Şanlıurfa Airport",
        "VAN": "VAN | Van Ferit Melen Airport",
        "BAL": "BAL | Batman Airport",
        "MSR": "MSR | Muş Airport",
        "SXZ": "SXZ | Siirt Airport",
        "NOP": "NOP | Sinop Airport",
        "KCM": "KCM | Kahramanmaraş Airport",
        "AJI": "AJI | Ağrı Airport",
        "ADF": "ADF | Adıyaman Airport",
        "MQM": "MQM | Mardin Airport",
        "GNY": "GNY | Şanlıurfa GAP Airport",
        "NKT": "NKT | Şırnak Şerafettin Elçi Airport",
        "YKO": "YKO | Hakkari Yüksekova Airport",
        "HTY": "HTY | Hatay Airport",
        "LTF": "LTF | Leitre Airport",
        "ISE": "ISE | Süleyman Demirel International Airport",
        "EDO": "EDO | Balıkesir Koca Seyit Airport",
        "BJV": "BJV | Milas Bodrum International Airport",
        "GZP": "GZP | Gazipaşa-Alanya Airport",
        "SZF": "SZF | Samsun-Çarşamba Airport",
        "SAW": "SAW | Istanbul Sabiha Gökçen International Airport",
        "IST": "IST | İstanbul Airport",
        "BZY": "BZY | Bălți International Airport",
        "KIV": "KIV | Chişinău International Airport",
        "LUZ": "LUZ | Lublin Airport",
        "LWA": "LWA | Lebak Rural Airport",
        "OHD": "OHD | Ohrid St. Paul the Apostle Airport",
        "SKP": "SKP | Skopje International Airport",
        "GIB": "GIB | Gibraltar Airport",
        "BCQ": "BCQ | Brak Airport",
        "DNF": "DNF | Martubah Airport",
        "MRA": "MRA | Misratah Airport",
        "QUB": "QUB | Ubari Airport",
        "UZC": "UZC | Ponikve Airport",
        "BEG": "BEG | Belgrade Nikola Tesla Airport",
        "IVG": "IVG | Berane Airport",
        "BJY": "BJY | Batajnica Air Base",
        "KVO": "KVO | Morava Airport",
        "INI": "INI | Nis Airport",
        "TGD": "TGD | Podgorica Airport / Podgorica Golubovci Airbase",
        "TIV": "TIV | Tivat Airport",
        "BTS": "BTS | M. R. Štefánik Airport",
        "KSC": "KSC | Košice Airport",
        "LUE": "LUE | Lučenec Airport",
        "LZO": "LZO | Luzhou Yunlong Airport",
        "PZY": "PZY | Piešťany Airport",
        "POV": "POV | Prešov Air Base",
        "SLD": "SLD | Sliač Airport",
        "TAT": "TAT | Poprad-Tatry Airport",
        "ILZ": "ILZ | Žilina Airport",
        "DRU": "DRU | Drummond Airport",
        "GLN": "GLN | Guelmim Airport",
        "UWA": "UWA | Ware Airport",
        "MAP": "MAP | Mamai Airport",
        "GDT": "GDT | JAGS McCartney International Airport",
        "MDS": "MDS | Middle Caicos Airport",
        "NCA": "NCA | North Caicos Airport",
        "PIC": "PIC | Pine Cay Airport",
        "PLS": "PLS | Providenciales International Airport",
        "XSC": "XSC | South Caicos Airport",
        "SLX": "SLX | Salt Cay Airport",
        "BRX": "BRX | Maria Montez International Airport",
        "CBJ": "CBJ | Cabo Rojo Airport",
        "AZS": "AZS | Samaná El Catey International Airport",
        "COZ": "COZ | Constanza - Expedición 14 de Junio National Airport",
        "JBQ": "JBQ | La Isabela International Airport",
        "LRM": "LRM | Casa De Campo International Airport",
        "PUJ": "PUJ | Punta Cana International Airport",
        "POP": "POP | Gregorio Luperon International Airport",
        "SDQ": "SDQ | Las Américas International Airport",
        "STI": "STI | Cibao International Airport",
        "MDV": "MDV | Médouneu Airport",
        "LIZ": "LIZ | Loring International Airport",
        "MEF": "MEF | Melfi Airport",
        "OHB": "OHB | Ambohibary Airport",
        "DOA": "DOA | Doany Airport",
        "CBV": "CBV | Coban Airport",
        "CMM": "CMM | Carmelita Airport",
        "CTF": "CTF | Coatepeque Airport",
        "GUA": "GUA | La Aurora Airport",
        "HUG": "HUG | Huehuetenango Airport",
        "MCR": "MCR | Melchor de Mencos Airport",
        "MGP": "MGP | Manga Airport",
        "PBR": "PBR | Puerto Barrios Airport",
        "PON": "PON | Poptún Airport",
        "AQB": "AQB | Quiché Airport",
        "AAZ": "AAZ | Quezaltenango Airport",
        "RUV": "RUV | Rubelsanto Airport",
        "LCF": "LCF | Las Vegas Airport",
        "RER": "RER | Retalhuleu Airport",
        "GSJ": "GSJ | San José Airport",
        "FRS": "FRS | Mundo Maya International Airport",
        "AIM": "AIM | Ailuk Airport",
        "AUL": "AUL | Aur Island Airport",
        "BII": "BII | Enyu Airfield",
        "EBN": "EBN | Ebadon Airport",
        "JAT": "JAT | Jabot Airport",
        "JEJ": "JEJ | Jeh Airport",
        "LIK": "LIK | Likiep Airport",
        "LML": "LML | Lae Island Airport",
        "MAV": "MAV | Maloelap Island Airport",
        "MJB": "MJB | Mejit Atoll Airport",
        "MJE": "MJE | Majkin Airport",
        "NDK": "NDK | Namorik Atoll Airport",
        "RNP": "RNP | Rongelap Island Airport",
        "TIC": "TIC | Tinak Airport",
        "UIT": "UIT | Jaluit Airport",
        "WJA": "WJA | Woja Airport",
        "WTE": "WTE | Wotje Airport",
        "WTO": "WTO | Wotho Island Airport",
        "AHS": "AHS | Ahuas Airport",
        "BHG": "BHG | Brus Laguna Airport",
        "CAA": "CAA | Catacamas Airport",
        "LUI": "LUI | Carta Airport",
        "CYL": "CYL | Coyoles Airport",
        "CDD": "CDD | Cauquira Airport",
        "OAN": "OAN | El Arrayán Airport",
        "GAC": "GAC | Celaque Airport",
        "IRN": "IRN | Iriona Airport",
        "GUO": "GUO | Jicalapa Airport",
        "JUT": "JUT | Jutigalpa airport",
        "LCE": "LCE | Goloson International Airport",
        "LEZ": "LEZ | La Esperanza Airport",
        "SAP": "SAP | Ramón Villeda Morales International Airport",
        "MHN": "MHN | Hooker County Airport",
        "GJA": "GJA | La Laguna Airport",
        "PCH": "PCH | Palacios Airport",
        "PEU": "PEU | Puerto Lempira Airport",
        "RTB": "RTB | Juan Manuel Gálvez International Airport",
        "RUY": "RUY | Copán Ruinas Airport",
        "XPL": "XPL | Coronel Enrique Soto Cano Air Base",
        "TEA": "TEA | Tela Airport",
        "TGU": "TGU | Toncontín International Airport",
        "TJI": "TJI | Trujillo Airport",
        "SCD": "SCD | Sulaco Airport",
        "UII": "UII | Utila Airport",
        "MHY": "MHY | Morehead Airport",
        "ORO": "ORO | Yoro Airport",
        "MIZ": "MIZ | Mainoru Airstrip",
        "MJJ": "MJJ | Moki Airport",
        "MJS": "MJS | Maganja da Costa Airport",
        "OCJ": "OCJ | Ian Fleming International Airport",
        "KIN": "KIN | Norman Manley International Airport",
        "MBJ": "MBJ | Sangster International Airport",
        "POT": "POT | Ken Jones Airport",
        "MKN": "MKN | Malekolon Airport",
        "NEG": "NEG | Negril Airport",
        "KTP": "KTP | Tinson Pen Airport",
        "MIJ": "MIJ | Mili Island Airport",
        "MLQ": "MLQ | Malalaua Airport",
        "HEB": "HEB | Hinthada Airport",
        "TZM": "TZM | Cupul Airport",
        "ACA": "ACA | General Juan N Alvarez International Airport",
        "NTR": "NTR | Del Norte International Airport",
        "AGU": "AGU | Jesús Terán Paredo International Airport",
        "HUX": "HUX | Bahías de Huatulco International Airport",
        "CNA": "CNA | Cananea National Airport",
        "CVJ": "CVJ | Cuernavaca - General Mariano Matamoros Airport",
        "ACN": "ACN | Ciudad Acuña New International Airport",
        "CME": "CME | Ciudad del Carmen International Airport",
        "NCG": "NCG | Nuevo Casas Grandes Municipal Airport",
        "CUL": "CUL | Bachigualato Federal International Airport",
        "CTM": "CTM | Chetumal International Airport",
        "CEN": "CEN | Ciudad Obregón International Airport",
        "CJT": "CJT | San Antonio Copalar Airport",
        "CPE": "CPE | Ingeniero Alberto Acuña Ongay International Airport",
        "CJS": "CJS | Abraham González International Airport",
        "CZA": "CZA | Chichen Itza International Airport",
        "CUU": "CUU | General Roberto Fierro Villalobos International Airport",
        "CVM": "CVM | General Pedro Jose Mendez International Airport",
        "CYW": "CYW | Captain Rogelio Castillo National Airport",
        "CZM": "CZM | Cozumel International Airport",
        "CUA": "CUA | Ciudad Constitución National Airport",
        "MMC": "MMC | Ciudad Mante National Airport",
        "DGO": "DGO | General Guadalupe Victoria International Airport",
        "TPQ": "TPQ | Amado Nervo National Airport",
        "ESE": "ESE | Ensenada International Airport / El Cipres Air Base",
        "GDL": "GDL | Don Miguel Hidalgo Y Costilla International Airport",
        "GYM": "GYM | General José María Yáñez International Airport",
        "GUB": "GUB | Guerrero Negro Airport",
        "TCN": "TCN | Tehuacan Airport",
        "HMO": "HMO | General Ignacio P. Garcia International Airport",
        "CLQ": "CLQ | Licenciado Miguel de la Madrid Airport",
        "ISJ": "ISJ | Isla Mujeres Airport",
        "SLW": "SLW | Plan De Guadalupe International Airport",
        "IZT": "IZT | Ixtepec Airport",
        "JAL": "JAL | El Lencero Airport",
        "AZP": "AZP | Atizapán de Zaragoza - Doctor Jorge Jiménez Cantu National Airport",
        "LZC": "LZC | Lázaro Cárdenas Airport",
        "LMM": "LMM | Valle del Fuerte International Airport",
        "BJX": "BJX | Del Bajío International Airport",
        "LAP": "LAP | Manuel Márquez de León International Airport",
        "LTO": "LTO | Loreto International Airport",
        "MAM": "MAM | General Servando Canales International Airport",
        "MID": "MID | Licenciado Manuel Crescencio Rejon Int Airport",
        "MUG": "MUG | Mulege Airport",
        "MXL": "MXL | General Rodolfo Sánchez Taboada International Airport",
        "MLM": "MLM | General Francisco J. Mujica International Airport",
        "MTT": "MTT | Minatitlán/Coatzacoalcos National Airport",
        "LOV": "LOV | Monclova International Airport",
        "MEX": "MEX | Licenciado Benito Juarez International Airport",
        "MTY": "MTY | General Mariano Escobedo International Airport",
        "MZT": "MZT | General Rafael Buelna International Airport",
        "NOG": "NOG | Nogales International Airport",
        "NLD": "NLD | Quetzalcóatl International Airport",
        "OAX": "OAX | Xoxocotlán International Airport",
        "PAZ": "PAZ | El Tajín National Airport",
        "PBC": "PBC | Hermanos Serdán International Airport",
        "PDS": "PDS | Piedras Negras International Airport",
        "UPN": "UPN | Uruapan - Licenciado y General Ignacio Lopez Rayon International Airport",
        "PQM": "PQM | Palenque International Airport",
        "PVR": "PVR | Licenciado Gustavo Díaz Ordaz International Airport",
        "PXM": "PXM | Puerto Escondido International Airport",
        "QRO": "QRO | Querétaro Intercontinental Airport",
        "REX": "REX | General Lucio Blanco International Airport",
        "SJD": "SJD | Los Cabos International Airport",
        "SFH": "SFH | San Felipe International Airport",
        "NLU": "NLU | Santa Lucía Air Force Base / General Felipe Ángeles International Airport",
        "SLP": "SLP | Ponciano Arriaga International Airport",
        "TRC": "TRC | Francisco Sarabia International Airport",
        "TGZ": "TGZ | Angel Albino Corzo International Airport",
        "TIJ": "TIJ | General Abelardo L. Rodríguez International Airport",
        "TAM": "TAM | General Francisco Javier Mina International Airport",
        "TSL": "TSL | Tamuin Airport",
        "TLC": "TLC | Licenciado Adolfo Lopez Mateos International Airport",
        "TAP": "TAP | Tapachula International Airport",
        "CUN": "CUN | Cancún International Airport",
        "MMV": "MMV | Mal Airport",
        "VSA": "VSA | Carlos Rovirosa Pérez International Airport",
        "VER": "VER | General Heriberto Jara International Airport",
        "ZCL": "ZCL | General Leobardo C. Ruiz International Airport",
        "ZIH": "ZIH | Ixtapa Zihuatanejo International Airport",
        "ZMM": "ZMM | Zamora Airport",
        "ZLO": "ZLO | Playa De Oro International Airport",
        "MXW": "MXW | Mandalgobi Airport",
        "BEF": "BEF | Bluefields Airport",
        "BZA": "BZA | San Pedro Airport",
        "ECI": "ECI | Costa Esmeralda Airport",
        "RNI": "RNI | Corn Island Airport",
        "MGA": "MGA | Augusto C. Sandino (Managua) International Airport",
        "NVG": "NVG | Nueva Guinea Airport",
        "PUZ": "PUZ | Puerto Cabezas Airport",
        "RFS": "RFS | Rosita Airport",
        "NCR": "NCR | San Carlos",
        "SIU": "SIU | Siuna",
        "WSP": "WSP | Waspam Airport",
        "MOH": "MOH | Maleo Airport",
        "MOS": "MOS | Moses Point Airport",
        "BOC": "BOC | Bocas del Toro International Airport",
        "CTD": "CTD | Alonso Valderrama Airport",
        "CHX": "CHX | Cap Manuel Niño International Airport",
        "DAV": "DAV | Enrique Malek International Airport",
        "ONX": "ONX | Enrique Adolfo Jimenez Airport",
        "MPG": "MPG | Makini Airport",
        "BLB": "BLB | Panamá Pacífico International Airport",
        "MPI": "MPI | Mamitupo Airport",
        "JQE": "JQE | Jaqué Airport",
        "PAC": "PAC | Marcos A. Gelabert International Airport",
        "PUE": "PUE | Puerto Obaldía Airport",
        "PDM": "PDM | Capt. J. Montenegro Airport",
        "RIH": "RIH | Scarlett Martinez International Airport",
        "SYP": "SYP | Ruben Cantu Airport",
        "PTY": "PTY | Tocumen International Airport",
        "MPU": "MPU | Mapua(Mabua) Airport",
        "PVE": "PVE | El Porvenir Airport",
        "NBL": "NBL | San Blas Airport",
        "MPX": "MPX | Miyanmin Airport",
        "MQO": "MQO | Malam Airport",
        "FON": "FON | La Fortuna Arenal Airport",
        "TTQ": "TTQ | Aerotortuguero Airport",
        "BAI": "BAI | Buenos Aires Airport",
        "BCL": "BCL | Barra del Colorado Airport",
        "OTR": "OTR | Coto 47 Airport",
        "JAP": "JAP | Chacarita Airport",
        "PLD": "PLD | Playa Samara/Carrillo Airport",
        "DRK": "DRK | Drake Bay Airport",
        "FMG": "FMG | Flamingo Airport",
        "GLF": "GLF | Golfito Airport",
        "GPL": "GPL | Guapiles Airport",
        "PBP": "PBP | Islita Airport",
        "LIR": "LIR | Daniel Oduber Quiros International Airport",
        "LSL": "LSL | Los Chiles Airport",
        "LIO": "LIO | Limon International Airport",
        "CSC": "CSC | Mojica Airport",
        "NCT": "NCT | Guanacaste Airport",
        "NOB": "NOB | Nosara Airport",
        "SJO": "SJO | Juan Santamaría International Airport",
        "PJM": "PJM | Puerto Jimenez Airport",
        "PMZ": "PMZ | Palmar Sur Airport",
        "SYQ": "SYQ | Tobías Bolaños International Airport",
        "XQP": "XQP | Quepos Managua Airport",
        "RFR": "RFR | Rio Frio / Progreso Airport",
        "IPZ": "IPZ | San Isidro del General Airport",
        "TOO": "TOO | San Vito De Java Airport",
        "MRT": "MRT | Moroak Airport",
        "TNO": "TNO | Tamarindo Airport",
        "TMU": "TMU | Tambor Airport",
        "UPL": "UPL | Upala Airport",
        "MSB": "MSB | Marigot Seaplane Base",
        "SAL": "SAL | Monseñor Óscar Arnulfo Romero International Airport",
        "CYA": "CYA | Les Cayes Airport",
        "CAP": "CAP | Cap Haitien International Airport",
        "MTX": "MTX | Metro Field",
        "JAK": "JAK | Jacmel Airport",
        "JEE": "JEE | Jérémie Airport",
        "PAP": "PAP | Toussaint Louverture International Airport",
        "PAX": "PAX | Port-de-Paix Airport",
        "MTU": "MTU | Montepuez Airport",
        "BCA": "BCA | Gustavo Rizo Airport",
        "BWW": "BWW | Las Brujas Airport",
        "BYM": "BYM | Carlos Manuel de Cespedes Airport",
        "AVI": "AVI | Maximo Gomez Airport",
        "CCC": "CCC | Jardines Del Rey Airport",
        "CFG": "CFG | Jaime Gonzalez Airport",
        "CYO": "CYO | Vilo Acuña International Airport",
        "CMW": "CMW | Ignacio Agramonte International Airport",
        "SCU": "SCU | Antonio Maceo International Airport",
        "NBW": "NBW | Leeward Point Field",
        "GAO": "GAO | Mariana Grajales Airport",
        "HAV": "HAV | José Martí International Airport",
        "HOG": "HOG | Frank Pais International Airport",
        "VRO": "VRO | Kawama Airport",
        "LCL": "LCL | La Coloma Airport",
        "UMA": "UMA | Punta de Maisi Airport",
        "MJG": "MJG | Mayajigua Airport",
        "MOA": "MOA | Orestes Acosta Airport",
        "MZO": "MZO | Sierra Maestra Airport",
        "QSN": "QSN | San Nicolas De Bari Airport",
        "ICR": "ICR | Nicaro Airport",
        "GER": "GER | Rafael Cabrera Airport",
        "UPB": "UPB | Playa Baracoa Airport",
        "SNU": "SNU | Abel Santamaria Airport",
        "SNJ": "SNJ | San Julian Air Base",
        "SZJ": "SZJ | Siguanea Airport",
        "USS": "USS | Sancti Spiritus Airport",
        "TND": "TND | Alberto Delgado Airport",
        "VRA": "VRA | Juan Gualberto Gomez International Airport",
        "VTU": "VTU | Hermanos Ameijeiras Airport",
        "CYB": "CYB | Gerrard Smith International Airport",
        "LYB": "LYB | Edward Bodden Little Cayman Airfield",
        "GCM": "GCM | Owen Roberts International Airport",
        "MWR": "MWR | Motswari Airport",
        "AJS": "AJS | Punta Abreojos Airport",
        "AZG": "AZG | Apatzingán - Pablo L. Sidar Airport",
        "PCM": "PCM | Playa del Carmen Airport",
        "PCV": "PCV | Punta Chivato Airport",
        "SCX": "SCX | Salina Cruz Naval Air Station",
        "SGM": "SGM | San Ignacio Airport",
        "TUY": "TUY | Tulum Naval Air Station",
        "UAC": "UAC | San Luis Río Colorado Airport",
        "XAL": "XAL | Álamos Airport",
        "MXC": "MXC | Monticello Airport",
        "MXK": "MXK | Mindik Airport",
        "GTK": "GTK | Sungai Tekai Airport",
        "LBP": "LBP | Long Banga Airport",
        "LLM": "LLM | Long Lama Airport",
        "MZS": "MZS | Mostyn Airport",
        "SPT": "SPT | Sipitang Airport",
        "MAY": "MAY | Clarence A. Bain Airport",
        "ASD": "ASD | Andros Town Airport",
        "TZN": "TZN | Congo Town Airport",
        "MHH": "MHH | Leonard M Thompson International Airport",
        "SAQ": "SAQ | San Andros Airport",
        "AXP": "AXP | Spring Point Airport",
        "TCB": "TCB | Treasure Cay Airport",
        "WKR": "WKR | Walkers Cay Airport",
        "CCZ": "CCZ | Chub Cay Airport",
        "GHC": "GHC | Great Harbour Cay Airport",
        "BIM": "BIM | South Bimini Airport",
        "ATC": "ATC | Arthur's Town Airport",
        "TBI": "TBI | New Bight Airport",
        "CRI": "CRI | Colonel Hill Airport",
        "PWN": "PWN | Pitts Town Airport",
        "GGT": "GGT | Exuma International Airport",
        "ELH": "ELH | North Eleuthera Airport",
        "GHB": "GHB | Governor's Harbour Airport",
        "NMC": "NMC | Normans Cay Airport",
        "RSD": "RSD | Rock Sound Airport",
        "TYM": "TYM | Staniel Cay Airport",
        "FPO": "FPO | Grand Bahama International Airport",
        "GBI": "GBI | Grand Bahama Auxiliary Airfield",
        "WTD": "WTD | West End Airport",
        "IGA": "IGA | Inagua Airport",
        "MYK": "MYK | May Creek Airport",
        "LGI": "LGI | Deadman's Cay Airport",
        "SML": "SML | Stella Maris Airport",
        "MYG": "MYG | Mayaguana Airport",
        "NAS": "NAS | Lynden Pindling International Airport",
        "DCT": "DCT | Duncan Town Airport",
        "RCY": "RCY | Rum Cay Airport",
        "MYS": "MYS | Moyale Airport",
        "ZSA": "ZSA | San Salvador Airport",
        "MYX": "MYX | Menyamya Airport",
        "NTC": "NTC | Paradise Island Airport",
        "IBO": "IBO | Ibo Airport",
        "TGS": "TGS | Chokwé Airport",
        "BZE": "BZE | Philip S. W. Goldson International Airport",
        "MZE": "MZE | Manatee Airport",
        "CYD": "CYD | San Ignacio Town (Maya Flats) Airstrip",
        "IMI": "IMI | Ine Airport",
        "BQI": "BQI | Bagani Airport",
        "NBS": "NBS | Changbaishan Airport",
        "AIT": "AIT | Aitutaki Airport",
        "AIU": "AIU | Enua Airport",
        "MGS": "MGS | Mangaia Island Airport",
        "MHX": "MHX | Manihiki Island Airport",
        "MUK": "MUK | Mauke Airport",
        "MOI": "MOI | Mitiaro Island Airport",
        "PZK": "PZK | Pukapuka Island Airport",
        "PYE": "PYE | Tongareva Airport",
        "RAR": "RAR | Rarotonga International Airport",
        "NDI": "NDI | Namudi Airport",
        "NDN": "NDN | Nadunumu Airport",
        "EPG": "EPG | Browns Airport",
        "ICI": "ICI | Cicia Airport",
        "CST": "CST | Castaway Island Seaplane Base",
        "NAN": "NAN | Nadi International Airport",
        "PTF": "PTF | Malolo Lailai Island Airport",
        "RBI": "RBI | Rabi Island Airport",
        "KDV": "KDV | Vunisea Airport",
        "MNF": "MNF | Mana Island Airport",
        "MFJ": "MFJ | Moala Airport",
        "SUV": "SUV | Nausori International Airport",
        "LEV": "LEV | Levuka Airfield",
        "NGI": "NGI | Ngau Airport",
        "LUC": "LUC | Laucala Island Airport",
        "LKB": "LKB | Lakeba Island Airport",
        "LBS": "LBS | Labasa Airport",
        "TVU": "TVU | Matei Airport",
        "KXF": "KXF | Koro Island Airport",
        "RTA": "RTA | Rotuma Airport",
        "SVU": "SVU | Savusavu Airport",
        "VAU": "VAU | Vatukoula Airport",
        "KAY": "KAY | Wakaya Island Airport",
        "ONU": "ONU | Ono-i-Lau Airport",
        "YAS": "YAS | Yasawa Island Airport",
        "EUA": "EUA | Kaufana Airport",
        "TBU": "TBU | Fua'amotu International Airport",
        "HPA": "HPA | Lifuka Island Airport",
        "NFO": "NFO | Mata'aho Airport",
        "NTT": "NTT | Kuini Lavenia Airport",
        "VAV": "VAV | Vava'u International Airport",
        "TTL": "TTL | Turtle Island Seaplane Base",
        "VBV": "VBV | Vanua Balavu Airport",
        "VTF": "VTF | Vatulele Airport",
        "GMO": "GMO | Gombe Lawanti International Airport",
        "PHG": "PHG | Port Harcourt City Airport / Port Harcourt Air Force Base",
        "QRW": "QRW | Warri Airport",
        "ABF": "ABF | Abaiang Airport",
        "BEZ": "BEZ | Beru Airport",
        "FUN": "FUN | Funafuti International Airport",
        "KUC": "KUC | Kuria Airport",
        "MNK": "MNK | Maiana Airport",
        "MZK": "MZK | Marakei Airport",
        "MTK": "MTK | Makin Island Airport",
        "NIG": "NIG | Nikunau Airport",
        "OOT": "OOT | Onotoa Airport",
        "TRW": "TRW | Bonriki International Airport",
        "AEA": "AEA | Abemama Airport",
        "TBF": "TBF | Tabiteuea North Airport",
        "TMN": "TMN | Tamana Island Airport",
        "NON": "NON | Nonouti Airport",
        "AIS": "AIS | Arorae Island Airport",
        "TSU": "TSU | Tabiteuea South Airport",
        "BBG": "BBG | Butaritari Airport",
        "AAK": "AAK | Aranuka Airport",
        "NIK": "NIK | Niokolo-Koba Airport",
        "IUE": "IUE | Niue International Airport",
        "NJJ": "NJJ | Nenjian Melgen Airport",
        "NKD": "NKD | Sinak Airport",
        "NLH": "NLH | Ninglang Luguhu Airport",
        "FUT": "FUT | Pointe Vele Airport",
        "WLS": "WLS | Hihifo Airport",
        "HBB": "HBB | Industrial Airpark",
        "NND": "NND | Nangade Airport",
        "NOM": "NOM | Nomad River Airport",
        "NOO": "NOO | Naoro Airport",
        "NPG": "NPG | Nipa Airport",
        "NRY": "NRY | Newry Airport",
        "OFU": "OFU | Ofu Airport",
        "AAU": "AAU | Asau Airport",
        "APW": "APW | Faleolo International Airport",
        "FTI": "FTI | Fitiuta Airport",
        "MXS": "MXS | Maota Airport",
        "PPG": "PPG | Pago Pago International Airport",
        "PPT": "PPT | Faa'a International Airport",
        "RMT": "RMT | Rimatara Airport",
        "RUR": "RUR | Rurutu Airport",
        "TUB": "TUB | Tubuai Airport",
        "RVV": "RVV | Raivavae Airport",
        "AAA": "AAA | Anaa Airport",
        "FGU": "FGU | Fangatau Airport",
        "TIH": "TIH | Tikehau Airport",
        "APK": "APK | Apataki Airport",
        "REA": "REA | Reao Airport",
        "FAV": "FAV | Fakarava Airport",
        "HHZ": "HHZ | Hikueru Airport",
        "XMH": "XMH | Manihi Airport",
        "GMR": "GMR | Totegegie Airport",
        "KKR": "KKR | Kaukura Airport",
        "MKP": "MKP | Makemo Airport",
        "NAU": "NAU | Napuka Island Airport",
        "TKV": "TKV | Tatakoto Airport",
        "PKP": "PKP | Puka Puka Airport",
        "PUK": "PUK | Pukaruha Airport",
        "TKP": "TKP | Takapoto Airport",
        "AXR": "AXR | Arutua Airport",
        "MVT": "MVT | Mataiva Airport",
        "NUK": "NUK | Nukutavake Airport",
        "ZTA": "ZTA | Tureia Airport",
        "AHE": "AHE | Ahe Airport",
        "KHZ": "KHZ | Kauehi Airport",
        "FAC": "FAC | Faaite Airport",
        "FHZ": "FHZ | Fakahina Airport",
        "RKA": "RKA | Aratika Nord Airport",
        "TJN": "TJN | Takume Airport",
        "NIU": "NIU | Naiu Airport",
        "RRR": "RRR | Raroia Airport",
        "TKX": "TKX | Takaroa Airport",
        "KXU": "KXU | Katiu Airport",
        "NKP": "NKP | Nukutepipi Airport",
        "NHV": "NHV | Nuku Hiva Airport",
        "AUQ": "AUQ | Hiva Oa-Atuona Airport",
        "UAP": "UAP | Ua Pou Airport",
        "UAH": "UAH | Ua Huka Airport",
        "BOB": "BOB | Bora Bora Airport",
        "TTI": "TTI | Tetiaroa Airport",
        "RGI": "RGI | Rangiroa Airport",
        "HUH": "HUH | Huahine-Fare Airport",
        "MOZ": "MOZ | Moorea Temae Airport",
        "HOI": "HOI | Hao Airport",
        "MAU": "MAU | Maupiti Airport",
        "RFP": "RFP | Raiatea Airport",
        "TPX": "TPX | Tupai Airport",
        "UOA": "UOA | Moruroa Airport",
        "VHZ": "VHZ | Vahitahi Airport",
        "NUF": "NUF | Castlereagh Lake Seaplane Base",
        "NUG": "NUG | Nuguria Airstrip",
        "UCC": "UCC | Yucca Airstrip",
        "MTV": "MTV | Mota Lava Airport",
        "SLH": "SLH | Sola Airport",
        "TOH": "TOH | Torres Airstrip",
        "EAE": "EAE | Siwo Airport",
        "CCV": "CCV | Craig Cove Airport",
        "LOD": "LOD | Longana Airport",
        "SSR": "SSR | Sara Airport",
        "PBJ": "PBJ | Tavie Airport",
        "LPM": "LPM | Lamap Airport",
        "LNB": "LNB | Lamen Bay Airport",
        "MWF": "MWF | Maewo-Naone Airport",
        "LNE": "LNE | Lonorore Airport",
        "NUS": "NUS | Norsup Airport",
        "ZGU": "ZGU | Gaua Island Airport",
        "RCL": "RCL | Redcliffe Airport",
        "SON": "SON | Santo Pekoa International Airport",
        "TGH": "TGH | Tongoa Airport",
        "ULB": "ULB | Uléi Airport",
        "VLS": "VLS | Valesdir Airport",
        "WLH": "WLH | Walaha Airport",
        "SWJ": "SWJ | Southwest Bay Airport",
        "OLJ": "OLJ | North West Santo Airport",
        "AUY": "AUY | Aneityum Airport",
        "AWD": "AWD | Aniwa Airport",
        "DLY": "DLY | Dillon's Bay Airport",
        "FTA": "FTA | Futuna Airport",
        "IPA": "IPA | Ipota Airport",
        "VLI": "VLI | Bauerfield International Airport",
        "TAH": "TAH | Tanna Airport",
        "TGJ": "TGJ | Tiga Airport",
        "BMY": "BMY | Île Art - Waala Airport",
        "KNQ": "KNQ | Koné Airport",
        "ILP": "ILP | Île des Pins Airport",
        "HLU": "HLU | Nesson Airport",
        "KOC": "KOC | Koumac Airport",
        "LIF": "LIF | Lifou Airport",
        "GEA": "GEA | Nouméa Magenta Airport",
        "PUV": "PUV | Poum / Malabou Airport",
        "PDC": "PDC | Mueo Airport",
        "MEE": "MEE | Maré Airport",
        "TOU": "TOU | Touho Airport",
        "UVE": "UVE | Ouvéa Airport",
        "NOU": "NOU | La Tontouta International Airport",
        "AKL": "AKL | Auckland International Airport",
        "TUO": "TUO | Taupo Airport",
        "AMZ": "AMZ | Ardmore Airport",
        "ASG": "ASG | Ashburton Aerodrome",
        "CHC": "CHC | Christchurch International Airport",
        "CHT": "CHT | Chatham Islands / Tuuta Airport",
        "CMV": "CMV | Coromandel Aerodrome",
        "DGR": "DGR | Dargaville Aerodrome",
        "DUD": "DUD | Dunedin International Airport",
        "WHO": "WHO | Franz Josef Aerodrome",
        "GBZ": "GBZ | Great Barrier Aerodrome",
        "GMN": "GMN | Greymouth Airport",
        "GIS": "GIS | Gisborne Airport",
        "GTN": "GTN | Glentanner Airport",
        "HKK": "HKK | Hokitika Airfield",
        "HLZ": "HLZ | Hamilton International Airport",
        "WIK": "WIK | Waiheke Reeve Airport",
        "KBZ": "KBZ | Kaikoura Airport",
        "KKE": "KKE | Kerikeri Airport",
        "KKO": "KKO | Kaikohe Airport",
        "KAT": "KAT | Kaitaia Airport",
        "ALR": "ALR | Alexandra Aerodrome",
        "MTA": "MTA | Matamata Glider Airport",
        "MON": "MON | Mount Cook Airport",
        "MFN": "MFN | Milford Sound Airport",
        "MZP": "MZP | Motueka Airport",
        "TEU": "TEU | Manapouri Airport",
        "MRO": "MRO | Hood Airport",
        "NPL": "NPL | New Plymouth Airport",
        "NPE": "NPE | Hawke's Bay Airport",
        "NSN": "NSN | Nelson Airport",
        "IVC": "IVC | Invercargill Airport",
        "OHA": "OHA | RNZAF Base Ohakea",
        "OAM": "OAM | Oamaru Airport",
        "PMR": "PMR | Palmerston North Airport",
        "PCN": "PCN | Picton Aerodrome",
        "PPQ": "PPQ | Paraparaumu Airport",
        "ZQN": "ZQN | Queenstown International Airport",
        "RAG": "RAG | Raglan Aerodrome",
        "SZS": "SZS | Ryan's Creek Aerodrome",
        "ROT": "ROT | Rotorua Regional Airport",
        "TRG": "TRG | Tauranga Airport",
        "TMZ": "TMZ | Thames Aerodrome",
        "KTF": "KTF | Takaka Airport",
        "TKZ": "TKZ | Tokoroa Airfield",
        "THH": "THH | Taharoa Aerodrome",
        "TIU": "TIU | Timaru Airport",
        "TWZ": "TWZ | Pukaki Airport",
        "BHE": "BHE | Woodbourne Airport",
        "WKA": "WKA | Wanaka Airport",
        "WHK": "WHK | Whakatane Airport",
        "WLG": "WLG | Wellington International Airport",
        "WIR": "WIR | Wairoa Airport",
        "WRE": "WRE | Whangarei Airport",
        "WSZ": "WSZ | Westport Airport",
        "WTZ": "WTZ | Whitianga Airport",
        "WAG": "WAG | Wanganui Airport",
        "NLN": "NLN | Kneeland Airport",
        "BZF": "BZF | Benton Field",
        "OAA": "OAA | Shank Air Base",
        "BIN": "BIN | Bamyan Airport",
        "BST": "BST | Bost Airport",
        "CCN": "CCN | Chaghcharan Airport",
        "SBF": "SBF | Sardeh Band Airport",
        "DAZ": "DAZ | Darwaz Airport",
        "FAH": "FAH | Farah Airport",
        "FBD": "FBD | Fayzabad Airport",
        "GRG": "GRG | Gardez Airport",
        "KWH": "KWH | Khwahan Airport",
        "HEA": "HEA | Herat - Khwaja Abdullah Ansari International Airport",
        "OAI": "OAI | Bagram Airfield",
        "JAA": "JAA | Jalalabad Airport",
        "KBL": "KBL | Kabul International Airport",
        "KDH": "KDH | Ahmad Shah Baba International Airport / Kandahar Airfield",
        "MMZ": "MMZ | Maymana Zahiraddin Faryabi Airport",
        "MZR": "MZR | Mazar-i-Sharif International Airport",
        "VCC": "VCC | Nili Airport",
        "URN": "URN | Urgun Airport",
        "LQN": "LQN | Qala-i-Naw Airport",
        "OAS": "OAS | Sharana Airstrip",
        "OAH": "OAH | Shindand Air Base",
        "SGA": "SGA | Sheghnan Airport",
        "TII": "TII | Tarinkot Airport",
        "TQN": "TQN | Taleqan Airport",
        "UND": "UND | Kunduz Airport",
        "OAZ": "OAZ | Camp Shorabak Airfield",
        "ZAJ": "ZAJ | Zaranj Airport",
        "BAH": "BAH | Bahrain International Airport",
        "OCS": "OCS | Corisco International Airport",
        "AHB": "AHB | Abha International Airport",
        "HOF": "HOF | Al-Ahsa International Airport",
        "ABT": "ABT | King Saud Bin Abdulaziz (Al Baha) Airport",
        "BHH": "BHH | Bisha Airport",
        "DMM": "DMM | King Fahd International Airport",
        "DWD": "DWD | King Salman Abdulaziz Airport",
        "DHA": "DHA | King Abdulaziz Air Base",
        "GIZ": "GIZ | Jizan Regional Airport / King Abdullah bin Abdulaziz Airport",
        "ELQ": "ELQ | Gassim Airport",
        "URY": "URY | Gurayat Domestic Airport",
        "HAS": "HAS | Ha'il Airport",
        "QJB": "QJB | Jubail Airport",
        "JED": "JED | King Abdulaziz International Airport",
        "KMC": "KMC | King Khaled Military City Airport",
        "KMX": "KMX | King Khalid Air Base",
        "MED": "MED | Prince Mohammad Bin Abdulaziz Airport",
        "EAM": "EAM | Najran Domestic Airport",
        "NUM": "NUM | Neom Bay Airport",
        "AQI": "AQI | Al Qaisumah/Hafr Al Batin Airport",
        "AKH": "AKH | Prince Sultan Air Base",
        "RAH": "RAH | Rafha Domestic Airport",
        "RUH": "RUH | King Khaled International Airport",
        "RAE": "RAE | Arar Domestic Airport",
        "XXN": "XXN | Riyadh Air Base",
        "SHW": "SHW | Sharurah Domestic Airport",
        "AJF": "AJF | Al-Jawf Domestic Airport",
        "SLF": "SLF | As-Sulayyil Airport",
        "TUU": "TUU | Tabuk Airport",
        "TIF": "TIF | Ta’if Regional Airport",
        "TUI": "TUI | Turaif Domestic Airport",
        "WAE": "WAE | Wadi Al Dawasir Domestic Airport",
        "EJH": "EJH | Al Wajh Domestic Airport",
        "YNB": "YNB | Yanbu Airport / Prince Abdul Mohsin bin Abdulaziz international Airport",
        "ZUL": "ZUL | Zilfi Airport",
        "OGE": "OGE | Ogeranang Airport",
        "OGM": "OGM | Ogubsucum Airport",
        "IAQ": "IAQ | Bahregan Airport",
        "ABD": "ABD | Abadan Airport",
        "DEF": "DEF | Dezful Airport",
        "AKW": "AKW | Aghajari Airport",
        "GCH": "GCH | Gachsaran Airport",
        "QMJ": "QMJ | Shahid Asiyaee Airport",
        "OMI": "OMI | Omidiyeh Airport",
        "MRX": "MRX | Mahshahr Airport",
        "AWZ": "AWZ | Lieutenant General Qasem Soleimani International Airport",
        "AEU": "AEU | Abu Musa Island Airport",
        "BUZ": "BUZ | Bushehr Airport",
        "KNR": "KNR | Jam Airport",
        "KIH": "KIH | Kish International Airport",
        "BDH": "BDH | Bandar Lengeh Airport",
        "PGU": "PGU | Persian Gulf International Airport",
        "KHK": "KHK | Khark Island Airport",
        "SXI": "SXI | Sirri Island Airport",
        "LVP": "LVP | Lavan Island Airport",
        "KSH": "KSH | Shahid Ashrafi Esfahani Airport",
        "IIL": "IIL | Ilam Airport",
        "KHD": "KHD | Khoram Abad Airport",
        "SDG": "SDG | Sanandaj Airport",
        "IFH": "IFH | Hesa Airport",
        "KKS": "KKS | Kashan Airport",
        "IFN": "IFN | Esfahan Shahid Beheshti International Airport",
        "CQD": "CQD | Shahrekord Airport",
        "RAS": "RAS | Sardar-e-Jangal Airport",
        "HDM": "HDM | Hamadan Airport",
        "AJK": "AJK | Arak Airport",
        "IKA": "IKA | Imam Khomeini International Airport",
        "THR": "THR | Mehrabad International Airport",
        "GZW": "GZW | Qazvin Airport",
        "PYK": "PYK | Payam International Airport",
        "SNX": "SNX | Semnan Municipal Airport",
        "BND": "BND | Bandar Abbas International Airport",
        "JYR": "JYR | Jiroft Airport",
        "KER": "KER | Kerman Airport",
        "BXR": "BXR | Bam Airport",
        "HDR": "HDR | Havadarya Airport",
        "GSM": "GSM | Qeshm International Airport",
        "RJN": "RJN | Rafsanjan Airport",
        "SYJ": "SYJ | Sirjan Airport",
        "XBJ": "XBJ | Birjand International Airport",
        "CKT": "CKT | Sarakhs Airport",
        "RUD": "RUD | Shahroud Airport",
        "MHD": "MHD | Mashhad International Airport",
        "BJB": "BJB | Bojnord Airport",
        "AFZ": "AFZ | Sabzevar National Airport",
        "TCX": "TCX | Tabas Airport",
        "KLM": "KLM | Kalaleh Airport",
        "GBT": "GBT | Gorgan Airport",
        "BSM": "BSM | Bishe Kola Air Base",
        "NSH": "NSH | Noshahr Airport",
        "RZR": "RZR | Ramsar Airport",
        "SRY": "SRY | Sari Dasht-e Naz International Airport",
        "FAZ": "FAZ | Fasa Airport",
        "JAR": "JAR | Jahrom Airport",
        "LRR": "LRR | Lar Airport",
        "LFM": "LFM | Lamerd Airport",
        "SYZ": "SYZ | Shiraz Shahid Dastghaib International Airport",
        "YES": "YES | Yasouj Airport",
        "KHY": "KHY | Khoy Airport",
        "ADU": "ADU | Ardabil Airport",
        "ACP": "ACP | Sahand Airport",
        "PFQ": "PFQ | Parsabad-Moghan Airport",
        "OMH": "OMH | Urmia Airport",
        "TBZ": "TBZ | Tabriz International Airport",
        "IMQ": "IMQ | Maku National Airport",
        "JWN": "JWN | Zanjan Airport",
        "AZD": "AZD | Shahid Sadooghi Airport",
        "ACZ": "ACZ | Zabol Airport",
        "ZBR": "ZBR | Konarak Airport",
        "ZAH": "ZAH | Zahedan International Airport",
        "IHR": "IHR | Iranshahr Airport",
        "JSK": "JSK | Jask Airport",
        "AMM": "AMM | Queen Alia International Airport",
        "ADJ": "ADJ | Amman Civil (Marka International) Airport",
        "AQJ": "AQJ | Aqaba King Hussein International Airport",
        "OMF": "OMF | King Hussein Air College",
        "XIJ": "XIJ | Ahmed Al Jaber Air Base",
        "KWI": "KWI | Kuwait International Airport",
        "OKV": "OKV | Okao Airport",
        "BEY": "BEY | Beirut Rafic Hariri International Airport",
        "OLH": "OLH | Old Harbor Airport",
        "KYE": "KYE | Rene Mouawad Air Base / Kleyate Airport",
        "OLQ": "OLQ | Olsobip Airport",
        "BYB": "BYB | Dibba Airport",
        "AOM": "AOM | Adam Airport",
        "DQM": "DQM | Duqm International Airport",
        "MNH": "MNH | Mussanah Airport",
        "AUH": "AUH | Abu Dhabi International Airport",
        "AZI": "AZI | Al Bateen Executive Airport",
        "AAN": "AAN | Al Ain International Airport",
        "DHF": "DHF | Al Dhafra Air Base",
        "XSB": "XSB | Sir Bani Yas Airport",
        "DXB": "DXB | Dubai International Airport",
        "NHD": "NHD | Al Minhad Air Base",
        "DWC": "DWC | Al Maktoum International Airport",
        "FJR": "FJR | Fujairah International Airport",
        "OMN": "OMN | Osmanabad Airport",
        "RKT": "RKT | Ras Al Khaimah International Airport",
        "SHJ": "SHJ | Sharjah International Airport",
        "OMY": "OMY | Preah Vinhear Airport",
        "ONB": "ONB | Ononge Airport",
        "RMB": "RMB | Buraimi Airport",
        "FAU": "FAU | Fahud Airport",
        "RNM": "RNM | Qarn Alam Airport",
        "JNJ": "JNJ | Ja'Aluni Airport",
        "KHS": "KHS | Khasab Air Base",
        "LKW": "LKW | Lekhwair Airport",
        "MSH": "MSH | Masirah Air Base",
        "MCT": "MCT | Muscat International Airport",
        "OMM": "OMM | Marmul Airport",
        "SLL": "SLL | Salalah Airport",
        "OHS": "OHS | Sohar Airport",
        "TTH": "TTH | Thumrait Air Base",
        "BHW": "BHW | Bhagatanwala Airport",
        "BNP": "BNP | Bannu Airport",
        "WGB": "WGB | Bahawalnagar Airport",
        "BHV": "BHV | Bahawalpur Airport",
        "CJL": "CJL | Chitral Airport",
        "CHB": "CHB | Chilas Airport",
        "DBA": "DBA | Dalbandin Airport",
        "DDU": "DDU | Dadu Airport",
        "DEA": "DEA | Dera Ghazi Khan Airport",
        "DSK": "DSK | Dera Ismael Khan Airport",
        "LYP": "LYP | Faisalabad International Airport",
        "GWD": "GWD | Gwadar International Airport",
        "GIL": "GIL | Gilgit Airport",
        "ISB": "ISB | Islamabad International Airport",
        "JAG": "JAG | Shahbaz Air Base",
        "JIW": "JIW | Jiwani Airport",
        "KHI": "KHI | Jinnah International Airport",
        "HDD": "HDD | Hyderabad Airport",
        "KDD": "KDD | Khuzdar Airport",
        "OHT": "OHT | Kohat Airport",
        "LHE": "LHE | Alama Iqbal International Airport",
        "LRG": "LRG | Loralai Airport",
        "XJM": "XJM | Mangla Airport",
        "MFG": "MFG | Muzaffarabad Airport",
        "MWD": "MWD | Mianwali Air Base",
        "MJD": "MJD | Moenjodaro Airport",
        "MPD": "MPD | Sindhri Tharparkar Airport",
        "ATG": "ATG | Minhas Air Base",
        "MUX": "MUX | Multan International Airport",
        "WNS": "WNS | Shaheed Benazirabad Airport",
        "ORW": "ORW | Ormara Airport",
        "PAJ": "PAJ | Parachinar Airport",
        "PJG": "PJG | Panjgur Airport",
        "PSI": "PSI | Pasni Airport",
        "PEW": "PEW | Peshawar International Airport",
        "UET": "UET | Quetta International Airport",
        "RYK": "RYK | Shaikh Zaid Airport",
        "RAZ": "RAZ | Rawalakot Airport",
        "SBQ": "SBQ | Sibi Airport",
        "KDU": "KDU | Skardu Airport",
        "SKZ": "SKZ | Sukkur Airport",
        "SYW": "SYW | Sehwan Sharif Airport",
        "SGI": "SGI | Mushaf Air Base",
        "SDT": "SDT | Saidu Sharif Airport",
        "SKT": "SKT | Sialkot International Airport",
        "SUL": "SUL | Sui Airport",
        "SWN": "SWN | Sahiwal Airport",
        "TLB": "TLB | Tarbela Dam Airport",
        "BDN": "BDN | Talhar Airport",
        "TFT": "TFT | Taftan Airport",
        "TUK": "TUK | Turbat International Airport",
        "WAF": "WAF | Wana Airport",
        "PZH": "PZH | Zhob Airport",
        "IQA": "IQA | Al Asad Air Base",
        "TQD": "TQD | Al Taqaddum Air Base",
        "BMN": "BMN | Bamarni Airport",
        "BGW": "BGW | Baghdad International Airport / New Al Muthana Air Base",
        "OSM": "OSM | Mosul International Airport",
        "EBL": "EBL | Erbil International Airport",
        "ORI": "ORI | Port Lions Airport",
        "KIK": "KIK | Kirkuk Air Base",
        "BSR": "BSR | Basra International Airport",
        "NJF": "NJF | Al Najaf International Airport",
        "RQW": "RQW | Qayyarah West Airport",
        "ISU": "ISU | Sulaymaniyah International Airport",
        "XNH": "XNH | Ali Air Base",
        "ALP": "ALP | Aleppo International Airport",
        "DAM": "DAM | Damascus International Airport",
        "DEZ": "DEZ | Deir ez-Zor Airport",
        "OSE": "OSE | Omora Airport",
        "OSG": "OSG | Ossima Airport",
        "KAC": "KAC | Qamishli Airport",
        "LTK": "LTK | Bassel Al-Assad International Airport",
        "PMS": "PMS | Palmyra Airport",
        "DIA": "DIA | Doha International Airport",
        "XJD": "XJD | Al Udeid Air Base",
        "DOH": "DOH | Hamad International Airport",
        "OTT": "OTT | Andre Maggi Airport",
        "OTY": "OTY | Oria Airport",
        "OUM": "OUM | Oum Hadjer Airport",
        "OXO": "OXO | Orientos Airport",
        "ADE": "ADE | Aden International Airport",
        "EAB": "EAB | Abs Airport",
        "AXK": "AXK | Ataq Airport",
        "BYD": "BYD | Al-Bayda Airport",
        "BHN": "BHN | Beihan Airport",
        "BUK": "BUK | Al-Bough Airport",
        "AAY": "AAY | Al Ghaydah International Airport",
        "HOD": "HOD | Hodeidah International Airport",
        "KAM": "KAM | Kamaran Airport",
        "MYN": "MYN | Mareb Airport",
        "UKR": "UKR | Mukeiras Airport",
        "IHN": "IHN | Qishn Airport",
        "RIY": "RIY | Riyan Mukalla International Airport",
        "SYE": "SYE | Sadah Airport",
        "SAH": "SAH | Sana'a International Airport",
        "SCT": "SCT | Socotra International Airport",
        "GXF": "GXF | Seiyun Hadhramaut International Airport",
        "TAI": "TAI | Ta'izz International Airport",
        "ACU": "ACU | Achutupu Airport",
        "AIL": "AIL | Alligandi Airport",
        "CTE": "CTE | Carti Airport",
        "MPP": "MPP | Mulatupo Airport",
        "PYC": "PYC | Playón Chico Airport",
        "NMG": "NMG | San Miguel Airport",
        "PYV": "PYV | Yaviza Airport",
        "BFQ": "BFQ | Bahia Piña Airport",
        "ELE": "ELE | EL Real Airport",
        "OTD": "OTD | Raul Arias Espinoza Airport",
        "SAX": "SAX | Sambú Airport",
        "AKB": "AKB | Atka Airport",
        "PML": "PML | Port Moller Airport",
        "PTD": "PTD | Port Alexander Seaplane Base",
        "PAQ": "PAQ | Warren Bud Woods Palmer Municipal Airport",
        "BTI": "BTI | Barter Island Long Range Radar Station Airport",
        "BET": "BET | Bethel Airport",
        "BVU": "BVU | Beluga Airport",
        "BIG": "BIG | Allen Army Airfield",
        "BKC": "BKC | Buckland Airport",
        "BMX": "BMX | Big Mountain Airport",
        "BRW": "BRW | Wiley Post Will Rogers Memorial Airport",
        "BTT": "BTT | Bettles Airport",
        "CDB": "CDB | Cold Bay Airport",
        "CEM": "CEM | Central Airport",
        "CIK": "CIK | Chalkyitsik Airport",
        "CYF": "CYF | Chefornak Airport",
        "SCM": "SCM | Scammon Bay Airport",
        "IRC": "IRC | Circle City (New) Airport",
        "CDV": "CDV | Merle K (Mudhole) Smith Airport",
        "CXF": "CXF | Coldfoot Airport",
        "CYT": "CYT | Yakataga Airport",
        "CZF": "CZF | Cape Romanzof LRRS Airport",
        "DRG": "DRG | Deering Airport",
        "RDB": "RDB | Red Dog Airport",
        "ADK": "ADK | Adak Airport",
        "DLG": "DLG | Dillingham Airport",
        "MLL": "MLL | Marshall Don Hunter Sr Airport",
        "ADQ": "ADQ | Kodiak Airport",
        "DUT": "DUT | Tom Madsen (Dutch Harbor) Airport",
        "KKH": "KKH | Kongiganak Airport",
        "EDF": "EDF | Elmendorf Air Force Base",
        "EEK": "EEK | Eek Airport",
        "EAA": "EAA | Eagle Airport",
        "EHM": "EHM | Cape Newenham LRRS Airport",
        "EIL": "EIL | Eielson Air Force Base",
        "ELV": "ELV | Elfin Cove Seaplane Base",
        "EMK": "EMK | Emmonak Airport",
        "ENA": "ENA | Kenai Municipal Airport",
        "WWT": "WWT | Newtok Airport",
        "FAI": "FAI | Fairbanks International Airport",
        "FBK": "FBK | Ladd AAF Airfield",
        "ABL": "ABL | Ambler Airport",
        "NIB": "NIB | Nikolai Airport",
        "FWL": "FWL | Farewell Airport",
        "GAL": "GAL | Edward G. Pitka Sr Airport",
        "GBH": "GBH | Galbraith Lake Airport",
        "KWK": "KWK | Kwigillingok Airport",
        "SHG": "SHG | Shungnak Airport",
        "GKN": "GKN | Gulkana Airport",
        "GLV": "GLV | Golovin Airport",
        "GAM": "GAM | Gambell Airport",
        "AGN": "AGN | Angoon Seaplane Base",
        "BGQ": "BGQ | Big Lake Airport",
        "GST": "GST | Gustavus Airport",
        "NME": "NME | Nightmute Airport",
        "SGY": "SGY | Skagway Airport",
        "HCR": "HCR | Holy Cross Airport",
        "HSL": "HSL | Huslia Airport",
        "HNS": "HNS | Haines Airport",
        "HOM": "HOM | Homer Airport",
        "HPB": "HPB | Hooper Bay Airport",
        "HUS": "HUS | Hughes Airport",
        "SHX": "SHX | Shageluk Airport",
        "HYG": "HYG | Hydaburg Seaplane Base",
        "IGG": "IGG | Igiugig Airport",
        "EGX": "EGX | Egegik Airport",
        "IAN": "IAN | Bob Baker Memorial Airport",
        "ILI": "ILI | Iliamna Airport",
        "UTO": "UTO | Indian Mountain LRRS Airport",
        "MCL": "MCL | McKinley National Park Airport",
        "WAA": "WAA | Wales Airport",
        "JNU": "JNU | Juneau International Airport",
        "KGK": "KGK | Koliganek Airport",
        "KDK": "KDK | Kodiak Municipal Airport",
        "KFP": "KFP | False Pass Airport",
        "AKK": "AKK | Akhiok Airport",
        "KPN": "KPN | Kipnuk Airport",
        "KKA": "KKA | Koyuk Alfred Adams Airport",
        "LKK": "LKK | Kulik Lake Airport",
        "AKN": "AKN | King Salmon Airport",
        "IKO": "IKO | Nikolski Air Station",
        "AKP": "AKP | Anaktuvuk Pass Airport",
        "KTN": "KTN | Ketchikan International Airport",
        "UUK": "UUK | Ugnu-Kuparuk Airport",
        "KAL": "KAL | Kaltag Airport",
        "KLW": "KLW | Klawock Airport",
        "KYK": "KYK | Karluk Airport",
        "KLN": "KLN | Larsen Bay Airport",
        "KLG": "KLG | Kalskag Airport",
        "DQH": "DQH | Alpine Airstrip",
        "WCR": "WCR | Chandalar Lake Airport",
        "LUR": "LUR | Cape Lisburne LRRS Airport",
        "KMO": "KMO | Manokotak Airport",
        "MCG": "MCG | McGrath Airport",
        "MDO": "MDO | Middleton Island Airport",
        "LMA": "LMA | Minchumina Airport",
        "SMK": "SMK | St Michael Airport",
        "MLY": "MLY | Manley Hot Springs Airport",
        "MTM": "MTM | Metlakatla Seaplane Base",
        "MOU": "MOU | Mountain Village Airport",
        "MRI": "MRI | Merrill Field",
        "MXY": "MXY | Mc Carthy Airport",
        "MYU": "MYU | Mekoryuk Airport",
        "WNA": "WNA | Napakiak Airport",
        "ANC": "ANC | Ted Stevens Anchorage International Airport",
        "ANI": "ANI | Aniak Airport",
        "ENN": "ENN | Nenana Municipal Airport",
        "NNL": "NNL | Nondalton Airport",
        "FNR": "FNR | Funter Bay Seaplane Base",
        "ANN": "ANN | Annette Island Airport",
        "NUL": "NUL | Nulato Airport",
        "ANV": "ANV | Anvik Airport",
        "KNW": "KNW | New Stuyahok Airport",
        "OBU": "OBU | Kobuk Airport",
        "PCA": "PCA | Portage Creek Airport",
        "HNH": "HNH | Hoonah Airport",
        "OME": "OME | Nome Airport",
        "OOK": "OOK | Toksook Bay Airport",
        "ORT": "ORT | Northway Airport",
        "OTZ": "OTZ | Ralph Wien Memorial Airport",
        "NLG": "NLG | Nelson Lagoon Airport",
        "STG": "STG | St George Airport",
        "KPC": "KPC | Port Clarence Coast Guard Station",
        "KPV": "KPV | Perryville Airport",
        "PSG": "PSG | Petersburg James A Johnson Airport",
        "PTH": "PTH | Port Heiden Airport",
        "PKA": "PKA | Napaskiak Airport",
        "PTU": "PTU | Platinum Airport",
        "PIP": "PIP | Pilot Point Airport",
        "PHO": "PHO | Point Hope Airport",
        "PPC": "PPC | Prospect Creek Airport",
        "KWN": "KWN | Quinhagak Airport",
        "NUI": "NUI | Nuiqsut Airport",
        "ARC": "ARC | Arctic Village Airport",
        "RSH": "RSH | Russian Mission Airport",
        "RBY": "RBY | Ruby Airport",
        "SVA": "SVA | Savoonga Airport",
        "SCC": "SCC | Deadhorse Airport",
        "SDP": "SDP | Sand Point Airport",
        "SHH": "SHH | Shishmaref Airport",
        "SIT": "SIT | Sitka Rocky Gutierrez Airport",
        "WLK": "WLK | Selawik Airport",
        "SLQ": "SLQ | Sleetmute Airport",
        "KSM": "KSM | St Mary's Airport",
        "SNP": "SNP | St Paul Island Airport",
        "SOV": "SOV | Seldovia Airport",
        "SMU": "SMU | Sheep Mountain Airport",
        "UMM": "UMM | Summit Airport",
        "SVW": "SVW | Sparrevohn LRRS Airport",
        "SKW": "SKW | Skwentna Airport",
        "SXQ": "SXQ | Soldotna Airport",
        "SYA": "SYA | Eareckson Air Station",
        "TAL": "TAL | Ralph M Calhoun Memorial Airport",
        "TNC": "TNC | Tin City Long Range Radar Station Airport",
        "TLA": "TLA | Teller Airport",
        "TOG": "TOG | Togiak Airport",
        "TKA": "TKA | Talkeetna Airport",
        "TLJ": "TLJ | Tatalina LRRS Airport",
        "ATK": "ATK | Atqasuk Edward Burnell Sr Memorial Airport",
        "AUK": "AUK | Alakanuk Airport",
        "UMT": "UMT | Umiat Airport",
        "UNK": "UNK | Unalakleet Airport",
        "WOW": "WOW | Willow Airport",
        "KQA": "KQA | Akutan Airport",
        "VAK": "VAK | Chevak Airport",
        "KVC": "KVC | King Cove Airport",
        "VDZ": "VDZ | Valdez Pioneer Field",
        "VEE": "VEE | Venetie Airport",
        "KVL": "KVL | Kivalina Airport",
        "WBQ": "WBQ | Beaver Airport",
        "SWD": "SWD | Seward Airport",
        "WRG": "WRG | Wrangell Airport",
        "AIN": "AIN | Wainwright Airport",
        "WMO": "WMO | White Mountain Airport",
        "WTK": "WTK | Noatak Airport",
        "WWA": "WWA | Wasilla Airport",
        "YAK": "YAK | Yakutat Airport",
        "CIS": "CIS | Canton Island Airport",
        "PCO": "PCO | Punta Colorada Airport",
        "PCQ": "PCQ | Boun Neau Airport",
        "PDI": "PDI | Pindiu Airport",
        "PDR": "PDR | Presidente José Sarney Airport",
        "PEB": "PEB | Pebane Airport",
        "PEC": "PEC | Pelican Seaplane Base",
        "AKI": "AKI | Akiak Airport",
        "AET": "AET | Allakaket Airport",
        "PFC": "PFC | Pacific City State Airport",
        "NCN": "NCN | Chenega Bay Airport",
        "CLP": "CLP | Clarks Point Airport",
        "ELI": "ELI | Elim Airport",
        "KUK": "KUK | Kasigluk Airport",
        "KNK": "KNK | Kokhanok Airport",
        "KOT": "KOT | Kotlik Airport",
        "KTS": "KTS | Brevig Mission Airport",
        "KYU": "KYU | Koyukuk Airport",
        "KWT": "KWT | Kwethluk Airport",
        "ORV": "ORV | Robert (Bob) Curtis Memorial Airport",
        "SKK": "SKK | Shaktoolik Airport",
        "TKJ": "TKJ | Tok Junction Airport",
        "WSN": "WSN | South Naknek Number 2 Airport",
        "FYU": "FYU | Fort Yukon Airport",
        "CPN": "CPN | Cape Rodney Airport",
        "EMI": "EMI | Emirau Airport",
        "ERE": "ERE | Erave Airport",
        "ESA": "ESA | Esa'ala Airport",
        "GAR": "GAR | Garaina Airport",
        "GOE": "GOE | Gonaili Airport",
        "BPD": "BPD | Bapi Airstrip",
        "BPK": "BPK | Biangabip Airport",
        "NWT": "NWT | Nowata Airport",
        "SGK": "SGK | Sengapi Airstrip",
        "KII": "KII | Kibuli Airstrip",
        "AKG": "AKG | Anguganak Airport",
        "TAJ": "TAJ | Tadji Airport",
        "AWB": "AWB | Awaba Airport",
        "BAA": "BAA | Bialla Airport",
        "CVB": "CVB | Chungribu Airport",
        "GMI": "GMI | Gasmata Island Airport",
        "GVI": "GVI | Green River Airport",
        "HYF": "HYF | Hayfields Airport",
        "IHU": "IHU | Ihu Airport",
        "IIS": "IIS | Nissan Island Airport",
        "JAQ": "JAQ | Jacquinot Bay Airport",
        "KDR": "KDR | Kandrian Airport",
        "KKD": "KKD | Kokoda Airport",
        "KUY": "KUY | Kamusi Airport",
        "KWO": "KWO | Kawito Airport",
        "LMI": "LMI | Lumi Airport",
        "LMY": "LMY | Lake Murray Airport",
        "OBX": "OBX | Obo Airport",
        "OPU": "OPU | Balimo Airport",
        "SKC": "SKC | Suki Airport",
        "TFI": "TFI | Tufi Airport",
        "TFM": "TFM | Telefomin Airport",
        "TLO": "TLO | Tol Airport",
        "UKU": "UKU | Nuku Airport",
        "ULE": "ULE | Sule Airport",
        "VMU": "VMU | Baimuru Airport",
        "WPM": "WPM | Wipim Airport",
        "PGE": "PGE | Yegepa Airport",
        "PGM": "PGM | Port Graham Airport",
        "ROP": "ROP | Rota International Airport",
        "SPN": "SPN | Saipan International Airport",
        "UAM": "UAM | Andersen Air Force Base",
        "GUM": "GUM | Antonio B. Won Pat International Airport",
        "TIQ": "TIQ | Tinian International Airport",
        "CGY": "CGY | Laguindingan Airport",
        "LLC": "LLC | Cagayan North International Airport",
        "ENI": "ENI | El Nido Airport",
        "BKH": "BKH | Barking Sands Airport",
        "HDH": "HDH | Dillingham Airfield",
        "HHI": "HHI | Wheeler Army Airfield",
        "HNM": "HNM | Hana Airport",
        "HIK": "HIK | Hickam Air Force Base",
        "JHM": "JHM | Kapalua Airport",
        "JRF": "JRF | Kalaeloa Airport",
        "KOA": "KOA | Ellison Onizuka Kona International Airport at Keahole",
        "LIH": "LIH | Lihue Airport",
        "LUP": "LUP | Kalaupapa Airport",
        "MKK": "MKK | Molokai Airport",
        "MUE": "MUE | Waimea Kohala Airport",
        "NGF": "NGF | Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
        "HNL": "HNL | Daniel K Inouye International Airport",
        "LNY": "LNY | Lanai Airport",
        "OGG": "OGG | Kahului Airport",
        "PAK": "PAK | Port Allen Airport",
        "BSF": "BSF | Bradshaw Army Airfield",
        "ITO": "ITO | Hilo International Airport",
        "UPP": "UPP | Upolu Airport",
        "BHC": "BHC | Bhurban Heliport",
        "CWP": "CWP | Campbellpore Airport",
        "GRT": "GRT | Gujrat Airport",
        "KCF": "KCF | Kadanwari Airport",
        "REQ": "REQ | Reko Diq Airport",
        "RZS": "RZS | Sawan Airport",
        "ENT": "ENT | Eniwetok Airport",
        "MAJ": "MAJ | Marshall Islands International Airport",
        "KAI": "KAI | Kaieteur International Airport",
        "KWA": "KWA | Bucholz Army Air Field",
        "CXI": "CXI | Cassidy International Airport",
        "PLE": "PLE | Paiela Airport",
        "TNV": "TNV | Tabuaeran Island Airport",
        "TNQ": "TNQ | Washington Island Airstrip",
        "MDY": "MDY | Henderson Field",
        "PMM": "PMM | Phanom Sarakham Airport",
        "PMP": "PMP | Pimaga Airport",
        "PIZ": "PIZ | Point Lay LRRS Airport",
        "PPX": "PPX | Param Airport",
        "HUC": "HUC | Humacao Airport",
        "XSO": "XSO | Siocon Airport",
        "TKK": "TKK | Chuuk International Airport",
        "PNI": "PNI | Pohnpei International Airport",
        "ROR": "ROR | Babelthuap Airport",
        "KSA": "KSA | Kosrae International Airport",
        "YAP": "YAP | Yap International Airport",
        "PUA": "PUA | Puas Airport",
        "AWK": "AWK | Wake Island Airfield",
        "PWR": "PWR | Port Walter Seaplane Base",
        "BFA": "BFA | Bahía Negra Airport",
        "OLK": "OLK | Fuerte Olimpo Airport",
        "PBT": "PBT | Puerto Leda Airport",
        "PCJ": "PCJ | Puerto La Victoria Airport",
        "PYL": "PYL | Perry Island Seaplane Base",
        "KIO": "KIO | Kili Airport",
        "QJP": "QJP | Pocheon (G-217) Airport",
        "RAA": "RAA | Rakanda Airport",
        "RAW": "RAW | Arawa Airport",
        "RAX": "RAX | Oram Airport",
        "RBP": "RBP | Raba Raba Airport",
        "KNH": "KNH | Kinmen Airport",
        "LZN": "LZN | Matsu Nangan Airport",
        "TTT": "TTT | Taitung Airport",
        "GNI": "GNI | Lyudao Airport",
        "KHH": "KHH | Kaohsiung International Airport",
        "CYI": "CYI | Chiayi Airport",
        "HCN": "HCN | Hengchun Airport",
        "KYD": "KYD | Lanyu Airport",
        "RMQ": "RMQ | Taichung International Airport / Ching Chuang Kang Air Base",
        "MFK": "MFK | Matsu Beigan Airport",
        "TNN": "TNN | Tainan International Airport / Tainan Air Base",
        "HSZ": "HSZ | Hsinchu Air Base",
        "MZG": "MZG | Penghu Magong Airport",
        "PIF": "PIF | Pingtung North Airport",
        "TSA": "TSA | Taipei Songshan Airport",
        "TPE": "TPE | Taiwan Taoyuan International Airport",
        "WOT": "WOT | Wang'an Airport",
        "HUN": "HUN | Hualien Airport",
        "RDV": "RDV | Red Devil Airport",
        "RHR": "RHR | Ras al Khaimah Al Hamra Seaplane Base",
        "RHT": "RHT | Alxa Right Banner Badanjilin Airport",
        "NRT": "NRT | Narita International Airport",
        "MMJ": "MMJ | Shinshu-Matsumoto Airport",
        "IBR": "IBR | Ibaraki Airport / JASDF Hyakuri Air Base",
        "MUS": "MUS | JMSDF Minami Torishima Air Base",
        "IWO": "IWO | Ioto (Iwo Jima) Air Base",
        "KIX": "KIX | Kansai International Airport",
        "SHM": "SHM | Nanki Shirahama Airport",
        "UKB": "UKB | Kobe Airport",
        "TJH": "TJH | Konotori Tajima Airport",
        "OBO": "OBO | Tokachi-Obihiro Airport",
        "CTS": "CTS | New Chitose Airport",
        "HKD": "HKD | Hakodate Airport",
        "KUH": "KUH | Kushiro Airport",
        "MMB": "MMB | Memanbetsu Airport",
        "SHB": "SHB | Nakashibetsu Airport",
        "OKD": "OKD | Sapporo Okadama Airport",
        "RBJ": "RBJ | Rebun Airport",
        "WKJ": "WKJ | Wakkanai Airport",
        "AXJ": "AXJ | Amakusa Airport",
        "IKI": "IKI | Iki Airport",
        "UBJ": "UBJ | Yamaguchi Ube Airport",
        "TSJ": "TSJ | Tsushima Airport",
        "OMJ": "OMJ | JMSDF Omura Air Base",
        "MBE": "MBE | Monbetsu Airport",
        "AKJ": "AKJ | Asahikawa Airport",
        "OIR": "OIR | Okushiri Airport",
        "RIS": "RIS | Rishiri Airport",
        "KUM": "KUM | Yakushima Airport",
        "FUJ": "FUJ | Fukue Airport",
        "FUK": "FUK | Fukuoka Airport",
        "TNE": "TNE | New Tanegashima Airport",
        "KOJ": "KOJ | Kagoshima Airport",
        "KMI": "KMI | Miyazaki Airport",
        "OIT": "OIT | Oita Airport",
        "KKJ": "KKJ | Kitakyushu Airport",
        "HSG": "HSG | Saga Airport",
        "KMJ": "KMJ | Kumamoto Airport",
        "NGS": "NGS | Nagasaki Airport",
        "NGO": "NGO | Chubu Centrair International Airport",
        "ASJ": "ASJ | Amami Airport",
        "OKE": "OKE | Okinoerabu Airport",
        "KKX": "KKX | Kikai Airport",
        "TKN": "TKN | Tokunoshima Airport",
        "NKM": "NKM | Nagoya Airport / JASDF Komaki Air Base",
        "FKJ": "FKJ | Fukui Airport",
        "QGU": "QGU | Gifu Airport",
        "KMQ": "KMQ | Komatsu Airport / JASDF Komatsu Air Base",
        "OKI": "OKI | Oki Global Geopark Airport",
        "FSZ": "FSZ | Mount Fuji Shizuoka Airport",
        "TOY": "TOY | Toyama Airport",
        "NTQ": "NTQ | Noto Satoyama Airport",
        "HIJ": "HIJ | Hiroshima Airport",
        "OKJ": "OKJ | Okayama Momotaro Airport",
        "IZO": "IZO | Izumo Enmusubi Airport",
        "YGJ": "YGJ | Yonago Kitaro Airport / JASDF Miho Air Base",
        "IWK": "IWK | Iwakuni Kintaikyo Airport / Marine Corps Air Station Iwakuni",
        "KCZ": "KCZ | Kochi Ryoma Airport",
        "MYJ": "MYJ | Matsuyama Airport",
        "ITM": "ITM | Osaka International Airport",
        "TTJ": "TTJ | Tottori Sand Dunes Conan Airport",
        "TKS": "TKS | Tokushima Awaodori Airport / JMSDF Tokushima Air Base",
        "TAK": "TAK | Takamatsu Airport",
        "IWJ": "IWJ | Iwami Airport",
        "AOJ": "AOJ | Aomori Airport",
        "GAJ": "GAJ | Yamagata Airport",
        "SDS": "SDS | Sado Airport",
        "FKS": "FKS | Fukushima Airport",
        "HHE": "HHE | JMSDF Hachinohe Air Base / Hachinohe Airport",
        "HNA": "HNA | Morioka Hanamaki Airport",
        "AXT": "AXT | Akita Airport",
        "MSJ": "MSJ | Misawa Air Base / Misawa Airport",
        "KIJ": "KIJ | Niigata Airport",
        "ONJ": "ONJ | Odate Noshiro Airport",
        "SDJ": "SDJ | Sendai Airport",
        "SYO": "SYO | Shonai Airport",
        "NJA": "NJA | JMSDF Atsugi Air Base / Naval Air Facility Atsugi",
        "HAC": "HAC | Hachijojima Airport",
        "OIM": "OIM | Oshima Airport",
        "MYE": "MYE | Miyakejima Airport",
        "HND": "HND | Tokyo Haneda International Airport",
        "OKO": "OKO | Yokota Air Base",
        "MWX": "MWX | Muan International Airport",
        "KWJ": "KWJ | Gwangju Airport",
        "KUV": "KUV | Kunsan Air Base",
        "CHN": "CHN | Jeonju (G 703) Air Base",
        "RSU": "RSU | Yeosu Airport",
        "KAG": "KAG | Gangneung Airport (K-18)",
        "WJU": "WJU | Wonju Airport / Hoengseong Air Base (K-38/K-46)",
        "YNY": "YNY | Yangyang International Airport",
        "CJU": "CJU | Jeju International Airport",
        "JDG": "JDG | Jeongseok Airport",
        "CHF": "CHF | Jinhae Airbase/Airport (G-813/K-10)",
        "PUS": "PUS | Gimhae International Airport",
        "HIN": "HIN | Sacheon Air Base/Airport",
        "USN": "USN | Ulsan Airport",
        "ICN": "ICN | Incheon International Airport",
        "SSN": "SSN | Seoul Air Base (K-16)",
        "OSN": "OSN | Osan Air Base",
        "GMP": "GMP | Gimpo International Airport",
        "SWU": "SWU | Suwon Airport",
        "QDY": "QDY | Taean Airport",
        "KPO": "KPO | Pohang Airport (G-815/K-3)",
        "TAE": "TAE | Daegu Airport",
        "HMY": "HMY | Seosan Air Base",
        "CJJ": "CJJ | Cheongju International Airport/Cheongju Air Base (K-59/G-513)",
        "YEC": "YEC | Yecheon Airbase",
        "RKU": "RKU | Kairuku Airport",
        "RKY": "RKY | Rokeby Airport",
        "RLP": "RLP | Rosella Plains Airport",
        "RMP": "RMP | Rampart Airport",
        "RMU": "RMU | Región de Murcia International Airport",
        "OKA": "OKA | Naha Airport / JASDF Naha Air Base",
        "DNA": "DNA | Kadena Air Base",
        "UEO": "UEO | Kumejima Airport",
        "KJP": "KJP | Kerama Airport",
        "MMD": "MMD | Minamidaito Airport",
        "MMY": "MMY | Miyako Airport",
        "AGJ": "AGJ | Aguni Airport",
        "IEJ": "IEJ | Iejima Airport",
        "HTR": "HTR | Hateruma Airport",
        "KTD": "KTD | Kitadaito Airport",
        "SHI": "SHI | Miyako Shimojishima Airport",
        "TRA": "TRA | Tarama Airport",
        "RNJ": "RNJ | Yoron Airport",
        "OGN": "OGN | Yonaguni Airport",
        "BSI": "BSI | Balesin Island Airport",
        "SFS": "SFS | Subic Bay International Airport / Naval Air Station Cubi Point",
        "CRK": "CRK | Diosdado Macapagal International Airport",
        "LAO": "LAO | Laoag International Airport",
        "MNL": "MNL | Ninoy Aquino International Airport",
        "CYU": "CYU | Cuyo Airport",
        "LGP": "LGP | Legazpi City International Airport",
        "SGL": "SGL | Sangley Point International Airport / Danilo Atienza Air Base",
        "LBX": "LBX | Lubang Airport",
        "AAV": "AAV | Allah Valley Airport",
        "CBO": "CBO | Awang Airport",
        "DVO": "DVO | Francisco Bangoy International Airport",
        "BXU": "BXU | Bancasi Airport",
        "BPH": "BPH | Bislig Airport",
        "DPL": "DPL | Dipolog Airport",
        "CGM": "CGM | Camiguin Airport",
        "IGN": "IGN | Maria Cristina Airport",
        "JOL": "JOL | Jolo Airport",
        "MLP": "MLP | Malabang Airport",
        "TWT": "TWT | Sanga Sanga Airport",
        "OZC": "OZC | Labo Airport",
        "PAG": "PAG | Pagadian Airport",
        "MXI": "MXI | Mati National Airport",
        "GES": "GES | General Santos International Airport",
        "SUG": "SUG | Surigao Airport",
        "CDY": "CDY | Cagayan de Sulu Airport",
        "IPE": "IPE | Ipil Airport",
        "TDG": "TDG | Tandag Airport",
        "ZAM": "ZAM | Zamboanga International Airport",
        "IAO": "IAO | Siargao Airport",
        "RZP": "RZP | Cesar Lim Rodriguez (Taytay-Sandoval) Airport",
        "TAG": "TAG | Bohol-Panglao International Airport",
        "SWL": "SWL | San Vicente Airport",
        "BAG": "BAG | Loakan Airport",
        "DTE": "DTE | Daet Airport",
        "SJI": "SJI | San Jose Airport",
        "MBO": "MBO | Mamburao Airport",
        "WNP": "WNP | Naga Airport",
        "BSO": "BSO | Basco Airport",
        "BQA": "BQA | Dr Juan C Angara Airport",
        "SFE": "SFE | San Fernando Airport",
        "TUG": "TUG | Tuguegarao Airport",
        "VRC": "VRC | Virac Airport",
        "MRQ": "MRQ | Marinduque Airport",
        "CYZ": "CYZ | Cauayan Airport",
        "RPV": "RPV | Roper Valley Airport",
        "TAC": "TAC | Daniel Z. Romualdez Airport",
        "BCD": "BCD | Bacolod-Silay Airport",
        "CYP": "CYP | Calbayog Airport",
        "DGT": "DGT | Sibulan Airport",
        "MPH": "MPH | Godofredo P. Ramos Airport",
        "CRM": "CRM | Catarman National Airport",
        "ILO": "ILO | Iloilo International Airport",
        "MBT": "MBT | Moises R. Espinosa Airport",
        "KLO": "KLO | Kalibo International Airport",
        "CEB": "CEB | Mactan Cebu International Airport",
        "OMC": "OMC | Ormoc Airport",
        "PPS": "PPS | Puerto Princesa Airport",
        "RXS": "RXS | Roxas Airport",
        "EUQ": "EUQ | Evelio Javier Airport",
        "TBH": "TBH | Tugdan Airport",
        "USU": "USU | Francisco B. Reyes Airport",
        "RRM": "RRM | Marromeu Airport",
        "RSE": "RSE | Rose Bay Seaplane Base",
        "NGK": "NGK | Nogliki Airport",
        "GRV": "GRV | Grozny North Airport",
        "KDY": "KDY | Typliy Klyuch Airport",
        "VUS": "VUS | Velikiy Ustyug Airport",
        "RUU": "RUU | Ruti Airport",
        "RVC": "RVC | River Cess Airport/Heliport",
        "LPS": "LPS | Lopez Island Airport",
        "KEH": "KEH | Kenmore Air Harbor Inc Seaplane Base",
        "MJR": "MJR | Miramar Airport",
        "COC": "COC | Comodoro Pierrestegui Airport",
        "GHU": "GHU | Gualeguaychu Airport",
        "PRA": "PRA | General Urquiza Airport",
        "ROS": "ROS | Rosario Islas Malvinas International Airport",
        "SFN": "SFN | Sauce Viejo Airport",
        "AEP": "AEP | Jorge Newbery Airpark",
        "LCM": "LCM | La Cumbre Airport",
        "COR": "COR | Ingeniero Ambrosio Taravella Airport",
        "FDO": "FDO | San Fernando Airport",
        "LPG": "LPG | La Plata Airport",
        "EPA": "EPA | El Palomar Airport",
        "EZE": "EZE | Ministro Pistarini International Airport",
        "RAF": "RAF | Rafaela Airport",
        "HOS": "HOS | Chos Malal Airport",
        "CVH": "CVH | Caviahue Airport",
        "GNR": "GNR | Dr. Arturo H. Illia Airport",
        "RDS": "RDS | Rincon De Los Sauces Airport",
        "APZ": "APZ | Zapala Airport",
        "SAM": "SAM | Salamo Airport",
        "MDZ": "MDZ | El Plumerillo Airport",
        "LGS": "LGS | Comodoro D.R. Salomón Airport",
        "AFA": "AFA | Suboficial Ay Santiago Germano Airport",
        "CTC": "CTC | Catamarca Airport",
        "SDE": "SDE | Vicecomodoro Angel D. La Paz Aragonés Airport",
        "IRJ": "IRJ | Capitan V A Almonacid Airport",
        "RHD": "RHD | Termas de Río Hondo international Airport",
        "TUC": "TUC | Teniente Benjamin Matienzo Airport",
        "UAQ": "UAQ | Domingo Faustino Sarmiento Airport",
        "CRR": "CRR | Ceres Airport",
        "RCU": "RCU | Area De Material Airport",
        "VDR": "VDR | Villa Dolores Airport",
        "VME": "VME | Villa Reynolds Airport",
        "RLO": "RLO | Valle Del Conlara International Airport",
        "LUQ": "LUQ | Brigadier Mayor D Cesar Raul Ojeda Airport",
        "CNQ": "CNQ | Corrientes Airport",
        "RES": "RES | Resistencia International Airport",
        "FMA": "FMA | Formosa Airport",
        "IGR": "IGR | Cataratas Del Iguazú International Airport",
        "AOL": "AOL | Paso De Los Libres Airport",
        "MCS": "MCS | Monte Caseros Airport",
        "PSS": "PSS | Libertador Gral D Jose De San Martin Airport",
        "SAS": "SAS | Salton Sea Airport",
        "SLA": "SLA | Martin Miguel De Guemes International Airport",
        "JUJ": "JUJ | Gobernador Horacio Guzman International Airport",
        "ORA": "ORA | Orán Airport",
        "TTG": "TTG | General Enrique Mosconi Airport",
        "CLX": "CLX | Clorinda Airport",
        "ELO": "ELO | El Dorado Airport",
        "OYA": "OYA | Goya Airport",
        "LLS": "LLS | Alferez Armando Rodriguez Airport",
        "MDX": "MDX | Mercedes Airport",
        "RCQ": "RCQ | Reconquista Airport",
        "UZU": "UZU | Curuzu Cuatia Airport",
        "EHL": "EHL | El Bolson Airport",
        "CRD": "CRD | General E. Mosconi Airport",
        "EMX": "EMX | El Maiten Airport",
        "EQS": "EQS | Brigadier Antonio Parodi Airport",
        "LHS": "LHS | Las Heras Airport",
        "IGB": "IGB | Ingeniero Jacobacci - Captain H R Bordón Airport",
        "OES": "OES | Antoine de Saint Exupéry Airport",
        "MQD": "MQD | Maquinchao Airport",
        "ARR": "ARR | D. Casimiro Szlapelis Airport",
        "SGV": "SGV | Sierra Grande Airport",
        "REL": "REL | Almirante Marco Andres Zar Airport",
        "VDM": "VDM | Gobernador Castello Airport",
        "PMY": "PMY | El Tehuelche Airport",
        "FTE": "FTE | El Calafate Airport",
        "PUD": "PUD | Puerto Deseado Airport",
        "RGA": "RGA | Hermes Quijada International Airport",
        "RGL": "RGL | Piloto Civil N. Fernández Airport",
        "USH": "USH | Malvinas Argentinas Airport",
        "ULA": "ULA | Capitan D Daniel Vazquez Airport",
        "ROY": "ROY | Rio Mayo Airport",
        "PMQ": "PMQ | Perito Moreno Airport",
        "GGS": "GGS | Gobernador Gregores Airport",
        "JSM": "JSM | Jose De San Martin Airport",
        "RYO": "RYO | 28 de Noviembre Airport",
        "RZA": "RZA | Santa Cruz Airport",
        "BHI": "BHI | Comandante Espora Airport",
        "CSZ": "CSZ | Brigadier D.H.E. Ruiz Airport",
        "OVR": "OVR | Olavarria Airport",
        "GPO": "GPO | General Pico Airport",
        "OYO": "OYO | Tres Arroyos Airport",
        "SST": "SST | Santa Teresita Airport",
        "MDQ": "MDQ | Ástor Piazzola International Airport",
        "NQN": "NQN | Presidente Peron Airport",
        "NEC": "NEC | Necochea Airport",
        "PEH": "PEH | Comodoro Pedro Zanni Airport",
        "RSA": "RSA | Santa Rosa Airport",
        "BRC": "BRC | San Carlos De Bariloche Airport",
        "TDL": "TDL | Héroes De Malvinas Airport",
        "VLG": "VLG | Villa Gesell Airport",
        "CUT": "CUT | Cutral-Co Airport",
        "CPC": "CPC | Aviador C. Campos Airport",
        "VIU": "VIU | Viru Harbour Airstrip",
        "CDJ": "CDJ | Conceição do Araguaia Airport",
        "AQA": "AQA | Araraquara Airport",
        "AJU": "AJU | Aracaju - Santa Maria International Airport",
        "AFL": "AFL | Piloto Osvaldo Marques Dias Airport",
        "ARU": "ARU | Araçatuba Airport",
        "AAX": "AAX | Romeu Zema Airport",
        "BEL": "BEL | Val de Cans/Júlio Cezar Ribeiro International Airport",
        "BGX": "BGX | Comandante Gustavo Kraemer Airport",
        "PLU": "PLU | Pampulha - Carlos Drummond de Andrade Airport",
        "BFH": "BFH | Bacacheri Airport",
        "BJP": "BJP | Estadual Arthur Siqueira Airport",
        "BSB": "BSB | Presidente Juscelino Kubitschek International Airport",
        "BAT": "BAT | Chafei Amsei Airport",
        "BAU": "BAU | Bauru Airport",
        "BVB": "BVB | Atlas Brasil Cantanhede Airport",
        "BPG": "BPG | Barra do Garças Airport",
        "BZC": "BZC | Umberto Modiano Airport",
        "CAC": "CAC | Coronel Adalberto Mendes da Silva Airport",
        "CFB": "CFB | Cabo Frio Airport",
        "CFC": "CFC | Caçador Airport",
        "CNF": "CNF | Tancredo Neves International Airport",
        "CGR": "CGR | Campo Grande Airport",
        "XAP": "XAP | Serafin Enoss Bertaso Airport",
        "CLN": "CLN | Brig. Lysias Augusto Rodrigues Airport",
        "CKS": "CKS | Carajás Airport",
        "CCM": "CCM | Diomício Freitas Airport",
        "CLV": "CLV | Nelson Ribeiro Guimarães Airport",
        "CAW": "CAW | Bartolomeu Lisandro Airport",
        "CMG": "CMG | Corumbá International Airport",
        "CWB": "CWB | Afonso Pena Airport",
        "CRQ": "CRQ | Caravelas Airport",
        "CXJ": "CXJ | Hugo Cantergiani Regional Airport",
        "CGB": "CGB | Marechal Rondon Airport",
        "CZS": "CZS | Cruzeiro do Sul Airport",
        "BYO": "BYO | Bonito Airport",
        "PPB": "PPB | Presidente Prudente Airport",
        "MAO": "MAO | Eduardo Gomes International Airport",
        "JCR": "JCR | Jacareacanga Airport",
        "ESI": "ESI | Espinosa Airport",
        "IGU": "IGU | Cataratas International Airport",
        "FLN": "FLN | Hercílio Luz International Airport",
        "FEN": "FEN | Fernando de Noronha Airport",
        "FOR": "FOR | Pinto Martins International Airport",
        "GIG": "GIG | Rio Galeão – Tom Jobim International Airport",
        "GJM": "GJM | Guajará-Mirim Airport",
        "GYN": "GYN | Santa Genoveva Airport",
        "GRU": "GRU | Guarulhos - Governador André Franco Montoro International Airport",
        "GPB": "GPB | Tancredo Thomas de Faria Airport",
        "GVR": "GVR | Coronel Altino Machado de Oliveira Airport",
        "GUJ": "GUJ | Guaratinguetá Airport",
        "ATM": "ATM | Altamira Interstate Airport",
        "ITA": "ITA | Itacoatiara Airport",
        "ITB": "ITB | Itaituba Airport",
        "IOS": "IOS | Bahia - Jorge Amado Airport",
        "IPN": "IPN | Vale do Aço Regional Airport",
        "ITR": "ITR | Francisco Vilela do Amaral Airport",
        "IMP": "IMP | Prefeito Renato Moreira Airport",
        "JJG": "JJG | Humberto Ghizzo Bortoluzzi Regional Airport",
        "JDF": "JDF | Francisco de Assis Airport",
        "JHF": "JHF | São Paulo Catarina Executive Airport",
        "JPA": "JPA | Presidente Castro Pinto International Airport",
        "RRJ": "RRJ | Jacarepaguá - Roberto Marinho Airport",
        "JDO": "JDO | Orlando Bezerra de Menezes Airport",
        "JOI": "JOI | Lauro Carneiro de Loyola Airport",
        "CPV": "CPV | Presidente João Suassuna Airport",
        "VCP": "VCP | Viracopos International Airport",
        "LEC": "LEC | Coronel Horácio de Mattos Airport",
        "LAJ": "LAJ | Lages Airport",
        "LIP": "LIP | Lins Airport",
        "LDB": "LDB | Governador José Richa Airport",
        "LAZ": "LAZ | Bom Jesus da Lapa Airport",
        "MAB": "MAB | João Correa da Rocha Airport",
        "MQH": "MQH | Minaçu Airport",
        "MEU": "MEU | Monte Dourado - Serra do Areão Airport",
        "MEA": "MEA | Macaé Benedito Lacerda Airport",
        "MGF": "MGF | Regional de Maringá - Sílvio Name Júnior Airport",
        "MOC": "MOC | Mário Ribeiro Airport",
        "MII": "MII | Frank Miloye Milenkowichi–Marília State Airport",
        "PLL": "PLL | Ponta Pelada Airport",
        "MCZ": "MCZ | Zumbi dos Palmares Airport",
        "MCP": "MCP | Alberto Alcolumbre Airport",
        "MVF": "MVF | Dix-Sept Rosado Airport",
        "MNX": "MNX | Manicoré Airport",
        "NVT": "NVT | Ministro Victor Konder International Airport",
        "GEL": "GEL | Santo Ângelo Airport",
        "OYK": "OYK | Oiapoque Airport",
        "POA": "POA | Salgado Filho International Airport",
        "PHB": "PHB | Parnaíba - Prefeito Doutor João Silva Filho International Airport",
        "POO": "POO | Poços de Caldas - Embaixador Walther Moreira Salles Airport",
        "PFB": "PFB | Lauro Kurtz Airport",
        "PMW": "PMW | Brigadeiro Lysias Rodrigues Airport",
        "PET": "PET | João Simões Lopes Neto International Airport",
        "PNZ": "PNZ | Senador Nilo Coelho Airport",
        "PNB": "PNB | Porto Nacional Airport",
        "PMG": "PMG | Ponta Porã Airport",
        "BPS": "BPS | Porto Seguro Airport",
        "PVH": "PVH | Governador Jorge Teixeira de Oliveira Airport",
        "RBR": "RBR | Rio Branco-Plácido de Castro International Airport",
        "REC": "REC | Recife/Guararapes - Gilberto Freyre International Airport",
        "SDU": "SDU | Santos Dumont Airport",
        "RAO": "RAO | Leite Lopes Airport",
        "BRB": "BRB | Barreirinhas National Airport",
        "SNZ": "SNZ | Santa Cruz Air Force Base",
        "NAT": "NAT | São Gonçalo do Amarante - Governador Aluízio Alves International Airport",
        "SJK": "SJK | Professor Urbano Ernesto Stumpf Airport",
        "SLZ": "SLZ | Marechal Cunha Machado International Airport",
        "RIA": "RIA | Santa Maria Airport",
        "STM": "STM | Maestro Wilson Fonseca Airport",
        "SMT": "SMT | Adolino Bedin Regional Airport",
        "CGH": "CGH | Congonhas Airport",
        "SJP": "SJP | Prof. Eribelto Manoel Reino State Airport",
        "SSZ": "SSZ | Santos Nero Moura Air Base / Guarujá Airport",
        "SSA": "SSA | Deputado Luiz Eduardo Magalhães International Airport",
        "TMT": "TMT | Trombetas Airport",
        "UNA": "UNA | Hotel Transamérica Airport",
        "TOW": "TOW | Toledo - Luiz Dalcanale Filho Municipal Airport",
        "THE": "THE | Senador Petrônio Portela Airport",
        "TFF": "TFF | Tefé Airport",
        "TRQ": "TRQ | Tarauacá Airport",
        "TEC": "TEC | Telêmaco Borba Airport",
        "TSQ": "TSQ | Torres Airport",
        "TBT": "TBT | Tabatinga Airport",
        "TUR": "TUR | Tucuruí Airport",
        "SJL": "SJL | São Gabriel da Cachoeira Airport",
        "PAV": "PAV | Paulo Afonso Airport",
        "URG": "URG | Rubem Berta Airport",
        "UDI": "UDI | Ten. Cel. Aviador César Bombonato Airport",
        "UBA": "UBA | Mário de Almeida Franco Airport",
        "VAG": "VAG | Major Brigadeiro Trompowsky Airport",
        "BVH": "BVH | Brigadeiro Camarão Airport",
        "VIX": "VIX | Eurico de Aguiar Salles Airport",
        "QPS": "QPS | Campo Fontenelle",
        "IZA": "IZA | Presidente Itamar Franco Airport",
        "ZUD": "ZUD | Pupelde Airport",
        "LOB": "LOB | San Rafael Airport",
        "WAP": "WAP | Alto Palena Airport",
        "ARI": "ARI | Chacalluta Airport",
        "WPA": "WPA | Cabo 1° Juan Román Airport",
        "CPO": "CPO | Desierto de Atacama Airport",
        "BBA": "BBA | Balmaceda Airport",
        "TOQ": "TOQ | Barriles Airport",
        "DPB": "DPB | Pampa Guanaco Airport",
        "CCH": "CCH | Chile Chico Airport",
        "CJC": "CJC | El Loa Airport",
        "YAI": "YAI | Gral. Bernardo O´Higgins Airport",
        "PUQ": "PUQ | Pdte. Carlos Ibañez del Campo Airport",
        "COW": "COW | Tambillos Airport",
        "GXQ": "GXQ | Teniente Vidal Airport",
        "IQQ": "IQQ | Diego Aracena Airport",
        "SCL": "SCL | Comodoro Arturo Merino Benítez International Airport",
        "ESR": "ESR | Ricardo García Posada Airport",
        "FRT": "FRT | El Avellano Airport",
        "ANF": "ANF | Andrés Sabella Gálvez International Airport",
        "WPR": "WPR | Capitan Fuentes Martinez Airport Airport",
        "FFU": "FFU | Futaleufú Airport",
        "LSQ": "LSQ | María Dolores Airport",
        "WPU": "WPU | Guardiamarina Zañartu Airport",
        "LGR": "LGR | Cochrane Airport",
        "CCP": "CCP | Carriel Sur Airport",
        "IPC": "IPC | Mataveri Airport",
        "ZOS": "ZOS | Cañal Bajo Carlos - Hott Siebert Airport",
        "CPP": "CPP | Coposa Airport",
        "VLR": "VLR | Vallenar Airport",
        "ZLR": "ZLR | Municipal de Linares Airport",
        "PNT": "PNT | Tte. Julio Gallardo Airport",
        "OVL": "OVL | El Tuqui Airport",
        "ZPC": "ZPC | Pucón Airport",
        "MHC": "MHC | Mocopulli Airport",
        "PUX": "PUX | El Mirador Airport",
        "ZCO": "ZCO | La Araucanía Airport",
        "CNR": "CNR | Chañaral Airport",
        "VAP": "VAP | Rodelillo Airport",
        "TNM": "TNM | Teniente Rodolfo Marsh Martin Airport",
        "SMB": "SMB | Franco Bianco Airport",
        "LSC": "LSC | La Florida Airport",
        "SSD": "SSD | Víctor Lafón Airport",
        "WCA": "WCA | Gamboa Airport",
        "PZS": "PZS | Maquehue Airport",
        "PMC": "PMC | El Tepual Airport",
        "TLX": "TLX | Panguilemo Airport",
        "WCH": "WCH | Chaitén Airport",
        "ZIC": "ZIC | Victoria Airport",
        "TTC": "TTC | Las Breas Airport",
        "ZAL": "ZAL | Pichoy Airport",
        "KNA": "KNA | Viña del mar Airport",
        "QCJ": "QCJ | Botucatu - Tancredo de Almeida Neves Airport",
        "OLC": "OLC | Senadora Eunice Micheles Airport",
        "SOD": "SOD | Sorocaba Airport",
        "QDC": "QDC | Dracena Airport",
        "SDI": "SDI | Saidor Airport",
        "JLS": "JLS | Jales Airport",
        "QOA": "QOA | Mococa Airport",
        "QGC": "QGC | Lençóis Paulista Airport",
        "QNV": "QNV | Aeroclube Airport",
        "OUS": "OUS | Ourinhos Airport",
        "OIA": "OIA | Ourilândia do Norte Airport",
        "QHB": "QHB | Piracicaba Airport",
        "REZ": "REZ | Resende Airport",
        "QSC": "QSC | Mário Pereira Lopes–São Carlos Airport",
        "UBT": "UBT | Ubatuba Gastão Madeira State Airport",
        "ITP": "ITP | Itaperuna Airport",
        "VOT": "VOT | Votuporanga Airport",
        "QGB": "QGB | Limeira Airport",
        "ATF": "ATF | Chachoán Airport",
        "OCC": "OCC | Francisco De Orellana Airport",
        "CUE": "CUE | Mariscal Lamar Airport",
        "GPS": "GPS | Seymour Airport",
        "GYE": "GYE | José Joaquín de Olmedo International Airport",
        "IBB": "IBB | General Villamil Airport",
        "JIP": "JIP | Jipijapa Airport",
        "LTX": "LTX | Cotopaxi International Airport",
        "MRR": "MRR | Jose Maria Velasco Ibarra Airport",
        "XMS": "XMS | Coronel E Carvajal Airport",
        "MCH": "MCH | General Manuel Serrano Airport",
        "MEC": "MEC | Eloy Alfaro International Airport",
        "LGQ": "LGQ | Nueva Loja Airport",
        "PYO": "PYO | Putumayo Airport",
        "PVO": "PVO | Reales Tamarindos Airport",
        "UIO": "UIO | Mariscal Sucre International Airport",
        "ETR": "ETR | Santa Rosa International Airport",
        "SNC": "SNC | General Ulpiano Paez Airport",
        "SUQ": "SUQ | Sucua Airport",
        "PTZ": "PTZ | Rio Amazonas Airport",
        "SCY": "SCY | San Cristóbal Airport",
        "BHA": "BHA | Los Perales Airport",
        "TSC": "TSC | Taisha Airport",
        "TPN": "TPN | Tiputini Airport",
        "LOH": "LOH | Camilo Ponce Enriquez Airport",
        "ESM": "ESM | General Rivadeneira Airport",
        "TPC": "TPC | Tarapoa Airport",
        "TUA": "TUA | Teniente Coronel Luis a Mantilla Airport",
        "PSY": "PSY | Port Stanley Airport",
        "SFU": "SFU | Safia Airport",
        "ASU": "ASU | Silvio Pettirossi International Airport",
        "AYO": "AYO | Juan De Ayolas Airport",
        "CIO": "CIO | Teniente Col Carmelo Peralta Airport",
        "ENO": "ENO | Teniente Amin Ayub Gonzalez Airport",
        "AGT": "AGT | Guarani International Airport",
        "FLM": "FLM | Filadelfia Airport",
        "ESG": "ESG | Dr. Luis Maria Argaña International Airport",
        "PIL": "PIL | Carlos Miguel Gimenez Airport",
        "PJC": "PJC | Dr Augusto Roberto Fuster International Airport",
        "SIC": "SIC | San José Island Airport",
        "LVR": "LVR | Municipal Bom Futuro Airport",
        "FRC": "FRC | Tenente Lund Pressoto Airport",
        "SIZ": "SIZ | Sissano Airport",
        "JUA": "JUA | Inácio Luís do Nascimento Airport",
        "SJF": "SJF | Cruz Bay Seaplane Base",
        "CFO": "CFO | Confresa Airport",
        "NPR": "NPR | Novo Progresso Airport",
        "AQM": "AQM | Ariquemes Airport",
        "RIG": "RIG | Rio Grande Regional Airport",
        "JTC": "JTC | Bauru/Arealva–Moussa Nakhal Tobias State Airport",
        "ARS": "ARS | Aragarças Airport",
        "ACM": "ACM | Arica Airport",
        "ECO": "ECO | El Encanto Airport",
        "ARO": "ARO | Arboletes Airport",
        "JUO": "JUO | Jurado Airport",
        "SJR": "SJR | San Juan De Uraba Airport",
        "NPU": "NPU | San Pedro Airport",
        "PCC": "PCC | Puerto Rico Airport",
        "SQF": "SQF | Solano Airport",
        "AYI": "AYI | Yari Airport",
        "CUI": "CUI | Currillo Airport",
        "MOY": "MOY | Monterrey Airport",
        "EUO": "EUO | Paratebueno Airport",
        "PRE": "PRE | Pore Airport",
        "SQE": "SQE | San Luis De Palenque Airport",
        "TAU": "TAU | Tauramena Airport",
        "AYC": "AYC | Ayacucho Airport",
        "DZI": "DZI | Las Flores Airport",
        "SJH": "SJH | San Juan Del César Airport",
        "BHF": "BHF | Bahia Cupica Airport",
        "GGL": "GGL | Gilgal Airport",
        "UNC": "UNC | Unguia Airport",
        "AYA": "AYA | Ayapel Airport",
        "NBB": "NBB | Barranco Minas Airport",
        "MND": "MND | Medina Airport",
        "NAD": "NAD | Macanal Airport",
        "GCA": "GCA | Guacamayas Airport",
        "SRO": "SRO | Santana Ramos Airport",
        "BAC": "BAC | Barranca De Upia Airport",
        "CQT": "CQT | Caquetania Airport",
        "ELJ": "ELJ | El Recreo Airport",
        "SOH": "SOH | Solita Airport",
        "URI": "URI | Uribe Airport",
        "ECR": "ECR | El Charco Airport",
        "MQR": "MQR | Mosquera Airport",
        "ISD": "ISD | Santa Bárbara Airport",
        "AZT": "AZT | Zapatoca Airport",
        "HRR": "HRR | Herrera Airport",
        "SQB": "SQB | Santa Ana Airport",
        "LPE": "LPE | La Primavera Airport",
        "ARF": "ARF | Acaricuara Airport",
        "MFB": "MFB | Monfort Airport",
        "MHF": "MHF | Morichal Airport",
        "CSR": "CSR | Casuarito Airport",
        "ACR": "ACR | Araracuara Airport",
        "ACD": "ACD | Alcides Fernández Airport",
        "AFI": "AFI | Amalfi Airport",
        "API": "API | Gomez Nino Apiay Air Base",
        "AXM": "AXM | El Eden Airport",
        "PUU": "PUU | Tres De Mayo Airport",
        "ELB": "ELB | Las Flores Airport",
        "BGA": "BGA | Palonegro Airport",
        "BOG": "BOG | El Dorado International Airport",
        "BAQ": "BAQ | Ernesto Cortissoz International Airport",
        "BSC": "BSC | José Celestino Mutis Airport",
        "BUN": "BUN | Gerardo Tobar López Airport",
        "CPB": "CPB | Capurganá Airport",
        "CUC": "CUC | Camilo Daza International Airport",
        "COG": "COG | Mandinga Airport",
        "CTG": "CTG | Rafael Nuñez International Airport",
        "CCO": "CCO | Carimagua Airport",
        "CLO": "CLO | Alfonso Bonilla Aragon International Airport",
        "CIM": "CIM | Cimitarra Airport",
        "RAV": "RAV | Cravo Norte Airport",
        "TCO": "TCO | La Florida Airport",
        "CUO": "CUO | Carurú Airport",
        "CAQ": "CAQ | Juan H White Airport",
        "CVE": "CVE | Coveñas Airport",
        "CZU": "CZU | Las Brujas Airport",
        "EBG": "EBG | El Bagre Airport",
        "EJA": "EJA | Yariguíes Airport",
        "FLA": "FLA | Gustavo Artunduaga Paredes Airport",
        "FDA": "FDA | Fundación Airport",
        "LGT": "LGT | La Gaviota Airport",
        "GIR": "GIR | Santiago Vila Airport",
        "CRC": "CRC | Santa Ana Airport",
        "GPI": "GPI | Juan Casiano Airport",
        "CPL": "CPL | Chaparral Airport",
        "HTZ": "HTZ | Hato Corozal Airport",
        "IBE": "IBE | Perales Airport",
        "IGO": "IGO | Chigorodó Jaime Ortiz Betancourt Airport",
        "IPI": "IPI | San Luis Airport",
        "APO": "APO | Antonio Roldan Betancourt Airport",
        "LQM": "LQM | Caucaya Airport",
        "MCJ": "MCJ | Jorge Isaac Airport",
        "LPD": "LPD | La Pedrera Airport",
        "LET": "LET | Alfredo Vásquez Cobo International Airport",
        "EOH": "EOH | Enrique Olaya Herrera Airport",
        "MFS": "MFS | Miraflores Airport",
        "MGN": "MGN | Baracoa Airport",
        "MTB": "MTB | Montelibano Airport",
        "MTR": "MTR | Los Garzones Airport",
        "MVP": "MVP | Fabio Alberto Leon Bentley Airport",
        "MZL": "MZL | La Nubia Airport",
        "NCI": "NCI | Necocli Airport",
        "NQU": "NQU | Reyes Murillo Airport",
        "NVA": "NVA | Benito Salas Airport",
        "OCV": "OCV | Aguas Claras Airport",
        "ORC": "ORC | Orocue Airport",
        "PCR": "PCR | German Olano Airport",
        "PDA": "PDA | Obando Airport",
        "PEI": "PEI | Matecaña International Airport",
        "PTX": "PTX | Pitalito Airport",
        "PLT": "PLT | Plato Airport",
        "NAR": "NAR | Puerto Nare Airport",
        "PPN": "PPN | Guillermo León Valencia Airport",
        "PAL": "PAL | German Olano Air Base",
        "PBE": "PBE | Puerto Berrio Airport",
        "PSO": "PSO | Antonio Narino Airport",
        "PVA": "PVA | El Embrujo Airport",
        "PZA": "PZA | Paz De Ariporo Airport",
        "MQU": "MQU | Mariquita Airport",
        "MDE": "MDE | Jose Maria Córdova International Airport",
        "RCH": "RCH | Almirante Padilla Airport",
        "RVE": "RVE | Los Colonizadores Airport",
        "SJE": "SJE | Jorge E. Gonzalez Torres Airport",
        "SSL": "SSL | Santa Rosalia Airport",
        "SMR": "SMR | Simón Bolívar International Airport",
        "SOX": "SOX | Alberto Lleras Camargo Airport",
        "ADZ": "ADZ | Gustavo Rojas Pinilla International Airport",
        "SRS": "SRS | San Marcos Airport",
        "SVI": "SVI | Eduardo Falla Solano Airport",
        "TIB": "TIB | Tibú Airport",
        "TDA": "TDA | Trinidad Airport",
        "TLU": "TLU | Golfo de Morrosquillo Airport",
        "TME": "TME | Gustavo Vargas Airport",
        "TQS": "TQS | Tres Esquinas Air Base",
        "AUC": "AUC | Santiago Perez Airport",
        "UIB": "UIB | El Caraño Airport",
        "ULQ": "ULQ | Heriberto Gíl Martínez Airport",
        "URR": "URR | Urrao Airport",
        "VGZ": "VGZ | Villa Garzón Airport",
        "PYA": "PYA | Velásquez Airport",
        "VUP": "VUP | Alfonso López Pumarejo Airport",
        "VVC": "VVC | Vanguardia Airport",
        "AYG": "AYG | Yaguara Airport",
        "EYP": "EYP | El Yopal Airport",
        "MHW": "MHW | Monteagudo Airport",
        "SRE": "SRE | Alcantarí Airport",
        "APB": "APB | Apolo Airport",
        "ASC": "ASC | Ascención De Guarayos Airport",
        "BJO": "BJO | Bermejo Airport",
        "CAM": "CAM | Camiri Airport",
        "CBB": "CBB | Jorge Wilsterman International Airport",
        "CCA": "CCA | Chimore Airport",
        "CIJ": "CIJ | Capitán Aníbal Arab Airport",
        "CEP": "CEP | Concepción Airport",
        "SRZ": "SRZ | El Trompillo Airport",
        "GYA": "GYA | Capitán de Av. Emilio Beltrán Airport",
        "BVK": "BVK | Huacaraje Airport",
        "SLJ": "SLJ | Solomon Airport",
        "SJS": "SJS | San José De Chiquitos Airport",
        "SJB": "SJB | San Joaquín Airport",
        "SJV": "SJV | San Javier Airport",
        "LPB": "LPB | El Alto International Airport",
        "MGD": "MGD | Magdalena Airport",
        "ORU": "ORU | Juan Mendoza Airport",
        "POI": "POI | Capitan Nicolas Rojas Airport",
        "PUR": "PUR | Puerto Rico Airport",
        "PSZ": "PSZ | Capitán Av. Salvador Ogaya G. airport",
        "SRD": "SRD | San Ramón Airport",
        "RBO": "RBO | Roboré Airport",
        "RIB": "RIB | Capitán Av. Selin Zeitun Lopez Airport",
        "REY": "REY | Reyes Airport",
        "SBL": "SBL | Santa Ana Del Yacuma Airport",
        "SRJ": "SRJ | Capitán Av. German Quiroga G. Airport",
        "SNG": "SNG | Capitán Av. Juan Cochamanidis S. Airport",
        "SNM": "SNM | San Ignacio de Moxos Airport",
        "SRB": "SRB | Santa Rosa De Yacuma Airport",
        "MQK": "MQK | San Matías Airport",
        "TJA": "TJA | Capitan Oriel Lea Plaza Airport",
        "TDD": "TDD | Teniente Av. Jorge Henrich Arauz Airport",
        "UYU": "UYU | Uyuni Joya Andina Airport",
        "VAH": "VAH | Capitán Av. Vidal Villagomez Toledo Airport",
        "VLM": "VLM | Teniente Coronel Rafael Pabón Airport",
        "VVI": "VVI | Viru Viru International Airport",
        "BYC": "BYC | Yacuiba Airport",
        "ABN": "ABN | Albina Airport",
        "TOT": "TOT | Totness Airport",
        "DRJ": "DRJ | Drietabbetje Airport",
        "SMH": "SMH | Sapmanga Airport",
        "PBM": "PBM | Johan Adolf Pengel International Airport",
        "MOJ": "MOJ | Moengo Airstrip",
        "ICK": "ICK | Nieuw Nickerie - Major Henk Fernandes Airport",
        "SMP": "SMP | Stockholm Airport",
        "OEM": "OEM | Vincent Fayks Airport",
        "SMZ": "SMZ | Stoelmanseiland Airport",
        "AGI": "AGI | Wageningen Airstrip",
        "ORG": "ORG | Zorg en Hoop Airport",
        "APY": "APY | Alto Parnaíba Airport",
        "APQ": "APQ | Arapiraca Airport",
        "AMJ": "AMJ | Cirilo Queiróz Airport",
        "ARX": "ARX | Aracati Dragão do Mar Regional Airport",
        "AIF": "AIF | Marcelo Pires Halzhausen Airport",
        "BDC": "BDC | Barra do Corda Airport",
        "BVM": "BVM | Belmonte Airport",
        "BRA": "BRA | Barreiras Airport",
        "BSS": "BSS | Balsas Airport",
        "BMS": "BMS | Sócrates Mariani Bittencourt Airport",
        "BQQ": "BQQ | Barra Airport",
        "CTP": "CTP | Carutapera Airport",
        "CPU": "CPU | Cururupu Airport",
        "QCH": "QCH | Colatina Airport",
        "RDC": "RDC | Redenção Airport",
        "LEP": "LEP | Leopoldina Airport",
        "DTI": "DTI | Diamantina Airport",
        "DIQ": "DIQ | Brigadeiro Cabral Airport",
        "JPE": "JPE | Nagib Demachki Airport",
        "CNV": "CNV | Sócrates Rezende Airport",
        "SXX": "SXX | São Félix do Xingu Airport",
        "GUZ": "GUZ | Guarapari Airport",
        "GDP": "GDP | Guadalupe Airport",
        "GNM": "GNM | Guanambi Airport",
        "QGP": "QGP | Garanhuns Airport",
        "ITN": "ITN | Tertuliano Guedes de Pinho Airport",
        "IRE": "IRE | Irecê Airport",
        "QIG": "QIG | Iguatu Airport",
        "QIT": "QIT | Itapetinga Airport",
        "IPU": "IPU | Ipiaú Airport",
        "JCM": "JCM | Jacobina Airport",
        "FEC": "FEC | João Durval Carneiro Airport",
        "JEQ": "JEQ | Jequié Airport",
        "JNA": "JNA | Januária Airport",
        "JDR": "JDR | Prefeito Octávio de Almeida Neves Airport",
        "CMP": "CMP | Santana do Araguaia Airport",
        "CDI": "CDI | Cachoeiro do Itapemirim - Raimundo de Andrade Airport",
        "QCP": "QCP | Currais Novos Airport",
        "SSO": "SSO | São Lourenço Airport",
        "MTE": "MTE | Monte Alegre Airport",
        "MVS": "MVS | Mucuri Airport",
        "SBJ": "SBJ | São Mateus - Ernesto Bonomo Airport",
        "PTQ": "PTQ | Porto de Moz Airport",
        "NNU": "NNU | Nanuque Airport",
        "PSW": "PSW | Municipal José Figueiredo Airport",
        "FEJ": "FEJ | Novo Aeródromo de Feijó",
        "ORX": "ORX | Oriximiná Airport",
        "PCS": "PCS | Picos Airport",
        "POJ": "POJ | Patos de Minas Airport",
        "PIV": "PIV | Pirapora Airport",
        "SNQ": "SNQ | San Quintín Military Airstrip",
        "FLB": "FLB | Cangapara Airport",
        "PDF": "PDF | Prado Airport",
        "CAU": "CAU | Caruaru Airport",
        "SFK": "SFK | Soure Airport",
        "TXF": "TXF | 9 de Maio - Teixeira de Freitas Airport",
        "OBI": "OBI | Óbidos Airport",
        "TFL": "TFL | Juscelino Kubitscheck Airport",
        "JPO": "JPO | Aeroporto Brigadeiro Firmino Ayres",
        "VAL": "VAL | Valença Airport",
        "QID": "QID | Mélio Viana Airport",
        "BVS": "BVS | Breves Airport",
        "CMC": "CMC | Camocim - Pinto Martins Airport",
        "GGF": "GGF | Almeirim Airport",
        "PHI": "PHI | Pinheiro Airport",
        "GMS": "GMS | Antônio Guerreiro Airport",
        "PPY": "PPY | Pouso Alegre Airport",
        "ITE": "ITE | Ituberá Airport",
        "BXX": "BXX | Borama Airport",
        "SOA": "SOA | Sóc Trăng Airport",
        "CAY": "CAY | Cayenne – Félix Eboué Airport",
        "GSI": "GSI | Grand-Santi Airport",
        "MPY": "MPY | Maripasoula Airport",
        "OYP": "OYP | Saint-Georges-de-l'Oyapock Airport",
        "LDX": "LDX | Saint-Laurent-du-Maroni Airport",
        "REI": "REI | Régina Airport",
        "XAU": "XAU | Saül Airport",
        "SOR": "SOR | Al Thaurah Airport",
        "APE": "APE | San Juan Aposento Airport",
        "ALD": "ALD | Alerta Airport",
        "AOP": "AOP | Alferez FAP Alfredo Vladimir Sara Bauer Airport",
        "AYX": "AYX | Teniente General Gerardo Pérez Pinedo Airport",
        "MBP": "MBP | Moyobamba Airport",
        "BLP": "BLP | Huallaga Airport",
        "IBP": "IBP | Iberia Airport",
        "PCL": "PCL | Cap FAP David Abenzur Rengifo International Airport",
        "TDP": "TDP | Trompeteros Airport",
        "CHM": "CHM | Teniente FAP Jaime A De Montreuil Morales Airport",
        "TGI": "TGI | Tingo Maria Airport",
        "CIX": "CIX | Capitan FAP Jose A Quinones Gonzales International Airport",
        "AYP": "AYP | Coronel FAP Alfredo Mendivil Duarte Airport",
        "ANS": "ANS | Andahuaylas Airport",
        "ATA": "ATA | Comandante FAP German Arias Graziani Airport",
        "UMI": "UMI | Quince Air Base",
        "LIM": "LIM | Jorge Chávez International Airport",
        "UCZ": "UCZ | Uchiza Airport",
        "RIJ": "RIJ | Juan Simons Vela Airport",
        "JAE": "JAE | Shumba Airport",
        "JJI": "JJI | Juanjui Airport",
        "JAU": "JAU | Francisco Carle Airport",
        "JUL": "JUL | Inca Manco Capac International Airport",
        "SJA": "SJA | San Juan de Marcona Airport",
        "CJA": "CJA | Mayor General FAP Armando Revoredo Iglesias Airport",
        "RIM": "RIM | San Nicolas Airport",
        "ILQ": "ILQ | Ilo Airport",
        "TBP": "TBP | Capitan FAP Pedro Canga Rodriguez Airport",
        "MZA": "MZA | Mayor PNP Nancy Flores Paucar Airport",
        "SMG": "SMG | Santa Maria Airport",
        "YMS": "YMS | Moises Benzaquen Rengifo Airport",
        "HUU": "HUU | Alferez Fap David Figueroa Fernandini Airport",
        "SQU": "SQU | Saposoa Airport",
        "CHH": "CHH | Chachapoyas Airport",
        "IQT": "IQT | Coronel FAP Francisco Secada Vignetta International Airport",
        "AQP": "AQP | Rodríguez Ballón International Airport",
        "TRU": "TRU | Capitan FAP Carlos Martinez De Pinillos International Airport",
        "PIO": "PIO | Capitán FAP Renán Elías Olivera International Airport",
        "TPP": "TPP | Cadete FAP Guillermo Del Castillo Paredes Airport",
        "SYC": "SYC | Shiringayoc Airport",
        "TCQ": "TCQ | Coronel FAP Carlos Ciriani Santa Rosa International Airport",
        "PEM": "PEM | Padre Aldamiz International Airport",
        "PIU": "PIU | Capitán FAP Guillermo Concha Iberico International Airport",
        "TYL": "TYL | Capitan Montes Airport",
        "NZC": "NZC | Maria Reiche Neuman Airport",
        "CUZ": "CUZ | Alejandro Velasco Astete International Airport",
        "SQD": "SQD | Shangrao Sanqingshan Airport",
        "SQJ": "SQJ | Shaxian Airport",
        "SQT": "SQT | China Strait Airstrip",
        "AAJ": "AAJ | Cayana Airstrip",
        "KCB": "KCB | Tepoe Airstrip",
        "SRL": "SRL | Palo Verde Airport",
        "SRM": "SRM | Sandringham Airport",
        "SRV": "SRV | Stony River 2 Airport",
        "CZB": "CZB | Carlos Ruhl Airport",
        "APU": "APU | Captain João Busse Airport",
        "BGV": "BGV | Aeroclube de Bento Gonçalves Airport",
        "BNU": "BNU | Blumenau Airport",
        "CCI": "CCI | Concórdia Airport",
        "CSS": "CSS | Cassilândia Airport",
        "QCN": "QCN | Canela Airport",
        "CKO": "CKO | Cornélio Procópio Airport",
        "DOU": "DOU | Dourados Airport",
        "ERM": "ERM | Erechim Airport",
        "FBE": "FBE | Francisco Beltrão Airport",
        "QGA": "QGA | Guaíra Airport",
        "HRZ": "HRZ | Walter Bündchen Airport",
        "IJU": "IJU | Ijuí Airport",
        "ITQ": "ITQ | Itaqui Airport",
        "JCB": "JCB | Santa Terezinha Airport",
        "CBW": "CBW | Campo Mourão Airport",
        "QDB": "QDB | Cachoeira do Sul Airport",
        "QCR": "QCR | Curitibanos Airport",
        "OAL": "OAL | Cacoal Airport",
        "LOI": "LOI | Helmuth Baungarten Airport",
        "ALQ": "ALQ | Alegrete Novo Airport",
        "QGF": "QGF | Montenegro Airport",
        "QHV": "QHV | Novo Hamburgo Airport",
        "SQX": "SQX | São Miguel do Oeste Airport",
        "APX": "APX | Arapongas Airport",
        "PTO": "PTO | Juvenal Loureiro Cardoso Airport",
        "PNG": "PNG | Paranaguá Airport",
        "PVI": "PVI | Paranavaí Airport",
        "PBB": "PBB | Paranaíba Airport",
        "QAC": "QAC | Castro Airport",
        "SQY": "SQY | São Lourenço do Sul Airport",
        "SSS": "SSS | Siassi Airport",
        "JBS": "JBS | São Borja Airport",
        "CSU": "CSU | Santa Cruz do Sul Airport",
        "TJL": "TJL | Plínio Alarcom Airport",
        "UMU": "UMU | Umuarama Airport",
        "VIA": "VIA | Videira Airport",
        "CTQ": "CTQ | Santa Vitória do Palmar Airport",
        "AXE": "AXE | Xanxerê Airport",
        "AAG": "AAG | Avelino Vieira Airport",
        "SRA": "SRA | Santa Rosa Airport",
        "PGZ": "PGZ | Ponta Grossa Airport - Comandante Antonio Amilton Beraldo",
        "ATI": "ATI | Artigas International Airport",
        "BUV": "BUV | Bella Union Airport",
        "CYR": "CYR | Laguna de Los Patos International Airport",
        "DZO": "DZO | Santa Bernardina International Airport",
        "PDP": "PDP | Capitan Corbeta CA Curbelo International Airport",
        "MLZ": "MLZ | Cerro Largo International Airport",
        "MVD": "MVD | Carrasco International /General C L Berisso Airport",
        "PDU": "PDU | Tydeo Larre Borges Airport",
        "RVY": "RVY | Presidente General Don Oscar D. Gestido International Airport",
        "STY": "STY | Nueva Hesperides International Airport",
        "TAW": "TAW | Tacuarembo Airport",
        "TYT": "TYT | Treinta y Tres Airport",
        "VCH": "VCH | Vichadero Airport",
        "AGV": "AGV | Oswaldo Guevara Mujica Airport",
        "AAO": "AAO | Anaco Airport",
        "LPJ": "LPJ | Armando Schwarck Airport",
        "BLA": "BLA | General José Antonio Anzoategui International Airport",
        "BNS": "BNS | Barinas Airport",
        "BRM": "BRM | Barquisimeto International Airport",
        "MYC": "MYC | Escuela Mariscal Sucre Airport",
        "CBL": "CBL | Aeropuerto General Tomas de Heres. Ciudad Bolivar",
        "CXA": "CXA | Caicara del Orinoco Airport",
        "ZRZ": "ZRZ | Zaraza Airport",
        "CUV": "CUV | Casigua El Cubo Airport",
        "CLZ": "CLZ | Calabozo Airport",
        "CAJ": "CAJ | Canaima Airport",
        "VCR": "VCR | Carora Airport",
        "CUP": "CUP | General Francisco Bermúdez Airport",
        "CZE": "CZE | José Leonardo Chirinos Airport",
        "CUM": "CUM | Cumaná (Antonio José de Sucre) Airport",
        "PPZ": "PPZ | Puerto Paez Airport",
        "EOR": "EOR | El Dorado Airport",
        "EOZ": "EOZ | Elorza Airport",
        "GDO": "GDO | Guasdalito Airport",
        "GUI": "GUI | Guiria Airport",
        "GUQ": "GUQ | Guanare Airport",
        "HGE": "HGE | Higuerote Airport",
        "ICA": "ICA | Icabarú Airport",
        "ICC": "ICC | Andrés Miguel Salazar Marcano Airport",
        "LSP": "LSP | Josefa Camejo International Airport",
        "KAV": "KAV | Kavanayen Airport",
        "LFR": "LFR | La Fria Airport",
        "MAR": "MAR | La Chinita International Airport",
        "MRD": "MRD | Alberto Carnevalli Airport",
        "PMV": "PMV | Del Caribe Santiago Mariño International Airport",
        "CCS": "CCS | Simón Bolívar International Airport",
        "MUN": "MUN | Maturín Airport",
        "CBS": "CBS | Oro Negro Airport",
        "PYH": "PYH | Cacique Aramare Airport",
        "PBL": "PBL | General Bartolome Salom International Airport",
        "PDZ": "PDZ | Pedernales Airport",
        "PPH": "PPH | Perai-Tepuy Airport",
        "SCI": "SCI | Paramillo Airport",
        "PZO": "PZO | General Manuel Carlos Piar International Airport",
        "PTM": "PTM | Palmarito Airport",
        "LRV": "LRV | Los Roques Airport",
        "SVS": "SVS | Stevens Village Airport",
        "SVZ": "SVZ | San Antonio Del Tachira Airport",
        "SBB": "SBB | Santa Bárbara de Barinas Airport",
        "SNV": "SNV | Santa Elena de Uairen Airport",
        "STD": "STD | Mayor Buenaventura Vivas International Airport",
        "SNF": "SNF | Sub Teniente Nestor Arias Airport",
        "SFD": "SFD | San Fernando De Apure Airport",
        "SOM": "SOM | San Tomé Airport",
        "STB": "STB | Santa Bárbara del Zulia Airport",
        "TUV": "TUV | Tucupita Airport",
        "TMO": "TMO | Tumeremo Airport",
        "URM": "URM | Uriman Airport",
        "VLN": "VLN | Arturo Michelena International Airport",
        "VIG": "VIG | Juan Pablo Pérez Alfonso Airport",
        "VLV": "VLV | Dr. Antonio Nicolás Briceño Airport",
        "VDP": "VDP | Valle de La Pascua Airport",
        "BAZ": "BAZ | Barcelos Airport",
        "LCB": "LCB | Pontes e Lacerda Airport",
        "RBB": "RBB | Borba Airport",
        "CAF": "CAF | Carauari Airport",
        "CQS": "CQS | Costa Marques Airport",
        "DMT": "DMT | Diamantino Airport",
        "DNO": "DNO | Dianópolis Airport",
        "SWE": "SWE | Siwea Airport",
        "ERN": "ERN | Eirunepé Airport",
        "CQA": "CQA | Canarana Airport",
        "SXO": "SXO | São Félix do Araguaia Airport",
        "SWG": "SWG | Satwag Airport",
        "GRP": "GRP | Gurupi Airport",
        "AUX": "AUX | Araguaína Airport",
        "GGB": "GGB | Olhos D`água Airport",
        "HUW": "HUW | Humaitá Airport",
        "IPG": "IPG | Ipiranga Airport",
        "IDO": "IDO | Santa Izabel do Morro Airport",
        "JPR": "JPR | Ji-Paraná Airport",
        "JIA": "JIA | Juína Airport",
        "JRN": "JRN | Juruena Airport",
        "JTI": "JTI | Jataí Airport",
        "CCX": "CCX | Cáceres Airport",
        "CIZ": "CIZ | Coari Airport",
        "TLZ": "TLZ | Catalão Airport",
        "LBR": "LBR | Lábrea Airport",
        "RVD": "RVD | General Leite de Castro Airport",
        "MBZ": "MBZ | Maués Airport",
        "NVP": "NVP | Novo Aripuanã Airport",
        "BCR": "BCR | Novo Campo Airport",
        "NQL": "NQL | Niquelândia Airport",
        "APS": "APS | Anápolis Airport",
        "FBA": "FBA | Fonte Boa Airport",
        "PBV": "PBV | Porto dos Gaúchos Airport",
        "PIN": "PIN | Parintins Airport",
        "PBQ": "PBQ | Pimenta Bueno Airport",
        "PBX": "PBX | Fazenda Piraguassu Airport",
        "SWR": "SWR | Silur Airport",
        "AAI": "AAI | Arraias Airport",
        "ROO": "ROO | Maestro Marinho Franco Airport",
        "AIR": "AIR | Aripuanã Airport",
        "OPS": "OPS | Presidente João Batista Figueiredo Airport",
        "STZ": "STZ | Santa Terezinha Airport",
        "IRZ": "IRZ | Tapuruquara Airport",
        "TGQ": "TGQ | Tangará da Serra Airport",
        "AZL": "AZL | Fazenda Tucunaré Airport",
        "QHN": "QHN | Taguatinga Airport",
        "SQM": "SQM | São Miguel do Araguaia Airport",
        "MTG": "MTG | Vila Bela da Santíssima Trindade Airport",
        "VLP": "VLP | Vila Rica Airport",
        "MBK": "MBK | Regional Orlando Villas Boas Airport",
        "NOK": "NOK | Xavantina Airport",
        "IUP": "IUP | Apuí Airport",
        "SXH": "SXH | Sehulea Airport",
        "SXP": "SXP | Nunam Iqua Airport",
        "AHL": "AHL | Aishalton Airport",
        "NAI": "NAI | Annai Airport",
        "SYB": "SYB | Seal Bay Seaplane Base",
        "BMJ": "BMJ | Baramita Airport",
        "GFO": "GFO | Bartica A Airport",
        "GEO": "GEO | Cheddi Jagan International Airport",
        "SYF": "SYF | Silva Bay Seaplane Base",
        "OGL": "OGL | Eugene F. Correira International Airport",
        "IMB": "IMB | Imbaimadai Airport",
        "KAR": "KAR | Kamarang Airport",
        "KRM": "KRM | Karanambo Airport",
        "KRG": "KRG | Karasabai Airport",
        "KTO": "KTO | Kato Airport",
        "KKG": "KKG | Konawaruk Airport",
        "LUB": "LUB | Lumid Pau Airport",
        "LTM": "LTM | Lethem Airport",
        "USI": "USI | Mabaruma Airport",
        "MHA": "MHA | Mahdia Airport",
        "VEG": "VEG | Maikwak Airport",
        "MYM": "MYM | Monkey Mountain Airport",
        "MWJ": "MWJ | Matthews Ridge Airport",
        "SYN": "SYN | Stanton Airfield",
        "QSX": "QSX | New Amsterdam Airport",
        "ORJ": "ORJ | Orinduik Airport",
        "PMT": "PMT | Paramakatoi Airport",
        "PRR": "PRR | Paruima Airport",
        "SDC": "SDC | Sand Creek Airport",
        "SKM": "SKM | Skeldon Airport",
        "SZN": "SZN | Santa Cruz Island Airport",
        "SZP": "SZP | Santa Paula Airport",
        "TAO": "TAO | Qingdao Jiaodong International Airport",
        "ANU": "ANU | V.C. Bird International Airport",
        "BBQ": "BBQ | Codrington Airport",
        "TBE": "TBE | Timbunke Airport",
        "BGI": "BGI | Grantley Adams International Airport",
        "TBQ": "TBQ | Tarabo Airport",
        "TBV": "TBV | Tabal Airstrip",
        "TCK": "TCK | Tinboli Airport",
        "TCT": "TCT | Takotna Airport",
        "TDB": "TDB | Tetebedi Airport",
        "DCF": "DCF | Canefield Airport",
        "DOM": "DOM | Douglas-Charles Airport",
        "TDS": "TDS | Sasereme Airport",
        "TEO": "TEO | Terapo Airport",
        "TFB": "TFB | Tifalmin Airport",
        "DSD": "DSD | La Désirade Airport",
        "BBR": "BBR | Basse-Terre Baillif Airport",
        "SFC": "SFC | St-François Airport",
        "FDF": "FDF | Martinique Aimé Césaire International Airport",
        "SFG": "SFG | Grand Case-Espérance Airport",
        "SBH": "SBH | Saint Barthélemy - Rémy de Haenen Airport",
        "GBJ": "GBJ | Les Bases Airport",
        "PTP": "PTP | Pointe-à-Pitre Le Raizet International  Airport",
        "LSS": "LSS | Terre-de-Haut Airport",
        "TGL": "TGL | Tagula Airport",
        "GND": "GND | Point Salines International Airport",
        "CRU": "CRU | Lauriston Airport",
        "THW": "THW | Trincomalee Harbor Waterdrome",
        "STT": "STT | Cyril E. King Airport",
        "STX": "STX | Henry E Rohlsen Airport",
        "ARE": "ARE | Antonio Nery Juarbe Pol Airport",
        "BQN": "BQN | Rafael Hernández International Airport",
        "TJC": "TJC | Ticantiki Airport",
        "CPX": "CPX | Benjamin Rivera Noriega Airport",
        "SIG": "SIG | Fernando Luis Ribas Dominicci Airport",
        "MAZ": "MAZ | Eugenio Maria De Hostos Airport",
        "PSE": "PSE | Mercedita Airport",
        "NRR": "NRR | José Aponte de la Torre Airport",
        "SJU": "SJU | Luis Munoz Marin International Airport",
        "VQS": "VQS | Antonio Rivera Rodriguez Airport",
        "TKE": "TKE | Tenakee Seaplane Base",
        "TKL": "TKL | Taku Lodge Seaplane Base",
        "SKB": "SKB | Robert L. Bradshaw International Airport",
        "NEV": "NEV | Vance W. Amory International Airport",
        "TLP": "TLP | Tumolbil Airport",
        "SLU": "SLU | George F. L. Charles Airport",
        "UVF": "UVF | Hewanorra International Airport",
        "TLT": "TLT | Tuluksak Airport",
        "KEA": "KEA | Kerki Airport",
        "NBE": "NBE | Enfidha - Hammamet International Airport",
        "AUA": "AUA | Queen Beatrix International Airport",
        "BON": "BON | Flamingo International Airport",
        "CUR": "CUR | Hato International Airport",
        "EUX": "EUX | F. D. Roosevelt Airport",
        "SXM": "SXM | Princess Juliana International Airport",
        "SAB": "SAB | Juancho E. Yrausquin Airport",
        "TNW": "TNW | Jumandy Airport",
        "TOK": "TOK | Torokina Airport",
        "TOV": "TOV | Tortola West End Seaplane Base",
        "PTA": "PTA | Port Alsworth Airport",
        "TPT": "TPT | Tapeta Airport",
        "AXA": "AXA | Clayton J Lloyd International Airport",
        "BGG": "BGG | Bingöl Çeltiksuyu Airport",
        "OGU": "OGU | Ordu–Giresun Airport",
        "IGD": "IGD | Iğdır Airport",
        "MNI": "MNI | John A. Osborne Airport",
        "TSG": "TSG | Tanacross Airport",
        "TSI": "TSI | Tsile Tsile Airport",
        "TAB": "TAB | Tobago-Crown Point Airport",
        "POS": "POS | Piarco International Airport",
        "TTW": "TTW | Tissa Tank Waterdrome",
        "TUE": "TUE | Tupile Airport",
        "TUJ": "TUJ | Tum Airport",
        "NGD": "NGD | Captain Auguste George Airport",
        "EIS": "EIS | Terrance B. Lettsome International Airport",
        "VIJ": "VIJ | Virgin Gorda Airport",
        "SVD": "SVD | Argyle International Airport",
        "BQU": "BQU | J F Mitchell Airport",
        "CIW": "CIW | Canouan Airport",
        "MQS": "MQS | Mustique Airport",
        "UNI": "UNI | Union Island International Airport",
        "DSX": "DSX | Dongsha Island (Pratas Island) Airport",
        "CMJ": "CMJ | Qimei Airport",
        "TWC": "TWC | Tumxuk Tangwangcheng Airport",
        "TWH": "TWH | Two Harbors Amphibious Terminal",
        "BDA": "BDA | L.F. Wade International International Airport",
        "TYE": "TYE | Tyonek Airport",
        "GIT": "GIT | Mchauru Airport",
        "LUY": "LUY | Lushoto Airport",
        "TZO": "TZO | Tsimiroro Airport",
        "DBS": "DBS | Dubois Municipal Airport",
        "MXR": "MXR | Myrhorod Air Base",
        "KHU": "KHU | Kakhnovka Airfield",
        "ALA": "ALA | Almaty Airport",
        "BXH": "BXH | Balkhash Airport",
        "BXJ": "BXJ | Boralday Airport",
        "NQZ": "NQZ | Nursultan Nazarbayev International Airport",
        "KOV": "KOV | Kokshetau Airport",
        "PPK": "PPK | Petropavl Airport",
        "DMB": "DMB | Taraz Airport",
        "UAE": "UAE | Mount Aue Airport",
        "IKU": "IKU | Issyk-Kul International Airport",
        "FRU": "FRU | Manas International Airport",
        "OSS": "OSS | Osh Airport",
        "CIT": "CIT | Shymkent Airport",
        "HSA": "HSA | Hazrat Sultan International Airport",
        "DZN": "DZN | Zhezkazgan Airport",
        "KGF": "KGF | Sary-Arka Airport",
        "KZO": "KZO | Kyzylorda Airport",
        "URA": "URA | Uralsk Airport",
        "EKB": "EKB | Ekibastuz Airport",
        "UKK": "UKK | Ust-Kamenogorsk Airport",
        "PWQ": "PWQ | Pavlodar Airport",
        "PLX": "PLX | Semipalatinsk Airport",
        "SZI": "SZI | Zaysan Airport",
        "SCO": "SCO | Aktau Airport",
        "GUW": "GUW | Atyrau Airport",
        "AKX": "AKX | Aktobe Airport",
        "AYK": "AYK | Arkalyk North Airport",
        "KSN": "KSN | Kostanay West Airport",
        "GYD": "GYD | Heydar Aliyev International Airport",
        "KVD": "KVD | Ganja International Airport",
        "LLK": "LLK | Lankaran International Airport",
        "NAJ": "NAJ | Nakhchivan Airport",
        "GBB": "GBB | Gabala International Airport",
        "ZTU": "ZTU | Zaqatala International Airport",
        "YLV": "YLV | Yevlakh Airport",
        "UBI": "UBI | Buin Airport",
        "LWN": "LWN | Shirak International Airport",
        "EVN": "EVN | Zvartnots International Airport",
        "BQJ": "BQJ | Batagay Airport",
        "SUK": "SUK | Sakkyryr Airport",
        "UKG": "UKG | Ust-Kuyga Airport",
        "TLK": "TLK | Talakan Airport",
        "ADH": "ADH | Aldan Airport",
        "YKS": "YKS | Yakutsk Airport",
        "NER": "NER | Chulman Airport",
        "MQJ": "MQJ | Moma Airport",
        "GYG": "GYG | Magan Airport",
        "OLZ": "OLZ | Olyokminsk Airport",
        "USR": "USR | Ust-Nera Airport",
        "UMS": "UMS | Ust-Maya Airport",
        "VHV": "VHV | Verkhnevilyuisk Airport",
        "NYR": "NYR | Nyurba Airport",
        "SUY": "SUY | Suntar Airport",
        "VYI": "VYI | Vilyuisk Airport",
        "ULK": "ULK | Lensk Airport",
        "ONK": "ONK | Olenyok Airport",
        "PYJ": "PYJ | Polyarny Airport",
        "MJZ": "MJZ | Mirny Airport",
        "SYS": "SYS | Saskylakh Airport",
        "BGN": "BGN | Belaya Gora Airport",
        "SEK": "SEK | Srednekolymsk Airport",
        "CKH": "CKH | Chokurdakh Airport",
        "CYX": "CYX | Cherskiy Airport",
        "IKS": "IKS | Tiksi Airport",
        "ZKP": "ZKP | Zyryanka Airport",
        "KHX": "KHX | Savannah Airstrip",
        "OYG": "OYG | Moyo Airport",
        "UGB": "UGB | Ugashik Bay Airport",
        "KUT": "KUT | David the Builder Kutaisi International Airport",
        "BUS": "BUS | Batumi International Airport",
        "SUI": "SUI | Sukhumi Babushara /  Vladislav Ardzinba International Airport",
        "TBS": "TBS | Tbilisi International Airport",
        "BQS": "BQS | Ignatyevo Airport",
        "GDG": "GDG | Magdagachi Airport",
        "TYD": "TYD | Tynda Airport",
        "KHV": "KHV | Khabarovsk Novy Airport",
        "KXK": "KXK | Komsomolsk-on-Amur Airport",
        "GVN": "GVN | Sovetskaya Gavan (Maygatka) Airport",
        "DYR": "DYR | Ugolny Yuri Ryktheu Airport",
        "PVS": "PVS | Provideniya Bay Airport",
        "KPW": "KPW | Keperveem Airport",
        "GDX": "GDX | Sokol Airport",
        "KVM": "KVM | Markovo Airport",
        "PWE": "PWE | Pevek Airport",
        "SWV": "SWV | Severo-Evensk Airport",
        "BQG": "BQG | Bogorodskoye Airport",
        "NLI": "NLI | Nikolayevsk-na-Amure Airport",
        "OHO": "OHO | Okhotsk Airport",
        "PKC": "PKC | Yelizovo Airport",
        "BVV": "BVV | Burevestnik Airport",
        "OHH": "OHH | Okha Airport",
        "ITU": "ITU | Iturup Airport",
        "EKS": "EKS | Shakhtyorsk Airport",
        "DEE": "DEE | Yuzhno-Kurilsk Mendeleyevo Airport",
        "ZZO": "ZZO | Zonalnoye Airport",
        "UUS": "UUS | Yuzhno-Sakhalinsk Airport",
        "AEM": "AEM | Amgu Airport",
        "ETL": "ETL | Svetlaya Airport",
        "EDN": "EDN | Yedinka Airport",
        "KVR": "KVR | Kavalerovo Airport",
        "TLY": "TLY | Plastun Airport",
        "NEI": "NEI | Terney Airport",
        "VVO": "VVO | Vladivostok International Airport",
        "HTA": "HTA | Chita-Kadala International Airport",
        "BTK": "BTK | Bratsk Airport",
        "UIK": "UIK | Ust-Ilimsk Airport",
        "IKT": "IKT | Irkutsk International Airport",
        "ODO": "ODO | Bodaybo Airport",
        "ERG": "ERG | Yerbogachen Airport",
        "KCK": "KCK | Kirensk Airport",
        "RZH": "RZH | Preobrazheniye Airport",
        "UKX": "UKX | Ust-Kut Airport",
        "UUD": "UUD | Baikal International Airport",
        "UJE": "UJE | Ujae Atoll Airport",
        "UJN": "UJN | Uljin Airport",
        "KBP": "KBP | Boryspil International Airport",
        "KRQ": "KRQ | Kramatorsk Airport",
        "MPW": "MPW | Mariupol International Airport",
        "SEV": "SEV | Sievierodonetsk Airport",
        "VSG": "VSG | Luhansk International Airport",
        "DNK": "DNK | Dnipropetrovsk International Airport",
        "OZH": "OZH | Zaporizhzhia International Airport",
        "KWG": "KWG | Kryvyi Rih International Airport",
        "UKS": "UKS | Sevastopol International Airport / Belbek Air Base",
        "SIP": "SIP | Simferopol International Airport",
        "KHC": "KHC | Kerch Airport",
        "UKH": "UKH | Mukhaizna Airport",
        "HRK": "HRK | Kharkiv International Airport",
        "PLV": "PLV | Suprunovka Airport",
        "UMY": "UMY | Sumy Airport",
        "CKC": "CKC | Cherkasy International Airport",
        "KGO": "KGO | Kirovograd Airport",
        "IEV": "IEV | Kiev Zhuliany International Airport",
        "GML": "GML | Gostomel Airport",
        "ZTR": "ZTR | Zhytomyr Airport",
        "UCK": "UCK | Lutsk Airport",
        "HMJ": "HMJ | Khmelnytskyi Airport",
        "IFO": "IFO | Ivano-Frankivsk International Airport",
        "LWO": "LWO | Lviv International Airport",
        "CWC": "CWC | Chernivtsi International Airport",
        "RWN": "RWN | Rivne International Airport",
        "UDJ": "UDJ | Uzhhorod International Airport",
        "KHE": "KHE | Kherson International Airport",
        "NLV": "NLV | Mykolaiv International Airport",
        "ODS": "ODS | Odessa International Airport",
        "VIN": "VIN | Vinnytsia/Gavyryshivka Airport",
        "ARH": "ARH | Talagi Airport",
        "LDG": "LDG | Leshukonskoye Airport",
        "NNM": "NNM | Naryan Mar Airport",
        "CSH": "CSH | Solovki Airport",
        "CEE": "CEE | Cherepovets Airport",
        "AMV": "AMV | Amderma Airport",
        "VRI": "VRI | Varandey Airport",
        "ULH": "ULH | Majeed Bin Abdulaziz Airport",
        "KSZ": "KSZ | Kotlas Airport",
        "LED": "LED | Pulkovo Airport",
        "KVK": "KVK | Kirovsk-Apatity Airport",
        "MMK": "MMK | Murmansk Airport",
        "VLU": "VLU | Velikiye Luki Airport",
        "PKV": "PKV | Pskov Airport",
        "PES": "PES | Petrozavodsk Airport",
        "VGD": "VGD | Vologda Airport",
        "BQT": "BQT | Brest Airport",
        "GME": "GME | Gomel Airport",
        "VTB": "VTB | Vitebsk Vostochny Airport",
        "KGD": "KGD | Khrabrovo Airport",
        "GNA": "GNA | Hrodna Airport",
        "MSQ": "MSQ | Minsk National Airport",
        "MVQ": "MVQ | Mogilev Airport",
        "ABA": "ABA | Abakan International Airport",
        "BAX": "BAX | Barnaul Airport",
        "RGK": "RGK | Gorno-Altaysk Airport",
        "KEJ": "KEJ | Kemerovo Airport",
        "EIE": "EIE | Yeniseysk Airport",
        "TGP": "TGP | Podkamennaya Tunguska Airport",
        "KJA": "KJA | Krasnoyarsk International Airport",
        "ACS": "ACS | Achinsk Airport",
        "KYZ": "KYZ | Kyzyl Airport",
        "OVB": "OVB | Novosibirsk Tolmachevo Airport",
        "OMS": "OMS | Omsk Central Airport",
        "SWT": "SWT | Strezhevoy Airport",
        "TOF": "TOF | Bogashevo Airport",
        "NOZ": "NOZ | Spichenkovo Airport",
        "DKS": "DKS | Dikson Airport",
        "HTG": "HTG | Khatanga Airport",
        "IAA": "IAA | Igarka Airport",
        "NSK": "NSK | Norilsk-Alykel Airport",
        "THX": "THX | Turukhansk Airport",
        "AAQ": "AAQ | Anapa Vityazevo Airport",
        "EIK": "EIK | Yeysk Airport",
        "GDZ": "GDZ | Gelendzhik Airport",
        "KRR": "KRR | Krasnodar Pashkovsky International Airport",
        "MCX": "MCX | Uytash Airport",
        "MRV": "MRV | Mineralnyye Vody Airport",
        "NAL": "NAL | Nalchik Airport",
        "OGZ": "OGZ | Beslan Airport",
        "IGT": "IGT | Magas Airport",
        "STW": "STW | Stavropol Shpakovskoye Airport",
        "ROV": "ROV | Platov International Airport",
        "TGK": "TGK | Taganrog Yuzhny Airport",
        "AER": "AER | Sochi International Airport",
        "ASF": "ASF | Astrakhan Airport",
        "ESL": "ESL | Elista Airport",
        "VOG": "VOG | Volgograd International Airport",
        "BNH": "BNH | Cape Air Seaplanes on Boston Harbor Seaplane Base",
        "XWA": "XWA | Williston Basin International Airport",
        "RTL": "RTL | Spirit Lake Municipal Airport",
        "CEK": "CEK | Chelyabinsk Balandino Airport",
        "MQF": "MQF | Magnitogorsk International Airport",
        "SBT": "SBT | Sabetta International Airport",
        "BVJ": "BVJ | Bovanenkovo Airport",
        "SLY": "SLY | Salekhard Airport",
        "YMK": "YMK | Mys Kamenny Airport",
        "KKQ": "KKQ | Krasnoselkup Airport",
        "TQL": "TQL | Tarko-Sale Airport",
        "UEN": "UEN | Urengoy Airport",
        "EZV": "EZV | Berezovo Airport",
        "HMA": "HMA | Khanty Mansiysk Airport",
        "IRM": "IRM | Igrim Airport",
        "NYA": "NYA | Nyagan Airport",
        "OVS": "OVS | Sovetskiy Airport",
        "URJ": "URJ | Uray Airport",
        "EYK": "EYK | Beloyarskiy Airport",
        "IJK": "IJK | Izhevsk Airport",
        "KVX": "KVX | Pobedilovo Airport",
        "NYM": "NYM | Nadym Airport",
        "NUX": "NUX | Novy Urengoy Airport",
        "NJC": "NJC | Nizhnevartovsk Airport",
        "PEE": "PEE | Perm International Airport",
        "KGP": "KGP | Kogalym International Airport",
        "NFG": "NFG | Nefteyugansk Airport",
        "NOJ": "NOJ | Noyabrsk Airport",
        "SGC": "SGC | Surgut Airport",
        "SVX": "SVX | Koltsovo Airport",
        "RMZ": "RMZ | Remezov Airport",
        "TOX": "TOX | Tobolsk Airport",
        "TJM": "TJM | Roshchino International Airport",
        "KRO": "KRO | Kurgan Airport",
        "BKN": "BKN | Balkanabat Airport",
        "GMV": "GMV | Monument Valley Airport",
        "ASB": "ASB | Ashgabat International Airport",
        "KRW": "KRW | Turkmenbashi International Airport",
        "MYP": "MYP | Mary International Airport",
        "TAZ": "TAZ | Daşoguz Airport",
        "CRZ": "CRZ | Türkmenabat International Airport",
        "DYU": "DYU | Dushanbe Airport",
        "TJU": "TJU | Kulob Airport",
        "LBD": "LBD | Khujand Airport",
        "KQT": "KQT | Qurghonteppa International Airport",
        "AZN": "AZN | Andizhan Airport",
        "FEG": "FEG | Fergana International Airport",
        "NMA": "NMA | Namangan Airport",
        "NCU": "NCU | Nukus Airport",
        "UGC": "UGC | Urgench Airport",
        "NVI": "NVI | Navoi Airport",
        "BHK": "BHK | Bukhara International Airport",
        "KSQ": "KSQ | Karshi Airport",
        "AFS": "AFS | Sugraly Airport",
        "SKD": "SKD | Samarkand Airport",
        "TMJ": "TMJ | Termez Airport",
        "TAS": "TAS | Tashkent International Airport",
        "UTU": "UTU | Ustupo Airport",
        "KMW": "KMW | Kostroma Sokerkino Airport",
        "KLF": "KLF | Grabtsevo Airport",
        "IWA": "IWA | Ivanovo South Airport",
        "RYB": "RYB | Staroselye Airport",
        "BZK": "BZK | Bryansk Airport",
        "ZIA": "ZIA | Zhukovsky International Airport",
        "DME": "DME | Domodedovo International Airport",
        "IAR": "IAR | Tunoshna Airport",
        "SVO": "SVO | Sheremetyevo International Airport",
        "KLD": "KLD | Migalovo Air Base",
        "OSF": "OSF | Ostafyevo International Airport",
        "CKL": "CKL | Chkalovskiy Air Base",
        "EGO": "EGO | Belgorod International Airport",
        "URS": "URS | Kursk East Airport",
        "LPK": "LPK | Lipetsk Airport",
        "VOZ": "VOZ | Voronezh International Airport",
        "OEL": "OEL | Oryol Yuzhny Airport",
        "TBW": "TBW | Donskoye Airport",
        "UUU": "UUU | Manumu Airport",
        "RZN": "RZN | Turlatovo Airport",
        "TYA": "TYA | Klokovo Airfield",
        "VKO": "VKO | Vnukovo International Airport",
        "UCT": "UCT | Ukhta Airport",
        "INA": "INA | Inta Airport",
        "PEX": "PEX | Pechora Airport",
        "USK": "USK | Usinsk Airport",
        "VKT": "VKT | Vorkuta Airport",
        "UTS": "UTS | Ust-Tsylma Airport",
        "SCW": "SCW | Syktyvkar Airport",
        "GOJ": "GOJ | Nizhny Novgorod Strigino International Airport",
        "UUA": "UUA | Bugulma Airport",
        "KZN": "KZN | Kazan International Airport",
        "NBC": "NBC | Begishevo Airport",
        "JOK": "JOK | Yoshkar-Ola Airport",
        "CSY": "CSY | Cheboksary Airport",
        "ZIX": "ZIX | Zhigansk Airport",
        "ULV": "ULV | Ulyanovsk Baratayevka Airport",
        "ULY": "ULY | Ulyanovsk East Airport",
        "REN": "REN | Orenburg Central Airport",
        "OSW": "OSW | Orsk Airport",
        "PEZ": "PEZ | Penza Airport",
        "SKX": "SKX | Saransk Airport",
        "BWO": "BWO | Balakovo Airport",
        "GSV": "GSV | Gagarin International Airport",
        "BCX": "BCX | Beloretsk Airport",
        "NEF": "NEF | Neftekamsk Airport",
        "OKT": "OKT | Oktyabrskiy Airport",
        "UFA": "UFA | Ufa International Airport",
        "KUF": "KUF | Kurumoch International Airport",
        "UZM": "UZM | Hope Bay Aerodrome",
        "UZR": "UZR | Urzhar Airport",
        "REW": "REW | Rewa Airport Chorhata REWA",
        "DIU": "DIU | Diu Airport",
        "GDB": "GDB | Gondia Airport",
        "AMD": "AMD | Sardar Vallabhbhai Patel International Airport",
        "AKD": "AKD | Akola Airport",
        "IXU": "IXU | Aurangabad Airport",
        "BOM": "BOM | Chhatrapati Shivaji International Airport",
        "PAB": "PAB | Bilaspur Airport",
        "BHJ": "BHJ | Bhuj Airport",
        "IXG": "IXG | Belagavi Airport",
        "BDQ": "BDQ | Vadodara Airport",
        "BHO": "BHO | Raja Bhoj International Airport",
        "BHU": "BHU | Bhavnagar Airport",
        "NMB": "NMB | Daman Airport",
        "GUX": "GUX | Guna Airport",
        "GOI": "GOI | Dabolim Airport",
        "HBX": "HBX | Hubli Airport",
        "IDR": "IDR | Devi Ahilyabai Holkar Airport",
        "JLR": "JLR | Jabalpur Airport",
        "JGA": "JGA | Jamnagar Airport",
        "IXY": "IXY | Kandla Airport",
        "HJR": "HJR | Khajuraho Airport",
        "KLH": "KLH | Kolhapur Airport",
        "IXK": "IXK | Keshod Airport",
        "NDC": "NDC | Nanded Airport",
        "NAG": "NAG | Dr. Babasaheb Ambedkar International Airport",
        "ISK": "ISK | Nashik Airport",
        "PNQ": "PNQ | Pune Airport / Lohagaon Air Force Station",
        "PBD": "PBD | Porbandar Airport",
        "RTC": "RTC | Ratnagiri Airport",
        "RAJ": "RAJ | Rajkot Airport",
        "RPR": "RPR | Swami Vivekananda Airport",
        "SAG": "SAG | Shirdi Airport",
        "SSE": "SSE | Solapur Airport",
        "STV": "STV | Surat Airport",
        "UDR": "UDR | Maharana Pratap Airport",
        "CMB": "CMB | Bandaranaike International Colombo Airport",
        "ACJ": "ACJ | Anuradhapura Airport",
        "BTC": "BTC | Batticaloa Airport",
        "RML": "RML | Colombo Ratmalana Airport",
        "ADP": "ADP | Ampara Airport",
        "HIM": "HIM | Hingurakgoda Air Force Base",
        "JAF": "JAF | Kankesanturai Airport",
        "KCT": "KCT | Koggala Airport",
        "KTY": "KTY | Katukurunda Air Force Base",
        "GIU": "GIU | Sigiriya Air Force Base",
        "TRR": "TRR | China Bay Airport",
        "WRZ": "WRZ | Weerawila Airport",
        "HRI": "HRI | Mattala Rajapaksa International Airport",
        "BBM": "BBM | Battambang Airport",
        "KZC": "KZC | Kampong Chhnang Airport",
        "KKZ": "KKZ | Koh Kong Airport",
        "MWV": "MWV | Mondulkiri Airport",
        "PNH": "PNH | Phnom Penh International Airport",
        "RBE": "RBE | Ratanakiri Airport",
        "REP": "REP | Siem Reap International Airport",
        "KOS": "KOS | Sihanoukville International Airport",
        "KZD": "KZD | Krakor Airport",
        "LGY": "LGY | Lagunillas Airport",
        "KTV": "KTV | Kamarata Airport",
        "LAG": "LAG | La Guaira Airport",
        "SFX": "SFX | Macagua Airport",
        "SVV": "SVV | San Salvador de Paul Airport",
        "WOK": "WOK | Wonken Airport",
        "DBR": "DBR | Darbhanga Airport",
        "IXV": "IXV | Along Airport",
        "IXA": "IXA | Agartala - Maharaja Bir Bikram Airport",
        "IXB": "IXB | Bagdogra Airport",
        "RGH": "RGH | Balurghat Airport",
        "SHL": "SHL | Shillong Airport",
        "BBI": "BBI | Biju Patnaik Airport",
        "CCU": "CCU | Netaji Subhash Chandra Bose International Airport",
        "COH": "COH | Cooch Behar Airport",
        "DBD": "DBD | Dhanbad Airport",
        "RDP": "RDP | Kazi Nazrul Islam Airport",
        "DEP": "DEP | Daporijo Airport",
        "GOP": "GOP | Gorakhpur Airport",
        "GAU": "GAU | Lokpriya Gopinath Bordoloi International Airport",
        "GAY": "GAY | Gaya Airport",
        "IMF": "IMF | Imphal Airport",
        "JRG": "JRG | Jharsuguda Airport",
        "PYB": "PYB | Jeypore Airport",
        "IXW": "IXW | Sonari Airport",
        "JRH": "JRH | Jorhat Airport",
        "IXQ": "IXQ | Kamalpur Airport",
        "IXH": "IXH | Kailashahar Airport",
        "IXS": "IXS | Silchar Airport",
        "IXN": "IXN | Khowai Airport",
        "AJL": "AJL | Lengpui Airport",
        "IXI": "IXI | North Lakhimpur Airport",
        "LDA": "LDA | Malda Airport",
        "DIB": "DIB | Dibrugarh Airport",
        "DMU": "DMU | Dimapur Airport",
        "MZU": "MZU | Muzaffarpur Airport",
        "IXT": "IXT | Pasighat Airport",
        "PAT": "PAT | Lok Nayak Jayaprakash Airport",
        "IXR": "IXR | Birsa Munda Airport",
        "RRK": "RRK | Rourkela Airport",
        "RUP": "RUP | Rupsi India Airport",
        "TEZ": "TEZ | Tezpur Airport",
        "VTZ": "VTZ | Visakhapatnam Airport",
        "ZER": "ZER | Ziro Airport",
        "BZL": "BZL | Barisal Airport",
        "CXB": "CXB | Cox's Bazar Airport",
        "CLA": "CLA | Comilla Airport",
        "CGP": "CGP | Shah Amanat International Airport",
        "IRD": "IRD | Ishurdi Airport",
        "JSR": "JSR | Jessore Airport",
        "RJH": "RJH | Shah Mokhdum Airport",
        "SPD": "SPD | Saidpur Airport",
        "TKR": "TKR | Thakurgaon Airport",
        "ZHM": "ZHM | Shamshernagar Airport",
        "ZYL": "ZYL | Osmany International Airport",
        "DAC": "DAC | Hazrat Shahjalal International Airport",
        "HKG": "HKG | Hong Kong International Airport",
        "SPB": "SPB | Charlotte Amalie Harbor Seaplane Base",
        "SSB": "SSB | Christiansted Harbor Seaplane Base",
        "AGR": "AGR | Agra Airport / Agra Air Force Station",
        "IXD": "IXD | Allahabad Airport",
        "ATQ": "ATQ | Sri Guru Ram Dass Jee International Airport",
        "AIP": "AIP | Adampur Airport",
        "BKB": "BKB | Nal Airport",
        "VNS": "VNS | Lal Bahadur Shastri Airport",
        "KUU": "KUU | Kullu Manali Airport",
        "BUP": "BUP | Bhatinda Air Force Station",
        "BEK": "BEK | Bareilly Air Force Station",
        "IXC": "IXC | Chandigarh Airport",
        "KNU": "KNU | Kanpur Airport",
        "DED": "DED | Dehradun Airport",
        "DEL": "DEL | Indira Gandhi International Airport",
        "DHM": "DHM | Kangra Airport",
        "GWL": "GWL | Gwalior Airport",
        "HSS": "HSS | Hissar Airport",
        "JDH": "JDH | Jodhpur Airport",
        "JAI": "JAI | Jaipur International Airport",
        "JSA": "JSA | Jaisalmer Airport",
        "IXJ": "IXJ | Jammu Airport",
        "KQH": "KQH | Kishangarh Airport Ajmer",
        "KTU": "KTU | Kota Airport",
        "LUH": "LUH | Ludhiana Airport",
        "IXL": "IXL | Leh Kushok Bakula Rimpochee Airport",
        "LKO": "LKO | Chaudhary Charan Singh International Airport",
        "IXP": "IXP | Pathankot Airport",
        "PGH": "PGH | Pantnagar Airport",
        "SLV": "SLV | Shimla Airport",
        "SXR": "SXR | Sheikh ul Alam Airport",
        "TNI": "TNI | Satna Airport",
        "VIV": "VIV | Vivigani Airfield",
        "VJQ": "VJQ | Gurue Airport",
        "AOU": "AOU | Attopeu Airport",
        "HOE": "HOE | Ban Huoeisay Airport",
        "LPQ": "LPQ | Luang Phabang International Airport",
        "LXG": "LXG | Luang Namtha Airport",
        "ODY": "ODY | Oudomsay Airport",
        "PKZ": "PKZ | Pakse International Airport",
        "ZBY": "ZBY | Sayaboury Airport",
        "ZVK": "ZVK | Savannakhet Airport",
        "NEU": "NEU | Sam Neua Airport",
        "VNA": "VNA | Saravane Airport",
        "THK": "THK | Thakhek Airport",
        "VTE": "VTE | Wattay International Airport",
        "XKH": "XKH | Xieng Khouang Airport",
        "XIE": "XIE | Xienglom Airport",
        "VMI": "VMI | Dr Juan Plate Airport",
        "MFM": "MFM | Macau International Airport",
        "VDH": "VDH | Dong Hoi Airport",
        "KON": "KON | Kontum Airport",
        "BJH": "BJH | Bajhang Airport",
        "BHP": "BHP | Bhojpur Airport",
        "BGL": "BGL | Baglung Airport",
        "BHR": "BHR | Bharatpur Airport",
        "BJU": "BJU | Bajura Airport",
        "BIT": "BIT | Baitadi Airport",
        "BWA": "BWA | Gautam Buddha International Airport",
        "BDP": "BDP | Bhadrapur Airport",
        "DNP": "DNP | Tulsipur Airport",
        "DHI": "DHI | Dhangarhi Airport",
        "DAP": "DAP | Darchula Airport",
        "DOP": "DOP | Dolpa Airport",
        "SIH": "SIH | Silgadi Doti Airport",
        "GKH": "GKH | Palungtar Airport",
        "JIR": "JIR | Jiri Airport",
        "JUM": "JUM | Jumla Airport",
        "JKR": "JKR | Janakpur Airport",
        "JMO": "JMO | Jomsom Airport",
        "KTM": "KTM | Tribhuvan International Airport",
        "LDN": "LDN | Lamidanda Airport",
        "LUA": "LUA | Lukla Airport",
        "LTG": "LTG | Langtang Airport",
        "NGX": "NGX | Manang Airport",
        "MEY": "MEY | Meghauli Airport",
        "XMG": "XMG | Mahendranagar Airport",
        "KEP": "KEP | Nepalgunj Airport",
        "PKR": "PKR | Pokhara Airport",
        "PPL": "PPL | Phaplu Airport",
        "RJB": "RJB | Rajbiraj Airport",
        "RHP": "RHP | Ramechhap Airport",
        "RUK": "RUK | Rukum Chaurjahari Airport",
        "RPA": "RPA | Rolpa Airport",
        "RUM": "RUM | Rumjatar Airport",
        "SYH": "SYH | Syangboche Airport",
        "SIF": "SIF | Simara Airport",
        "SKH": "SKH | Surkhet Airport",
        "FEB": "FEB | Sanfebagar Airport",
        "IMK": "IMK | Simikot Airport",
        "TPJ": "TPJ | Taplejung Airport",
        "TPU": "TPU | Tikapur Airport",
        "TMI": "TMI | Tumling Tar Airport",
        "BIR": "BIR | Biratnagar Airport",
        "LTU": "LTU | Murod Kond Airport",
        "TCR": "TCR | Tuticorin Airport",
        "AGX": "AGX | Agatti Airport",
        "BEP": "BEP | Bellary Airport",
        "BLR": "BLR | Kempegowda International Airport",
        "IXX": "IXX | Bidar Airport / Bidar Air Force Station",
        "VGA": "VGA | Vijayawada Airport",
        "CJB": "CJB | Coimbatore International Airport",
        "COK": "COK | Cochin International Airport",
        "CCJ": "CCJ | Calicut International Airport",
        "CDP": "CDP | Kadapa Airport",
        "CBD": "CBD | Car Nicobar Air Force Base",
        "HYD": "HYD | Rajiv Gandhi International Airport",
        "BPM": "BPM | Begumpet Airport",
        "CNN": "CNN | Kannur International Airport",
        "KJB": "KJB | Kurnool Airport",
        "IXM": "IXM | Madurai Airport",
        "IXE": "IXE | Mangalore International Airport",
        "MAA": "MAA | Chennai International Airport",
        "MYQ": "MYQ | Mysore Airport",
        "IXZ": "IXZ | Veer Savarkar International Airport / INS Utkrosh",
        "PNY": "PNY | Pondicherry Airport",
        "PUT": "PUT | Sri Sathya Sai Airport",
        "RMD": "RMD | Basanth Nagar Airport",
        "RJA": "RJA | Rajahmundry Airport",
        "SXV": "SXV | Salem Airport",
        "TJV": "TJV | Thanjavur Air Force Station",
        "TIR": "TIR | Tirupati Airport",
        "TRZ": "TRZ | Tiruchirappalli International Airport",
        "TRV": "TRV | Trivandrum International Airport",
        "WGC": "WGC | Warangal Airport",
        "YON": "YON | Yongphulla Airport",
        "BUT": "BUT | Bathpalathang Airport",
        "GLU": "GLU | Gelephu Airport",
        "PBH": "PBH | Paro Airport",
        "HDK": "HDK | Kulhudhuffushi Airport",
        "NMF": "NMF | Maafaru International Airport",
        "IFU": "IFU | Ifuru Airport",
        "DRV": "DRV | Dharavandhoo Airport",
        "FVM": "FVM | Fuvahmulah Airport",
        "GAN": "GAN | Gan International Airport",
        "HAQ": "HAQ | Hanimaadhoo Airport",
        "KDO": "KDO | Kadhdhoo Airport",
        "MLE": "MLE | Malé International Airport",
        "GKK": "GKK | Kooddoo Airport",
        "KDM": "KDM | Kaadedhdhoo Airport",
        "DDD": "DDD | Dhaalu Atoll Airport",
        "VAM": "VAM | Villa Airport",
        "TMF": "TMF | Thimarafushi Airport",
        "RUL": "RUL | Maavaarulaa Airport",
        "DMK": "DMK | Don Mueang International Airport",
        "KKM": "KKM | Sa Pran Nak Airport",
        "KDT": "KDT | Kamphaeng Saen Airport",
        "TDX": "TDX | Trat Airport",
        "BKK": "BKK | Suvarnabhumi Airport",
        "UTP": "UTP | U-Tapao International Airport",
        "CNX": "CNX | Chiang Mai International Airport",
        "HGN": "HGN | Mae Hong Son Airport",
        "PYY": "PYY | Mae Hong Son Airport",
        "LPT": "LPT | Lampang Airport",
        "NNT": "NNT | Nan Airport",
        "PRH": "PRH | Phrae Airport",
        "CEI": "CEI | Mae Fah Luang - Chiang Rai International Airport",
        "BAO": "BAO | Udorn Air Base",
        "PHY": "PHY | Phetchabun Airport",
        "HHQ": "HHQ | Hua Hin Airport",
        "TKH": "TKH | Takhli Airport",
        "MAQ": "MAQ | Mae Sot Airport",
        "THS": "THS | Sukhothai Airport",
        "PHS": "PHS | Phitsanulok Airport",
        "TKT": "TKT | Tak Airport",
        "UTR": "UTR | Uttaradit Airport",
        "URT": "URT | Surat Thani Airport",
        "NAW": "NAW | Narathiwat Airport",
        "CJM": "CJM | Chumphon Airport",
        "NST": "NST | Nakhon Si Thammarat Airport",
        "KBV": "KBV | Krabi Airport",
        "SGZ": "SGZ | Songkhla Airport",
        "PAN": "PAN | Pattani Airport",
        "USM": "USM | Samui Airport",
        "HKT": "HKT | Phuket International Airport",
        "UNN": "UNN | Ranong Airport",
        "HDY": "HDY | Hat Yai International Airport",
        "TST": "TST | Trang Airport",
        "UTH": "UTH | Udon Thani Airport",
        "SNO": "SNO | Sakon Nakhon Airport",
        "PXR": "PXR | Surin Airport",
        "KKC": "KKC | Khon Kaen Airport",
        "LOE": "LOE | Loei Airport",
        "BFV": "BFV | Buri Ram Airport",
        "NAK": "NAK | Nakhon Ratchasima Airport",
        "UBP": "UBP | Ubon Ratchathani Airport",
        "ROI": "ROI | Roi Et Airport",
        "KOP": "KOP | Nakhon Phanom Airport",
        "VUU": "VUU | Mvuu Camp Airport",
        "BMV": "BMV | Buon Ma Thuot Airport",
        "VCL": "VCL | Chu Lai Airport",
        "HPH": "HPH | Cat Bi International Airport",
        "CAH": "CAH | Cà Mau Airport",
        "CXR": "CXR | Cam Ranh International Airport / Cam Ranh Air Base",
        "VCS": "VCS | Con Dao Airport",
        "VCA": "VCA | Can Tho International Airport",
        "DIN": "DIN | Dien Bien Phu Airport",
        "DLI": "DLI | Lien Khuong Airport",
        "DAD": "DAD | Da Nang International Airport",
        "VVN": "VVN | Las Malvinas/Echarate Airport",
        "HAN": "HAN | Noi Bai International Airport",
        "SQH": "SQH | Na-San Airport",
        "HUI": "HUI | Phu Bai International Airport",
        "UIH": "UIH | Phu Cat Airport",
        "PXU": "PXU | Pleiku Airport",
        "PQC": "PQC | Phu Quoc International Airport",
        "PHA": "PHA | Phan Rang Airport",
        "PHH": "PHH | Phan Thiet Airport",
        "VKG": "VKG | Rach Gia Airport",
        "TBB": "TBB | Dong Tac Airport",
        "SGN": "SGN | Tan Son Nhat International Airport",
        "THD": "THD | Tho Xuan Airport",
        "VDO": "VDO | Van Don International Airport",
        "VII": "VII | Vinh Airport",
        "VTG": "VTG | Vung Tau Airport",
        "VBA": "VBA | Ann Airport",
        "NYU": "NYU | Bagan Airport",
        "BMO": "BMO | Banmaw Airport",
        "VBP": "VBP | Bokpyinn Airport",
        "TVY": "TVY | Dawei Airport",
        "NYT": "NYT | Nay Pyi Taw International Airport",
        "GAW": "GAW | Gangaw Airport",
        "GWA": "GWA | Gwa Airport",
        "HEH": "HEH | Heho Airport",
        "HOX": "HOX | Hommalinn Airport",
        "TIO": "TIO | Tilin Airport",
        "KET": "KET | Kengtung Airport",
        "KHM": "KHM | Kanti Airport",
        "KMV": "KMV | Kalay Airport",
        "KYP": "KYP | Kyaukpyu Airport",
        "KAW": "KAW | Kawthoung Airport",
        "KYT": "KYT | Kyauktu Airport",
        "LIW": "LIW | Loikaw Airport",
        "LSH": "LSH | Lashio Airport",
        "MDL": "MDL | Mandalay International Airport",
        "MGZ": "MGZ | Myeik Airport",
        "MYT": "MYT | Myitkyina Airport",
        "MNU": "MNU | Mawlamyine Airport",
        "MGU": "MGU | Manaung Airport",
        "MOE": "MOE | Momeik Airport",
        "MOG": "MOG | Mong Hsat Airport",
        "MGK": "MGK | Mong Tong Airport",
        "MWQ": "MWQ | Magway Airport",
        "NYW": "NYW | Monywar Airport",
        "NMS": "NMS | Namsang Airport",
        "NMT": "NMT | Namtu Airport",
        "PAA": "PAA | Hpa-N Airport",
        "PAU": "PAU | Pauk Airport",
        "BSX": "BSX | Pathein Airport",
        "PPU": "PPU | Hpapun Airport",
        "PBU": "PBU | Putao Airport",
        "PKK": "PKK | Pakhokku Airport",
        "PRU": "PRU | Pyay Airport",
        "AKY": "AKY | Sittwe Airport",
        "SNW": "SNW | Thandwe Airport",
        "THL": "THL | Tachileik Airport",
        "XYE": "XYE | Ye Airport",
        "RGN": "RGN | Yangon International Airport",
        "FBS": "FBS | Friday Harbor Seaplane Base",
        "RSJ": "RSJ | Rosario Seaplane Base",
        "LKE": "LKE | Kenmore Air Harbor Seaplane Base",
        "RCE": "RCE | Roche Harbor Airport",
        "TQQ": "TQQ | Maranggo Airport",
        "WSX": "WSX | Westsound/WSX Seaplane Base",
        "UPG": "UPG | Hasanuddin International Airport",
        "MJU": "MJU | Tampa Padang Airport",
        "BIK": "BIK | Frans Kaisiepo Airport",
        "ONI": "ONI | Moanamani Airport",
        "WET": "WET | Wagethe Airport",
        "NBX": "NBX | Nabire Airport",
        "ILA": "ILA | Illaga Airport",
        "KOX": "KOX | Kokonau Airport",
        "ZRI": "ZRI | Serui Airport",
        "TIM": "TIM | Mozes Kilangin Airport",
        "EWI": "EWI | Enarotali Airport",
        "WAD": "WAD | Andriamena Airport",
        "AMI": "AMI | Selaparang Airport",
        "BMU": "BMU | Muhammad Salahuddin Airport",
        "DPS": "DPS | Ngurah Rai (Bali) International Airport",
        "LOP": "LOP | Lombok International Airport",
        "SWQ": "SWQ | Sumbawa Besar Airport",
        "TMC": "TMC | Tambolaka Airport",
        "WGP": "WGP | Umbu Mehang Kunda Airport",
        "LLO": "LLO | Bua - Palopo Lagaligo Airport",
        "HMS": "HMS | Haji Muhammad Sidik Airport",
        "KLP": "KLP | Seruyan Kuala Pembuang Airport",
        "YIA": "YIA | Yogyakarta International Airport",
        "KWB": "KWB | Dewadaru - Kemujan Island",
        "ARJ": "ARJ | Arso Airport",
        "BUI": "BUI | Bokondini Airport",
        "ZRM": "ZRM | Sarmi Airport",
        "DJJ": "DJJ | Sentani International Airport",
        "LHI": "LHI | Lereh Airport",
        "LII": "LII | Mulia Airport",
        "OKL": "OKL | Oksibil Airport",
        "WAR": "WAR | Waris Airport",
        "SEH": "SEH | Senggeh Airport",
        "UBR": "UBR | Ubrub Airport",
        "WMX": "WMX | Wamena Airport",
        "MDP": "MDP | Mindiptana Airport",
        "BXD": "BXD | Bade Airport",
        "MKQ": "MKQ | Mopah International Airport",
        "OKQ": "OKQ | Okaba Airport",
        "KEI": "KEI | Kepi Airport",
        "TMH": "TMH | Tanah Merah Airport",
        "GHS": "GHS | Melalan Airport",
        "TJS": "TJS | Tanjung Harapan Airport",
        "DTD": "DTD | Datadawai Airport",
        "BEJ": "BEJ | Kalimarau Airport",
        "BPN": "BPN | Sultan Aji Muhamad Sulaiman Airport",
        "TRK": "TRK | Juwata Airport",
        "AAP": "AAP | Aji Pangeran Tumenggung Pranoto International Airport",
        "TSX": "TSX | Tanjung Santan Airport",
        "BYQ": "BYQ | Bunyu Airport",
        "GLX": "GLX | Gamarmalamo Airport",
        "PGQ": "PGQ | Buli Airport",
        "GTO": "GTO | Jalaluddin Airport",
        "NAH": "NAH | Naha Airport",
        "TLI": "TLI | Sultan Bantilan Airport",
        "GEB": "GEB | Gebe Airport",
        "KAZ": "KAZ | Kao Airport",
        "PLW": "PLW | Mutiara - SIS Al-Jufrie Airport",
        "MDC": "MDC | Sam Ratulangi Airport",
        "MNA": "MNA | Melangguane Airport",
        "PSJ": "PSJ | Kasiguncu Airport",
        "OTI": "OTI | Pitu Airport",
        "TTE": "TTE | Sultan Babullah Airport",
        "LUW": "LUW | Syukuran Aminuddin Amir Airport",
        "UOL": "UOL | Buol Airport",
        "WAN": "WAN | Waverney Airport",
        "BTW": "BTW | Batu Licin Airport",
        "PKN": "PKN | Iskandar Airport",
        "KBU": "KBU | Gusti Syamsir Alam Airport",
        "TJG": "TJG | Warukin Airport",
        "BDJ": "BDJ | Syamsudin Noor Airport",
        "PKY": "PKY | Tjilik Riwut Airport",
        "SMQ": "SMQ | Sampit(Hasan) Airport",
        "AHI": "AHI | Amahai Airport",
        "NDA": "NDA | Bandanaira Airport",
        "DOB": "DOB | Rar Gwamar Airport",
        "MAL": "MAL | Mangole Airport Falabisahaya",
        "LUV": "LUV | Karel Sadsuitubun Airport",
        "NRE": "NRE | Namrole Airport",
        "LAH": "LAH | Oesman Sadik Airport",
        "SXK": "SXK | Saumlaki/Olilit Airport",
        "BJK": "BJK | Nangasuri Airport",
        "SQN": "SQN | Emalamo Sanana Airport",
        "AMQ": "AMQ | Pattimura Airport Ambon",
        "NAM": "NAM | Namlea Airport",
        "TAX": "TAX | Taliabu Island Airport",
        "WBA": "WBA | WahaiSeram Island",
        "MLG": "MLG | Abdul Rachman Saleh Airport",
        "CPF": "CPF | Ngloram Airport",
        "JOG": "JOG | Adisutjipto International Airport",
        "SOC": "SOC | Adisumarmo International Airport",
        "SUB": "SUB | Juanda International Airport",
        "SRG": "SRG | Achmad Yani Airport",
        "SUP": "SUP | Trunojoyo Airport",
        "NTI": "NTI | Stenkol Airport",
        "RSK": "RSK | Abresso Airport",
        "KEQ": "KEQ | Kebar Airport",
        "FKQ": "FKQ | Fakfak Airport",
        "INX": "INX | Inanwatan Airport",
        "KNG": "KNG | Kaimana Airport",
        "RDE": "RDE | Merdei Airport",
        "RJM": "RJM | Marinda Airport",
        "BXB": "BXB | Babo Airport",
        "MKW": "MKW | Rendani Airport",
        "TXM": "TXM | Teminabuan Airport",
        "WSR": "WSR | Wasior Airport",
        "BJW": "BJW | Bajawa Soa Airport",
        "MOF": "MOF | Maumere(Wai Oti) Airport",
        "ENE": "ENE | Ende (H Hasan Aroeboesman) Airport",
        "RTG": "RTG | Frans Sales Lega Airport",
        "ARD": "ARD | Mali Airport",
        "LBJ": "LBJ | Komodo Airport",
        "SAU": "SAU | Sabu-Tardanu Airport",
        "KOE": "KOE | El Tari Airport",
        "BUW": "BUW | Betoambari Airport",
        "WNI": "WNI | Matahora Airport",
        "KSR": "KSR | Selayar - Haji Aroeppala Airport",
        "MXB": "MXB | Andi Jemma Airport",
        "SQR": "SQR | Soroako Airport",
        "TTR": "TTR | Pongtiku Airport",
        "KDI": "KDI | Wolter Monginsidi Airport",
        "SOQ": "SOQ | Domine Eduard Osok Airport",
        "WBB": "WBB | Stebbins Airport",
        "WBC": "WBC | Wapolu Airport",
        "BTU": "BTU | Bintulu Airport",
        "BLG": "BLG | Belaga Airport",
        "LSM": "LSM | Long Semado Airport",
        "LGL": "LGL | Long Lellang Airport",
        "KCH": "KCH | Kuching International Airport",
        "ODN": "ODN | Long Seridan Airport",
        "LMN": "LMN | Limbang Airport",
        "MKM": "MKM | Mukah Airport",
        "LKH": "LKH | Long Akah Airport",
        "MUR": "MUR | Marudi Airport",
        "BSE": "BSE | Sematan Airport",
        "KPI": "KPI | Kapit Airport",
        "BKM": "BKM | Bakalalan Airport",
        "MYY": "MYY | Miri Airport",
        "SBW": "SBW | Sibu Airport",
        "TGC": "TGC | Tanjung Manis Airport",
        "LSU": "LSU | Long Sukang Airport",
        "LWY": "LWY | Lawas Airport",
        "BBN": "BBN | Bario Airport",
        "SMM": "SMM | Semporna Airport",
        "LDU": "LDU | Lahad Datu Airport",
        "TEL": "TEL | Telupid Airport",
        "KGU": "KGU | Keningau Airport",
        "SXS": "SXS | Sahabat [Sahabat 16] Airport",
        "BKI": "BKI | Kota Kinabalu International Airport",
        "LBU": "LBU | Labuan Airport",
        "TMG": "TMG | Tomanggong Airport",
        "GSA": "GSA | Long Pasia Airport",
        "SPE": "SPE | Sepulot Airport",
        "PAY": "PAY | Pamol Airport",
        "RNU": "RNU | Ranau Airport",
        "SDK": "SDK | Sandakan Airport",
        "KUD": "KUD | Kudat Airport",
        "TWU": "TWU | Tawau Airport",
        "MZV": "MZV | Mulu Airport",
        "BWN": "BWN | Brunei International Airport",
        "WDA": "WDA | Al Ain Airport Ain District Shabwah Governorate Yemen",
        "WEA": "WEA | Parker County Airport",
        "WED": "WED | Wedau Airport",
        "WHL": "WHL | Welshpool Airport",
        "CJN": "CJN | Nusawiru Airport",
        "PKU": "PKU | Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
        "DUM": "DUM | Pinang Kampai Airport",
        "RKO": "RKO | Rokot Airport",
        "SEQ": "SEQ | Sungai Pakning Bengkalis Airport",
        "TJB": "TJB | Sei Bati Airport",
        "KJT": "KJT | Kertajati International Airport",
        "BDO": "BDO | Husein Sastranegara International Airport",
        "CBN": "CBN | Penggung Airport",
        "TSY": "TSY | Cibeureum Airport",
        "BTH": "BTH | Hang Nadim International Airport",
        "PPR": "PPR | Pasir Pangaraan Airport",
        "LMU": "LMU | Letung Airport",
        "TNJ": "TNJ | Raja Haji Fisabilillah International Airport",
        "SIQ": "SIQ | Dabo Airport",
        "HLP": "HLP | Halim Perdanakusuma International Airport",
        "CXP": "CXP | Tunggul Wulung Airport",
        "PCB": "PCB | Pondok Cabe Air Base",
        "CGK": "CGK | Soekarno-Hatta International Airport",
        "LLJ": "LLJ | Silampari Airport",
        "TKG": "TKG | Radin Inten II International Airport",
        "TFY": "TFY | Muhammad Taufiq Kiemas Airport",
        "GNS": "GNS | Binaka Airport",
        "AEG": "AEG | Aek Godang Airport",
        "MES": "MES | Soewondo Air Force Base",
        "KNO": "KNO | Kualanamu International Airport",
        "DTB": "DTB | Silangit Airport",
        "SIW": "SIW | Sibisa Airport",
        "FLZ": "FLZ | Dr. Ferdinand Lumban Tobing Airport",
        "TJQ": "TJQ | H A S Hanandjoeddin International Airport",
        "NPO": "NPO | Nanga Pinoh Airport",
        "KTG": "KTG | Rahadi Osman Airport",
        "MWK": "MWK | Tarempa Airport",
        "NTX": "NTX | Ranai Airport",
        "PNK": "PNK | Supadio Airport",
        "PSU": "PSU | Pangsuma Airport",
        "SQG": "SQG | Sintang(Susilo) Airport",
        "DJB": "DJB | Sultan Thaha Airport",
        "BUU": "BUU | Muara Bungo Airport",
        "PGK": "PGK | Depati Amir Airport",
        "BKS": "BKS | Fatmawati Soekarno Airport",
        "PLM": "PLM | Sultan Mahmud Badaruddin II Airport",
        "PDO": "PDO | Pendopo Airport",
        "RGT": "RGT | Japura Airport",
        "PDG": "PDG | Minangkabau International Airport",
        "MPC": "MPC | Muko Muko Airport",
        "PXA": "PXA | Bentayan Airport",
        "TPK": "TPK | Teuku Cut Ali Airport",
        "SBG": "SBG | Maimun Saleh Airport",
        "MEQ": "MEQ | Cut Nyak Dhien Airport",
        "TXE": "TXE | Rembele Airport",
        "LSX": "LSX | Lhok Sukon Airport",
        "LSW": "LSW | Malikus Saleh Airport",
        "BTJ": "BTJ | Sultan Iskandar Muda International Airport",
        "SXT": "SXT | Sungai Tiang Airport",
        "MEP": "MEP | Mersing Airport",
        "SWY": "SWY | Sitiawan Airport",
        "TPG": "TPG | Taiping (Tekah) Airport",
        "TOD": "TOD | Pulau Tioman Airport",
        "AOR": "AOR | Sultan Abdul Halim Airport",
        "BWH": "BWH | RMAF Butterworth Air Base",
        "KBR": "KBR | Sultan Ismail Petra Airport",
        "KUA": "KUA | Kuantan Airport",
        "KTE": "KTE | Kerteh Airport",
        "IPH": "IPH | Sultan Azlan Shah Airport",
        "JHB": "JHB | Senai International Airport",
        "KUL": "KUL | Kuala Lumpur International Airport",
        "LGK": "LGK | Langkawi International Airport",
        "MKZ": "MKZ | Malacca Airport",
        "TGG": "TGG | Sultan Mahmud Airport",
        "PEN": "PEN | Penang International Airport",
        "PKG": "PKG | Pulau Pangkor Airport",
        "RDN": "RDN | LTS Pulau Redang Airport",
        "SZB": "SZB | Sultan Abdul Aziz Shah International Airport",
        "DTR": "DTR | Decatur Shores Airport",
        "WNU": "WNU | Wanuma Airport",
        "AUT": "AUT | Atauro Airport",
        "UAI": "UAI | Suai Airport",
        "DIL": "DIL | Presidente Nicolau Lobato International Airport",
        "BCH": "BCH | Cakung Airport",
        "MPT": "MPT | Maliana Airport",
        "OEC": "OEC | Rota Do Sândalo Oecusse Airport",
        "VIQ": "VIQ | Viqueque Airport",
        "ABU": "ABU | AA Bere Tallo (Haliwen) Airport",
        "LKA": "LKA | Gewayentana Airport",
        "RTI": "RTI | David Constantijn Saudale Airport",
        "SGQ": "SGQ | Sanggata/Sangkimah Airport",
        "LBW": "LBW | Long Bawan Airport",
        "BXT": "BXT | Bontang Airport",
        "NNX": "NNX | Nunukan Airport",
        "TNB": "TNB | Tanah Grogot Airport",
        "LPU": "LPU | Long Apung Airport",
        "WSA": "WSA | Wasua Airport",
        "QPG": "QPG | Paya Lebar Air Base",
        "TGA": "TGA | Tengah Air Base",
        "WSB": "WSB | Steamboat Bay Seaplane Base",
        "UGI": "UGI | San Juan /Uganik/ Seaplane Base",
        "WSM": "WSM | Wiseman Airport",
        "XSP": "XSP | Seletar Airport",
        "SIN": "SIN | Singapore Changi Airport",
        "WTT": "WTT | Wantoat Airport",
        "WUV": "WUV | Wuvulu Island Airport",
        "WUZ": "WUZ | Wuzhou Xijiang Airport",
        "GWV": "GWV | Glendale Fokker Field",
        "WZQ": "WZQ | Urad Middle Banner",
        "MPB": "MPB | Miami Seaplane Base",
        "XBB": "XBB | Blubber Bay Seaplane Base",
        "XBN": "XBN | Biniguni Airport",
        "XIG": "XIG | Xinguara Municipal Airport",
        "XMA": "XMA | Maramag Airport",
        "XRQ": "XRQ | Xinbarag Youqi Baogede Airport",
        "LNX": "LNX | Smolensk North Airport",
        "XVL": "XVL | Vinh Long Airfield",
        "UKN": "UKN | Waukon Municipal Airport",
        "ALH": "ALH | Albany Airport",
        "ABG": "ABG | Abingdon Downs Airport",
        "AWN": "AWN | Alton Downs Airport",
        "AUD": "AUD | Augustus Downs Airport",
        "YAJ": "YAJ | Lyall Harbour Seaplane Base",
        "MRP": "MRP | Marla Airport",
        "AXL": "AXL | Alexandria Homestead Airport",
        "AXC": "AXC | Aramac Airport",
        "ADO": "ADO | Andamooka Airport",
        "AMX": "AMX | Ammaroo Airport",
        "AMT": "AMT | Amata Airport",
        "WLP": "WLP | West Angelas Airport",
        "AYL": "AYL | Anthony Lagoon Airport",
        "ABH": "ABH | Alpha Airport",
        "YAQ": "YAQ | Maple Bay Seaplane Base",
        "ARY": "ARY | Ararat Airport",
        "GYL": "GYL | Argyle Airport",
        "ARM": "ARM | Armidale Airport",
        "AAB": "AAB | Arrabury Airport",
        "AUU": "AUU | Aurukun Airport",
        "AWP": "AWP | Austral Downs Airport",
        "AVG": "AVG | Auvergne Airport",
        "AYQ": "AYQ | Ayers Rock Connellan Airport",
        "AYR": "AYR | Ayr Airport",
        "ACF": "ACF | Brisbane Archerfield Airport",
        "BCI": "BCI | Barcaldine Airport",
        "ASP": "ASP | Alice Springs Airport",
        "BDD": "BDD | Badu Island Airport",
        "BKP": "BKP | Barkly Downs Airport",
        "BBE": "BBE | Big Bell Airport",
        "BNE": "BNE | Brisbane International Airport",
        "OOL": "OOL | Gold Coast Airport",
        "BKQ": "BKQ | Blackall Airport",
        "CNS": "CNS | Cairns International Airport",
        "CTL": "CTL | Charleville Airport",
        "BDW": "BDW | Bedford Downs Airport",
        "BXG": "BXG | Bendigo Airport",
        "BVI": "BVI | Birdsville Airport",
        "BXF": "BXF | Bellburn Airstrip",
        "BTX": "BTX | Betoota Airport",
        "BEE": "BEE | Beagle Bay Airport",
        "OCM": "OCM | Boolgeeda",
        "BQW": "BQW | Balgo Hill Airport",
        "YBH": "YBH | Bull Harbour Water Aerodrome",
        "BHQ": "BHQ | Broken Hill Airport",
        "HTI": "HTI | Hamilton Island Airport",
        "BEU": "BEU | Bedourie Airport",
        "BIW": "BIW | Billiluna Airport",
        "BZP": "BZP | Bizant Airport",
        "YBJ": "YBJ | Baie-Johan-Beetz Water Aerodrome",
        "BRK": "BRK | Bourke Airport",
        "BUC": "BUC | Burketown Airport",
        "BLN": "BLN | Benalla Airport",
        "LCN": "LCN | Balcanoona Airport",
        "ZBL": "ZBL | Biloela Airport",
        "BLS": "BLS | Bollon Airport",
        "BQB": "BQB | Busselton Regional Airport",
        "ISA": "ISA | Mount Isa Airport",
        "BFC": "BFC | Bloomfield River Airport",
        "MKY": "MKY | Mackay Airport",
        "BNK": "BNK | Ballina Byron Gateway Airport",
        "BSJ": "BSJ | Bairnsdale Airport",
        "GIC": "GIC | Boigu Airport",
        "OKY": "OKY | Oakey Airport",
        "BQL": "BQL | Boulia Airport",
        "BMP": "BMP | Brampton Island Airport",
        "PPP": "PPP | Proserpine Whitsunday Coast Airport",
        "YBQ": "YBQ | Telegraph Harbour Seaplane Base",
        "ROK": "ROK | Rockhampton Airport",
        "BOX": "BOX | Borroloola Airport",
        "BME": "BME | Broome International Airport",
        "BZD": "BZD | Balranald Airport",
        "BTD": "BTD | Brunette Downs Airport",
        "BWQ": "BWQ | Brewarrina Airport",
        "BYP": "BYP | Barimunya Airport",
        "MCY": "MCY | Sunshine Coast Airport",
        "BHS": "BHS | Bathurst Airport",
        "BRT": "BRT | Bathurst Island Airport",
        "TSV": "TSV | Townsville Airport / RAAF Base Townsville",
        "BLT": "BLT | Blackwater Airport",
        "BVW": "BVW | Batavia Downs Airport",
        "BDB": "BDB | Bundaberg Airport",
        "BUY": "BUY | Bunbury Airport",
        "BIP": "BIP | Bulimba Airport",
        "ZBO": "ZBO | Bowen Airport",
        "WEI": "WEI | Weipa Airport",
        "BCK": "BCK | Bolwarra Airport",
        "WTB": "WTB | Brisbane West Wellcamp Airport",
        "BWB": "BWB | Barrow Island Airport",
        "BVZ": "BVZ | Beverley Springs Airport",
        "CTR": "CTR | Cattle Creek Airport",
        "CGV": "CGV | Caiguna Airport",
        "CLH": "CLH | Coolah Airport",
        "CVQ": "CVQ | Carnarvon Airport",
        "CSI": "CSI | Casino Airport",
        "CAZ": "CAZ | Cobar Airport",
        "COJ": "COJ | Coonabarabran Airport",
        "CBY": "CBY | Canobie Airport",
        "CBI": "CBI | Cape Barren Island Airport",
        "CPD": "CPD | Coober Pedy Airport",
        "CRB": "CRB | Collarenebri Airport",
        "CCL": "CCL | Chinchilla Airport",
        "CNC": "CNC | Coconut Island Airport",
        "CNJ": "CNJ | Cloncurry Airport",
        "CBX": "CBX | Condobolin Airport",
        "CUD": "CUD | Caloundra Airport",
        "CED": "CED | Ceduna Airport",
        "CVC": "CVC | Cleve Airport",
        "YCF": "YCF | Cortes Bay Water Aerodrome",
        "CFI": "CFI | Camfield Airport",
        "CFH": "CFH | Clifton Hills Airport",
        "CQP": "CQP | Cape Flattery Airport",
        "CFS": "CFS | Coffs Harbour Airport",
        "LLG": "LLG | Chillagoe Airport",
        "CRH": "CRH | Cherrabah Airport",
        "CKW": "CKW | Graeme Rowley Aerodrome",
        "CXT": "CXT | Charters Towers Airport",
        "DCN": "DCN | RAAF Base Curtin",
        "CKI": "CKI | Croker Island Airport",
        "CTN": "CTN | Cooktown Airport",
        "CMQ": "CMQ | Clermont Airport",
        "CMA": "CMA | Cunnamulla Airport",
        "CML": "CML | Camooweal Airport",
        "NIF": "NIF | Camp Nifty Airport",
        "CES": "CES | Cessnock Airport",
        "CNB": "CNB | Coonamble Airport",
        "ODL": "ODL | Cordillo Downs Airport",
        "CUQ": "CUQ | Coen Airport",
        "CIE": "CIE | Collie Airport",
        "OOM": "OOM | Cooma Snowy Mountains Airport",
        "CDA": "CDA | Cooinda Airport",
        "CWW": "CWW | Corowa Airport",
        "CFP": "CFP | Carpentaria Downs Airport",
        "CYG": "CYG | Corryong Airport",
        "CXQ": "CXQ | Christmas Creek Station Airport",
        "CDQ": "CDQ | Croydon Airport",
        "KCE": "KCE | Collinsville Airport",
        "CMD": "CMD | Cootamundra Airport",
        "CUG": "CUG | Cudal Airport",
        "CUY": "CUY | Cue Airport",
        "CJF": "CJF | Coondewanna Airport",
        "CWR": "CWR | Cowarie Airport",
        "CCW": "CCW | Cowell Airport",
        "CWT": "CWT | Cowra Airport",
        "COY": "COY | Coolawanyah Airport",
        "DJR": "DJR | Dajarra Airport",
        "DBY": "DBY | Dalby Airport",
        "DRN": "DRN | Dirranbandi Airport",
        "DNB": "DNB | Dunbar Airport",
        "DRB": "DRB | Derby Airport",
        "DFP": "DFP | Drumduff Airport",
        "DGD": "DGD | Dalgaranga Gold Mine Airport",
        "DNG": "DNG | Doongan Airport",
        "DXD": "DXD | Dixie Airport",
        "DKI": "DKI | Dunk Island Airport",
        "DLK": "DLK | Dulkaninna Airport",
        "DNQ": "DNQ | Deniliquin Airport",
        "DDN": "DDN | Delta Downs Airport",
        "DLV": "DLV | Delissaville Airport",
        "DYW": "DYW | Daly Waters Airport",
        "DMD": "DMD | Doomadgee Airport",
        "DVR": "DVR | Daly River Airport",
        "NLF": "NLF | Darnley Island Airport",
        "DRD": "DRD | Dorunda Airport",
        "DVP": "DVP | Davenport Downs Airport",
        "DPO": "DPO | Devonport Airport",
        "DOX": "DOX | Dongara Airport",
        "DRY": "DRY | Drysdale River Airport",
        "DHD": "DHD | Durham Downs Airport",
        "DRR": "DRR | Durrie Airport",
        "SRR": "SRR | Dunwich Airport",
        "DKV": "DKV | Docker River Airport",
        "DYA": "DYA | Dysart Airport",
        "ECH": "ECH | Echuca Airport",
        "EUC": "EUC | Eucla Airport",
        "ETD": "ETD | Etadunna Airport",
        "ENB": "ENB | Eneabba Airport",
        "YEH": "YEH | Yinchuan/Xincheng Air Base",
        "EIH": "EIH | Einasleigh Airport",
        "ELC": "ELC | Elcho Island Airport",
        "EKD": "EKD | Elkedra Airport",
        "EMD": "EMD | Emerald Airport",
        "YEQ": "YEQ | Yenkis(Yankisa) Airport",
        "EDD": "EDD | Erldunda Airport",
        "ERB": "ERB | Ernabella Airport",
        "ERQ": "ERQ | Elrose Airport",
        "EPR": "EPR | Esperance Airport",
        "EVD": "EVD | Eva Downs Airport",
        "EVH": "EVH | Evans Head Aerodrome",
        "WHB": "WHB | Eliwana",
        "EXM": "EXM | Exmouth Airport",
        "FRB": "FRB | Forbes Airport",
        "KFE": "KFE | Fortescue - Dave Forrest Aerodrome",
        "FLY": "FLY | Finley Airport",
        "FLS": "FLS | Flinders Island Airport",
        "FVL": "FVL | Flora Valley Airport",
        "FIK": "FIK | Finke Airport",
        "FOS": "FOS | Forrest Airport",
        "FVR": "FVR | Oombulgurri Airport",
        "FOT": "FOT | Forster (Wallis Is) Airport",
        "FIZ": "FIZ | Fitzroy Crossing Airport",
        "YGA": "YGA | Yongchuan Da'an General Airport",
        "GBP": "GBP | Gamboola Airport",
        "GAH": "GAH | Gayndah Airport",
        "GBL": "GBL | South Goulburn Is Airport",
        "GUH": "GUH | Gunnedah Airport",
        "GOO": "GOO | Goondiwindi Airport",
        "GDD": "GDD | Gordon Downs Airport",
        "GGD": "GGD | Gregory Downs Airport",
        "YGE": "YGE | Gorge Harbour Seaplane Base",
        "GET": "GET | Geraldton Airport",
        "GFN": "GFN | Grafton Airport",
        "GBW": "GBW | Ginbata Airport",
        "GBV": "GBV | Gibb River Airport",
        "GKL": "GKL | Great Keppel Is Airport",
        "GLT": "GLT | Gladstone Airport",
        "GUL": "GUL | Goulburn Airport",
        "GLG": "GLG | Glengyle Airport",
        "GEX": "GEX | Geelong Airport",
        "GLI": "GLI | Glen Innes Airport",
        "GLM": "GLM | Glenormiston Airport",
        "YGN": "YGN | Greenway Sound Seaplane Base",
        "GFE": "GFE | Grenfell Airport",
        "GVP": "GVP | Greenvale Airport",
        "GPD": "GPD | Mount Gordon Airport",
        "GPN": "GPN | Garden Point Airport",
        "GSC": "GSC | Gascoyne Junction Airport",
        "GTE": "GTE | Groote Eylandt Airport",
        "GFF": "GFF | Griffith Airport",
        "GTT": "GTT | Georgetown Airport",
        "GEE": "GEE | Georgetown (Tas) Airport",
        "GYP": "GYP | Gympie Airport",
        "HWK": "HWK | Wilpena Pound Airport",
        "HXX": "HXX | Hay Airport",
        "HVB": "HVB | Hervey Bay Airport",
        "HUB": "HUB | Humbert River Airport",
        "HRY": "HRY | Henbury Airport",
        "HIP": "HIP | Headingly Airport",
        "HIG": "HIG | Highbury Airport",
        "HID": "HID | Horn Island Airport",
        "HLL": "HLL | Hillside Airport",
        "HCQ": "HCQ | Halls Creek Airport",
        "HMG": "HMG | Hermannsburg Airport",
        "HLT": "HLT | Hamilton Airport",
        "HOK": "HOK | Hooker Creek Airport",
        "MHU": "MHU | Mount Hotham Airport",
        "HTU": "HTU | Hopetoun Airport",
        "HPE": "HPE | Hope Vale Airport",
        "HSM": "HSM | Horsham Airport",
        "HAT": "HAT | Heathlands Airport",
        "HGD": "HGD | Hughenden Airport",
        "IDK": "IDK | Indulkana Airport",
        "IFL": "IFL | Innisfail Airport",
        "IFF": "IFF | Iffley Airport",
        "IGH": "IGH | Ingham Airport",
        "IKP": "IKP | Inkerman Airport",
        "INJ": "INJ | Injune Airport",
        "INM": "INM | Innamincka Airport",
        "IVW": "IVW | Inverway Airport",
        "ISI": "ISI | Isisford Airport",
        "IVR": "IVR | Inverell Airport",
        "JAB": "JAB | Jabiru Airport",
        "JUN": "JUN | Jundah Airport",
        "QJD": "QJD | Jindabyne Airport",
        "JCK": "JCK | Julia Creek Airport",
        "JUR": "JUR | Jurien Bay Airport",
        "UBU": "UBU | Kalumburu Airport",
        "KDB": "KDB | Kambalda Airport",
        "KAX": "KAX | Kalbarri Airport",
        "KBY": "KBY | Streaky Bay Airport",
        "KBJ": "KBJ | Kings Canyon Airport",
        "KCS": "KCS | Kings Creek Airport",
        "KRA": "KRA | Kerang Airport",
        "KNS": "KNS | King Island Airport",
        "KBB": "KBB | Kirkimbie Station Airport",
        "KFG": "KFG | Kalkgurung Airport",
        "KOH": "KOH | Koolatah Airport",
        "KKP": "KKP | Koolburra Airport",
        "KRB": "KRB | Karumba Airport",
        "KML": "KML | Kamileroi Airport",
        "KPS": "KPS | Kempsey Airport",
        "KNI": "KNI | Katanning Airport",
        "KWM": "KWM | Kowanyama Airport",
        "KPP": "KPP | Kalpowar Airport",
        "KGY": "KGY | Kingaroy Airport",
        "KGC": "KGC | Kingscote Airport",
        "YKT": "YKT | Klemtu Water Aerodrome",
        "KUG": "KUG | Kubin Airport",
        "KRD": "KRD | Kurundi Airport",
        "LWH": "LWH | Lawn Hill Airport",
        "LGH": "LGH | Leigh Creek Airport",
        "LNO": "LNO | Leonora Airport",
        "LEL": "LEL | Lake Evella Airport",
        "LFP": "LFP | Lakefield Airport",
        "LDH": "LDH | Lord Howe Island Airport",
        "IRG": "IRG | Lockhart River Airport",
        "LTP": "LTP | Lyndhurst Airport",
        "LIB": "LIB | Limbunya Airport",
        "LDC": "LDC | Lindeman Island Airport",
        "LSY": "LSY | Lismore Airport",
        "LNH": "LNH | Lake Nash Airport",
        "BBL": "BBL | Ballera Airport",
        "BEO": "BEO | Lake Macquarie Airport",
        "LKD": "LKD | Lakeland Airport",
        "LOC": "LOC | Lock Airport",
        "LOA": "LOA | Lorraine Airport",
        "LTV": "LTV | Lotus Vale Airport",
        "YLP": "YLP | Mingan Airport",
        "LUU": "LUU | Laura Airport",
        "LHG": "LHG | Lightning Ridge Airport",
        "LRE": "LRE | Longreach Airport",
        "LUT": "LUT | New Laura Airport",
        "LER": "LER | Leinster Airport",
        "LVO": "LVO | Laverton Airport",
        "TGN": "TGN | Latrobe Valley Airport",
        "LZR": "LZR | Lizard Island Airport",
        "UBB": "UBB | Mabuiag Island Airport",
        "MYI": "MYI | Murray Island Airport",
        "AVV": "AVV | Avalon Airport",
        "ABX": "ABX | Albury Airport",
        "MRG": "MRG | Mareeba Airport",
        "MBB": "MBB | Marble Bar Airport",
        "XMC": "XMC | Mallacoota Airport",
        "MFP": "MFP | Manners Creek Airport",
        "MLR": "MLR | Millicent Airport",
        "DGE": "DGE | Mudgee Airport",
        "MQA": "MQA | Mandora Airport",
        "MNW": "MNW | Macdonald Downs Airport",
        "MKR": "MKR | Meekatharra Airport",
        "MEB": "MEB | Melbourne Essendon Airport",
        "MIM": "MIM | Merimbula Airport",
        "MLV": "MLV | Merluna Airport",
        "MGT": "MGT | Milingimbi Airport",
        "MNG": "MNG | Maningrida Airport",
        "GSN": "GSN | Mount Gunson Airport",
        "MGV": "MGV | Margaret River (Station) Airport",
        "MQZ": "MQZ | Margaret River Airport",
        "MVU": "MVU | Musgrave Airport",
        "HBA": "HBA | Hobart International Airport",
        "MHO": "MHO | Mount House Airport",
        "MCV": "MCV | McArthur River Mine Airport",
        "MQL": "MQL | Mildura Airport",
        "XML": "XML | Minlaton Airport",
        "MIH": "MIH | Mitchell Plateau Airport",
        "MTQ": "MTQ | Mitchell Airport",
        "MJP": "MJP | Manjimup Airport",
        "WLE": "WLE | Miles Airport",
        "LST": "LST | Launceston Airport",
        "MBW": "MBW | Melbourne Moorabbin Airport",
        "WUI": "WUI | Murrin Murrin Airport",
        "MEL": "MEL | Melbourne International Airport",
        "MMM": "MMM | Middlemount Airport",
        "MTL": "MTL | Maitland Airport",
        "WME": "WME | Mount Keith Airport",
        "ONR": "ONR | Monkira Airport",
        "MSF": "MSF | Mount Swan Airport",
        "OXY": "OXY | Morney Airport",
        "MMG": "MMG | Mount Magnet Airport",
        "OOR": "OOR | Mooraberree Airport",
        "MRZ": "MRZ | Moree Airport",
        "MET": "MET | Moreton Airport",
        "MIN": "MIN | Minnipa Airport",
        "MQE": "MQE | Marqua Airport",
        "MOV": "MOV | Moranbah Airport",
        "RRE": "RRE | Marree Airport",
        "MWB": "MWB | Morawa Airport",
        "MYA": "MYA | Moruya Airport",
        "MTD": "MTD | Mount Sanford Station Airport",
        "MIY": "MIY | Mittebah Airport",
        "UTB": "UTB | Muttaburra Airport",
        "MGB": "MGB | Mount Gambier Airport",
        "ONG": "ONG | Mornington Island Airport",
        "MNQ": "MNQ | Monto Airport",
        "MUQ": "MUQ | Muccan Station Airport",
        "MNE": "MNE | Mungeranie Airport",
        "MVK": "MVK | Mulka Airport",
        "MUP": "MUP | Mulga Park Airport",
        "MKV": "MKV | Mount Cavenagh Airport",
        "MXU": "MXU | Mullewa Airport",
        "MWT": "MWT | Moolawatana Airport",
        "MXD": "MXD | Marion Downs Airport",
        "MBH": "MBH | Maryborough Airport",
        "RTY": "RTY | Merty Merty Airport",
        "NMR": "NMR | Nappa Merrie Airport",
        "NRA": "NRA | Narrandera Airport",
        "NAA": "NAA | Narrabri Airport",
        "RPM": "RPM | Ngukurr Airport",
        "NBH": "NBH | Nambucca Heads Airport",
        "NLS": "NLS | Nicholson Airport",
        "NKB": "NKB | Noonkanbah Airport",
        "NMP": "NMP | New Moon Airport",
        "NPP": "NPP | Napperby Airport",
        "ABM": "ABM | Northern Peninsula Airport",
        "NAC": "NAC | Naracoorte Airport",
        "NRG": "NRG | Narrogin Airport",
        "QRM": "QRM | Narromine Airport",
        "RVT": "RVT | Ravensthorpe Airport",
        "NSV": "NSV | Noosa Airport",
        "NSM": "NSM | Norseman Airport",
        "YNT": "YNT | Yantai Penglai International Airport",
        "NTN": "NTN | Normanton Airport",
        "NUR": "NUR | Nullabor Motel Airport",
        "NLL": "NLL | Nullagine Airport",
        "NUB": "NUB | Numbulwar Airport",
        "UTD": "UTD | Nutwood Downs Airport",
        "ZNE": "ZNE | Newman Airport",
        "NYN": "NYN | Nyngan Airport",
        "OPI": "OPI | Oenpelli Airport",
        "YOI": "YOI | Opinaca Aerodrome",
        "XCO": "XCO | Colac Airport",
        "OLP": "OLP | Olympic Dam Airport",
        "ONS": "ONS | Onslow Airport",
        "ODD": "ODD | Oodnadatta Airport",
        "MOO": "MOO | Moomba Airport",
        "RBS": "RBS | Orbost Airport",
        "OAG": "OAG | Orange Airport",
        "ODR": "ODR | Ord River Airport",
        "OSO": "OSO | Osborne Mine Airport",
        "OYN": "OYN | Ouyen Airport",
        "ADL": "ADL | Adelaide International Airport",
        "PUG": "PUG | Port Augusta Airport",
        "PMK": "PMK | Palm Island Airport",
        "PBO": "PBO | Paraburdoo Airport",
        "CCK": "CCK | Cocos (Keeling) Islands Airport",
        "PDN": "PDN | Parndana Airport",
        "PDE": "PDE | Pandie Pandie Airport",
        "DRW": "DRW | Darwin International Airport",
        "PRD": "PRD | Pardoo Airport",
        "GOV": "GOV | Gove Airport",
        "PPI": "PPI | Port Pirie Airport",
        "JAD": "JAD | Perth Jandakot Airport",
        "KTA": "KTA | Karratha Airport",
        "KGI": "KGI | Kalgoorlie Boulder Airport",
        "PKE": "PKE | Parkes Airport",
        "PKT": "PKT | Port Keats Airport",
        "KNX": "KNX | East Kimberley Regional (Kununurra) Airport",
        "PLO": "PLO | Port Lincoln Airport",
        "LEA": "LEA | Learmonth Airport",
        "PXH": "PXH | Prominent Hill Airport",
        "EDR": "EDR | Pormpuraaw Airport",
        "PQQ": "PQQ | Port Macquarie Airport",
        "PEY": "PEY | Penong Airport",
        "PTJ": "PTJ | Portland Airport",
        "PHE": "PHE | Port Hedland International Airport",
        "PER": "PER | Perth International Airport",
        "PEA": "PEA | Penneshaw Airport",
        "KTR": "KTR | Tindal Airport",
        "UMR": "UMR | Woomera Airfield",
        "XCH": "XCH | Christmas Island Airport",
        "UIR": "UIR | Quirindi Airport",
        "YQJ": "YQJ | April Point Seaplane Base",
        "ULP": "ULP | Quilpie Airport",
        "UEE": "UEE | Queenstown Airport",
        "RRV": "RRV | Robinson River Airport",
        "YRC": "YRC | Refuge Cove Seaplane Base",
        "YRD": "YRD | Dean River Airport",
        "RMK": "RMK | Renmark Airport",
        "RCM": "RCM | Richmond Airport",
        "RAM": "RAM | Ramingining Airport",
        "ROH": "ROH | Robinhood Airport",
        "RBU": "RBU | Roebourne Airport",
        "RBC": "RBC | Robinvale Airport",
        "RMA": "RMA | Roma Airport",
        "RPB": "RPB | Roper Bar Airport",
        "RSB": "RSB | Roseberth Airport",
        "RTS": "RTS | Rottnest Island Airport",
        "RTP": "RTP | Rutland Plains Airport",
        "RHL": "RHL | Roy Hill Station Airport",
        "NDS": "NDS | Sandstone Airport",
        "BWU": "BWU | Sydney Bankstown Airport",
        "CBR": "CBR | Canberra International Airport",
        "WCD": "WCD | Carosue Dam Airport",
        "CDU": "CDU | Camden Airport",
        "NSO": "NSO | Scone Airport",
        "SQC": "SQC | Southern Cross Airport",
        "DBO": "DBO | Dubbo City Regional Airport",
        "SGO": "SGO | St George Airport",
        "SIX": "SIX | Singleton Airport",
        "ZGL": "ZGL | South Galway Airport",
        "SGP": "SGP | Shay Gap Airport",
        "MJK": "MJK | Shark Bay Airport",
        "JHQ": "JHQ | Shute Harbour Airport",
        "SHT": "SHT | Shepparton Airport",
        "SBR": "SBR | Saibai Island Airport",
        "SIO": "SIO | Smithton Airport",
        "SHU": "SHU | Smith Point Airport",
        "STH": "STH | Strathmore Airport",
        "SNB": "SNB | Snake Bay Airport",
        "NLK": "NLK | Norfolk Island International Airport",
        "NOA": "NOA | Naval Air Station Nowra - HMAS Albatross",
        "SNH": "SNH | Stanthorpe Airport",
        "SCG": "SCG | Spring Creek Airport",
        "SHQ": "SHQ | Southport Airport",
        "KSV": "KSV | Springvale Airport",
        "XRH": "XRH | RAAF Base Richmond",
        "SRN": "SRN | Strahan Airport",
        "SYD": "SYD | Sydney Kingsford Smith International Airport",
        "HLS": "HLS | St Helens Airport",
        "STF": "STF | Stephens Island Seaplane Base",
        "TMW": "TMW | Tamworth Airport",
        "SSP": "SSP | Silver Plains Airport",
        "WGA": "WGA | Wagga Wagga City Airport",
        "SWH": "SWH | Swan Hill Airport",
        "SWC": "SWC | Stawell Airport",
        "XTR": "XTR | Tara Airport",
        "TBL": "TBL | Tableland Homestead Airport",
        "XTO": "XTO | Taroom Airport",
        "TAQ": "TAQ | Tarcoola Airport",
        "PYX": "PYX | Pattaya Airpark",
        "TBK": "TBK | Timber Creek Airport",
        "TDR": "TDR | Theodore Airport",
        "TQP": "TQP | Trepell Airport",
        "TEF": "TEF | Telfer Airport",
        "TEM": "TEM | Temora Airport",
        "TAN": "TAN | Tangalooma Airport",
        "XTG": "XTG | Thargomindah Airport",
        "GTS": "GTS | The Granites Airport",
        "TDN": "TDN | Theda Station Airport",
        "TYG": "TYG | Thylungra Airport",
        "TYB": "TYB | Tibooburra Airport",
        "TKY": "TKY | Turkey Creek Airport",
        "PHQ": "PHQ | The Monument Airport",
        "TUM": "TUM | Tumut Airport",
        "TYP": "TYP | Tobermorey Airport",
        "TXR": "TXR | Tanbar Airport",
        "THG": "THG | Thangool Airport",
        "TCA": "TCA | Tennant Creek Airport",
        "TCW": "TCW | Tocumwal Airport",
        "TRO": "TRO | Taree Airport",
        "TTX": "TTX | Truscott-Mungalalu Airport",
        "TWB": "TWB | Toowoomba Airport",
        "UDA": "UDA | Undara Airport",
        "CZY": "CZY | Cluny Airport",
        "USL": "USL | Useless Loop Airport",
        "VCD": "VCD | Victoria River Downs Airport",
        "VNR": "VNR | Vanrook Station Airport",
        "WAU": "WAU | Wauchope Airport",
        "WLA": "WLA | Wallal Airport",
        "WAV": "WAV | Wave Hill Airport",
        "WMB": "WMB | Warrnambool Airport",
        "SYU": "SYU | Warraber Island Airport",
        "WIO": "WIO | Wilcannia Airport",
        "WLC": "WLC | Walcha Airport",
        "WAZ": "WAZ | Warwick Airport",
        "WND": "WND | Windarra Airport",
        "WRN": "WRN | Windarling Airport",
        "WNR": "WNR | Windorah Airport",
        "WON": "WON | Wondoola Airport",
        "MFL": "MFL | Mount Full Stop Airport",
        "WGT": "WGT | Wangaratta Airport",
        "WYA": "WYA | Whyalla Airport",
        "WSY": "WSY | Whitsunday Island Airport",
        "WIT": "WIT | Wittenoom Airport",
        "WKB": "WKB | Warracknabeal Airport",
        "WGE": "WGE | Walgett Airport",
        "NTL": "NTL | Newcastle Airport",
        "WUN": "WUN | Wiluna Airport",
        "WPK": "WPK | Wrotham Park Airport",
        "WDI": "WDI | Wondai Airport",
        "WOL": "WOL | Shellharbour Airport",
        "WLL": "WLL | Wollogorang Airport",
        "QRR": "QRR | Warren Airport",
        "SXE": "SXE | West Sale Airport",
        "WLO": "WLO | Waterloo Airport",
        "WIN": "WIN | Winton Airport",
        "WUD": "WUD | Wudinna Airport",
        "WEW": "WEW | Wee Waa Airport",
        "WRW": "WRW | Warrawagine Airport",
        "WWI": "WWI | Woodie Woodie Airport",
        "WWY": "WWY | West Wyalong Airport",
        "WYN": "WYN | Wyndham Airport",
        "BWT": "BWT | Wynyard Airport",
        "YYA": "YYA | Yueyang Sanhe Airport",
        "YLG": "YLG | Yalgoo Airport",
        "OKR": "OKR | Yorke Island Airport",
        "KYF": "KYF | Yeelirrie Airport",
        "XMY": "XMY | Yam Island Airport",
        "YUE": "YUE | Yuendumu Airport",
        "NGA": "NGA | Young Airport",
        "ORR": "ORR | Yorketown Airport",
        "KYI": "KYI | Yalata Mission Airport",
        "KKI": "KKI | Akiachak Airport",
        "BCC": "BCC | Bear Creek 3 Airport",
        "JBT": "JBT | Bethel Seaplane Base",
        "CZP": "CZP | Cape Pole Seaplane Base",
        "KBW": "KBW | Chignik Bay Seaplane Base",
        "KBC": "KBC | Birch Creek Airport",
        "CZC": "CZC | Copper Center 2 Airport",
        "ULX": "ULX | Ulusaba Airport",
        "TDT": "TDT | Tanda Tula Airport",
        "HZV": "HZV | Hazyview Airport",
        "KHO": "KHO | Khoka Moya Airport",
        "MBM": "MBM | Mkambati Airport",
        "INY": "INY | Inyati Airport",
        "TSD": "TSD | Tshipise Airport",
        "SSX": "SSX | Singita Safari Lodge Airport",
        "KIG": "KIG | Koingnaas Airport",
        "PEK": "PEK | Beijing Capital International Airport",
        "PKX": "PKX | Beijing Daxing International Airport",
        "CDE": "CDE | Chengde Puning Airport",
        "CIF": "CIF | Chifeng Airport",
        "CIH": "CIH | Changzhi Airport",
        "BPE": "BPE | Qinhuangdao Beidaihe Airport",
        "DSN": "DSN | Ordos Ejin Horo Airport",
        "DAT": "DAT | Datong Yungang Airport",
        "ERL": "ERL | Erenhot Saiwusu International Airport",
        "YIE": "YIE | Arxan Yi'ershi Airport",
        "HDG": "HDG | Handan Airport",
        "HET": "HET | Baita International Airport",
        "HUO": "HUO | Holingol Huolinhe Airport",
        "HLD": "HLD | Hulunbuir Hailar Airport",
        "LFQ": "LFQ | Linfen Qiaoli Airport",
        "LLV": "LLV | Lüliang Airport",
        "NZH": "NZH | Manzhouli Xijiao Airport",
        "BAV": "BAV | Baotou Airport",
        "SJW": "SJW | Shijiazhuang Zhengding International Airport",
        "TSN": "TSN | Tianjin Binhai International Airport",
        "TGO": "TGO | Tongliao Airport",
        "UCB": "UCB | Ulanqab Jining Airport",
        "WUA": "WUA | Wuhai Airport",
        "HLH": "HLH | Ulanhot Airport",
        "XIL": "XIL | Xilinhot Airport",
        "XNT": "XNT | Xingtai Dalian Airport",
        "WUT": "WUT | Xinzhou Wutaishan Airport",
        "YCU": "YCU | Yuncheng Guangong Airport",
        "TYN": "TYN | Taiyuan Wusu Airport",
        "RLK": "RLK | Bayannur Tianjitai Airport",
        "NZL": "NZL | Chengjisihan Airport",
        "ZDY": "ZDY | Delma Airport",
        "ZEN": "ZEN | Zenag Airport",
        "BHY": "BHY | Beihai Fucheng Airport",
        "CGD": "CGD | Changde Taohuayuan Airport",
        "HJJ": "HJJ | Huaihua Zhijiang Airport",
        "DYG": "DYG | Zhangjiajie Hehua International Airport",
        "CAN": "CAN | Guangzhou Baiyun International Airport",
        "CSX": "CSX | Changsha Huanghua International Airport",
        "HCJ": "HCJ | Hechi Jinchengjiang Airport",
        "SHF": "SHF | Huayuan Airport",
        "HNY": "HNY | Hengyang Nanyue Airport",
        "KWL": "KWL | Guilin Liangjiang International Airport",
        "LLF": "LLF | Yongzhou Lingling Airport",
        "MXZ": "MXZ | Meizhou Meixian Changgangji International Airport",
        "NNG": "NNG | Nanning Wuxu Airport",
        "SWA": "SWA | Jieyang Chaoshan International Airport",
        "ZUH": "ZUH | Zhuhai Jinwan Airport",
        "WGN": "WGN | Shaoyang Wugang Airport",
        "SZX": "SZX | Shenzhen Bao'an International Airport",
        "XIN": "XIN | Xingning Air Base",
        "YLX": "YLX | Yulin Fumian Airport",
        "LZH": "LZH | Liuzhou Bailian Airport / Bailian Air Base",
        "ZHA": "ZHA | Zhanjiang Xintang Airport",
        "AYN": "AYN | Anyang Airport",
        "CGO": "CGO | Zhengzhou Xinzheng International Airport",
        "ENH": "ENH | Enshi Airport",
        "LHK": "LHK | Guangzhou MR Air Base / Guanghua Airport",
        "WUH": "WUH | Wuhan Tianhe International Airport",
        "SHS": "SHS | Jingzhou Shashi Airport",
        "LYA": "LYA | Luoyang Airport",
        "NNY": "NNY | Nanyang Jiangying Airport",
        "HPG": "HPG | Shennongjia Hongping Airport",
        "WDS": "WDS | Shiyan Wudangshan Airport",
        "XFN": "XFN | Xiangyang Liuji Airport",
        "YIH": "YIH | Yichang Sanxia Airport",
        "ZHH": "ZHH | Herschel Island Field",
        "HAK": "HAK | Haikou Meilan International Airport",
        "BAR": "BAR | Qionghai Bo'ao Airport",
        "SYX": "SYX | Sanya Phoenix International Airport",
        "FNJ": "FNJ | Pyongyang Sunan International Airport",
        "DSO": "DSO | Sondok Airport",
        "WOS": "WOS | Wonsan Kalma International Airport",
        "AKA": "AKA | Ankang Fuqiang Airport",
        "DNH": "DNH | Dunhuang Airport",
        "HXD": "HXD | Delingha Airport",
        "GOQ": "GOQ | Golmud Airport",
        "GYU": "GYU | Guyuan Liupanshan Airport",
        "HTT": "HTT | Huatugou Airport",
        "HZG": "HZG | Hanzhong Chenggu Airport",
        "INC": "INC | Yinchuan Hedong International Airport",
        "JNG": "JNG | Jining Qufu Airport",
        "JGN": "JGN | Jiayuguan Airport",
        "LHW": "LHW | Lanzhou Zhongchuan Airport",
        "LNL": "LNL | Longnan Chengzhou Airport",
        "IQN": "IQN | Qingyang Xifeng Airport",
        "SIA": "SIA | Xi'an Xiguan Airport",
        "GXH": "GXH | Gannan Xiahe Airport",
        "XNN": "XNN | Xining Caojiabu Airport",
        "XIY": "XIY | Xi'an Xianyang International Airport",
        "UYN": "UYN | Yulin Yuyang Airport",
        "ZHY": "ZHY | Zhongwei Shapotou Airport",
        "AVK": "AVK | Arvaikheer Airport",
        "LTI": "LTI | Altai Airport",
        "BYN": "BYN | Bayankhongor Airport",
        "UGA": "UGA | Bulgan Airport",
        "UGT": "UGT | Bulagtai Resort Airport",
        "HBU": "HBU | Bulgan Sum Airport",
        "UUN": "UUN | Baruun Urt Airport",
        "COQ": "COQ | Choibalsan Airport",
        "UBN": "UBN | Ulaanbaatar Chinggis Khaan International Airport",
        "ZMD": "ZMD | Sena Madureira Airport",
        "ULZ": "ULZ | Donoi Airport",
        "DLZ": "DLZ | Dalanzadgad Airport",
        "KHR": "KHR | Kharkhorin Airport",
        "HJT": "HJT | Khujirt Airport",
        "HVD": "HVD | Khovd Airport",
        "MXV": "MXV | Mörön Airport",
        "TSZ": "TSZ | Tsetserleg Airport",
        "TNZ": "TNZ | Tosontsengel Airport",
        "ULN": "ULN | Buyant-Ukhaa International Airport",
        "ULO": "ULO | Ulaangom Airport",
        "ULG": "ULG | Ölgii Mongolei Airport",
        "ZNC": "ZNC | Nyac Airport",
        "ZNU": "ZNU | Namu Water Aerodrome",
        "CWJ": "CWJ | Cangyuan Washan Airport",
        "DLU": "DLU | Dali Huangcaoba Airport",
        "DIG": "DIG | Diqing Shangri-La Airport",
        "JHG": "JHG | Xishuangbanna Gasa Airport",
        "JMJ": "JMJ | Lancang Jingmai Airport",
        "LJG": "LJG | Lijiang Sanyi International Airport",
        "LUM": "LUM | Dehong Mangshi Airport",
        "KMG": "KMG | Kunming Changshui International Airport",
        "SYM": "SYM | Pu'er Simao Airport",
        "WNH": "WNH | Wenshan Puzhehei Airport",
        "ZAT": "ZAT | Zhaotong Airport",
        "XMN": "XMN | Xiamen Gaoqi International Airport",
        "AQG": "AQG | Anqing Tianzhushan Airport / Anqing North Air Base",
        "BFU": "BFU | Bengbu Airport",
        "CZX": "CZX | Changzhou Benniu Airport",
        "KHN": "KHN | Nanchang Changbei International Airport",
        "FUG": "FUG | Fuyang Xiguan Airport",
        "FOC": "FOC | Fuzhou Changle International Airport",
        "KOW": "KOW | Ganzhou Huangjin Airport",
        "HGH": "HGH | Hangzhou Xiaoshan International Airport",
        "JDZ": "JDZ | Jingdezhen Airport",
        "JIU": "JIU | Jiujiang Lushan Airport",
        "TNA": "TNA | Jinan Yaoqiang International Airport",
        "JUZ": "JUZ | Quzhou Airport",
        "LCX": "LCX | Longyan Guanzhishan Airport",
        "LYG": "LYG | Lianyungang Baitabu Airport / Baitabu Air Base",
        "HYN": "HYN | Taizhou Luqiao Airport",
        "LYI": "LYI | Linyi Qiyang Airport",
        "NGB": "NGB | Ningbo Lishe International Airport",
        "NKG": "NKG | Nanjing Lukou International Airport",
        "HFE": "HFE | Hefei Xinqiao International Airport",
        "PVG": "PVG | Shanghai Pudong International Airport",
        "JJN": "JJN | Quanzhou Jinjiang International Airport",
        "RUG": "RUG | Rugao Air Base",
        "RIZ": "RIZ | Rizhao Shanzihe Airport",
        "SHA": "SHA | Shanghai Hongqiao International Airport",
        "SZV": "SZV | Suzhou Guangfu Airport",
        "TXN": "TXN | Tunxi International Airport",
        "WEF": "WEF | Weifang Nanyuan Airport",
        "WEH": "WEH | Weihai Dashuibo Airport",
        "WHU": "WHU | Wuhu Wanli Airport / Wuhu Air Base",
        "WUX": "WUX | Sunan Shuofang International Airport",
        "WUS": "WUS | Nanping Wuyishan Airport",
        "WNZ": "WNZ | Wenzhou Longwan International Airport",
        "XUZ": "XUZ | Xuzhou Guanyin Airport / Xuzhou Daguozhang Air Base",
        "YTY": "YTY | Yangzhou Taizhou Airport",
        "YIC": "YIC | Yichun Mingyueshan Airport",
        "YNZ": "YNZ | Yancheng Airport",
        "YIW": "YIW | Yiwu Airport",
        "HSN": "HSN | Zhoushan Airport",
        "NGQ": "NGQ | Ngari Gunsa Airport",
        "AVA": "AVA | Anshun Huangguoshu Airport",
        "BPX": "BPX | Qamdo Bangda Airport",
        "BFJ": "BFJ | Bijie Feixiong Airport",
        "CKG": "CKG | Chongqing Jiangbei International Airport",
        "DCY": "DCY | Daocheng Yading Airport",
        "DAX": "DAX | Dachuan Airport",
        "GHN": "GHN | Guanghan Airport",
        "GYS": "GYS | Guangyuan Panlong Airport",
        "KWE": "KWE | Longdongbao Airport",
        "GZG": "GZG | Garze Gesar Airport",
        "JZH": "JZH | Jiuzhai Huanglong Airport",
        "KGT": "KGT | Kangding Airport",
        "KJH": "KJH | Kaili Airport",
        "LLB": "LLB | Libo Airport",
        "LIA": "LIA | Liangping Airport",
        "LXA": "LXA | Lhasa Gonggar Airport",
        "UNR": "UNR | Öndörkhaan Airport",
        "WMT": "WMT | Zunyi Maotai Airport",
        "MIG": "MIG | Mianyang Nanjiao Airport",
        "NAO": "NAO | Nanchong Gaoping Airport",
        "HZH": "HZH | Liping Airport",
        "LZY": "LZY | Nyingchi Airport",
        "LPF": "LPF | Liupanshui Yuezhao Airport",
        "TCZ": "TCZ | Tengchong Tuofeng Airport",
        "TFU": "TFU | Chengdu Tianfu International Airport",
        "TEN": "TEN | Tongren Fenghuang Airport",
        "CTU": "CTU | Chengdu Shuangliu International Airport",
        "WSK": "WSK | Chongqing Wushan Airport",
        "WXN": "WXN | Wanxian Airport",
        "XIC": "XIC | Xichang Qingshan Airport",
        "YBP": "YBP | Yibin Caiba Airport",
        "ACX": "ACX | Xingyi Wanfenglin Airport",
        "ZYI": "ZYI | Zunyi Xinzhou Airport",
        "AKU": "AKU | Aksu Onsu Airport",
        "BPL": "BPL | Bole Alashankou Airport",
        "IQM": "IQM | Qiemo Yudu Airport",
        "FYN": "FYN | Fuyun Koktokay Airport",
        "HMI": "HMI | Hami Airport",
        "KCA": "KCA | Kuqa Qiuci Airport",
        "KRL": "KRL | Korla Airport",
        "KRY": "KRY | Karamay Airport",
        "KJI": "KJI | Kanas Airport",
        "NLT": "NLT | Xinyuan Nalati Airport",
        "RQA": "RQA | Ruoqiang Loulan Airport",
        "QSZ": "QSZ | Shache Yeerqiang Airport",
        "KHG": "KHG | Kashgar Airport",
        "SXJ": "SXJ | Shanshan Airport",
        "TCG": "TCG | Tacheng Airport",
        "HTN": "HTN | Hotan Airport",
        "TLQ": "TLQ | Turpan Jiaohe Airport",
        "URC": "URC | Ürümqi Diwopu International Airport",
        "YIN": "YIN | Yining Airport",
        "YTW": "YTW | Yutian Wanfang Airport",
        "AOG": "AOG | Anshan Teng'ao Airport / Anshan Air Base",
        "CGQ": "CGQ | Longjia Airport",
        "CNI": "CNI | Changhai Airport",
        "CHG": "CHG | Chaoyang Airport",
        "DTU": "DTU | Wudalianchi Dedu Airport",
        "FYJ": "FYJ | Fuyuan Dongji Aiport",
        "HRB": "HRB | Harbin Taiping International Airport",
        "HEK": "HEK | Heihe Aihui Airport",
        "JIL": "JIL | Jilin Ertaizi Airport",
        "JMU": "JMU | Jiamusi Airport",
        "JSJ": "JSJ | Jiansanjiang Shidi Airport",
        "JXA": "JXA | Jixi Xingkaihu Airport",
        "LDS": "LDS | Lindu Airport",
        "YUS": "YUS | Yushu Batang Airport",
        "MDG": "MDG | Mudanjiang Hailang International Airport",
        "OHE": "OHE | Gu-Lian Airport",
        "NDG": "NDG | Qiqihar Sanjiazi Airport",
        "YSQ": "YSQ | Songyuan Chaganhu Airport",
        "DLC": "DLC | Dalian Zhoushuizi International Airport",
        "TNH": "TNH | Tonghua Sanyuanpu Airport",
        "SHE": "SHE | Shenyang Taoxian International Airport",
        "YNJ": "YNJ | Yanji Chaoyangchuan Airport",
        "YKH": "YKH | Yingkou Lanqi Airport"
    };

    return airports[iso];
}

const airportToIso = (iso: string) => {
    let airports: { [index: string]: string } = {
        "UTK | Utirik Airport": "UTK",
        "OCA | Ocean Reef Club Airport": "OCA",
        "PQS | Pilot Station Airport": "PQS",
        "CSE | Crested Butte Airpark": "CSE",
        "JCY | LBJ Ranch Airport": "JCY",
        "WLR | Loring Seaplane Base": "WLR",
        "NUP | Nunapitchuk Airport": "NUP",
        "PTC | Port Alice Seaplane Base": "PTC",
        "ICY | Icy Bay Airport": "ICY",
        "PPV | Port Protection Seaplane Base": "PPV",
        "KKK | Kalakaket Creek AS Airport": "KKK",
        "MHS | Dunsmuir Muni-Mott Airport": "MHS",
        "NIR | Chase Field Industrial Airport": "NIR",
        "GCT | Grand Canyon Bar Ten Airstrip": "GCT",
        "ELW | Ellamar Seaplane Base": "ELW",
        "LVD | Lime Village Airport": "LVD",
        "HGZ | Hog River Airport": "HGZ",
        "OTN | Ed-Air Airport": "OTN",
        "TLF | Telida Airport": "TLF",
        "BZT | Eagle Air Park": "BZT",
        "HBH | Entrance Island Seaplane Base": "HBH",
        "FAK | False Island Seaplane Base": "FAK",
        "BYW | Blakely Island Airport": "BYW",
        "DRF | Drift River Airport": "DRF",
        "BDF | Rinkenberger Restricted Landing Area": "BDF",
        "VRS | Roy Otten Memorial Airfield": "VRS",
        "GDH | Golden Horn Lodge Seaplane Base": "GDH",
        "ATT | Atmautluak Airport": "ATT",
        "LIV | Livengood Camp Airport": "LIV",
        "PDB | Pedro Bay Airport": "PDB",
        "KOZ | Ouzinkie Airport": "KOZ",
        "TNK | Tununak Airport": "TNK",
        "WHD | Hyder Seaplane Base": "WHD",
        "MNT | Minto Al Wright Airport": "MNT",
        "TKI | Tokeen Seaplane Base": "TKI",
        "WKK | Aleknagik / New Airport": "WKK",
        "WFB | Ketchikan Harbor Seaplane Base": "WFB",
        "NNK | Naknek Airport": "NNK",
        "BKF | Lake Brooks Seaplane Base": "BKF",
        "BCS | Southern Seaplane Airport": "BCS",
        "QNY | New York Skyports Inc Seaplane Base": "QNY",
        "BWL | Earl Henry Airport": "BWL",
        "WYB | Yes Bay Lodge Seaplane Base": "WYB",
        "CWS | Center Island Airport": "CWS",
        "TEK | Tatitlek Airport": "TEK",
        "DUF | Pine Island Airport": "DUF",
        "SSW | Stuart Island Airpark": "SSW",
        "FOB | Fort Bragg Airport": "FOB",
        "PUL | Port of Poulsbo Marina Moorage Seaplane Base": "PUL",
        "WMK | Meyers Chuck Seaplane Base": "WMK",
        "AXB | Maxson Airfield": "AXB",
        "REE | Reese Airpark": "REE",
        "WDN | Waldron Airstrip": "WDN",
        "WWP | Whale Pass Seaplane Float Harbor Facility": "WWP",
        "CHU | Chuathbaluk Airport": "CHU",
        "UGS | Ugashik Airport": "UGS",
        "PSQ | Philadelphia Seaplane Base": "PSQ",
        "KLL | Levelock Airport": "KLL",
        "SGW | Saginaw Seaplane Base": "SGW",
        "WTL | Tuntutuliak Airport": "WTL",
        "TWA | Twin Hills Airport": "TWA",
        "KCQ | Chignik Lake Airport": "KCQ",
        "AAD | Adado Airport": "AAD",
        "ABP | Atkamba Airport": "ABP",
        "ADC | Andakombe Airport": "ADC",
        "ADV | El Daein Airport": "ADV",
        "DJH | Jebel Ali Seaplane Base": "DJH",
        "AEE | Adareil Airport": "AEE",
        "AEK | Aseki Airport": "AEK",
        "AEQ | Ar Horqin Airport": "AEQ",
        "KHT | Khost International Airport": "KHT",
        "URZ | Orūzgān Airport": "URZ",
        "OLR | Salerno Airport": "OLR",
        "AFK | Kondavattavana Tank Seaplane Base": "AFK",
        "AFR | Afore Airstrip": "AFR",
        "AFT | Afutara Aerodrome": "AFT",
        "RNA | Ulawa Airport": "RNA",
        "ATD | Uru Harbour Airport": "ATD",
        "VEV | Barakoma Airport": "VEV",
        "GEF | Geva Airport": "GEF",
        "AGG | Angoram Airport": "AGG",
        "AKS | Gwaunaru'u Airport": "AKS",
        "BNY | Bellona/Anua Airport": "BNY",
        "CHY | Choiseul Bay Airport": "CHY",
        "BAS | Ballalae Airport": "BAS",
        "FRE | Fera/Maringe Airport": "FRE",
        "HIR | Honiara International Airport": "HIR",
        "MBU | Babanakira Airport": "MBU",
        "AVU | Avu Avu Airport": "AVU",
        "IRA | Ngorangora Airport": "IRA",
        "SCZ | Santa Cruz/Graciosa Bay/Luova Airport": "SCZ",
        "MUA | Munda Airport": "MUA",
        "GZO | Nusatupe Airport": "GZO",
        "MNY | Mono Airport": "MNY",
        "PRS | Parasi Airport": "PRS",
        "RNL | Rennell/Tingoa Airport": "RNL",
        "EGM | Sege Airport": "EGM",
        "NNB | Santa Ana Airport": "NNB",
        "RUS | Marau Airport": "RUS",
        "VAO | Suavanao Airport": "VAO",
        "XYA | Yandina Airport": "XYA",
        "AGK | Kagua Airport": "AGK",
        "KGE | Kaghau Airport": "KGE",
        "KUE | Kukudu Airport": "KUE",
        "KWS | Kwailabesi Airport": "KWS",
        "AGL | Wanigela Airport": "AGL",
        "NAZ | Nana Airport": "NAZ",
        "GTA | Gatokae Aerodrome": "GTA",
        "RIN | Ringi Cove Airport": "RIN",
        "RBV | Ramata Airport": "RBV",
        "AGY | Argyle Downs Airport": "AGY",
        "AHJ | Hongyuan Airport": "AHJ",
        "AHY | Ambatolhy Airport": "AHY",
        "AIE | Aiome Airport": "AIE",
        "AIH | Aiambak Airport": "AIH",
        "AIC | Ailinglaplap Airok Airport": "AIC",
        "CEX | Chena Hot Springs Airport": "CEX",
        "SOL | Solomon State Field": "SOL",
        "HED | Herendeen Bay Airport": "HED",
        "TWE | Taylor Airport": "TWE",
        "KTH | Tikchik Lodge Seaplane Base": "KTH",
        "NKI | Naukati Bay Seaplane Base": "NKI",
        "LNI | Point Lonely Short Range Radar Site Airfield": "LNI",
        "CDL | Candle 2 Airport": "CDL",
        "AOS | Amook Bay Seaplane Base": "AOS",
        "BSW | Boswell Bay Airport": "BSW",
        "AKM | Zakuoma Airport": "AKM",
        "TGE | Sharpe Field": "TGE",
        "ALZ | Alitak Seaplane Base": "ALZ",
        "PPE | Mar de Cortés International Airport": "PPE",
        "AME | Alto Molocue Airport": "AME",
        "AMF | Ama Airport": "AMF",
        "AMU | Amanab Airport": "AMU",
        "AMY | Ambatomainty Airport": "AMY",
        "INU | Nauru International Airport": "INU",
        "ANZ | Angus Downs Airport": "ANZ",
        "CTV | Catoca Airport": "CTV",
        "ANL | Andulo Airport": "ANL",
        "CNZ | Cangamba Airport": "CNZ",
        "DRC | Dirico Airport": "DRC",
        "JMB | Jamba Airport": "JMB",
        "KNP | Capanda Airport": "KNP",
        "NDF | N'dalatando Airport": "NDF",
        "AOB | Annanberg Airport": "AOB",
        "AOD | Abou-Deïa Airport": "AOD",
        "APP | Asapa Airport": "APP",
        "APR | April River Airport": "APR",
        "QET | Troll Airfield": "QET",
        "AQY | Girdwood Airport": "AQY",
        "QRF | Bragado Airport": "QRF",
        "CVI | Caleta Olivia Airport": "CVI",
        "CNT | Charata Airport": "CNT",
        "VGS | General Villegas Airport": "VGS",
        "LMD | Los Menucos Airport": "LMD",
        "VCF | Valcheta Airport": "VCF",
        "NCJ | Sunchales Aeroclub Airport": "NCJ",
        "CPG | Carmen de Patagones Airport": "CPG",
        "PRQ | Termal Airport": "PRQ",
        "OLN | Colonia Sarmiento Airport": "OLN",
        "ARP | Aragip Airport": "ARP",
        "ASZ | Asirim Airport": "ASZ",
        "ATN | Namatanai Airport": "ATN",
        "ATP | Aitape Airport": "ATP",
        "LYT | Lady Elliot Island Airstrip": "LYT",
        "AGW | Agnew Airport": "AGW",
        "AYD | Alroy Downs Airport": "AYD",
        "BYX | Baniyala Airport": "BYX",
        "COB | Coolibah Airport": "COB",
        "CRJ | Coorabie Airport": "CRJ",
        "CRY | Carlton Hill Airport": "CRY",
        "CSD | Cresswell Downs Airport": "CSD",
        "DYM | Diamantina Lakes Airport": "DYM",
        "HIS | Hayman Island Resort Seaplane Base": "HIS",
        "HLV | Helenvale Airport": "HLV",
        "KBD | Kimberley Downs Airport": "KBD",
        "KGR | Kulgera Airport": "KGR",
        "MWY | Miranda Downs Airport": "MWY",
        "MYO | Camballin Airport": "MYO",
        "OKB | Orchid Beach Airport": "OKB",
        "PEP | Peppimenarti Airport": "PEP",
        "RDA | Rockhampton Downs Airport": "RDA",
        "SSK | Sturt Creek Airport": "SSK",
        "SWB | Shaw River Airport": "SWB",
        "TPR | Tom Price Airport": "TPR",
        "TWP | Torwood Airport": "TWP",
        "ZVG | Springvale Airport": "ZVG",
        "AUI | Aua Island Airport": "AUI",
        "AUJ | Ambunti Airport": "AUJ",
        "AUP | Agaun Airport": "AUP",
        "AUV | Aumo Airport": "AUV",
        "AWE | Alowe Airport": "AWE",
        "AXF | Alxa Left Banner Bayanhot Airport": "AXF",
        "KPM | Kompiam Airport": "KPM",
        "BUA | Buka Airport": "BUA",
        "BRP | Biaru Airport": "BRP",
        "CMU | Chimbu Airport": "CMU",
        "MDM | Munduku Airport": "MDM",
        "KPF | Kondobol Airport": "KPF",
        "DNU | Dinangat Airport": "DNU",
        "DOI | Doini Airport": "DOI",
        "DAU | Daru Airport": "DAU",
        "EMS | Embessa Airport": "EMS",
        "XYR | Edwaki Airport": "XYR",
        "EPT | Eliptamin Airport": "EPT",
        "EGA | Engati Airstrip": "EGA",
        "EMO | Emo River Airstrip": "EMO",
        "ERU | Erume Airport": "ERU",
        "MFZ | Meselia Airport": "MFZ",
        "FRQ | Feramin Airport": "FRQ",
        "FAQ | Frieda River Airport": "FAQ",
        "FUM | Fuma Airport": "FUM",
        "GKA | Goroka Airport": "GKA",
        "GUG | Guari Airport": "GUG",
        "GRL | Garasa Airport": "GRL",
        "GUR | Gurney Airport": "GUR",
        "GAP | Gusap Airport": "GAP",
        "PNP | Girua Airport": "PNP",
        "GBC | Gasuke Airport": "GBC",
        "HBD | Habi Airport": "HBD",
        "HNI | Heiweni Airport": "HNI",
        "HNN | Honinabi Airport": "HNN",
        "HKN | Kimbe Airport": "HKN",
        "HIT | Haivaro Airport": "HIT",
        "IMN | Imane Airport": "IMN",
        "KGM | Kungim Airport": "KGM",
        "IMD | Imonda Airport": "IMD",
        "KIE | Aropa Airport": "KIE",
        "IAL | Ialibu Airport": "IAL",
        "WIU | Witu Airport": "WIU",
        "KGH | Yongai Airport": "KGH",
        "LSA | Losuia Airport": "LSA",
        "KPA | Kopiago Airport": "KPA",
        "UNG | Kiunga Airport": "UNG",
        "KNE | Kanainj Airport": "KNE",
        "KRI | Kikori Airport": "KRI",
        "KMA | Kerema Airport": "KMA",
        "KRX | Kar Kar Airport": "KRX",
        "KUQ | Kuri Airport": "KUQ",
        "KVG | Kavieng Airport": "KVG",
        "LNV | Londolovit Airport": "LNV",
        "LAB | Lab Lab Airport": "LAB",
        "LWI | Lowai Airport": "LWI",
        "LPN | Leron Plains Airport": "LPN",
        "LNG | Lese Airport": "LNG",
        "LSJ | Long Island Airport": "LSJ",
        "AYM | Yas Island Seaplane Base": "AYM",
        "MRM | Manari Airport": "MRM",
        "OBM | Morobe Airport": "OBM",
        "MAG | Madang Airport": "MAG",
        "HGU | Mount Hagen Kagamuga Airport": "HGU",
        "GUV | Mougulu Airport": "GUV",
        "MDU | Mendi Airport": "MDU",
        "MAS | Momote Airport": "MAS",
        "MXH | Moro Airport": "MXH",
        "MIS | Misima Island Airport": "MIS",
        "MWG | Marawaka Airport": "MWG",
        "NKN | Nankina Airport": "NKN",
        "GBF | Negarbo(Negabo) Airport": "GBF",
        "MFO | Manguna Airport": "MFO",
        "KSB | Kasonombe Airport": "KSB",
        "NMN | Nomane Airport": "NMN",
        "NBA | Nambaiyufa Airport": "NBA",
        "LAE | Nadzab Airport": "LAE",
        "KGB | Konge Airport": "KGB",
        "OKP | Oksapmin Airport": "OKP",
        "HOC | Komako Airport": "HOC",
        "KCJ | Komaio Airport": "KCJ",
        "KDE | Koroba Airport": "KDE",
        "OPB | Open Bay Airport": "OPB",
        "PGB | Pangoa Airport": "PGB",
        "PGN | Pangia Airport": "PGN",
        "MPF | Mapoda Airport": "MPF",
        "PMN | Pumani Airport": "PMN",
        "POM | Port Moresby Jacksons International Airport": "POM",
        "SPH | Sopu Airport": "SPH",
        "SXA | Sialum Airport": "SXA",
        "RMN | Rumginae Airport": "RMN",
        "KMR | Karimui Airport": "KMR",
        "MWI | Maramuni Airport": "MWI",
        "MRH | May River Airstrip": "MRH",
        "SBE | Suabi Airport": "SBE",
        "NIS | Simberi Airport": "NIS",
        "SIL | Sila Airport": "SIL",
        "SBV | Sabah Airport": "SBV",
        "SIM | Simbai Airport": "SIM",
        "SBC | Selbang Airport": "SBC",
        "SPV | Sepik Plains Airport": "SPV",
        "SXW | Sauren Airport": "SXW",
        "MBV | Masa Airport": "MBV",
        "TIZ | Tari Airport": "TIZ",
        "TBG | Tabubil Airport": "TBG",
        "TPI | Tapini Airport": "TPI",
        "RAB | Tokua Airport": "RAB",
        "TKW | Tekin Airport": "TKW",
        "TEP | Tep Tep Airport": "TEP",
        "TSW | Tsewi Airport": "TSW",
        "TRJ | Tarakbits Airport": "TRJ",
        "TWY | Tawa Airport": "TWY",
        "TKB | Tekadu Airport": "TKB",
        "AYU | Aiyura Airport": "AYU",
        "UMC | Umba Airport": "UMC",
        "URU | Uroubi Airport": "URU",
        "UPR | Upiara Airport": "UPR",
        "UVO | Uvol Airport": "UVO",
        "TLW | Talasea Airport": "TLW",
        "TCJ | Torembi Airport": "TCJ",
        "VAI | Vanimo Airport": "VAI",
        "TON | Tonu Airport": "TON",
        "WAO | Wabo Airport": "WAO",
        "WBM | Wapenamanda Airport": "WBM",
        "WAJ | Wawoi Falls Airport": "WAJ",
        "WWK | Wewak International Airport": "WWK",
        "WOA | Wonenara Airport": "WOA",
        "WKN | Wakunai Airport": "WKN",
        "WSU | Wasu Airport": "WSU",
        "WTP | Woitape Airport": "WTP",
        "WUG | Wau Airport": "WUG",
        "YVD | Yeva Airport": "YVD",
        "SMJ | Sim Airport": "SMJ",
        "WEP | Weam Airport": "WEP",
        "AYY | Arugam Bay SPB": "AYY",
        "KYX | Yalumet Airport": "KYX",
        "KSX | Yasuru Airport": "KSX",
        "WUM | Wasum Airport": "WUM",
        "ZXT | Zabrat Airport": "ZXT",
        "AZB | Amazon Bay Airport": "AZB",
        "AZJ | Dalu Airport": "AZJ",
        "BAJ | Bali Airport": "BAJ",
        "BKG | Branson Airport": "BKG",
        "BCP | Bambu Airport": "BCP",
        "BCW | Benguera Island Airport": "BCW",
        "BCZ | Milyakburra Airport": "BCZ",
        "ILL | Willmar Municipal -John L Rice Field": "ILL",
        "BDZ | Baindoung Airport": "BDZ",
        "HKV | Malevo Airport": "HKV",
        "JAM | Bezmer Air Base": "JAM",
        "JEG | Aasiaat Airport": "JEG",
        "UAK | Narsarsuaq Airport": "UAK",
        "CNP | Neerlerit Inaat Airport": "CNP",
        "GOH | Godthaab / Nuuk Airport": "GOH",
        "JAV | Ilulissat Airport": "JAV",
        "KUS | Kulusuk Airport": "KUS",
        "JSU | Maniitsoq Airport": "JSU",
        "JFR | Paamiut Airport": "JFR",
        "NAQ | Qaanaaq Airport": "NAQ",
        "SFJ | Kangerlussuaq Airport": "SFJ",
        "JHS | Sisimiut Airport": "JHS",
        "THU | Thule Air Base": "THU",
        "JUV | Upernavik Airport": "JUV",
        "JQA | Qaarsut Airport": "JQA",
        "BHL | Bahía de los Ángeles Airport": "BHL",
        "BHT | Brighton Downs Airport": "BHT",
        "AEY | Akureyri Airport": "AEY",
        "BIU | Bildudalur Airport": "BIU",
        "BGJ | Borgarfjörður eystri Airport": "BGJ",
        "BJD | Bakkafjörður Airport": "BJD",
        "BLO | Hjaltabakki Airport": "BLO",
        "BQD | Búðardalur Airport": "BQD",
        "BXV | Breiðdalsvík Airport": "BXV",
        "DJU | Djúpivogur Airport": "DJU",
        "EGS | Egilsstaðir Airport": "EGS",
        "FAS | Fáskrúðsfjörður Airport": "FAS",
        "FAG | Fagurhólsmýri Airport": "FAG",
        "GUU | Grundarfjörður Airport": "GUU",
        "GJR | Gjögur Airport": "GJR",
        "GRY | Grímsey Airport": "GRY",
        "HVK | Hólmavík Airport": "HVK",
        "HFN | Hornafjörður Airport": "HFN",
        "FLI | Holt Airport": "FLI",
        "HZK | Húsavík Airport": "HZK",
        "HVM | Krókstaðarmelar Airport": "HVM",
        "HLO | Ingjaldssanður Airport": "HLO",
        "IFJ | Ísafjörður Airport": "IFJ",
        "KEF | Keflavik International Airport": "KEF",
        "OPA | Kópasker Airport": "OPA",
        "SAK | Sauðárkrókur Airport": "SAK",
        "NOR | Norðfjörður Airport": "NOR",
        "OFJ | Ólafsfjörður Airport": "OFJ",
        "PFJ | Patreksfjörður Airport": "PFJ",
        "RHA | Reykhólar Airport": "RHA",
        "OLI | Rif Airport": "OLI",
        "RFN | Raufarhöfn Airport": "RFN",
        "RKV | Reykjavik Airport": "RKV",
        "MVA | Reykjahlíð Airport": "MVA",
        "SIJ | Siglufjörður Airport": "SIJ",
        "SYK | Stykkishólmur Airport": "SYK",
        "TEY | Þingeyri Airport": "TEY",
        "THO | Þórshöfn Airport": "THO",
        "VEY | Vestmannaeyjar Airport": "VEY",
        "VPN | Vopnafjörður Airport": "VPN",
        "BJE | Baleela Airport": "BJE",
        "BJQ | Bahja Airport": "BJQ",
        "BJT | Bentota River Waterdrome": "BJT",
        "PRN | Priština Adem Jashari International Airport": "PRN",
        "BMH | Bomai Airport": "BMH",
        "BMQ | Bamburi Airport": "BMQ",
        "BMZ | Bamu Airport": "BMZ",
        "BNF | Baranof Warm Springs Float and Seaplane Base": "BNF",
        "BNM | Bodinumu Airport": "BNM",
        "BNT | Bundi Airport": "BNT",
        "RBQ | Rurenabaque Airport": "RBQ",
        "BVL | Baures Airport": "BVL",
        "BOK | Brookings Airport": "BOK",
        "BOT | Bosset Airport": "BOT",
        "BOV | Boang Airport": "BOV",
        "BPF | Batuna Aerodrome": "BPF",
        "BQV | Bartlett Cove Seaplane Base": "BQV",
        "VDC | Glauber de Andrade Rocha Airport": "VDC",
        "ALT | Alenquer Airport": "ALT",
        "NSB | Bimini North Seaplane Base": "NSB",
        "BSP | Bensbach Airport": "BSP",
        "BSV | Besakoa Airport": "BSV",
        "BUL | Bulolo Airport": "BUL",
        "BVR | Esperadinha Airport": "BVR",
        "HUK | Hukuntsi Airport": "HUK",
        "BWJ | Bawan Airport": "BWJ",
        "BWP | Bewani Airport": "BWP",
        "BXL | Blue Lagoon Seaplane Base": "BXL",
        "BXZ | Bunsil Airport": "BXZ",
        "BYA | Boundary Airport": "BYA",
        "BYL | Bella Yella Airport": "BYL",
        "BYV | Beira Lake Seaplane Base": "BYV",
        "BCV | Hector Silva Airstrip": "BCV",
        "BGK | Big Creek Airport": "BGK",
        "CUK | Caye Caulker Airport": "CUK",
        "CYC | Caye Chapel Airport": "CYC",
        "CZH | Corozal Municipal Airport": "CZH",
        "DGA | Dangriga Airport": "DGA",
        "INB | Independence Airport": "INB",
        "MDB | Melinda Airport": "MDB",
        "ORZ | Orange Walk Airport": "ORZ",
        "PLJ | Placencia Airport": "PLJ",
        "PND | Punta Gorda Airport": "PND",
        "SJX | Sartaneja Airport": "SJX",
        "SPR | John Greif II Airport": "SPR",
        "SQS | Matthew Spain Airport": "SQS",
        "STU | Santa Cruz Airport": "STU",
        "SVK | Silver Creek Airport": "SVK",
        "TZA | Sir Barry Bowen Municipal Airport": "TZA",
        "BZB | Bazaruto Island Airport": "BZB",
        "BZM | Bemolanga Airport": "BZM",
        "YRR | Stuart Island Airstrip": "YRR",
        "YMV | Mary River Aerodrome": "YMV",
        "YBW | Bedwell Harbour Seaplane Base": "YBW",
        "YTP | Tofino Harbour Seaplane Base": "YTP",
        "ZNA | Nanaimo Harbour Water Airport": "ZNA",
        "YZZ | Trail Airport": "YZZ",
        "YMB | Merritt Airport": "YMB",
        "YHH | Campbell River Seaplane Base": "YHH",
        "YWS | Whistler/Green Lake Water Aerodrome": "YWS",
        "YBF | Bamfield Seaplane Base": "YBF",
        "YIG | Big Bay Seaplane Base": "YIG",
        "CJH | Chilko Lake (Tsylos Park Lodge) Airport": "CJH",
        "YPT | Pender Harbour Seaplane Base": "YPT",
        "ZOF | Ocean Falls Seaplane Base": "ZOF",
        "YCA | Courtenay Airpark": "YCA",
        "CFQ | Creston Valley Regional Airport - Art Sutcliffe Field": "CFQ",
        "YAA | Anahim Lake Airport": "YAA",
        "DGF | Douglas Lake Airport": "DGF",
        "JHL | Fort MacKay/Albian Aerodrome": "JHL",
        "ZTS | Tahsis Seaplane Base": "ZTS",
        "DUQ | Duncan Airport": "DUQ",
        "YHS | Sechelt-Gibsons Airport": "YHS",
        "YKK | Kitkatla Seaplane Base": "YKK",
        "WPL | Powell Lake Seaplane Base": "WPL",
        "XQU | Qualicum Beach Airport": "XQU",
        "YMP | Port Mcneill Airport": "YMP",
        "YRN | Rivers Inlet Seaplane Base": "YRN",
        "YTG | Sullivan Bay Seaplane Base": "YTG",
        "YMU | Mansons Landing Seaplane Base": "YMU",
        "YSX | Bella Bella/Shearwater Seaplane Base": "YSX",
        "YGG | Ganges Seaplane Base": "YGG",
        "YTB | Hartley Bay Seaplane Base": "YTB",
        "YZA | Cache Creek-Ashcroft Regional Airport": "YZA",
        "ZEL | Bella Bella (Campbell Island) Airport": "ZEL",
        "CBC | Cherrabun Airport": "CBC",
        "YPB | Alberni Valley Regional Airport": "YPB",
        "YBO | Bob Quinn Lake Airport": "YBO",
        "TNS | Tungsten (Cantung) Airport": "TNS",
        "TUX | Tumbler Ridge Airport": "TUX",
        "YWM | Williams Harbour Airport": "YWM",
        "YSO | Postville Airport": "YSO",
        "YBI | Black Tickle Airport": "YBI",
        "YFX | St. Lewis (Fox Harbour) Airport": "YFX",
        "YHA | Port Hope Simpson Airport": "YHA",
        "YRG | Rigolet Airport": "YRG",
        "DVK | Diavik Airport": "DVK",
        "JOJ | Doris Lake": "JOJ",
        "ZFW | Fairview Airport": "ZFW",
        "YJP | Hinton/Jasper-Hinton Airport": "YJP",
        "YLE | Whatì Airport": "YLE",
        "YDC | Drayton Valley Industrial Airport": "YDC",
        "NML | Fort McMurray / Mildred Lake Airport": "NML",
        "CFM | Conklin (Leismer) Airport": "CFM",
        "ZSP | St. Paul Airport": "ZSP",
        "GSL | Taltheilei Narrows Airport": "GSL",
        "XMP | Macmillan Pass Airport": "XMP",
        "DAS | Great Bear Lake Airport": "DAS",
        "YFI | Fort Mackay / Firebag": "YFI",
        "YFJ | Wekweètì Airport": "YFJ",
        "YOE | Donnelly Airport": "YOE",
        "TIL | Cheadle Airport": "TIL",
        "OKG | Okoyo Airport": "OKG",
        "CGA | Craig Seaplane Base": "CGA",
        "CGC | Cape Gloucester Airport": "CGC",
        "CGG | Casiguran Airport": "CGG",
        "GHK | Gahcho Kue": "GHK",
        "CHP | Circle Hot Springs Airport": "CHP",
        "CIV | Chomley Seaplane Base": "CIV",
        "LRQ | Laurie River Airport": "LRQ",
        "YDJ | Hatchet Lake Airport": "YDJ",
        "YDU | Kasba Lake Airport": "YDU",
        "YFL | Fort Reliance Seaplane Base": "YFL",
        "XCL | Cluff Lake Airport": "XCL",
        "YKE | Knee Lake Airport": "YKE",
        "SUR | Summer Beaver Airport": "SUR",
        "CKD | Crooked Creek Airport": "CKD",
        "YTT | Tisdale Airport": "YTT",
        "YAX | Wapekeka Airport": "YAX",
        "WNN | Wunnumin Lake Airport": "WNN",
        "YBS | Opapimiskan Lake Airport": "YBS",
        "YNO | North Spirit Lake Airport": "YNO",
        "CKR | Crane Island Airstrip": "CKR",
        "CKU | Cordova Municipal Airport": "CKU",
        "YDW | North of Sixty Airport": "YDW",
        "CKX | Chicken Airport": "CKX",
        "CMT | New Cametá Airport": "CMT",
        "CMZ | Caia Airport": "CMZ",
        "TVS | Tangshan Sannühe Airport": "TVS",
        "YUA | Yuanmou Air Base": "YUA",
        "ZQZ | Zhangjiakou Ningyuan Airport": "ZQZ",
        "BSD | Baoshan Yunrui Airport": "BSD",
        "DZU | Dazu Air Base": "DZU",
        "LNJ | Lincang Airport": "LNJ",
        "RKZ | Shigatse Air Base": "RKZ",
        "PZI | Bao'anying Airport": "PZI",
        "ZKL | Zigong Airport": "ZKL",
        "FUO | Foshan Shadi Airport": "FUO",
        "BZX | Bazhong Enyang Airport": "BZX",
        "XAI | Xinyang Minggang Airport": "XAI",
        "HUZ | Huizhou Pingtan Airport": "HUZ",
        "HSC | Shaoguan Guitou Airport": "HSC",
        "JGS | Jinggangshan Airport": "JGS",
        "AEB | Baise Youjiang Airport": "AEB",
        "DOY | Dongying Shengli Airport": "DOY",
        "XEN | Xingcheng Air Base": "XEN",
        "AAT | Altay Airport": "AAT",
        "THQ | Tianshui Maijishan Airport": "THQ",
        "YZY | Zhangye Ganzhou Airport": "YZY",
        "DWS | Morin Dawa Banner Airport": "DWS",
        "LCT | Shijiazhuang Luancheng Airport": "LCT",
        "NAY | Beijing Nanjiao Airport": "NAY",
        "DDG | Dandong Langtou Airport": "DDG",
        "NTG | Nantong Airport": "NTG",
        "XBE | Bearskin Lake Airport": "XBE",
        "YNP | Natuashish Airport": "YNP",
        "YWR | White River Seaplane Base": "YWR",
        "YPD | Parry Sound Area Municipal Airport": "YPD",
        "XBR | Brockville - Thousand Islands Regional Tackaberry Airport": "XBR",
        "KIF | Kingfisher Lake Airport": "KIF",
        "YOG | Ogoki Post Airport": "YOG",
        "ARQ | El Troncal Airport": "ARQ",
        "LCR | La Chorrera Airport": "LCR",
        "SNT | Las Cruces Airport": "SNT",
        "TCD | Tarapacá Airport": "TCD",
        "YEB | Bar River Airport": "YEB",
        "CPI | Cape Orford Airport": "CPI",
        "YSI | Parry Sound/Frying Pan Island-Sans Souci Seaplane Base": "YSI",
        "YHP | Poplar Hill Airport": "YHP",
        "KEW | Keewaywin Airport": "KEW",
        "KFM | Kirby Lake Airport": "KFM",
        "YSA | Sable Island Landing Strip": "YSA",
        "YLS | Lebel-sur-Quevillon Airport": "YLS",
        "YNX | Snap Lake Airport": "YNX",
        "SSQ | La Sarre Airport": "SSQ",
        "YKU | Chisasibi Airport": "YKU",
        "ZTB | Tête-à-la-Baleine Airport": "ZTB",
        "ZKG | Kegaska Airport": "ZKG",
        "YWQ | Chute-Des-Passes/Lac Margane Seaplane Base": "YWQ",
        "YAU | Donaldson Airport": "YAU",
        "TR7 | Ottawa / Rockcliffe Seaplane Base": "TR7",
        "ZGS | La Romaine Airport": "ZGS",
        "YFG | Fontanges Airport": "YFG",
        "ZLT | La Tabatière Airport": "ZLT",
        "CUJ | Culion Airport": "CUJ",
        "HLI | Hollister Municipal Airport": "HLI",
        "CVL | Cape Vogel Airport": "CVL",
        "CXC | Chitina Airport": "CXC",
        "GEC | Lefkoniko Airport / Geçitkale Air Base": "GEC",
        "YAB | Arctic Bay Airport": "YAB",
        "YAC | Cat Lake Airport": "YAC",
        "YAR | La Grande-3 Airport": "YAR",
        "YAG | Fort Frances Municipal Airport": "YAG",
        "YAH | La Grande-4 Airport": "YAH",
        "YAL | Alert Bay Airport": "YAL",
        "YAM | Sault Ste Marie Airport": "YAM",
        "XKS | Kasabonika Airport": "XKS",
        "YKG | Kangirsuk Airport": "YKG",
        "YAT | Attawapiskat Airport": "YAT",
        "YAY | St. Anthony Airport": "YAY",
        "YAZ | Tofino / Long Beach Airport": "YAZ",
        "YBA | Banff Airport": "YBA",
        "YBB | Kugaaruk Airport": "YBB",
        "YBC | Baie-Comeau Airport": "YBC",
        "QBC | Bella Coola Airport": "QBC",
        "YBE | Uranium City Airport": "YBE",
        "YBY | Bonnyville Airport": "YBY",
        "YBG | CFB Bagotville": "YBG",
        "YBK | Baker Lake Airport": "YBK",
        "YBL | Campbell River Airport": "YBL",
        "XTL | Tadoule Lake Airport": "XTL",
        "YBR | Brandon Municipal Airport": "YBR",
        "YBT | Brochet Airport": "YBT",
        "YBV | Berens River Airport": "YBV",
        "YBX | Lourdes de Blanc Sablon Airport": "YBX",
        "YRF | Cartwright Airport": "YRF",
        "YCB | Cambridge Bay Airport": "YCB",
        "YCC | Cornwall Regional Airport": "YCC",
        "YCD | Nanaimo Airport": "YCD",
        "YCE | Centralia / James T. Field Memorial Aerodrome": "YCE",
        "YCG | Castlegar/West Kootenay Regional Airport": "YCG",
        "YCH | Miramichi Airport": "YCH",
        "XCM | Chatham Kent Airport": "XCM",
        "YCL | Charlo Airport": "YCL",
        "YCN | Cochrane Airport": "YCN",
        "YCO | Kugluktuk Airport": "YCO",
        "YCQ | Chetwynd Airport": "YCQ",
        "YCR | Cross Lake (Charlie Sinclair Memorial) Airport": "YCR",
        "YCS | Chesterfield Inlet Airport": "YCS",
        "YCT | Coronation Airport": "YCT",
        "YCW | Chilliwack Airport": "YCW",
        "YCY | Clyde River Airport": "YCY",
        "YCZ | Fairmont Hot Springs Airport": "YCZ",
        "YDA | Dawson City Airport": "YDA",
        "YDB | Burwash Airport": "YDB",
        "YDF | Deer Lake Airport": "YDF",
        "YDL | Dease Lake Airport": "YDL",
        "XRR | Ross River Airport": "XRR",
        "YDN | Dauphin Barker Airport": "YDN",
        "YDO | Dolbeau St Felicien Airport": "YDO",
        "YDP | Nain Airport": "YDP",
        "YDQ | Dawson Creek Airport": "YDQ",
        "YEG | Edmonton International Airport": "YEG",
        "YEK | Arviat Airport": "YEK",
        "YEL | Elliot Lake Municipal Airport": "YEL",
        "YEM | Manitoulin East Municipal Airport": "YEM",
        "YEN | Estevan Airport": "YEN",
        "YER | Fort Severn Airport": "YER",
        "YET | Edson Airport": "YET",
        "YEU | Eureka Airport": "YEU",
        "YEV | Inuvik Mike Zubko Airport": "YEV",
        "YEY | Amos/Magny Airport": "YEY",
        "YFA | Fort Albany Airport": "YFA",
        "YFB | Iqaluit Airport": "YFB",
        "YFC | Fredericton Airport": "YFC",
        "YFE | Forestville Airport": "YFE",
        "YFH | Fort Hope Airport": "YFH",
        "YTM | La Macaza / Mont-Tremblant International Inc Airport": "YTM",
        "YFO | Flin Flon Airport": "YFO",
        "YFR | Fort Resolution Airport": "YFR",
        "YFS | Fort Simpson Airport": "YFS",
        "YMN | Makkovik Airport": "YMN",
        "YGB | Texada Gillies Bay Airport": "YGB",
        "YGH | Fort Good Hope Airport": "YGH",
        "YGK | Kingston Norman Rogers Airport": "YGK",
        "YGL | La Grande Rivière Airport": "YGL",
        "YGM | Gimli Industrial Park Airport": "YGM",
        "YGO | Gods Lake Narrows Airport": "YGO",
        "YGP | Gaspé (Michel-Pouliot) Airport": "YGP",
        "YGQ | Geraldton Greenstone Regional Airport": "YGQ",
        "YGR | Îles-de-la-Madeleine Airport": "YGR",
        "YGT | Igloolik Airport": "YGT",
        "YGV | Havre-Saint-Pierre Airport": "YGV",
        "YGW | Kuujjuarapik Airport": "YGW",
        "YGX | Gillam Airport": "YGX",
        "YGZ | Grise Fiord Airport": "YGZ",
        "YQC | Quaqtaq Airport": "YQC",
        "YHB | Hudson Bay Airport": "YHB",
        "CXH | Vancouver Harbour Water Aerodrome": "CXH",
        "YHD | Dryden Regional Airport": "YHD",
        "YHE | Hope Airport": "YHE",
        "YHF | Hearst René Fontaine Municipal Airport": "YHF",
        "YNS | Nemiscau Airport": "YNS",
        "YHI | Ulukhaktok Holman Airport": "YHI",
        "YHK | Gjoa Haven Airport": "YHK",
        "YHM | John C. Munro Hamilton International Airport": "YHM",
        "YHN | Hornepayne Municipal Airport": "YHN",
        "YHO | Hopedale Airport": "YHO",
        "YHR | Chevery Airport": "YHR",
        "YHT | Haines Junction Airport": "YHT",
        "YHU | Montréal / Saint-Hubert Airport": "YHU",
        "YHY | Hay River / Merlyn Carter Airport": "YHY",
        "YHZ | Halifax / Stanfield International Airport": "YHZ",
        "YIB | Atikokan Municipal Airport": "YIB",
        "YDG | Digby / Annapolis Regional Airport": "YDG",
        "YIF | St Augustin Airport": "YIF",
        "YIK | Ivujivik Airport": "YIK",
        "YIO | Pond Inlet Airport": "YIO",
        "YIV | Island Lake Airport": "YIV",
        "YJA | Jasper Airport": "YJA",
        "YJF | Fort Liard Airport": "YJF",
        "YJN | St Jean Airport": "YJN",
        "YJT | Stephenville Airport": "YJT",
        "YKA | Kamloops Airport": "YKA",
        "YKC | Collins Bay Airport": "YKC",
        "LAK | Aklavik/Freddie Carmichael Airport": "LAK",
        "YKF | Waterloo Airport": "YKF",
        "YWB | Kangiqsujuaq (Wakeham Bay) Airport": "YWB",
        "YKJ | Key Lake Airport": "YKJ",
        "YKL | Schefferville Airport": "YKL",
        "YKD | Kincardine Municipal Airport": "YKD",
        "AKV | Akulivik Airport": "AKV",
        "YKQ | Waskaganish Airport": "YKQ",
        "YKX | Kirkland Lake Airport": "YKX",
        "YKY | Kindersley Airport": "YKY",
        "YKZ | Buttonville Municipal Airport": "YKZ",
        "YPJ | Aupaluk Airport": "YPJ",
        "YLB | Lac La Biche Airport": "YLB",
        "YLC | Kimmirut Airport": "YLC",
        "YLD | Chapleau Airport": "YLD",
        "YLH | Lansdowne House Airport": "YLH",
        "YLJ | Meadow Lake Airport": "YLJ",
        "YSG | Lutselk'e Airport": "YSG",
        "YLL | Lloydminster Airport": "YLL",
        "YLQ | La Tuque Airport": "YLQ",
        "YLR | Leaf Rapids Airport": "YLR",
        "YLK | Barrie-Orillia (Lake Simcoe Regional Airport)": "YLK",
        "YLT | Alert Airport": "YLT",
        "XGR | Kangiqsualujjuaq (Georges River) Airport": "XGR",
        "YLW | Kelowna International Airport": "YLW",
        "CYM | Chatham Seaplane Base": "CYM",
        "YMA | Mayo Airport": "YMA",
        "YME | Matane Airport": "YME",
        "YMG | Manitouwadge Airport": "YMG",
        "YMH | Mary's Harbour Airport": "YMH",
        "YMJ | Moose Jaw Air Vice Marshal C. M. McEwen Airport": "YMJ",
        "YML | Charlevoix Airport": "YML",
        "YMM | Fort McMurray Airport": "YMM",
        "YMO | Moosonee Airport": "YMO",
        "YMT | Chapais Airport": "YMT",
        "YUD | Umiujaq Airport": "YUD",
        "YMW | Maniwaki Airport": "YMW",
        "YMX | Montreal International (Mirabel) Airport": "YMX",
        "YNA | Natashquan Airport": "YNA",
        "YNC | Wemindji Airport": "YNC",
        "YND | Ottawa / Gatineau Airport": "YND",
        "YNE | Norway House Airport": "YNE",
        "YNH | Hudsons Hope Airport": "YNH",
        "YLY | Langley Airport": "YLY",
        "YNL | Points North Landing Airport": "YNL",
        "YNM | Matagami Airport": "YNM",
        "HZP | Fort Mackay / Horizon Airport": "HZP",
        "YOA | Ekati Airport": "YOA",
        "YOC | Old Crow Airport": "YOC",
        "YOD | CFB Cold Lake": "YOD",
        "YOH | Oxford House Airport": "YOH",
        "YOJ | High Level Airport": "YOJ",
        "YOO | Toronto/Oshawa Executive Airport": "YOO",
        "YOP | Rainbow Lake Airport": "YOP",
        "YOS | Owen Sound / Billy Bishop Regional Airport": "YOS",
        "YOW | Ottawa Macdonald-Cartier International Airport": "YOW",
        "YPA | Prince Albert Glass Field": "YPA",
        "YPC | Paulatuk (Nora Aliqatchialuk Ruben) Airport": "YPC",
        "YPS | Port Hawkesbury Airport": "YPS",
        "YPE | Peace River Airport": "YPE",
        "YPG | Portage-la-Prairie / Southport Airport": "YPG",
        "YPH | Inukjuak Airport": "YPH",
        "YPL | Pickle Lake Airport": "YPL",
        "YPM | Pikangikum Airport": "YPM",
        "YPN | Port Menier Airport": "YPN",
        "YPO | Peawanuck Airport": "YPO",
        "YPQ | Peterborough Airport": "YPQ",
        "YPR | Prince Rupert Airport": "YPR",
        "YPW | Powell River Airport": "YPW",
        "YPX | Puvirnituq Airport": "YPX",
        "YPY | Fort Chipewyan Airport": "YPY",
        "YPZ | Burns Lake Airport": "YPZ",
        "YQA | Muskoka Airport": "YQA",
        "YQB | Quebec Jean Lesage International Airport": "YQB",
        "YQD | The Pas Airport": "YQD",
        "YQF | Red Deer Regional Airport": "YQF",
        "YQG | Windsor Airport": "YQG",
        "YQH | Watson Lake Airport": "YQH",
        "YQI | Yarmouth Airport": "YQI",
        "YQK | Kenora Airport": "YQK",
        "YQL | Lethbridge County Airport": "YQL",
        "YQM | Greater Moncton Roméo LeBlanc International Airport": "YQM",
        "YQN | Nakina Airport": "YQN",
        "YQQ | Comox Valley Airport / CFB Comox": "YQQ",
        "YQR | Regina International Airport": "YQR",
        "YQS | St Thomas Municipal Airport": "YQS",
        "YQT | Thunder Bay Airport": "YQT",
        "YQU | Grande Prairie Airport": "YQU",
        "YQV | Yorkton Municipal Airport": "YQV",
        "YQW | North Battleford Airport": "YQW",
        "YQX | Gander International Airport": "YQX",
        "YQY | Sydney / J.A. Douglas McCurdy Airport": "YQY",
        "YQZ | Quesnel Airport": "YQZ",
        "YRA | Rae Lakes Airport": "YRA",
        "YRB | Resolute Bay Airport": "YRB",
        "YRI | Rivière-du-Loup Airport": "YRI",
        "YRJ | Roberval Airport": "YRJ",
        "YRL | Red Lake Airport": "YRL",
        "YRM | Rocky Mountain House Airport": "YRM",
        "YRO | Ottawa / Rockcliffe Airport": "YRO",
        "YRQ | Trois-Rivières Airport": "YRQ",
        "YRS | Red Sucker Lake Airport": "YRS",
        "YRT | Rankin Inlet Airport": "YRT",
        "YRV | Revelstoke Airport": "YRV",
        "YSB | Sudbury Airport": "YSB",
        "YSC | Sherbrooke Airport": "YSC",
        "YSE | Squamish Airport": "YSE",
        "YSF | Stony Rapids Airport": "YSF",
        "YSH | Smiths Falls-Montague (Russ Beach) Airport": "YSH",
        "YSJ | Saint John Airport": "YSJ",
        "YSK | Sanikiluaq Airport": "YSK",
        "YSL | St Leonard Airport": "YSL",
        "YSM | Fort Smith Airport": "YSM",
        "YCM | Niagara District Airport": "YCM",
        "YSP | Marathon Airport": "YSP",
        "YST | St. Theresa Point Airport": "YST",
        "YSU | Summerside Airport": "YSU",
        "YSY | Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport": "YSY",
        "YTA | Pembroke Airport": "YTA",
        "YTE | Cape Dorset Airport": "YTE",
        "YTF | Alma Airport": "YTF",
        "YTH | Thompson Airport": "YTH",
        "YTL | Big Trout Lake Airport": "YTL",
        "YTQ | Tasiujaq Airport": "YTQ",
        "YTR | CFB Trenton": "YTR",
        "YTS | Timmins/Victor M. Power": "YTS",
        "YTZ | Billy Bishop Toronto City Centre Airport": "YTZ",
        "YUB | Tuktoyaktuk Airport": "YUB",
        "YUL | Montreal / Pierre Elliott Trudeau International Airport": "YUL",
        "YUT | Naujaat Airport": "YUT",
        "YUX | Hall Beach Airport": "YUX",
        "YUY | Rouyn Noranda Airport": "YUY",
        "YVB | Bonaventure Airport": "YVB",
        "YVC | La Ronge Airport": "YVC",
        "YVG | Vermilion Airport": "YVG",
        "YVE | Vernon Airport": "YVE",
        "YCK | Tommy Kochon Airport": "YCK",
        "YVM | Qikiqtarjuaq Airport": "YVM",
        "YVO | Val-d'Or Airport": "YVO",
        "YVP | Kuujjuaq Airport": "YVP",
        "YVQ | Norman Wells Airport": "YVQ",
        "YVR | Vancouver International Airport": "YVR",
        "YVT | Buffalo Narrows Airport": "YVT",
        "YVV | Wiarton Airport": "YVV",
        "YVZ | Deer Lake Airport": "YVZ",
        "YWA | Petawawa Airport": "YWA",
        "YWG | Winnipeg / James Armstrong Richardson International Airport": "YWG",
        "YWH | Victoria Harbour Seaplane Base": "YWH",
        "YWJ | Déline Airport": "YWJ",
        "YWK | Wabush Airport": "YWK",
        "YWL | Williams Lake Airport": "YWL",
        "YWP | Webequie Airport": "YWP",
        "YWY | Wrigley Airport": "YWY",
        "YXC | Cranbrook/Canadian Rockies International Airport": "YXC",
        "YXE | Saskatoon John G. Diefenbaker International Airport": "YXE",
        "YXH | Medicine Hat Airport": "YXH",
        "YXJ | Fort St John Airport": "YXJ",
        "YXK | Rimouski Airport": "YXK",
        "YXL | Sioux Lookout Airport": "YXL",
        "YXN | Whale Cove Airport": "YXN",
        "YXP | Pangnirtung Airport": "YXP",
        "YXQ | Beaver Creek Airport": "YXQ",
        "YXR | Earlton (Timiskaming Regional) Airport": "YXR",
        "YXS | Prince George Airport": "YXS",
        "YXT | Northwest Regional Airport Terrace-Kitimat": "YXT",
        "YXU | London Airport": "YXU",
        "YXX | Abbotsford International Airport": "YXX",
        "YXY | Whitehorse / Erik Nielsen International Airport": "YXY",
        "YXZ | Wawa Airport": "YXZ",
        "YYB | North Bay Jack Garland Airport": "YYB",
        "YYC | Calgary International Airport": "YYC",
        "YYD | Smithers Airport": "YYD",
        "YYE | Fort Nelson Airport": "YYE",
        "YYF | Penticton Airport": "YYF",
        "YYG | Charlottetown Airport": "YYG",
        "YYH | Taloyoak Airport": "YYH",
        "YYJ | Victoria International Airport": "YYJ",
        "YYL | Lynn Lake Airport": "YYL",
        "YYM | Cowley Airport": "YYM",
        "YYN | Swift Current Airport": "YYN",
        "YYQ | Churchill Airport": "YYQ",
        "YYR | Goose Bay Airport": "YYR",
        "YYT | St. John's International Airport": "YYT",
        "YYU | Kapuskasing Airport": "YYU",
        "YYW | Armstrong Airport": "YYW",
        "YYY | Mont Joli Airport": "YYY",
        "YYZ | Lester B. Pearson International Airport": "YYZ",
        "YZE | Gore Bay Manitoulin Airport": "YZE",
        "YZF | Yellowknife International Airport": "YZF",
        "YZG | Salluit Airport": "YZG",
        "YZH | Slave Lake Airport": "YZH",
        "YZP | Sandspit Airport": "YZP",
        "YZR | Chris Hadfield Airport": "YZR",
        "YZS | Coral Harbour Airport": "YZS",
        "YZT | Port Hardy Airport": "YZT",
        "YZU | Whitecourt Airport": "YZU",
        "YZV | Sept-Îles Airport": "YZV",
        "YZW | Teslin Airport": "YZW",
        "YZX | CFB Greenwood": "YZX",
        "ZAC | York Landing Airport": "ZAC",
        "YSN | Shuswap Regional Airport": "YSN",
        "YDT | Boundary Bay Airport": "YDT",
        "ILF | Ilford Airport": "ILF",
        "ZBF | Bathurst Airport": "ZBF",
        "ZBM | Bromont (Roland Desourdy) Airport": "ZBM",
        "KES | Kelsey Airport": "KES",
        "ZEM | Eastmain River Airport": "ZEM",
        "ZFA | Faro Airport": "ZFA",
        "ZFD | Fond-Du-Lac Airport": "ZFD",
        "XPK | Pukatawagan Airport": "XPK",
        "ZFM | Fort Mcpherson Airport": "ZFM",
        "ZFN | Tulita Airport": "ZFN",
        "ZGF | Grand Forks Airport": "ZGF",
        "ZGI | Gods River Airport": "ZGI",
        "ZGR | Little Grand Rapids Airport": "ZGR",
        "ZHP | High Prairie Airport": "ZHP",
        "CZJ | Corazón de Jesús Airport": "CZJ",
        "ZJG | Jenpeg Airport": "ZJG",
        "ZJN | Swan River Airport": "ZJN",
        "CZK | Cascade Locks State Airport": "CZK",
        "ZKE | Kashechewan Airport": "ZKE",
        "YTD | Thicket Portage Airport": "YTD",
        "MSA | Muskrat Dam Airport": "MSA",
        "ZMH | South Cariboo Region / 108 Mile Airport": "ZMH",
        "PIW | Pikwitonei Airport": "PIW",
        "ZMT | Masset Airport": "ZMT",
        "CZN | Chisana Airport": "CZN",
        "XPP | Poplar River Airport": "XPP",
        "CZO | Chistochina Airport": "CZO",
        "ZPB | Sachigo Lake Airport": "ZPB",
        "WPC | Pincher Creek Airport": "WPC",
        "ZPO | Pinehouse Lake Airport": "ZPO",
        "ZRJ | Round Lake (Weagamow Lake) Airport": "ZRJ",
        "ZSJ | Sandy Lake Airport": "ZSJ",
        "XSI | South Indian Lake Airport": "XSI",
        "ZST | Stewart Airport": "ZST",
        "ZSW | Prince Rupert/Seal Cove Seaplane Base": "ZSW",
        "YDV | Bloodvein River Airport": "YDV",
        "ZTM | Shamattawa Airport": "ZTM",
        "ZUC | Ignace Municipal Airport": "ZUC",
        "ZUM | Churchill Falls Airport": "ZUM",
        "XLB | Lac Brochet Airport": "XLB",
        "ZWL | Wollaston Lake Airport": "ZWL",
        "IUA | Canandaigua Airport": "IUA",
        "DJN | Delta Junction Airport": "DJN",
        "MQV | Mostaganem Airport": "MQV",
        "QLD | Blida Airport": "QLD",
        "BUJ | Bou Saada Airport": "BUJ",
        "BJA | Soummam – Abane Ramdane Airport": "BJA",
        "ALG | Houari Boumediene Airport": "ALG",
        "DJG | Djanet Inedbirene Airport": "DJG",
        "VVZ | Illizi Takhamalt Airport": "VVZ",
        "QSF | Ain Arnat Airport": "QSF",
        "TMR | Aguenar – Hadj Bey Akhamok Airport": "TMR",
        "GJL | Jijel Ferhat Abbas Airport": "GJL",
        "MZW | Mecheria Airport": "MZW",
        "AAE | Rabah Bitat Airport": "AAE",
        "CZL | Mohamed Boudiaf International Airport": "CZL",
        "QMH | Oum El Bouaghi Air Base": "QMH",
        "TEE | Cheikh Larbi Tébessi Airport": "TEE",
        "BLJ | Batna Mostefa Ben Boulaid Airport": "BLJ",
        "DAF | Daup Airport": "DAF",
        "HRM | Hassi R'Mel Airport": "HRM",
        "QDJ | Tsletsi Airport": "QDJ",
        "DAH | Dathina Airport": "DAH",
        "DAO | Dabo Airport": "DAO",
        "TID | Abdelhafid Boussouf Bou Chekif Airport": "TID",
        "TIN | Tindouf Airport": "TIN",
        "CFK | Chlef Aboubakr Belkaid International Airport": "CFK",
        "TAF | Oran Tafraoui Airport": "TAF",
        "TLM | Zenata – Messali El Hadj Airport": "TLM",
        "ORN | Oran International Airport Ahmed Ben Bella": "ORN",
        "CBH | Béchar Boudghene Ben Ali Lotfi Airport": "CBH",
        "BFW | Sidi Bel Abbes Airport": "BFW",
        "MUW | Ghriss Airport": "MUW",
        "EBH | El Bayadh Airport": "EBH",
        "INF | In Guezzam Airport": "INF",
        "BMW | Bordj Badji Mokhtar Airport": "BMW",
        "AZR | Touat Cheikh Sidi Mohamed Belkebir Airport": "AZR",
        "BSK | Biskra - Mohamed Khider Airport": "BSK",
        "ELG | El Golea Airport": "ELG",
        "GHA | Noumérat - Moufdi Zakaria Airport": "GHA",
        "HME | Hassi Messaoud-Oued Irara Krim Belkacem Airport": "HME",
        "INZ | In Salah Airport": "INZ",
        "TGR | Touggourt Sidi Madhi Airport": "TGR",
        "LOO | Laghouat - Molay Ahmed Medeghri Airport": "LOO",
        "ELU | Guemar Airport": "ELU",
        "TMX | Timimoun Airport": "TMX",
        "OGX | Ain Beida Airport": "OGX",
        "IAM | Zarzaitine - In Aménas Airport": "IAM",
        "COO | Cadjehoun Airport": "COO",
        "DJA | Djougou Airport": "DJA",
        "KDC | Kandi Airport": "KDC",
        "NAE | Natitingou Airport": "NAE",
        "PKO | Parakou Airport": "PKO",
        "SVF | Savé Airport": "SVF",
        "DBC | Baicheng Chang'an Airport": "DBC",
        "DBK | Dutch Bay SPB": "DBK",
        "DBP | Debepare Airport": "DBP",
        "DBU | Dambulu Oya Tank Seaplane Base": "DBU",
        "DCG | Dubai Creek SPB": "DCG",
        "DCK | Dahl Creek Airport": "DCK",
        "DDM | Dodoima Airport": "DDM",
        "DEQ | Deqing Moganshan Airport": "DEQ",
        "DER | Derim Airport": "DER",
        "DEX | Nop Goliat Airport": "DEX",
        "XKY | Kaya Airport": "XKY",
        "OUG | Ouahigouya Airport": "OUG",
        "XDJ | Djibo Airport": "XDJ",
        "XLU | Leo Airport": "XLU",
        "PUP | Po Airport": "PUP",
        "XBO | Boulsa Airport": "XBO",
        "XBG | Bogande Airport": "XBG",
        "DIP | Diapaga Airport": "DIP",
        "DOR | Dori Airport": "DOR",
        "FNG | Fada N'gourma Airport": "FNG",
        "XGG | Gorom-Gorom Airport": "XGG",
        "XKA | Kantchari Airport": "XKA",
        "TMQ | Tambao Airport": "TMQ",
        "XPA | Pama Airport": "XPA",
        "ARL | Arly Airport": "ARL",
        "XSE | Sebba Airport": "XSE",
        "TEG | Tenkodogo Airport": "TEG",
        "XZA | Zabré Airport": "XZA",
        "OUA | Ouagadougou Airport": "OUA",
        "BNR | Banfora Airport": "BNR",
        "DGU | Dedougou Airport": "DGU",
        "XGA | Gaoua Airport": "XGA",
        "XNU | Nouna Airport": "XNU",
        "BOY | Bobo Dioulasso Airport": "BOY",
        "TUQ | Tougan Airport": "TUQ",
        "XDE | Diebougou Airport": "XDE",
        "XAR | Aribinda Airport": "XAR",
        "ACC | Kotoka International Airport": "ACC",
        "TML | Tamale Airport": "TML",
        "DGM | Dandugama Seaplane Base": "DGM",
        "KMS | Kumasi Airport": "KMS",
        "NYI | Sunyani Airport": "NYI",
        "TKD | Takoradi Airport": "TKD",
        "DHB | Deer Harbor SPB": "DHB",
        "DHG | Dalnegorsk Airport": "DHG",
        "DHL | Ad-Dali Airport": "DHL",
        "ABO | Aboisso Airport": "ABO",
        "ABJ | Félix-Houphouët-Boigny International Airport": "ABJ",
        "OGO | Abengourou Airport": "OGO",
        "BXI | Boundiali Airport": "BXI",
        "BYK | Bouaké Airport": "BYK",
        "BQO | Bouna Airport": "BQO",
        "BDK | Soko Airport": "BDK",
        "DIM | Dimbokro Airport": "DIM",
        "DJO | Daloa Airport": "DJO",
        "FEK | Ferkessedougou Airport": "FEK",
        "GGN | Gagnoa Airport": "GGN",
        "GGO | Guiglo Airport": "GGO",
        "BBV | Nero-Mer Airport": "BBV",
        "HGO | Korhogo Airport": "HGO",
        "MJC | Man Airport": "MJC",
        "KEO | Odienne Airport": "KEO",
        "OFI | Ouango Fitini Airport": "OFI",
        "SEO | Seguela Airport": "SEO",
        "SPY | San Pedro Airport": "SPY",
        "ZSS | Sassandra Airport": "ZSS",
        "TXU | Tabou Airport": "TXU",
        "TOZ | Mahana Airport": "TOZ",
        "DIW | Mawella Lagoon Seaplane Base": "DIW",
        "ASK | Yamoussoukro Airport": "ASK",
        "DLR | Dalnerechensk Airport": "DLR",
        "DKA | Katsina Airport": "DKA",
        "ABV | Nnamdi Azikiwe International Airport": "ABV",
        "QUO | Akwa Ibom International Airport": "QUO",
        "AKR | Akure Airport": "AKR",
        "ABB | Asaba International Airport": "ABB",
        "BCU | Sir Abubakar Tafawa Balewa International Airport": "BCU",
        "BNI | Benin Airport": "BNI",
        "CBQ | Margaret Ekpo International Airport": "CBQ",
        "ENU | Akanu Ibiam International Airport": "ENU",
        "IBA | Ibadan Airport": "IBA",
        "ILR | Ilorin International Airport": "ILR",
        "QOW | Sam Mbakwe International Airport": "QOW",
        "JOS | Yakubu Gowon Airport": "JOS",
        "KAD | Kaduna Airport": "KAD",
        "KAN | Mallam Aminu International Airport": "KAN",
        "MIU | Maiduguri International Airport": "MIU",
        "MDI | Makurdi Airport": "MDI",
        "LOS | Murtala Muhammed International Airport": "LOS",
        "MXJ | Minna Airport": "MXJ",
        "PHC | Port Harcourt International Airport": "PHC",
        "SKO | Sadiq Abubakar III International Airport": "SKO",
        "YOL | Yola Airport": "YOL",
        "ZAR | Zaria Airport": "ZAR",
        "DOO | Dorobisoro Airport": "DOO",
        "DPT | Deputatskiy Airport": "DPT",
        "DQA | Saertu Airport": "DQA",
        "MFQ | Maradi Airport": "MFQ",
        "NIM | Diori Hamani International Airport": "NIM",
        "THZ | Tahoua Airport": "THZ",
        "AJY | Mano Dayak International Airport": "AJY",
        "RLT | Arlit Airport": "RLT",
        "ZND | Zinder Airport": "ZND",
        "DSG | Dilasag Airport": "DSG",
        "TBJ | Tabarka 7 Novembre Airport": "TBJ",
        "MIR | Monastir Habib Bourguiba International Airport": "MIR",
        "TUN | Tunis Carthage International Airport": "TUN",
        "GAF | Gafsa Ksar International Airport": "GAF",
        "GAE | Gabès Matmata International Airport": "GAE",
        "DJE | Djerba Zarzis International Airport": "DJE",
        "EBM | El Borma Airport": "EBM",
        "SFA | Sfax Thyna International Airport": "SFA",
        "TOE | Tozeur Nefta International Airport": "TOE",
        "DVD | Andavadoaka Airport": "DVD",
        "DWO | Diyawanna Oya Seaplane Base": "DWO",
        "DWR | Dwyer Airport": "DWR",
        "LRL | Niamtougou International Airport": "LRL",
        "LFW | Lomé–Tokoin International Airport": "LFW",
        "EAL | Elenak Airport": "EAL",
        "ANR | Antwerp International Airport (Deurne)": "ANR",
        "BRU | Brussels Airport": "BRU",
        "CRL | Brussels South Charleroi Airport": "CRL",
        "KJK | Wevelgem Airport": "KJK",
        "LGG | Liège Airport": "LGG",
        "EBO | Ebon Airport": "EBO",
        "OST | Ostend-Bruges International Airport": "OST",
        "MZD | Méndez Airport": "MZD",
        "EDA | Edna Bay Seaplane Base": "EDA",
        "AOC | Altenburg-Nobitz Airport": "AOC",
        "HDF | Heringsdorf Airport": "HDF",
        "IES | Riesa-Göhlis Airport": "IES",
        "REB | Rechlin-Lärz Airport": "REB",
        "CSO | Cochstedt Airport": "CSO",
        "BBH | Barth Airport": "BBH",
        "ZMG | Magdeburg City Airport": "ZMG",
        "FNB | Neubrandenburg Airport": "FNB",
        "GTI | Flugplatz Güttin / Rügen": "GTI",
        "KOQ | Köthen Airport": "KOQ",
        "PEF | Peenemünde Airport": "PEF",
        "BER | Berlin Brandenburg Airport": "BER",
        "DRS | Dresden Airport": "DRS",
        "ERF | Erfurt Airport": "ERF",
        "FRA | Frankfurt am Main Airport": "FRA",
        "FMO | Münster Osnabrück Airport": "FMO",
        "HAM | Hamburg Helmut Schmidt Airport": "HAM",
        "CGN | Cologne Bonn Airport": "CGN",
        "DUS | Düsseldorf Airport": "DUS",
        "MUC | Munich Airport": "MUC",
        "NUE | Nuremberg Airport": "NUE",
        "LEJ | Leipzig/Halle Airport": "LEJ",
        "SCN | Saarbrücken Airport": "SCN",
        "STR | Stuttgart Airport": "STR",
        "HAJ | Hannover Airport": "HAJ",
        "BRE | Bremen Airport": "BRE",
        "HHN | Frankfurt-Hahn Airport": "HHN",
        "MHG | Mannheim-City Airport": "MHG",
        "EIB | Eisenach-Kindel Airport": "EIB",
        "SGE | Siegerland Airport": "SGE",
        "XFW | Hamburg-Finkenwerder Airport": "XFW",
        "KEL | Kiel-Holtenau Airport": "KEL",
        "LBC | Lübeck Blankensee Airport": "LBC",
        "EUM | Neumünster Airport": "EUM",
        "FMM | Memmingen Allgau Airport": "FMM",
        "AAH | Aachen-Merzbrück Airport": "AAH",
        "BNJ | Bonn-Hangelar Airport": "BNJ",
        "ESS | Essen Mülheim Airport": "ESS",
        "BFE | Bielefeld Airport": "BFE",
        "MGL | Mönchengladbach Airport": "MGL",
        "PAD | Paderborn Lippstadt Airport": "PAD",
        "NRN | Weeze Airport": "NRN",
        "DTM | Dortmund Airport": "DTM",
        "AGB | Augsburg Airport": "AGB",
        "OBF | Oberpfaffenhofen Airport": "OBF",
        "RBM | Straubing Airport": "RBM",
        "FDH | Friedrichshafen Airport": "FDH",
        "SZW | Schwerin Parchim Airport": "SZW",
        "BYU | Bayreuth Airport": "BYU",
        "URD | Burg Feuerstein Airport": "URD",
        "GHF | Giebelstadt Airport": "GHF",
        "HOQ | Hof-Plauen Airport": "HOQ",
        "BBJ | Bitburg Airport": "BBJ",
        "ZQW | Zweibrücken Airport": "ZQW",
        "FKB | Karlsruhe Baden-Baden Airport": "FKB",
        "LHA | Lahr Airport": "LHA",
        "BWE | Braunschweig-Wolfsburg Airport": "BWE",
        "KSF | Kassel-Calden Airport": "KSF",
        "EME | Emden Airport": "EME",
        "AGE | Wangerooge Airport": "AGE",
        "WVN | Wilhelmshaven-Mariensiel Airport": "WVN",
        "JUI | Juist Airport": "JUI",
        "LGO | Langeoog Airport": "LGO",
        "BMK | Borkum Airport": "BMK",
        "NOD | Norden-Norddeich Airport": "NOD",
        "VAC | Varrelbusch Airport": "VAC",
        "NRD | Norderney Airport": "NRD",
        "BMR | Baltrum Airport": "BMR",
        "HEI | Heide-Büsum Airport": "HEI",
        "FLF | Flensburg-Schäferhaus Airport": "FLF",
        "HGL | Helgoland-Düne Airport": "HGL",
        "QHU | Husum-Schwesing Airport": "QHU",
        "PSH | St. Peter-Ording Airport": "PSH",
        "GWT | Westerland Sylt Airport": "GWT",
        "OHR | Wyk auf Föhr Airport": "OHR",
        "KDL | Kärdla Airport": "KDL",
        "URE | Kuressaare Airport": "URE",
        "EPU | Pärnu Airport": "EPU",
        "TLL | Lennart Meri Tallinn Airport": "TLL",
        "TAY | Tartu Airport": "TAY",
        "ENF | Enontekio Airport": "ENF",
        "EFG | Efogi Airport": "EFG",
        "KEV | Halli Airport": "KEV",
        "HEL | Helsinki Vantaa Airport": "HEL",
        "HYV | Hyvinkää Airfield": "HYV",
        "KTQ | Kitee Airport": "KTQ",
        "IVL | Ivalo Airport": "IVL",
        "JOE | Joensuu Airport": "JOE",
        "JYV | Jyväskylä Airport": "JYV",
        "KAU | Kauhava Airfield": "KAU",
        "KEM | Kemi-Tornio Airport": "KEM",
        "KAJ | Kajaani Airport": "KAJ",
        "KHJ | Kauhajoki Airfield": "KHJ",
        "KOK | Kokkola-Pietarsaari Airport": "KOK",
        "KAO | Kuusamo Airport": "KAO",
        "KTT | Kittilä Airport": "KTT",
        "KUO | Kuopio Airport": "KUO",
        "LPP | Lappeenranta Airport": "LPP",
        "MHQ | Mariehamn Airport": "MHQ",
        "MIK | Mikkeli Airport": "MIK",
        "OUL | Oulu Airport": "OUL",
        "POR | Pori Airport": "POR",
        "RVN | Rovaniemi Airport": "RVN",
        "SVL | Savonlinna Airport": "SVL",
        "SJY | Seinäjoki Airport": "SJY",
        "SOT | Sodankyla Airport": "SOT",
        "TMP | Tampere-Pirkkala Airport": "TMP",
        "TKU | Turku Airport": "TKU",
        "UTI | Utti Air Base": "UTI",
        "VAA | Vaasa Airport": "VAA",
        "VRK | Varkaus Airport": "VRK",
        "YLI | Ylivieska Airfield": "YLI",
        "AUE | Abu Rudeis Airport": "AUE",
        "BFS | Belfast International Airport": "BFS",
        "ENK | Enniskillen/St Angelo Airport": "ENK",
        "BHD | George Best Belfast City Airport": "BHD",
        "LDY | City of Derry Airport": "LDY",
        "BHX | Birmingham International Airport": "BHX",
        "CVT | Coventry Airport": "CVT",
        "GLO | Gloucestershire Airport": "GLO",
        "ORM | Sywell Aerodrome": "ORM",
        "NQT | Nottingham Airport": "NQT",
        "GBA | Cotswold Airport": "GBA",
        "MAN | Manchester Airport": "MAN",
        "DSA | Robin Hood Doncaster Sheffield Airport": "DSA",
        "UPV | Upavon Aerodrome": "UPV",
        "YEO | RNAS Yeovilton": "YEO",
        "CAL | Campbeltown Airport": "CAL",
        "EOI | Eday Airport": "EOI",
        "FIE | Fair Isle Airport": "FIE",
        "WHS | Whalsay Airport": "WHS",
        "COL | Coll Airport": "COL",
        "NRL | North Ronaldsay Airport": "NRL",
        "OBN | Oban Airport": "OBN",
        "PPW | Papa Westray Airport": "PPW",
        "SOY | Stronsay Airport": "SOY",
        "NDY | Sanday Airport": "NDY",
        "LWK | Lerwick / Tingwall Airport": "LWK",
        "WRY | Westray Airport": "WRY",
        "CSA | Colonsay Airstrip": "CSA",
        "HAW | Haverfordwest Airport": "HAW",
        "CWL | Cardiff International Airport": "CWL",
        "SWS | Swansea Airport": "SWS",
        "BRS | Bristol Airport": "BRS",
        "LPL | Liverpool John Lennon Airport": "LPL",
        "LTN | London Luton Airport": "LTN",
        "LEQ | Land's End Airport": "LEQ",
        "ISC | St. Mary's Airport": "ISC",
        "BOH | Bournemouth Airport": "BOH",
        "SOU | Southampton Airport": "SOU",
        "BBP | Bembridge Airport": "BBP",
        "NQY | Newquay Cornwall Airport": "NQY",
        "QUG | Goodwood Aerodrome": "QUG",
        "ACI | Alderney Airport": "ACI",
        "GCI | Guernsey Airport": "GCI",
        "JER | Jersey Airport": "JER",
        "ESH | Shoreham Airport": "ESH",
        "BQH | London Biggin Hill Airport": "BQH",
        "LGW | London Gatwick Airport": "LGW",
        "KRH | Redhill Aerodrome": "KRH",
        "LCY | London City Airport": "LCY",
        "FAB | Farnborough Airport": "FAB",
        "BBS | Blackbushe Airport": "BBS",
        "LHR | London Heathrow Airport": "LHR",
        "SEN | Southend Airport": "SEN",
        "LYX | Lydd Airport": "LYX",
        "CAX | Carlisle Airport": "CAX",
        "BLK | Blackpool International Airport": "BLK",
        "HUY | Humberside Airport": "HUY",
        "BWF | Barrow Walney Island Airport": "BWF",
        "LBA | Leeds Bradford Airport": "LBA",
        "WRT | Warton Aerodrome": "WRT",
        "CEG | Hawarden Airport": "CEG",
        "IOM | Isle of Man Airport": "IOM",
        "NCL | Newcastle Airport": "NCL",
        "MME | Teesside International Airport": "MME",
        "EMA | East Midlands Airport": "EMA",
        "VLY | Anglesey Airport": "VLY",
        "KOI | Kirkwall Airport": "KOI",
        "LSI | Sumburgh Airport": "LSI",
        "WIC | Wick Airport": "WIC",
        "ABZ | Aberdeen Dyce Airport": "ABZ",
        "INV | Inverness Airport": "INV",
        "GLA | Glasgow International Airport": "GLA",
        "EDI | Edinburgh Airport": "EDI",
        "ILY | Islay Airport": "ILY",
        "PIK | Glasgow Prestwick Airport": "PIK",
        "BEB | Benbecula Airport": "BEB",
        "SCS | Scatsta Airport": "SCS",
        "DND | Dundee Airport": "DND",
        "SYY | Stornoway Airport": "SYY",
        "BRR | Barra Airport": "BRR",
        "PSL | Perth/Scone Airport": "PSL",
        "TRE | Tiree Airport": "TRE",
        "UNT | Unst Airport": "UNT",
        "FSS | RAF Kinloss": "FSS",
        "ADX | Leuchars Station Airfield": "ADX",
        "LMO | RAF Lossiemouth": "LMO",
        "CBG | Cambridge Airport": "CBG",
        "NWI | Norwich International Airport": "NWI",
        "STN | London Stansted Airport": "STN",
        "HYC | Wycombe Air Park": "HYC",
        "EXT | Exeter International Airport": "EXT",
        "OXF | Oxford (Kidlington) Airport": "OXF",
        "RCS | Rochester Airport": "RCS",
        "BEX | RAF Benson": "BEX",
        "LKZ | RAF Lakenheath": "LKZ",
        "MHZ | RAF Mildenhall": "MHZ",
        "QUY | RAF Wyton": "QUY",
        "FFD | RAF Fairford": "FFD",
        "BZZ | RAF Brize Norton": "BZZ",
        "ODH | RAF Odiham": "ODH",
        "NHT | RAF Northolt": "NHT",
        "QCY | RAF Coningsby": "QCY",
        "BEQ | RAF Honington": "BEQ",
        "SQZ | RAF Scampton": "SQZ",
        "HRT | RAF Linton-On-Ouse": "HRT",
        "WTN | RAF Waddington": "WTN",
        "KNF | RAF Marham": "KNF",
        "MPN | Mount Pleasant Airport": "MPN",
        "AMS | Amsterdam Airport Schiphol": "AMS",
        "MST | Maastricht Aachen Airport": "MST",
        "EIN | Eindhoven Airport": "EIN",
        "GRQ | Eelde Airport": "GRQ",
        "GLZ | Gilze Rijen Air Base": "GLZ",
        "DHR | De Kooy Airport": "DHR",
        "LEY | Lelystad Airport": "LEY",
        "LWR | Leeuwarden Air Base": "LWR",
        "RTM | Rotterdam The Hague Airport": "RTM",
        "ENS | Twente Airport": "ENS",
        "EHU | Ezhou Huahu Airport": "EHU",
        "WOE | Woensdrecht Air Base": "WOE",
        "BYT | Bantry Aerodrome": "BYT",
        "BLY | Belmullet Aerodrome": "BLY",
        "NNR | Connemara Regional Airport": "NNR",
        "ORK | Cork Airport": "ORK",
        "GWY | Galway Airport": "GWY",
        "CFN | Donegal Airport": "CFN",
        "DUB | Dublin Airport": "DUB",
        "IOR | Inishmore Aerodrome": "IOR",
        "INQ | Inisheer Aerodrome": "INQ",
        "KKY | Kilkenny Airport": "KKY",
        "NOC | Ireland West Knock Airport": "NOC",
        "KIR | Kerry Airport": "KIR",
        "LTR | Letterkenny Airfield": "LTR",
        "IIA | Inishmaan Aerodrome": "IIA",
        "SNN | Shannon Airport": "SNN",
        "SXL | Sligo Airport": "SXL",
        "WAT | Waterford Airport": "WAT",
        "EJN | Ejin Banner-Taolai Airport": "EJN",
        "EJT | Enejit Airport": "EJT",
        "AAR | Aarhus Airport": "AAR",
        "BLL | Billund Airport": "BLL",
        "CPH | Copenhagen Kastrup Airport": "CPH",
        "EBJ | Esbjerg Airport": "EBJ",
        "KRP | Karup Airport": "KRP",
        "BYR | Læsø Airport": "BYR",
        "MRW | Lolland Falster Maribo Airport": "MRW",
        "ODE | Odense Airport": "ODE",
        "RKE | Copenhagen Roskilde Airport": "RKE",
        "RNN | Bornholm Airport": "RNN",
        "SGD | Sønderborg Airport": "SGD",
        "CNL | Sindal Airport": "CNL",
        "SKS | Skrydstrup Air Base": "SKS",
        "SQW | Skive Airport": "SQW",
        "TED | Thisted Airport": "TED",
        "FAE | Vágar Airport": "FAE",
        "STA | Stauning Airport": "STA",
        "AAL | Aalborg Airport": "AAL",
        "LUX | Luxembourg-Findel International Airport": "LUX",
        "AES | Ålesund Airport Vigra": "AES",
        "ANX | Andøya Airport Andenes": "ANX",
        "ALF | Alta Airport": "ALF",
        "FDE | Førde Airport Bringeland": "FDE",
        "BNN | Brønnøysund Airport Brønnøy": "BNN",
        "BOO | Bodø Airport": "BOO",
        "BGO | Bergen Airport Flesland": "BGO",
        "BJF | Båtsfjord Airport": "BJF",
        "BVG | Berlevåg Airport": "BVG",
        "KRS | Kristiansand Airport Kjevik": "KRS",
        "DLD | Geilo Airport Dagali": "DLD",
        "BDU | Bardufoss Airport": "BDU",
        "EVE | Harstad/Narvik Airport Evenes": "EVE",
        "VDB | Fagernes Airport Leirin": "VDB",
        "FRO | Florø Airport": "FRO",
        "OSL | Oslo Airport Gardermoen": "OSL",
        "HMR | Hamar Lufthavn Stavsberg": "HMR",
        "HAU | Haugesund Airport Karmøy": "HAU",
        "HFT | Hammerfest Airport": "HFT",
        "HAA | Hasvik Airport": "HAA",
        "HVG | Honningsvåg Airport Valan": "HVG",
        "KSU | Kristiansund Airport Kvernberget": "KSU",
        "GLL | Gol Airport": "GLL",
        "KKN | Kirkenes Airport Høybuktmoen": "KKN",
        "FAN | Lista Airport": "FAN",
        "LKN | Leknes Airport": "LKN",
        "MEH | Mehamn Airport": "MEH",
        "MOL | Molde Airport Årø": "MOL",
        "MJF | Mosjøen Airport Kjærstad": "MJF",
        "LKL | Lakselv Airport Banak": "LKL",
        "OSY | Namsos Airport": "OSY",
        "NTB | Notodden Airport": "NTB",
        "OLA | Ørland Airport": "OLA",
        "HOV | Ørsta-Volda Airport Hovden": "HOV",
        "MQN | Mo i Rana Airport Røssvoll": "MQN",
        "RVK | Rørvik Airport Ryum": "RVK",
        "RRS | Røros Airport": "RRS",
        "RET | Røst Airport": "RET",
        "RYG | Moss Airport Rygge": "RYG",
        "LYR | Svalbard Airport Longyear": "LYR",
        "SDN | Sandane Airport Anda": "SDN",
        "SOG | Sogndal Airport Haukåsen": "SOG",
        "SVJ | Svolvær Airport Helle": "SVJ",
        "SKN | Stokmarknes Airport Skagen": "SKN",
        "SKE | Skien Airport": "SKE",
        "SRP | Stord Airport Sørstokken": "SRP",
        "SOJ | Sørkjosen Airport": "SOJ",
        "VAW | Vardø Airport Svartnes": "VAW",
        "SSJ | Sandnessjøen Airport Stokka": "SSJ",
        "TOS | Tromsø Airport Langnes": "TOS",
        "TRF | Sandefjord Airport Torp": "TRF",
        "TRD | Trondheim Airport Værnes": "TRD",
        "VDS | Vadsø Airport": "VDS",
        "ENY | Yan'an Nanniwan Airport": "ENY",
        "SVG | Stavanger Airport Sola": "SVG",
        "BZG | Bydgoszcz Ignacy Jan Paderewski Airport": "BZG",
        "CZW | Częstochowa-Rudniki": "CZW",
        "GDN | Gdańsk Lech Wałęsa Airport": "GDN",
        "KRK | Kraków John Paul II International Airport": "KRK",
        "KTW | Katowice International Airport": "KTW",
        "LCJ | Łódź Władysław Reymont Airport": "LCJ",
        "WMI | Modlin Airport": "WMI",
        "POZ | Poznań-Ławica Airport": "POZ",
        "RDO | Radom Airport": "RDO",
        "RZE | Rzeszów-Jasionka Airport": "RZE",
        "SZZ | Szczecin-Goleniów Solidarność Airport": "SZZ",
        "SZY | Olsztyn-Mazury Airport": "SZY",
        "WAW | Warsaw Chopin Airport": "WAW",
        "WRO | Copernicus Wrocław Airport": "WRO",
        "IEG | Zielona Góra-Babimost Airport": "IEG",
        "ERT | Erdenet Airport": "ERT",
        "RNB | Ronneby Airport": "RNB",
        "GOT | Gothenburg-Landvetter Airport": "GOT",
        "JKG | Jönköping Airport": "JKG",
        "LDK | Lidköping-Hovby Airport": "LDK",
        "GSE | Gothenburg City Airport": "GSE",
        "KVB | Skövde Airport": "KVB",
        "THN | Trollhättan-Vänersborg Airport": "THN",
        "KSK | Karlskoga Airport": "KSK",
        "MXX | Mora Airport": "MXX",
        "NYO | Stockholm Skavsta Airport": "NYO",
        "SCR | Scandinavian Mountains Airport": "SCR",
        "KID | Kristianstad Airport": "KID",
        "OSK | Oskarshamn Airport": "OSK",
        "KLR | Kalmar Airport": "KLR",
        "MMX | Malmö Sturup Airport": "MMX",
        "HAD | Halmstad Airport": "HAD",
        "VXO | Växjö Kronoberg Airport": "VXO",
        "EVG | Sveg Airport": "EVG",
        "GEV | Gällivare Airport": "GEV",
        "KRF | Kramfors-Sollefteå Höga Kusten Airport": "KRF",
        "LYC | Lycksele Airport": "LYC",
        "SDL | Sundsvall-Härnösand Airport": "SDL",
        "OER | Örnsköldsvik Airport": "OER",
        "KRN | Kiruna Airport": "KRN",
        "SFT | Skellefteå Airport": "SFT",
        "UME | Umeå Airport": "UME",
        "VHM | Vilhelmina South Lapland Airport": "VHM",
        "AJR | Arvidsjaur Airport": "AJR",
        "SOO | Söderhamn Airport": "SOO",
        "OSD | Åre Östersund Airport": "OSD",
        "ORB | Örebro Airport": "ORB",
        "HFS | Hagfors Airport": "HFS",
        "KSD | Karlstad Airport": "KSD",
        "VST | Stockholm Västerås Airport": "VST",
        "LLA | Luleå Airport": "LLA",
        "ARN | Stockholm-Arlanda Airport": "ARN",
        "BMA | Stockholm-Bromma Airport": "BMA",
        "BLE | Dala Airport": "BLE",
        "HLF | Hultsfred Airport": "HLF",
        "GVX | Gävle Sandviken Airport": "GVX",
        "LPI | Linköping City Airport": "LPI",
        "NRK | Norrköping Airport": "NRK",
        "TYF | Torsby Airport": "TYF",
        "EKT | Eskilstuna Airport": "EKT",
        "VBY | Visby Airport": "VBY",
        "VVK | Västervik Airport": "VVK",
        "AGH | Ängelholm-Helsingborg Airport": "AGH",
        "SQO | Storuman Airport": "SQO",
        "IDB | Idre Airport": "IDB",
        "PJA | Pajala Airport": "PJA",
        "HMV | Hemavan Airport": "HMV",
        "GLC | Geladi Airport": "GLC",
        "SHC | Shire Inda Selassie Airport": "SHC",
        "SPM | Spangdahlem Air Base": "SPM",
        "RMS | Ramstein Air Base": "RMS",
        "FRZ | Fritzlar Army Airfield": "FRZ",
        "KZG | Flugplatz Kitzingen": "KZG",
        "FCN | Nordholz Naval Airbase": "FCN",
        "GKE | Geilenkirchen Air Base": "GKE",
        "RLG | Rostock-Laage Airport": "RLG",
        "WBG | Schleswig Air Base": "WBG",
        "WIE | Wiesbaden Army Airfield": "WIE",
        "IGS | Ingolstadt Manching Airport": "IGS",
        "DGP | Daugavpils Intrenational Airport": "DGP",
        "LPX | Liepāja International Airport": "LPX",
        "RIX | Riga International Airport": "RIX",
        "VNT | Ventspils International Airport": "VNT",
        "EXI | Excursion Inlet Seaplane Base": "EXI",
        "KUN | Kaunas International Airport": "KUN",
        "PLQ | Palanga International Airport": "PLQ",
        "PNV | Panevėžys Air Base": "PNV",
        "SQQ | Šiauliai International Airport": "SQQ",
        "VNO | Vilnius International Airport": "VNO",
        "RGR | Ranger Municipal Airport": "RGR",
        "ALJ | Alexander Bay Airport": "ALJ",
        "AGZ | Aggeneys Airport": "AGZ",
        "ADY | Alldays Airport": "ADY",
        "BIY | Bisho Airport": "BIY",
        "BFN | Bram Fischer International Airport": "BFN",
        "UTE | Bultfontein Airport": "UTE",
        "ASS | Arathusa Safari Lodge Airport": "ASS",
        "CDO | Cradock Airport": "CDO",
        "CPT | Cape Town International Airport": "CPT",
        "DUK | Mubatuba Airport": "DUK",
        "PZL | Zulu Inyala Airport": "PZL",
        "ELS | Ben Schoeman Airport": "ELS",
        "EMG | Empangeni Airport": "EMG",
        "ELL | Ellisras Matimba Airport": "ELL",
        "FCB | Ficksburg Sentraoes Airport": "FCB",
        "GCJ | Grand Central Airport": "GCJ",
        "GRJ | George Airport": "GRJ",
        "GIY | Giyani Airport": "GIY",
        "QRA | Rand Airport": "QRA",
        "HLW | Hluhluwe Airport": "HLW",
        "HRS | Harrismith Airport": "HRS",
        "HDS | Hoedspruit Air Force Base Airport": "HDS",
        "KXE | P C Pelser Airport": "KXE",
        "KIM | Kimberley Airport": "KIM",
        "MQP | Kruger Mpumalanga International Airport": "MQP",
        "KOF | Komatipoort Airport": "KOF",
        "KMH | Johan Pienaar Airport": "KMH",
        "KLZ | Kleinsee Airport": "KLZ",
        "HLA | Lanseria Airport": "HLA",
        "LMR | Lime Acres Finsch Mine Airport": "LMR",
        "LDZ | Londolozi Airport": "LDZ",
        "DUR | King Shaka International Airport": "DUR",
        "LUJ | Lusikisiki Airport": "LUJ",
        "LCD | Louis Trichardt Airport": "LCD",
        "SDB | Langebaanweg Airport": "SDB",
        "LAY | Ladysmith Airport": "LAY",
        "AAM | Malamala Airport": "AAM",
        "MGH | Margate Airport": "MGH",
        "MEZ | Musina(Messina) Airport": "MEZ",
        "MBD | Mmabatho International Airport": "MBD",
        "LLE | Riverside Airport": "LLE",
        "MZY | Mossel Bay Airport": "MZY",
        "MZQ | Mkuze Airport": "MZQ",
        "NCS | Newcastle Airport": "NCS",
        "NGL | Ngala Airport": "NGL",
        "NLP | Nelspruit Airport": "NLP",
        "OVG | Overberg Airport": "OVG",
        "OUH | Oudtshoorn Airport": "OUH",
        "JNB | OR Tambo International Airport": "JNB",
        "AFD | Port Alfred Airport": "AFD",
        "PLZ | Port Elizabeth International Airport": "PLZ",
        "PBZ | Plettenberg Bay Airport": "PBZ",
        "PHW | Hendrik Van Eck Airport": "PHW",
        "JOH | Port St Johns Airport": "JOH",
        "PRK | Prieska Airport": "PRK",
        "PZB | Pietermaritzburg Airport": "PZB",
        "NTY | Pilanesberg International Airport": "NTY",
        "PTG | Polokwane International Airport": "PTG",
        "PCF | Potchefstroom Airport": "PCF",
        "UTW | Queenstown Airport": "UTW",
        "RCB | Richards Bay Airport": "RCB",
        "RVO | Reivilo Airport": "RVO",
        "ROD | Robertson Airport": "ROD",
        "SBU | Springbok Airport": "SBU",
        "ZEC | Secunda Airport": "ZEC",
        "GSS | Sabi Sabi Airport": "GSS",
        "SIS | Sishen Airport": "SIS",
        "SZK | Skukuza Airport": "SZK",
        "THY | Thohoyandou Airport": "THY",
        "TCU | Thaba Nchu Tar Airport": "TCU",
        "LTA | Tzaneen Airport": "LTA",
        "ULD | Prince Mangosuthu Buthelezi Airport": "ULD",
        "UTN | Pierre Van Ryneveld Airport": "UTN",
        "UTT | K. D. Matanzima Airport": "UTT",
        "VRU | Vryburg Airport": "VRU",
        "VIR | Virginia Airport": "VIR",
        "VRE | Vredendal Airport": "VRE",
        "VYD | Vryheid Airport": "VYD",
        "PRY | Wonderboom Airport": "PRY",
        "WKF | Waterkloof Air Force Base": "WKF",
        "FRW | Francistown Airport": "FRW",
        "GNZ | Ghanzi Airport": "GNZ",
        "JWA | Jwaneng Airport": "JWA",
        "BBK | Kasane Airport": "BBK",
        "KHW | Khwai River Lodge Airport": "KHW",
        "LOQ | Lobatse Airport": "LOQ",
        "MUB | Maun Airport": "MUB",
        "ORP | Orapa Airport": "ORP",
        "QPH | Palapye Airport": "QPH",
        "GBE | Sir Seretse Khama International Airport": "GBE",
        "SXN | Sua Pan Airport": "SXN",
        "PKW | Selebi Phikwe Airport": "PKW",
        "SVT | Savuti Airport": "SVT",
        "SWX | Shakawe Airport": "SWX",
        "TLD | Limpopo Valley Airport": "TLD",
        "TBY | Tshabong Airport": "TBY",
        "BZV | Maya-Maya Airport": "BZV",
        "DJM | Djambala Airport": "DJM",
        "KNJ | Kindamba Airport": "KNJ",
        "LCO | Lague Airport": "LCO",
        "MUY | Mouyondzi Airport": "MUY",
        "SIB | Sibiti Airport": "SIB",
        "NKY | Yokangassi Airport": "NKY",
        "ANJ | Zanaga Airport": "ANJ",
        "MSX | Mossendjo Airport": "MSX",
        "BOE | Boundji Airport": "BOE",
        "OLL | Oyo Ollombo Airport": "OLL",
        "EWO | Ewo Airport": "EWO",
        "GMM | Gamboma Airport": "GMM",
        "ION | Impfondo Airport": "ION",
        "KEE | Kelle Airport": "KEE",
        "MKJ | Makoua Airport": "MKJ",
        "FTX | Owando Airport": "FTX",
        "SOE | Souanke Airport": "SOE",
        "BTB | Betou Airport": "BTB",
        "OUE | Ouesso Airport": "OUE",
        "KMK | Makabana Airport": "KMK",
        "DIS | Ngot Nzoungou Airport": "DIS",
        "PNR | Antonio Agostinho-Neto International Airport": "PNR",
        "MTS | Matsapha Airport": "MTS",
        "SHO | King Mswati III International Airport": "SHO",
        "FEA | Fetlar Airport": "FEA",
        "CRF | Carnot Airport": "CRF",
        "BGF | Bangui M'Poko International Airport": "BGF",
        "BGU | Bangassou Airport": "BGU",
        "IRO | Birao Airport": "IRO",
        "BBY | Bambari Airport": "BBY",
        "NDL | N'Délé Airport": "NDL",
        "BOP | Bouar Airport": "BOP",
        "BIV | Bria Airport": "BIV",
        "BSN | Bossangoa Airport": "BSN",
        "BBT | Berbérati Airport": "BBT",
        "ODA | Ouadda Airport": "ODA",
        "AIG | Yalinga Airport": "AIG",
        "IMO | Zemio Airport": "IMO",
        "MKI | M'Boki Airport": "MKI",
        "BTG | Batangafo Airport": "BTG",
        "GDI | Gordil Airport": "GDI",
        "BMF | Bakouma Airport": "BMF",
        "ODJ | Ouanda Djallé Airport": "ODJ",
        "RFA | Rafaï Airport": "RFA",
        "BCF | Bouca Airport": "BCF",
        "BOZ | Bozoum Airport": "BOZ",
        "NBN | Annobón Airport": "NBN",
        "BSG | Bata Airport": "BSG",
        "GEM | President Obiang Nguema International Airport": "GEM",
        "SSG | Malabo Airport": "SSG",
        "ASI | RAF Ascension Island": "ASI",
        "HLE | Saint Helena Airport": "HLE",
        "MRU | Sir Seewoosagur Ramgoolam International Airport": "MRU",
        "RRG | Sir Charles Gaetan Duval Airport": "RRG",
        "FIN | Finschhafen Airport": "FIN",
        "NKW | Diego Garcia Naval Support Facility": "NKW",
        "KBI | Kribi Airport": "KBI",
        "TKC | Tiko Airport": "TKC",
        "DLA | Douala International Airport": "DLA",
        "MMF | Mamfe Airport": "MMF",
        "BLC | Bali Airport": "BLC",
        "KLE | Kaélé Airport": "KLE",
        "OUR | Batouri Airport": "OUR",
        "GXX | Yagoua Airport": "GXX",
        "MVR | Salak Airport": "MVR",
        "FOM | Foumban Nkounja Airport": "FOM",
        "NGE | N'Gaoundéré Airport": "NGE",
        "BTA | Bertoua Airport": "BTA",
        "GOU | Garoua International Airport": "GOU",
        "DSC | Dschang Airport": "DSC",
        "BFX | Bafoussam Airport": "BFX",
        "BPC | Bamenda Airport": "BPC",
        "EBW | Ebolowa Airport": "EBW",
        "YAO | Yaoundé Airport": "YAO",
        "NSI | Yaoundé Nsimalen International Airport": "NSI",
        "MMQ | Mbala Airport": "MMQ",
        "CIP | Chipata Airport": "CIP",
        "JEK | Jeki Airport": "JEK",
        "CGJ | Kasompe Airport": "CGJ",
        "KLB | Kalabo Airport": "KLB",
        "KMZ | Kaoma Airport": "KMZ",
        "KAA | Kasama Airport": "KAA",
        "ZKB | Kasaba Bay Airport": "ZKB",
        "LVI | Harry Mwanga Nkumbula International Airport": "LVI",
        "LXU | Lukulu Airport": "LXU",
        "LUN | Kenneth Kaunda International Airport": "LUN",
        "MNS | Mansa Airport": "MNS",
        "MFU | Mfuwe Airport": "MFU",
        "MNR | Mongu Airport": "MNR",
        "ZGM | Ngoma Airport": "ZGM",
        "NLA | Simon Mwansa Kapwepwe International Airport": "NLA",
        "RYL | Royal Zambesi Lodge Airstrip": "RYL",
        "SXG | Senanga Airport": "SXG",
        "KIW | Southdowns Airport": "KIW",
        "SJQ | Sesheke Airport": "SJQ",
        "SLI | Solwesi Airport": "SLI",
        "FLT | Flat Airport": "FLT",
        "BBZ | Zambezi Airport": "BBZ",
        "ULI | Ulithi Airport": "ULI",
        "HAH | Prince Said Ibrahim International Airport": "HAH",
        "NWA | Mohéli Bandar Es Eslam Airport": "NWA",
        "AJN | Ouani Airport": "AJN",
        "DZA | Dzaoudzi Pamandzi International Airport": "DZA",
        "RUN | Roland Garros Airport": "RUN",
        "ZSE | Pierrefonds Airport": "ZSE",
        "WML | Malaimbandy Airport": "WML",
        "ATJ | Antsirabe Airport": "ATJ",
        "WAQ | Antsalova Airport": "WAQ",
        "VVB | Mahanoro Airport": "VVB",
        "TNR | Ivato Airport": "TNR",
        "JVA | Ankavandra Airport": "JVA",
        "BMD | Belo sur Tsiribihina Airport": "BMD",
        "ZVA | Miandrivazo Airport": "ZVA",
        "MXT | Maintirano Airport": "MXT",
        "ILK | Atsinanana Airport": "ILK",
        "TVA | Morafenobe Airport": "TVA",
        "SMS | Sainte Marie Airport": "SMS",
        "TMM | Toamasina Ambalamanasy Airport": "TMM",
        "WTA | Tambohorano Airport": "WTA",
        "MOQ | Morondava Airport": "MOQ",
        "WTS | Tsiroanomandidy Airport": "WTS",
        "VAT | Vatomandry Airport": "VAT",
        "WAM | Ambatondrazaka Airport": "WAM",
        "DIE | Arrachart Airport": "DIE",
        "WBE | Ankaizina Airport": "WBE",
        "WMR | Mananara Nord Airport": "WMR",
        "ZWA | Andapa Airport": "ZWA",
        "AMB | Ambilobe Airport": "AMB",
        "WBD | Avaratra Airport": "WBD",
        "WPB | Port Bergé Airport": "WPB",
        "ANM | Antsirabe Airport": "ANM",
        "HVA | Analalava Airport": "HVA",
        "MJN | Amborovy Airport": "MJN",
        "NOS | Fascene Airport": "NOS",
        "DWB | Soalala Airport": "DWB",
        "WMP | Mampikony Airport": "WMP",
        "BPY | Besalampy Airport": "BPY",
        "WMN | Maroantsetra Airport": "WMN",
        "SVB | Sambava Airport": "SVB",
        "TTS | Tsaratanana Airport": "TTS",
        "VOH | Vohemar Airport": "VOH",
        "WAI | Ambalabe Airport": "WAI",
        "WMA | Mandritsara Airport": "WMA",
        "IVA | Ampampamena Airport": "IVA",
        "WBO | Antsoa Airport": "WBO",
        "WMD | Mandabe Airport": "WMD",
        "FTU | Tôlanaro Airport": "FTU",
        "WFI | Fianarantsoa Airport": "WFI",
        "RVA | Farafangana Airport": "RVA",
        "IHO | Ihosy Airport": "IHO",
        "MJA | Manja Airport": "MJA",
        "WVK | Manakara Airport": "WVK",
        "OVA | Bekily Airport": "OVA",
        "MNJ | Mananjary Airport": "MNJ",
        "TDV | Samangoky Airport": "TDV",
        "MXM | Morombe Airport": "MXM",
        "TLE | Toliara Airport": "TLE",
        "VND | Vangaindrano Airport": "VND",
        "BKU | Betioky Airport": "BKU",
        "AMP | Ampanihy Airport": "AMP",
        "WAK | Ankazoabo Airport": "WAK",
        "AZZ | Ambriz Airport": "AZZ",
        "SSY | Mbanza Congo Airport": "SSY",
        "BUG | Benguela 17th of September Airport": "BUG",
        "GGC | Lumbala Airport": "GGC",
        "CAB | Cabinda Airport": "CAB",
        "CFF | Cafunfo Airport": "CFF",
        "PGI | Chitato Airport": "PGI",
        "CBT | Catumbela Airport": "CBT",
        "CTI | Cuito Cuanavale Airport": "CTI",
        "CXM | Camaxilo Airport": "CXM",
        "CAV | Cazombo Airport": "CAV",
        "FND | Funadhoo Airport": "FND",
        "DUE | Dundo Airport": "DUE",
        "FNE | Fane Airport": "FNE",
        "VPE | Ngjiva Pereira Airport": "VPE",
        "NOV | Nova Lisboa Airport": "NOV",
        "SVP | Kuito Airport": "SVP",
        "LLT | Lobito Airport": "LLT",
        "LBZ | Lucapa Airport": "LBZ",
        "LAD | Quatro de Fevereiro Airport": "LAD",
        "LZM | Luzamba Airport": "LZM",
        "MEG | Malanje Airport": "MEG",
        "SPP | Menongue Airport": "SPP",
        "MSZ | Welwitschia Mirabilis International Airport": "MSZ",
        "GXG | Negage Airport": "GXG",
        "PBN | Porto Amboim Airport": "PBN",
        "VHC | Saurimo Airport": "VHC",
        "SZA | Soyo Airport": "SZA",
        "NDD | Sumbe Airport": "NDD",
        "UAL | Luau Airport": "UAL",
        "SDD | Lubango Airport": "SDD",
        "LUO | Luena Airport": "LUO",
        "UGO | Uige Airport": "UGO",
        "CEO | Waco Kungo Airport": "CEO",
        "XGN | Xangongo Airport": "XGN",
        "ARZ | N'zeto Airport": "ARZ",
        "NZA | Nzagi Airport": "NZA",
        "BGB | Booue Airport": "BGB",
        "KDN | Ndende Airport": "KDN",
        "FOU | Fougamou Airport": "FOU",
        "MBC | M'Bigou Airport": "MBC",
        "MGX | Moabi Airport": "MGX",
        "KDJ | Ville Airport": "KDJ",
        "KOU | Koulamoutou Mabimbi Airport": "KOU",
        "MJL | Mouilla Ville Airport": "MJL",
        "OYE | Oyem Airport": "OYE",
        "OKN | Okondja Airport": "OKN",
        "LBQ | Lambarene Airport": "LBQ",
        "MVX | Minvoul Airport": "MVX",
        "BMM | Bitam Airport": "BMM",
        "MFF | Moanda Airport": "MFF",
        "MKB | Mekambo Airport": "MKB",
        "POG | Port Gentil Airport": "POG",
        "OMB | Omboue Hospital Airport": "OMB",
        "IGE | Tchongorove Airport": "IGE",
        "MKU | Makokou Airport": "MKU",
        "LBV | Libreville Leon M'ba International Airport": "LBV",
        "MZC | Mitzic Airport": "MZC",
        "MVB | M'Vengue El Hadj Omar Bongo Ondimba International Airport": "MVB",
        "LTL | Lastourville Airport": "LTL",
        "ZKM | Sette Cama Airport": "ZKM",
        "TCH | Tchibanga Airport": "TCH",
        "MYB | Mayumba Airport": "MYB",
        "FOY | Foya Airport": "FOY",
        "PCP | Principe Airport": "PCP",
        "TMS | São Tomé International Airport": "TMS",
        "ANO | Angoche Airport": "ANO",
        "BEW | Beira Airport": "BEW",
        "FXO | Cuamba Airport": "FXO",
        "VPY | Chimoio Airport": "VPY",
        "IHC | Inhaca Airport": "IHC",
        "INH | Inhambane Airport": "INH",
        "VXC | Lichinga Airport": "VXC",
        "LFB | Lumbo Airport": "LFB",
        "MPM | Maputo Airport": "MPM",
        "MUD | Mueda Airport": "MUD",
        "MZB | Mocímboa da Praia Airport": "MZB",
        "MNC | Nacala Airport": "MNC",
        "APL | Nampula Airport": "APL",
        "POL | Pemba Airport": "POL",
        "PDD | Ponta do Ouro Airport": "PDD",
        "UEL | Quelimane Airport": "UEL",
        "TET | Chingozi Airport": "TET",
        "VNX | Vilankulo Airport": "VNX",
        "VJB | Xai-Xai Airport": "VJB",
        "DES | Desroches Airport": "DES",
        "SEZ | Seychelles International Airport": "SEZ",
        "FSL | Fossil Downs Airport": "FSL",
        "PRI | Praslin Airport": "PRI",
        "BDI | Bird Island Airport": "BDI",
        "DEI | Denis Island Airport": "DEI",
        "FRK | Frégate Island Airport": "FRK",
        "SRH | Sarh Airport": "SRH",
        "OGR | Bongor Airport": "OGR",
        "AEH | Abeche Airport": "AEH",
        "MQQ | Moundou Airport": "MQQ",
        "LTC | Lai Airport": "LTC",
        "ATV | Ati Airport": "ATV",
        "NDJ | N'Djamena International Airport": "NDJ",
        "BKR | Bokoro Airport": "BKR",
        "OTC | Bol Airport": "OTC",
        "MVO | Mongo Airport": "MVO",
        "AMC | Am Timan Airport": "AMC",
        "PLF | Pala Airport": "PLF",
        "OUT | Bousso Airport": "OUT",
        "AMO | Mao Airport": "AMO",
        "FYT | Faya Largeau Airport": "FYT",
        "FUB | Fulleborn Airport": "FUB",
        "BZH | Bumi Airport": "BZH",
        "BUQ | Joshua Mqabuko Nkomo International Airport": "BUQ",
        "CHJ | Chipinge Airport": "CHJ",
        "BFO | Buffalo Range Airport": "BFO",
        "VFA | Victoria Falls International Airport": "VFA",
        "HRE | Robert Gabriel Mugabe International Airport": "HRE",
        "KAB | Kariba International Airport": "KAB",
        "MJW | Mahenye Airport": "MJW",
        "UTA | Mutare Airport": "UTA",
        "MVZ | Masvingo International Airport": "MVZ",
        "GWE | Thornhill Air Base": "GWE",
        "HWN | Hwange National Park Airport": "HWN",
        "WKI | Hwange (Town) Airport": "WKI",
        "CEH | Chelinda Malawi Airport": "CEH",
        "BLZ | Chileka International Airport": "BLZ",
        "CMK | Club Makokola Airport": "CMK",
        "DWA | Dwangwa Airport": "DWA",
        "KGJ | Karonga Airport": "KGJ",
        "KBQ | Kasungu Airport": "KBQ",
        "LLW | Lilongwe International Airport": "LLW",
        "LIX | Likoma Island Airport": "LIX",
        "MAI | Mangochi Airport": "MAI",
        "MYZ | Monkey Bay Airport": "MYZ",
        "LMB | Salima Airport": "LMB",
        "ZZU | Mzuzu Airport": "ZZU",
        "LEF | Lebakeng Airport": "LEF",
        "LRB | Leribe Airport": "LRB",
        "LES | Lesobeng Airport": "LES",
        "MFC | Mafeteng Airport": "MFC",
        "MKH | Mokhotlong Airport": "MKH",
        "MSU | Moshoeshoe I International Airport": "MSU",
        "NKU | Nkaus Airport": "NKU",
        "PEL | Pelaneng Airport": "PEL",
        "UTG | Quthing Airport": "UTG",
        "UNE | Qacha's Nek Airport": "UNE",
        "SHK | Sehonghong Airport": "SHK",
        "SKQ | Sekakes Airport": "SKQ",
        "SOK | Semonkong Airport": "SOK",
        "SHZ | Seshutes Airport": "SHZ",
        "THB | Thaba-Tseka Airport": "THB",
        "TKO | Tlokoeng Airport": "TKO",
        "AIW | Ai-Ais Airport": "AIW",
        "ADI | Arandis Airport": "ADI",
        "GOG | Gobabis Airport": "GOG",
        "GFY | Grootfontein Airport": "GFY",
        "HAL | Halali Airport": "HAL",
        "KAS | Karasburg Airport": "KAS",
        "MPA | Katima Mulilo Airport": "MPA",
        "KMP | Keetmanshoop Airport": "KMP",
        "LHU | Lianshulu Airport": "LHU",
        "LUD | Luderitz Airport": "LUD",
        "MJO | Mount Etjo Airport": "MJO",
        "MQG | Midgard Airport": "MQG",
        "OKU | Mokuti Lodge Airport": "OKU",
        "NNI | Namutoni Airport": "NNI",
        "OND | Ondangwa Airport": "OND",
        "OMG | Omega Airport": "OMG",
        "OMD | Oranjemund Airport": "OMD",
        "OKF | Okaukuejo Airport": "OKF",
        "OPW | Opuwa Airport": "OPW",
        "OHI | Oshakati Airport": "OHI",
        "OTJ | Otjiwarongo Airport": "OTJ",
        "NDU | Rundu Airport": "NDU",
        "RHN | Skorpion Mine Airport": "RHN",
        "SWP | Swakopmund Municipal Aerodrome": "SWP",
        "SZM | Sesriem Airstrip": "SZM",
        "TCY | Terrace Bay Airport": "TCY",
        "TSB | Tsumeb Airport": "TSB",
        "WVB | Walvis Bay Airport": "WVB",
        "ERS | Eros Airport": "ERS",
        "WDH | Hosea Kutako International Airport": "WDH",
        "FIH | Ndjili International Airport": "FIH",
        "NLO | Ndolo Airport": "NLO",
        "MNB | Muanda Airport": "MNB",
        "BOA | Boma Airport": "BOA",
        "LZI | Luozi Airport": "LZI",
        "MAT | Tshimpi Airport": "MAT",
        "NKL | N'Kolo-Fuma Airport": "NKL",
        "INO | Inongo Airport": "INO",
        "NIO | Nioki Airport": "NIO",
        "FDU | Bandundu Airport": "FDU",
        "KRZ | Basango Mboliasa Airport": "KRZ",
        "KKW | Kikwit Airport": "KKW",
        "IDF | Idiofa Airport": "IDF",
        "LUS | Lusanga Airport": "LUS",
        "MSM | Masi Manimba Airport": "MSM",
        "MDK | Mbandaka Airport": "MDK",
        "BSU | Basankusu Airport": "BSU",
        "LIE | Libenge Airport": "LIE",
        "BDT | Gbadolite Airport": "BDT",
        "GMA | Gemena Airport": "GMA",
        "KLI | Kotakoli Airport": "KLI",
        "BMB | Bumbar Airport": "BMB",
        "LIQ | Lisala Airport": "LIQ",
        "BNB | Boende Airport": "BNB",
        "IKL | Ikela Airport": "IKL",
        "FKI | Bangoka International Airport": "FKI",
        "YAN | Yangambi Airport": "YAN",
        "IRP | Matari Airport": "IRP",
        "BUX | Bunia Airport": "BUX",
        "BZU | Buta Zega Airport": "BZU",
        "BKY | Bukavu Kavumu Airport": "BKY",
        "RUE | Rughenda Airfield": "RUE",
        "GOM | Goma International Airport": "GOM",
        "BNC | Beni Airport": "BNC",
        "KND | Kindu Airport": "KND",
        "KLY | Kinkungwa Airport": "KLY",
        "PUN | Punia Airport": "PUN",
        "FBM | Lubumbashi International Airport": "FBM",
        "PWO | Pweto Airport": "PWO",
        "KEC | Kasenga Airport": "KEC",
        "KWZ | Kolwezi Airport": "KWZ",
        "MNO | Manono Airport": "MNO",
        "BDV | Moba Airport": "BDV",
        "FMI | Kalemie Airport": "FMI",
        "KBO | Kabalo Airport": "KBO",
        "KOO | Kongolo Airport": "KOO",
        "KMN | Kamina Base Airport": "KMN",
        "KAP | Kapanga Airport": "KAP",
        "KNM | Kaniama Airport": "KNM",
        "KGA | Kananga Airport": "KGA",
        "LZA | Luiza Airport": "LZA",
        "TSH | Tshikapa Airport": "TSH",
        "LJA | Lodja Airport": "LJA",
        "LBO | Lusambo Airport": "LBO",
        "MEW | Mweka Airport": "MEW",
        "BAN | Basongo Airport": "BAN",
        "PFR | Ilebo Airport": "PFR",
        "MJM | Mbuji Mayi Airport": "MJM",
        "GDJ | Gandajika Airport": "GDJ",
        "KBN | Tunta Airport": "KBN",
        "AKE | Akieni Airport": "AKE",
        "GAX | Gamba Airport": "GAX",
        "GAB | Gabbs Airport": "GAB",
        "BKO | Modibo Keita International Airport": "BKO",
        "GUD | Goundam Airport": "GUD",
        "GAQ | Gao Airport": "GAQ",
        "KNZ | Kenieba Airport": "KNZ",
        "KTX | Koutiala Airport": "KTX",
        "KYS | Kayes Dag Dag Airport": "KYS",
        "MZI | Mopti Airport": "MZI",
        "NRM | Nara Airport": "NRM",
        "NIX | Nioro du Sahel Airport": "NIX",
        "KSS | Sikasso Airport": "KSS",
        "TOM | Timbuktu Airport": "TOM",
        "EYL | Yélimané Airport": "EYL",
        "GAZ | Guasopa Airport": "GAZ",
        "DOC | Dornoch Airfield": "DOC",
        "FLH | Flotta Isle Airport": "FLH",
        "FOA | Foula Airfield": "FOA",
        "OUK | Outer Skerries Airport": "OUK",
        "PSV | Papa Stour Airport": "PSV",
        "GBM | Garbaharey Airport": "GBM",
        "BJL | Banjul International Airport": "BJL",
        "FUE | Fuerteventura Airport": "FUE",
        "GMZ | La Gomera Airport": "GMZ",
        "VDE | El Hierro Airport": "VDE",
        "SPC | La Palma Airport": "SPC",
        "LPA | Gran Canaria Airport": "LPA",
        "ACE | César Manrique-Lanzarote Airport": "ACE",
        "TFS | Tenerife Sur Airport": "TFS",
        "GCV | Gravatai Airport": "GCV",
        "TFN | Tenerife Norte-Ciudad de La Laguna Airport": "TFN",
        "MLN | Melilla Airport": "MLN",
        "GEW | Gewoia Airport": "GEW",
        "OYC | Camopi Airport": "OYC",
        "KBS | Bo Airport": "KBS",
        "GFD | Pope Field": "GFD",
        "GBK | Gbangbatok Airport": "GBK",
        "HGS | Hastings Airport": "HGS",
        "KBA | Kabala Airport": "KBA",
        "KEN | Kenema Airport": "KEN",
        "FNA | Lungi International Airport": "FNA",
        "WYE | Yengema Airport": "WYE",
        "BQE | Bubaque Airport": "BQE",
        "OXB | Osvaldo Vieira International Airport": "OXB",
        "GHE | Garachiné Airport": "GHE",
        "UCN | Buchanan Airport": "UCN",
        "CPA | Cape Palmas Airport": "CPA",
        "SNI | Greenville/Sinoe Airport": "SNI",
        "MLW | Spriggs Payne Airport": "MLW",
        "NIA | Nimba Airport": "NIA",
        "GLP | Gulgubip Airport": "GLP",
        "ROB | Roberts International Airport": "ROB",
        "SAZ | Sasstown Airport": "SAZ",
        "THC | Tchien Airport": "THC",
        "VOI | Voinjama Airport": "VOI",
        "AGA | Al Massira Airport": "AGA",
        "TTA | Tan Tan Airport": "TTA",
        "OZG | Zagora Airport": "OZG",
        "UAR | Bouarfa Airport": "UAR",
        "FEZ | Saïss Airport": "FEZ",
        "ERH | Moulay Ali Cherif Airport": "ERH",
        "MEK | Bassatine Airport": "MEK",
        "OUD | Angads Airport": "OUD",
        "SMW | Smara Airport": "SMW",
        "GMD | Ben Slimane Airport": "GMD",
        "BEM | Beni Mellal Airport": "BEM",
        "RBA | Rabat-Salé Airport": "RBA",
        "VIL | Dakhla Airport": "VIL",
        "ESU | Essaouira-Mogador Airport": "ESU",
        "EUN | Hassan I Airport": "EUN",
        "CMN | Mohammed V International Airport": "CMN",
        "NDR | Nador International Airport": "NDR",
        "RAK | Menara Airport": "RAK",
        "NNA | Kenitra Air Base": "NNA",
        "OZZ | Ouarzazate Airport": "OZZ",
        "GMQ | Golog Maqin Airport": "GMQ",
        "AHU | Cherif Al Idrissi Airport": "AHU",
        "TTU | Saniat R'mel Airport": "TTU",
        "TNG | Tangier Ibn Battouta Airport": "TNG",
        "GNU | Goodnews Airport": "GNU",
        "DSS | Blaise Diagne International Airport": "DSS",
        "GOC | Gora Airstrip": "GOC",
        "KDA | Kolda North Airport": "KDA",
        "ZIG | Ziguinchor Airport": "ZIG",
        "CSK | Cap Skirring Airport": "CSK",
        "KLC | Kaolack Airport": "KLC",
        "DKR | Léopold Sédar Senghor International Airport": "DKR",
        "MAX | Ouro Sogui Airport": "MAX",
        "POD | Podor Airport": "POD",
        "RDT | Richard Toll Airport": "RDT",
        "XLS | Saint Louis Airport": "XLS",
        "BXE | Bakel Airport": "BXE",
        "KGG | Kédougou Airport": "KGG",
        "SMY | Simenti Airport": "SMY",
        "TUD | Tambacounda Airport": "TUD",
        "AEO | Aioun el Atrouss Airport": "AEO",
        "OTL | Boutilimit Airport": "OTL",
        "THI | Tichitt Airport": "THI",
        "TIY | Tidjikja Airport": "TIY",
        "BGH | Abbaye Airport": "BGH",
        "KFA | Kiffa Airport": "KFA",
        "TMD | Timbedra Airport": "TMD",
        "EMN | Néma Airport": "EMN",
        "AJJ | Akjoujt Airport": "AJJ",
        "KED | Kaédi Airport": "KED",
        "MOM | Letfotar Airport": "MOM",
        "NKC | Nouakchott–Oumtounsy International Airport": "NKC",
        "SEY | Sélibaby Airport": "SEY",
        "THT | Tamchakett Airport": "THT",
        "ATR | Atar International Airport": "ATR",
        "FGD | Fderik Airport": "FGD",
        "NDB | Nouadhibou International Airport": "NDB",
        "OUZ | Tazadit Airport": "OUZ",
        "GRC | Grand Cess Airport": "GRC",
        "GMT | Granite Mountain Air Station": "GMT",
        "CIQ | Chiquimula Airport": "CIQ",
        "DON | Dos Lagunas Airport": "DON",
        "ENJ | El Naranjo Airport": "ENJ",
        "PCG | Paso Caballos Airport": "PCG",
        "TKM | El Petén Airport": "TKM",
        "UAX | Uaxactun Airport": "UAX",
        "PKJ | Playa Grande Airport": "PKJ",
        "GTZ | Kirawira B Aerodrome": "GTZ",
        "CKY | Conakry International Airport": "CKY",
        "GUE | Guriaso (Keraso) Airport": "GUE",
        "FIG | Fria Airport": "FIG",
        "FAA | Faranah Airport": "FAA",
        "KSI | Kissidougou Airport": "KSI",
        "LEK | Tata Airport": "LEK",
        "MCA | Macenta Airport": "MCA",
        "NZE | Nzérékoré Airport": "NZE",
        "BKJ | Boké Baralande Airport": "BKJ",
        "SBI | Sambailo Airport": "SBI",
        "GII | Siguiri Airport": "GII",
        "KNN | Kankan Airport": "KNN",
        "SID | Amílcar Cabral International Airport": "SID",
        "NTO | Agostinho Neto Airport": "NTO",
        "BVC | Rabil Airport": "BVC",
        "GVE | Gordonsville Municipal Airport": "GVE",
        "MMO | Maio Airport": "MMO",
        "MTI | Mosteiros Airport": "MTI",
        "RAI | Praia International Airport": "RAI",
        "SFL | São Filipe Airport": "SFL",
        "SNE | Preguiça Airport": "SNE",
        "VXE | São Pedro Airport": "VXE",
        "BCG | Bemichi Airport": "BCG",
        "BTO | Botopasi Airport": "BTO",
        "DOE | Djumu-Djomoe Airport": "DOE",
        "LDO | Ladouanie Airport": "LDO",
        "WSO | Washabo Airport": "WSO",
        "GZI | Ghazni Airport": "GZI",
        "ADD | Addis Ababa Bole International Airport": "ADD",
        "AMH | Arba Minch Airport": "AMH",
        "AXU | Axum Airport": "AXU",
        "BCO | Baco Airport": "BCO",
        "BJR | Bahir Dar Airport": "BJR",
        "BEI | Beica Airport": "BEI",
        "DSE | Combolcha Airport": "DSE",
        "DEM | Dembidollo Airport": "DEM",
        "DBM | Debra Marcos Airport": "DBM",
        "DIR | Aba Tenna Dejazmach Yilma International Airport": "DIR",
        "DBT | Debre Tabor Airport": "DBT",
        "FNH | Fincha Airport": "FNH",
        "GOB | Robe Airport": "GOB",
        "GNN | Ghinnir Airport": "GNN",
        "GMB | Gambella Airport": "GMB",
        "GDQ | Gonder Airport": "GDQ",
        "GDE | Gode Airport": "GDE",
        "GOR | Gore Airport": "GOR",
        "QHR | Harar Meda Airport": "QHR",
        "HUE | Humera Airport": "HUE",
        "JIJ | Wilwal International Airport": "JIJ",
        "JIM | Jimma Airport": "JIM",
        "ABK | Kebri Dahar Airport": "ABK",
        "LFO | Kelafo East Airport": "LFO",
        "AWA | Awasa Airport": "AWA",
        "LLI | Lalibella Airport": "LLI",
        "MKS | Mekane Selam Airport": "MKS",
        "MQX | Mekele Airport": "MQX",
        "ETE | Metema Airport": "ETE",
        "NDM | Mendi Airport": "NDM",
        "MUJ | Mui River Airport": "MUJ",
        "MTF | Mizan Teferi Airport": "MTF",
        "EGL | Negele Airport": "EGL",
        "NEJ | Nejjo Airport": "NEJ",
        "NEK | Nekemte Airport": "NEK",
        "PWI | Beles Airport": "PWI",
        "SXU | Soddu Airport": "SXU",
        "SKR | Shakiso Airport": "SKR",
        "SZE | Semera Airport": "SZE",
        "ASO | Asosa Airport": "ASO",
        "TIE | Tippi Airport": "TIE",
        "WAC | Waca Airport": "WAC",
        "WRA | Warder Airport": "WRA",
        "HAY | Haycock Airport": "HAY",
        "HAZ | Hatzfeldhaven Airport": "HAZ",
        "BJM | Bujumbura Melchior Ndadaye International Airport": "BJM",
        "GID | Gitega Airport": "GID",
        "KRE | Kirundo Airport": "KRE",
        "HBQ | Haibei Qilian Airport": "HBQ",
        "HBT | Hambantota Seaplane Base": "HBT",
        "ALU | Alula Airport": "ALU",
        "BIB | Baidoa Airport": "BIB",
        "CXN | Candala Airport": "CXN",
        "BSY | Bardera Airport": "BSY",
        "HCM | Eil Airport": "HCM",
        "BSA | Bosaso / Bender Qassim International Airport": "BSA",
        "GSR | Gardo Airport": "GSR",
        "HGA | Egal International Airport": "HGA",
        "BBO | Berbera Airport": "BBO",
        "LGX | Lugh Ganane Airport": "LGX",
        "KMU | Kismayo Airport": "KMU",
        "MGQ | Aden Adde International Airport": "MGQ",
        "BLW | Beledweyne Airport": "BLW",
        "CMO | Hobyo Airport": "CMO",
        "GLK | Galcaio Airport": "GLK",
        "CMS | Iskushuban Airport": "CMS",
        "ERA | Erigavo Airport": "ERA",
        "BUO | Burao Airport": "BUO",
        "GGR | Garowe Airport": "GGR",
        "JIB | Djibouti-Ambouli Airport": "JIB",
        "AII | Ali-Sabieh Airport": "AII",
        "MHI | Moucha Airport": "MHI",
        "OBC | Obock Airport": "OBC",
        "TDJ | Tadjoura Airport": "TDJ",
        "EMY | El Minya Airport": "EMY",
        "SQK | Sidi Barrani Airport": "SQK",
        "DBB | El Alamein International Airport": "DBB",
        "AAC | El Arish International Airport": "AAC",
        "ATZ | Asyut International Airport": "ATZ",
        "ALY | El Nuzha Airport": "ALY",
        "HBE | Borg El Arab International Airport": "HBE",
        "ABS | Abu Simbel Airport": "ABS",
        "CAI | Cairo International Airport": "CAI",
        "CCE | Capital International Airport": "CCE",
        "DAK | Dakhla Airport": "DAK",
        "HRG | Hurghada International Airport": "HRG",
        "EGH | El Gora Airport": "EGH",
        "UVL | El Kharga Airport": "UVL",
        "LXR | Luxor International Airport": "LXR",
        "RMF | Marsa Alam International Airport": "RMF",
        "MUH | Marsa Matruh Airport": "MUH",
        "HEO | Haelogo Airport": "HEO",
        "GSQ | Sharq El Owainat International Airport": "GSQ",
        "PSD | Port Said Airport": "PSD",
        "SKV | Saint Catherine International Airport": "SKV",
        "HMB | Sohag International Airport": "HMB",
        "SSH | Sharm El Sheikh International Airport": "SSH",
        "ASW | Aswan International Airport": "ASW",
        "SEW | Siwa Oasis North Airport": "SEW",
        "SPX | Sphinx International Airport": "SPX",
        "TCP | Taba International Airport": "TCP",
        "ELT | El Tor Airport": "ELT",
        "ASM | Asmara International Airport": "ASM",
        "MSW | Massawa International Airport": "MSW",
        "ASA | Assab International Airport": "ASA",
        "TES | Tessenei Airport": "TES",
        "HPV | Princeville Airport": "HPV",
        "HIA | Lianshui Airport": "HIA",
        "HIL | Shilavo Airport": "HIL",
        "JUB | Juba International Airport": "JUB",
        "ASV | Amboseli Airport": "ASV",
        "HKB | Healy Lake Airport": "HKB",
        "EDL | Eldoret International Airport": "EDL",
        "EYS | Eliye Springs Airport": "EYS",
        "KLK | Kalokol Airport": "KLK",
        "GAS | Garissa Airport": "GAS",
        "HOA | Hola Airport": "HOA",
        "NBO | Jomo Kenyatta International Airport": "NBO",
        "KEU | Keekorok Airport": "KEU",
        "GGM | Kakamega Airport": "GGM",
        "KIS | Kisumu Airport": "KIS",
        "ILU | Kilaguni Airport": "ILU",
        "KEY | Kericho Airport": "KEY",
        "KTL | Kitale Airport": "KTL",
        "LKG | Lokichoggio Airport": "LKG",
        "LOK | Lodwar Airport": "LOK",
        "LAU | Manda Airstrip": "LAU",
        "LOY | Loyengalani Airport": "LOY",
        "NDE | Mandera Airport": "NDE",
        "RBT | Marsabit Airport": "RBT",
        "JJM | Mulika Lodge Airport": "JJM",
        "MYD | Malindi Airport": "MYD",
        "MBA | Moi International Airport": "MBA",
        "MRE | Mara Serena Lodge Airstrip": "MRE",
        "OYL | Moyale Airport": "OYL",
        "NYE | Nyeri Airport": "NYE",
        "NUU | Nakuru Airport": "NUU",
        "WIL | Nairobi Wilson Airport": "WIL",
        "NYK | Nanyuki Airport": "NYK",
        "UAS | Buffalo Spring": "UAS",
        "UKA | Ukunda Airstrip": "UKA",
        "WJR | Wajir Airport": "WJR",
        "SRX | Gardabya Airport": "SRX",
        "TOB | Gamal Abdel Nasser Airport": "TOB",
        "GHT | Ghat Airport": "GHT",
        "AKF | Kufra Airport": "AKF",
        "BEN | Benina International Airport": "BEN",
        "MJI | Mitiga Airport": "MJI",
        "LAQ | Al Abraq International Airport": "LAQ",
        "SEB | Sabha Airport": "SEB",
        "TIP | Tripoli International Airport": "TIP",
        "LMQ | Marsa Brega Airport": "LMQ",
        "NFR | Nafurah 1 Airport": "NFR",
        "HUQ | Hon Airport": "HUQ",
        "LTD | Ghadames East Airport": "LTD",
        "ZIS | Alzintan Airport": "ZIS",
        "WAX | Zwara Airport": "WAX",
        "EDQ | Erandique Airport": "EDQ",
        "HOO | Nhon Co Airfield": "HOO",
        "HRC | Sary Su Airport": "HRC",
        "GYI | Gisenyi Airport": "GYI",
        "BTQ | Butare Airport": "BTQ",
        "KGL | Kigali International Airport": "KGL",
        "RHG | Ruhengeri Airport": "RHG",
        "KME | Kamembe Airport": "KME",
        "ATB | Atbara Airport": "ATB",
        "EDB | El Debba Airport": "EDB",
        "DOG | Dongola Airport": "DOG",
        "RSS | Damazin Airport": "RSS",
        "HGI | Paloich Airport Heliport": "HGI",
        "ELF | El Fasher Airport": "ELF",
        "DNX | Galegu Airport": "DNX",
        "EGN | Geneina Airport": "EGN",
        "HEG | Heglig Airport": "HEG",
        "HSJ | Shangjie Airport": "HSJ",
        "KSL | Kassala Airport": "KSL",
        "GBU | Khashm El Girba Airport": "GBU",
        "KDX | Kadugli Airport": "KDX",
        "RBX | Rumbek Airport": "RBX",
        "MWE | Merowe New Airport": "MWE",
        "NUD | En Nahud Airport": "NUD",
        "UYL | Nyala Airport": "UYL",
        "NHF | New Halfa Airport": "NHF",
        "EBD | El Obeid Airport": "EBD",
        "PZU | Port Sudan New International Airport": "PZU",
        "KRT | Khartoum International Airport": "KRT",
        "MAK | Malakal Airport": "MAK",
        "WHF | Wadi Halfa Airport": "WHF",
        "DNI | Wad Medani Airport": "DNI",
        "WUU | Wau Airport": "WUU",
        "ZLX | Zalingei Airport": "ZLX",
        "ARK | Arusha Airport": "ARK",
        "BKZ | Bukoba Airport": "BKZ",
        "DAR | Julius Nyerere International Airport": "DAR",
        "DOD | Dodoma Airport": "DOD",
        "MBI | Songwe Airport": "MBI",
        "IRI | Iringa Airport": "IRI",
        "TKQ | Kigoma Airport": "TKQ",
        "KIY | Kilwa Airport": "KIY",
        "JRO | Kilimanjaro International Airport": "JRO",
        "LDI | Lindi Airport": "LDI",
        "LKY | Lake Manyara Airport": "LKY",
        "HTM | Khatgal Airport": "HTM",
        "MFA | Mafia Airport": "MFA",
        "MWN | Mwadui Airport": "MWN",
        "XMI | Masasi Airport": "XMI",
        "NPY | Mpanda Airport": "NPY",
        "QSI | Moshi Airport": "QSI",
        "MYW | Mtwara Airport": "MYW",
        "MUZ | Musoma Airport": "MUZ",
        "MWZ | Mwanza Airport": "MWZ",
        "NCH | Nachingwea Airport": "NCH",
        "JOM | Njombe Airport": "JOM",
        "PMA | Pemba Airport": "PMA",
        "SEU | Seronera Airport": "SEU",
        "SGX | Songea Airport": "SGX",
        "SUT | Sumbawanga Airport": "SUT",
        "SHY | Shinyanga Airport": "SHY",
        "TBO | Tabora Airport": "TBO",
        "TGT | Tanga Airport": "TGT",
        "ZNZ | Abeid Amani Karume International Airport": "ZNZ",
        "RUA | Arua Airport": "RUA",
        "EBB | Entebbe International Airport": "EBB",
        "ULU | Gulu Airport": "ULU",
        "JIN | Jinja Airport": "JIN",
        "PAF | Pakuba Airfield": "PAF",
        "KSE | Kasese Airport": "KSE",
        "MBQ | Mbarara Airport": "MBQ",
        "KCU | Masindi Airport": "KCU",
        "SRT | Soroti Airport": "SRT",
        "TRY | Tororo Airport": "TRY",
        "HWA | Hawabango Airport": "HWA",
        "HYL | Hollis Clark Bay Seaplane Base": "HYL",
        "IBI | Iboki Airport": "IBI",
        "IBL | Indigo Bay Lodge Airport": "IBL",
        "ICO | Sicogon Airstrip": "ICO",
        "PPJ | Pulau Panjang Airport": "PPJ",
        "JBB | Notohadinegoro Airport": "JBB",
        "BWX | Banyuwangi International Airport": "BWX",
        "BXW | Bawean - Harun Thohir Airport": "BXW",
        "AAS | Apalapsili Airport": "AAS",
        "AGD | Anggi Airport": "AGD",
        "AKQ | Gunung Batin Airport": "AKQ",
        "AYW | Ayawasi Airport": "AYW",
        "BJG | Boalang Airport": "BJG",
        "BXM | Batom Airport": "BXM",
        "DRH | Dabra Airport": "DRH",
        "ELR | Elelim Airport": "ELR",
        "EWE | Ewer Airport": "EWE",
        "FOO | Kornasoren Airfield": "FOO",
        "GAV | Gag Island Airport": "GAV",
        "IUL | Ilu Airport": "IUL",
        "KBF | Karubaga Airport": "KBF",
        "KBX | Kambuaya Airport": "KBX",
        "KCD | Kamur Airport": "KCD",
        "KCI | Kon Airport": "KCI",
        "KOD | Kotabangun Airport": "KOD",
        "KRC | Departi Parbo Airport": "KRC",
        "LLN | Kelila Airport": "LLN",
        "LWE | Lewoleba Airport": "LWE",
        "LYK | Lunyuk Airport": "LYK",
        "MSI | Masalembo Airport": "MSI",
        "MUF | Muting Airport": "MUF",
        "NAF | Banaina Airport": "NAF",
        "OBD | Obano Airport": "OBD",
        "PUM | Pomala Airport": "PUM",
        "PWL | Purwokerto Airport": "PWL",
        "RAQ | Sugimanuru Airport": "RAQ",
        "RKI | Rokot Airport": "RKI",
        "RUF | Yuruf Airport": "RUF",
        "SAE | Sangir Airport": "SAE",
        "TBM | Tumbang Samba Airport": "TBM",
        "TMY | Tiom Airport": "TMY",
        "ZEG | Senggo Airport": "ZEG",
        "UGU | Bilogai-Sugapa Airport": "UGU",
        "IDN | Indagen Airport": "IDN",
        "IMA | Iamalele Airport": "IMA",
        "IMG | Inhaminga Airport": "IMG",
        "PYG | Pakyong Airport": "PYG",
        "VDY | Jindal Vijaynagar Airport": "VDY",
        "JGB | Jagdalpur Airport": "JGB",
        "NVY | Neyveli Airport": "NVY",
        "RJI | Rajouri Airport": "RJI",
        "TEI | Tezu Airport": "TEI",
        "INE | Chinde Airport": "INE",
        "IOK | Iokea Airport": "IOK",
        "IOP | Ioma Airport": "IOP",
        "KHA | Khaneh Airport": "KHA",
        "IRU | Iranamadu Seaplane Base": "IRU",
        "ISG | New Ishigaki Airport": "ISG",
        "ITK | Itokama Airport": "ITK",
        "IVI | Viveros Island Airport": "IVI",
        "JGD | Jiagedaqi Airport": "JGD",
        "JIC | Jinchuan Airport": "JIC",
        "JIO | Jos Orno Imsula Airport": "JIO",
        "JIQ | Qianjiang Wulingshan Airport": "JIQ",
        "JJD | Jericoacoara - Comandante Ariston Pessoa Regional Airport": "JJD",
        "JLA | Quartz Creek Airport": "JLA",
        "JNZ | Jinzhou Bay Airport": "JNZ",
        "JOP | Josephstaal Airport": "JOP",
        "JUH | Chizhou Jiuhuashan Airport": "JUH",
        "AMK | Animas Air Park": "AMK",
        "BDX | Broadus Airport": "BDX",
        "EUE | Eureka Airport": "EUE",
        "KPT | Jackpot Airport/Hayden Field": "KPT",
        "RLA | Rolla Downtown Airport": "RLA",
        "FID | Elizabeth Field": "FID",
        "HUD | Humboldt Municipal Airport": "HUD",
        "TWD | Jefferson County International Airport": "TWD",
        "MVM | Kayenta Airport": "MVM",
        "HCC | Columbia County Airport": "HCC",
        "AHD | Ardmore Downtown Executive Airport": "AHD",
        "GCW | Grand Canyon West Airport": "GCW",
        "CKE | Lampson Field": "CKE",
        "ROF | Montague-Yreka Rohrer Field": "ROF",
        "CNE | Fremont County Airport": "CNE",
        "RSX | Rouses Point Seaplane Base": "RSX",
        "COP | Cooperstown-Westville Airport": "COP",
        "CIL | Council Airport": "CIL",
        "IRB | Iraan Municipal Airport": "IRB",
        "GNF | Gansner Field": "GNF",
        "CHZ | Chiloquin State Airport": "CHZ",
        "LTW | St. Mary's County Regional Airport": "LTW",
        "AHF | Arapahoe Municipal Airport": "AHF",
        "PCT | Princeton Airport": "PCT",
        "CTO | Calverton Executive Airpark": "CTO",
        "NRI | Grand Lake Regional Airport": "NRI",
        "GTP | Grants Pass Airport": "GTP",
        "NLE | Jerry Tyler Memorial Airport": "NLE",
        "GCD | Grand Coulee Dam Airport": "GCD",
        "VLE | Valle Airport": "VLE",
        "FPY | Perry-Foley Airport": "FPY",
        "NTJ | Manti-Ephraim Airport": "NTJ",
        "SBO | Salina Gunnison Airport": "SBO",
        "JVI | Central Jersey Regional Airport": "JVI",
        "UCE | Eunice Airport": "UCE",
        "GOL | Gold Beach Municipal Airport": "GOL",
        "KKT | Kentland Municipal Airport": "KKT",
        "PRW | Prentice Airport": "PRW",
        "EGP | Maverick County Memorial International Airport": "EGP",
        "BLD | Boulder City-Lake Mead International Airport": "BLD",
        "MFH | Mesquite International Airport": "MFH",
        "ECA | Iosco County Airport": "ECA",
        "FMU | Florence Municipal Airport": "FMU",
        "OTS | Anacortes Airport": "OTS",
        "ROL | Roosevelt Municipal Airport": "ROL",
        "CTK | Canton Municipal Airport": "CTK",
        "WPO | North Fork Valley Airport": "WPO",
        "ATE | Antlers Municipal Airport": "ATE",
        "ASQ | Austin Airport": "ASQ",
        "AAF | Apalachicola Regional Airport": "AAF",
        "ABE | Lehigh Valley International Airport": "ABE",
        "ABI | Abilene Regional Airport": "ABI",
        "ABQ | Albuquerque International Sunport": "ABQ",
        "ABR | Aberdeen Regional Airport": "ABR",
        "ABY | Southwest Georgia Regional Airport": "ABY",
        "ACB | Antrim County Airport": "ACB",
        "ACK | Nantucket Memorial Airport": "ACK",
        "ACT | Waco Regional Airport": "ACT",
        "ACV | California Redwood Coast-Humboldt County Airport": "ACV",
        "ACY | Atlantic City International Airport": "ACY",
        "ADG | Lenawee County Airport": "ADG",
        "ADT | Ada Regional Airport": "ADT",
        "ADM | Ardmore Municipal Airport": "ADM",
        "ADS | Addison Airport": "ADS",
        "ADW | Joint Base Andrews": "ADW",
        "KAE | Kake Seaplane Base": "KAE",
        "AEL | Albert Lea Municipal Airport": "AEL",
        "AEX | Alexandria International Airport": "AEX",
        "KAF | Karato Airport": "KAF",
        "AFF | USAF Academy Airfield": "AFF",
        "WSG | Washington County Airport": "WSG",
        "AFN | Jaffrey Airport Silver Ranch Airport": "AFN",
        "AFO | Afton Municipal Airport": "AFO",
        "AFW | Fort Worth Alliance Airport": "AFW",
        "AGC | Allegheny County Airport": "AGC",
        "AGO | Magnolia Municipal Airport / Ralph C Weiser Field": "AGO",
        "AGS | Augusta Regional At Bush Field": "AGS",
        "AHC | Amedee Army Air Field": "AHC",
        "AHH | Amery Municipal Airport": "AHH",
        "AHN | Athens Ben Epps Airport": "AHN",
        "AIA | Alliance Municipal Airport": "AIA",
        "AID | Anderson Municipal Darlington Field": "AID",
        "AIK | Aiken Regional Airport": "AIK",
        "AIO | Atlantic Municipal Airport": "AIO",
        "AIV | George Downer Airport": "AIV",
        "AIZ | Lee C Fine Memorial Airport": "AIZ",
        "KAK | Kar Airport": "KAK",
        "AKO | Colorado Plains Regional Airport": "AKO",
        "AKC | Akron Executive Airport": "AKC",
        "ALB | Albany International Airport": "ALB",
        "ALI | Alice International Airport": "ALI",
        "ALM | Alamogordo White Sands Regional Airport": "ALM",
        "ALN | St Louis Regional Airport": "ALN",
        "ALO | Waterloo Regional Airport": "ALO",
        "ALS | San Luis Valley Regional Airport/Bergman Field": "ALS",
        "ALW | Walla Walla Regional Airport": "ALW",
        "ALX | Thomas C Russell Field": "ALX",
        "AMA | Rick Husband Amarillo International Airport": "AMA",
        "AMN | Gratiot Community Airport": "AMN",
        "AMW | Ames Municipal Airport": "AMW",
        "ANB | Anniston Regional Airport": "ANB",
        "AND | Anderson Regional Airport": "AND",
        "SLT | Salida Airport Harriett Alexander Field": "SLT",
        "ANP | Lee Airport": "ANP",
        "ANQ | Tri State Steuben County Airport": "ANQ",
        "ANW | Ainsworth Regional Airport": "ANW",
        "ANY | Anthony Municipal Airport": "ANY",
        "AOH | Lima Allen County Airport": "AOH",
        "AOO | Altoona Blair County Airport": "AOO",
        "APA | Centennial Airport": "APA",
        "APC | Napa County Airport": "APC",
        "APF | Naples Municipal Airport": "APF",
        "APG | Phillips Army Air Field": "APG",
        "APH | A P Hill AAF (Fort A P Hill) Airport": "APH",
        "APN | Alpena County Regional Airport": "APN",
        "APT | Marion County Brown Field": "APT",
        "APV | Apple Valley Airport": "APV",
        "KAQ | Kamulai Airport": "KAQ",
        "ARA | Acadiana Regional Airport": "ARA",
        "ARB | Ann Arbor Municipal Airport": "ARB",
        "ARG | Walnut Ridge Regional Airport": "ARG",
        "WHT | Wharton Regional Airport": "WHT",
        "AUZ | Aurora Municipal Airport": "AUZ",
        "ART | Watertown International Airport": "ART",
        "ARV | Lakeland-Noble F. Lee Memorial field": "ARV",
        "BFT | Beaufort County Airport": "BFT",
        "ASE | Aspen-Pitkin Co/Sardy Field": "ASE",
        "SPZ | Springdale Municipal Airport": "SPZ",
        "ASH | Nashua Airport / Boire Field": "ASH",
        "ASL | Harrison County Airport": "ASL",
        "ASN | Talladega Municipal Airport": "ASN",
        "AST | Astoria Regional Airport": "AST",
        "ASX | John F Kennedy Memorial Airport": "ASX",
        "ASY | Ashley Municipal Airport": "ASY",
        "ATL | Hartsfield Jackson Atlanta International Airport": "ATL",
        "ATS | Artesia Municipal Airport": "ATS",
        "ATW | Appleton International Airport": "ATW",
        "ATY | Watertown Regional Airport": "ATY",
        "AUG | Augusta State Airport": "AUG",
        "AUM | Austin Municipal Airport": "AUM",
        "AUN | Auburn Municipal Airport": "AUN",
        "AUO | Auburn University Regional Airport": "AUO",
        "AUS | Austin Bergstrom International Airport": "AUS",
        "AUW | Wausau Downtown Airport": "AUW",
        "AVL | Asheville Regional Airport": "AVL",
        "AVO | Avon Park Executive Airport": "AVO",
        "AVP | Wilkes Barre Scranton International Airport": "AVP",
        "AVW | Marana Regional Airport": "AVW",
        "AVX | Catalina Airport": "AVX",
        "AWM | West Memphis Municipal Airport": "AWM",
        "AXG | Algona Municipal Airport": "AXG",
        "AXN | Chandler Field": "AXN",
        "AXS | Altus Quartz Mountain Regional Airport": "AXS",
        "AXV | Neil Armstrong Airport": "AXV",
        "AXX | Angel Fire Airport": "AXX",
        "AYS | Waycross Ware County Airport": "AYS",
        "TUH | Arnold Air Force Base": "TUH",
        "AZO | Kalamazoo Battle Creek International Airport": "AZO",
        "BAB | Beale Air Force Base": "BAB",
        "BAD | Barksdale Air Force Base": "BAD",
        "BAF | Westfield-Barnes Regional Airport": "BAF",
        "CLU | Columbus Municipal Airport": "CLU",
        "BAM | Battle Mountain Airport": "BAM",
        "BBB | Benson Municipal Airport": "BBB",
        "BBD | Curtis Field": "BBD",
        "BTN | Marlboro County Jetport H.E. Avent Field": "BTN",
        "BBW | Broken Bow Municipal Airport": "BBW",
        "BCB | Virginia Tech Montgomery Executive Airport": "BCB",
        "BCE | Bryce Canyon Airport": "BCE",
        "BCT | Boca Raton Airport": "BCT",
        "BDE | Baudette International Airport": "BDE",
        "BDG | Blanding Municipal Airport": "BDG",
        "BDL | Bradley International Airport": "BDL",
        "BDR | Igor I Sikorsky Memorial Airport": "BDR",
        "WBU | Boulder Municipal Airport": "WBU",
        "KBE | Bell Island Hot Springs Seaplane Base": "KBE",
        "BEC | Beech Factory Airport": "BEC",
        "BED | Laurence G Hanscom Field": "BED",
        "BEH | Southwest Michigan Regional Airport": "BEH",
        "BFD | Bradford Regional Airport": "BFD",
        "BFF | Western Neb. Rgnl/William B. Heilig Airport": "BFF",
        "BFI | Boeing Field King County International Airport": "BFI",
        "BFL | Meadows Field": "BFL",
        "BFM | Mobile Downtown Airport": "BFM",
        "BFR | Virgil I Grissom Municipal Airport": "BFR",
        "BGD | Hutchinson County Airport": "BGD",
        "BGE | Decatur County Industrial Air Park": "BGE",
        "BGM | Greater Binghamton/Edwin A Link field": "BGM",
        "BGR | Bangor International Airport": "BGR",
        "KBH | Kahama Airstrip": "KBH",
        "BHB | Hancock County-Bar Harbor Airport": "BHB",
        "BHM | Birmingham-Shuttlesworth International Airport": "BHM",
        "BID | Block Island State Airport": "BID",
        "BIE | Beatrice Municipal Airport": "BIE",
        "BIF | Biggs Army Air Field (Fort Bliss)": "BIF",
        "BIH | Eastern Sierra Regional Airport": "BIH",
        "BIL | Billings Logan International Airport": "BIL",
        "BIS | Bismarck Municipal Airport": "BIS",
        "BIX | Keesler Air Force Base": "BIX",
        "BJC | Rocky Mountain Metropolitan Airport": "BJC",
        "BJI | Bemidji Regional Airport": "BJI",
        "BJJ | Wayne County Airport": "BJJ",
        "BKD | Stephens County Airport": "BKD",
        "BKE | Baker City Municipal Airport": "BKE",
        "BFK | Buckley Space Force Base": "BFK",
        "BKL | Burke Lakefront Airport": "BKL",
        "BKT | Allen C Perkinson Blackstone Army Air Field": "BKT",
        "BKW | Raleigh County Memorial Airport": "BKW",
        "BKX | Brookings Regional Airport": "BKX",
        "BLF | Mercer County Airport": "BLF",
        "BLH | Blythe Airport": "BLH",
        "BLI | Bellingham International Airport": "BLI",
        "BLM | Monmouth Executive Airport": "BLM",
        "BLU | Blue Canyon Nyack Airport": "BLU",
        "BLV | Scott AFB/Midamerica Airport": "BLV",
        "KBM | Kabwum": "KBM",
        "BMC | Brigham City Regional Airport": "BMC",
        "BMG | Monroe County Airport": "BMG",
        "BMI | Central Illinois Regional Airport at Bloomington-Normal": "BMI",
        "BML | Berlin Regional Airport": "BML",
        "BMT | Beaumont Municipal Airport": "BMT",
        "BNA | Nashville International Airport": "BNA",
        "BNG | Banning Municipal Airport": "BNG",
        "BNL | Barnwell Regional Airport": "BNL",
        "BNO | Burns Municipal Airport": "BNO",
        "BNW | Boone Municipal Airport": "BNW",
        "BOI | Boise Air Terminal/Gowen Field": "BOI",
        "BOS | Logan International Airport": "BOS",
        "BOW | Bartow Executive Airport": "BOW",
        "HCA | Big Spring Mc Mahon-Wrinkle Airport": "HCA",
        "BPI | Miley Memorial Field": "BPI",
        "WMH | Ozark Regional Airport": "WMH",
        "BPT | Jack Brooks Regional Airport": "BPT",
        "BQK | Brunswick Golden Isles Airport": "BQK",
        "BRD | Brainerd Lakes Regional Airport": "BRD",
        "BRL | Southeast Iowa Regional Airport": "BRL",
        "BRO | Brownsville South Padre Island International Airport": "BRO",
        "BRY | Samuels Field": "BRY",
        "KBT | Kaben Airport": "KBT",
        "BTF | Skypark Airport": "BTF",
        "BTL | Battle Creek Executive Airport at Kellogg Field": "BTL",
        "BTM | Bert Mooney Airport": "BTM",
        "TTO | Britton Municipal Airport": "TTO",
        "BTP | Pittsburgh/Butler Regional Airport": "BTP",
        "BTR | Baton Rouge Metropolitan Airport": "BTR",
        "BTV | Burlington International Airport": "BTV",
        "BTY | Beatty Airport": "BTY",
        "BUB | Cram Field": "BUB",
        "BUF | Buffalo Niagara International Airport": "BUF",
        "BUM | Butler Memorial Airport": "BUM",
        "BUR | Bob Hope Airport": "BUR",
        "BFP | Beaver County Airport": "BFP",
        "BVO | Bartlesville Municipal Airport": "BVO",
        "MVW | Skagit Regional Airport": "MVW",
        "BVX | Batesville Regional Airport": "BVX",
        "BVY | Beverly Regional Airport": "BVY",
        "BWC | Brawley Municipal Airport": "BWC",
        "BWD | Brownwood Regional Airport": "BWD",
        "BWG | Bowling Green Warren County Regional Airport": "BWG",
        "BWI | Baltimore/Washington International Thurgood Marshall Airport": "BWI",
        "WAH | Harry Stern Airport": "WAH",
        "BWM | Bowman Regional Airport": "BWM",
        "BXA | George R Carr Memorial Air Field": "BXA",
        "BXK | Buckeye Municipal Airport": "BXK",
        "BYG | Johnson County Airport": "BYG",
        "BYH | Arkansas International Airport": "BYH",
        "BYI | Burley Municipal Airport": "BYI",
        "BYS | Bicycle Lake Army Air Field": "BYS",
        "BBC | Bay City Regional Airport": "BBC",
        "BZN | Gallatin Field": "BZN",
        "XES | Grand Geneva Resort Airport": "XES",
        "PLY | Plymouth Municipal Airport": "PLY",
        "CLG | New Coalinga Municipal Airport": "CLG",
        "CAD | Wexford County Airport": "CAD",
        "CAE | Columbia Metropolitan Airport": "CAE",
        "CIG | Craig Moffat Airport": "CIG",
        "CAK | Akron Canton Regional Airport": "CAK",
        "CAO | Clayton Municipal Airpark": "CAO",
        "CAR | Caribou Municipal Airport": "CAR",
        "CBE | Greater Cumberland Regional Airport": "CBE",
        "CBF | Council Bluffs Municipal Airport": "CBF",
        "CBK | Shalz Field": "CBK",
        "CBM | Columbus Air Force Base": "CBM",
        "KCC | Coffman Cove Seaplane Base": "KCC",
        "CCB | Cable Airport": "CCB",
        "CCR | Buchanan Field": "CCR",
        "CCY | Northeast Iowa Regional Airport": "CCY",
        "LLX | Caledonia County Airport": "LLX",
        "CDC | Cedar City Regional Airport": "CDC",
        "CDH | Harrell Field": "CDH",
        "CDN | Woodward Field": "CDN",
        "CDR | Chadron Municipal Airport": "CDR",
        "CDS | Childress Municipal Airport": "CDS",
        "CDW | Essex County Airport": "CDW",
        "CEA | Cessna Aircraft Field": "CEA",
        "CEC | Jack Mc Namara Field Airport": "CEC",
        "CEF | Westover ARB/Metropolitan Airport": "CEF",
        "CEU | Oconee County Regional Airport": "CEU",
        "CEV | Mettel Field": "CEV",
        "CEW | Bob Sikes Airport": "CEW",
        "CEY | Kyle Oakley Field": "CEY",
        "CEZ | Cortez Municipal Airport": "CEZ",
        "CFD | Coulter Field": "CFD",
        "TZC | Tuscola Area Airport": "TZC",
        "CFT | Greenlee County Airport": "CFT",
        "CFV | Coffeyville Municipal Airport": "CFV",
        "CGE | Cambridge Dorchester Airport": "CGE",
        "CGF | Cuyahoga County Airport": "CGF",
        "CGI | Cape Girardeau Regional Airport": "CGI",
        "CGS | College Park Airport": "CGS",
        "CGZ | Casa Grande Municipal Airport": "CGZ",
        "CHA | Chattanooga Metropolitan Airport (Lovell Field)": "CHA",
        "CHK | Chickasha Municipal Airport": "CHK",
        "CHO | Charlottesville Albemarle Airport": "CHO",
        "CHS | Charleston International Airport": "CHS",
        "CIC | Chico Municipal Airport": "CIC",
        "CID | The Eastern Iowa Airport": "CID",
        "CIN | Arthur N Neu Airport": "CIN",
        "CIR | Cairo Regional Airport": "CIR",
        "CIU | Chippewa County International Airport": "CIU",
        "CKA | Kegelman AF Aux Field": "CKA",
        "CKB | North Central West Virginia Airport": "CKB",
        "GRM | Grand Marais Cook County Airport": "GRM",
        "CKM | Fletcher Field": "CKM",
        "CKN | Crookston Municipal Kirkwood Field": "CKN",
        "CKV | Clarksville–Montgomery County Regional Airport": "CKV",
        "KCL | Chignik Lagoon Airport": "KCL",
        "CLE | Cleveland Hopkins International Airport": "CLE",
        "CLI | Clintonville Municipal Airport": "CLI",
        "CLK | Clinton Regional Airport": "CLK",
        "CLL | Easterwood Field": "CLL",
        "CLM | William R Fairchild International Airport": "CLM",
        "CLR | Cliff Hatfield Memorial Airport": "CLR",
        "CLS | Chehalis Centralia Airport": "CLS",
        "CLT | Charlotte Douglas International Airport": "CLT",
        "CLW | Clearwater Air Park": "CLW",
        "CMH | John Glenn Columbus International Airport": "CMH",
        "CMI | University of Illinois Willard Airport": "CMI",
        "CMX | Houghton County Memorial Airport": "CMX",
        "CMY | Sparta Fort Mc Coy Airport": "CMY",
        "KCN | Chernofski Harbor Seaplane Base": "KCN",
        "CNH | Claremont Municipal Airport": "CNH",
        "CNK | Blosser Municipal Airport": "CNK",
        "CNM | Cavern City Air Terminal": "CNM",
        "CNO | Chino Airport": "CNO",
        "CNU | Chanute Martin Johnson Airport": "CNU",
        "CNW | TSTC Waco Airport": "CNW",
        "CNY | Canyonlands Field": "CNY",
        "COD | Yellowstone Regional Airport": "COD",
        "COE | Coeur D'Alene - Pappy Boyington Field": "COE",
        "COF | Patrick Air Force Base": "COF",
        "COI | Merritt Island Airport": "COI",
        "COM | Coleman Municipal Airport": "COM",
        "CON | Concord Municipal Airport": "CON",
        "COS | City of Colorado Springs Municipal Airport": "COS",
        "COT | Cotulla-La Salle County Airport": "COT",
        "COU | Columbia Regional Airport": "COU",
        "CPM | Compton Woodley Airport": "CPM",
        "CPR | Casper-Natrona County International Airport": "CPR",
        "CPS | St Louis Downtown Airport": "CPS",
        "HCW | Cheraw Municipal Airport/Lynch Bellinger Field": "HCW",
        "KCR | Colorado Creek Airport": "KCR",
        "CRE | Grand Strand Airport": "CRE",
        "CRG | Jacksonville Executive at Craig Airport": "CRG",
        "CRO | Corcoran Airport": "CRO",
        "CRP | Corpus Christi International Airport": "CRP",
        "CLD | McClellan-Palomar Airport": "CLD",
        "CRS | C David Campbell Field Corsicana Municipal Airport": "CRS",
        "CRT | Z M Jack Stell Field": "CRT",
        "CRW | Yeager Airport": "CRW",
        "CRX | Roscoe Turner Airport": "CRX",
        "CSG | Columbus Metropolitan Airport": "CSG",
        "CSM | Clinton Sherman Airport": "CSM",
        "CSQ | Creston Municipal Airport": "CSQ",
        "CSV | Crossville Memorial Whitson Field": "CSV",
        "CTB | Cut Bank International Airport": "CTB",
        "CTY | Cross City Airport": "CTY",
        "CTZ | Sampson County Airport": "CTZ",
        "CUB | Jim Hamilton L.B. Owens Airport": "CUB",
        "CUH | Cushing Municipal Airport": "CUH",
        "CVG | Cincinnati Northern Kentucky International Airport": "CVG",
        "CKK | Sharp County Regional Airport": "CKK",
        "CVN | Clovis Municipal Airport": "CVN",
        "CVO | Corvallis Municipal Airport": "CVO",
        "CVS | Cannon Air Force Base": "CVS",
        "CWA | Central Wisconsin Airport": "CWA",
        "KIP | Kickapoo Downtown Airport": "KIP",
        "CWF | Chennault International Airport": "CWF",
        "CWI | Clinton Municipal Airport": "CWI",
        "CXL | Calexico International Airport": "CXL",
        "CXO | Conroe-North Houston Regional Airport": "CXO",
        "CSN | Carson Airport": "CSN",
        "HAR | Capital City Airport": "HAR",
        "CYS | Cheyenne Regional Jerry Olson Field": "CYS",
        "CZT | Dimmit County Airport": "CZT",
        "VEX | Tioga Municipal Airport": "VEX",
        "DAA | Davison Army Air Field": "DAA",
        "DAB | Daytona Beach International Airport": "DAB",
        "DAG | Barstow Daggett Airport": "DAG",
        "DAL | Dallas Love Field": "DAL",
        "DAN | Danville Regional Airport": "DAN",
        "DAY | James M Cox Dayton International Airport": "DAY",
        "DBN | W H 'Bud' Barron Airport": "DBN",
        "DBQ | Dubuque Regional Airport": "DBQ",
        "DCA | Ronald Reagan Washington National Airport": "DCA",
        "DCU | Pryor Field Regional Airport": "DCU",
        "DDC | Dodge City Regional Airport": "DDC",
        "DEC | Decatur Airport": "DEC",
        "DEH | Decorah Municipal Airport": "DEH",
        "DEN | Denver International Airport": "DEN",
        "DET | Coleman A. Young Municipal Airport": "DET",
        "DFI | Defiance Memorial Airport": "DFI",
        "DFW | Dallas Fort Worth International Airport": "DFW",
        "DGL | Douglas Municipal Airport": "DGL",
        "DGW | Converse County Airport": "DGW",
        "DHN | Dothan Regional Airport": "DHN",
        "DHT | Dalhart Municipal Airport": "DHT",
        "DIK | Dickinson Theodore Roosevelt Regional Airport": "DIK",
        "DKK | Chautauqua County-Dunkirk Airport": "DKK",
        "DLL | Dillon County Airport": "DLL",
        "DLF | Laughlin Air Force Base": "DLF",
        "DLH | Duluth International Airport": "DLH",
        "DLN | Dillon Airport": "DLN",
        "DLS | Columbia Gorge Regional Airport": "DLS",
        "DMA | Davis Monthan Air Force Base": "DMA",
        "DMN | Deming Municipal Airport": "DMN",
        "DMO | Sedalia Memorial Airport": "DMO",
        "DNL | Daniel Field": "DNL",
        "DNN | Dalton Municipal Airport": "DNN",
        "DNS | Denison Municipal Airport": "DNS",
        "DNV | Vermilion Regional Airport": "DNV",
        "DOV | Dover Air Force Base": "DOV",
        "KDP | Kandep Airport": "KDP",
        "DPA | Dupage Airport": "DPA",
        "DPG | Michael AAF (Dugway Proving Ground) Airport": "DPG",
        "KDQ | Kamberatoro Airport": "KDQ",
        "DRA | Desert Rock Airport": "DRA",
        "DRI | Beauregard Regional Airport": "DRI",
        "DRE | Drummond Island Airport": "DRE",
        "DRO | Durango La Plata County Airport": "DRO",
        "DRT | Del Rio International Airport": "DRT",
        "KDS | Kamaran Downs Airport": "KDS",
        "DSM | Des Moines International Airport": "DSM",
        "DSV | Dansville Municipal Airport": "DSV",
        "DTA | Delta Municipal Airport": "DTA",
        "DTL | Detroit Lakes Airport - Wething Field": "DTL",
        "DTN | Shreveport Downtown Airport": "DTN",
        "DSI | Destin Executive Airport": "DSI",
        "DTW | Detroit Metropolitan Wayne County Airport": "DTW",
        "DUA | Eaker Field": "DUA",
        "DUC | Halliburton Field": "DUC",
        "DUG | Bisbee Douglas International Airport": "DUG",
        "DUJ | DuBois Regional Airport": "DUJ",
        "DVL | Devils Lake Regional Airport": "DVL",
        "DVN | Davenport Municipal Airport": "DVN",
        "NOT | Marin County Airport - Gnoss Field": "NOT",
        "NSL | Slayton Municipal Airport": "NSL",
        "DVT | Phoenix Deer Valley Airport": "DVT",
        "KDW | Victoria Reservoir Seaplane Base": "KDW",
        "DWH | David Wayne Hooks Memorial Airport": "DWH",
        "DXR | Danbury Municipal Airport": "DXR",
        "DYL | Doylestown Airport": "DYL",
        "DYS | Dyess Air Force Base": "DYS",
        "KDZ | Polgolla Reservoir Seaplane Base": "KDZ",
        "VPG | Vipingo Estate Airport": "VPG",
        "KTJ | Kichwa Tembo Airport": "KTJ",
        "OSJ | Ol Seki Airstrip": "OSJ",
        "OLG | Olare Orok Airfield": "OLG",
        "HKR | Mara North Conservancy Airstrip": "HKR",
        "ANA | Angama Airport": "ANA",
        "OLX | Olkiombo Airport": "OLX",
        "KRV | Kerio Valley Airport": "KRV",
        "KIU | Kiunga Airport": "KIU",
        "LBK | Liboi Airport": "LBK",
        "LBN | Lake Baringo Airport": "LBN",
        "LKU | Lake Rudolf Airport": "LKU",
        "MUM | Mumias Airport": "MUM",
        "MIF | Roy Hurd Memorial Airport": "MIF",
        "CCG | Crane County Airport": "CCG",
        "ESO | Ohkay Owingeh Airport": "ESO",
        "WTR | Whiteriver Airport": "WTR",
        "ALE | Alpine Casparis Municipal Airport": "ALE",
        "BGT | Bagdad Airport": "BGT",
        "EAN | Phifer Airfield": "EAN",
        "EAR | Kearney Regional Airport": "EAR",
        "EAT | Pangborn Memorial Airport": "EAT",
        "EAU | Chippewa Valley Regional Airport": "EAU",
        "KEB | Nanwalek Airport": "KEB",
        "EBS | Webster City Municipal Airport": "EBS",
        "ECG | Elizabeth City Regional Airport & Coast Guard Air Station": "ECG",
        "ECS | Mondell Field": "ECS",
        "EDE | Northeastern Regional Airport": "EDE",
        "ETS | Enterprise Municipal Airport": "ETS",
        "EDW | Edwards Air Force Base": "EDW",
        "EED | Needles Airport": "EED",
        "EEN | Dillant Hopkins Airport": "EEN",
        "EFD | Ellington Airport": "EFD",
        "EFK | Northeast Kingdom International Airport": "EFK",
        "EFW | Jefferson Municipal Airport": "EFW",
        "KEG | Keglsugl Airport": "KEG",
        "EGE | Eagle County Regional Airport": "EGE",
        "EGI | Duke Field": "EGI",
        "EGV | Eagle River Union Airport": "EGV",
        "KEK | Ekwok Airport": "KEK",
        "EKA | Murray Field": "EKA",
        "EKI | Elkhart Municipal Airport": "EKI",
        "EKN | Elkins-Randolph Co-Jennings Randolph Field": "EKN",
        "EKO | Elko Regional Airport": "EKO",
        "EKX | Addington Field / Elizabethtown Regional Airport": "EKX",
        "ELA | Eagle Lake Airport": "ELA",
        "ELD | South Arkansas Regional At Goodwin Field": "ELD",
        "ELK | Elk City Regional Business Airport": "ELK",
        "ELM | Elmira Corning Regional Airport": "ELM",
        "ELN | Bowers Field": "ELN",
        "LYU | Ely Municipal Airport": "LYU",
        "ELP | El Paso International Airport": "ELP",
        "ELY | Ely Airport Yelland Field": "ELY",
        "ELZ | Wellsville Municipal ArptTarantine Field": "ELZ",
        "EMM | Kemmerer Municipal Airport": "EMM",
        "EMP | Emporia Municipal Airport": "EMP",
        "EMT | San Gabriel Valley Airport": "EMT",
        "END | Vance Air Force Base": "END",
        "ENL | Centralia Municipal Airport": "ENL",
        "ENV | Wendover Airport": "ENV",
        "ENW | Kenosha Regional Airport": "ENW",
        "EOK | Keokuk Municipal Airport": "EOK",
        "EPH | Ephrata Municipal Airport": "EPH",
        "EDK | Captain Jack Thomas El Dorado Airport": "EDK",
        "ERI | Erie International Tom Ridge Field": "ERI",
        "ERR | Errol Airport": "ERR",
        "ERV | Kerrville Municipal Louis Schreiner Field": "ERV",
        "ESC | Delta County Airport": "ESC",
        "ESF | Esler Army Airfield / Esler Regional Airport": "ESF",
        "ESN | Easton Airport / Newnam Field": "ESN",
        "EST | Estherville Municipal Airport": "EST",
        "ESW | Easton State Airport": "ESW",
        "ETB | West Bend Municipal Airport": "ETB",
        "ETN | Eastland Municipal Airport": "ETN",
        "EUF | Weedon Field": "EUF",
        "EUG | Mahlon Sweet Field": "EUG",
        "EVM | Eveleth Virginia Municipal Airport": "EVM",
        "EVV | Evansville Regional Airport": "EVV",
        "EVW | Evanston-Uinta County Airport-Burns Field": "EVW",
        "EWB | New Bedford Regional Airport": "EWB",
        "EWK | Newton City-County Airport": "EWK",
        "EWN | Coastal Carolina Regional Airport": "EWN",
        "EWR | Newark Liberty International Airport": "EWR",
        "KEX | Kanabea Airport": "KEX",
        "EYW | Key West International Airport": "EYW",
        "KEZ | Kelani-Peliyagoda Seaplane Base": "KEZ",
        "WIB | Wilbarger County Airport": "WIB",
        "RBK | French Valley Airport": "RBK",
        "FAF | Felker Army Air Field": "FAF",
        "FAM | Farmington Regional Airport": "FAM",
        "FAR | Hector International Airport": "FAR",
        "FAT | Fresno Yosemite International Airport": "FAT",
        "FAY | Fayetteville Regional Airport - Grannis Field": "FAY",
        "FBG | Simmons Army Air Field": "FBG",
        "FBL | Faribault Municipal Airport-Liz Wall Strohfus Field": "FBL",
        "FBR | Fort Bridger Airport": "FBR",
        "FBY | Fairbury Municipal Airport": "FBY",
        "FCH | Fresno Chandler Executive Airport": "FCH",
        "FCM | Flying Cloud Airport": "FCM",
        "FCS | Butts AAF (Fort Carson) Air Field": "FCS",
        "FCY | Forrest City Municipal Airport": "FCY",
        "FDK | Frederick Municipal Airport": "FDK",
        "FDR | Frederick Regional Airport": "FDR",
        "FDY | Findlay Airport": "FDY",
        "FEP | Albertus Airport": "FEP",
        "FET | Fremont Municipal Airport": "FET",
        "FFA | First Flight Airport": "FFA",
        "FFL | Fairfield Municipal Airport": "FFL",
        "FFM | Fergus Falls Municipal Airport - Einar Mickelson Field": "FFM",
        "FFO | Wright-Patterson Air Force Base": "FFO",
        "FFT | Capital City Airport": "FFT",
        "MSC | Falcon Field": "MSC",
        "FRD | Friday Harbor Airport": "FRD",
        "FHU | Sierra Vista Municipal Airport / Libby Army Air Field": "FHU",
        "FKL | Venango Regional Airport": "FKL",
        "FKN | Franklin Regional Airport": "FKN",
        "FLD | Fond du Lac County Airport": "FLD",
        "FLG | Flagstaff Pulliam International Airport": "FLG",
        "FLL | Fort Lauderdale Hollywood International Airport": "FLL",
        "FLO | Florence Regional Airport": "FLO",
        "FLP | Marion County Regional Airport": "FLP",
        "FLV | Sherman Army Air Field": "FLV",
        "FLX | Fallon Municipal Airport": "FLX",
        "FME | Tipton Airport": "FME",
        "FMH | Cape Cod Coast Guard Air Station": "FMH",
        "FMN | Four Corners Regional Airport": "FMN",
        "FMY | Page Field": "FMY",
        "FNL | Northern Colorado Regional Airport": "FNL",
        "FNT | Bishop International Airport": "FNT",
        "FOD | Fort Dodge Regional Airport": "FOD",
        "FOE | Topeka Regional Airport": "FOE",
        "FOK | Francis S Gabreski Airport": "FOK",
        "FIL | Fillmore Municipal Airport": "FIL",
        "FPR | St Lucie County International Airport": "FPR",
        "FRG | Republic Airport": "FRG",
        "FRH | French Lick Municipal Airport": "FRH",
        "FRI | Marshall Army Air Field": "FRI",
        "FRM | Fairmont Municipal Airport": "FRM",
        "FRR | Front Royal Warren County Airport": "FRR",
        "FSD | Sioux Falls Regional Airport / Joe Foss Field": "FSD",
        "FSI | Henry Post Army Air Field (Fort Sill)": "FSI",
        "FSK | Fort Scott Municipal Airport": "FSK",
        "FSM | Fort Smith Regional Airport": "FSM",
        "FST | Fort Stockton Pecos County Airport": "FST",
        "FSU | Fort Sumner Municipal Airport": "FSU",
        "FMS | Fort Madison Municipal Airport": "FMS",
        "FTK | Godman Army Air Field": "FTK",
        "FTW | Fort Worth Meacham International Airport": "FTW",
        "FTY | Fulton County Airport Brown Field": "FTY",
        "FUL | Fullerton Municipal Airport": "FUL",
        "WFK | Northern Aroostook Regional Airport": "WFK",
        "FWA | Fort Wayne International Airport": "FWA",
        "FXE | Fort Lauderdale Executive Airport": "FXE",
        "FXY | Forest City Municipal Airport": "FXY",
        "FYM | Fayetteville Municipal Airport": "FYM",
        "FYV | Drake Field": "FYV",
        "GAD | Northeast Alabama Regional Airport": "GAD",
        "GAG | Gage Airport": "GAG",
        "GAI | Montgomery County Airpark": "GAI",
        "GBD | Great Bend Municipal Airport": "GBD",
        "GBG | Galesburg Municipal Airport": "GBG",
        "GBR | Walter J. Koladza Airport": "GBR",
        "GCC | Gillette Campbell County Airport": "GCC",
        "JDA | Grant Co Regional/Ogilvie Field": "JDA",
        "GCK | Garden City Regional Airport": "GCK",
        "GCN | Grand Canyon National Park Airport": "GCN",
        "GCY | Greeneville Municipal Airport": "GCY",
        "GDM | Gardner Municipal Airport": "GDM",
        "GDV | Dawson Community Airport": "GDV",
        "GDW | Gladwin Zettel Memorial Airport": "GDW",
        "GED | Delaware Coastal Airport": "GED",
        "GEG | Spokane International Airport": "GEG",
        "GEY | South Big Horn County Airport": "GEY",
        "GFK | Grand Forks International Airport": "GFK",
        "GFL | Floyd Bennett Memorial Airport": "GFL",
        "GGE | Georgetown County Airport": "GGE",
        "GGG | East Texas Regional Airport": "GGG",
        "GGW | Wokal Field/Glasgow-Valley County Airport": "GGW",
        "GHM | Centerville Municipal Airport": "GHM",
        "GIF | Winter Haven Regional Airport - Gilbert Field": "GIF",
        "GJT | Grand Junction Regional Airport": "GJT",
        "MEJ | Port Meadville Airport": "MEJ",
        "GKT | Gatlinburg-Pigeon Forge Airport": "GKT",
        "GLD | Renner Field-Goodland Municipal Airport": "GLD",
        "GLE | Gainesville Municipal Airport": "GLE",
        "GLH | Mid Delta Regional Airport": "GLH",
        "GLR | Gaylord Regional Airport": "GLR",
        "GLS | Scholes International At Galveston Airport": "GLS",
        "GLW | Glasgow Municipal Airport": "GLW",
        "GMU | Greenville Downtown Airport": "GMU",
        "GNG | Gooding Municipal Airport": "GNG",
        "GNT | Grants-Milan Municipal Airport": "GNT",
        "GNV | Gainesville Regional Airport": "GNV",
        "GOK | Guthrie-Edmond Regional Airport": "GOK",
        "GON | Groton New London Airport": "GON",
        "FCA | Glacier Park International Airport": "FCA",
        "GPT | Gulfport Biloxi International Airport": "GPT",
        "GPZ | Grand Rapids Itasca Co-Gordon Newstrom field": "GPZ",
        "GQQ | Galion Municipal Airport": "GQQ",
        "GRB | Austin Straubel International Airport": "GRB",
        "GRD | Greenwood County Airport": "GRD",
        "GRE | Greenville Airport": "GRE",
        "GRF | Gray Army Air Field": "GRF",
        "GRI | Central Nebraska Regional Airport": "GRI",
        "GRK | Killeen-Fort Hood Regional Airport / Robert Gray Army Air Field": "GRK",
        "GRN | Gordon Municipal Airport": "GRN",
        "GRR | Gerald R. Ford International Airport": "GRR",
        "GSB | Seymour Johnson Air Force Base": "GSB",
        "GSH | Goshen Municipal Airport": "GSH",
        "GSO | Piedmont Triad International Airport": "GSO",
        "GSP | Greenville Spartanburg International Airport": "GSP",
        "GTF | Great Falls International Airport": "GTF",
        "GTG | Grantsburg Municipal Airport": "GTG",
        "GTR | Golden Triangle Regional Airport": "GTR",
        "GUC | Gunnison Crested Butte Regional Airport": "GUC",
        "GUP | Gallup Municipal Airport": "GUP",
        "GUS | Grissom Air Reserve Base": "GUS",
        "GUY | Guymon Municipal Airport": "GUY",
        "GVL | Lee Gilmer Memorial Airport": "GVL",
        "GVT | Majors Airport": "GVT",
        "KGW | Kagi Airport": "KGW",
        "GWO | Greenwood–Leflore Airport": "GWO",
        "GWS | Glenwood Springs Municipal Airport": "GWS",
        "KGX | Grayling Airport": "KGX",
        "GXY | Greeley–Weld County Airport": "GXY",
        "GDC | Donaldson Field Airport": "GDC",
        "PNX | North Texas Regional Airport/Perrin Field": "PNX",
        "GYR | Phoenix Goodyear Airport": "GYR",
        "GYY | Gary Chicago International Airport": "GYY",
        "KGZ | Glacier Creek Airport": "KGZ",
        "HAB | Marion County Rankin Fite Airport": "HAB",
        "HAF | Half Moon Bay Airport": "HAF",
        "HAI | Three Rivers Municipal Dr Haines Airport": "HAI",
        "HAO | Butler Co Regional Airport - Hogan Field": "HAO",
        "HBG | Hattiesburg Bobby L Chain Municipal Airport": "HBG",
        "HBR | Hobart Regional Airport": "HBR",
        "HDE | Brewster Field": "HDE",
        "HDN | Yampa Valley Airport": "HDN",
        "HEE | Thompson-Robbins Airport": "HEE",
        "MNZ | Manassas Regional Airport/Harry P. Davis Field": "MNZ",
        "HEZ | Hardy-Anders Field / Natchez-Adams County Airport": "HEZ",
        "HFD | Hartford Brainard Airport": "HFD",
        "HFF | Mackall Army Air Field": "HFF",
        "HGR | Hagerstown Regional Richard A Henson Field": "HGR",
        "HHR | Jack Northrop Field Hawthorne Municipal Airport": "HHR",
        "HUJ | Stan Stamper Municipal Airport": "HUJ",
        "HIB | Range Regional Airport": "HIB",
        "HIE | Mount Washington Regional Airport": "HIE",
        "HIF | Hill Air Force Base": "HIF",
        "HII | Lake Havasu City International Airport": "HII",
        "HIO | Portland Hillsboro Airport": "HIO",
        "HKA | Blytheville Municipal Airport": "HKA",
        "HKS | Hawkins Field": "HKS",
        "HKY | Hickory Regional Airport": "HKY",
        "HLB | Hillenbrand Industries Airport": "HLB",
        "HLC | Hill City Municipal Airport": "HLC",
        "HLG | Wheeling Ohio County Airport": "HLG",
        "HLM | Park Township Airport": "HLM",
        "HLN | Helena Regional Airport": "HLN",
        "HLR | Hood Army Air Field": "HLR",
        "HMN | Holloman Air Force Base": "HMN",
        "HMT | Hemet Ryan Airport": "HMT",
        "HNB | Huntingburg Airport": "HNB",
        "HSH | Henderson Executive Airport": "HSH",
        "HOB | Lea County Regional Airport": "HOB",
        "HON | Huron Regional Airport": "HON",
        "HOP | Campbell AAF (Fort Campbell) Air Field": "HOP",
        "HOT | Memorial Field": "HOT",
        "HOU | William P Hobby Airport": "HOU",
        "HPN | Westchester County Airport": "HPN",
        "HPT | Hampton Municipal Airport": "HPT",
        "HPY | Baytown Airport": "HPY",
        "HQM | Bowerman Airport": "HQM",
        "HES | Hermiston Municipal Airport": "HES",
        "HRL | Valley International Airport": "HRL",
        "HRO | Boone County Airport": "HRO",
        "HSB | Harrisburg-Raleigh Airport": "HSB",
        "HNC | Billy Mitchell Airport": "HNC",
        "THP | Hot Springs County Airport": "THP",
        "HSI | Hastings Municipal Airport": "HSI",
        "HSP | Ingalls Field": "HSP",
        "HST | Homestead ARB Airport": "HST",
        "HSV | Huntsville International Carl T Jones Field": "HSV",
        "HTH | Hawthorne Industrial Airport": "HTH",
        "HTL | Roscommon County - Blodgett Memorial Airport": "HTL",
        "HTO | East Hampton Airport": "HTO",
        "HTS | Tri-State/Milton J. Ferguson Field": "HTS",
        "HTW | Lawrence County Airpark": "HTW",
        "HUA | Redstone Army Air Field": "HUA",
        "HUF | Terre Haute Regional Airport Hulman Field": "HUF",
        "HUL | Houlton International Airport": "HUL",
        "HUM | Houma Terrebonne Airport": "HUM",
        "HUT | Hutchinson Municipal Airport": "HUT",
        "HVE | Hanksville Airport": "HVE",
        "HVN | Tweed New Haven Airport": "HVN",
        "HVR | Havre City County Airport": "HVR",
        "HVS | Hartsville Regional Airport": "HVS",
        "HWD | Hayward Executive Airport": "HWD",
        "HWO | North Perry Airport": "HWO",
        "WSH | Brookhaven Airport": "WSH",
        "HHH | Hilton Head Airport": "HHH",
        "HYA | Barnstable Municipal Boardman Polando Field": "HYA",
        "HYR | Sawyer County Airport": "HYR",
        "HYS | Hays Regional Airport": "HYS",
        "HZL | Hazleton Municipal Airport": "HZL",
        "JFN | Northeast Ohio Regional Airport": "JFN",
        "IAB | Mc Connell Air Force Base": "IAB",
        "IAD | Washington Dulles International Airport": "IAD",
        "IAG | Niagara Falls International Airport": "IAG",
        "IAH | George Bush Intercontinental Houston Airport": "IAH",
        "KIB | Ivanof Bay Seaplane Base": "KIB",
        "ICL | Schenck Field": "ICL",
        "ICT | Wichita Eisenhower National Airport": "ICT",
        "IDA | Idaho Falls Regional Airport": "IDA",
        "IDI | Indiana County/Jimmy Stewart Fld/ Airport": "IDI",
        "IDP | Independence Municipal Airport": "IDP",
        "XPR | Pine Ridge Airport": "XPR",
        "IFA | Iowa Falls Municipal Airport": "IFA",
        "IFP | Laughlin Bullhead International Airport": "IFP",
        "IGM | Kingman Airport": "IGM",
        "IKK | Greater Kankakee Airport": "IKK",
        "KIL | Kilwa Airport": "KIL",
        "ILE | Skylark Field": "ILE",
        "ILG | New Castle Airport": "ILG",
        "ILM | Wilmington International Airport": "ILM",
        "ILN | Wilmington Airpark": "ILN",
        "IML | Imperial Municipal Airport": "IML",
        "IMM | Immokalee Regional Airport": "IMM",
        "MDN | Madison Municipal Airport": "MDN",
        "IMT | Ford Airport": "IMT",
        "IND | Indianapolis International Airport": "IND",
        "INK | Winkler County Airport": "INK",
        "INL | Falls International Airport": "INL",
        "INS | Creech Air Force Base": "INS",
        "INT | Smith Reynolds Airport": "INT",
        "INW | Winslow Lindbergh Regional Airport": "INW",
        "IOW | Iowa City Municipal Airport": "IOW",
        "IPL | Imperial County Airport": "IPL",
        "IPT | Williamsport Regional Airport": "IPT",
        "KIQ | Kira Airport": "KIQ",
        "IRK | Kirksville Regional Airport": "IRK",
        "IRS | Kirsch Municipal Airport": "IRS",
        "ISM | Kissimmee Gateway Airport": "ISM",
        "ISO | Kinston Regional Jetport At Stallings Field": "ISO",
        "ISP | Long Island Mac Arthur Airport": "ISP",
        "ISQ | Schoolcraft County Airport": "ISQ",
        "ISW | Alexander Field South Wood County Airport": "ISW",
        "ITH | Ithaca Tompkins Regional Airport": "ITH",
        "AZA | Phoenix–Mesa Gateway Airport": "AZA",
        "IWD | Gogebic Iron County Airport": "IWD",
        "ISS | Wiscasset Airport": "ISS",
        "IWS | West Houston Airport": "IWS",
        "JCI | New Century Aircenter Airport": "JCI",
        "IYK | Inyokern Airport": "IYK",
        "SQA | Santa Ynez Airport": "SQA",
        "FRY | Eastern Slopes Regional Airport": "FRY",
        "JAC | Jackson Hole Airport": "JAC",
        "JAN | Jackson-Medgar Wiley Evers International Airport": "JAN",
        "JAS | Jasper County Airport Bell Field": "JAS",
        "JAX | Jacksonville International Airport": "JAX",
        "JBR | Jonesboro Municipal Airport": "JBR",
        "JCT | Kimble County Airport": "JCT",
        "JDN | Jordan Airport": "JDN",
        "JEF | Jefferson City Memorial Airport": "JEF",
        "JFK | John F Kennedy International Airport": "JFK",
        "JHW | Chautauqua County-Jamestown Airport": "JHW",
        "GUF | Jack Edwards National Airport": "GUF",
        "JLN | Joplin Regional Airport": "JLN",
        "JMS | Jamestown Regional Airport": "JMS",
        "JOT | Joliet Regional Airport": "JOT",
        "USA | Concord-Padgett Regional Airport": "USA",
        "JKV | Cherokee County Airport": "JKV",
        "JST | John Murtha Johnstown Cambria County Airport": "JST",
        "JVL | Southern Wisconsin Regional Airport": "JVL",
        "KJX | Blang Pidie Airport": "KJX",
        "JXN | Jackson County Reynolds Field": "JXN",
        "KKB | Kitoi Bay Seaplane Base": "KKB",
        "KIC | Mesa Del Rey Airport": "KIC",
        "KKL | Karluk Lake Seaplane Base": "KKL",
        "KLS | Southwest Washington Regional Airport": "KLS",
        "KKU | Ekuk Airport": "KKU",
        "DTH | Furnace Creek Airport": "DTH",
        "BXS | Borrego Valley Airport": "BXS",
        "RBF | Big Bear City Airport": "RBF",
        "TRH | Trona Airport": "TRH",
        "LAA | Southeast Colorado Regional Airport": "LAA",
        "LAF | Purdue University Airport": "LAF",
        "LAL | Lakeland Linder International Airport": "LAL",
        "LAM | Los Alamos Airport": "LAM",
        "LAN | Capital City Airport": "LAN",
        "LAR | Laramie Regional Airport": "LAR",
        "LAS | McCarran International Airport": "LAS",
        "LAW | Lawton Fort Sill Regional Airport": "LAW",
        "LAX | Los Angeles International Airport": "LAX",
        "LBB | Lubbock Preston Smith International Airport": "LBB",
        "LBE | Arnold Palmer Regional Airport": "LBE",
        "LBF | North Platte Regional Airport Lee Bird Field": "LBF",
        "LBL | Liberal Mid-America Regional Airport": "LBL",
        "LBT | Lumberton Regional Airport": "LBT",
        "LJN | Texas Gulf Coast Regional Airport": "LJN",
        "LCH | Lake Charles Regional Airport": "LCH",
        "LCI | Laconia Municipal Airport": "LCI",
        "LCK | Rickenbacker International Airport": "LCK",
        "LCQ | Lake City Gateway Airport": "LCQ",
        "LDJ | Linden Airport": "LDJ",
        "LDM | Mason County Airport": "LDM",
        "LEB | Lebanon Municipal Airport": "LEB",
        "LEE | Leesburg International Airport": "LEE",
        "LEM | Lemmon Municipal Airport": "LEM",
        "LEW | Auburn Lewiston Municipal Airport": "LEW",
        "LEX | Blue Grass Airport": "LEX",
        "LFI | Langley Air Force Base": "LFI",
        "LFK | Angelina County Airport": "LFK",
        "LFT | Lafayette Regional Airport": "LFT",
        "LGA | La Guardia Airport": "LGA",
        "LGB | Long Beach Airport (Daugherty Field)": "LGB",
        "LGC | LaGrange Callaway Airport": "LGC",
        "LGD | La Grande/Union County Airport": "LGD",
        "LGF | Laguna Army Airfield": "LGF",
        "LGU | Logan-Cache Airport": "LGU",
        "LHV | William T. Piper Memorial Airport": "LHV",
        "LIY | Wright AAF (Fort Stewart)/Midcoast Regional Airport": "LIY",
        "LFN | Triangle North Executive Airport": "LFN",
        "LIC | Limon Municipal Airport": "LIC",
        "LIT | Bill & Hillary Clinton National Airport/Adams Field": "LIT",
        "LKP | Lake Placid Airport": "LKP",
        "LOW | Louisa County Airport/Freeman Field": "LOW",
        "LKV | Lake County Airport": "LKV",
        "CHL | Challis Airport": "CHL",
        "LMS | Louisville Winston County Airport": "LMS",
        "LMT | Crater Lake-Klamath Regional Airport": "LMT",
        "LNA | Palm Beach County Park Airport": "LNA",
        "LND | Hunt Field": "LND",
        "LNK | Lincoln Airport": "LNK",
        "LNN | Lake County Executive Airport": "LNN",
        "LNP | Lonesome Pine Airport": "LNP",
        "LNR | Tri-County Regional Airport": "LNR",
        "LNS | Lancaster Airport": "LNS",
        "LOL | Derby Field": "LOL",
        "BBX | Wings Field": "BBX",
        "LOT | Lewis University Airport": "LOT",
        "LOU | Bowman Field": "LOU",
        "LOZ | London-Corbin Airport/Magee Field": "LOZ",
        "LPC | Lompoc Airport": "LPC",
        "LQK | Pickens County Airport": "LQK",
        "LRD | Laredo International Airport": "LRD",
        "LRF | Little Rock Air Force Base": "LRF",
        "LRJ | Le Mars Municipal Airport": "LRJ",
        "LRU | Las Cruces International Airport": "LRU",
        "LSB | Lordsburg Municipal Airport": "LSB",
        "LSE | La Crosse Municipal Airport": "LSE",
        "LSF | Lawson Army Air Field (Fort Benning)": "LSF",
        "LSK | Lusk Municipal Airport": "LSK",
        "LSN | Los Banos Municipal Airport": "LSN",
        "LSV | Nellis Air Force Base": "LSV",
        "LTS | Altus Air Force Base": "LTS",
        "LUF | Luke Air Force Base": "LUF",
        "LUK | Cincinnati Municipal Airport Lunken Field": "LUK",
        "LUL | Hesler Noble Field": "LUL",
        "LVK | Livermore Municipal Airport": "LVK",
        "LVL | Brunswick Municipal Airport": "LVL",
        "LVM | Mission Field": "LVM",
        "LVS | Las Vegas Municipal Airport": "LVS",
        "LWB | Greenbrier Valley Airport": "LWB",
        "LWC | Lawrence Municipal Airport": "LWC",
        "LWL | Wells Municipal Airport/Harriet Field": "LWL",
        "LWM | Lawrence Municipal Airport": "LWM",
        "LWS | Lewiston Nez Perce County Airport": "LWS",
        "LWT | Lewistown Municipal Airport": "LWT",
        "LWV | Lawrenceville Vincennes International Airport": "LWV",
        "LXN | Jim Kelly Field": "LXN",
        "LXV | Lake County Airport": "LXV",
        "LYH | Lynchburg Regional Preston Glenn Field": "LYH",
        "LYO | Lyons-Rice County Municipal Airport": "LYO",
        "LZU | Gwinnett County Briscoe Field": "LZU",
        "PCU | Poplarville Pearl River County Airport": "PCU",
        "MLK | Malta Airport": "MLK",
        "MAC | Macon Downtown Airport": "MAC",
        "MAE | Madera Municipal Airport": "MAE",
        "MAF | Midland International Airport": "MAF",
        "MAW | Malden Regional Airport": "MAW",
        "KMB | Koinambe Airport": "KMB",
        "MBG | Mobridge Municipal Airport": "MBG",
        "MBL | Manistee County Blacker Airport": "MBL",
        "DXE | Bruce Campbell Field": "DXE",
        "MBS | MBS International Airport": "MBS",
        "MBY | Omar N Bradley Airport": "MBY",
        "MCB | McComb-Pike County Airport / John E Lewis Field": "MCB",
        "MCC | Mc Clellan Airfield": "MCC",
        "MCD | Mackinac Island Airport": "MCD",
        "MCE | Merced Regional Macready Field": "MCE",
        "MCF | Mac Dill Air Force Base": "MCF",
        "MCI | Kansas City International Airport": "MCI",
        "MCK | Mc Cook Ben Nelson Regional Airport": "MCK",
        "MCN | Middle Georgia Regional Airport": "MCN",
        "MCO | Orlando International Airport": "MCO",
        "MCW | Mason City Municipal Airport": "MCW",
        "MDD | Midland Airpark": "MDD",
        "MDH | Southern Illinois Airport": "MDH",
        "XMD | Madison Municipal Airport": "XMD",
        "MDT | Harrisburg International Airport": "MDT",
        "MDW | Chicago Midway International Airport": "MDW",
        "MDF | Taylor County Airport": "MDF",
        "MXE | Laurinburg Maxton Airport": "MXE",
        "MEI | Key Field / Meridian Regional Airport": "MEI",
        "MEM | Memphis International Airport": "MEM",
        "MER | Castle Airport": "MER",
        "MEV | Minden-Tahoe Airport": "MEV",
        "KMF | Kamina Airport": "KMF",
        "MFD | Mansfield Lahm Regional Airport": "MFD",
        "MFE | McAllen Miller International Airport": "MFE",
        "MFI | Marshfield Municipal Airport": "MFI",
        "MFR | Rogue Valley International Medford Airport": "MFR",
        "MFV | Accomack County Airport": "MFV",
        "MGC | Michigan City Municipal Airport": "MGC",
        "MGE | Dobbins Air Reserve Base": "MGE",
        "MGJ | Orange County Airport": "MGJ",
        "MGM | Montgomery Regional (Dannelly Field) Airport": "MGM",
        "MGR | Moultrie Municipal Airport": "MGR",
        "MGW | Morgantown Municipal Walter L. Bill Hart Field": "MGW",
        "MGY | Dayton-Wright Brothers Airport": "MGY",
        "MHE | Mitchell Municipal Airport": "MHE",
        "MHK | Manhattan Regional Airport": "MHK",
        "MHL | Marshall Memorial Municipal Airport": "MHL",
        "MHR | Sacramento Mather Airport": "MHR",
        "MHT | Manchester-Boston Regional Airport": "MHT",
        "MHV | Mojave Airport": "MHV",
        "MIA | Miami International Airport": "MIA",
        "MIB | Minot Air Force Base": "MIB",
        "MIE | Delaware County Johnson Field": "MIE",
        "MIT | Shafter Airport - Minter Field": "MIT",
        "MIV | Millville Municipal Airport": "MIV",
        "MJX | Ocean County Airport": "MJX",
        "MKC | Charles B. Wheeler Downtown Airport": "MKC",
        "MKE | General Mitchell International Airport": "MKE",
        "MKG | Muskegon County Airport": "MKG",
        "MKL | McKellar-Sipes Regional Airport": "MKL",
        "MRK | Marco Island Executive Airport": "MRK",
        "MLB | Melbourne Orlando International Airport": "MLB",
        "MLC | Mc Alester Regional Airport": "MLC",
        "MLI | Quad City International Airport": "MLI",
        "MLS | Frank Wiley Field": "MLS",
        "MLU | Monroe Regional Airport": "MLU",
        "KMM | Kimaam Airport": "KMM",
        "MMH | Mammoth Yosemite Airport": "MMH",
        "MMI | McMinn County Airport": "MMI",
        "MML | Southwest Minnesota Regional Airport - Marshall/Ryan Field": "MML",
        "MMS | Selfs Airport": "MMS",
        "MMT | Mc Entire Joint National Guard Base": "MMT",
        "MMU | Morristown Municipal Airport": "MMU",
        "MNM | Menominee Regional Airport": "MNM",
        "MNN | Marion Municipal Airport": "MNN",
        "MOB | Mobile Regional Airport": "MOB",
        "MOD | Modesto City Co-Harry Sham Field": "MOD",
        "MOT | Minot International Airport": "MOT",
        "RMY | Mariposa Yosemite Airport": "RMY",
        "MPJ | Petit Jean Park Airport": "MPJ",
        "MPO | Pocono Mountains Municipal Airport": "MPO",
        "MPV | Edward F Knapp State Airport": "MPV",
        "MPZ | Mount Pleasant Municipal Airport": "MPZ",
        "MQB | Macomb Municipal Airport": "MQB",
        "MEO | Dare County Regional Airport": "MEO",
        "CTH | Chester County G O Carlson Airport": "CTH",
        "MQY | Smyrna Airport": "MQY",
        "MRB | Eastern WV Regional Airport/Shepherd Field": "MRB",
        "MRC | Maury County Airport": "MRC",
        "MRF | Marfa Municipal Airport": "MRF",
        "MRN | Foothills Regional Airport": "MRN",
        "MRY | Monterey Peninsula Airport": "MRY",
        "MSL | Northwest Alabama Regional Airport": "MSL",
        "MSN | Dane County Regional Truax Field": "MSN",
        "MSO | Missoula International Airport": "MSO",
        "MSP | Minneapolis–Saint Paul International Airport / Wold–Chamberlain Field": "MSP",
        "MSS | Massena International Richards Field": "MSS",
        "MSV | Sullivan County International Airport": "MSV",
        "MSY | Louis Armstrong New Orleans International Airport": "MSY",
        "MTC | Selfridge Air National Guard Base Airport": "MTC",
        "MTH | The Florida Keys Marathon Airport": "MTH",
        "MTJ | Montrose Regional Airport": "MTJ",
        "MTN | Martin State Airport": "MTN",
        "MTO | Coles County Memorial Airport": "MTO",
        "MTP | Montauk Airport": "MTP",
        "MTW | Manitowoc County Airport": "MTW",
        "MUI | Muir Army Air Field (Fort Indiantown Gap) Airport": "MUI",
        "MUO | Mountain Home Air Force Base": "MUO",
        "MUT | Muscatine Municipal Airport": "MUT",
        "MVC | Monroe County Aeroplex Airport": "MVC",
        "MVE | Montevideo Chippewa County Airport": "MVE",
        "MVL | Morrisville Stowe State Airport": "MVL",
        "MVY | Martha's Vineyard Airport": "MVY",
        "MWA | Veterans Airport of Southern Illinois": "MWA",
        "MWC | Lawrence J Timmerman Airport": "MWC",
        "MWH | Grant County International Airport": "MWH",
        "MWL | Mineral Wells Regional Airport": "MWL",
        "MWO | Middletown Regional Airport": "MWO",
        "MXA | Manila Municipal Airport": "MXA",
        "MXF | Maxwell Air Force Base": "MXF",
        "KMY | Moser Bay Seaplane Base": "KMY",
        "MYF | Montgomery-Gibbs Executive Airport": "MYF",
        "MYL | McCall Municipal Airport": "MYL",
        "MYR | Myrtle Beach International Airport": "MYR",
        "MYV | Yuba County Airport": "MYV",
        "MZJ | Pinal Airpark": "MZJ",
        "MZZ | Marion Municipal Airport": "MZZ",
        "CTX | Cortland County Chase Field": "CTX",
        "SXY | Sidney Municipal Airport": "SXY",
        "ESP | Stroudsburg Pocono Airport": "ESP",
        "NBG | New Orleans NAS JRB/Alvin Callender Field": "NBG",
        "NHX | Naval Outlying Field Barin": "NHX",
        "DGN | Dahlgren Naval Surface Warfare Center Airport": "DGN",
        "NEL | Lakehurst Maxfield Field Airport": "NEL",
        "NEN | Whitehouse Naval Outlying Field": "NEN",
        "NEW | Lakefront Airport": "NEW",
        "NFL | Fallon Naval Air Station": "NFL",
        "FWH | NAS Fort Worth JRB / Carswell Field": "FWH",
        "NGU | Norfolk Naval Station (Chambers Field)": "NGU",
        "NHK | Patuxent River Naval Air Station (Trapnell Field)": "NHK",
        "NHZ | Brunswick Executive Airport": "NHZ",
        "NIP | Jacksonville Naval Air Station (Towers Field)": "NIP",
        "NJK | El Centro NAF Airport (Vraciu Field)": "NJK",
        "NKX | Miramar Marine Corps Air Station - Mitscher Field": "NKX",
        "NLC | Lemoore Naval Air Station (Reeves Field) Airport": "NLC",
        "NPA | Naval Air Station Pensacola Forrest Sherman Field": "NPA",
        "NQA | Millington-Memphis Airport": "NQA",
        "NQI | Kingsville Naval Air Station": "NQI",
        "NQX | Naval Air Station Key West/Boca Chica Field": "NQX",
        "NRB | Naval Station Mayport (Admiral David L. Mcdonald Field)": "NRB",
        "NRS | Naval Outlying Field Imperial Beach (Ream Field)": "NRS",
        "NSE | Whiting Field Naval Air Station - North": "NSE",
        "NTD | Point Mugu Naval Air Station (Naval Base Ventura Co)": "NTD",
        "NTU | Oceana Naval Air Station": "NTU",
        "NUQ | Moffett Federal Airfield": "NUQ",
        "NUW | Whidbey Island Naval Air Station (Ault Field)": "NUW",
        "YUM | Yuma International Airport / Marine Corps Air Station Yuma": "YUM",
        "NZY | North Island Naval Air Station-Halsey Field": "NZY",
        "NVN | Nervino Airport": "NVN",
        "COA | Columbia Airport": "COA",
        "ODC | Oakdale Airport": "ODC",
        "EYR | Yerington Municipal Airport": "EYR",
        "OAJ | Albert J Ellis Airport": "OAJ",
        "OAK | Metropolitan Oakland International Airport": "OAK",
        "OAR | Marina Municipal Airport": "OAR",
        "OBE | Okeechobee County Airport": "OBE",
        "OCF | Ocala International Airport - Jim Taylor Field": "OCF",
        "OCH | A L Mangham Jr. Regional Airport": "OCH",
        "OCW | Warren Field": "OCW",
        "OEA | O'Neal Airport": "OEA",
        "OEO | L O Simenstad Municipal Airport": "OEO",
        "OFF | Offutt Air Force Base": "OFF",
        "OFK | Karl Stefan Memorial Airport": "OFK",
        "OGA | Searle Field": "OGA",
        "OGB | Orangeburg Municipal Airport": "OGB",
        "OGD | Ogden Hinckley Airport": "OGD",
        "OGS | Ogdensburg International Airport": "OGS",
        "OIC | Lt Warren Eaton Airport": "OIC",
        "OJC | Johnson County Executive Airport": "OJC",
        "OCN | Oceanside Municipal Airport": "OCN",
        "OKC | Will Rogers World Airport": "OKC",
        "ODW | AJ Eisenberg Airport": "ODW",
        "OKK | Kokomo Municipal Airport": "OKK",
        "OKM | Okmulgee Regional Airport": "OKM",
        "OKS | Garden County Airport/King Rhiley Field": "OKS",
        "WGO | Winchester Regional Airport": "WGO",
        "OLD | Dewitt Field - Old Town Municipal Airport": "OLD",
        "OLF | L M Clayton Airport": "OLF",
        "OLM | Olympia Regional Airport": "OLM",
        "OLS | Nogales International Airport": "OLS",
        "OLV | Olive Branch Airport": "OLV",
        "KOM | Komo-Manda Airport": "KOM",
        "OMA | Eppley Airfield": "OMA",
        "OMK | Omak Airport": "OMK",
        "ONL | The O'Neill Municipal John L Baker Field": "ONL",
        "ONO | Ontario Municipal Airport": "ONO",
        "ONP | Newport Municipal Airport": "ONP",
        "ONT | Ontario International Airport": "ONT",
        "OPF | Miami-Opa Locka Executive Airport": "OPF",
        "NCO | Quonset State Airport": "NCO",
        "KOR | Kakoro(Koroko) Airstrip": "KOR",
        "ORD | Chicago O'Hare International Airport": "ORD",
        "ORF | Norfolk International Airport": "ORF",
        "ORH | Worcester Regional Airport": "ORH",
        "ORL | Orlando Executive Airport": "ORL",
        "ESD | Orcas Island Airport": "ESD",
        "OSC | Oscoda Wurtsmith Airport": "OSC",
        "OSH | Wittman Regional Airport": "OSH",
        "OSU | The Ohio State University Airport - Don Scott Field": "OSU",
        "OTH | Southwest Oregon Regional Airport": "OTH",
        "OTM | Ottumwa Regional Airport": "OTM",
        "OUN | University of Oklahoma Westheimer Airport": "OUN",
        "OVE | Oroville Municipal Airport": "OVE",
        "OWA | Owatonna Degner Regional Airport": "OWA",
        "OWB | Owensboro Daviess County Airport": "OWB",
        "OWD | Norwood Memorial Airport": "OWD",
        "OWK | Central Maine Airport of Norridgewock": "OWK",
        "OCE | Ocean City Municipal Airport": "OCE",
        "OXC | Waterbury Oxford Airport": "OXC",
        "OXD | Miami University Airport": "OXD",
        "OXR | Oxnard Airport": "OXR",
        "KOY | Olga Bay Seaplane Base": "KOY",
        "STQ | St Marys Municipal Airport": "STQ",
        "OZA | Ozona Municipal Airport": "OZA",
        "OZR | Cairns AAF (Fort Rucker) Air Field": "OZR",
        "YJS | Samjiyŏn Airport": "YJS",
        "RGO | Orang (Chongjin) Airport": "RGO",
        "BSQ | Bisbee Municipal Airport": "BSQ",
        "PXL | Polacca Airport": "PXL",
        "GLB | San Carlos Apache Airport": "GLB",
        "HBK | Holbrook Municipal Airport": "HBK",
        "CWX | Cochise County Airport": "CWX",
        "PAE | Snohomish County (Paine Field) Airport": "PAE",
        "PAH | Barkley Regional Airport": "PAH",
        "PAM | Tyndall Air Force Base": "PAM",
        "PJB | Payson Airport": "PJB",
        "PAO | Palo Alto Airport of Santa Clara County": "PAO",
        "KPB | Point Baker Seaplane Base": "KPB",
        "PBF | Pine Bluff Regional Airport Grider Field": "PBF",
        "PBG | Plattsburgh International Airport": "PBG",
        "PBI | Palm Beach International Airport": "PBI",
        "PVL | Pike County-Hatcher Field": "PVL",
        "PCD | Prairie Du Chien Municipal Airport": "PCD",
        "PDK | DeKalb Peachtree Airport": "PDK",
        "PDT | Eastern Oregon Regional At Pendleton Airport": "PDT",
        "PDX | Portland International Airport": "PDX",
        "KPE | Yapsiei Airport": "KPE",
        "PEQ | Pecos Municipal Airport": "PEQ",
        "PGA | Page Municipal Airport": "PGA",
        "PGD | Charlotte County Airport": "PGD",
        "PGR | Kirk Field": "PGR",
        "PGV | Pitt Greenville Airport": "PGV",
        "PHD | Harry Clever Field": "PHD",
        "PHF | Newport News Williamsburg International Airport": "PHF",
        "ADR | Robert F Swinnie Airport": "ADR",
        "PHK | Palm Beach County Glades Airport": "PHK",
        "PHL | Philadelphia International Airport": "PHL",
        "PHN | St Clair County International Airport": "PHN",
        "PHP | Philip Airport": "PHP",
        "PHT | Henry County Airport": "PHT",
        "PHX | Phoenix Sky Harbor International Airport": "PHX",
        "PIA | General Wayne A. Downing Peoria International Airport": "PIA",
        "PIB | Hattiesburg Laurel Regional Airport": "PIB",
        "PIE | St Petersburg Clearwater International Airport": "PIE",
        "PIH | Pocatello Regional Airport": "PIH",
        "PIM | Harris County Airport": "PIM",
        "PIR | Pierre Regional Airport": "PIR",
        "PIT | Pittsburgh International Airport": "PIT",
        "PKB | Mid Ohio Valley Regional Airport": "PKB",
        "PKD | Park Rapids Municipal Konshok Field": "PKD",
        "PKF | Park Falls Municipal Airport": "PKF",
        "KPL | Kapal Airport": "KPL",
        "PLK | M. Graham Clark Downtown Airport": "PLK",
        "PLN | Pellston Regional Airport of Emmet County Airport": "PLN",
        "PLR | St Clair County Airport": "PLR",
        "PMB | Pembina Municipal Airport": "PMB",
        "PMD | Palmdale Regional Airport / USAF Plant 42 Airport": "PMD",
        "PMH | Greater Portsmouth Regional Airport": "PMH",
        "PPM | Pompano Beach Airpark": "PPM",
        "PWY | Ralph Wenz Field": "PWY",
        "PNC | Ponca City Regional Airport": "PNC",
        "PNE | Northeast Philadelphia Airport": "PNE",
        "PNN | Princeton Municipal Airport": "PNN",
        "PNS | Pensacola International Airport": "PNS",
        "POB | Pope Field": "POB",
        "POC | Brackett Field": "POC",
        "POE | Polk Army Air Field": "POE",
        "POF | Poplar Bluff Municipal Airport": "POF",
        "POU | Dutchess County Airport": "POU",
        "POY | Powell Municipal Airport": "POY",
        "PPA | Perry Lefors Field": "PPA",
        "PPF | Tri-City Airport": "PPF",
        "LPO | La Porte Municipal Airport": "LPO",
        "PQI | Presque Isle International Airport": "PQI",
        "PGL | Trent Lott International Airport": "PGL",
        "KPR | Port Williams Seaplane Base": "KPR",
        "PRB | Paso Robles Municipal Airport": "PRB",
        "PRC | Prescott International Airport - Ernest A. Love Field": "PRC",
        "PRO | Perry Municipal Airport": "PRO",
        "PRX | Cox Field": "PRX",
        "PSC | Tri Cities Airport": "PSC",
        "PSF | Pittsfield Municipal Airport": "PSF",
        "PSK | New River Valley Airport": "PSK",
        "PSM | Portsmouth International at Pease Airport": "PSM",
        "PSN | Palestine Municipal Airport": "PSN",
        "PGO | Stevens Field": "PGO",
        "PSP | Palm Springs International Airport": "PSP",
        "PSX | Palacios Municipal Airport": "PSX",
        "PTB | Dinwiddie County Airport": "PTB",
        "PTK | Oakland County International Airport": "PTK",
        "PTN | Harry P Williams Memorial Airport": "PTN",
        "PTT | Pratt Regional Airport": "PTT",
        "PTV | Porterville Municipal Airport": "PTV",
        "PTW | Heritage Field": "PTW",
        "PUB | Pueblo Memorial Airport": "PUB",
        "PUC | Carbon County Regional/Buck Davis Field": "PUC",
        "PUW | Pullman Moscow Regional Airport": "PUW",
        "PVC | Provincetown Municipal Airport": "PVC",
        "PVD | Theodore Francis Green State Airport": "PVD",
        "PVF | Placerville Airport": "PVF",
        "PVU | Provo-Utah Lake International Airport": "PVU",
        "PVW | Hale County Airport": "PVW",
        "PWA | Wiley Post Airport": "PWA",
        "PWD | Sher-Wood Airport": "PWD",
        "PWK | Chicago Executive Airport": "PWK",
        "PWM | Portland International Jetport": "PWM",
        "PWT | Bremerton National Airport": "PWT",
        "KPY | Port Bailey Seaplane Base": "KPY",
        "PYM | Plymouth Municipal Airport": "PYM",
        "KQL | Kol Airport": "KQL",
        "RAC | John H Batten Airport": "RAC",
        "RAL | Riverside Municipal Airport": "RAL",
        "RAP | Rapid City Regional Airport": "RAP",
        "RBD | Dallas Executive Airport": "RBD",
        "RBG | Roseburg Regional Airport": "RBG",
        "RBL | Red Bluff Municipal Airport": "RBL",
        "RBW | Lowcountry Regional Airport": "RBW",
        "RCA | Ellsworth Air Force Base": "RCA",
        "RCK | H H Coffield Regional Airport": "RCK",
        "RCR | Fulton County Airport": "RCR",
        "RCT | Nartron Field": "RCT",
        "RDD | Redding Municipal Airport": "RDD",
        "RDG | Reading Regional Airport (Carl A Spaatz Field)": "RDG",
        "RDM | Roberts Field": "RDM",
        "RDR | Grand Forks Air Force Base": "RDR",
        "RDU | Raleigh Durham International Airport": "RDU",
        "REO | Rome State Airport": "REO",
        "RFD | Chicago Rockford International Airport": "RFD",
        "RFG | Rooke Field": "RFG",
        "RHI | Rhinelander Oneida County Airport": "RHI",
        "RHV | Reid-Hillview Airport of Santa Clara County": "RHV",
        "RIC | Richmond International Airport": "RIC",
        "RIL | Garfield County Regional Airport": "RIL",
        "RIV | March Air Reserve Base": "RIV",
        "RIW | Riverton Regional Airport": "RIW",
        "KRJ | Karawari Airstrip": "KRJ",
        "RKD | Knox County Regional Airport": "RKD",
        "RKP | Aransas County Airport": "RKP",
        "RKS | Southwest Wyoming Regional Airport": "RKS",
        "RKW | Rockwood Municipal Airport": "RKW",
        "RME | Griffiss International Airport": "RME",
        "RMG | Richard B Russell Airport": "RMG",
        "RNC | Warren County Memorial Airport": "RNC",
        "RND | Randolph Air Force Base": "RND",
        "RNO | Reno Tahoe International Airport": "RNO",
        "RNT | Renton Municipal Airport": "RNT",
        "ROA | Roanoke–Blacksburg Regional Airport": "ROA",
        "ROC | Greater Rochester International Airport": "ROC",
        "ROG | Rogers Municipal Airport-Carter Field": "ROG",
        "ROW | Roswell Air Center Airport": "ROW",
        "ROX | Roseau Municipal Rudy Billberg Field": "ROX",
        "RIE | Rice Lake Regional Airport - Carl's Field": "RIE",
        "RPX | Roundup Airport": "RPX",
        "WBR | Roben Hood Airport": "WBR",
        "RQO | El Reno Regional Airport": "RQO",
        "RRL | Merrill Municipal Airport": "RRL",
        "RRT | Warroad International Memorial Airport": "RRT",
        "RSL | Russell Municipal Airport": "RSL",
        "RSN | Ruston Regional Airport": "RSN",
        "RST | Rochester International Airport": "RST",
        "RSW | Southwest Florida International Airport": "RSW",
        "RTN | Raton Municipal Airport / Crews Field": "RTN",
        "KRU | Kerau Airport": "KRU",
        "SRW | Mid-Carolina Regional Airport": "SRW",
        "RUT | Rutland - Southern Vermont Regional Airport": "RUT",
        "RED | Mifflin County Airport": "RED",
        "RVS | Richard Lloyd Jones Jr Airport": "RVS",
        "RWF | Redwood Falls Municipal Airport": "RWF",
        "RWI | Rocky Mount Wilson Regional Airport": "RWI",
        "RWL | Rawlins Municipal Airport/Harvey Field": "RWL",
        "RXE | Rexburg Madison County Airport": "RXE",
        "RNZ | Jasper County Airport": "RNZ",
        "AHM | Ashland Municipal Sumner Parker Field": "AHM",
        "BDY | Bandon State Airport": "BDY",
        "SUO | Sunriver Airport": "SUO",
        "MDJ | Madras Municipal Airport": "MDJ",
        "PRZ | Prineville Airport": "PRZ",
        "IDH | Idaho County Airport": "IDH",
        "SAA | Shively Field": "SAA",
        "SAC | Sacramento Executive Airport": "SAC",
        "SAD | Safford Regional Airport": "SAD",
        "SAF | Santa Fe Municipal Airport": "SAF",
        "SAN | San Diego International Airport": "SAN",
        "SAR | Sparta Community Hunter Field": "SAR",
        "SAT | San Antonio International Airport": "SAT",
        "SAV | Savannah Hilton Head International Airport": "SAV",
        "MQT | Sawyer International Airport": "MQT",
        "SBA | Santa Barbara Municipal Airport": "SBA",
        "SBD | San Bernardino International Airport": "SBD",
        "SBM | Sheboygan County Memorial Airport": "SBM",
        "SBN | South Bend Regional Airport": "SBN",
        "SBP | San Luis County Regional Airport": "SBP",
        "SBS | Steamboat Springs Bob Adams Field": "SBS",
        "SBX | Shelby Airport": "SBX",
        "SBY | Salisbury Ocean City Wicomico Regional Airport": "SBY",
        "SCB | Scribner State Airport": "SCB",
        "SCH | Schenectady County Airport": "SCH",
        "SCK | Stockton Metropolitan Airport": "SCK",
        "SDF | Louisville Muhammad Ali International Airport": "SDF",
        "SCF | Scottsdale Airport": "SCF",
        "SDM | Brown Field Municipal Airport": "SDM",
        "SDY | Sidney - Richland Regional Airport": "SDY",
        "SEA | Seattle Tacoma International Airport": "SEA",
        "SEE | Gillespie Field": "SEE",
        "SEF | Sebring Regional Airport": "SEF",
        "SEG | Penn Valley Airport": "SEG",
        "SEM | Craig Field": "SEM",
        "SEP | Stephenville Clark Regional Airport": "SEP",
        "SER | Freeman Municipal Airport": "SER",
        "SDX | Sedona Airport": "SDX",
        "SFB | Orlando Sanford International Airport": "SFB",
        "SFF | Felts Field": "SFF",
        "SFM | Sanford Seacoast Regional Airport": "SFM",
        "SFO | San Francisco International Airport": "SFO",
        "SFZ | North Central State Airport": "SFZ",
        "SGF | Springfield Branson National Airport": "SGF",
        "SGH | Springfield-Beckley Municipal Airport": "SGH",
        "UST | Northeast Florida Regional Airport": "UST",
        "SGR | Sugar Land Regional Airport": "SGR",
        "SGT | Stuttgart Municipal Airport / Carl Humphrey Field": "SGT",
        "SGU | Saint George-Southwest Utah International Airport": "SGU",
        "SHD | Shenandoah Valley Regional Airport": "SHD",
        "SHN | Sanderson Field": "SHN",
        "SHR | Sheridan County Airport": "SHR",
        "SHV | Shreveport Regional Airport": "SHV",
        "SIK | Sikeston Memorial Municipal Airport": "SIK",
        "SIV | Sullivan County Airport": "SIV",
        "SJC | Norman Y. Mineta San Jose International Airport": "SJC",
        "SJN | St Johns Industrial Air Park": "SJN",
        "SJT | San Angelo Regional Mathis Field": "SJT",
        "SKA | Fairchild Air Force Base": "SKA",
        "SKF | Lackland Air Force Base": "SKF",
        "TSM | Taos Regional Airport": "TSM",
        "SLB | Storm Lake Municipal Airport": "SLB",
        "SLC | Salt Lake City International Airport": "SLC",
        "SLE | Salem Municipal Airport/McNary Field": "SLE",
        "SLG | Smith Field": "SLG",
        "SLK | Adirondack Regional Airport": "SLK",
        "SLN | Salina Municipal Airport": "SLN",
        "SLO | Salem Leckrone Airport": "SLO",
        "SLR | Sulphur Springs Municipal Airport": "SLR",
        "SMD | Smith Field": "SMD",
        "SME | Lake Cumberland Regional Airport": "SME",
        "SMF | Sacramento International Airport": "SMF",
        "SMN | Lemhi County Airport": "SMN",
        "SMO | Santa Monica Municipal Airport": "SMO",
        "SUM | Sumter Airport": "SUM",
        "SMX | Santa Maria Public Airport Captain G Allan Hancock Field": "SMX",
        "SNA | John Wayne Airport-Orange County Airport": "SNA",
        "SNK | Winston Field": "SNK",
        "SNL | Shawnee Regional Airport": "SNL",
        "SNS | Salinas Municipal Airport": "SNS",
        "SNY | Sidney Municipal-Lloyd W Carr Field": "SNY",
        "SOP | Moore County Airport": "SOP",
        "SOW | Show Low Regional Airport": "SOW",
        "KSP | Kosipe Airport": "KSP",
        "SPA | Spartanburg Downtown Memorial Airport": "SPA",
        "SPF | Black Hills Airport-Clyde Ice Field": "SPF",
        "SPG | Albert Whitted Airport": "SPG",
        "SPI | Abraham Lincoln Capital Airport": "SPI",
        "SPS | Sheppard Air Force Base-Wichita Falls Municipal Airport": "SPS",
        "SPW | Spencer Municipal Airport": "SPW",
        "SQI | Whiteside County Airport-Joseph H Bittorf Field": "SQI",
        "SQL | San Carlos Airport": "SQL",
        "SRQ | Sarasota Bradenton International Airport": "SRQ",
        "RUI | Sierra Blanca Regional Airport": "RUI",
        "SSC | Shaw Air Force Base": "SSC",
        "SSF | Stinson Municipal Airport": "SSF",
        "SSI | St Simons Island Airport": "SSI",
        "STC | Saint Cloud Regional Airport": "STC",
        "STE | Stevens Point Municipal Airport": "STE",
        "STJ | Rosecrans Memorial Airport": "STJ",
        "STK | Sterling Municipal Airport": "STK",
        "STL | St Louis Lambert International Airport": "STL",
        "STP | St Paul Downtown Holman Field": "STP",
        "STS | Charles M. Schulz Sonoma County Airport": "STS",
        "SUA | Witham Field": "SUA",
        "SUD | Stroud Municipal Airport": "SUD",
        "SUE | Door County Cherryland Airport": "SUE",
        "SUN | Friedman Memorial Airport": "SUN",
        "SUS | Spirit of St Louis Airport": "SUS",
        "SUU | Travis Air Force Base": "SUU",
        "SUW | Richard I Bong Airport": "SUW",
        "SUX | Sioux Gateway Airport / Brigadier General Bud Day Field": "SUX",
        "SVC | Grant County Airport": "SVC",
        "SVE | Susanville Municipal Airport": "SVE",
        "SVH | Statesville Regional Airport": "SVH",
        "SVN | Hunter Army Air Field": "SVN",
        "SWF | New York Stewart International Airport": "SWF",
        "SWO | Stillwater Regional Airport": "SWO",
        "SWW | Avenger Field": "SWW",
        "SYI | Bomar Field Shelbyville Municipal Airport": "SYI",
        "SYR | Syracuse Hancock International Airport": "SYR",
        "SYV | Sylvester Airport": "SYV",
        "SZL | Whiteman Air Force Base": "SZL",
        "TBC | Tuba City Airport": "TBC",
        "TAD | Perry Stokes Airport": "TAD",
        "KTB | Thorne Bay Seaplane Base": "KTB",
        "TBN | Waynesville-St. Robert Regional Forney field": "TBN",
        "TBR | Statesboro Bulloch County Airport": "TBR",
        "KTC | Katiola Airport": "KTC",
        "TCC | Tucumcari Municipal Airport": "TCC",
        "TCL | Tuscaloosa Regional Airport": "TCL",
        "TCM | McChord Air Force Base": "TCM",
        "TCS | Truth or Consequences Municipal Airport": "TCS",
        "TDO | Ed Carlson Memorial Field South Lewis County Airport": "TDO",
        "TDW | Tradewind Airport": "TDW",
        "TDZ | Toledo Executive Airport": "TDZ",
        "TEB | Teterboro Airport": "TEB",
        "TEX | Telluride Regional Airport": "TEX",
        "THA | Tullahoma Regional Arpt/Wm Northern Field": "THA",
        "THM | Thompson Falls Airport": "THM",
        "THV | York Airport": "THV",
        "TIK | Tinker Air Force Base": "TIK",
        "TIW | Tacoma Narrows Airport": "TIW",
        "TIX | Space Coast Regional Airport": "TIX",
        "KNT | Kennett Memorial Airport": "KNT",
        "TLH | Tallahassee Regional Airport": "TLH",
        "TLR | Mefford Field": "TLR",
        "TMA | Henry Tift Myers Airport": "TMA",
        "TMB | Miami Executive Airport": "TMB",
        "OTK | Tillamook Airport": "OTK",
        "TNP | Twentynine Palms Airport": "TNP",
        "TNT | Dade Collier Training and Transition Airport": "TNT",
        "TNU | Newton Municipal Airport": "TNU",
        "XSD | Tonopah Test Range Airport": "XSD",
        "TOA | Zamperini Field": "TOA",
        "TOC | Toccoa Airport - R.G. Letourneau Field": "TOC",
        "TOI | Troy Municipal Airport at N Kenneth Campbell Field": "TOI",
        "TOL | Eugene F. Kranz Toledo Express Airport": "TOL",
        "TOP | Philip Billard Municipal Airport": "TOP",
        "TOR | Torrington Municipal Airport": "TOR",
        "TPA | Tampa International Airport": "TPA",
        "TPF | Peter O Knight Airport": "TPF",
        "TPH | Tonopah Airport": "TPH",
        "TPL | Draughon Miller Central Texas Regional Airport": "TPL",
        "TRI | Tri-Cities Regional TN/VA Airport": "TRI",
        "TKF | Truckee Tahoe Airport": "TKF",
        "TRL | Terrell Municipal Airport": "TRL",
        "TRM | Jacqueline Cochran Regional Airport": "TRM",
        "TSP | Tehachapi Municipal Airport": "TSP",
        "TTD | Portland Troutdale Airport": "TTD",
        "TTN | Trenton Mercer Airport": "TTN",
        "TUL | Tulsa International Airport": "TUL",
        "TUP | Tupelo Regional Airport": "TUP",
        "TUS | Tucson International Airport / Morris Air National Guard Base": "TUS",
        "TVC | Cherry Capital Airport": "TVC",
        "TVF | Thief River Falls Regional Airport": "TVF",
        "TVI | Thomasville Regional Airport": "TVI",
        "TVL | Lake Tahoe Airport": "TVL",
        "TWF | Joslin Field Magic Valley Regional Airport": "TWF",
        "TXK | Texarkana Regional Webb Field": "TXK",
        "TYZ | Taylor Airport": "TYZ",
        "TYR | Tyler Pounds Regional Airport": "TYR",
        "TYS | McGhee Tyson Airport": "TYS",
        "BFG | Bullfrog Basin Airport": "BFG",
        "NPH | Nephi-Central Utah International Airport": "NPH",
        "RVR | Green River Municipal Airport": "RVR",
        "PNU | Panguitch Municipal Airport": "PNU",
        "ICS | Cascade Airport": "ICS",
        "UBS | Columbus Lowndes County Airport": "UBS",
        "UCY | Everett-Stewart Regional Airport": "UCY",
        "UDD | Bermuda Dunes Airport": "UDD",
        "UES | Waukesha County Airport": "UES",
        "UGN | Waukegan National Airport": "UGN",
        "UIL | Quillayute Airport": "UIL",
        "UIN | Quincy Regional Baldwin Field": "UIN",
        "IKB | Wilkes County Airport": "IKB",
        "UKI | Ukiah Municipal Airport": "UKI",
        "UKT | Quakertown Airport": "UKT",
        "ULM | New Ulm Municipal Airport": "ULM",
        "ATO | Ohio University Snyder Field": "ATO",
        "UNU | Dodge County Airport": "UNU",
        "SCE | University Park Airport": "SCE",
        "UOS | Franklin County Airport": "UOS",
        "UOX | University Oxford Airport": "UOX",
        "KUP | Kupiano Airport": "KUP",
        "UTM | Tunica Municipal Airport": "UTM",
        "HTV | Huntsville Regional Airport": "HTV",
        "NPT | Newport State Airport": "NPT",
        "UVA | Garner Field": "UVA",
        "KUX | Kuyol Airport": "KUX",
        "RKH | Rock Hill - York County Airport": "RKH",
        "VAD | Moody Air Force Base": "VAD",
        "LLY | South Jersey Regional Airport": "LLY",
        "VBG | Vandenberg Air Force Base": "VBG",
        "VCT | Victoria Regional Airport": "VCT",
        "VCV | Southern California Logistics Airport": "VCV",
        "VDI | Vidalia Regional Airport": "VDI",
        "KVE | Kitava Airport": "KVE",
        "VEL | Vernal Regional Airport": "VEL",
        "VGT | North Las Vegas Metropolitan International Airport": "VGT",
        "VHN | Culberson County Airport": "VHN",
        "VIH | Rolla National Airport": "VIH",
        "VIS | Visalia International Airport": "VIS",
        "VJI | Virginia Highlands Airport": "VJI",
        "VKS | Vicksburg Municipal Airport": "VKS",
        "VLA | Vandalia Municipal Airport": "VLA",
        "VLD | Valdosta Regional Airport": "VLD",
        "VNC | Venice Municipal Airport": "VNC",
        "VNY | Van Nuys Airport": "VNY",
        "VOK | Volk Field": "VOK",
        "VPS | Destin-Fort Walton Beach Airport": "VPS",
        "VPZ | Porter County Municipal Airport": "VPZ",
        "VQQ | Cecil Airport": "VQQ",
        "VRB | Vero Beach Regional Airport": "VRB",
        "VSF | Hartness State (Springfield) Airport": "VSF",
        "VTN | Miller Field": "VTN",
        "VYS | Illinois Valley Regional Airport-Walter A Duncan Field": "VYS",
        "GTY | Gettysburg Regional Airport": "GTY",
        "SQV | Sequim Valley Airport": "SQV",
        "PGC | Grant County Airport": "PGC",
        "WAL | Wallops Flight Facility Airport": "WAL",
        "WAY | Greene County Airport": "WAY",
        "WBW | Wilkes Barre Wyoming Valley Airport": "WBW",
        "WDG | Enid Woodring Regional Airport": "WDG",
        "WDR | Barrow County Airport": "WDR",
        "KWF | Waterfall Seaplane Base": "KWF",
        "WHP | Whiteman Airport": "WHP",
        "WJF | General WM J Fox Airfield": "WJF",
        "WLD | Strother Field": "WLD",
        "WLW | Willows Glenn County Airport": "WLW",
        "WMC | Winnemucca Municipal Airport": "WMC",
        "KWP | West Point Village Seaplane Base": "KWP",
        "WRB | Robins Air Force Base": "WRB",
        "WRI | Mc Guire Air Force Base": "WRI",
        "WRL | Worland Municipal Airport": "WRL",
        "WSD | Condron Army Air Field": "WSD",
        "WST | Westerly State Airport": "WST",
        "WVI | Watsonville Municipal Airport": "WVI",
        "WVL | Waterville Robert Lafleur Airport": "WVL",
        "WWD | Cape May County Airport": "WWD",
        "WWR | West Woodward Airport": "WWR",
        "KWY | Kiwayu Airport": "KWY",
        "WYS | Yellowstone Airport": "WYS",
        "KYO | Tampa North Aero Park Airport": "KYO",
        "KXA | Kasaan Seaplane Base": "KXA",
        "XNA | Northwest Arkansas Regional Airport": "XNA",
        "WBK | West Branch Community Airport": "WBK",
        "YIP | Willow Run Airport": "YIP",
        "YKM | Yakima Air Terminal McAllister Field": "YKM",
        "YKN | Chan Gurney Municipal Airport": "YKN",
        "YNG | Youngstown Warren Regional Airport": "YNG",
        "TDK | Taldykorgan Airport": "TDK",
        "BXY | Krainiy Airport": "BXY",
        "USJ | Usharal Airport": "USJ",
        "ATX | Atbasar Airport": "ATX",
        "KZB | Zachar Bay Seaplane Base": "KZB",
        "KZF | Kaintiba Airport": "KZF",
        "ZPH | Zephyrhills Municipal Airport": "ZPH",
        "ZZV | Zanesville Municipal Airport": "ZZV",
        "LAC | Layang-Layang Airport": "LAC",
        "KFZ | Kukës International Airport": "KFZ",
        "TIA | Tirana International Airport Mother Teresa": "TIA",
        "BOJ | Burgas Airport": "BOJ",
        "GOZ | Gorna Oryahovitsa Airport": "GOZ",
        "LBH | Palm Beach Seaplane Base": "LBH",
        "LBM | Luabo Airport": "LBM",
        "PDV | Plovdiv International Airport": "PDV",
        "ROU | Ruse Airport": "ROU",
        "SOF | Sofia Airport": "SOF",
        "SZR | Stara Zagora Airport": "SZR",
        "TGV | Targovishte Airport / Buhovtsi Airfield": "TGV",
        "VAR | Varna Airport": "VAR",
        "ECN | Ercan International Airport": "ECN",
        "LCA | Larnaca International Airport": "LCA",
        "LCP | Loncopue Airport": "LCP",
        "PFO | Paphos International Airport": "PFO",
        "AKT | RAF Akrotiri": "AKT",
        "DBV | Dubrovnik Airport": "DBV",
        "LSZ | Lošinj Island Airport": "LSZ",
        "OSI | Osijek Airport": "OSI",
        "PUY | Pula Airport": "PUY",
        "RJK | Rijeka Airport": "RJK",
        "BWK | Brač Airport": "BWK",
        "SPU | Split Airport": "SPU",
        "LDW | Lansdowne Airport": "LDW",
        "ZAG | Zagreb Airport": "ZAG",
        "ZAD | Zadar Airport": "ZAD",
        "ABC | Albacete Airport / Los Llanos Air Base": "ABC",
        "ALC | Alicante-Elche Miguel Hernández Airport": "ALC",
        "LEI | Almería Airport": "LEI",
        "OVD | Asturias Airport": "OVD",
        "ODB | Córdoba Airport": "ODB",
        "BIO | Bilbao Airport": "BIO",
        "RGS | Burgos Airport": "RGS",
        "BCN | Josep Tarradellas Barcelona-El Prat Airport": "BCN",
        "BJZ | Badajoz Airport": "BJZ",
        "CDT | Castellón-Costa Azahar Airport": "CDT",
        "LCG | A Coruña Airport": "LCG",
        "ECV | Madrid-Cuatro Vientos Airport": "ECV",
        "ILD | Lleida-Alguaire Airport": "ILD",
        "GRO | Girona-Costa Brava Airport": "GRO",
        "GRX | F.G.L. Airport Granada-Jaén Airport": "GRX",
        "HSK | Huesca-Pirineos Airport": "HSK",
        "IBZ | Ibiza Airport": "IBZ",
        "XRY | Jerez Airport": "XRY",
        "MJV | San Javier Airport": "MJV",
        "QSA | Sabadell Airfield": "QSA",
        "LEN | León Airport": "LEN",
        "RJL | Logroño-Agoncillo Airport": "RJL",
        "MAD | Adolfo Suárez Madrid–Barajas Airport": "MAD",
        "AGP | Málaga-Costa del Sol Airport": "AGP",
        "MAH | Menorca Airport": "MAH",
        "OZP | Moron Air Base": "OZP",
        "LEO | Lekoni Airport": "LEO",
        "PMI | Palma de Mallorca Airport": "PMI",
        "PNA | Pamplona Airport": "PNA",
        "CQM | Ciudad Real International Airport": "CQM",
        "REU | Reus Airport": "REU",
        "ROZ | Rota Naval Station Airport": "ROZ",
        "SLM | Salamanca Airport": "SLM",
        "EAS | San Sebastián Airport": "EAS",
        "SCQ | Santiago-Rosalía de Castro Airport": "SCQ",
        "LEU | Pirineus - la Seu d'Urgel Airport": "LEU",
        "TEV | Teruel Airport": "TEV",
        "TOJ | Madrid–Torrejón Airport / Torrejón Air Base": "TOJ",
        "VLC | Valencia Airport": "VLC",
        "VLL | Valladolid Airport": "VLL",
        "VIT | Vitoria Airport": "VIT",
        "VGO | Vigo Airport": "VGO",
        "SDR | Seve Ballesteros-Santander Airport": "SDR",
        "ZAZ | Zaragoza Airport": "ZAZ",
        "SVQ | Sevilla Airport": "SVQ",
        "DPE | St Aubin Airport": "DPE",
        "CQF | Calais-Dunkerque Airport": "CQF",
        "BYF | Albert-Bray Airport": "BYF",
        "LTQ | Le Touquet-Côte d'Opale Airport": "LTQ",
        "AGF | Agen-La Garenne Airport": "AGF",
        "BOD | Bordeaux-Mérignac Airport": "BOD",
        "EGC | Bergerac-Roumanière Airport": "EGC",
        "CNG | Cognac-Châteaubernard (BA 709) Air Base": "CNG",
        "LRH | La Rochelle-Île de Ré Airport": "LRH",
        "PIS | Poitiers-Biard Airport": "PIS",
        "MCU | Montluçon-Guéret Airport": "MCU",
        "LIG | Limoges Airport": "LIG",
        "NIT | Niort-Souché Airport": "NIT",
        "TLS | Toulouse-Blagnac Airport": "TLS",
        "PUF | Pau Pyrénées Airport": "PUF",
        "LDE | Tarbes-Lourdes-Pyrénées Airport": "LDE",
        "ANG | Angoulême-Brie-Champniers Airport": "ANG",
        "PGX | Périgueux-Bassillac Airport": "PGX",
        "BIQ | Biarritz-Anglet-Bayonne Airport": "BIQ",
        "ZAO | Aérodrome de Cahors-Lalbenque": "ZAO",
        "LBI | Albi-Le Séquestre Airport": "LBI",
        "DCM | Castres-Mazamet Airport": "DCM",
        "RDZ | Rodez-Marcillac Airport": "RDZ",
        "RYN | Royan-Médis Airport": "RYN",
        "RCO | Rochefort-Saint-Agnant (BA 721) Airport": "RCO",
        "BIC | Aérodrome de Belle Île": "BIC",
        "OUI | Ouessant Airport": "OUI",
        "IDY | Île d'Yeu Airport": "IDY",
        "CMR | Colmar-Houssen Airport": "CMR",
        "DLE | Dole-Tavaux Airport": "DLE",
        "MVV | Megève Altiport": "MVV",
        "OBS | Aubenas-Ardèche Méridional Airport": "OBS",
        "LPY | Le Puy-Loudes Airport": "LPY",
        "AHZ | Altiport L'Alpe d'Huez - Henri GIRAUD": "AHZ",
        "ETZ | Metz-Nancy-Lorraine Airport": "ETZ",
        "ANE | Angers-Loire Airport": "ANE",
        "BIA | Bastia-Poretta Airport": "BIA",
        "CLY | Calvi-Sainte-Catherine Airport": "CLY",
        "FSC | Figari Sud-Corse Airport": "FSC",
        "AJA | Ajaccio-Napoléon Bonaparte Airport": "AJA",
        "PRP | Propriano Airport": "PRP",
        "SOZ | Solenzara (BA 126) Air Base": "SOZ",
        "MFX | Méribel Altiport": "MFX",
        "AUF | Auxerre-Branches Airport": "AUF",
        "CMF | Chambéry-Savoie Airport": "CMF",
        "CFE | Clermont-Ferrand Auvergne Airport": "CFE",
        "BOU | Bourges Airport": "BOU",
        "CVF | Courchevel Airport": "CVF",
        "LYS | Lyon Saint-Exupéry Airport": "LYS",
        "SYT | Saint-Yan Airport": "SYT",
        "RNE | Roanne-Renaison Airport": "RNE",
        "NCY | Annecy-Haute-Savoie-Mont Blanc Airport": "NCY",
        "GNB | Grenoble-Isère Airport": "GNB",
        "VAF | Valence-Chabeuil Airport": "VAF",
        "VHY | Vichy-Charmeil Airport": "VHY",
        "AUR | Aurillac Airport": "AUR",
        "CHR | Châteauroux-Déols Marcel Dassault Airport": "CHR",
        "LYN | Lyon-Bron Airport": "LYN",
        "CEQ | Cannes-Mandelieu Airport": "CEQ",
        "EBU | Saint-Étienne-Bouthéon Airport": "EBU",
        "CCF | Carcassonne Airport": "CCF",
        "MRS | Marseille Provence Airport": "MRS",
        "NCE | Nice-Côte d'Azur Airport": "NCE",
        "PGF | Perpignan-Rivesaltes (Llabanère) Airport": "PGF",
        "CTT | Le Castellet Airport": "CTT",
        "BAE | Barcelonnette - Saint-Pons Airport": "BAE",
        "MPL | Montpellier-Méditerranée Airport": "MPL",
        "BZR | Béziers-Vias Airport": "BZR",
        "AVN | Avignon-Caumont Airport": "AVN",
        "GAT | Gap - Tallard Airfield": "GAT",
        "MEN | Mende-Brenoux Airfield": "MEN",
        "SCP | Mont-Dauphin - St-Crépin Airfield": "SCP",
        "BVA | Paris Beauvais Tillé Airport": "BVA",
        "EVX | Évreux-Fauville (BA 105) Air Base": "EVX",
        "LEH | Le Havre Octeville Airport": "LEH",
        "XCR | Châlons-Vatry Airport": "XCR",
        "LSO | Les Sables-d'Olonne Talmont Airport": "LSO",
        "URO | Rouen Airport": "URO",
        "TUF | Tours-Val-de-Loire Airport": "TUF",
        "CET | Cholet Le Pontreau Airport": "CET",
        "LVA | Laval-Entrammes Airport": "LVA",
        "ORE | Orléans – Saint-Denis-de-l'Hôtel Airport": "ORE",
        "LBG | Paris-Le Bourget Airport": "LBG",
        "CSF | Creil Air Base": "CSF",
        "CDG | Charles de Gaulle International Airport": "CDG",
        "TNF | Toussus-le-Noble Airport": "TNF",
        "ORY | Paris-Orly Airport": "ORY",
        "POX | Aérodrome de Pontoise - Cormeilles en Vexin": "POX",
        "VIY | Villacoublay-Vélizy (BA 107) Air Base": "VIY",
        "NVS | Nevers-Fourchambault Airport": "NVS",
        "LIL | Lille-Lesquin Airport": "LIL",
        "HZB | Merville-Calonne Airport": "HZB",
        "BES | Brest Bretagne Airport": "BES",
        "CER | Cherbourg-Maupertus Airport": "CER",
        "DNR | Dinard-Pleurtuit-Saint-Malo Airport": "DNR",
        "LBY | La Baule-Escoublac Airport": "LBY",
        "GFR | Granville Airport": "GFR",
        "DOL | Deauville-Saint-Gatien Airport": "DOL",
        "LRT | Lorient South Brittany (Bretagne Sud) Airport": "LRT",
        "EDM | La Roche-sur-Yon Airport": "EDM",
        "LDV | Landivisiau Air Base": "LDV",
        "CFR | Caen-Carpiquet Airport": "CFR",
        "LME | Le Mans-Arnage Airport": "LME",
        "RNS | Rennes-Saint-Jacques Airport": "RNS",
        "LAI | Lannion-Côte de Granit Airport": "LAI",
        "UIP | Quimper-Cornouaille Airport": "UIP",
        "NTE | Nantes Atlantique Airport": "NTE",
        "SBK | Saint-Brieuc-Armor Airport": "SBK",
        "MXN | Morlaix-Ploujean Airport": "MXN",
        "VNE | Vannes-Meucon Airport": "VNE",
        "SNR | Saint-Nazaire-Montoir Airport": "SNR",
        "BSL | EuroAirport Basel-Mulhouse-Freiburg Airport": "BSL",
        "DIJ | Dijon-Bourgogne Airport": "DIJ",
        "EPL | Épinal-Mirecourt Airport": "EPL",
        "BVE | Brive-Souillac": "BVE",
        "ENC | Nancy-Essey Airport": "ENC",
        "SXB | Strasbourg Airport": "SXB",
        "TLN | Toulon-Hyères Airport": "TLN",
        "FNI | Nîmes-Arles-Camargue Airport": "FNI",
        "LTT | La Môle Airport": "LTT",
        "MQC | Miquelon Airport": "MQC",
        "FSP | St Pierre Airport": "FSP",
        "PYR | Andravida Air Base": "PYR",
        "AXD | Alexandroupoli Democritus Airport": "AXD",
        "ATH | Athens Eleftherios Venizelos International Airport": "ATH",
        "VOL | Nea Anchialos National Airport": "VOL",
        "LGE | Mulan Airport": "LGE",
        "JKH | Chios Island National Airport": "JKH",
        "JIK | Ikaria Airport": "JIK",
        "IOA | Ioannina Airport": "IOA",
        "HER | Heraklion International Nikos Kazantzakis Airport": "HER",
        "KSO | Kastoria National Airport": "KSO",
        "KIT | Kithira Airport": "KIT",
        "EFL | Kefallinia Airport": "EFL",
        "KZS | Kastelorizo Airport": "KZS",
        "KLX | Kalamata Airport": "KLX",
        "KGS | Kos Airport": "KGS",
        "AOK | Karpathos Airport": "AOK",
        "CFU | Ioannis Kapodistrias International Airport": "CFU",
        "KSJ | Kasos Airport": "KSJ",
        "KVA | Kavala Alexander the Great International Airport": "KVA",
        "JKL | Kalymnos Airport": "JKL",
        "KZI | Filippos Airport": "KZI",
        "LRS | Leros Airport": "LRS",
        "LXS | Limnos Airport": "LXS",
        "LRA | Larisa Air Base": "LRA",
        "JMK | Mikonos Airport": "JMK",
        "MLO | Milos Airport": "MLO",
        "MJT | Mytilene International Airport": "MJT",
        "LGN | Linga Linga Airport": "LGN",
        "JNX | Naxos Apollon Airport": "JNX",
        "PAS | Paros National Airport": "PAS",
        "JTY | Astypalaia Airport": "JTY",
        "PVK | Aktion National Airport": "PVK",
        "RHO | Diagoras Airport": "RHO",
        "GPA | Patras Araxos Agamemnon Airport": "GPA",
        "CHQ | Chania International Airport": "CHQ",
        "JSI | Skiathos Island National Airport": "JSI",
        "SMI | Samos Airport": "SMI",
        "JSY | Syros Airport": "JSY",
        "SPJ | Sparti Airport": "SPJ",
        "JTR | Santorini Airport": "JTR",
        "JSH | Sitia Airport": "JSH",
        "SKU | Skiros Airport": "SKU",
        "SKG | Thessaloniki Macedonia International Airport": "SKG",
        "ZTH | Zakynthos International Airport Dionysios Solomos": "ZTH",
        "BUD | Budapest Liszt Ferenc International Airport": "BUD",
        "DEB | Debrecen International Airport": "DEB",
        "MCQ | Miskolc Airport": "MCQ",
        "PEV | Pécs-Pogány Airport": "PEV",
        "SOB | Hévíz–Balaton Airport": "SOB",
        "TZR | Taszár Air Base": "TZR",
        "QAQ | L'Aquila–Preturo Airport": "QAQ",
        "CRV | Crotone Airport": "CRV",
        "BRI | Bari Karol Wojtyła Airport": "BRI",
        "FOG | Foggia Gino Lisa Airport": "FOG",
        "TAR | Taranto-Grottaglie Marcello Arlotta Airport": "TAR",
        "LCC | Lecce Galatina Air Base": "LCC",
        "PSR | Abruzzo Airport": "PSR",
        "BDS | Brindisi Airport": "BDS",
        "SUF | Lamezia Terme Airport": "SUF",
        "CIY | Comiso Airport": "CIY",
        "CTA | Catania-Fontanarossa Airport": "CTA",
        "LMP | Lampedusa Airport": "LMP",
        "PNL | Pantelleria Airport": "PNL",
        "PMO | Falcone–Borsellino Airport": "PMO",
        "REG | Reggio Calabria Airport": "REG",
        "TPS | Vincenzo Florio Airport Trapani-Birgi": "TPS",
        "NSY | Sigonella Navy Air Base": "NSY",
        "BLX | Belluno Airport": "BLX",
        "RAN | Ravenna Airport": "RAN",
        "AHO | Alghero-Fertilia Airport": "AHO",
        "DCI | Decimomannu Air Base": "DCI",
        "CAG | Cagliari Elmas Airport": "CAG",
        "OLB | Olbia Costa Smeralda Airport": "OLB",
        "FNU | Oristano-Fenosu Airport": "FNU",
        "TTB | Tortolì Airport": "TTB",
        "MXP | Malpensa International Airport": "MXP",
        "BGY | Milan Bergamo Airport": "BGY",
        "TRN | Turin Airport": "TRN",
        "ALL | Villanova D'Albenga International Airport": "ALL",
        "GOA | Genoa Cristoforo Colombo Airport": "GOA",
        "LIN | Milano Linate Airport": "LIN",
        "PMF | Parma Airport": "PMF",
        "AOT | Aosta Airport": "AOT",
        "CUF | Cuneo International Airport": "CUF",
        "AVB | Aviano Air Base": "AVB",
        "BZO | Bolzano Airport": "BZO",
        "UDN | Udine-Campoformido Air Base": "UDN",
        "BLQ | Bologna Guglielmo Marconi Airport": "BLQ",
        "TSF | Treviso-Sant'Angelo Airport": "TSF",
        "FRL | Forlì Airport": "FRL",
        "VBS | Brescia Airport": "VBS",
        "TRS | Trieste–Friuli Venezia Giulia Airport": "TRS",
        "RMI | Federico Fellini International Airport": "RMI",
        "VRN | Verona Villafranca Airport": "VRN",
        "AOI | Marche Airport": "AOI",
        "VCE | Venice Marco Polo Airport": "VCE",
        "LCV | Lucca-Tassignano Airport": "LCV",
        "SAY | Siena-Ampugnano Airport": "SAY",
        "CIA | Ciampino–G. B. Pastine International Airport": "CIA",
        "FCO | Rome–Fiumicino Leonardo da Vinci International Airport": "FCO",
        "QSR | Salerno Costa d'Amalfi Airport": "QSR",
        "EBA | Marina Di Campo Airport": "EBA",
        "NAP | Naples International Airport": "NAP",
        "PSA | Pisa International Airport": "PSA",
        "FLR | Peretola Airport": "FLR",
        "GRS | Grosseto Air Base": "GRS",
        "PEG | Perugia San Francesco d'Assisi – Umbria International Airport": "PEG",
        "LJU | Ljubljana Jože Pučnik Airport": "LJU",
        "MBX | Maribor Airport": "MBX",
        "POW | Portorož Airport": "POW",
        "LKC | Lekana Airport": "LKC",
        "UHE | Kunovice Airport": "UHE",
        "KLV | Karlovy Vary International Airport": "KLV",
        "MKA | Mariánské Lázně Airport": "MKA",
        "OSR | Ostrava Leos Janáček Airport": "OSR",
        "OLO | Olomouc Airport": "OLO",
        "PED | Pardubice Airport": "PED",
        "PRV | Přerov Air Base": "PRV",
        "PRG | Václav Havel Airport Prague": "PRG",
        "BRQ | Brno-Tuřany Airport": "BRQ",
        "VOD | Vodochody Airport": "VOD",
        "ZBE | Zabreh Ostrava Airport": "ZBE",
        "TLV | Ben Gurion International Airport": "TLV",
        "BEV | Beersheba (Teyman) Airport": "BEV",
        "ETM | Ramon Airport": "ETM",
        "EIY | Ein Yahav Airfield": "EIY",
        "LLH | Reginaldo Hammer Airport": "LLH",
        "HFA | Haifa International Airport": "HFA",
        "RPN | Ben Ya'akov Airport": "RPN",
        "KSW | Kiryat Shmona Airport": "KSW",
        "LLL | Lissadell Airport": "LLL",
        "MTZ | Bar Yehuda Airfield": "MTZ",
        "VTM | Nevatim Air Base": "VTM",
        "VDA | Ovda Airport": "VDA",
        "MIP | Ramon Air Base": "MIP",
        "YOT | Yotvata Airfield": "YOT",
        "LMC | La Macarena Airport": "LMC",
        "MLA | Malta International Airport": "MLA",
        "LMV | Madivaru Airport": "LMV",
        "LMZ | Palma Airport": "LMZ",
        "LNC | Lengbati Airport": "LNC",
        "LNF | Munbil Airport": "LNF",
        "LNM | Langimar Airport": "LNM",
        "HOH | Hohenems-Dornbirn Airfield": "HOH",
        "LOM | Francisco Primo de Verdad y Ramos Airport": "LOM",
        "GRZ | Graz Airport": "GRZ",
        "INN | Innsbruck Airport": "INN",
        "KLU | Klagenfurt Airport": "KLU",
        "LNZ | Linz-Hörsching Airport / Vogler Air Base": "LNZ",
        "SZG | Salzburg Airport": "SZG",
        "VIE | Vienna International Airport": "VIE",
        "AVR | Alverca Air Base": "AVR",
        "SMA | Santa Maria Airport": "SMA",
        "BGC | Bragança Airport": "BGC",
        "BYJ | Beja Airport / Airbase": "BYJ",
        "BGZ | Braga Municipal Aerodrome": "BGZ",
        "CHV | Aerodromo de Chaves": "CHV",
        "CVU | Corvo Airport": "CVU",
        "CAT | Cascais Airport": "CAT",
        "FLW | Flores Airport": "FLW",
        "FAO | Faro Airport": "FAO",
        "GRW | Graciosa Airport": "GRW",
        "HOR | Horta Airport": "HOR",
        "TER | Lajes Airport": "TER",
        "FNC | Madeira Airport": "FNC",
        "QLR | Monte Real Air Base": "QLR",
        "PDL | João Paulo II Airport": "PDL",
        "PIX | Pico Airport": "PIX",
        "PRM | Portimão Airport": "PRM",
        "OPO | Francisco de Sá Carneiro Airport": "OPO",
        "PXO | Porto Santo Airport": "PXO",
        "LIS | Humberto Delgado Airport (Lisbon Portela Airport)": "LIS",
        "SJZ | São Jorge Airport": "SJZ",
        "VRL | Vila Real Airport": "VRL",
        "VSE | Aerodromo Goncalves Lobato (Viseu Airport)": "VSE",
        "BNX | Banja Luka International Airport": "BNX",
        "OMO | Mostar International Airport": "OMO",
        "SJJ | Sarajevo International Airport": "SJJ",
        "TZL | Tuzla International Airport": "TZL",
        "ARW | Arad International Airport": "ARW",
        "BCM | Bacău Airport": "BCM",
        "BAY | Maramureș International Airport": "BAY",
        "BBU | Băneasa International Airport": "BBU",
        "CND | Mihail Kogălniceanu International Airport": "CND",
        "CLJ | Cluj-Napoca International Airport": "CLJ",
        "CSB | Caransebeş Airport": "CSB",
        "CRA | Craiova Airport": "CRA",
        "IAS | Iaşi Airport": "IAS",
        "OMR | Oradea International Airport": "OMR",
        "OTP | Henri Coandă International Airport": "OTP",
        "SBZ | Sibiu International Airport": "SBZ",
        "SUJ | Satu Mare Airport": "SUJ",
        "SCV | Suceava Stefan cel Mare Airport": "SCV",
        "TCE | Tulcea Danube Delta Airport": "TCE",
        "TGM | Transilvania Târgu Mureş International Airport": "TGM",
        "TSR | Timişoara Traian Vuia Airport": "TSR",
        "GVA | Geneva Cointrin International Airport": "GVA",
        "SIR | Sion Airport": "SIR",
        "EML | Emmen Air Base": "EML",
        "LUG | Lugano Airport": "LUG",
        "BRN | Bern Belp Airport": "BRN",
        "BXO | Buochs Airport": "BXO",
        "ZHI | Grenchen Airport": "ZHI",
        "ZRH | Zürich Airport": "ZRH",
        "ZJI | Locarno Airport": "ZJI",
        "ACH | St Gallen Altenrhein Airport": "ACH",
        "SMV | Samedan Airport": "SMV",
        "GKD | Gökçeada Airport": "GKD",
        "ESB | Esenboğa International Airport": "ESB",
        "ANK | Etimesgut Air Base": "ANK",
        "ADA | Adana Şakirpaşa Airport": "ADA",
        "UAB | İncirlik Air Base": "UAB",
        "AFY | Afyon Air Base": "AFY",
        "AYT | Antalya International Airport": "AYT",
        "GZT | Gaziantep International Airport": "GZT",
        "KFS | Kastamonu Airport": "KFS",
        "KYA | Konya Airport": "KYA",
        "MZH | Amasya Merzifon Airport": "MZH",
        "VAS | Sivas Nuri Demirağ Airport": "VAS",
        "ONQ | Zonguldak Çaycuma Airport": "ONQ",
        "MLX | Malatya Erhaç Airport": "MLX",
        "ASR | Kayseri Erkilet Airport": "ASR",
        "TJK | Tokat Airport": "TJK",
        "DNZ | Çardak Airport": "DNZ",
        "NAV | Nevşehir Kapadokya Airport": "NAV",
        "ISL | İstanbul Atatürk Airport": "ISL",
        "CII | Çıldır Airport": "CII",
        "BTZ | Bursa Airport": "BTZ",
        "BZI | Balıkesir Merkez Airport": "BZI",
        "BDM | Bandırma Airport": "BDM",
        "CKZ | Çanakkale Airport": "CKZ",
        "ESK | Eskişehir Air Base": "ESK",
        "ADB | Adnan Menderes International Airport": "ADB",
        "IGL | Çiğli Airport": "IGL",
        "USQ | Uşak Airport": "USQ",
        "KCO | Cengiz Topel Airport": "KCO",
        "YEI | Bursa Yenişehir Airport": "YEI",
        "DLM | Dalaman International Airport": "DLM",
        "TEQ | Tekirdağ Çorlu Airport": "TEQ",
        "BXN | Bodrum-Imsık Airport": "BXN",
        "AOE | Anadolu Airport": "AOE",
        "KZR | Zafer Airport": "KZR",
        "EZS | Elazığ Airport": "EZS",
        "DIY | Diyarbakır Airport": "DIY",
        "ERC | Erzincan Airport": "ERC",
        "ERZ | Erzurum International Airport": "ERZ",
        "KSY | Kars Airport": "KSY",
        "TZX | Trabzon International Airport": "TZX",
        "SFQ | Şanlıurfa Airport": "SFQ",
        "VAN | Van Ferit Melen Airport": "VAN",
        "BAL | Batman Airport": "BAL",
        "MSR | Muş Airport": "MSR",
        "SXZ | Siirt Airport": "SXZ",
        "NOP | Sinop Airport": "NOP",
        "KCM | Kahramanmaraş Airport": "KCM",
        "AJI | Ağrı Airport": "AJI",
        "ADF | Adıyaman Airport": "ADF",
        "MQM | Mardin Airport": "MQM",
        "GNY | Şanlıurfa GAP Airport": "GNY",
        "NKT | Şırnak Şerafettin Elçi Airport": "NKT",
        "YKO | Hakkari Yüksekova Airport": "YKO",
        "HTY | Hatay Airport": "HTY",
        "LTF | Leitre Airport": "LTF",
        "ISE | Süleyman Demirel International Airport": "ISE",
        "EDO | Balıkesir Koca Seyit Airport": "EDO",
        "BJV | Milas Bodrum International Airport": "BJV",
        "GZP | Gazipaşa-Alanya Airport": "GZP",
        "SZF | Samsun-Çarşamba Airport": "SZF",
        "SAW | Istanbul Sabiha Gökçen International Airport": "SAW",
        "IST | İstanbul Airport": "IST",
        "BZY | Bălți International Airport": "BZY",
        "KIV | Chişinău International Airport": "KIV",
        "LUZ | Lublin Airport": "LUZ",
        "LWA | Lebak Rural Airport": "LWA",
        "OHD | Ohrid St. Paul the Apostle Airport": "OHD",
        "SKP | Skopje International Airport": "SKP",
        "GIB | Gibraltar Airport": "GIB",
        "BCQ | Brak Airport": "BCQ",
        "DNF | Martubah Airport": "DNF",
        "MRA | Misratah Airport": "MRA",
        "QUB | Ubari Airport": "QUB",
        "UZC | Ponikve Airport": "UZC",
        "BEG | Belgrade Nikola Tesla Airport": "BEG",
        "IVG | Berane Airport": "IVG",
        "BJY | Batajnica Air Base": "BJY",
        "KVO | Morava Airport": "KVO",
        "INI | Nis Airport": "INI",
        "TGD | Podgorica Airport / Podgorica Golubovci Airbase": "TGD",
        "TIV | Tivat Airport": "TIV",
        "BTS | M. R. Štefánik Airport": "BTS",
        "KSC | Košice Airport": "KSC",
        "LUE | Lučenec Airport": "LUE",
        "LZO | Luzhou Yunlong Airport": "LZO",
        "PZY | Piešťany Airport": "PZY",
        "POV | Prešov Air Base": "POV",
        "SLD | Sliač Airport": "SLD",
        "TAT | Poprad-Tatry Airport": "TAT",
        "ILZ | Žilina Airport": "ILZ",
        "DRU | Drummond Airport": "DRU",
        "GLN | Guelmim Airport": "GLN",
        "UWA | Ware Airport": "UWA",
        "MAP | Mamai Airport": "MAP",
        "GDT | JAGS McCartney International Airport": "GDT",
        "MDS | Middle Caicos Airport": "MDS",
        "NCA | North Caicos Airport": "NCA",
        "PIC | Pine Cay Airport": "PIC",
        "PLS | Providenciales International Airport": "PLS",
        "XSC | South Caicos Airport": "XSC",
        "SLX | Salt Cay Airport": "SLX",
        "BRX | Maria Montez International Airport": "BRX",
        "CBJ | Cabo Rojo Airport": "CBJ",
        "AZS | Samaná El Catey International Airport": "AZS",
        "COZ | Constanza - Expedición 14 de Junio National Airport": "COZ",
        "JBQ | La Isabela International Airport": "JBQ",
        "LRM | Casa De Campo International Airport": "LRM",
        "PUJ | Punta Cana International Airport": "PUJ",
        "POP | Gregorio Luperon International Airport": "POP",
        "SDQ | Las Américas International Airport": "SDQ",
        "STI | Cibao International Airport": "STI",
        "MDV | Médouneu Airport": "MDV",
        "LIZ | Loring International Airport": "LIZ",
        "MEF | Melfi Airport": "MEF",
        "OHB | Ambohibary Airport": "OHB",
        "DOA | Doany Airport": "DOA",
        "CBV | Coban Airport": "CBV",
        "CMM | Carmelita Airport": "CMM",
        "CTF | Coatepeque Airport": "CTF",
        "GUA | La Aurora Airport": "GUA",
        "HUG | Huehuetenango Airport": "HUG",
        "MCR | Melchor de Mencos Airport": "MCR",
        "MGP | Manga Airport": "MGP",
        "PBR | Puerto Barrios Airport": "PBR",
        "PON | Poptún Airport": "PON",
        "AQB | Quiché Airport": "AQB",
        "AAZ | Quezaltenango Airport": "AAZ",
        "RUV | Rubelsanto Airport": "RUV",
        "LCF | Las Vegas Airport": "LCF",
        "RER | Retalhuleu Airport": "RER",
        "GSJ | San José Airport": "GSJ",
        "FRS | Mundo Maya International Airport": "FRS",
        "AIM | Ailuk Airport": "AIM",
        "AUL | Aur Island Airport": "AUL",
        "BII | Enyu Airfield": "BII",
        "EBN | Ebadon Airport": "EBN",
        "JAT | Jabot Airport": "JAT",
        "JEJ | Jeh Airport": "JEJ",
        "LIK | Likiep Airport": "LIK",
        "LML | Lae Island Airport": "LML",
        "MAV | Maloelap Island Airport": "MAV",
        "MJB | Mejit Atoll Airport": "MJB",
        "MJE | Majkin Airport": "MJE",
        "NDK | Namorik Atoll Airport": "NDK",
        "RNP | Rongelap Island Airport": "RNP",
        "TIC | Tinak Airport": "TIC",
        "UIT | Jaluit Airport": "UIT",
        "WJA | Woja Airport": "WJA",
        "WTE | Wotje Airport": "WTE",
        "WTO | Wotho Island Airport": "WTO",
        "AHS | Ahuas Airport": "AHS",
        "BHG | Brus Laguna Airport": "BHG",
        "CAA | Catacamas Airport": "CAA",
        "LUI | Carta Airport": "LUI",
        "CYL | Coyoles Airport": "CYL",
        "CDD | Cauquira Airport": "CDD",
        "OAN | El Arrayán Airport": "OAN",
        "GAC | Celaque Airport": "GAC",
        "IRN | Iriona Airport": "IRN",
        "GUO | Jicalapa Airport": "GUO",
        "JUT | Jutigalpa airport": "JUT",
        "LCE | Goloson International Airport": "LCE",
        "LEZ | La Esperanza Airport": "LEZ",
        "SAP | Ramón Villeda Morales International Airport": "SAP",
        "MHN | Hooker County Airport": "MHN",
        "GJA | La Laguna Airport": "GJA",
        "PCH | Palacios Airport": "PCH",
        "PEU | Puerto Lempira Airport": "PEU",
        "RTB | Juan Manuel Gálvez International Airport": "RTB",
        "RUY | Copán Ruinas Airport": "RUY",
        "XPL | Coronel Enrique Soto Cano Air Base": "XPL",
        "TEA | Tela Airport": "TEA",
        "TGU | Toncontín International Airport": "TGU",
        "TJI | Trujillo Airport": "TJI",
        "SCD | Sulaco Airport": "SCD",
        "UII | Utila Airport": "UII",
        "MHY | Morehead Airport": "MHY",
        "ORO | Yoro Airport": "ORO",
        "MIZ | Mainoru Airstrip": "MIZ",
        "MJJ | Moki Airport": "MJJ",
        "MJS | Maganja da Costa Airport": "MJS",
        "OCJ | Ian Fleming International Airport": "OCJ",
        "KIN | Norman Manley International Airport": "KIN",
        "MBJ | Sangster International Airport": "MBJ",
        "POT | Ken Jones Airport": "POT",
        "MKN | Malekolon Airport": "MKN",
        "NEG | Negril Airport": "NEG",
        "KTP | Tinson Pen Airport": "KTP",
        "MIJ | Mili Island Airport": "MIJ",
        "MLQ | Malalaua Airport": "MLQ",
        "HEB | Hinthada Airport": "HEB",
        "TZM | Cupul Airport": "TZM",
        "ACA | General Juan N Alvarez International Airport": "ACA",
        "NTR | Del Norte International Airport": "NTR",
        "AGU | Jesús Terán Paredo International Airport": "AGU",
        "HUX | Bahías de Huatulco International Airport": "HUX",
        "CNA | Cananea National Airport": "CNA",
        "CVJ | Cuernavaca - General Mariano Matamoros Airport": "CVJ",
        "ACN | Ciudad Acuña New International Airport": "ACN",
        "CME | Ciudad del Carmen International Airport": "CME",
        "NCG | Nuevo Casas Grandes Municipal Airport": "NCG",
        "CUL | Bachigualato Federal International Airport": "CUL",
        "CTM | Chetumal International Airport": "CTM",
        "CEN | Ciudad Obregón International Airport": "CEN",
        "CJT | San Antonio Copalar Airport": "CJT",
        "CPE | Ingeniero Alberto Acuña Ongay International Airport": "CPE",
        "CJS | Abraham González International Airport": "CJS",
        "CZA | Chichen Itza International Airport": "CZA",
        "CUU | General Roberto Fierro Villalobos International Airport": "CUU",
        "CVM | General Pedro Jose Mendez International Airport": "CVM",
        "CYW | Captain Rogelio Castillo National Airport": "CYW",
        "CZM | Cozumel International Airport": "CZM",
        "CUA | Ciudad Constitución National Airport": "CUA",
        "MMC | Ciudad Mante National Airport": "MMC",
        "DGO | General Guadalupe Victoria International Airport": "DGO",
        "TPQ | Amado Nervo National Airport": "TPQ",
        "ESE | Ensenada International Airport / El Cipres Air Base": "ESE",
        "GDL | Don Miguel Hidalgo Y Costilla International Airport": "GDL",
        "GYM | General José María Yáñez International Airport": "GYM",
        "GUB | Guerrero Negro Airport": "GUB",
        "TCN | Tehuacan Airport": "TCN",
        "HMO | General Ignacio P. Garcia International Airport": "HMO",
        "CLQ | Licenciado Miguel de la Madrid Airport": "CLQ",
        "ISJ | Isla Mujeres Airport": "ISJ",
        "SLW | Plan De Guadalupe International Airport": "SLW",
        "IZT | Ixtepec Airport": "IZT",
        "JAL | El Lencero Airport": "JAL",
        "AZP | Atizapán de Zaragoza - Doctor Jorge Jiménez Cantu National Airport": "AZP",
        "LZC | Lázaro Cárdenas Airport": "LZC",
        "LMM | Valle del Fuerte International Airport": "LMM",
        "BJX | Del Bajío International Airport": "BJX",
        "LAP | Manuel Márquez de León International Airport": "LAP",
        "LTO | Loreto International Airport": "LTO",
        "MAM | General Servando Canales International Airport": "MAM",
        "MID | Licenciado Manuel Crescencio Rejon Int Airport": "MID",
        "MUG | Mulege Airport": "MUG",
        "MXL | General Rodolfo Sánchez Taboada International Airport": "MXL",
        "MLM | General Francisco J. Mujica International Airport": "MLM",
        "MTT | Minatitlán/Coatzacoalcos National Airport": "MTT",
        "LOV | Monclova International Airport": "LOV",
        "MEX | Licenciado Benito Juarez International Airport": "MEX",
        "MTY | General Mariano Escobedo International Airport": "MTY",
        "MZT | General Rafael Buelna International Airport": "MZT",
        "NOG | Nogales International Airport": "NOG",
        "NLD | Quetzalcóatl International Airport": "NLD",
        "OAX | Xoxocotlán International Airport": "OAX",
        "PAZ | El Tajín National Airport": "PAZ",
        "PBC | Hermanos Serdán International Airport": "PBC",
        "PDS | Piedras Negras International Airport": "PDS",
        "UPN | Uruapan - Licenciado y General Ignacio Lopez Rayon International Airport": "UPN",
        "PQM | Palenque International Airport": "PQM",
        "PVR | Licenciado Gustavo Díaz Ordaz International Airport": "PVR",
        "PXM | Puerto Escondido International Airport": "PXM",
        "QRO | Querétaro Intercontinental Airport": "QRO",
        "REX | General Lucio Blanco International Airport": "REX",
        "SJD | Los Cabos International Airport": "SJD",
        "SFH | San Felipe International Airport": "SFH",
        "NLU | Santa Lucía Air Force Base / General Felipe Ángeles International Airport": "NLU",
        "SLP | Ponciano Arriaga International Airport": "SLP",
        "TRC | Francisco Sarabia International Airport": "TRC",
        "TGZ | Angel Albino Corzo International Airport": "TGZ",
        "TIJ | General Abelardo L. Rodríguez International Airport": "TIJ",
        "TAM | General Francisco Javier Mina International Airport": "TAM",
        "TSL | Tamuin Airport": "TSL",
        "TLC | Licenciado Adolfo Lopez Mateos International Airport": "TLC",
        "TAP | Tapachula International Airport": "TAP",
        "CUN | Cancún International Airport": "CUN",
        "MMV | Mal Airport": "MMV",
        "VSA | Carlos Rovirosa Pérez International Airport": "VSA",
        "VER | General Heriberto Jara International Airport": "VER",
        "ZCL | General Leobardo C. Ruiz International Airport": "ZCL",
        "ZIH | Ixtapa Zihuatanejo International Airport": "ZIH",
        "ZMM | Zamora Airport": "ZMM",
        "ZLO | Playa De Oro International Airport": "ZLO",
        "MXW | Mandalgobi Airport": "MXW",
        "BEF | Bluefields Airport": "BEF",
        "BZA | San Pedro Airport": "BZA",
        "ECI | Costa Esmeralda Airport": "ECI",
        "RNI | Corn Island Airport": "RNI",
        "MGA | Augusto C. Sandino (Managua) International Airport": "MGA",
        "NVG | Nueva Guinea Airport": "NVG",
        "PUZ | Puerto Cabezas Airport": "PUZ",
        "RFS | Rosita Airport": "RFS",
        "NCR | San Carlos": "NCR",
        "SIU | Siuna": "SIU",
        "WSP | Waspam Airport": "WSP",
        "MOH | Maleo Airport": "MOH",
        "MOS | Moses Point Airport": "MOS",
        "BOC | Bocas del Toro International Airport": "BOC",
        "CTD | Alonso Valderrama Airport": "CTD",
        "CHX | Cap Manuel Niño International Airport": "CHX",
        "DAV | Enrique Malek International Airport": "DAV",
        "ONX | Enrique Adolfo Jimenez Airport": "ONX",
        "MPG | Makini Airport": "MPG",
        "BLB | Panamá Pacífico International Airport": "BLB",
        "MPI | Mamitupo Airport": "MPI",
        "JQE | Jaqué Airport": "JQE",
        "PAC | Marcos A. Gelabert International Airport": "PAC",
        "PUE | Puerto Obaldía Airport": "PUE",
        "PDM | Capt. J. Montenegro Airport": "PDM",
        "RIH | Scarlett Martinez International Airport": "RIH",
        "SYP | Ruben Cantu Airport": "SYP",
        "PTY | Tocumen International Airport": "PTY",
        "MPU | Mapua(Mabua) Airport": "MPU",
        "PVE | El Porvenir Airport": "PVE",
        "NBL | San Blas Airport": "NBL",
        "MPX | Miyanmin Airport": "MPX",
        "MQO | Malam Airport": "MQO",
        "FON | La Fortuna Arenal Airport": "FON",
        "TTQ | Aerotortuguero Airport": "TTQ",
        "BAI | Buenos Aires Airport": "BAI",
        "BCL | Barra del Colorado Airport": "BCL",
        "OTR | Coto 47 Airport": "OTR",
        "JAP | Chacarita Airport": "JAP",
        "PLD | Playa Samara/Carrillo Airport": "PLD",
        "DRK | Drake Bay Airport": "DRK",
        "FMG | Flamingo Airport": "FMG",
        "GLF | Golfito Airport": "GLF",
        "GPL | Guapiles Airport": "GPL",
        "PBP | Islita Airport": "PBP",
        "LIR | Daniel Oduber Quiros International Airport": "LIR",
        "LSL | Los Chiles Airport": "LSL",
        "LIO | Limon International Airport": "LIO",
        "CSC | Mojica Airport": "CSC",
        "NCT | Guanacaste Airport": "NCT",
        "NOB | Nosara Airport": "NOB",
        "SJO | Juan Santamaría International Airport": "SJO",
        "PJM | Puerto Jimenez Airport": "PJM",
        "PMZ | Palmar Sur Airport": "PMZ",
        "SYQ | Tobías Bolaños International Airport": "SYQ",
        "XQP | Quepos Managua Airport": "XQP",
        "RFR | Rio Frio / Progreso Airport": "RFR",
        "IPZ | San Isidro del General Airport": "IPZ",
        "TOO | San Vito De Java Airport": "TOO",
        "MRT | Moroak Airport": "MRT",
        "TNO | Tamarindo Airport": "TNO",
        "TMU | Tambor Airport": "TMU",
        "UPL | Upala Airport": "UPL",
        "MSB | Marigot Seaplane Base": "MSB",
        "SAL | Monseñor Óscar Arnulfo Romero International Airport": "SAL",
        "CYA | Les Cayes Airport": "CYA",
        "CAP | Cap Haitien International Airport": "CAP",
        "MTX | Metro Field": "MTX",
        "JAK | Jacmel Airport": "JAK",
        "JEE | Jérémie Airport": "JEE",
        "PAP | Toussaint Louverture International Airport": "PAP",
        "PAX | Port-de-Paix Airport": "PAX",
        "MTU | Montepuez Airport": "MTU",
        "BCA | Gustavo Rizo Airport": "BCA",
        "BWW | Las Brujas Airport": "BWW",
        "BYM | Carlos Manuel de Cespedes Airport": "BYM",
        "AVI | Maximo Gomez Airport": "AVI",
        "CCC | Jardines Del Rey Airport": "CCC",
        "CFG | Jaime Gonzalez Airport": "CFG",
        "CYO | Vilo Acuña International Airport": "CYO",
        "CMW | Ignacio Agramonte International Airport": "CMW",
        "SCU | Antonio Maceo International Airport": "SCU",
        "NBW | Leeward Point Field": "NBW",
        "GAO | Mariana Grajales Airport": "GAO",
        "HAV | José Martí International Airport": "HAV",
        "HOG | Frank Pais International Airport": "HOG",
        "VRO | Kawama Airport": "VRO",
        "LCL | La Coloma Airport": "LCL",
        "UMA | Punta de Maisi Airport": "UMA",
        "MJG | Mayajigua Airport": "MJG",
        "MOA | Orestes Acosta Airport": "MOA",
        "MZO | Sierra Maestra Airport": "MZO",
        "QSN | San Nicolas De Bari Airport": "QSN",
        "ICR | Nicaro Airport": "ICR",
        "GER | Rafael Cabrera Airport": "GER",
        "UPB | Playa Baracoa Airport": "UPB",
        "SNU | Abel Santamaria Airport": "SNU",
        "SNJ | San Julian Air Base": "SNJ",
        "SZJ | Siguanea Airport": "SZJ",
        "USS | Sancti Spiritus Airport": "USS",
        "TND | Alberto Delgado Airport": "TND",
        "VRA | Juan Gualberto Gomez International Airport": "VRA",
        "VTU | Hermanos Ameijeiras Airport": "VTU",
        "CYB | Gerrard Smith International Airport": "CYB",
        "LYB | Edward Bodden Little Cayman Airfield": "LYB",
        "GCM | Owen Roberts International Airport": "GCM",
        "MWR | Motswari Airport": "MWR",
        "AJS | Punta Abreojos Airport": "AJS",
        "AZG | Apatzingán - Pablo L. Sidar Airport": "AZG",
        "PCM | Playa del Carmen Airport": "PCM",
        "PCV | Punta Chivato Airport": "PCV",
        "SCX | Salina Cruz Naval Air Station": "SCX",
        "SGM | San Ignacio Airport": "SGM",
        "TUY | Tulum Naval Air Station": "TUY",
        "UAC | San Luis Río Colorado Airport": "UAC",
        "XAL | Álamos Airport": "XAL",
        "MXC | Monticello Airport": "MXC",
        "MXK | Mindik Airport": "MXK",
        "GTK | Sungai Tekai Airport": "GTK",
        "LBP | Long Banga Airport": "LBP",
        "LLM | Long Lama Airport": "LLM",
        "MZS | Mostyn Airport": "MZS",
        "SPT | Sipitang Airport": "SPT",
        "MAY | Clarence A. Bain Airport": "MAY",
        "ASD | Andros Town Airport": "ASD",
        "TZN | Congo Town Airport": "TZN",
        "MHH | Leonard M Thompson International Airport": "MHH",
        "SAQ | San Andros Airport": "SAQ",
        "AXP | Spring Point Airport": "AXP",
        "TCB | Treasure Cay Airport": "TCB",
        "WKR | Walkers Cay Airport": "WKR",
        "CCZ | Chub Cay Airport": "CCZ",
        "GHC | Great Harbour Cay Airport": "GHC",
        "BIM | South Bimini Airport": "BIM",
        "ATC | Arthur's Town Airport": "ATC",
        "TBI | New Bight Airport": "TBI",
        "CRI | Colonel Hill Airport": "CRI",
        "PWN | Pitts Town Airport": "PWN",
        "GGT | Exuma International Airport": "GGT",
        "ELH | North Eleuthera Airport": "ELH",
        "GHB | Governor's Harbour Airport": "GHB",
        "NMC | Normans Cay Airport": "NMC",
        "RSD | Rock Sound Airport": "RSD",
        "TYM | Staniel Cay Airport": "TYM",
        "FPO | Grand Bahama International Airport": "FPO",
        "GBI | Grand Bahama Auxiliary Airfield": "GBI",
        "WTD | West End Airport": "WTD",
        "IGA | Inagua Airport": "IGA",
        "MYK | May Creek Airport": "MYK",
        "LGI | Deadman's Cay Airport": "LGI",
        "SML | Stella Maris Airport": "SML",
        "MYG | Mayaguana Airport": "MYG",
        "NAS | Lynden Pindling International Airport": "NAS",
        "DCT | Duncan Town Airport": "DCT",
        "RCY | Rum Cay Airport": "RCY",
        "MYS | Moyale Airport": "MYS",
        "ZSA | San Salvador Airport": "ZSA",
        "MYX | Menyamya Airport": "MYX",
        "NTC | Paradise Island Airport": "NTC",
        "IBO | Ibo Airport": "IBO",
        "TGS | Chokwé Airport": "TGS",
        "BZE | Philip S. W. Goldson International Airport": "BZE",
        "MZE | Manatee Airport": "MZE",
        "CYD | San Ignacio Town (Maya Flats) Airstrip": "CYD",
        "IMI | Ine Airport": "IMI",
        "BQI | Bagani Airport": "BQI",
        "NBS | Changbaishan Airport": "NBS",
        "AIT | Aitutaki Airport": "AIT",
        "AIU | Enua Airport": "AIU",
        "MGS | Mangaia Island Airport": "MGS",
        "MHX | Manihiki Island Airport": "MHX",
        "MUK | Mauke Airport": "MUK",
        "MOI | Mitiaro Island Airport": "MOI",
        "PZK | Pukapuka Island Airport": "PZK",
        "PYE | Tongareva Airport": "PYE",
        "RAR | Rarotonga International Airport": "RAR",
        "NDI | Namudi Airport": "NDI",
        "NDN | Nadunumu Airport": "NDN",
        "EPG | Browns Airport": "EPG",
        "ICI | Cicia Airport": "ICI",
        "CST | Castaway Island Seaplane Base": "CST",
        "NAN | Nadi International Airport": "NAN",
        "PTF | Malolo Lailai Island Airport": "PTF",
        "RBI | Rabi Island Airport": "RBI",
        "KDV | Vunisea Airport": "KDV",
        "MNF | Mana Island Airport": "MNF",
        "MFJ | Moala Airport": "MFJ",
        "SUV | Nausori International Airport": "SUV",
        "LEV | Levuka Airfield": "LEV",
        "NGI | Ngau Airport": "NGI",
        "LUC | Laucala Island Airport": "LUC",
        "LKB | Lakeba Island Airport": "LKB",
        "LBS | Labasa Airport": "LBS",
        "TVU | Matei Airport": "TVU",
        "KXF | Koro Island Airport": "KXF",
        "RTA | Rotuma Airport": "RTA",
        "SVU | Savusavu Airport": "SVU",
        "VAU | Vatukoula Airport": "VAU",
        "KAY | Wakaya Island Airport": "KAY",
        "ONU | Ono-i-Lau Airport": "ONU",
        "YAS | Yasawa Island Airport": "YAS",
        "EUA | Kaufana Airport": "EUA",
        "TBU | Fua'amotu International Airport": "TBU",
        "HPA | Lifuka Island Airport": "HPA",
        "NFO | Mata'aho Airport": "NFO",
        "NTT | Kuini Lavenia Airport": "NTT",
        "VAV | Vava'u International Airport": "VAV",
        "TTL | Turtle Island Seaplane Base": "TTL",
        "VBV | Vanua Balavu Airport": "VBV",
        "VTF | Vatulele Airport": "VTF",
        "GMO | Gombe Lawanti International Airport": "GMO",
        "PHG | Port Harcourt City Airport / Port Harcourt Air Force Base": "PHG",
        "QRW | Warri Airport": "QRW",
        "ABF | Abaiang Airport": "ABF",
        "BEZ | Beru Airport": "BEZ",
        "FUN | Funafuti International Airport": "FUN",
        "KUC | Kuria Airport": "KUC",
        "MNK | Maiana Airport": "MNK",
        "MZK | Marakei Airport": "MZK",
        "MTK | Makin Island Airport": "MTK",
        "NIG | Nikunau Airport": "NIG",
        "OOT | Onotoa Airport": "OOT",
        "TRW | Bonriki International Airport": "TRW",
        "AEA | Abemama Airport": "AEA",
        "TBF | Tabiteuea North Airport": "TBF",
        "TMN | Tamana Island Airport": "TMN",
        "NON | Nonouti Airport": "NON",
        "AIS | Arorae Island Airport": "AIS",
        "TSU | Tabiteuea South Airport": "TSU",
        "BBG | Butaritari Airport": "BBG",
        "AAK | Aranuka Airport": "AAK",
        "NIK | Niokolo-Koba Airport": "NIK",
        "IUE | Niue International Airport": "IUE",
        "NJJ | Nenjian Melgen Airport": "NJJ",
        "NKD | Sinak Airport": "NKD",
        "NLH | Ninglang Luguhu Airport": "NLH",
        "FUT | Pointe Vele Airport": "FUT",
        "WLS | Hihifo Airport": "WLS",
        "HBB | Industrial Airpark": "HBB",
        "NND | Nangade Airport": "NND",
        "NOM | Nomad River Airport": "NOM",
        "NOO | Naoro Airport": "NOO",
        "NPG | Nipa Airport": "NPG",
        "NRY | Newry Airport": "NRY",
        "OFU | Ofu Airport": "OFU",
        "AAU | Asau Airport": "AAU",
        "APW | Faleolo International Airport": "APW",
        "FTI | Fitiuta Airport": "FTI",
        "MXS | Maota Airport": "MXS",
        "PPG | Pago Pago International Airport": "PPG",
        "PPT | Faa'a International Airport": "PPT",
        "RMT | Rimatara Airport": "RMT",
        "RUR | Rurutu Airport": "RUR",
        "TUB | Tubuai Airport": "TUB",
        "RVV | Raivavae Airport": "RVV",
        "AAA | Anaa Airport": "AAA",
        "FGU | Fangatau Airport": "FGU",
        "TIH | Tikehau Airport": "TIH",
        "APK | Apataki Airport": "APK",
        "REA | Reao Airport": "REA",
        "FAV | Fakarava Airport": "FAV",
        "HHZ | Hikueru Airport": "HHZ",
        "XMH | Manihi Airport": "XMH",
        "GMR | Totegegie Airport": "GMR",
        "KKR | Kaukura Airport": "KKR",
        "MKP | Makemo Airport": "MKP",
        "NAU | Napuka Island Airport": "NAU",
        "TKV | Tatakoto Airport": "TKV",
        "PKP | Puka Puka Airport": "PKP",
        "PUK | Pukaruha Airport": "PUK",
        "TKP | Takapoto Airport": "TKP",
        "AXR | Arutua Airport": "AXR",
        "MVT | Mataiva Airport": "MVT",
        "NUK | Nukutavake Airport": "NUK",
        "ZTA | Tureia Airport": "ZTA",
        "AHE | Ahe Airport": "AHE",
        "KHZ | Kauehi Airport": "KHZ",
        "FAC | Faaite Airport": "FAC",
        "FHZ | Fakahina Airport": "FHZ",
        "RKA | Aratika Nord Airport": "RKA",
        "TJN | Takume Airport": "TJN",
        "NIU | Naiu Airport": "NIU",
        "RRR | Raroia Airport": "RRR",
        "TKX | Takaroa Airport": "TKX",
        "KXU | Katiu Airport": "KXU",
        "NKP | Nukutepipi Airport": "NKP",
        "NHV | Nuku Hiva Airport": "NHV",
        "AUQ | Hiva Oa-Atuona Airport": "AUQ",
        "UAP | Ua Pou Airport": "UAP",
        "UAH | Ua Huka Airport": "UAH",
        "BOB | Bora Bora Airport": "BOB",
        "TTI | Tetiaroa Airport": "TTI",
        "RGI | Rangiroa Airport": "RGI",
        "HUH | Huahine-Fare Airport": "HUH",
        "MOZ | Moorea Temae Airport": "MOZ",
        "HOI | Hao Airport": "HOI",
        "MAU | Maupiti Airport": "MAU",
        "RFP | Raiatea Airport": "RFP",
        "TPX | Tupai Airport": "TPX",
        "UOA | Moruroa Airport": "UOA",
        "VHZ | Vahitahi Airport": "VHZ",
        "NUF | Castlereagh Lake Seaplane Base": "NUF",
        "NUG | Nuguria Airstrip": "NUG",
        "UCC | Yucca Airstrip": "UCC",
        "MTV | Mota Lava Airport": "MTV",
        "SLH | Sola Airport": "SLH",
        "TOH | Torres Airstrip": "TOH",
        "EAE | Siwo Airport": "EAE",
        "CCV | Craig Cove Airport": "CCV",
        "LOD | Longana Airport": "LOD",
        "SSR | Sara Airport": "SSR",
        "PBJ | Tavie Airport": "PBJ",
        "LPM | Lamap Airport": "LPM",
        "LNB | Lamen Bay Airport": "LNB",
        "MWF | Maewo-Naone Airport": "MWF",
        "LNE | Lonorore Airport": "LNE",
        "NUS | Norsup Airport": "NUS",
        "ZGU | Gaua Island Airport": "ZGU",
        "RCL | Redcliffe Airport": "RCL",
        "SON | Santo Pekoa International Airport": "SON",
        "TGH | Tongoa Airport": "TGH",
        "ULB | Uléi Airport": "ULB",
        "VLS | Valesdir Airport": "VLS",
        "WLH | Walaha Airport": "WLH",
        "SWJ | Southwest Bay Airport": "SWJ",
        "OLJ | North West Santo Airport": "OLJ",
        "AUY | Aneityum Airport": "AUY",
        "AWD | Aniwa Airport": "AWD",
        "DLY | Dillon's Bay Airport": "DLY",
        "FTA | Futuna Airport": "FTA",
        "IPA | Ipota Airport": "IPA",
        "VLI | Bauerfield International Airport": "VLI",
        "TAH | Tanna Airport": "TAH",
        "TGJ | Tiga Airport": "TGJ",
        "BMY | Île Art - Waala Airport": "BMY",
        "KNQ | Koné Airport": "KNQ",
        "ILP | Île des Pins Airport": "ILP",
        "HLU | Nesson Airport": "HLU",
        "KOC | Koumac Airport": "KOC",
        "LIF | Lifou Airport": "LIF",
        "GEA | Nouméa Magenta Airport": "GEA",
        "PUV | Poum / Malabou Airport": "PUV",
        "PDC | Mueo Airport": "PDC",
        "MEE | Maré Airport": "MEE",
        "TOU | Touho Airport": "TOU",
        "UVE | Ouvéa Airport": "UVE",
        "NOU | La Tontouta International Airport": "NOU",
        "AKL | Auckland International Airport": "AKL",
        "TUO | Taupo Airport": "TUO",
        "AMZ | Ardmore Airport": "AMZ",
        "ASG | Ashburton Aerodrome": "ASG",
        "CHC | Christchurch International Airport": "CHC",
        "CHT | Chatham Islands / Tuuta Airport": "CHT",
        "CMV | Coromandel Aerodrome": "CMV",
        "DGR | Dargaville Aerodrome": "DGR",
        "DUD | Dunedin International Airport": "DUD",
        "WHO | Franz Josef Aerodrome": "WHO",
        "GBZ | Great Barrier Aerodrome": "GBZ",
        "GMN | Greymouth Airport": "GMN",
        "GIS | Gisborne Airport": "GIS",
        "GTN | Glentanner Airport": "GTN",
        "HKK | Hokitika Airfield": "HKK",
        "HLZ | Hamilton International Airport": "HLZ",
        "WIK | Waiheke Reeve Airport": "WIK",
        "KBZ | Kaikoura Airport": "KBZ",
        "KKE | Kerikeri Airport": "KKE",
        "KKO | Kaikohe Airport": "KKO",
        "KAT | Kaitaia Airport": "KAT",
        "ALR | Alexandra Aerodrome": "ALR",
        "MTA | Matamata Glider Airport": "MTA",
        "MON | Mount Cook Airport": "MON",
        "MFN | Milford Sound Airport": "MFN",
        "MZP | Motueka Airport": "MZP",
        "TEU | Manapouri Airport": "TEU",
        "MRO | Hood Airport": "MRO",
        "NPL | New Plymouth Airport": "NPL",
        "NPE | Hawke's Bay Airport": "NPE",
        "NSN | Nelson Airport": "NSN",
        "IVC | Invercargill Airport": "IVC",
        "OHA | RNZAF Base Ohakea": "OHA",
        "OAM | Oamaru Airport": "OAM",
        "PMR | Palmerston North Airport": "PMR",
        "PCN | Picton Aerodrome": "PCN",
        "PPQ | Paraparaumu Airport": "PPQ",
        "ZQN | Queenstown International Airport": "ZQN",
        "RAG | Raglan Aerodrome": "RAG",
        "SZS | Ryan's Creek Aerodrome": "SZS",
        "ROT | Rotorua Regional Airport": "ROT",
        "TRG | Tauranga Airport": "TRG",
        "TMZ | Thames Aerodrome": "TMZ",
        "KTF | Takaka Airport": "KTF",
        "TKZ | Tokoroa Airfield": "TKZ",
        "THH | Taharoa Aerodrome": "THH",
        "TIU | Timaru Airport": "TIU",
        "TWZ | Pukaki Airport": "TWZ",
        "BHE | Woodbourne Airport": "BHE",
        "WKA | Wanaka Airport": "WKA",
        "WHK | Whakatane Airport": "WHK",
        "WLG | Wellington International Airport": "WLG",
        "WIR | Wairoa Airport": "WIR",
        "WRE | Whangarei Airport": "WRE",
        "WSZ | Westport Airport": "WSZ",
        "WTZ | Whitianga Airport": "WTZ",
        "WAG | Wanganui Airport": "WAG",
        "NLN | Kneeland Airport": "NLN",
        "BZF | Benton Field": "BZF",
        "OAA | Shank Air Base": "OAA",
        "BIN | Bamyan Airport": "BIN",
        "BST | Bost Airport": "BST",
        "CCN | Chaghcharan Airport": "CCN",
        "SBF | Sardeh Band Airport": "SBF",
        "DAZ | Darwaz Airport": "DAZ",
        "FAH | Farah Airport": "FAH",
        "FBD | Fayzabad Airport": "FBD",
        "GRG | Gardez Airport": "GRG",
        "KWH | Khwahan Airport": "KWH",
        "HEA | Herat - Khwaja Abdullah Ansari International Airport": "HEA",
        "OAI | Bagram Airfield": "OAI",
        "JAA | Jalalabad Airport": "JAA",
        "KBL | Kabul International Airport": "KBL",
        "KDH | Ahmad Shah Baba International Airport / Kandahar Airfield": "KDH",
        "MMZ | Maymana Zahiraddin Faryabi Airport": "MMZ",
        "MZR | Mazar-i-Sharif International Airport": "MZR",
        "VCC | Nili Airport": "VCC",
        "URN | Urgun Airport": "URN",
        "LQN | Qala-i-Naw Airport": "LQN",
        "OAS | Sharana Airstrip": "OAS",
        "OAH | Shindand Air Base": "OAH",
        "SGA | Sheghnan Airport": "SGA",
        "TII | Tarinkot Airport": "TII",
        "TQN | Taleqan Airport": "TQN",
        "UND | Kunduz Airport": "UND",
        "OAZ | Camp Shorabak Airfield": "OAZ",
        "ZAJ | Zaranj Airport": "ZAJ",
        "BAH | Bahrain International Airport": "BAH",
        "OCS | Corisco International Airport": "OCS",
        "AHB | Abha International Airport": "AHB",
        "HOF | Al-Ahsa International Airport": "HOF",
        "ABT | King Saud Bin Abdulaziz (Al Baha) Airport": "ABT",
        "BHH | Bisha Airport": "BHH",
        "DMM | King Fahd International Airport": "DMM",
        "DWD | King Salman Abdulaziz Airport": "DWD",
        "DHA | King Abdulaziz Air Base": "DHA",
        "GIZ | Jizan Regional Airport / King Abdullah bin Abdulaziz Airport": "GIZ",
        "ELQ | Gassim Airport": "ELQ",
        "URY | Gurayat Domestic Airport": "URY",
        "HAS | Ha'il Airport": "HAS",
        "QJB | Jubail Airport": "QJB",
        "JED | King Abdulaziz International Airport": "JED",
        "KMC | King Khaled Military City Airport": "KMC",
        "KMX | King Khalid Air Base": "KMX",
        "MED | Prince Mohammad Bin Abdulaziz Airport": "MED",
        "EAM | Najran Domestic Airport": "EAM",
        "NUM | Neom Bay Airport": "NUM",
        "AQI | Al Qaisumah/Hafr Al Batin Airport": "AQI",
        "AKH | Prince Sultan Air Base": "AKH",
        "RAH | Rafha Domestic Airport": "RAH",
        "RUH | King Khaled International Airport": "RUH",
        "RAE | Arar Domestic Airport": "RAE",
        "XXN | Riyadh Air Base": "XXN",
        "SHW | Sharurah Domestic Airport": "SHW",
        "AJF | Al-Jawf Domestic Airport": "AJF",
        "SLF | As-Sulayyil Airport": "SLF",
        "TUU | Tabuk Airport": "TUU",
        "TIF | Ta’if Regional Airport": "TIF",
        "TUI | Turaif Domestic Airport": "TUI",
        "WAE | Wadi Al Dawasir Domestic Airport": "WAE",
        "EJH | Al Wajh Domestic Airport": "EJH",
        "YNB | Yanbu Airport / Prince Abdul Mohsin bin Abdulaziz international Airport": "YNB",
        "ZUL | Zilfi Airport": "ZUL",
        "OGE | Ogeranang Airport": "OGE",
        "OGM | Ogubsucum Airport": "OGM",
        "IAQ | Bahregan Airport": "IAQ",
        "ABD | Abadan Airport": "ABD",
        "DEF | Dezful Airport": "DEF",
        "AKW | Aghajari Airport": "AKW",
        "GCH | Gachsaran Airport": "GCH",
        "QMJ | Shahid Asiyaee Airport": "QMJ",
        "OMI | Omidiyeh Airport": "OMI",
        "MRX | Mahshahr Airport": "MRX",
        "AWZ | Lieutenant General Qasem Soleimani International Airport": "AWZ",
        "AEU | Abu Musa Island Airport": "AEU",
        "BUZ | Bushehr Airport": "BUZ",
        "KNR | Jam Airport": "KNR",
        "KIH | Kish International Airport": "KIH",
        "BDH | Bandar Lengeh Airport": "BDH",
        "PGU | Persian Gulf International Airport": "PGU",
        "KHK | Khark Island Airport": "KHK",
        "SXI | Sirri Island Airport": "SXI",
        "LVP | Lavan Island Airport": "LVP",
        "KSH | Shahid Ashrafi Esfahani Airport": "KSH",
        "IIL | Ilam Airport": "IIL",
        "KHD | Khoram Abad Airport": "KHD",
        "SDG | Sanandaj Airport": "SDG",
        "IFH | Hesa Airport": "IFH",
        "KKS | Kashan Airport": "KKS",
        "IFN | Esfahan Shahid Beheshti International Airport": "IFN",
        "CQD | Shahrekord Airport": "CQD",
        "RAS | Sardar-e-Jangal Airport": "RAS",
        "HDM | Hamadan Airport": "HDM",
        "AJK | Arak Airport": "AJK",
        "IKA | Imam Khomeini International Airport": "IKA",
        "THR | Mehrabad International Airport": "THR",
        "GZW | Qazvin Airport": "GZW",
        "PYK | Payam International Airport": "PYK",
        "SNX | Semnan Municipal Airport": "SNX",
        "BND | Bandar Abbas International Airport": "BND",
        "JYR | Jiroft Airport": "JYR",
        "KER | Kerman Airport": "KER",
        "BXR | Bam Airport": "BXR",
        "HDR | Havadarya Airport": "HDR",
        "GSM | Qeshm International Airport": "GSM",
        "RJN | Rafsanjan Airport": "RJN",
        "SYJ | Sirjan Airport": "SYJ",
        "XBJ | Birjand International Airport": "XBJ",
        "CKT | Sarakhs Airport": "CKT",
        "RUD | Shahroud Airport": "RUD",
        "MHD | Mashhad International Airport": "MHD",
        "BJB | Bojnord Airport": "BJB",
        "AFZ | Sabzevar National Airport": "AFZ",
        "TCX | Tabas Airport": "TCX",
        "KLM | Kalaleh Airport": "KLM",
        "GBT | Gorgan Airport": "GBT",
        "BSM | Bishe Kola Air Base": "BSM",
        "NSH | Noshahr Airport": "NSH",
        "RZR | Ramsar Airport": "RZR",
        "SRY | Sari Dasht-e Naz International Airport": "SRY",
        "FAZ | Fasa Airport": "FAZ",
        "JAR | Jahrom Airport": "JAR",
        "LRR | Lar Airport": "LRR",
        "LFM | Lamerd Airport": "LFM",
        "SYZ | Shiraz Shahid Dastghaib International Airport": "SYZ",
        "YES | Yasouj Airport": "YES",
        "KHY | Khoy Airport": "KHY",
        "ADU | Ardabil Airport": "ADU",
        "ACP | Sahand Airport": "ACP",
        "PFQ | Parsabad-Moghan Airport": "PFQ",
        "OMH | Urmia Airport": "OMH",
        "TBZ | Tabriz International Airport": "TBZ",
        "IMQ | Maku National Airport": "IMQ",
        "JWN | Zanjan Airport": "JWN",
        "AZD | Shahid Sadooghi Airport": "AZD",
        "ACZ | Zabol Airport": "ACZ",
        "ZBR | Konarak Airport": "ZBR",
        "ZAH | Zahedan International Airport": "ZAH",
        "IHR | Iranshahr Airport": "IHR",
        "JSK | Jask Airport": "JSK",
        "AMM | Queen Alia International Airport": "AMM",
        "ADJ | Amman Civil (Marka International) Airport": "ADJ",
        "AQJ | Aqaba King Hussein International Airport": "AQJ",
        "OMF | King Hussein Air College": "OMF",
        "XIJ | Ahmed Al Jaber Air Base": "XIJ",
        "KWI | Kuwait International Airport": "KWI",
        "OKV | Okao Airport": "OKV",
        "BEY | Beirut Rafic Hariri International Airport": "BEY",
        "OLH | Old Harbor Airport": "OLH",
        "KYE | Rene Mouawad Air Base / Kleyate Airport": "KYE",
        "OLQ | Olsobip Airport": "OLQ",
        "BYB | Dibba Airport": "BYB",
        "AOM | Adam Airport": "AOM",
        "DQM | Duqm International Airport": "DQM",
        "MNH | Mussanah Airport": "MNH",
        "AUH | Abu Dhabi International Airport": "AUH",
        "AZI | Al Bateen Executive Airport": "AZI",
        "AAN | Al Ain International Airport": "AAN",
        "DHF | Al Dhafra Air Base": "DHF",
        "XSB | Sir Bani Yas Airport": "XSB",
        "DXB | Dubai International Airport": "DXB",
        "NHD | Al Minhad Air Base": "NHD",
        "DWC | Al Maktoum International Airport": "DWC",
        "FJR | Fujairah International Airport": "FJR",
        "OMN | Osmanabad Airport": "OMN",
        "RKT | Ras Al Khaimah International Airport": "RKT",
        "SHJ | Sharjah International Airport": "SHJ",
        "OMY | Preah Vinhear Airport": "OMY",
        "ONB | Ononge Airport": "ONB",
        "RMB | Buraimi Airport": "RMB",
        "FAU | Fahud Airport": "FAU",
        "RNM | Qarn Alam Airport": "RNM",
        "JNJ | Ja'Aluni Airport": "JNJ",
        "KHS | Khasab Air Base": "KHS",
        "LKW | Lekhwair Airport": "LKW",
        "MSH | Masirah Air Base": "MSH",
        "MCT | Muscat International Airport": "MCT",
        "OMM | Marmul Airport": "OMM",
        "SLL | Salalah Airport": "SLL",
        "OHS | Sohar Airport": "OHS",
        "TTH | Thumrait Air Base": "TTH",
        "BHW | Bhagatanwala Airport": "BHW",
        "BNP | Bannu Airport": "BNP",
        "WGB | Bahawalnagar Airport": "WGB",
        "BHV | Bahawalpur Airport": "BHV",
        "CJL | Chitral Airport": "CJL",
        "CHB | Chilas Airport": "CHB",
        "DBA | Dalbandin Airport": "DBA",
        "DDU | Dadu Airport": "DDU",
        "DEA | Dera Ghazi Khan Airport": "DEA",
        "DSK | Dera Ismael Khan Airport": "DSK",
        "LYP | Faisalabad International Airport": "LYP",
        "GWD | Gwadar International Airport": "GWD",
        "GIL | Gilgit Airport": "GIL",
        "ISB | Islamabad International Airport": "ISB",
        "JAG | Shahbaz Air Base": "JAG",
        "JIW | Jiwani Airport": "JIW",
        "KHI | Jinnah International Airport": "KHI",
        "HDD | Hyderabad Airport": "HDD",
        "KDD | Khuzdar Airport": "KDD",
        "OHT | Kohat Airport": "OHT",
        "LHE | Alama Iqbal International Airport": "LHE",
        "LRG | Loralai Airport": "LRG",
        "XJM | Mangla Airport": "XJM",
        "MFG | Muzaffarabad Airport": "MFG",
        "MWD | Mianwali Air Base": "MWD",
        "MJD | Moenjodaro Airport": "MJD",
        "MPD | Sindhri Tharparkar Airport": "MPD",
        "ATG | Minhas Air Base": "ATG",
        "MUX | Multan International Airport": "MUX",
        "WNS | Shaheed Benazirabad Airport": "WNS",
        "ORW | Ormara Airport": "ORW",
        "PAJ | Parachinar Airport": "PAJ",
        "PJG | Panjgur Airport": "PJG",
        "PSI | Pasni Airport": "PSI",
        "PEW | Peshawar International Airport": "PEW",
        "UET | Quetta International Airport": "UET",
        "RYK | Shaikh Zaid Airport": "RYK",
        "RAZ | Rawalakot Airport": "RAZ",
        "SBQ | Sibi Airport": "SBQ",
        "KDU | Skardu Airport": "KDU",
        "SKZ | Sukkur Airport": "SKZ",
        "SYW | Sehwan Sharif Airport": "SYW",
        "SGI | Mushaf Air Base": "SGI",
        "SDT | Saidu Sharif Airport": "SDT",
        "SKT | Sialkot International Airport": "SKT",
        "SUL | Sui Airport": "SUL",
        "SWN | Sahiwal Airport": "SWN",
        "TLB | Tarbela Dam Airport": "TLB",
        "BDN | Talhar Airport": "BDN",
        "TFT | Taftan Airport": "TFT",
        "TUK | Turbat International Airport": "TUK",
        "WAF | Wana Airport": "WAF",
        "PZH | Zhob Airport": "PZH",
        "IQA | Al Asad Air Base": "IQA",
        "TQD | Al Taqaddum Air Base": "TQD",
        "BMN | Bamarni Airport": "BMN",
        "BGW | Baghdad International Airport / New Al Muthana Air Base": "BGW",
        "OSM | Mosul International Airport": "OSM",
        "EBL | Erbil International Airport": "EBL",
        "ORI | Port Lions Airport": "ORI",
        "KIK | Kirkuk Air Base": "KIK",
        "BSR | Basra International Airport": "BSR",
        "NJF | Al Najaf International Airport": "NJF",
        "RQW | Qayyarah West Airport": "RQW",
        "ISU | Sulaymaniyah International Airport": "ISU",
        "XNH | Ali Air Base": "XNH",
        "ALP | Aleppo International Airport": "ALP",
        "DAM | Damascus International Airport": "DAM",
        "DEZ | Deir ez-Zor Airport": "DEZ",
        "OSE | Omora Airport": "OSE",
        "OSG | Ossima Airport": "OSG",
        "KAC | Qamishli Airport": "KAC",
        "LTK | Bassel Al-Assad International Airport": "LTK",
        "PMS | Palmyra Airport": "PMS",
        "DIA | Doha International Airport": "DIA",
        "XJD | Al Udeid Air Base": "XJD",
        "DOH | Hamad International Airport": "DOH",
        "OTT | Andre Maggi Airport": "OTT",
        "OTY | Oria Airport": "OTY",
        "OUM | Oum Hadjer Airport": "OUM",
        "OXO | Orientos Airport": "OXO",
        "ADE | Aden International Airport": "ADE",
        "EAB | Abs Airport": "EAB",
        "AXK | Ataq Airport": "AXK",
        "BYD | Al-Bayda Airport": "BYD",
        "BHN | Beihan Airport": "BHN",
        "BUK | Al-Bough Airport": "BUK",
        "AAY | Al Ghaydah International Airport": "AAY",
        "HOD | Hodeidah International Airport": "HOD",
        "KAM | Kamaran Airport": "KAM",
        "MYN | Mareb Airport": "MYN",
        "UKR | Mukeiras Airport": "UKR",
        "IHN | Qishn Airport": "IHN",
        "RIY | Riyan Mukalla International Airport": "RIY",
        "SYE | Sadah Airport": "SYE",
        "SAH | Sana'a International Airport": "SAH",
        "SCT | Socotra International Airport": "SCT",
        "GXF | Seiyun Hadhramaut International Airport": "GXF",
        "TAI | Ta'izz International Airport": "TAI",
        "ACU | Achutupu Airport": "ACU",
        "AIL | Alligandi Airport": "AIL",
        "CTE | Carti Airport": "CTE",
        "MPP | Mulatupo Airport": "MPP",
        "PYC | Playón Chico Airport": "PYC",
        "NMG | San Miguel Airport": "NMG",
        "PYV | Yaviza Airport": "PYV",
        "BFQ | Bahia Piña Airport": "BFQ",
        "ELE | EL Real Airport": "ELE",
        "OTD | Raul Arias Espinoza Airport": "OTD",
        "SAX | Sambú Airport": "SAX",
        "AKB | Atka Airport": "AKB",
        "PML | Port Moller Airport": "PML",
        "PTD | Port Alexander Seaplane Base": "PTD",
        "PAQ | Warren Bud Woods Palmer Municipal Airport": "PAQ",
        "BTI | Barter Island Long Range Radar Station Airport": "BTI",
        "BET | Bethel Airport": "BET",
        "BVU | Beluga Airport": "BVU",
        "BIG | Allen Army Airfield": "BIG",
        "BKC | Buckland Airport": "BKC",
        "BMX | Big Mountain Airport": "BMX",
        "BRW | Wiley Post Will Rogers Memorial Airport": "BRW",
        "BTT | Bettles Airport": "BTT",
        "CDB | Cold Bay Airport": "CDB",
        "CEM | Central Airport": "CEM",
        "CIK | Chalkyitsik Airport": "CIK",
        "CYF | Chefornak Airport": "CYF",
        "SCM | Scammon Bay Airport": "SCM",
        "IRC | Circle City (New) Airport": "IRC",
        "CDV | Merle K (Mudhole) Smith Airport": "CDV",
        "CXF | Coldfoot Airport": "CXF",
        "CYT | Yakataga Airport": "CYT",
        "CZF | Cape Romanzof LRRS Airport": "CZF",
        "DRG | Deering Airport": "DRG",
        "RDB | Red Dog Airport": "RDB",
        "ADK | Adak Airport": "ADK",
        "DLG | Dillingham Airport": "DLG",
        "MLL | Marshall Don Hunter Sr Airport": "MLL",
        "ADQ | Kodiak Airport": "ADQ",
        "DUT | Tom Madsen (Dutch Harbor) Airport": "DUT",
        "KKH | Kongiganak Airport": "KKH",
        "EDF | Elmendorf Air Force Base": "EDF",
        "EEK | Eek Airport": "EEK",
        "EAA | Eagle Airport": "EAA",
        "EHM | Cape Newenham LRRS Airport": "EHM",
        "EIL | Eielson Air Force Base": "EIL",
        "ELV | Elfin Cove Seaplane Base": "ELV",
        "EMK | Emmonak Airport": "EMK",
        "ENA | Kenai Municipal Airport": "ENA",
        "WWT | Newtok Airport": "WWT",
        "FAI | Fairbanks International Airport": "FAI",
        "FBK | Ladd AAF Airfield": "FBK",
        "ABL | Ambler Airport": "ABL",
        "NIB | Nikolai Airport": "NIB",
        "FWL | Farewell Airport": "FWL",
        "GAL | Edward G. Pitka Sr Airport": "GAL",
        "GBH | Galbraith Lake Airport": "GBH",
        "KWK | Kwigillingok Airport": "KWK",
        "SHG | Shungnak Airport": "SHG",
        "GKN | Gulkana Airport": "GKN",
        "GLV | Golovin Airport": "GLV",
        "GAM | Gambell Airport": "GAM",
        "AGN | Angoon Seaplane Base": "AGN",
        "BGQ | Big Lake Airport": "BGQ",
        "GST | Gustavus Airport": "GST",
        "NME | Nightmute Airport": "NME",
        "SGY | Skagway Airport": "SGY",
        "HCR | Holy Cross Airport": "HCR",
        "HSL | Huslia Airport": "HSL",
        "HNS | Haines Airport": "HNS",
        "HOM | Homer Airport": "HOM",
        "HPB | Hooper Bay Airport": "HPB",
        "HUS | Hughes Airport": "HUS",
        "SHX | Shageluk Airport": "SHX",
        "HYG | Hydaburg Seaplane Base": "HYG",
        "IGG | Igiugig Airport": "IGG",
        "EGX | Egegik Airport": "EGX",
        "IAN | Bob Baker Memorial Airport": "IAN",
        "ILI | Iliamna Airport": "ILI",
        "UTO | Indian Mountain LRRS Airport": "UTO",
        "MCL | McKinley National Park Airport": "MCL",
        "WAA | Wales Airport": "WAA",
        "JNU | Juneau International Airport": "JNU",
        "KGK | Koliganek Airport": "KGK",
        "KDK | Kodiak Municipal Airport": "KDK",
        "KFP | False Pass Airport": "KFP",
        "AKK | Akhiok Airport": "AKK",
        "KPN | Kipnuk Airport": "KPN",
        "KKA | Koyuk Alfred Adams Airport": "KKA",
        "LKK | Kulik Lake Airport": "LKK",
        "AKN | King Salmon Airport": "AKN",
        "IKO | Nikolski Air Station": "IKO",
        "AKP | Anaktuvuk Pass Airport": "AKP",
        "KTN | Ketchikan International Airport": "KTN",
        "UUK | Ugnu-Kuparuk Airport": "UUK",
        "KAL | Kaltag Airport": "KAL",
        "KLW | Klawock Airport": "KLW",
        "KYK | Karluk Airport": "KYK",
        "KLN | Larsen Bay Airport": "KLN",
        "KLG | Kalskag Airport": "KLG",
        "DQH | Alpine Airstrip": "DQH",
        "WCR | Chandalar Lake Airport": "WCR",
        "LUR | Cape Lisburne LRRS Airport": "LUR",
        "KMO | Manokotak Airport": "KMO",
        "MCG | McGrath Airport": "MCG",
        "MDO | Middleton Island Airport": "MDO",
        "LMA | Minchumina Airport": "LMA",
        "SMK | St Michael Airport": "SMK",
        "MLY | Manley Hot Springs Airport": "MLY",
        "MTM | Metlakatla Seaplane Base": "MTM",
        "MOU | Mountain Village Airport": "MOU",
        "MRI | Merrill Field": "MRI",
        "MXY | Mc Carthy Airport": "MXY",
        "MYU | Mekoryuk Airport": "MYU",
        "WNA | Napakiak Airport": "WNA",
        "ANC | Ted Stevens Anchorage International Airport": "ANC",
        "ANI | Aniak Airport": "ANI",
        "ENN | Nenana Municipal Airport": "ENN",
        "NNL | Nondalton Airport": "NNL",
        "FNR | Funter Bay Seaplane Base": "FNR",
        "ANN | Annette Island Airport": "ANN",
        "NUL | Nulato Airport": "NUL",
        "ANV | Anvik Airport": "ANV",
        "KNW | New Stuyahok Airport": "KNW",
        "OBU | Kobuk Airport": "OBU",
        "PCA | Portage Creek Airport": "PCA",
        "HNH | Hoonah Airport": "HNH",
        "OME | Nome Airport": "OME",
        "OOK | Toksook Bay Airport": "OOK",
        "ORT | Northway Airport": "ORT",
        "OTZ | Ralph Wien Memorial Airport": "OTZ",
        "NLG | Nelson Lagoon Airport": "NLG",
        "STG | St George Airport": "STG",
        "KPC | Port Clarence Coast Guard Station": "KPC",
        "KPV | Perryville Airport": "KPV",
        "PSG | Petersburg James A Johnson Airport": "PSG",
        "PTH | Port Heiden Airport": "PTH",
        "PKA | Napaskiak Airport": "PKA",
        "PTU | Platinum Airport": "PTU",
        "PIP | Pilot Point Airport": "PIP",
        "PHO | Point Hope Airport": "PHO",
        "PPC | Prospect Creek Airport": "PPC",
        "KWN | Quinhagak Airport": "KWN",
        "NUI | Nuiqsut Airport": "NUI",
        "ARC | Arctic Village Airport": "ARC",
        "RSH | Russian Mission Airport": "RSH",
        "RBY | Ruby Airport": "RBY",
        "SVA | Savoonga Airport": "SVA",
        "SCC | Deadhorse Airport": "SCC",
        "SDP | Sand Point Airport": "SDP",
        "SHH | Shishmaref Airport": "SHH",
        "SIT | Sitka Rocky Gutierrez Airport": "SIT",
        "WLK | Selawik Airport": "WLK",
        "SLQ | Sleetmute Airport": "SLQ",
        "KSM | St Mary's Airport": "KSM",
        "SNP | St Paul Island Airport": "SNP",
        "SOV | Seldovia Airport": "SOV",
        "SMU | Sheep Mountain Airport": "SMU",
        "UMM | Summit Airport": "UMM",
        "SVW | Sparrevohn LRRS Airport": "SVW",
        "SKW | Skwentna Airport": "SKW",
        "SXQ | Soldotna Airport": "SXQ",
        "SYA | Eareckson Air Station": "SYA",
        "TAL | Ralph M Calhoun Memorial Airport": "TAL",
        "TNC | Tin City Long Range Radar Station Airport": "TNC",
        "TLA | Teller Airport": "TLA",
        "TOG | Togiak Airport": "TOG",
        "TKA | Talkeetna Airport": "TKA",
        "TLJ | Tatalina LRRS Airport": "TLJ",
        "ATK | Atqasuk Edward Burnell Sr Memorial Airport": "ATK",
        "AUK | Alakanuk Airport": "AUK",
        "UMT | Umiat Airport": "UMT",
        "UNK | Unalakleet Airport": "UNK",
        "WOW | Willow Airport": "WOW",
        "KQA | Akutan Airport": "KQA",
        "VAK | Chevak Airport": "VAK",
        "KVC | King Cove Airport": "KVC",
        "VDZ | Valdez Pioneer Field": "VDZ",
        "VEE | Venetie Airport": "VEE",
        "KVL | Kivalina Airport": "KVL",
        "WBQ | Beaver Airport": "WBQ",
        "SWD | Seward Airport": "SWD",
        "WRG | Wrangell Airport": "WRG",
        "AIN | Wainwright Airport": "AIN",
        "WMO | White Mountain Airport": "WMO",
        "WTK | Noatak Airport": "WTK",
        "WWA | Wasilla Airport": "WWA",
        "YAK | Yakutat Airport": "YAK",
        "CIS | Canton Island Airport": "CIS",
        "PCO | Punta Colorada Airport": "PCO",
        "PCQ | Boun Neau Airport": "PCQ",
        "PDI | Pindiu Airport": "PDI",
        "PDR | Presidente José Sarney Airport": "PDR",
        "PEB | Pebane Airport": "PEB",
        "PEC | Pelican Seaplane Base": "PEC",
        "AKI | Akiak Airport": "AKI",
        "AET | Allakaket Airport": "AET",
        "PFC | Pacific City State Airport": "PFC",
        "NCN | Chenega Bay Airport": "NCN",
        "CLP | Clarks Point Airport": "CLP",
        "ELI | Elim Airport": "ELI",
        "KUK | Kasigluk Airport": "KUK",
        "KNK | Kokhanok Airport": "KNK",
        "KOT | Kotlik Airport": "KOT",
        "KTS | Brevig Mission Airport": "KTS",
        "KYU | Koyukuk Airport": "KYU",
        "KWT | Kwethluk Airport": "KWT",
        "ORV | Robert (Bob) Curtis Memorial Airport": "ORV",
        "SKK | Shaktoolik Airport": "SKK",
        "TKJ | Tok Junction Airport": "TKJ",
        "WSN | South Naknek Number 2 Airport": "WSN",
        "FYU | Fort Yukon Airport": "FYU",
        "CPN | Cape Rodney Airport": "CPN",
        "EMI | Emirau Airport": "EMI",
        "ERE | Erave Airport": "ERE",
        "ESA | Esa'ala Airport": "ESA",
        "GAR | Garaina Airport": "GAR",
        "GOE | Gonaili Airport": "GOE",
        "BPD | Bapi Airstrip": "BPD",
        "BPK | Biangabip Airport": "BPK",
        "NWT | Nowata Airport": "NWT",
        "SGK | Sengapi Airstrip": "SGK",
        "KII | Kibuli Airstrip": "KII",
        "AKG | Anguganak Airport": "AKG",
        "TAJ | Tadji Airport": "TAJ",
        "AWB | Awaba Airport": "AWB",
        "BAA | Bialla Airport": "BAA",
        "CVB | Chungribu Airport": "CVB",
        "GMI | Gasmata Island Airport": "GMI",
        "GVI | Green River Airport": "GVI",
        "HYF | Hayfields Airport": "HYF",
        "IHU | Ihu Airport": "IHU",
        "IIS | Nissan Island Airport": "IIS",
        "JAQ | Jacquinot Bay Airport": "JAQ",
        "KDR | Kandrian Airport": "KDR",
        "KKD | Kokoda Airport": "KKD",
        "KUY | Kamusi Airport": "KUY",
        "KWO | Kawito Airport": "KWO",
        "LMI | Lumi Airport": "LMI",
        "LMY | Lake Murray Airport": "LMY",
        "OBX | Obo Airport": "OBX",
        "OPU | Balimo Airport": "OPU",
        "SKC | Suki Airport": "SKC",
        "TFI | Tufi Airport": "TFI",
        "TFM | Telefomin Airport": "TFM",
        "TLO | Tol Airport": "TLO",
        "UKU | Nuku Airport": "UKU",
        "ULE | Sule Airport": "ULE",
        "VMU | Baimuru Airport": "VMU",
        "WPM | Wipim Airport": "WPM",
        "PGE | Yegepa Airport": "PGE",
        "PGM | Port Graham Airport": "PGM",
        "ROP | Rota International Airport": "ROP",
        "SPN | Saipan International Airport": "SPN",
        "UAM | Andersen Air Force Base": "UAM",
        "GUM | Antonio B. Won Pat International Airport": "GUM",
        "TIQ | Tinian International Airport": "TIQ",
        "CGY | Laguindingan Airport": "CGY",
        "LLC | Cagayan North International Airport": "LLC",
        "ENI | El Nido Airport": "ENI",
        "BKH | Barking Sands Airport": "BKH",
        "HDH | Dillingham Airfield": "HDH",
        "HHI | Wheeler Army Airfield": "HHI",
        "HNM | Hana Airport": "HNM",
        "HIK | Hickam Air Force Base": "HIK",
        "JHM | Kapalua Airport": "JHM",
        "JRF | Kalaeloa Airport": "JRF",
        "KOA | Ellison Onizuka Kona International Airport at Keahole": "KOA",
        "LIH | Lihue Airport": "LIH",
        "LUP | Kalaupapa Airport": "LUP",
        "MKK | Molokai Airport": "MKK",
        "MUE | Waimea Kohala Airport": "MUE",
        "NGF | Kaneohe Bay MCAS (Marion E. Carl Field) Airport": "NGF",
        "HNL | Daniel K Inouye International Airport": "HNL",
        "LNY | Lanai Airport": "LNY",
        "OGG | Kahului Airport": "OGG",
        "PAK | Port Allen Airport": "PAK",
        "BSF | Bradshaw Army Airfield": "BSF",
        "ITO | Hilo International Airport": "ITO",
        "UPP | Upolu Airport": "UPP",
        "BHC | Bhurban Heliport": "BHC",
        "CWP | Campbellpore Airport": "CWP",
        "GRT | Gujrat Airport": "GRT",
        "KCF | Kadanwari Airport": "KCF",
        "REQ | Reko Diq Airport": "REQ",
        "RZS | Sawan Airport": "RZS",
        "ENT | Eniwetok Airport": "ENT",
        "MAJ | Marshall Islands International Airport": "MAJ",
        "KAI | Kaieteur International Airport": "KAI",
        "KWA | Bucholz Army Air Field": "KWA",
        "CXI | Cassidy International Airport": "CXI",
        "PLE | Paiela Airport": "PLE",
        "TNV | Tabuaeran Island Airport": "TNV",
        "TNQ | Washington Island Airstrip": "TNQ",
        "MDY | Henderson Field": "MDY",
        "PMM | Phanom Sarakham Airport": "PMM",
        "PMP | Pimaga Airport": "PMP",
        "PIZ | Point Lay LRRS Airport": "PIZ",
        "PPX | Param Airport": "PPX",
        "HUC | Humacao Airport": "HUC",
        "XSO | Siocon Airport": "XSO",
        "TKK | Chuuk International Airport": "TKK",
        "PNI | Pohnpei International Airport": "PNI",
        "ROR | Babelthuap Airport": "ROR",
        "KSA | Kosrae International Airport": "KSA",
        "YAP | Yap International Airport": "YAP",
        "PUA | Puas Airport": "PUA",
        "AWK | Wake Island Airfield": "AWK",
        "PWR | Port Walter Seaplane Base": "PWR",
        "BFA | Bahía Negra Airport": "BFA",
        "OLK | Fuerte Olimpo Airport": "OLK",
        "PBT | Puerto Leda Airport": "PBT",
        "PCJ | Puerto La Victoria Airport": "PCJ",
        "PYL | Perry Island Seaplane Base": "PYL",
        "KIO | Kili Airport": "KIO",
        "QJP | Pocheon (G-217) Airport": "QJP",
        "RAA | Rakanda Airport": "RAA",
        "RAW | Arawa Airport": "RAW",
        "RAX | Oram Airport": "RAX",
        "RBP | Raba Raba Airport": "RBP",
        "KNH | Kinmen Airport": "KNH",
        "LZN | Matsu Nangan Airport": "LZN",
        "TTT | Taitung Airport": "TTT",
        "GNI | Lyudao Airport": "GNI",
        "KHH | Kaohsiung International Airport": "KHH",
        "CYI | Chiayi Airport": "CYI",
        "HCN | Hengchun Airport": "HCN",
        "KYD | Lanyu Airport": "KYD",
        "RMQ | Taichung International Airport / Ching Chuang Kang Air Base": "RMQ",
        "MFK | Matsu Beigan Airport": "MFK",
        "TNN | Tainan International Airport / Tainan Air Base": "TNN",
        "HSZ | Hsinchu Air Base": "HSZ",
        "MZG | Penghu Magong Airport": "MZG",
        "PIF | Pingtung North Airport": "PIF",
        "TSA | Taipei Songshan Airport": "TSA",
        "TPE | Taiwan Taoyuan International Airport": "TPE",
        "WOT | Wang'an Airport": "WOT",
        "HUN | Hualien Airport": "HUN",
        "RDV | Red Devil Airport": "RDV",
        "RHR | Ras al Khaimah Al Hamra Seaplane Base": "RHR",
        "RHT | Alxa Right Banner Badanjilin Airport": "RHT",
        "NRT | Narita International Airport": "NRT",
        "MMJ | Shinshu-Matsumoto Airport": "MMJ",
        "IBR | Ibaraki Airport / JASDF Hyakuri Air Base": "IBR",
        "MUS | JMSDF Minami Torishima Air Base": "MUS",
        "IWO | Ioto (Iwo Jima) Air Base": "IWO",
        "KIX | Kansai International Airport": "KIX",
        "SHM | Nanki Shirahama Airport": "SHM",
        "UKB | Kobe Airport": "UKB",
        "TJH | Konotori Tajima Airport": "TJH",
        "OBO | Tokachi-Obihiro Airport": "OBO",
        "CTS | New Chitose Airport": "CTS",
        "HKD | Hakodate Airport": "HKD",
        "KUH | Kushiro Airport": "KUH",
        "MMB | Memanbetsu Airport": "MMB",
        "SHB | Nakashibetsu Airport": "SHB",
        "OKD | Sapporo Okadama Airport": "OKD",
        "RBJ | Rebun Airport": "RBJ",
        "WKJ | Wakkanai Airport": "WKJ",
        "AXJ | Amakusa Airport": "AXJ",
        "IKI | Iki Airport": "IKI",
        "UBJ | Yamaguchi Ube Airport": "UBJ",
        "TSJ | Tsushima Airport": "TSJ",
        "OMJ | JMSDF Omura Air Base": "OMJ",
        "MBE | Monbetsu Airport": "MBE",
        "AKJ | Asahikawa Airport": "AKJ",
        "OIR | Okushiri Airport": "OIR",
        "RIS | Rishiri Airport": "RIS",
        "KUM | Yakushima Airport": "KUM",
        "FUJ | Fukue Airport": "FUJ",
        "FUK | Fukuoka Airport": "FUK",
        "TNE | New Tanegashima Airport": "TNE",
        "KOJ | Kagoshima Airport": "KOJ",
        "KMI | Miyazaki Airport": "KMI",
        "OIT | Oita Airport": "OIT",
        "KKJ | Kitakyushu Airport": "KKJ",
        "HSG | Saga Airport": "HSG",
        "KMJ | Kumamoto Airport": "KMJ",
        "NGS | Nagasaki Airport": "NGS",
        "NGO | Chubu Centrair International Airport": "NGO",
        "ASJ | Amami Airport": "ASJ",
        "OKE | Okinoerabu Airport": "OKE",
        "KKX | Kikai Airport": "KKX",
        "TKN | Tokunoshima Airport": "TKN",
        "NKM | Nagoya Airport / JASDF Komaki Air Base": "NKM",
        "FKJ | Fukui Airport": "FKJ",
        "QGU | Gifu Airport": "QGU",
        "KMQ | Komatsu Airport / JASDF Komatsu Air Base": "KMQ",
        "OKI | Oki Global Geopark Airport": "OKI",
        "FSZ | Mount Fuji Shizuoka Airport": "FSZ",
        "TOY | Toyama Airport": "TOY",
        "NTQ | Noto Satoyama Airport": "NTQ",
        "HIJ | Hiroshima Airport": "HIJ",
        "OKJ | Okayama Momotaro Airport": "OKJ",
        "IZO | Izumo Enmusubi Airport": "IZO",
        "YGJ | Yonago Kitaro Airport / JASDF Miho Air Base": "YGJ",
        "IWK | Iwakuni Kintaikyo Airport / Marine Corps Air Station Iwakuni": "IWK",
        "KCZ | Kochi Ryoma Airport": "KCZ",
        "MYJ | Matsuyama Airport": "MYJ",
        "ITM | Osaka International Airport": "ITM",
        "TTJ | Tottori Sand Dunes Conan Airport": "TTJ",
        "TKS | Tokushima Awaodori Airport / JMSDF Tokushima Air Base": "TKS",
        "TAK | Takamatsu Airport": "TAK",
        "IWJ | Iwami Airport": "IWJ",
        "AOJ | Aomori Airport": "AOJ",
        "GAJ | Yamagata Airport": "GAJ",
        "SDS | Sado Airport": "SDS",
        "FKS | Fukushima Airport": "FKS",
        "HHE | JMSDF Hachinohe Air Base / Hachinohe Airport": "HHE",
        "HNA | Morioka Hanamaki Airport": "HNA",
        "AXT | Akita Airport": "AXT",
        "MSJ | Misawa Air Base / Misawa Airport": "MSJ",
        "KIJ | Niigata Airport": "KIJ",
        "ONJ | Odate Noshiro Airport": "ONJ",
        "SDJ | Sendai Airport": "SDJ",
        "SYO | Shonai Airport": "SYO",
        "NJA | JMSDF Atsugi Air Base / Naval Air Facility Atsugi": "NJA",
        "HAC | Hachijojima Airport": "HAC",
        "OIM | Oshima Airport": "OIM",
        "MYE | Miyakejima Airport": "MYE",
        "HND | Tokyo Haneda International Airport": "HND",
        "OKO | Yokota Air Base": "OKO",
        "MWX | Muan International Airport": "MWX",
        "KWJ | Gwangju Airport": "KWJ",
        "KUV | Kunsan Air Base": "KUV",
        "CHN | Jeonju (G 703) Air Base": "CHN",
        "RSU | Yeosu Airport": "RSU",
        "KAG | Gangneung Airport (K-18)": "KAG",
        "WJU | Wonju Airport / Hoengseong Air Base (K-38/K-46)": "WJU",
        "YNY | Yangyang International Airport": "YNY",
        "CJU | Jeju International Airport": "CJU",
        "JDG | Jeongseok Airport": "JDG",
        "CHF | Jinhae Airbase/Airport (G-813/K-10)": "CHF",
        "PUS | Gimhae International Airport": "PUS",
        "HIN | Sacheon Air Base/Airport": "HIN",
        "USN | Ulsan Airport": "USN",
        "ICN | Incheon International Airport": "ICN",
        "SSN | Seoul Air Base (K-16)": "SSN",
        "OSN | Osan Air Base": "OSN",
        "GMP | Gimpo International Airport": "GMP",
        "SWU | Suwon Airport": "SWU",
        "QDY | Taean Airport": "QDY",
        "KPO | Pohang Airport (G-815/K-3)": "KPO",
        "TAE | Daegu Airport": "TAE",
        "HMY | Seosan Air Base": "HMY",
        "CJJ | Cheongju International Airport/Cheongju Air Base (K-59/G-513)": "CJJ",
        "YEC | Yecheon Airbase": "YEC",
        "RKU | Kairuku Airport": "RKU",
        "RKY | Rokeby Airport": "RKY",
        "RLP | Rosella Plains Airport": "RLP",
        "RMP | Rampart Airport": "RMP",
        "RMU | Región de Murcia International Airport": "RMU",
        "OKA | Naha Airport / JASDF Naha Air Base": "OKA",
        "DNA | Kadena Air Base": "DNA",
        "UEO | Kumejima Airport": "UEO",
        "KJP | Kerama Airport": "KJP",
        "MMD | Minamidaito Airport": "MMD",
        "MMY | Miyako Airport": "MMY",
        "AGJ | Aguni Airport": "AGJ",
        "IEJ | Iejima Airport": "IEJ",
        "HTR | Hateruma Airport": "HTR",
        "KTD | Kitadaito Airport": "KTD",
        "SHI | Miyako Shimojishima Airport": "SHI",
        "TRA | Tarama Airport": "TRA",
        "RNJ | Yoron Airport": "RNJ",
        "OGN | Yonaguni Airport": "OGN",
        "BSI | Balesin Island Airport": "BSI",
        "SFS | Subic Bay International Airport / Naval Air Station Cubi Point": "SFS",
        "CRK | Diosdado Macapagal International Airport": "CRK",
        "LAO | Laoag International Airport": "LAO",
        "MNL | Ninoy Aquino International Airport": "MNL",
        "CYU | Cuyo Airport": "CYU",
        "LGP | Legazpi City International Airport": "LGP",
        "SGL | Sangley Point International Airport / Danilo Atienza Air Base": "SGL",
        "LBX | Lubang Airport": "LBX",
        "AAV | Allah Valley Airport": "AAV",
        "CBO | Awang Airport": "CBO",
        "DVO | Francisco Bangoy International Airport": "DVO",
        "BXU | Bancasi Airport": "BXU",
        "BPH | Bislig Airport": "BPH",
        "DPL | Dipolog Airport": "DPL",
        "CGM | Camiguin Airport": "CGM",
        "IGN | Maria Cristina Airport": "IGN",
        "JOL | Jolo Airport": "JOL",
        "MLP | Malabang Airport": "MLP",
        "TWT | Sanga Sanga Airport": "TWT",
        "OZC | Labo Airport": "OZC",
        "PAG | Pagadian Airport": "PAG",
        "MXI | Mati National Airport": "MXI",
        "GES | General Santos International Airport": "GES",
        "SUG | Surigao Airport": "SUG",
        "CDY | Cagayan de Sulu Airport": "CDY",
        "IPE | Ipil Airport": "IPE",
        "TDG | Tandag Airport": "TDG",
        "ZAM | Zamboanga International Airport": "ZAM",
        "IAO | Siargao Airport": "IAO",
        "RZP | Cesar Lim Rodriguez (Taytay-Sandoval) Airport": "RZP",
        "TAG | Bohol-Panglao International Airport": "TAG",
        "SWL | San Vicente Airport": "SWL",
        "BAG | Loakan Airport": "BAG",
        "DTE | Daet Airport": "DTE",
        "SJI | San Jose Airport": "SJI",
        "MBO | Mamburao Airport": "MBO",
        "WNP | Naga Airport": "WNP",
        "BSO | Basco Airport": "BSO",
        "BQA | Dr Juan C Angara Airport": "BQA",
        "SFE | San Fernando Airport": "SFE",
        "TUG | Tuguegarao Airport": "TUG",
        "VRC | Virac Airport": "VRC",
        "MRQ | Marinduque Airport": "MRQ",
        "CYZ | Cauayan Airport": "CYZ",
        "RPV | Roper Valley Airport": "RPV",
        "TAC | Daniel Z. Romualdez Airport": "TAC",
        "BCD | Bacolod-Silay Airport": "BCD",
        "CYP | Calbayog Airport": "CYP",
        "DGT | Sibulan Airport": "DGT",
        "MPH | Godofredo P. Ramos Airport": "MPH",
        "CRM | Catarman National Airport": "CRM",
        "ILO | Iloilo International Airport": "ILO",
        "MBT | Moises R. Espinosa Airport": "MBT",
        "KLO | Kalibo International Airport": "KLO",
        "CEB | Mactan Cebu International Airport": "CEB",
        "OMC | Ormoc Airport": "OMC",
        "PPS | Puerto Princesa Airport": "PPS",
        "RXS | Roxas Airport": "RXS",
        "EUQ | Evelio Javier Airport": "EUQ",
        "TBH | Tugdan Airport": "TBH",
        "USU | Francisco B. Reyes Airport": "USU",
        "RRM | Marromeu Airport": "RRM",
        "RSE | Rose Bay Seaplane Base": "RSE",
        "NGK | Nogliki Airport": "NGK",
        "GRV | Grozny North Airport": "GRV",
        "KDY | Typliy Klyuch Airport": "KDY",
        "VUS | Velikiy Ustyug Airport": "VUS",
        "RUU | Ruti Airport": "RUU",
        "RVC | River Cess Airport/Heliport": "RVC",
        "LPS | Lopez Island Airport": "LPS",
        "KEH | Kenmore Air Harbor Inc Seaplane Base": "KEH",
        "MJR | Miramar Airport": "MJR",
        "COC | Comodoro Pierrestegui Airport": "COC",
        "GHU | Gualeguaychu Airport": "GHU",
        "PRA | General Urquiza Airport": "PRA",
        "ROS | Rosario Islas Malvinas International Airport": "ROS",
        "SFN | Sauce Viejo Airport": "SFN",
        "AEP | Jorge Newbery Airpark": "AEP",
        "LCM | La Cumbre Airport": "LCM",
        "COR | Ingeniero Ambrosio Taravella Airport": "COR",
        "FDO | San Fernando Airport": "FDO",
        "LPG | La Plata Airport": "LPG",
        "EPA | El Palomar Airport": "EPA",
        "EZE | Ministro Pistarini International Airport": "EZE",
        "RAF | Rafaela Airport": "RAF",
        "HOS | Chos Malal Airport": "HOS",
        "CVH | Caviahue Airport": "CVH",
        "GNR | Dr. Arturo H. Illia Airport": "GNR",
        "RDS | Rincon De Los Sauces Airport": "RDS",
        "APZ | Zapala Airport": "APZ",
        "SAM | Salamo Airport": "SAM",
        "MDZ | El Plumerillo Airport": "MDZ",
        "LGS | Comodoro D.R. Salomón Airport": "LGS",
        "AFA | Suboficial Ay Santiago Germano Airport": "AFA",
        "CTC | Catamarca Airport": "CTC",
        "SDE | Vicecomodoro Angel D. La Paz Aragonés Airport": "SDE",
        "IRJ | Capitan V A Almonacid Airport": "IRJ",
        "RHD | Termas de Río Hondo international Airport": "RHD",
        "TUC | Teniente Benjamin Matienzo Airport": "TUC",
        "UAQ | Domingo Faustino Sarmiento Airport": "UAQ",
        "CRR | Ceres Airport": "CRR",
        "RCU | Area De Material Airport": "RCU",
        "VDR | Villa Dolores Airport": "VDR",
        "VME | Villa Reynolds Airport": "VME",
        "RLO | Valle Del Conlara International Airport": "RLO",
        "LUQ | Brigadier Mayor D Cesar Raul Ojeda Airport": "LUQ",
        "CNQ | Corrientes Airport": "CNQ",
        "RES | Resistencia International Airport": "RES",
        "FMA | Formosa Airport": "FMA",
        "IGR | Cataratas Del Iguazú International Airport": "IGR",
        "AOL | Paso De Los Libres Airport": "AOL",
        "MCS | Monte Caseros Airport": "MCS",
        "PSS | Libertador Gral D Jose De San Martin Airport": "PSS",
        "SAS | Salton Sea Airport": "SAS",
        "SLA | Martin Miguel De Guemes International Airport": "SLA",
        "JUJ | Gobernador Horacio Guzman International Airport": "JUJ",
        "ORA | Orán Airport": "ORA",
        "TTG | General Enrique Mosconi Airport": "TTG",
        "CLX | Clorinda Airport": "CLX",
        "ELO | El Dorado Airport": "ELO",
        "OYA | Goya Airport": "OYA",
        "LLS | Alferez Armando Rodriguez Airport": "LLS",
        "MDX | Mercedes Airport": "MDX",
        "RCQ | Reconquista Airport": "RCQ",
        "UZU | Curuzu Cuatia Airport": "UZU",
        "EHL | El Bolson Airport": "EHL",
        "CRD | General E. Mosconi Airport": "CRD",
        "EMX | El Maiten Airport": "EMX",
        "EQS | Brigadier Antonio Parodi Airport": "EQS",
        "LHS | Las Heras Airport": "LHS",
        "IGB | Ingeniero Jacobacci - Captain H R Bordón Airport": "IGB",
        "OES | Antoine de Saint Exupéry Airport": "OES",
        "MQD | Maquinchao Airport": "MQD",
        "ARR | D. Casimiro Szlapelis Airport": "ARR",
        "SGV | Sierra Grande Airport": "SGV",
        "REL | Almirante Marco Andres Zar Airport": "REL",
        "VDM | Gobernador Castello Airport": "VDM",
        "PMY | El Tehuelche Airport": "PMY",
        "FTE | El Calafate Airport": "FTE",
        "PUD | Puerto Deseado Airport": "PUD",
        "RGA | Hermes Quijada International Airport": "RGA",
        "RGL | Piloto Civil N. Fernández Airport": "RGL",
        "USH | Malvinas Argentinas Airport": "USH",
        "ULA | Capitan D Daniel Vazquez Airport": "ULA",
        "ROY | Rio Mayo Airport": "ROY",
        "PMQ | Perito Moreno Airport": "PMQ",
        "GGS | Gobernador Gregores Airport": "GGS",
        "JSM | Jose De San Martin Airport": "JSM",
        "RYO | 28 de Noviembre Airport": "RYO",
        "RZA | Santa Cruz Airport": "RZA",
        "BHI | Comandante Espora Airport": "BHI",
        "CSZ | Brigadier D.H.E. Ruiz Airport": "CSZ",
        "OVR | Olavarria Airport": "OVR",
        "GPO | General Pico Airport": "GPO",
        "OYO | Tres Arroyos Airport": "OYO",
        "SST | Santa Teresita Airport": "SST",
        "MDQ | Ástor Piazzola International Airport": "MDQ",
        "NQN | Presidente Peron Airport": "NQN",
        "NEC | Necochea Airport": "NEC",
        "PEH | Comodoro Pedro Zanni Airport": "PEH",
        "RSA | Santa Rosa Airport": "RSA",
        "BRC | San Carlos De Bariloche Airport": "BRC",
        "TDL | Héroes De Malvinas Airport": "TDL",
        "VLG | Villa Gesell Airport": "VLG",
        "CUT | Cutral-Co Airport": "CUT",
        "CPC | Aviador C. Campos Airport": "CPC",
        "VIU | Viru Harbour Airstrip": "VIU",
        "CDJ | Conceição do Araguaia Airport": "CDJ",
        "AQA | Araraquara Airport": "AQA",
        "AJU | Aracaju - Santa Maria International Airport": "AJU",
        "AFL | Piloto Osvaldo Marques Dias Airport": "AFL",
        "ARU | Araçatuba Airport": "ARU",
        "AAX | Romeu Zema Airport": "AAX",
        "BEL | Val de Cans/Júlio Cezar Ribeiro International Airport": "BEL",
        "BGX | Comandante Gustavo Kraemer Airport": "BGX",
        "PLU | Pampulha - Carlos Drummond de Andrade Airport": "PLU",
        "BFH | Bacacheri Airport": "BFH",
        "BJP | Estadual Arthur Siqueira Airport": "BJP",
        "BSB | Presidente Juscelino Kubitschek International Airport": "BSB",
        "BAT | Chafei Amsei Airport": "BAT",
        "BAU | Bauru Airport": "BAU",
        "BVB | Atlas Brasil Cantanhede Airport": "BVB",
        "BPG | Barra do Garças Airport": "BPG",
        "BZC | Umberto Modiano Airport": "BZC",
        "CAC | Coronel Adalberto Mendes da Silva Airport": "CAC",
        "CFB | Cabo Frio Airport": "CFB",
        "CFC | Caçador Airport": "CFC",
        "CNF | Tancredo Neves International Airport": "CNF",
        "CGR | Campo Grande Airport": "CGR",
        "XAP | Serafin Enoss Bertaso Airport": "XAP",
        "CLN | Brig. Lysias Augusto Rodrigues Airport": "CLN",
        "CKS | Carajás Airport": "CKS",
        "CCM | Diomício Freitas Airport": "CCM",
        "CLV | Nelson Ribeiro Guimarães Airport": "CLV",
        "CAW | Bartolomeu Lisandro Airport": "CAW",
        "CMG | Corumbá International Airport": "CMG",
        "CWB | Afonso Pena Airport": "CWB",
        "CRQ | Caravelas Airport": "CRQ",
        "CXJ | Hugo Cantergiani Regional Airport": "CXJ",
        "CGB | Marechal Rondon Airport": "CGB",
        "CZS | Cruzeiro do Sul Airport": "CZS",
        "BYO | Bonito Airport": "BYO",
        "PPB | Presidente Prudente Airport": "PPB",
        "MAO | Eduardo Gomes International Airport": "MAO",
        "JCR | Jacareacanga Airport": "JCR",
        "ESI | Espinosa Airport": "ESI",
        "IGU | Cataratas International Airport": "IGU",
        "FLN | Hercílio Luz International Airport": "FLN",
        "FEN | Fernando de Noronha Airport": "FEN",
        "FOR | Pinto Martins International Airport": "FOR",
        "GIG | Rio Galeão – Tom Jobim International Airport": "GIG",
        "GJM | Guajará-Mirim Airport": "GJM",
        "GYN | Santa Genoveva Airport": "GYN",
        "GRU | Guarulhos - Governador André Franco Montoro International Airport": "GRU",
        "GPB | Tancredo Thomas de Faria Airport": "GPB",
        "GVR | Coronel Altino Machado de Oliveira Airport": "GVR",
        "GUJ | Guaratinguetá Airport": "GUJ",
        "ATM | Altamira Interstate Airport": "ATM",
        "ITA | Itacoatiara Airport": "ITA",
        "ITB | Itaituba Airport": "ITB",
        "IOS | Bahia - Jorge Amado Airport": "IOS",
        "IPN | Vale do Aço Regional Airport": "IPN",
        "ITR | Francisco Vilela do Amaral Airport": "ITR",
        "IMP | Prefeito Renato Moreira Airport": "IMP",
        "JJG | Humberto Ghizzo Bortoluzzi Regional Airport": "JJG",
        "JDF | Francisco de Assis Airport": "JDF",
        "JHF | São Paulo Catarina Executive Airport": "JHF",
        "JPA | Presidente Castro Pinto International Airport": "JPA",
        "RRJ | Jacarepaguá - Roberto Marinho Airport": "RRJ",
        "JDO | Orlando Bezerra de Menezes Airport": "JDO",
        "JOI | Lauro Carneiro de Loyola Airport": "JOI",
        "CPV | Presidente João Suassuna Airport": "CPV",
        "VCP | Viracopos International Airport": "VCP",
        "LEC | Coronel Horácio de Mattos Airport": "LEC",
        "LAJ | Lages Airport": "LAJ",
        "LIP | Lins Airport": "LIP",
        "LDB | Governador José Richa Airport": "LDB",
        "LAZ | Bom Jesus da Lapa Airport": "LAZ",
        "MAB | João Correa da Rocha Airport": "MAB",
        "MQH | Minaçu Airport": "MQH",
        "MEU | Monte Dourado - Serra do Areão Airport": "MEU",
        "MEA | Macaé Benedito Lacerda Airport": "MEA",
        "MGF | Regional de Maringá - Sílvio Name Júnior Airport": "MGF",
        "MOC | Mário Ribeiro Airport": "MOC",
        "MII | Frank Miloye Milenkowichi–Marília State Airport": "MII",
        "PLL | Ponta Pelada Airport": "PLL",
        "MCZ | Zumbi dos Palmares Airport": "MCZ",
        "MCP | Alberto Alcolumbre Airport": "MCP",
        "MVF | Dix-Sept Rosado Airport": "MVF",
        "MNX | Manicoré Airport": "MNX",
        "NVT | Ministro Victor Konder International Airport": "NVT",
        "GEL | Santo Ângelo Airport": "GEL",
        "OYK | Oiapoque Airport": "OYK",
        "POA | Salgado Filho International Airport": "POA",
        "PHB | Parnaíba - Prefeito Doutor João Silva Filho International Airport": "PHB",
        "POO | Poços de Caldas - Embaixador Walther Moreira Salles Airport": "POO",
        "PFB | Lauro Kurtz Airport": "PFB",
        "PMW | Brigadeiro Lysias Rodrigues Airport": "PMW",
        "PET | João Simões Lopes Neto International Airport": "PET",
        "PNZ | Senador Nilo Coelho Airport": "PNZ",
        "PNB | Porto Nacional Airport": "PNB",
        "PMG | Ponta Porã Airport": "PMG",
        "BPS | Porto Seguro Airport": "BPS",
        "PVH | Governador Jorge Teixeira de Oliveira Airport": "PVH",
        "RBR | Rio Branco-Plácido de Castro International Airport": "RBR",
        "REC | Recife/Guararapes - Gilberto Freyre International Airport": "REC",
        "SDU | Santos Dumont Airport": "SDU",
        "RAO | Leite Lopes Airport": "RAO",
        "BRB | Barreirinhas National Airport": "BRB",
        "SNZ | Santa Cruz Air Force Base": "SNZ",
        "NAT | São Gonçalo do Amarante - Governador Aluízio Alves International Airport": "NAT",
        "SJK | Professor Urbano Ernesto Stumpf Airport": "SJK",
        "SLZ | Marechal Cunha Machado International Airport": "SLZ",
        "RIA | Santa Maria Airport": "RIA",
        "STM | Maestro Wilson Fonseca Airport": "STM",
        "SMT | Adolino Bedin Regional Airport": "SMT",
        "CGH | Congonhas Airport": "CGH",
        "SJP | Prof. Eribelto Manoel Reino State Airport": "SJP",
        "SSZ | Santos Nero Moura Air Base / Guarujá Airport": "SSZ",
        "SSA | Deputado Luiz Eduardo Magalhães International Airport": "SSA",
        "TMT | Trombetas Airport": "TMT",
        "UNA | Hotel Transamérica Airport": "UNA",
        "TOW | Toledo - Luiz Dalcanale Filho Municipal Airport": "TOW",
        "THE | Senador Petrônio Portela Airport": "THE",
        "TFF | Tefé Airport": "TFF",
        "TRQ | Tarauacá Airport": "TRQ",
        "TEC | Telêmaco Borba Airport": "TEC",
        "TSQ | Torres Airport": "TSQ",
        "TBT | Tabatinga Airport": "TBT",
        "TUR | Tucuruí Airport": "TUR",
        "SJL | São Gabriel da Cachoeira Airport": "SJL",
        "PAV | Paulo Afonso Airport": "PAV",
        "URG | Rubem Berta Airport": "URG",
        "UDI | Ten. Cel. Aviador César Bombonato Airport": "UDI",
        "UBA | Mário de Almeida Franco Airport": "UBA",
        "VAG | Major Brigadeiro Trompowsky Airport": "VAG",
        "BVH | Brigadeiro Camarão Airport": "BVH",
        "VIX | Eurico de Aguiar Salles Airport": "VIX",
        "QPS | Campo Fontenelle": "QPS",
        "IZA | Presidente Itamar Franco Airport": "IZA",
        "ZUD | Pupelde Airport": "ZUD",
        "LOB | San Rafael Airport": "LOB",
        "WAP | Alto Palena Airport": "WAP",
        "ARI | Chacalluta Airport": "ARI",
        "WPA | Cabo 1° Juan Román Airport": "WPA",
        "CPO | Desierto de Atacama Airport": "CPO",
        "BBA | Balmaceda Airport": "BBA",
        "TOQ | Barriles Airport": "TOQ",
        "DPB | Pampa Guanaco Airport": "DPB",
        "CCH | Chile Chico Airport": "CCH",
        "CJC | El Loa Airport": "CJC",
        "YAI | Gral. Bernardo O´Higgins Airport": "YAI",
        "PUQ | Pdte. Carlos Ibañez del Campo Airport": "PUQ",
        "COW | Tambillos Airport": "COW",
        "GXQ | Teniente Vidal Airport": "GXQ",
        "IQQ | Diego Aracena Airport": "IQQ",
        "SCL | Comodoro Arturo Merino Benítez International Airport": "SCL",
        "ESR | Ricardo García Posada Airport": "ESR",
        "FRT | El Avellano Airport": "FRT",
        "ANF | Andrés Sabella Gálvez International Airport": "ANF",
        "WPR | Capitan Fuentes Martinez Airport Airport": "WPR",
        "FFU | Futaleufú Airport": "FFU",
        "LSQ | María Dolores Airport": "LSQ",
        "WPU | Guardiamarina Zañartu Airport": "WPU",
        "LGR | Cochrane Airport": "LGR",
        "CCP | Carriel Sur Airport": "CCP",
        "IPC | Mataveri Airport": "IPC",
        "ZOS | Cañal Bajo Carlos - Hott Siebert Airport": "ZOS",
        "CPP | Coposa Airport": "CPP",
        "VLR | Vallenar Airport": "VLR",
        "ZLR | Municipal de Linares Airport": "ZLR",
        "PNT | Tte. Julio Gallardo Airport": "PNT",
        "OVL | El Tuqui Airport": "OVL",
        "ZPC | Pucón Airport": "ZPC",
        "MHC | Mocopulli Airport": "MHC",
        "PUX | El Mirador Airport": "PUX",
        "ZCO | La Araucanía Airport": "ZCO",
        "CNR | Chañaral Airport": "CNR",
        "VAP | Rodelillo Airport": "VAP",
        "TNM | Teniente Rodolfo Marsh Martin Airport": "TNM",
        "SMB | Franco Bianco Airport": "SMB",
        "LSC | La Florida Airport": "LSC",
        "SSD | Víctor Lafón Airport": "SSD",
        "WCA | Gamboa Airport": "WCA",
        "PZS | Maquehue Airport": "PZS",
        "PMC | El Tepual Airport": "PMC",
        "TLX | Panguilemo Airport": "TLX",
        "WCH | Chaitén Airport": "WCH",
        "ZIC | Victoria Airport": "ZIC",
        "TTC | Las Breas Airport": "TTC",
        "ZAL | Pichoy Airport": "ZAL",
        "KNA | Viña del mar Airport": "KNA",
        "QCJ | Botucatu - Tancredo de Almeida Neves Airport": "QCJ",
        "OLC | Senadora Eunice Micheles Airport": "OLC",
        "SOD | Sorocaba Airport": "SOD",
        "QDC | Dracena Airport": "QDC",
        "SDI | Saidor Airport": "SDI",
        "JLS | Jales Airport": "JLS",
        "QOA | Mococa Airport": "QOA",
        "QGC | Lençóis Paulista Airport": "QGC",
        "QNV | Aeroclube Airport": "QNV",
        "OUS | Ourinhos Airport": "OUS",
        "OIA | Ourilândia do Norte Airport": "OIA",
        "QHB | Piracicaba Airport": "QHB",
        "REZ | Resende Airport": "REZ",
        "QSC | Mário Pereira Lopes–São Carlos Airport": "QSC",
        "UBT | Ubatuba Gastão Madeira State Airport": "UBT",
        "ITP | Itaperuna Airport": "ITP",
        "VOT | Votuporanga Airport": "VOT",
        "QGB | Limeira Airport": "QGB",
        "ATF | Chachoán Airport": "ATF",
        "OCC | Francisco De Orellana Airport": "OCC",
        "CUE | Mariscal Lamar Airport": "CUE",
        "GPS | Seymour Airport": "GPS",
        "GYE | José Joaquín de Olmedo International Airport": "GYE",
        "IBB | General Villamil Airport": "IBB",
        "JIP | Jipijapa Airport": "JIP",
        "LTX | Cotopaxi International Airport": "LTX",
        "MRR | Jose Maria Velasco Ibarra Airport": "MRR",
        "XMS | Coronel E Carvajal Airport": "XMS",
        "MCH | General Manuel Serrano Airport": "MCH",
        "MEC | Eloy Alfaro International Airport": "MEC",
        "LGQ | Nueva Loja Airport": "LGQ",
        "PYO | Putumayo Airport": "PYO",
        "PVO | Reales Tamarindos Airport": "PVO",
        "UIO | Mariscal Sucre International Airport": "UIO",
        "ETR | Santa Rosa International Airport": "ETR",
        "SNC | General Ulpiano Paez Airport": "SNC",
        "SUQ | Sucua Airport": "SUQ",
        "PTZ | Rio Amazonas Airport": "PTZ",
        "SCY | San Cristóbal Airport": "SCY",
        "BHA | Los Perales Airport": "BHA",
        "TSC | Taisha Airport": "TSC",
        "TPN | Tiputini Airport": "TPN",
        "LOH | Camilo Ponce Enriquez Airport": "LOH",
        "ESM | General Rivadeneira Airport": "ESM",
        "TPC | Tarapoa Airport": "TPC",
        "TUA | Teniente Coronel Luis a Mantilla Airport": "TUA",
        "PSY | Port Stanley Airport": "PSY",
        "SFU | Safia Airport": "SFU",
        "ASU | Silvio Pettirossi International Airport": "ASU",
        "AYO | Juan De Ayolas Airport": "AYO",
        "CIO | Teniente Col Carmelo Peralta Airport": "CIO",
        "ENO | Teniente Amin Ayub Gonzalez Airport": "ENO",
        "AGT | Guarani International Airport": "AGT",
        "FLM | Filadelfia Airport": "FLM",
        "ESG | Dr. Luis Maria Argaña International Airport": "ESG",
        "PIL | Carlos Miguel Gimenez Airport": "PIL",
        "PJC | Dr Augusto Roberto Fuster International Airport": "PJC",
        "SIC | San José Island Airport": "SIC",
        "LVR | Municipal Bom Futuro Airport": "LVR",
        "FRC | Tenente Lund Pressoto Airport": "FRC",
        "SIZ | Sissano Airport": "SIZ",
        "JUA | Inácio Luís do Nascimento Airport": "JUA",
        "SJF | Cruz Bay Seaplane Base": "SJF",
        "CFO | Confresa Airport": "CFO",
        "NPR | Novo Progresso Airport": "NPR",
        "AQM | Ariquemes Airport": "AQM",
        "RIG | Rio Grande Regional Airport": "RIG",
        "JTC | Bauru/Arealva–Moussa Nakhal Tobias State Airport": "JTC",
        "ARS | Aragarças Airport": "ARS",
        "ACM | Arica Airport": "ACM",
        "ECO | El Encanto Airport": "ECO",
        "ARO | Arboletes Airport": "ARO",
        "JUO | Jurado Airport": "JUO",
        "SJR | San Juan De Uraba Airport": "SJR",
        "NPU | San Pedro Airport": "NPU",
        "PCC | Puerto Rico Airport": "PCC",
        "SQF | Solano Airport": "SQF",
        "AYI | Yari Airport": "AYI",
        "CUI | Currillo Airport": "CUI",
        "MOY | Monterrey Airport": "MOY",
        "EUO | Paratebueno Airport": "EUO",
        "PRE | Pore Airport": "PRE",
        "SQE | San Luis De Palenque Airport": "SQE",
        "TAU | Tauramena Airport": "TAU",
        "AYC | Ayacucho Airport": "AYC",
        "DZI | Las Flores Airport": "DZI",
        "SJH | San Juan Del César Airport": "SJH",
        "BHF | Bahia Cupica Airport": "BHF",
        "GGL | Gilgal Airport": "GGL",
        "UNC | Unguia Airport": "UNC",
        "AYA | Ayapel Airport": "AYA",
        "NBB | Barranco Minas Airport": "NBB",
        "MND | Medina Airport": "MND",
        "NAD | Macanal Airport": "NAD",
        "GCA | Guacamayas Airport": "GCA",
        "SRO | Santana Ramos Airport": "SRO",
        "BAC | Barranca De Upia Airport": "BAC",
        "CQT | Caquetania Airport": "CQT",
        "ELJ | El Recreo Airport": "ELJ",
        "SOH | Solita Airport": "SOH",
        "URI | Uribe Airport": "URI",
        "ECR | El Charco Airport": "ECR",
        "MQR | Mosquera Airport": "MQR",
        "ISD | Santa Bárbara Airport": "ISD",
        "AZT | Zapatoca Airport": "AZT",
        "HRR | Herrera Airport": "HRR",
        "SQB | Santa Ana Airport": "SQB",
        "LPE | La Primavera Airport": "LPE",
        "ARF | Acaricuara Airport": "ARF",
        "MFB | Monfort Airport": "MFB",
        "MHF | Morichal Airport": "MHF",
        "CSR | Casuarito Airport": "CSR",
        "ACR | Araracuara Airport": "ACR",
        "ACD | Alcides Fernández Airport": "ACD",
        "AFI | Amalfi Airport": "AFI",
        "API | Gomez Nino Apiay Air Base": "API",
        "AXM | El Eden Airport": "AXM",
        "PUU | Tres De Mayo Airport": "PUU",
        "ELB | Las Flores Airport": "ELB",
        "BGA | Palonegro Airport": "BGA",
        "BOG | El Dorado International Airport": "BOG",
        "BAQ | Ernesto Cortissoz International Airport": "BAQ",
        "BSC | José Celestino Mutis Airport": "BSC",
        "BUN | Gerardo Tobar López Airport": "BUN",
        "CPB | Capurganá Airport": "CPB",
        "CUC | Camilo Daza International Airport": "CUC",
        "COG | Mandinga Airport": "COG",
        "CTG | Rafael Nuñez International Airport": "CTG",
        "CCO | Carimagua Airport": "CCO",
        "CLO | Alfonso Bonilla Aragon International Airport": "CLO",
        "CIM | Cimitarra Airport": "CIM",
        "RAV | Cravo Norte Airport": "RAV",
        "TCO | La Florida Airport": "TCO",
        "CUO | Carurú Airport": "CUO",
        "CAQ | Juan H White Airport": "CAQ",
        "CVE | Coveñas Airport": "CVE",
        "CZU | Las Brujas Airport": "CZU",
        "EBG | El Bagre Airport": "EBG",
        "EJA | Yariguíes Airport": "EJA",
        "FLA | Gustavo Artunduaga Paredes Airport": "FLA",
        "FDA | Fundación Airport": "FDA",
        "LGT | La Gaviota Airport": "LGT",
        "GIR | Santiago Vila Airport": "GIR",
        "CRC | Santa Ana Airport": "CRC",
        "GPI | Juan Casiano Airport": "GPI",
        "CPL | Chaparral Airport": "CPL",
        "HTZ | Hato Corozal Airport": "HTZ",
        "IBE | Perales Airport": "IBE",
        "IGO | Chigorodó Jaime Ortiz Betancourt Airport": "IGO",
        "IPI | San Luis Airport": "IPI",
        "APO | Antonio Roldan Betancourt Airport": "APO",
        "LQM | Caucaya Airport": "LQM",
        "MCJ | Jorge Isaac Airport": "MCJ",
        "LPD | La Pedrera Airport": "LPD",
        "LET | Alfredo Vásquez Cobo International Airport": "LET",
        "EOH | Enrique Olaya Herrera Airport": "EOH",
        "MFS | Miraflores Airport": "MFS",
        "MGN | Baracoa Airport": "MGN",
        "MTB | Montelibano Airport": "MTB",
        "MTR | Los Garzones Airport": "MTR",
        "MVP | Fabio Alberto Leon Bentley Airport": "MVP",
        "MZL | La Nubia Airport": "MZL",
        "NCI | Necocli Airport": "NCI",
        "NQU | Reyes Murillo Airport": "NQU",
        "NVA | Benito Salas Airport": "NVA",
        "OCV | Aguas Claras Airport": "OCV",
        "ORC | Orocue Airport": "ORC",
        "PCR | German Olano Airport": "PCR",
        "PDA | Obando Airport": "PDA",
        "PEI | Matecaña International Airport": "PEI",
        "PTX | Pitalito Airport": "PTX",
        "PLT | Plato Airport": "PLT",
        "NAR | Puerto Nare Airport": "NAR",
        "PPN | Guillermo León Valencia Airport": "PPN",
        "PAL | German Olano Air Base": "PAL",
        "PBE | Puerto Berrio Airport": "PBE",
        "PSO | Antonio Narino Airport": "PSO",
        "PVA | El Embrujo Airport": "PVA",
        "PZA | Paz De Ariporo Airport": "PZA",
        "MQU | Mariquita Airport": "MQU",
        "MDE | Jose Maria Córdova International Airport": "MDE",
        "RCH | Almirante Padilla Airport": "RCH",
        "RVE | Los Colonizadores Airport": "RVE",
        "SJE | Jorge E. Gonzalez Torres Airport": "SJE",
        "SSL | Santa Rosalia Airport": "SSL",
        "SMR | Simón Bolívar International Airport": "SMR",
        "SOX | Alberto Lleras Camargo Airport": "SOX",
        "ADZ | Gustavo Rojas Pinilla International Airport": "ADZ",
        "SRS | San Marcos Airport": "SRS",
        "SVI | Eduardo Falla Solano Airport": "SVI",
        "TIB | Tibú Airport": "TIB",
        "TDA | Trinidad Airport": "TDA",
        "TLU | Golfo de Morrosquillo Airport": "TLU",
        "TME | Gustavo Vargas Airport": "TME",
        "TQS | Tres Esquinas Air Base": "TQS",
        "AUC | Santiago Perez Airport": "AUC",
        "UIB | El Caraño Airport": "UIB",
        "ULQ | Heriberto Gíl Martínez Airport": "ULQ",
        "URR | Urrao Airport": "URR",
        "VGZ | Villa Garzón Airport": "VGZ",
        "PYA | Velásquez Airport": "PYA",
        "VUP | Alfonso López Pumarejo Airport": "VUP",
        "VVC | Vanguardia Airport": "VVC",
        "AYG | Yaguara Airport": "AYG",
        "EYP | El Yopal Airport": "EYP",
        "MHW | Monteagudo Airport": "MHW",
        "SRE | Alcantarí Airport": "SRE",
        "APB | Apolo Airport": "APB",
        "ASC | Ascención De Guarayos Airport": "ASC",
        "BJO | Bermejo Airport": "BJO",
        "CAM | Camiri Airport": "CAM",
        "CBB | Jorge Wilsterman International Airport": "CBB",
        "CCA | Chimore Airport": "CCA",
        "CIJ | Capitán Aníbal Arab Airport": "CIJ",
        "CEP | Concepción Airport": "CEP",
        "SRZ | El Trompillo Airport": "SRZ",
        "GYA | Capitán de Av. Emilio Beltrán Airport": "GYA",
        "BVK | Huacaraje Airport": "BVK",
        "SLJ | Solomon Airport": "SLJ",
        "SJS | San José De Chiquitos Airport": "SJS",
        "SJB | San Joaquín Airport": "SJB",
        "SJV | San Javier Airport": "SJV",
        "LPB | El Alto International Airport": "LPB",
        "MGD | Magdalena Airport": "MGD",
        "ORU | Juan Mendoza Airport": "ORU",
        "POI | Capitan Nicolas Rojas Airport": "POI",
        "PUR | Puerto Rico Airport": "PUR",
        "PSZ | Capitán Av. Salvador Ogaya G. airport": "PSZ",
        "SRD | San Ramón Airport": "SRD",
        "RBO | Roboré Airport": "RBO",
        "RIB | Capitán Av. Selin Zeitun Lopez Airport": "RIB",
        "REY | Reyes Airport": "REY",
        "SBL | Santa Ana Del Yacuma Airport": "SBL",
        "SRJ | Capitán Av. German Quiroga G. Airport": "SRJ",
        "SNG | Capitán Av. Juan Cochamanidis S. Airport": "SNG",
        "SNM | San Ignacio de Moxos Airport": "SNM",
        "SRB | Santa Rosa De Yacuma Airport": "SRB",
        "MQK | San Matías Airport": "MQK",
        "TJA | Capitan Oriel Lea Plaza Airport": "TJA",
        "TDD | Teniente Av. Jorge Henrich Arauz Airport": "TDD",
        "UYU | Uyuni Joya Andina Airport": "UYU",
        "VAH | Capitán Av. Vidal Villagomez Toledo Airport": "VAH",
        "VLM | Teniente Coronel Rafael Pabón Airport": "VLM",
        "VVI | Viru Viru International Airport": "VVI",
        "BYC | Yacuiba Airport": "BYC",
        "ABN | Albina Airport": "ABN",
        "TOT | Totness Airport": "TOT",
        "DRJ | Drietabbetje Airport": "DRJ",
        "SMH | Sapmanga Airport": "SMH",
        "PBM | Johan Adolf Pengel International Airport": "PBM",
        "MOJ | Moengo Airstrip": "MOJ",
        "ICK | Nieuw Nickerie - Major Henk Fernandes Airport": "ICK",
        "SMP | Stockholm Airport": "SMP",
        "OEM | Vincent Fayks Airport": "OEM",
        "SMZ | Stoelmanseiland Airport": "SMZ",
        "AGI | Wageningen Airstrip": "AGI",
        "ORG | Zorg en Hoop Airport": "ORG",
        "APY | Alto Parnaíba Airport": "APY",
        "APQ | Arapiraca Airport": "APQ",
        "AMJ | Cirilo Queiróz Airport": "AMJ",
        "ARX | Aracati Dragão do Mar Regional Airport": "ARX",
        "AIF | Marcelo Pires Halzhausen Airport": "AIF",
        "BDC | Barra do Corda Airport": "BDC",
        "BVM | Belmonte Airport": "BVM",
        "BRA | Barreiras Airport": "BRA",
        "BSS | Balsas Airport": "BSS",
        "BMS | Sócrates Mariani Bittencourt Airport": "BMS",
        "BQQ | Barra Airport": "BQQ",
        "CTP | Carutapera Airport": "CTP",
        "CPU | Cururupu Airport": "CPU",
        "QCH | Colatina Airport": "QCH",
        "RDC | Redenção Airport": "RDC",
        "LEP | Leopoldina Airport": "LEP",
        "DTI | Diamantina Airport": "DTI",
        "DIQ | Brigadeiro Cabral Airport": "DIQ",
        "JPE | Nagib Demachki Airport": "JPE",
        "CNV | Sócrates Rezende Airport": "CNV",
        "SXX | São Félix do Xingu Airport": "SXX",
        "GUZ | Guarapari Airport": "GUZ",
        "GDP | Guadalupe Airport": "GDP",
        "GNM | Guanambi Airport": "GNM",
        "QGP | Garanhuns Airport": "QGP",
        "ITN | Tertuliano Guedes de Pinho Airport": "ITN",
        "IRE | Irecê Airport": "IRE",
        "QIG | Iguatu Airport": "QIG",
        "QIT | Itapetinga Airport": "QIT",
        "IPU | Ipiaú Airport": "IPU",
        "JCM | Jacobina Airport": "JCM",
        "FEC | João Durval Carneiro Airport": "FEC",
        "JEQ | Jequié Airport": "JEQ",
        "JNA | Januária Airport": "JNA",
        "JDR | Prefeito Octávio de Almeida Neves Airport": "JDR",
        "CMP | Santana do Araguaia Airport": "CMP",
        "CDI | Cachoeiro do Itapemirim - Raimundo de Andrade Airport": "CDI",
        "QCP | Currais Novos Airport": "QCP",
        "SSO | São Lourenço Airport": "SSO",
        "MTE | Monte Alegre Airport": "MTE",
        "MVS | Mucuri Airport": "MVS",
        "SBJ | São Mateus - Ernesto Bonomo Airport": "SBJ",
        "PTQ | Porto de Moz Airport": "PTQ",
        "NNU | Nanuque Airport": "NNU",
        "PSW | Municipal José Figueiredo Airport": "PSW",
        "FEJ | Novo Aeródromo de Feijó": "FEJ",
        "ORX | Oriximiná Airport": "ORX",
        "PCS | Picos Airport": "PCS",
        "POJ | Patos de Minas Airport": "POJ",
        "PIV | Pirapora Airport": "PIV",
        "SNQ | San Quintín Military Airstrip": "SNQ",
        "FLB | Cangapara Airport": "FLB",
        "PDF | Prado Airport": "PDF",
        "CAU | Caruaru Airport": "CAU",
        "SFK | Soure Airport": "SFK",
        "TXF | 9 de Maio - Teixeira de Freitas Airport": "TXF",
        "OBI | Óbidos Airport": "OBI",
        "TFL | Juscelino Kubitscheck Airport": "TFL",
        "JPO | Aeroporto Brigadeiro Firmino Ayres": "JPO",
        "VAL | Valença Airport": "VAL",
        "QID | Mélio Viana Airport": "QID",
        "BVS | Breves Airport": "BVS",
        "CMC | Camocim - Pinto Martins Airport": "CMC",
        "GGF | Almeirim Airport": "GGF",
        "PHI | Pinheiro Airport": "PHI",
        "GMS | Antônio Guerreiro Airport": "GMS",
        "PPY | Pouso Alegre Airport": "PPY",
        "ITE | Ituberá Airport": "ITE",
        "BXX | Borama Airport": "BXX",
        "SOA | Sóc Trăng Airport": "SOA",
        "CAY | Cayenne – Félix Eboué Airport": "CAY",
        "GSI | Grand-Santi Airport": "GSI",
        "MPY | Maripasoula Airport": "MPY",
        "OYP | Saint-Georges-de-l'Oyapock Airport": "OYP",
        "LDX | Saint-Laurent-du-Maroni Airport": "LDX",
        "REI | Régina Airport": "REI",
        "XAU | Saül Airport": "XAU",
        "SOR | Al Thaurah Airport": "SOR",
        "APE | San Juan Aposento Airport": "APE",
        "ALD | Alerta Airport": "ALD",
        "AOP | Alferez FAP Alfredo Vladimir Sara Bauer Airport": "AOP",
        "AYX | Teniente General Gerardo Pérez Pinedo Airport": "AYX",
        "MBP | Moyobamba Airport": "MBP",
        "BLP | Huallaga Airport": "BLP",
        "IBP | Iberia Airport": "IBP",
        "PCL | Cap FAP David Abenzur Rengifo International Airport": "PCL",
        "TDP | Trompeteros Airport": "TDP",
        "CHM | Teniente FAP Jaime A De Montreuil Morales Airport": "CHM",
        "TGI | Tingo Maria Airport": "TGI",
        "CIX | Capitan FAP Jose A Quinones Gonzales International Airport": "CIX",
        "AYP | Coronel FAP Alfredo Mendivil Duarte Airport": "AYP",
        "ANS | Andahuaylas Airport": "ANS",
        "ATA | Comandante FAP German Arias Graziani Airport": "ATA",
        "UMI | Quince Air Base": "UMI",
        "LIM | Jorge Chávez International Airport": "LIM",
        "UCZ | Uchiza Airport": "UCZ",
        "RIJ | Juan Simons Vela Airport": "RIJ",
        "JAE | Shumba Airport": "JAE",
        "JJI | Juanjui Airport": "JJI",
        "JAU | Francisco Carle Airport": "JAU",
        "JUL | Inca Manco Capac International Airport": "JUL",
        "SJA | San Juan de Marcona Airport": "SJA",
        "CJA | Mayor General FAP Armando Revoredo Iglesias Airport": "CJA",
        "RIM | San Nicolas Airport": "RIM",
        "ILQ | Ilo Airport": "ILQ",
        "TBP | Capitan FAP Pedro Canga Rodriguez Airport": "TBP",
        "MZA | Mayor PNP Nancy Flores Paucar Airport": "MZA",
        "SMG | Santa Maria Airport": "SMG",
        "YMS | Moises Benzaquen Rengifo Airport": "YMS",
        "HUU | Alferez Fap David Figueroa Fernandini Airport": "HUU",
        "SQU | Saposoa Airport": "SQU",
        "CHH | Chachapoyas Airport": "CHH",
        "IQT | Coronel FAP Francisco Secada Vignetta International Airport": "IQT",
        "AQP | Rodríguez Ballón International Airport": "AQP",
        "TRU | Capitan FAP Carlos Martinez De Pinillos International Airport": "TRU",
        "PIO | Capitán FAP Renán Elías Olivera International Airport": "PIO",
        "TPP | Cadete FAP Guillermo Del Castillo Paredes Airport": "TPP",
        "SYC | Shiringayoc Airport": "SYC",
        "TCQ | Coronel FAP Carlos Ciriani Santa Rosa International Airport": "TCQ",
        "PEM | Padre Aldamiz International Airport": "PEM",
        "PIU | Capitán FAP Guillermo Concha Iberico International Airport": "PIU",
        "TYL | Capitan Montes Airport": "TYL",
        "NZC | Maria Reiche Neuman Airport": "NZC",
        "CUZ | Alejandro Velasco Astete International Airport": "CUZ",
        "SQD | Shangrao Sanqingshan Airport": "SQD",
        "SQJ | Shaxian Airport": "SQJ",
        "SQT | China Strait Airstrip": "SQT",
        "AAJ | Cayana Airstrip": "AAJ",
        "KCB | Tepoe Airstrip": "KCB",
        "SRL | Palo Verde Airport": "SRL",
        "SRM | Sandringham Airport": "SRM",
        "SRV | Stony River 2 Airport": "SRV",
        "CZB | Carlos Ruhl Airport": "CZB",
        "APU | Captain João Busse Airport": "APU",
        "BGV | Aeroclube de Bento Gonçalves Airport": "BGV",
        "BNU | Blumenau Airport": "BNU",
        "CCI | Concórdia Airport": "CCI",
        "CSS | Cassilândia Airport": "CSS",
        "QCN | Canela Airport": "QCN",
        "CKO | Cornélio Procópio Airport": "CKO",
        "DOU | Dourados Airport": "DOU",
        "ERM | Erechim Airport": "ERM",
        "FBE | Francisco Beltrão Airport": "FBE",
        "QGA | Guaíra Airport": "QGA",
        "HRZ | Walter Bündchen Airport": "HRZ",
        "IJU | Ijuí Airport": "IJU",
        "ITQ | Itaqui Airport": "ITQ",
        "JCB | Santa Terezinha Airport": "JCB",
        "CBW | Campo Mourão Airport": "CBW",
        "QDB | Cachoeira do Sul Airport": "QDB",
        "QCR | Curitibanos Airport": "QCR",
        "OAL | Cacoal Airport": "OAL",
        "LOI | Helmuth Baungarten Airport": "LOI",
        "ALQ | Alegrete Novo Airport": "ALQ",
        "QGF | Montenegro Airport": "QGF",
        "QHV | Novo Hamburgo Airport": "QHV",
        "SQX | São Miguel do Oeste Airport": "SQX",
        "APX | Arapongas Airport": "APX",
        "PTO | Juvenal Loureiro Cardoso Airport": "PTO",
        "PNG | Paranaguá Airport": "PNG",
        "PVI | Paranavaí Airport": "PVI",
        "PBB | Paranaíba Airport": "PBB",
        "QAC | Castro Airport": "QAC",
        "SQY | São Lourenço do Sul Airport": "SQY",
        "SSS | Siassi Airport": "SSS",
        "JBS | São Borja Airport": "JBS",
        "CSU | Santa Cruz do Sul Airport": "CSU",
        "TJL | Plínio Alarcom Airport": "TJL",
        "UMU | Umuarama Airport": "UMU",
        "VIA | Videira Airport": "VIA",
        "CTQ | Santa Vitória do Palmar Airport": "CTQ",
        "AXE | Xanxerê Airport": "AXE",
        "AAG | Avelino Vieira Airport": "AAG",
        "SRA | Santa Rosa Airport": "SRA",
        "PGZ | Ponta Grossa Airport - Comandante Antonio Amilton Beraldo": "PGZ",
        "ATI | Artigas International Airport": "ATI",
        "BUV | Bella Union Airport": "BUV",
        "CYR | Laguna de Los Patos International Airport": "CYR",
        "DZO | Santa Bernardina International Airport": "DZO",
        "PDP | Capitan Corbeta CA Curbelo International Airport": "PDP",
        "MLZ | Cerro Largo International Airport": "MLZ",
        "MVD | Carrasco International /General C L Berisso Airport": "MVD",
        "PDU | Tydeo Larre Borges Airport": "PDU",
        "RVY | Presidente General Don Oscar D. Gestido International Airport": "RVY",
        "STY | Nueva Hesperides International Airport": "STY",
        "TAW | Tacuarembo Airport": "TAW",
        "TYT | Treinta y Tres Airport": "TYT",
        "VCH | Vichadero Airport": "VCH",
        "AGV | Oswaldo Guevara Mujica Airport": "AGV",
        "AAO | Anaco Airport": "AAO",
        "LPJ | Armando Schwarck Airport": "LPJ",
        "BLA | General José Antonio Anzoategui International Airport": "BLA",
        "BNS | Barinas Airport": "BNS",
        "BRM | Barquisimeto International Airport": "BRM",
        "MYC | Escuela Mariscal Sucre Airport": "MYC",
        "CBL | Aeropuerto General Tomas de Heres. Ciudad Bolivar": "CBL",
        "CXA | Caicara del Orinoco Airport": "CXA",
        "ZRZ | Zaraza Airport": "ZRZ",
        "CUV | Casigua El Cubo Airport": "CUV",
        "CLZ | Calabozo Airport": "CLZ",
        "CAJ | Canaima Airport": "CAJ",
        "VCR | Carora Airport": "VCR",
        "CUP | General Francisco Bermúdez Airport": "CUP",
        "CZE | José Leonardo Chirinos Airport": "CZE",
        "CUM | Cumaná (Antonio José de Sucre) Airport": "CUM",
        "PPZ | Puerto Paez Airport": "PPZ",
        "EOR | El Dorado Airport": "EOR",
        "EOZ | Elorza Airport": "EOZ",
        "GDO | Guasdalito Airport": "GDO",
        "GUI | Guiria Airport": "GUI",
        "GUQ | Guanare Airport": "GUQ",
        "HGE | Higuerote Airport": "HGE",
        "ICA | Icabarú Airport": "ICA",
        "ICC | Andrés Miguel Salazar Marcano Airport": "ICC",
        "LSP | Josefa Camejo International Airport": "LSP",
        "KAV | Kavanayen Airport": "KAV",
        "LFR | La Fria Airport": "LFR",
        "MAR | La Chinita International Airport": "MAR",
        "MRD | Alberto Carnevalli Airport": "MRD",
        "PMV | Del Caribe Santiago Mariño International Airport": "PMV",
        "CCS | Simón Bolívar International Airport": "CCS",
        "MUN | Maturín Airport": "MUN",
        "CBS | Oro Negro Airport": "CBS",
        "PYH | Cacique Aramare Airport": "PYH",
        "PBL | General Bartolome Salom International Airport": "PBL",
        "PDZ | Pedernales Airport": "PDZ",
        "PPH | Perai-Tepuy Airport": "PPH",
        "SCI | Paramillo Airport": "SCI",
        "PZO | General Manuel Carlos Piar International Airport": "PZO",
        "PTM | Palmarito Airport": "PTM",
        "LRV | Los Roques Airport": "LRV",
        "SVS | Stevens Village Airport": "SVS",
        "SVZ | San Antonio Del Tachira Airport": "SVZ",
        "SBB | Santa Bárbara de Barinas Airport": "SBB",
        "SNV | Santa Elena de Uairen Airport": "SNV",
        "STD | Mayor Buenaventura Vivas International Airport": "STD",
        "SNF | Sub Teniente Nestor Arias Airport": "SNF",
        "SFD | San Fernando De Apure Airport": "SFD",
        "SOM | San Tomé Airport": "SOM",
        "STB | Santa Bárbara del Zulia Airport": "STB",
        "TUV | Tucupita Airport": "TUV",
        "TMO | Tumeremo Airport": "TMO",
        "URM | Uriman Airport": "URM",
        "VLN | Arturo Michelena International Airport": "VLN",
        "VIG | Juan Pablo Pérez Alfonso Airport": "VIG",
        "VLV | Dr. Antonio Nicolás Briceño Airport": "VLV",
        "VDP | Valle de La Pascua Airport": "VDP",
        "BAZ | Barcelos Airport": "BAZ",
        "LCB | Pontes e Lacerda Airport": "LCB",
        "RBB | Borba Airport": "RBB",
        "CAF | Carauari Airport": "CAF",
        "CQS | Costa Marques Airport": "CQS",
        "DMT | Diamantino Airport": "DMT",
        "DNO | Dianópolis Airport": "DNO",
        "SWE | Siwea Airport": "SWE",
        "ERN | Eirunepé Airport": "ERN",
        "CQA | Canarana Airport": "CQA",
        "SXO | São Félix do Araguaia Airport": "SXO",
        "SWG | Satwag Airport": "SWG",
        "GRP | Gurupi Airport": "GRP",
        "AUX | Araguaína Airport": "AUX",
        "GGB | Olhos D`água Airport": "GGB",
        "HUW | Humaitá Airport": "HUW",
        "IPG | Ipiranga Airport": "IPG",
        "IDO | Santa Izabel do Morro Airport": "IDO",
        "JPR | Ji-Paraná Airport": "JPR",
        "JIA | Juína Airport": "JIA",
        "JRN | Juruena Airport": "JRN",
        "JTI | Jataí Airport": "JTI",
        "CCX | Cáceres Airport": "CCX",
        "CIZ | Coari Airport": "CIZ",
        "TLZ | Catalão Airport": "TLZ",
        "LBR | Lábrea Airport": "LBR",
        "RVD | General Leite de Castro Airport": "RVD",
        "MBZ | Maués Airport": "MBZ",
        "NVP | Novo Aripuanã Airport": "NVP",
        "BCR | Novo Campo Airport": "BCR",
        "NQL | Niquelândia Airport": "NQL",
        "APS | Anápolis Airport": "APS",
        "FBA | Fonte Boa Airport": "FBA",
        "PBV | Porto dos Gaúchos Airport": "PBV",
        "PIN | Parintins Airport": "PIN",
        "PBQ | Pimenta Bueno Airport": "PBQ",
        "PBX | Fazenda Piraguassu Airport": "PBX",
        "SWR | Silur Airport": "SWR",
        "AAI | Arraias Airport": "AAI",
        "ROO | Maestro Marinho Franco Airport": "ROO",
        "AIR | Aripuanã Airport": "AIR",
        "OPS | Presidente João Batista Figueiredo Airport": "OPS",
        "STZ | Santa Terezinha Airport": "STZ",
        "IRZ | Tapuruquara Airport": "IRZ",
        "TGQ | Tangará da Serra Airport": "TGQ",
        "AZL | Fazenda Tucunaré Airport": "AZL",
        "QHN | Taguatinga Airport": "QHN",
        "SQM | São Miguel do Araguaia Airport": "SQM",
        "MTG | Vila Bela da Santíssima Trindade Airport": "MTG",
        "VLP | Vila Rica Airport": "VLP",
        "MBK | Regional Orlando Villas Boas Airport": "MBK",
        "NOK | Xavantina Airport": "NOK",
        "IUP | Apuí Airport": "IUP",
        "SXH | Sehulea Airport": "SXH",
        "SXP | Nunam Iqua Airport": "SXP",
        "AHL | Aishalton Airport": "AHL",
        "NAI | Annai Airport": "NAI",
        "SYB | Seal Bay Seaplane Base": "SYB",
        "BMJ | Baramita Airport": "BMJ",
        "GFO | Bartica A Airport": "GFO",
        "GEO | Cheddi Jagan International Airport": "GEO",
        "SYF | Silva Bay Seaplane Base": "SYF",
        "OGL | Eugene F. Correira International Airport": "OGL",
        "IMB | Imbaimadai Airport": "IMB",
        "KAR | Kamarang Airport": "KAR",
        "KRM | Karanambo Airport": "KRM",
        "KRG | Karasabai Airport": "KRG",
        "KTO | Kato Airport": "KTO",
        "KKG | Konawaruk Airport": "KKG",
        "LUB | Lumid Pau Airport": "LUB",
        "LTM | Lethem Airport": "LTM",
        "USI | Mabaruma Airport": "USI",
        "MHA | Mahdia Airport": "MHA",
        "VEG | Maikwak Airport": "VEG",
        "MYM | Monkey Mountain Airport": "MYM",
        "MWJ | Matthews Ridge Airport": "MWJ",
        "SYN | Stanton Airfield": "SYN",
        "QSX | New Amsterdam Airport": "QSX",
        "ORJ | Orinduik Airport": "ORJ",
        "PMT | Paramakatoi Airport": "PMT",
        "PRR | Paruima Airport": "PRR",
        "SDC | Sand Creek Airport": "SDC",
        "SKM | Skeldon Airport": "SKM",
        "SZN | Santa Cruz Island Airport": "SZN",
        "SZP | Santa Paula Airport": "SZP",
        "TAO | Qingdao Jiaodong International Airport": "TAO",
        "ANU | V.C. Bird International Airport": "ANU",
        "BBQ | Codrington Airport": "BBQ",
        "TBE | Timbunke Airport": "TBE",
        "BGI | Grantley Adams International Airport": "BGI",
        "TBQ | Tarabo Airport": "TBQ",
        "TBV | Tabal Airstrip": "TBV",
        "TCK | Tinboli Airport": "TCK",
        "TCT | Takotna Airport": "TCT",
        "TDB | Tetebedi Airport": "TDB",
        "DCF | Canefield Airport": "DCF",
        "DOM | Douglas-Charles Airport": "DOM",
        "TDS | Sasereme Airport": "TDS",
        "TEO | Terapo Airport": "TEO",
        "TFB | Tifalmin Airport": "TFB",
        "DSD | La Désirade Airport": "DSD",
        "BBR | Basse-Terre Baillif Airport": "BBR",
        "SFC | St-François Airport": "SFC",
        "FDF | Martinique Aimé Césaire International Airport": "FDF",
        "SFG | Grand Case-Espérance Airport": "SFG",
        "SBH | Saint Barthélemy - Rémy de Haenen Airport": "SBH",
        "GBJ | Les Bases Airport": "GBJ",
        "PTP | Pointe-à-Pitre Le Raizet International  Airport": "PTP",
        "LSS | Terre-de-Haut Airport": "LSS",
        "TGL | Tagula Airport": "TGL",
        "GND | Point Salines International Airport": "GND",
        "CRU | Lauriston Airport": "CRU",
        "THW | Trincomalee Harbor Waterdrome": "THW",
        "STT | Cyril E. King Airport": "STT",
        "STX | Henry E Rohlsen Airport": "STX",
        "ARE | Antonio Nery Juarbe Pol Airport": "ARE",
        "BQN | Rafael Hernández International Airport": "BQN",
        "TJC | Ticantiki Airport": "TJC",
        "CPX | Benjamin Rivera Noriega Airport": "CPX",
        "SIG | Fernando Luis Ribas Dominicci Airport": "SIG",
        "MAZ | Eugenio Maria De Hostos Airport": "MAZ",
        "PSE | Mercedita Airport": "PSE",
        "NRR | José Aponte de la Torre Airport": "NRR",
        "SJU | Luis Munoz Marin International Airport": "SJU",
        "VQS | Antonio Rivera Rodriguez Airport": "VQS",
        "TKE | Tenakee Seaplane Base": "TKE",
        "TKL | Taku Lodge Seaplane Base": "TKL",
        "SKB | Robert L. Bradshaw International Airport": "SKB",
        "NEV | Vance W. Amory International Airport": "NEV",
        "TLP | Tumolbil Airport": "TLP",
        "SLU | George F. L. Charles Airport": "SLU",
        "UVF | Hewanorra International Airport": "UVF",
        "TLT | Tuluksak Airport": "TLT",
        "KEA | Kerki Airport": "KEA",
        "NBE | Enfidha - Hammamet International Airport": "NBE",
        "AUA | Queen Beatrix International Airport": "AUA",
        "BON | Flamingo International Airport": "BON",
        "CUR | Hato International Airport": "CUR",
        "EUX | F. D. Roosevelt Airport": "EUX",
        "SXM | Princess Juliana International Airport": "SXM",
        "SAB | Juancho E. Yrausquin Airport": "SAB",
        "TNW | Jumandy Airport": "TNW",
        "TOK | Torokina Airport": "TOK",
        "TOV | Tortola West End Seaplane Base": "TOV",
        "PTA | Port Alsworth Airport": "PTA",
        "TPT | Tapeta Airport": "TPT",
        "AXA | Clayton J Lloyd International Airport": "AXA",
        "BGG | Bingöl Çeltiksuyu Airport": "BGG",
        "OGU | Ordu–Giresun Airport": "OGU",
        "IGD | Iğdır Airport": "IGD",
        "MNI | John A. Osborne Airport": "MNI",
        "TSG | Tanacross Airport": "TSG",
        "TSI | Tsile Tsile Airport": "TSI",
        "TAB | Tobago-Crown Point Airport": "TAB",
        "POS | Piarco International Airport": "POS",
        "TTW | Tissa Tank Waterdrome": "TTW",
        "TUE | Tupile Airport": "TUE",
        "TUJ | Tum Airport": "TUJ",
        "NGD | Captain Auguste George Airport": "NGD",
        "EIS | Terrance B. Lettsome International Airport": "EIS",
        "VIJ | Virgin Gorda Airport": "VIJ",
        "SVD | Argyle International Airport": "SVD",
        "BQU | J F Mitchell Airport": "BQU",
        "CIW | Canouan Airport": "CIW",
        "MQS | Mustique Airport": "MQS",
        "UNI | Union Island International Airport": "UNI",
        "DSX | Dongsha Island (Pratas Island) Airport": "DSX",
        "CMJ | Qimei Airport": "CMJ",
        "TWC | Tumxuk Tangwangcheng Airport": "TWC",
        "TWH | Two Harbors Amphibious Terminal": "TWH",
        "BDA | L.F. Wade International International Airport": "BDA",
        "TYE | Tyonek Airport": "TYE",
        "GIT | Mchauru Airport": "GIT",
        "LUY | Lushoto Airport": "LUY",
        "TZO | Tsimiroro Airport": "TZO",
        "DBS | Dubois Municipal Airport": "DBS",
        "MXR | Myrhorod Air Base": "MXR",
        "KHU | Kakhnovka Airfield": "KHU",
        "ALA | Almaty Airport": "ALA",
        "BXH | Balkhash Airport": "BXH",
        "BXJ | Boralday Airport": "BXJ",
        "NQZ | Nursultan Nazarbayev International Airport": "NQZ",
        "KOV | Kokshetau Airport": "KOV",
        "PPK | Petropavl Airport": "PPK",
        "DMB | Taraz Airport": "DMB",
        "UAE | Mount Aue Airport": "UAE",
        "IKU | Issyk-Kul International Airport": "IKU",
        "FRU | Manas International Airport": "FRU",
        "OSS | Osh Airport": "OSS",
        "CIT | Shymkent Airport": "CIT",
        "HSA | Hazrat Sultan International Airport": "HSA",
        "DZN | Zhezkazgan Airport": "DZN",
        "KGF | Sary-Arka Airport": "KGF",
        "KZO | Kyzylorda Airport": "KZO",
        "URA | Uralsk Airport": "URA",
        "EKB | Ekibastuz Airport": "EKB",
        "UKK | Ust-Kamenogorsk Airport": "UKK",
        "PWQ | Pavlodar Airport": "PWQ",
        "PLX | Semipalatinsk Airport": "PLX",
        "SZI | Zaysan Airport": "SZI",
        "SCO | Aktau Airport": "SCO",
        "GUW | Atyrau Airport": "GUW",
        "AKX | Aktobe Airport": "AKX",
        "AYK | Arkalyk North Airport": "AYK",
        "KSN | Kostanay West Airport": "KSN",
        "GYD | Heydar Aliyev International Airport": "GYD",
        "KVD | Ganja International Airport": "KVD",
        "LLK | Lankaran International Airport": "LLK",
        "NAJ | Nakhchivan Airport": "NAJ",
        "GBB | Gabala International Airport": "GBB",
        "ZTU | Zaqatala International Airport": "ZTU",
        "YLV | Yevlakh Airport": "YLV",
        "UBI | Buin Airport": "UBI",
        "LWN | Shirak International Airport": "LWN",
        "EVN | Zvartnots International Airport": "EVN",
        "BQJ | Batagay Airport": "BQJ",
        "SUK | Sakkyryr Airport": "SUK",
        "UKG | Ust-Kuyga Airport": "UKG",
        "TLK | Talakan Airport": "TLK",
        "ADH | Aldan Airport": "ADH",
        "YKS | Yakutsk Airport": "YKS",
        "NER | Chulman Airport": "NER",
        "MQJ | Moma Airport": "MQJ",
        "GYG | Magan Airport": "GYG",
        "OLZ | Olyokminsk Airport": "OLZ",
        "USR | Ust-Nera Airport": "USR",
        "UMS | Ust-Maya Airport": "UMS",
        "VHV | Verkhnevilyuisk Airport": "VHV",
        "NYR | Nyurba Airport": "NYR",
        "SUY | Suntar Airport": "SUY",
        "VYI | Vilyuisk Airport": "VYI",
        "ULK | Lensk Airport": "ULK",
        "ONK | Olenyok Airport": "ONK",
        "PYJ | Polyarny Airport": "PYJ",
        "MJZ | Mirny Airport": "MJZ",
        "SYS | Saskylakh Airport": "SYS",
        "BGN | Belaya Gora Airport": "BGN",
        "SEK | Srednekolymsk Airport": "SEK",
        "CKH | Chokurdakh Airport": "CKH",
        "CYX | Cherskiy Airport": "CYX",
        "IKS | Tiksi Airport": "IKS",
        "ZKP | Zyryanka Airport": "ZKP",
        "KHX | Savannah Airstrip": "KHX",
        "OYG | Moyo Airport": "OYG",
        "UGB | Ugashik Bay Airport": "UGB",
        "KUT | David the Builder Kutaisi International Airport": "KUT",
        "BUS | Batumi International Airport": "BUS",
        "SUI | Sukhumi Babushara /  Vladislav Ardzinba International Airport": "SUI",
        "TBS | Tbilisi International Airport": "TBS",
        "BQS | Ignatyevo Airport": "BQS",
        "GDG | Magdagachi Airport": "GDG",
        "TYD | Tynda Airport": "TYD",
        "KHV | Khabarovsk Novy Airport": "KHV",
        "KXK | Komsomolsk-on-Amur Airport": "KXK",
        "GVN | Sovetskaya Gavan (Maygatka) Airport": "GVN",
        "DYR | Ugolny Yuri Ryktheu Airport": "DYR",
        "PVS | Provideniya Bay Airport": "PVS",
        "KPW | Keperveem Airport": "KPW",
        "GDX | Sokol Airport": "GDX",
        "KVM | Markovo Airport": "KVM",
        "PWE | Pevek Airport": "PWE",
        "SWV | Severo-Evensk Airport": "SWV",
        "BQG | Bogorodskoye Airport": "BQG",
        "NLI | Nikolayevsk-na-Amure Airport": "NLI",
        "OHO | Okhotsk Airport": "OHO",
        "PKC | Yelizovo Airport": "PKC",
        "BVV | Burevestnik Airport": "BVV",
        "OHH | Okha Airport": "OHH",
        "ITU | Iturup Airport": "ITU",
        "EKS | Shakhtyorsk Airport": "EKS",
        "DEE | Yuzhno-Kurilsk Mendeleyevo Airport": "DEE",
        "ZZO | Zonalnoye Airport": "ZZO",
        "UUS | Yuzhno-Sakhalinsk Airport": "UUS",
        "AEM | Amgu Airport": "AEM",
        "ETL | Svetlaya Airport": "ETL",
        "EDN | Yedinka Airport": "EDN",
        "KVR | Kavalerovo Airport": "KVR",
        "TLY | Plastun Airport": "TLY",
        "NEI | Terney Airport": "NEI",
        "VVO | Vladivostok International Airport": "VVO",
        "HTA | Chita-Kadala International Airport": "HTA",
        "BTK | Bratsk Airport": "BTK",
        "UIK | Ust-Ilimsk Airport": "UIK",
        "IKT | Irkutsk International Airport": "IKT",
        "ODO | Bodaybo Airport": "ODO",
        "ERG | Yerbogachen Airport": "ERG",
        "KCK | Kirensk Airport": "KCK",
        "RZH | Preobrazheniye Airport": "RZH",
        "UKX | Ust-Kut Airport": "UKX",
        "UUD | Baikal International Airport": "UUD",
        "UJE | Ujae Atoll Airport": "UJE",
        "UJN | Uljin Airport": "UJN",
        "KBP | Boryspil International Airport": "KBP",
        "KRQ | Kramatorsk Airport": "KRQ",
        "MPW | Mariupol International Airport": "MPW",
        "SEV | Sievierodonetsk Airport": "SEV",
        "VSG | Luhansk International Airport": "VSG",
        "DNK | Dnipropetrovsk International Airport": "DNK",
        "OZH | Zaporizhzhia International Airport": "OZH",
        "KWG | Kryvyi Rih International Airport": "KWG",
        "UKS | Sevastopol International Airport / Belbek Air Base": "UKS",
        "SIP | Simferopol International Airport": "SIP",
        "KHC | Kerch Airport": "KHC",
        "UKH | Mukhaizna Airport": "UKH",
        "HRK | Kharkiv International Airport": "HRK",
        "PLV | Suprunovka Airport": "PLV",
        "UMY | Sumy Airport": "UMY",
        "CKC | Cherkasy International Airport": "CKC",
        "KGO | Kirovograd Airport": "KGO",
        "IEV | Kiev Zhuliany International Airport": "IEV",
        "GML | Gostomel Airport": "GML",
        "ZTR | Zhytomyr Airport": "ZTR",
        "UCK | Lutsk Airport": "UCK",
        "HMJ | Khmelnytskyi Airport": "HMJ",
        "IFO | Ivano-Frankivsk International Airport": "IFO",
        "LWO | Lviv International Airport": "LWO",
        "CWC | Chernivtsi International Airport": "CWC",
        "RWN | Rivne International Airport": "RWN",
        "UDJ | Uzhhorod International Airport": "UDJ",
        "KHE | Kherson International Airport": "KHE",
        "NLV | Mykolaiv International Airport": "NLV",
        "ODS | Odessa International Airport": "ODS",
        "VIN | Vinnytsia/Gavyryshivka Airport": "VIN",
        "ARH | Talagi Airport": "ARH",
        "LDG | Leshukonskoye Airport": "LDG",
        "NNM | Naryan Mar Airport": "NNM",
        "CSH | Solovki Airport": "CSH",
        "CEE | Cherepovets Airport": "CEE",
        "AMV | Amderma Airport": "AMV",
        "VRI | Varandey Airport": "VRI",
        "ULH | Majeed Bin Abdulaziz Airport": "ULH",
        "KSZ | Kotlas Airport": "KSZ",
        "LED | Pulkovo Airport": "LED",
        "KVK | Kirovsk-Apatity Airport": "KVK",
        "MMK | Murmansk Airport": "MMK",
        "VLU | Velikiye Luki Airport": "VLU",
        "PKV | Pskov Airport": "PKV",
        "PES | Petrozavodsk Airport": "PES",
        "VGD | Vologda Airport": "VGD",
        "BQT | Brest Airport": "BQT",
        "GME | Gomel Airport": "GME",
        "VTB | Vitebsk Vostochny Airport": "VTB",
        "KGD | Khrabrovo Airport": "KGD",
        "GNA | Hrodna Airport": "GNA",
        "MSQ | Minsk National Airport": "MSQ",
        "MVQ | Mogilev Airport": "MVQ",
        "ABA | Abakan International Airport": "ABA",
        "BAX | Barnaul Airport": "BAX",
        "RGK | Gorno-Altaysk Airport": "RGK",
        "KEJ | Kemerovo Airport": "KEJ",
        "EIE | Yeniseysk Airport": "EIE",
        "TGP | Podkamennaya Tunguska Airport": "TGP",
        "KJA | Krasnoyarsk International Airport": "KJA",
        "ACS | Achinsk Airport": "ACS",
        "KYZ | Kyzyl Airport": "KYZ",
        "OVB | Novosibirsk Tolmachevo Airport": "OVB",
        "OMS | Omsk Central Airport": "OMS",
        "SWT | Strezhevoy Airport": "SWT",
        "TOF | Bogashevo Airport": "TOF",
        "NOZ | Spichenkovo Airport": "NOZ",
        "DKS | Dikson Airport": "DKS",
        "HTG | Khatanga Airport": "HTG",
        "IAA | Igarka Airport": "IAA",
        "NSK | Norilsk-Alykel Airport": "NSK",
        "THX | Turukhansk Airport": "THX",
        "AAQ | Anapa Vityazevo Airport": "AAQ",
        "EIK | Yeysk Airport": "EIK",
        "GDZ | Gelendzhik Airport": "GDZ",
        "KRR | Krasnodar Pashkovsky International Airport": "KRR",
        "MCX | Uytash Airport": "MCX",
        "MRV | Mineralnyye Vody Airport": "MRV",
        "NAL | Nalchik Airport": "NAL",
        "OGZ | Beslan Airport": "OGZ",
        "IGT | Magas Airport": "IGT",
        "STW | Stavropol Shpakovskoye Airport": "STW",
        "ROV | Platov International Airport": "ROV",
        "TGK | Taganrog Yuzhny Airport": "TGK",
        "AER | Sochi International Airport": "AER",
        "ASF | Astrakhan Airport": "ASF",
        "ESL | Elista Airport": "ESL",
        "VOG | Volgograd International Airport": "VOG",
        "BNH | Cape Air Seaplanes on Boston Harbor Seaplane Base": "BNH",
        "XWA | Williston Basin International Airport": "XWA",
        "RTL | Spirit Lake Municipal Airport": "RTL",
        "CEK | Chelyabinsk Balandino Airport": "CEK",
        "MQF | Magnitogorsk International Airport": "MQF",
        "SBT | Sabetta International Airport": "SBT",
        "BVJ | Bovanenkovo Airport": "BVJ",
        "SLY | Salekhard Airport": "SLY",
        "YMK | Mys Kamenny Airport": "YMK",
        "KKQ | Krasnoselkup Airport": "KKQ",
        "TQL | Tarko-Sale Airport": "TQL",
        "UEN | Urengoy Airport": "UEN",
        "EZV | Berezovo Airport": "EZV",
        "HMA | Khanty Mansiysk Airport": "HMA",
        "IRM | Igrim Airport": "IRM",
        "NYA | Nyagan Airport": "NYA",
        "OVS | Sovetskiy Airport": "OVS",
        "URJ | Uray Airport": "URJ",
        "EYK | Beloyarskiy Airport": "EYK",
        "IJK | Izhevsk Airport": "IJK",
        "KVX | Pobedilovo Airport": "KVX",
        "NYM | Nadym Airport": "NYM",
        "NUX | Novy Urengoy Airport": "NUX",
        "NJC | Nizhnevartovsk Airport": "NJC",
        "PEE | Perm International Airport": "PEE",
        "KGP | Kogalym International Airport": "KGP",
        "NFG | Nefteyugansk Airport": "NFG",
        "NOJ | Noyabrsk Airport": "NOJ",
        "SGC | Surgut Airport": "SGC",
        "SVX | Koltsovo Airport": "SVX",
        "RMZ | Remezov Airport": "RMZ",
        "TOX | Tobolsk Airport": "TOX",
        "TJM | Roshchino International Airport": "TJM",
        "KRO | Kurgan Airport": "KRO",
        "BKN | Balkanabat Airport": "BKN",
        "GMV | Monument Valley Airport": "GMV",
        "ASB | Ashgabat International Airport": "ASB",
        "KRW | Turkmenbashi International Airport": "KRW",
        "MYP | Mary International Airport": "MYP",
        "TAZ | Daşoguz Airport": "TAZ",
        "CRZ | Türkmenabat International Airport": "CRZ",
        "DYU | Dushanbe Airport": "DYU",
        "TJU | Kulob Airport": "TJU",
        "LBD | Khujand Airport": "LBD",
        "KQT | Qurghonteppa International Airport": "KQT",
        "AZN | Andizhan Airport": "AZN",
        "FEG | Fergana International Airport": "FEG",
        "NMA | Namangan Airport": "NMA",
        "NCU | Nukus Airport": "NCU",
        "UGC | Urgench Airport": "UGC",
        "NVI | Navoi Airport": "NVI",
        "BHK | Bukhara International Airport": "BHK",
        "KSQ | Karshi Airport": "KSQ",
        "AFS | Sugraly Airport": "AFS",
        "SKD | Samarkand Airport": "SKD",
        "TMJ | Termez Airport": "TMJ",
        "TAS | Tashkent International Airport": "TAS",
        "UTU | Ustupo Airport": "UTU",
        "KMW | Kostroma Sokerkino Airport": "KMW",
        "KLF | Grabtsevo Airport": "KLF",
        "IWA | Ivanovo South Airport": "IWA",
        "RYB | Staroselye Airport": "RYB",
        "BZK | Bryansk Airport": "BZK",
        "ZIA | Zhukovsky International Airport": "ZIA",
        "DME | Domodedovo International Airport": "DME",
        "IAR | Tunoshna Airport": "IAR",
        "SVO | Sheremetyevo International Airport": "SVO",
        "KLD | Migalovo Air Base": "KLD",
        "OSF | Ostafyevo International Airport": "OSF",
        "CKL | Chkalovskiy Air Base": "CKL",
        "EGO | Belgorod International Airport": "EGO",
        "URS | Kursk East Airport": "URS",
        "LPK | Lipetsk Airport": "LPK",
        "VOZ | Voronezh International Airport": "VOZ",
        "OEL | Oryol Yuzhny Airport": "OEL",
        "TBW | Donskoye Airport": "TBW",
        "UUU | Manumu Airport": "UUU",
        "RZN | Turlatovo Airport": "RZN",
        "TYA | Klokovo Airfield": "TYA",
        "VKO | Vnukovo International Airport": "VKO",
        "UCT | Ukhta Airport": "UCT",
        "INA | Inta Airport": "INA",
        "PEX | Pechora Airport": "PEX",
        "USK | Usinsk Airport": "USK",
        "VKT | Vorkuta Airport": "VKT",
        "UTS | Ust-Tsylma Airport": "UTS",
        "SCW | Syktyvkar Airport": "SCW",
        "GOJ | Nizhny Novgorod Strigino International Airport": "GOJ",
        "UUA | Bugulma Airport": "UUA",
        "KZN | Kazan International Airport": "KZN",
        "NBC | Begishevo Airport": "NBC",
        "JOK | Yoshkar-Ola Airport": "JOK",
        "CSY | Cheboksary Airport": "CSY",
        "ZIX | Zhigansk Airport": "ZIX",
        "ULV | Ulyanovsk Baratayevka Airport": "ULV",
        "ULY | Ulyanovsk East Airport": "ULY",
        "REN | Orenburg Central Airport": "REN",
        "OSW | Orsk Airport": "OSW",
        "PEZ | Penza Airport": "PEZ",
        "SKX | Saransk Airport": "SKX",
        "BWO | Balakovo Airport": "BWO",
        "GSV | Gagarin International Airport": "GSV",
        "BCX | Beloretsk Airport": "BCX",
        "NEF | Neftekamsk Airport": "NEF",
        "OKT | Oktyabrskiy Airport": "OKT",
        "UFA | Ufa International Airport": "UFA",
        "KUF | Kurumoch International Airport": "KUF",
        "UZM | Hope Bay Aerodrome": "UZM",
        "UZR | Urzhar Airport": "UZR",
        "REW | Rewa Airport Chorhata REWA": "REW",
        "DIU | Diu Airport": "DIU",
        "GDB | Gondia Airport": "GDB",
        "AMD | Sardar Vallabhbhai Patel International Airport": "AMD",
        "AKD | Akola Airport": "AKD",
        "IXU | Aurangabad Airport": "IXU",
        "BOM | Chhatrapati Shivaji International Airport": "BOM",
        "PAB | Bilaspur Airport": "PAB",
        "BHJ | Bhuj Airport": "BHJ",
        "IXG | Belagavi Airport": "IXG",
        "BDQ | Vadodara Airport": "BDQ",
        "BHO | Raja Bhoj International Airport": "BHO",
        "BHU | Bhavnagar Airport": "BHU",
        "NMB | Daman Airport": "NMB",
        "GUX | Guna Airport": "GUX",
        "GOI | Dabolim Airport": "GOI",
        "HBX | Hubli Airport": "HBX",
        "IDR | Devi Ahilyabai Holkar Airport": "IDR",
        "JLR | Jabalpur Airport": "JLR",
        "JGA | Jamnagar Airport": "JGA",
        "IXY | Kandla Airport": "IXY",
        "HJR | Khajuraho Airport": "HJR",
        "KLH | Kolhapur Airport": "KLH",
        "IXK | Keshod Airport": "IXK",
        "NDC | Nanded Airport": "NDC",
        "NAG | Dr. Babasaheb Ambedkar International Airport": "NAG",
        "ISK | Nashik Airport": "ISK",
        "PNQ | Pune Airport / Lohagaon Air Force Station": "PNQ",
        "PBD | Porbandar Airport": "PBD",
        "RTC | Ratnagiri Airport": "RTC",
        "RAJ | Rajkot Airport": "RAJ",
        "RPR | Swami Vivekananda Airport": "RPR",
        "SAG | Shirdi Airport": "SAG",
        "SSE | Solapur Airport": "SSE",
        "STV | Surat Airport": "STV",
        "UDR | Maharana Pratap Airport": "UDR",
        "CMB | Bandaranaike International Colombo Airport": "CMB",
        "ACJ | Anuradhapura Airport": "ACJ",
        "BTC | Batticaloa Airport": "BTC",
        "RML | Colombo Ratmalana Airport": "RML",
        "ADP | Ampara Airport": "ADP",
        "HIM | Hingurakgoda Air Force Base": "HIM",
        "JAF | Kankesanturai Airport": "JAF",
        "KCT | Koggala Airport": "KCT",
        "KTY | Katukurunda Air Force Base": "KTY",
        "GIU | Sigiriya Air Force Base": "GIU",
        "TRR | China Bay Airport": "TRR",
        "WRZ | Weerawila Airport": "WRZ",
        "HRI | Mattala Rajapaksa International Airport": "HRI",
        "BBM | Battambang Airport": "BBM",
        "KZC | Kampong Chhnang Airport": "KZC",
        "KKZ | Koh Kong Airport": "KKZ",
        "MWV | Mondulkiri Airport": "MWV",
        "PNH | Phnom Penh International Airport": "PNH",
        "RBE | Ratanakiri Airport": "RBE",
        "REP | Siem Reap International Airport": "REP",
        "KOS | Sihanoukville International Airport": "KOS",
        "KZD | Krakor Airport": "KZD",
        "LGY | Lagunillas Airport": "LGY",
        "KTV | Kamarata Airport": "KTV",
        "LAG | La Guaira Airport": "LAG",
        "SFX | Macagua Airport": "SFX",
        "SVV | San Salvador de Paul Airport": "SVV",
        "WOK | Wonken Airport": "WOK",
        "DBR | Darbhanga Airport": "DBR",
        "IXV | Along Airport": "IXV",
        "IXA | Agartala - Maharaja Bir Bikram Airport": "IXA",
        "IXB | Bagdogra Airport": "IXB",
        "RGH | Balurghat Airport": "RGH",
        "SHL | Shillong Airport": "SHL",
        "BBI | Biju Patnaik Airport": "BBI",
        "CCU | Netaji Subhash Chandra Bose International Airport": "CCU",
        "COH | Cooch Behar Airport": "COH",
        "DBD | Dhanbad Airport": "DBD",
        "RDP | Kazi Nazrul Islam Airport": "RDP",
        "DEP | Daporijo Airport": "DEP",
        "GOP | Gorakhpur Airport": "GOP",
        "GAU | Lokpriya Gopinath Bordoloi International Airport": "GAU",
        "GAY | Gaya Airport": "GAY",
        "IMF | Imphal Airport": "IMF",
        "JRG | Jharsuguda Airport": "JRG",
        "PYB | Jeypore Airport": "PYB",
        "IXW | Sonari Airport": "IXW",
        "JRH | Jorhat Airport": "JRH",
        "IXQ | Kamalpur Airport": "IXQ",
        "IXH | Kailashahar Airport": "IXH",
        "IXS | Silchar Airport": "IXS",
        "IXN | Khowai Airport": "IXN",
        "AJL | Lengpui Airport": "AJL",
        "IXI | North Lakhimpur Airport": "IXI",
        "LDA | Malda Airport": "LDA",
        "DIB | Dibrugarh Airport": "DIB",
        "DMU | Dimapur Airport": "DMU",
        "MZU | Muzaffarpur Airport": "MZU",
        "IXT | Pasighat Airport": "IXT",
        "PAT | Lok Nayak Jayaprakash Airport": "PAT",
        "IXR | Birsa Munda Airport": "IXR",
        "RRK | Rourkela Airport": "RRK",
        "RUP | Rupsi India Airport": "RUP",
        "TEZ | Tezpur Airport": "TEZ",
        "VTZ | Visakhapatnam Airport": "VTZ",
        "ZER | Ziro Airport": "ZER",
        "BZL | Barisal Airport": "BZL",
        "CXB | Cox's Bazar Airport": "CXB",
        "CLA | Comilla Airport": "CLA",
        "CGP | Shah Amanat International Airport": "CGP",
        "IRD | Ishurdi Airport": "IRD",
        "JSR | Jessore Airport": "JSR",
        "RJH | Shah Mokhdum Airport": "RJH",
        "SPD | Saidpur Airport": "SPD",
        "TKR | Thakurgaon Airport": "TKR",
        "ZHM | Shamshernagar Airport": "ZHM",
        "ZYL | Osmany International Airport": "ZYL",
        "DAC | Hazrat Shahjalal International Airport": "DAC",
        "HKG | Hong Kong International Airport": "HKG",
        "SPB | Charlotte Amalie Harbor Seaplane Base": "SPB",
        "SSB | Christiansted Harbor Seaplane Base": "SSB",
        "AGR | Agra Airport / Agra Air Force Station": "AGR",
        "IXD | Allahabad Airport": "IXD",
        "ATQ | Sri Guru Ram Dass Jee International Airport": "ATQ",
        "AIP | Adampur Airport": "AIP",
        "BKB | Nal Airport": "BKB",
        "VNS | Lal Bahadur Shastri Airport": "VNS",
        "KUU | Kullu Manali Airport": "KUU",
        "BUP | Bhatinda Air Force Station": "BUP",
        "BEK | Bareilly Air Force Station": "BEK",
        "IXC | Chandigarh Airport": "IXC",
        "KNU | Kanpur Airport": "KNU",
        "DED | Dehradun Airport": "DED",
        "DEL | Indira Gandhi International Airport": "DEL",
        "DHM | Kangra Airport": "DHM",
        "GWL | Gwalior Airport": "GWL",
        "HSS | Hissar Airport": "HSS",
        "JDH | Jodhpur Airport": "JDH",
        "JAI | Jaipur International Airport": "JAI",
        "JSA | Jaisalmer Airport": "JSA",
        "IXJ | Jammu Airport": "IXJ",
        "KQH | Kishangarh Airport Ajmer": "KQH",
        "KTU | Kota Airport": "KTU",
        "LUH | Ludhiana Airport": "LUH",
        "IXL | Leh Kushok Bakula Rimpochee Airport": "IXL",
        "LKO | Chaudhary Charan Singh International Airport": "LKO",
        "IXP | Pathankot Airport": "IXP",
        "PGH | Pantnagar Airport": "PGH",
        "SLV | Shimla Airport": "SLV",
        "SXR | Sheikh ul Alam Airport": "SXR",
        "TNI | Satna Airport": "TNI",
        "VIV | Vivigani Airfield": "VIV",
        "VJQ | Gurue Airport": "VJQ",
        "AOU | Attopeu Airport": "AOU",
        "HOE | Ban Huoeisay Airport": "HOE",
        "LPQ | Luang Phabang International Airport": "LPQ",
        "LXG | Luang Namtha Airport": "LXG",
        "ODY | Oudomsay Airport": "ODY",
        "PKZ | Pakse International Airport": "PKZ",
        "ZBY | Sayaboury Airport": "ZBY",
        "ZVK | Savannakhet Airport": "ZVK",
        "NEU | Sam Neua Airport": "NEU",
        "VNA | Saravane Airport": "VNA",
        "THK | Thakhek Airport": "THK",
        "VTE | Wattay International Airport": "VTE",
        "XKH | Xieng Khouang Airport": "XKH",
        "XIE | Xienglom Airport": "XIE",
        "VMI | Dr Juan Plate Airport": "VMI",
        "MFM | Macau International Airport": "MFM",
        "VDH | Dong Hoi Airport": "VDH",
        "KON | Kontum Airport": "KON",
        "BJH | Bajhang Airport": "BJH",
        "BHP | Bhojpur Airport": "BHP",
        "BGL | Baglung Airport": "BGL",
        "BHR | Bharatpur Airport": "BHR",
        "BJU | Bajura Airport": "BJU",
        "BIT | Baitadi Airport": "BIT",
        "BWA | Gautam Buddha International Airport": "BWA",
        "BDP | Bhadrapur Airport": "BDP",
        "DNP | Tulsipur Airport": "DNP",
        "DHI | Dhangarhi Airport": "DHI",
        "DAP | Darchula Airport": "DAP",
        "DOP | Dolpa Airport": "DOP",
        "SIH | Silgadi Doti Airport": "SIH",
        "GKH | Palungtar Airport": "GKH",
        "JIR | Jiri Airport": "JIR",
        "JUM | Jumla Airport": "JUM",
        "JKR | Janakpur Airport": "JKR",
        "JMO | Jomsom Airport": "JMO",
        "KTM | Tribhuvan International Airport": "KTM",
        "LDN | Lamidanda Airport": "LDN",
        "LUA | Lukla Airport": "LUA",
        "LTG | Langtang Airport": "LTG",
        "NGX | Manang Airport": "NGX",
        "MEY | Meghauli Airport": "MEY",
        "XMG | Mahendranagar Airport": "XMG",
        "KEP | Nepalgunj Airport": "KEP",
        "PKR | Pokhara Airport": "PKR",
        "PPL | Phaplu Airport": "PPL",
        "RJB | Rajbiraj Airport": "RJB",
        "RHP | Ramechhap Airport": "RHP",
        "RUK | Rukum Chaurjahari Airport": "RUK",
        "RPA | Rolpa Airport": "RPA",
        "RUM | Rumjatar Airport": "RUM",
        "SYH | Syangboche Airport": "SYH",
        "SIF | Simara Airport": "SIF",
        "SKH | Surkhet Airport": "SKH",
        "FEB | Sanfebagar Airport": "FEB",
        "IMK | Simikot Airport": "IMK",
        "TPJ | Taplejung Airport": "TPJ",
        "TPU | Tikapur Airport": "TPU",
        "TMI | Tumling Tar Airport": "TMI",
        "BIR | Biratnagar Airport": "BIR",
        "LTU | Murod Kond Airport": "LTU",
        "TCR | Tuticorin Airport": "TCR",
        "AGX | Agatti Airport": "AGX",
        "BEP | Bellary Airport": "BEP",
        "BLR | Kempegowda International Airport": "BLR",
        "IXX | Bidar Airport / Bidar Air Force Station": "IXX",
        "VGA | Vijayawada Airport": "VGA",
        "CJB | Coimbatore International Airport": "CJB",
        "COK | Cochin International Airport": "COK",
        "CCJ | Calicut International Airport": "CCJ",
        "CDP | Kadapa Airport": "CDP",
        "CBD | Car Nicobar Air Force Base": "CBD",
        "HYD | Rajiv Gandhi International Airport": "HYD",
        "BPM | Begumpet Airport": "BPM",
        "CNN | Kannur International Airport": "CNN",
        "KJB | Kurnool Airport": "KJB",
        "IXM | Madurai Airport": "IXM",
        "IXE | Mangalore International Airport": "IXE",
        "MAA | Chennai International Airport": "MAA",
        "MYQ | Mysore Airport": "MYQ",
        "IXZ | Veer Savarkar International Airport / INS Utkrosh": "IXZ",
        "PNY | Pondicherry Airport": "PNY",
        "PUT | Sri Sathya Sai Airport": "PUT",
        "RMD | Basanth Nagar Airport": "RMD",
        "RJA | Rajahmundry Airport": "RJA",
        "SXV | Salem Airport": "SXV",
        "TJV | Thanjavur Air Force Station": "TJV",
        "TIR | Tirupati Airport": "TIR",
        "TRZ | Tiruchirappalli International Airport": "TRZ",
        "TRV | Trivandrum International Airport": "TRV",
        "WGC | Warangal Airport": "WGC",
        "YON | Yongphulla Airport": "YON",
        "BUT | Bathpalathang Airport": "BUT",
        "GLU | Gelephu Airport": "GLU",
        "PBH | Paro Airport": "PBH",
        "HDK | Kulhudhuffushi Airport": "HDK",
        "NMF | Maafaru International Airport": "NMF",
        "IFU | Ifuru Airport": "IFU",
        "DRV | Dharavandhoo Airport": "DRV",
        "FVM | Fuvahmulah Airport": "FVM",
        "GAN | Gan International Airport": "GAN",
        "HAQ | Hanimaadhoo Airport": "HAQ",
        "KDO | Kadhdhoo Airport": "KDO",
        "MLE | Malé International Airport": "MLE",
        "GKK | Kooddoo Airport": "GKK",
        "KDM | Kaadedhdhoo Airport": "KDM",
        "DDD | Dhaalu Atoll Airport": "DDD",
        "VAM | Villa Airport": "VAM",
        "TMF | Thimarafushi Airport": "TMF",
        "RUL | Maavaarulaa Airport": "RUL",
        "DMK | Don Mueang International Airport": "DMK",
        "KKM | Sa Pran Nak Airport": "KKM",
        "KDT | Kamphaeng Saen Airport": "KDT",
        "TDX | Trat Airport": "TDX",
        "BKK | Suvarnabhumi Airport": "BKK",
        "UTP | U-Tapao International Airport": "UTP",
        "CNX | Chiang Mai International Airport": "CNX",
        "HGN | Mae Hong Son Airport": "HGN",
        "PYY | Mae Hong Son Airport": "PYY",
        "LPT | Lampang Airport": "LPT",
        "NNT | Nan Airport": "NNT",
        "PRH | Phrae Airport": "PRH",
        "CEI | Mae Fah Luang - Chiang Rai International Airport": "CEI",
        "BAO | Udorn Air Base": "BAO",
        "PHY | Phetchabun Airport": "PHY",
        "HHQ | Hua Hin Airport": "HHQ",
        "TKH | Takhli Airport": "TKH",
        "MAQ | Mae Sot Airport": "MAQ",
        "THS | Sukhothai Airport": "THS",
        "PHS | Phitsanulok Airport": "PHS",
        "TKT | Tak Airport": "TKT",
        "UTR | Uttaradit Airport": "UTR",
        "URT | Surat Thani Airport": "URT",
        "NAW | Narathiwat Airport": "NAW",
        "CJM | Chumphon Airport": "CJM",
        "NST | Nakhon Si Thammarat Airport": "NST",
        "KBV | Krabi Airport": "KBV",
        "SGZ | Songkhla Airport": "SGZ",
        "PAN | Pattani Airport": "PAN",
        "USM | Samui Airport": "USM",
        "HKT | Phuket International Airport": "HKT",
        "UNN | Ranong Airport": "UNN",
        "HDY | Hat Yai International Airport": "HDY",
        "TST | Trang Airport": "TST",
        "UTH | Udon Thani Airport": "UTH",
        "SNO | Sakon Nakhon Airport": "SNO",
        "PXR | Surin Airport": "PXR",
        "KKC | Khon Kaen Airport": "KKC",
        "LOE | Loei Airport": "LOE",
        "BFV | Buri Ram Airport": "BFV",
        "NAK | Nakhon Ratchasima Airport": "NAK",
        "UBP | Ubon Ratchathani Airport": "UBP",
        "ROI | Roi Et Airport": "ROI",
        "KOP | Nakhon Phanom Airport": "KOP",
        "VUU | Mvuu Camp Airport": "VUU",
        "BMV | Buon Ma Thuot Airport": "BMV",
        "VCL | Chu Lai Airport": "VCL",
        "HPH | Cat Bi International Airport": "HPH",
        "CAH | Cà Mau Airport": "CAH",
        "CXR | Cam Ranh International Airport / Cam Ranh Air Base": "CXR",
        "VCS | Con Dao Airport": "VCS",
        "VCA | Can Tho International Airport": "VCA",
        "DIN | Dien Bien Phu Airport": "DIN",
        "DLI | Lien Khuong Airport": "DLI",
        "DAD | Da Nang International Airport": "DAD",
        "VVN | Las Malvinas/Echarate Airport": "VVN",
        "HAN | Noi Bai International Airport": "HAN",
        "SQH | Na-San Airport": "SQH",
        "HUI | Phu Bai International Airport": "HUI",
        "UIH | Phu Cat Airport": "UIH",
        "PXU | Pleiku Airport": "PXU",
        "PQC | Phu Quoc International Airport": "PQC",
        "PHA | Phan Rang Airport": "PHA",
        "PHH | Phan Thiet Airport": "PHH",
        "VKG | Rach Gia Airport": "VKG",
        "TBB | Dong Tac Airport": "TBB",
        "SGN | Tan Son Nhat International Airport": "SGN",
        "THD | Tho Xuan Airport": "THD",
        "VDO | Van Don International Airport": "VDO",
        "VII | Vinh Airport": "VII",
        "VTG | Vung Tau Airport": "VTG",
        "VBA | Ann Airport": "VBA",
        "NYU | Bagan Airport": "NYU",
        "BMO | Banmaw Airport": "BMO",
        "VBP | Bokpyinn Airport": "VBP",
        "TVY | Dawei Airport": "TVY",
        "NYT | Nay Pyi Taw International Airport": "NYT",
        "GAW | Gangaw Airport": "GAW",
        "GWA | Gwa Airport": "GWA",
        "HEH | Heho Airport": "HEH",
        "HOX | Hommalinn Airport": "HOX",
        "TIO | Tilin Airport": "TIO",
        "KET | Kengtung Airport": "KET",
        "KHM | Kanti Airport": "KHM",
        "KMV | Kalay Airport": "KMV",
        "KYP | Kyaukpyu Airport": "KYP",
        "KAW | Kawthoung Airport": "KAW",
        "KYT | Kyauktu Airport": "KYT",
        "LIW | Loikaw Airport": "LIW",
        "LSH | Lashio Airport": "LSH",
        "MDL | Mandalay International Airport": "MDL",
        "MGZ | Myeik Airport": "MGZ",
        "MYT | Myitkyina Airport": "MYT",
        "MNU | Mawlamyine Airport": "MNU",
        "MGU | Manaung Airport": "MGU",
        "MOE | Momeik Airport": "MOE",
        "MOG | Mong Hsat Airport": "MOG",
        "MGK | Mong Tong Airport": "MGK",
        "MWQ | Magway Airport": "MWQ",
        "NYW | Monywar Airport": "NYW",
        "NMS | Namsang Airport": "NMS",
        "NMT | Namtu Airport": "NMT",
        "PAA | Hpa-N Airport": "PAA",
        "PAU | Pauk Airport": "PAU",
        "BSX | Pathein Airport": "BSX",
        "PPU | Hpapun Airport": "PPU",
        "PBU | Putao Airport": "PBU",
        "PKK | Pakhokku Airport": "PKK",
        "PRU | Pyay Airport": "PRU",
        "AKY | Sittwe Airport": "AKY",
        "SNW | Thandwe Airport": "SNW",
        "THL | Tachileik Airport": "THL",
        "XYE | Ye Airport": "XYE",
        "RGN | Yangon International Airport": "RGN",
        "FBS | Friday Harbor Seaplane Base": "FBS",
        "RSJ | Rosario Seaplane Base": "RSJ",
        "LKE | Kenmore Air Harbor Seaplane Base": "LKE",
        "RCE | Roche Harbor Airport": "RCE",
        "TQQ | Maranggo Airport": "TQQ",
        "WSX | Westsound/WSX Seaplane Base": "WSX",
        "UPG | Hasanuddin International Airport": "UPG",
        "MJU | Tampa Padang Airport": "MJU",
        "BIK | Frans Kaisiepo Airport": "BIK",
        "ONI | Moanamani Airport": "ONI",
        "WET | Wagethe Airport": "WET",
        "NBX | Nabire Airport": "NBX",
        "ILA | Illaga Airport": "ILA",
        "KOX | Kokonau Airport": "KOX",
        "ZRI | Serui Airport": "ZRI",
        "TIM | Mozes Kilangin Airport": "TIM",
        "EWI | Enarotali Airport": "EWI",
        "WAD | Andriamena Airport": "WAD",
        "AMI | Selaparang Airport": "AMI",
        "BMU | Muhammad Salahuddin Airport": "BMU",
        "DPS | Ngurah Rai (Bali) International Airport": "DPS",
        "LOP | Lombok International Airport": "LOP",
        "SWQ | Sumbawa Besar Airport": "SWQ",
        "TMC | Tambolaka Airport": "TMC",
        "WGP | Umbu Mehang Kunda Airport": "WGP",
        "LLO | Bua - Palopo Lagaligo Airport": "LLO",
        "HMS | Haji Muhammad Sidik Airport": "HMS",
        "KLP | Seruyan Kuala Pembuang Airport": "KLP",
        "YIA | Yogyakarta International Airport": "YIA",
        "KWB | Dewadaru - Kemujan Island": "KWB",
        "ARJ | Arso Airport": "ARJ",
        "BUI | Bokondini Airport": "BUI",
        "ZRM | Sarmi Airport": "ZRM",
        "DJJ | Sentani International Airport": "DJJ",
        "LHI | Lereh Airport": "LHI",
        "LII | Mulia Airport": "LII",
        "OKL | Oksibil Airport": "OKL",
        "WAR | Waris Airport": "WAR",
        "SEH | Senggeh Airport": "SEH",
        "UBR | Ubrub Airport": "UBR",
        "WMX | Wamena Airport": "WMX",
        "MDP | Mindiptana Airport": "MDP",
        "BXD | Bade Airport": "BXD",
        "MKQ | Mopah International Airport": "MKQ",
        "OKQ | Okaba Airport": "OKQ",
        "KEI | Kepi Airport": "KEI",
        "TMH | Tanah Merah Airport": "TMH",
        "GHS | Melalan Airport": "GHS",
        "TJS | Tanjung Harapan Airport": "TJS",
        "DTD | Datadawai Airport": "DTD",
        "BEJ | Kalimarau Airport": "BEJ",
        "BPN | Sultan Aji Muhamad Sulaiman Airport": "BPN",
        "TRK | Juwata Airport": "TRK",
        "AAP | Aji Pangeran Tumenggung Pranoto International Airport": "AAP",
        "TSX | Tanjung Santan Airport": "TSX",
        "BYQ | Bunyu Airport": "BYQ",
        "GLX | Gamarmalamo Airport": "GLX",
        "PGQ | Buli Airport": "PGQ",
        "GTO | Jalaluddin Airport": "GTO",
        "NAH | Naha Airport": "NAH",
        "TLI | Sultan Bantilan Airport": "TLI",
        "GEB | Gebe Airport": "GEB",
        "KAZ | Kao Airport": "KAZ",
        "PLW | Mutiara - SIS Al-Jufrie Airport": "PLW",
        "MDC | Sam Ratulangi Airport": "MDC",
        "MNA | Melangguane Airport": "MNA",
        "PSJ | Kasiguncu Airport": "PSJ",
        "OTI | Pitu Airport": "OTI",
        "TTE | Sultan Babullah Airport": "TTE",
        "LUW | Syukuran Aminuddin Amir Airport": "LUW",
        "UOL | Buol Airport": "UOL",
        "WAN | Waverney Airport": "WAN",
        "BTW | Batu Licin Airport": "BTW",
        "PKN | Iskandar Airport": "PKN",
        "KBU | Gusti Syamsir Alam Airport": "KBU",
        "TJG | Warukin Airport": "TJG",
        "BDJ | Syamsudin Noor Airport": "BDJ",
        "PKY | Tjilik Riwut Airport": "PKY",
        "SMQ | Sampit(Hasan) Airport": "SMQ",
        "AHI | Amahai Airport": "AHI",
        "NDA | Bandanaira Airport": "NDA",
        "DOB | Rar Gwamar Airport": "DOB",
        "MAL | Mangole Airport Falabisahaya": "MAL",
        "LUV | Karel Sadsuitubun Airport": "LUV",
        "NRE | Namrole Airport": "NRE",
        "LAH | Oesman Sadik Airport": "LAH",
        "SXK | Saumlaki/Olilit Airport": "SXK",
        "BJK | Nangasuri Airport": "BJK",
        "SQN | Emalamo Sanana Airport": "SQN",
        "AMQ | Pattimura Airport Ambon": "AMQ",
        "NAM | Namlea Airport": "NAM",
        "TAX | Taliabu Island Airport": "TAX",
        "WBA | WahaiSeram Island": "WBA",
        "MLG | Abdul Rachman Saleh Airport": "MLG",
        "CPF | Ngloram Airport": "CPF",
        "JOG | Adisutjipto International Airport": "JOG",
        "SOC | Adisumarmo International Airport": "SOC",
        "SUB | Juanda International Airport": "SUB",
        "SRG | Achmad Yani Airport": "SRG",
        "SUP | Trunojoyo Airport": "SUP",
        "NTI | Stenkol Airport": "NTI",
        "RSK | Abresso Airport": "RSK",
        "KEQ | Kebar Airport": "KEQ",
        "FKQ | Fakfak Airport": "FKQ",
        "INX | Inanwatan Airport": "INX",
        "KNG | Kaimana Airport": "KNG",
        "RDE | Merdei Airport": "RDE",
        "RJM | Marinda Airport": "RJM",
        "BXB | Babo Airport": "BXB",
        "MKW | Rendani Airport": "MKW",
        "TXM | Teminabuan Airport": "TXM",
        "WSR | Wasior Airport": "WSR",
        "BJW | Bajawa Soa Airport": "BJW",
        "MOF | Maumere(Wai Oti) Airport": "MOF",
        "ENE | Ende (H Hasan Aroeboesman) Airport": "ENE",
        "RTG | Frans Sales Lega Airport": "RTG",
        "ARD | Mali Airport": "ARD",
        "LBJ | Komodo Airport": "LBJ",
        "SAU | Sabu-Tardanu Airport": "SAU",
        "KOE | El Tari Airport": "KOE",
        "BUW | Betoambari Airport": "BUW",
        "WNI | Matahora Airport": "WNI",
        "KSR | Selayar - Haji Aroeppala Airport": "KSR",
        "MXB | Andi Jemma Airport": "MXB",
        "SQR | Soroako Airport": "SQR",
        "TTR | Pongtiku Airport": "TTR",
        "KDI | Wolter Monginsidi Airport": "KDI",
        "SOQ | Domine Eduard Osok Airport": "SOQ",
        "WBB | Stebbins Airport": "WBB",
        "WBC | Wapolu Airport": "WBC",
        "BTU | Bintulu Airport": "BTU",
        "BLG | Belaga Airport": "BLG",
        "LSM | Long Semado Airport": "LSM",
        "LGL | Long Lellang Airport": "LGL",
        "KCH | Kuching International Airport": "KCH",
        "ODN | Long Seridan Airport": "ODN",
        "LMN | Limbang Airport": "LMN",
        "MKM | Mukah Airport": "MKM",
        "LKH | Long Akah Airport": "LKH",
        "MUR | Marudi Airport": "MUR",
        "BSE | Sematan Airport": "BSE",
        "KPI | Kapit Airport": "KPI",
        "BKM | Bakalalan Airport": "BKM",
        "MYY | Miri Airport": "MYY",
        "SBW | Sibu Airport": "SBW",
        "TGC | Tanjung Manis Airport": "TGC",
        "LSU | Long Sukang Airport": "LSU",
        "LWY | Lawas Airport": "LWY",
        "BBN | Bario Airport": "BBN",
        "SMM | Semporna Airport": "SMM",
        "LDU | Lahad Datu Airport": "LDU",
        "TEL | Telupid Airport": "TEL",
        "KGU | Keningau Airport": "KGU",
        "SXS | Sahabat [Sahabat 16] Airport": "SXS",
        "BKI | Kota Kinabalu International Airport": "BKI",
        "LBU | Labuan Airport": "LBU",
        "TMG | Tomanggong Airport": "TMG",
        "GSA | Long Pasia Airport": "GSA",
        "SPE | Sepulot Airport": "SPE",
        "PAY | Pamol Airport": "PAY",
        "RNU | Ranau Airport": "RNU",
        "SDK | Sandakan Airport": "SDK",
        "KUD | Kudat Airport": "KUD",
        "TWU | Tawau Airport": "TWU",
        "MZV | Mulu Airport": "MZV",
        "BWN | Brunei International Airport": "BWN",
        "WDA | Al Ain Airport Ain District Shabwah Governorate Yemen": "WDA",
        "WEA | Parker County Airport": "WEA",
        "WED | Wedau Airport": "WED",
        "WHL | Welshpool Airport": "WHL",
        "CJN | Nusawiru Airport": "CJN",
        "PKU | Sultan Syarif Kasim Ii (Simpang Tiga) Airport": "PKU",
        "DUM | Pinang Kampai Airport": "DUM",
        "RKO | Rokot Airport": "RKO",
        "SEQ | Sungai Pakning Bengkalis Airport": "SEQ",
        "TJB | Sei Bati Airport": "TJB",
        "KJT | Kertajati International Airport": "KJT",
        "BDO | Husein Sastranegara International Airport": "BDO",
        "CBN | Penggung Airport": "CBN",
        "TSY | Cibeureum Airport": "TSY",
        "BTH | Hang Nadim International Airport": "BTH",
        "PPR | Pasir Pangaraan Airport": "PPR",
        "LMU | Letung Airport": "LMU",
        "TNJ | Raja Haji Fisabilillah International Airport": "TNJ",
        "SIQ | Dabo Airport": "SIQ",
        "HLP | Halim Perdanakusuma International Airport": "HLP",
        "CXP | Tunggul Wulung Airport": "CXP",
        "PCB | Pondok Cabe Air Base": "PCB",
        "CGK | Soekarno-Hatta International Airport": "CGK",
        "LLJ | Silampari Airport": "LLJ",
        "TKG | Radin Inten II International Airport": "TKG",
        "TFY | Muhammad Taufiq Kiemas Airport": "TFY",
        "GNS | Binaka Airport": "GNS",
        "AEG | Aek Godang Airport": "AEG",
        "MES | Soewondo Air Force Base": "MES",
        "KNO | Kualanamu International Airport": "KNO",
        "DTB | Silangit Airport": "DTB",
        "SIW | Sibisa Airport": "SIW",
        "FLZ | Dr. Ferdinand Lumban Tobing Airport": "FLZ",
        "TJQ | H A S Hanandjoeddin International Airport": "TJQ",
        "NPO | Nanga Pinoh Airport": "NPO",
        "KTG | Rahadi Osman Airport": "KTG",
        "MWK | Tarempa Airport": "MWK",
        "NTX | Ranai Airport": "NTX",
        "PNK | Supadio Airport": "PNK",
        "PSU | Pangsuma Airport": "PSU",
        "SQG | Sintang(Susilo) Airport": "SQG",
        "DJB | Sultan Thaha Airport": "DJB",
        "BUU | Muara Bungo Airport": "BUU",
        "PGK | Depati Amir Airport": "PGK",
        "BKS | Fatmawati Soekarno Airport": "BKS",
        "PLM | Sultan Mahmud Badaruddin II Airport": "PLM",
        "PDO | Pendopo Airport": "PDO",
        "RGT | Japura Airport": "RGT",
        "PDG | Minangkabau International Airport": "PDG",
        "MPC | Muko Muko Airport": "MPC",
        "PXA | Bentayan Airport": "PXA",
        "TPK | Teuku Cut Ali Airport": "TPK",
        "SBG | Maimun Saleh Airport": "SBG",
        "MEQ | Cut Nyak Dhien Airport": "MEQ",
        "TXE | Rembele Airport": "TXE",
        "LSX | Lhok Sukon Airport": "LSX",
        "LSW | Malikus Saleh Airport": "LSW",
        "BTJ | Sultan Iskandar Muda International Airport": "BTJ",
        "SXT | Sungai Tiang Airport": "SXT",
        "MEP | Mersing Airport": "MEP",
        "SWY | Sitiawan Airport": "SWY",
        "TPG | Taiping (Tekah) Airport": "TPG",
        "TOD | Pulau Tioman Airport": "TOD",
        "AOR | Sultan Abdul Halim Airport": "AOR",
        "BWH | RMAF Butterworth Air Base": "BWH",
        "KBR | Sultan Ismail Petra Airport": "KBR",
        "KUA | Kuantan Airport": "KUA",
        "KTE | Kerteh Airport": "KTE",
        "IPH | Sultan Azlan Shah Airport": "IPH",
        "JHB | Senai International Airport": "JHB",
        "KUL | Kuala Lumpur International Airport": "KUL",
        "LGK | Langkawi International Airport": "LGK",
        "MKZ | Malacca Airport": "MKZ",
        "TGG | Sultan Mahmud Airport": "TGG",
        "PEN | Penang International Airport": "PEN",
        "PKG | Pulau Pangkor Airport": "PKG",
        "RDN | LTS Pulau Redang Airport": "RDN",
        "SZB | Sultan Abdul Aziz Shah International Airport": "SZB",
        "DTR | Decatur Shores Airport": "DTR",
        "WNU | Wanuma Airport": "WNU",
        "AUT | Atauro Airport": "AUT",
        "UAI | Suai Airport": "UAI",
        "DIL | Presidente Nicolau Lobato International Airport": "DIL",
        "BCH | Cakung Airport": "BCH",
        "MPT | Maliana Airport": "MPT",
        "OEC | Rota Do Sândalo Oecusse Airport": "OEC",
        "VIQ | Viqueque Airport": "VIQ",
        "ABU | AA Bere Tallo (Haliwen) Airport": "ABU",
        "LKA | Gewayentana Airport": "LKA",
        "RTI | David Constantijn Saudale Airport": "RTI",
        "SGQ | Sanggata/Sangkimah Airport": "SGQ",
        "LBW | Long Bawan Airport": "LBW",
        "BXT | Bontang Airport": "BXT",
        "NNX | Nunukan Airport": "NNX",
        "TNB | Tanah Grogot Airport": "TNB",
        "LPU | Long Apung Airport": "LPU",
        "WSA | Wasua Airport": "WSA",
        "QPG | Paya Lebar Air Base": "QPG",
        "TGA | Tengah Air Base": "TGA",
        "WSB | Steamboat Bay Seaplane Base": "WSB",
        "UGI | San Juan /Uganik/ Seaplane Base": "UGI",
        "WSM | Wiseman Airport": "WSM",
        "XSP | Seletar Airport": "XSP",
        "SIN | Singapore Changi Airport": "SIN",
        "WTT | Wantoat Airport": "WTT",
        "WUV | Wuvulu Island Airport": "WUV",
        "WUZ | Wuzhou Xijiang Airport": "WUZ",
        "GWV | Glendale Fokker Field": "GWV",
        "WZQ | Urad Middle Banner": "WZQ",
        "MPB | Miami Seaplane Base": "MPB",
        "XBB | Blubber Bay Seaplane Base": "XBB",
        "XBN | Biniguni Airport": "XBN",
        "XIG | Xinguara Municipal Airport": "XIG",
        "XMA | Maramag Airport": "XMA",
        "XRQ | Xinbarag Youqi Baogede Airport": "XRQ",
        "LNX | Smolensk North Airport": "LNX",
        "XVL | Vinh Long Airfield": "XVL",
        "UKN | Waukon Municipal Airport": "UKN",
        "ALH | Albany Airport": "ALH",
        "ABG | Abingdon Downs Airport": "ABG",
        "AWN | Alton Downs Airport": "AWN",
        "AUD | Augustus Downs Airport": "AUD",
        "YAJ | Lyall Harbour Seaplane Base": "YAJ",
        "MRP | Marla Airport": "MRP",
        "AXL | Alexandria Homestead Airport": "AXL",
        "AXC | Aramac Airport": "AXC",
        "ADO | Andamooka Airport": "ADO",
        "AMX | Ammaroo Airport": "AMX",
        "AMT | Amata Airport": "AMT",
        "WLP | West Angelas Airport": "WLP",
        "AYL | Anthony Lagoon Airport": "AYL",
        "ABH | Alpha Airport": "ABH",
        "YAQ | Maple Bay Seaplane Base": "YAQ",
        "ARY | Ararat Airport": "ARY",
        "GYL | Argyle Airport": "GYL",
        "ARM | Armidale Airport": "ARM",
        "AAB | Arrabury Airport": "AAB",
        "AUU | Aurukun Airport": "AUU",
        "AWP | Austral Downs Airport": "AWP",
        "AVG | Auvergne Airport": "AVG",
        "AYQ | Ayers Rock Connellan Airport": "AYQ",
        "AYR | Ayr Airport": "AYR",
        "ACF | Brisbane Archerfield Airport": "ACF",
        "BCI | Barcaldine Airport": "BCI",
        "ASP | Alice Springs Airport": "ASP",
        "BDD | Badu Island Airport": "BDD",
        "BKP | Barkly Downs Airport": "BKP",
        "BBE | Big Bell Airport": "BBE",
        "BNE | Brisbane International Airport": "BNE",
        "OOL | Gold Coast Airport": "OOL",
        "BKQ | Blackall Airport": "BKQ",
        "CNS | Cairns International Airport": "CNS",
        "CTL | Charleville Airport": "CTL",
        "BDW | Bedford Downs Airport": "BDW",
        "BXG | Bendigo Airport": "BXG",
        "BVI | Birdsville Airport": "BVI",
        "BXF | Bellburn Airstrip": "BXF",
        "BTX | Betoota Airport": "BTX",
        "BEE | Beagle Bay Airport": "BEE",
        "OCM | Boolgeeda": "OCM",
        "BQW | Balgo Hill Airport": "BQW",
        "YBH | Bull Harbour Water Aerodrome": "YBH",
        "BHQ | Broken Hill Airport": "BHQ",
        "HTI | Hamilton Island Airport": "HTI",
        "BEU | Bedourie Airport": "BEU",
        "BIW | Billiluna Airport": "BIW",
        "BZP | Bizant Airport": "BZP",
        "YBJ | Baie-Johan-Beetz Water Aerodrome": "YBJ",
        "BRK | Bourke Airport": "BRK",
        "BUC | Burketown Airport": "BUC",
        "BLN | Benalla Airport": "BLN",
        "LCN | Balcanoona Airport": "LCN",
        "ZBL | Biloela Airport": "ZBL",
        "BLS | Bollon Airport": "BLS",
        "BQB | Busselton Regional Airport": "BQB",
        "ISA | Mount Isa Airport": "ISA",
        "BFC | Bloomfield River Airport": "BFC",
        "MKY | Mackay Airport": "MKY",
        "BNK | Ballina Byron Gateway Airport": "BNK",
        "BSJ | Bairnsdale Airport": "BSJ",
        "GIC | Boigu Airport": "GIC",
        "OKY | Oakey Airport": "OKY",
        "BQL | Boulia Airport": "BQL",
        "BMP | Brampton Island Airport": "BMP",
        "PPP | Proserpine Whitsunday Coast Airport": "PPP",
        "YBQ | Telegraph Harbour Seaplane Base": "YBQ",
        "ROK | Rockhampton Airport": "ROK",
        "BOX | Borroloola Airport": "BOX",
        "BME | Broome International Airport": "BME",
        "BZD | Balranald Airport": "BZD",
        "BTD | Brunette Downs Airport": "BTD",
        "BWQ | Brewarrina Airport": "BWQ",
        "BYP | Barimunya Airport": "BYP",
        "MCY | Sunshine Coast Airport": "MCY",
        "BHS | Bathurst Airport": "BHS",
        "BRT | Bathurst Island Airport": "BRT",
        "TSV | Townsville Airport / RAAF Base Townsville": "TSV",
        "BLT | Blackwater Airport": "BLT",
        "BVW | Batavia Downs Airport": "BVW",
        "BDB | Bundaberg Airport": "BDB",
        "BUY | Bunbury Airport": "BUY",
        "BIP | Bulimba Airport": "BIP",
        "ZBO | Bowen Airport": "ZBO",
        "WEI | Weipa Airport": "WEI",
        "BCK | Bolwarra Airport": "BCK",
        "WTB | Brisbane West Wellcamp Airport": "WTB",
        "BWB | Barrow Island Airport": "BWB",
        "BVZ | Beverley Springs Airport": "BVZ",
        "CTR | Cattle Creek Airport": "CTR",
        "CGV | Caiguna Airport": "CGV",
        "CLH | Coolah Airport": "CLH",
        "CVQ | Carnarvon Airport": "CVQ",
        "CSI | Casino Airport": "CSI",
        "CAZ | Cobar Airport": "CAZ",
        "COJ | Coonabarabran Airport": "COJ",
        "CBY | Canobie Airport": "CBY",
        "CBI | Cape Barren Island Airport": "CBI",
        "CPD | Coober Pedy Airport": "CPD",
        "CRB | Collarenebri Airport": "CRB",
        "CCL | Chinchilla Airport": "CCL",
        "CNC | Coconut Island Airport": "CNC",
        "CNJ | Cloncurry Airport": "CNJ",
        "CBX | Condobolin Airport": "CBX",
        "CUD | Caloundra Airport": "CUD",
        "CED | Ceduna Airport": "CED",
        "CVC | Cleve Airport": "CVC",
        "YCF | Cortes Bay Water Aerodrome": "YCF",
        "CFI | Camfield Airport": "CFI",
        "CFH | Clifton Hills Airport": "CFH",
        "CQP | Cape Flattery Airport": "CQP",
        "CFS | Coffs Harbour Airport": "CFS",
        "LLG | Chillagoe Airport": "LLG",
        "CRH | Cherrabah Airport": "CRH",
        "CKW | Graeme Rowley Aerodrome": "CKW",
        "CXT | Charters Towers Airport": "CXT",
        "DCN | RAAF Base Curtin": "DCN",
        "CKI | Croker Island Airport": "CKI",
        "CTN | Cooktown Airport": "CTN",
        "CMQ | Clermont Airport": "CMQ",
        "CMA | Cunnamulla Airport": "CMA",
        "CML | Camooweal Airport": "CML",
        "NIF | Camp Nifty Airport": "NIF",
        "CES | Cessnock Airport": "CES",
        "CNB | Coonamble Airport": "CNB",
        "ODL | Cordillo Downs Airport": "ODL",
        "CUQ | Coen Airport": "CUQ",
        "CIE | Collie Airport": "CIE",
        "OOM | Cooma Snowy Mountains Airport": "OOM",
        "CDA | Cooinda Airport": "CDA",
        "CWW | Corowa Airport": "CWW",
        "CFP | Carpentaria Downs Airport": "CFP",
        "CYG | Corryong Airport": "CYG",
        "CXQ | Christmas Creek Station Airport": "CXQ",
        "CDQ | Croydon Airport": "CDQ",
        "KCE | Collinsville Airport": "KCE",
        "CMD | Cootamundra Airport": "CMD",
        "CUG | Cudal Airport": "CUG",
        "CUY | Cue Airport": "CUY",
        "CJF | Coondewanna Airport": "CJF",
        "CWR | Cowarie Airport": "CWR",
        "CCW | Cowell Airport": "CCW",
        "CWT | Cowra Airport": "CWT",
        "COY | Coolawanyah Airport": "COY",
        "DJR | Dajarra Airport": "DJR",
        "DBY | Dalby Airport": "DBY",
        "DRN | Dirranbandi Airport": "DRN",
        "DNB | Dunbar Airport": "DNB",
        "DRB | Derby Airport": "DRB",
        "DFP | Drumduff Airport": "DFP",
        "DGD | Dalgaranga Gold Mine Airport": "DGD",
        "DNG | Doongan Airport": "DNG",
        "DXD | Dixie Airport": "DXD",
        "DKI | Dunk Island Airport": "DKI",
        "DLK | Dulkaninna Airport": "DLK",
        "DNQ | Deniliquin Airport": "DNQ",
        "DDN | Delta Downs Airport": "DDN",
        "DLV | Delissaville Airport": "DLV",
        "DYW | Daly Waters Airport": "DYW",
        "DMD | Doomadgee Airport": "DMD",
        "DVR | Daly River Airport": "DVR",
        "NLF | Darnley Island Airport": "NLF",
        "DRD | Dorunda Airport": "DRD",
        "DVP | Davenport Downs Airport": "DVP",
        "DPO | Devonport Airport": "DPO",
        "DOX | Dongara Airport": "DOX",
        "DRY | Drysdale River Airport": "DRY",
        "DHD | Durham Downs Airport": "DHD",
        "DRR | Durrie Airport": "DRR",
        "SRR | Dunwich Airport": "SRR",
        "DKV | Docker River Airport": "DKV",
        "DYA | Dysart Airport": "DYA",
        "ECH | Echuca Airport": "ECH",
        "EUC | Eucla Airport": "EUC",
        "ETD | Etadunna Airport": "ETD",
        "ENB | Eneabba Airport": "ENB",
        "YEH | Yinchuan/Xincheng Air Base": "YEH",
        "EIH | Einasleigh Airport": "EIH",
        "ELC | Elcho Island Airport": "ELC",
        "EKD | Elkedra Airport": "EKD",
        "EMD | Emerald Airport": "EMD",
        "YEQ | Yenkis(Yankisa) Airport": "YEQ",
        "EDD | Erldunda Airport": "EDD",
        "ERB | Ernabella Airport": "ERB",
        "ERQ | Elrose Airport": "ERQ",
        "EPR | Esperance Airport": "EPR",
        "EVD | Eva Downs Airport": "EVD",
        "EVH | Evans Head Aerodrome": "EVH",
        "WHB | Eliwana": "WHB",
        "EXM | Exmouth Airport": "EXM",
        "FRB | Forbes Airport": "FRB",
        "KFE | Fortescue - Dave Forrest Aerodrome": "KFE",
        "FLY | Finley Airport": "FLY",
        "FLS | Flinders Island Airport": "FLS",
        "FVL | Flora Valley Airport": "FVL",
        "FIK | Finke Airport": "FIK",
        "FOS | Forrest Airport": "FOS",
        "FVR | Oombulgurri Airport": "FVR",
        "FOT | Forster (Wallis Is) Airport": "FOT",
        "FIZ | Fitzroy Crossing Airport": "FIZ",
        "YGA | Yongchuan Da'an General Airport": "YGA",
        "GBP | Gamboola Airport": "GBP",
        "GAH | Gayndah Airport": "GAH",
        "GBL | South Goulburn Is Airport": "GBL",
        "GUH | Gunnedah Airport": "GUH",
        "GOO | Goondiwindi Airport": "GOO",
        "GDD | Gordon Downs Airport": "GDD",
        "GGD | Gregory Downs Airport": "GGD",
        "YGE | Gorge Harbour Seaplane Base": "YGE",
        "GET | Geraldton Airport": "GET",
        "GFN | Grafton Airport": "GFN",
        "GBW | Ginbata Airport": "GBW",
        "GBV | Gibb River Airport": "GBV",
        "GKL | Great Keppel Is Airport": "GKL",
        "GLT | Gladstone Airport": "GLT",
        "GUL | Goulburn Airport": "GUL",
        "GLG | Glengyle Airport": "GLG",
        "GEX | Geelong Airport": "GEX",
        "GLI | Glen Innes Airport": "GLI",
        "GLM | Glenormiston Airport": "GLM",
        "YGN | Greenway Sound Seaplane Base": "YGN",
        "GFE | Grenfell Airport": "GFE",
        "GVP | Greenvale Airport": "GVP",
        "GPD | Mount Gordon Airport": "GPD",
        "GPN | Garden Point Airport": "GPN",
        "GSC | Gascoyne Junction Airport": "GSC",
        "GTE | Groote Eylandt Airport": "GTE",
        "GFF | Griffith Airport": "GFF",
        "GTT | Georgetown Airport": "GTT",
        "GEE | Georgetown (Tas) Airport": "GEE",
        "GYP | Gympie Airport": "GYP",
        "HWK | Wilpena Pound Airport": "HWK",
        "HXX | Hay Airport": "HXX",
        "HVB | Hervey Bay Airport": "HVB",
        "HUB | Humbert River Airport": "HUB",
        "HRY | Henbury Airport": "HRY",
        "HIP | Headingly Airport": "HIP",
        "HIG | Highbury Airport": "HIG",
        "HID | Horn Island Airport": "HID",
        "HLL | Hillside Airport": "HLL",
        "HCQ | Halls Creek Airport": "HCQ",
        "HMG | Hermannsburg Airport": "HMG",
        "HLT | Hamilton Airport": "HLT",
        "HOK | Hooker Creek Airport": "HOK",
        "MHU | Mount Hotham Airport": "MHU",
        "HTU | Hopetoun Airport": "HTU",
        "HPE | Hope Vale Airport": "HPE",
        "HSM | Horsham Airport": "HSM",
        "HAT | Heathlands Airport": "HAT",
        "HGD | Hughenden Airport": "HGD",
        "IDK | Indulkana Airport": "IDK",
        "IFL | Innisfail Airport": "IFL",
        "IFF | Iffley Airport": "IFF",
        "IGH | Ingham Airport": "IGH",
        "IKP | Inkerman Airport": "IKP",
        "INJ | Injune Airport": "INJ",
        "INM | Innamincka Airport": "INM",
        "IVW | Inverway Airport": "IVW",
        "ISI | Isisford Airport": "ISI",
        "IVR | Inverell Airport": "IVR",
        "JAB | Jabiru Airport": "JAB",
        "JUN | Jundah Airport": "JUN",
        "QJD | Jindabyne Airport": "QJD",
        "JCK | Julia Creek Airport": "JCK",
        "JUR | Jurien Bay Airport": "JUR",
        "UBU | Kalumburu Airport": "UBU",
        "KDB | Kambalda Airport": "KDB",
        "KAX | Kalbarri Airport": "KAX",
        "KBY | Streaky Bay Airport": "KBY",
        "KBJ | Kings Canyon Airport": "KBJ",
        "KCS | Kings Creek Airport": "KCS",
        "KRA | Kerang Airport": "KRA",
        "KNS | King Island Airport": "KNS",
        "KBB | Kirkimbie Station Airport": "KBB",
        "KFG | Kalkgurung Airport": "KFG",
        "KOH | Koolatah Airport": "KOH",
        "KKP | Koolburra Airport": "KKP",
        "KRB | Karumba Airport": "KRB",
        "KML | Kamileroi Airport": "KML",
        "KPS | Kempsey Airport": "KPS",
        "KNI | Katanning Airport": "KNI",
        "KWM | Kowanyama Airport": "KWM",
        "KPP | Kalpowar Airport": "KPP",
        "KGY | Kingaroy Airport": "KGY",
        "KGC | Kingscote Airport": "KGC",
        "YKT | Klemtu Water Aerodrome": "YKT",
        "KUG | Kubin Airport": "KUG",
        "KRD | Kurundi Airport": "KRD",
        "LWH | Lawn Hill Airport": "LWH",
        "LGH | Leigh Creek Airport": "LGH",
        "LNO | Leonora Airport": "LNO",
        "LEL | Lake Evella Airport": "LEL",
        "LFP | Lakefield Airport": "LFP",
        "LDH | Lord Howe Island Airport": "LDH",
        "IRG | Lockhart River Airport": "IRG",
        "LTP | Lyndhurst Airport": "LTP",
        "LIB | Limbunya Airport": "LIB",
        "LDC | Lindeman Island Airport": "LDC",
        "LSY | Lismore Airport": "LSY",
        "LNH | Lake Nash Airport": "LNH",
        "BBL | Ballera Airport": "BBL",
        "BEO | Lake Macquarie Airport": "BEO",
        "LKD | Lakeland Airport": "LKD",
        "LOC | Lock Airport": "LOC",
        "LOA | Lorraine Airport": "LOA",
        "LTV | Lotus Vale Airport": "LTV",
        "YLP | Mingan Airport": "YLP",
        "LUU | Laura Airport": "LUU",
        "LHG | Lightning Ridge Airport": "LHG",
        "LRE | Longreach Airport": "LRE",
        "LUT | New Laura Airport": "LUT",
        "LER | Leinster Airport": "LER",
        "LVO | Laverton Airport": "LVO",
        "TGN | Latrobe Valley Airport": "TGN",
        "LZR | Lizard Island Airport": "LZR",
        "UBB | Mabuiag Island Airport": "UBB",
        "MYI | Murray Island Airport": "MYI",
        "AVV | Avalon Airport": "AVV",
        "ABX | Albury Airport": "ABX",
        "MRG | Mareeba Airport": "MRG",
        "MBB | Marble Bar Airport": "MBB",
        "XMC | Mallacoota Airport": "XMC",
        "MFP | Manners Creek Airport": "MFP",
        "MLR | Millicent Airport": "MLR",
        "DGE | Mudgee Airport": "DGE",
        "MQA | Mandora Airport": "MQA",
        "MNW | Macdonald Downs Airport": "MNW",
        "MKR | Meekatharra Airport": "MKR",
        "MEB | Melbourne Essendon Airport": "MEB",
        "MIM | Merimbula Airport": "MIM",
        "MLV | Merluna Airport": "MLV",
        "MGT | Milingimbi Airport": "MGT",
        "MNG | Maningrida Airport": "MNG",
        "GSN | Mount Gunson Airport": "GSN",
        "MGV | Margaret River (Station) Airport": "MGV",
        "MQZ | Margaret River Airport": "MQZ",
        "MVU | Musgrave Airport": "MVU",
        "HBA | Hobart International Airport": "HBA",
        "MHO | Mount House Airport": "MHO",
        "MCV | McArthur River Mine Airport": "MCV",
        "MQL | Mildura Airport": "MQL",
        "XML | Minlaton Airport": "XML",
        "MIH | Mitchell Plateau Airport": "MIH",
        "MTQ | Mitchell Airport": "MTQ",
        "MJP | Manjimup Airport": "MJP",
        "WLE | Miles Airport": "WLE",
        "LST | Launceston Airport": "LST",
        "MBW | Melbourne Moorabbin Airport": "MBW",
        "WUI | Murrin Murrin Airport": "WUI",
        "MEL | Melbourne International Airport": "MEL",
        "MMM | Middlemount Airport": "MMM",
        "MTL | Maitland Airport": "MTL",
        "WME | Mount Keith Airport": "WME",
        "ONR | Monkira Airport": "ONR",
        "MSF | Mount Swan Airport": "MSF",
        "OXY | Morney Airport": "OXY",
        "MMG | Mount Magnet Airport": "MMG",
        "OOR | Mooraberree Airport": "OOR",
        "MRZ | Moree Airport": "MRZ",
        "MET | Moreton Airport": "MET",
        "MIN | Minnipa Airport": "MIN",
        "MQE | Marqua Airport": "MQE",
        "MOV | Moranbah Airport": "MOV",
        "RRE | Marree Airport": "RRE",
        "MWB | Morawa Airport": "MWB",
        "MYA | Moruya Airport": "MYA",
        "MTD | Mount Sanford Station Airport": "MTD",
        "MIY | Mittebah Airport": "MIY",
        "UTB | Muttaburra Airport": "UTB",
        "MGB | Mount Gambier Airport": "MGB",
        "ONG | Mornington Island Airport": "ONG",
        "MNQ | Monto Airport": "MNQ",
        "MUQ | Muccan Station Airport": "MUQ",
        "MNE | Mungeranie Airport": "MNE",
        "MVK | Mulka Airport": "MVK",
        "MUP | Mulga Park Airport": "MUP",
        "MKV | Mount Cavenagh Airport": "MKV",
        "MXU | Mullewa Airport": "MXU",
        "MWT | Moolawatana Airport": "MWT",
        "MXD | Marion Downs Airport": "MXD",
        "MBH | Maryborough Airport": "MBH",
        "RTY | Merty Merty Airport": "RTY",
        "NMR | Nappa Merrie Airport": "NMR",
        "NRA | Narrandera Airport": "NRA",
        "NAA | Narrabri Airport": "NAA",
        "RPM | Ngukurr Airport": "RPM",
        "NBH | Nambucca Heads Airport": "NBH",
        "NLS | Nicholson Airport": "NLS",
        "NKB | Noonkanbah Airport": "NKB",
        "NMP | New Moon Airport": "NMP",
        "NPP | Napperby Airport": "NPP",
        "ABM | Northern Peninsula Airport": "ABM",
        "NAC | Naracoorte Airport": "NAC",
        "NRG | Narrogin Airport": "NRG",
        "QRM | Narromine Airport": "QRM",
        "RVT | Ravensthorpe Airport": "RVT",
        "NSV | Noosa Airport": "NSV",
        "NSM | Norseman Airport": "NSM",
        "YNT | Yantai Penglai International Airport": "YNT",
        "NTN | Normanton Airport": "NTN",
        "NUR | Nullabor Motel Airport": "NUR",
        "NLL | Nullagine Airport": "NLL",
        "NUB | Numbulwar Airport": "NUB",
        "UTD | Nutwood Downs Airport": "UTD",
        "ZNE | Newman Airport": "ZNE",
        "NYN | Nyngan Airport": "NYN",
        "OPI | Oenpelli Airport": "OPI",
        "YOI | Opinaca Aerodrome": "YOI",
        "XCO | Colac Airport": "XCO",
        "OLP | Olympic Dam Airport": "OLP",
        "ONS | Onslow Airport": "ONS",
        "ODD | Oodnadatta Airport": "ODD",
        "MOO | Moomba Airport": "MOO",
        "RBS | Orbost Airport": "RBS",
        "OAG | Orange Airport": "OAG",
        "ODR | Ord River Airport": "ODR",
        "OSO | Osborne Mine Airport": "OSO",
        "OYN | Ouyen Airport": "OYN",
        "ADL | Adelaide International Airport": "ADL",
        "PUG | Port Augusta Airport": "PUG",
        "PMK | Palm Island Airport": "PMK",
        "PBO | Paraburdoo Airport": "PBO",
        "CCK | Cocos (Keeling) Islands Airport": "CCK",
        "PDN | Parndana Airport": "PDN",
        "PDE | Pandie Pandie Airport": "PDE",
        "DRW | Darwin International Airport": "DRW",
        "PRD | Pardoo Airport": "PRD",
        "GOV | Gove Airport": "GOV",
        "PPI | Port Pirie Airport": "PPI",
        "JAD | Perth Jandakot Airport": "JAD",
        "KTA | Karratha Airport": "KTA",
        "KGI | Kalgoorlie Boulder Airport": "KGI",
        "PKE | Parkes Airport": "PKE",
        "PKT | Port Keats Airport": "PKT",
        "KNX | East Kimberley Regional (Kununurra) Airport": "KNX",
        "PLO | Port Lincoln Airport": "PLO",
        "LEA | Learmonth Airport": "LEA",
        "PXH | Prominent Hill Airport": "PXH",
        "EDR | Pormpuraaw Airport": "EDR",
        "PQQ | Port Macquarie Airport": "PQQ",
        "PEY | Penong Airport": "PEY",
        "PTJ | Portland Airport": "PTJ",
        "PHE | Port Hedland International Airport": "PHE",
        "PER | Perth International Airport": "PER",
        "PEA | Penneshaw Airport": "PEA",
        "KTR | Tindal Airport": "KTR",
        "UMR | Woomera Airfield": "UMR",
        "XCH | Christmas Island Airport": "XCH",
        "UIR | Quirindi Airport": "UIR",
        "YQJ | April Point Seaplane Base": "YQJ",
        "ULP | Quilpie Airport": "ULP",
        "UEE | Queenstown Airport": "UEE",
        "RRV | Robinson River Airport": "RRV",
        "YRC | Refuge Cove Seaplane Base": "YRC",
        "YRD | Dean River Airport": "YRD",
        "RMK | Renmark Airport": "RMK",
        "RCM | Richmond Airport": "RCM",
        "RAM | Ramingining Airport": "RAM",
        "ROH | Robinhood Airport": "ROH",
        "RBU | Roebourne Airport": "RBU",
        "RBC | Robinvale Airport": "RBC",
        "RMA | Roma Airport": "RMA",
        "RPB | Roper Bar Airport": "RPB",
        "RSB | Roseberth Airport": "RSB",
        "RTS | Rottnest Island Airport": "RTS",
        "RTP | Rutland Plains Airport": "RTP",
        "RHL | Roy Hill Station Airport": "RHL",
        "NDS | Sandstone Airport": "NDS",
        "BWU | Sydney Bankstown Airport": "BWU",
        "CBR | Canberra International Airport": "CBR",
        "WCD | Carosue Dam Airport": "WCD",
        "CDU | Camden Airport": "CDU",
        "NSO | Scone Airport": "NSO",
        "SQC | Southern Cross Airport": "SQC",
        "DBO | Dubbo City Regional Airport": "DBO",
        "SGO | St George Airport": "SGO",
        "SIX | Singleton Airport": "SIX",
        "ZGL | South Galway Airport": "ZGL",
        "SGP | Shay Gap Airport": "SGP",
        "MJK | Shark Bay Airport": "MJK",
        "JHQ | Shute Harbour Airport": "JHQ",
        "SHT | Shepparton Airport": "SHT",
        "SBR | Saibai Island Airport": "SBR",
        "SIO | Smithton Airport": "SIO",
        "SHU | Smith Point Airport": "SHU",
        "STH | Strathmore Airport": "STH",
        "SNB | Snake Bay Airport": "SNB",
        "NLK | Norfolk Island International Airport": "NLK",
        "NOA | Naval Air Station Nowra - HMAS Albatross": "NOA",
        "SNH | Stanthorpe Airport": "SNH",
        "SCG | Spring Creek Airport": "SCG",
        "SHQ | Southport Airport": "SHQ",
        "KSV | Springvale Airport": "KSV",
        "XRH | RAAF Base Richmond": "XRH",
        "SRN | Strahan Airport": "SRN",
        "SYD | Sydney Kingsford Smith International Airport": "SYD",
        "HLS | St Helens Airport": "HLS",
        "STF | Stephens Island Seaplane Base": "STF",
        "TMW | Tamworth Airport": "TMW",
        "SSP | Silver Plains Airport": "SSP",
        "WGA | Wagga Wagga City Airport": "WGA",
        "SWH | Swan Hill Airport": "SWH",
        "SWC | Stawell Airport": "SWC",
        "XTR | Tara Airport": "XTR",
        "TBL | Tableland Homestead Airport": "TBL",
        "XTO | Taroom Airport": "XTO",
        "TAQ | Tarcoola Airport": "TAQ",
        "PYX | Pattaya Airpark": "PYX",
        "TBK | Timber Creek Airport": "TBK",
        "TDR | Theodore Airport": "TDR",
        "TQP | Trepell Airport": "TQP",
        "TEF | Telfer Airport": "TEF",
        "TEM | Temora Airport": "TEM",
        "TAN | Tangalooma Airport": "TAN",
        "XTG | Thargomindah Airport": "XTG",
        "GTS | The Granites Airport": "GTS",
        "TDN | Theda Station Airport": "TDN",
        "TYG | Thylungra Airport": "TYG",
        "TYB | Tibooburra Airport": "TYB",
        "TKY | Turkey Creek Airport": "TKY",
        "PHQ | The Monument Airport": "PHQ",
        "TUM | Tumut Airport": "TUM",
        "TYP | Tobermorey Airport": "TYP",
        "TXR | Tanbar Airport": "TXR",
        "THG | Thangool Airport": "THG",
        "TCA | Tennant Creek Airport": "TCA",
        "TCW | Tocumwal Airport": "TCW",
        "TRO | Taree Airport": "TRO",
        "TTX | Truscott-Mungalalu Airport": "TTX",
        "TWB | Toowoomba Airport": "TWB",
        "UDA | Undara Airport": "UDA",
        "CZY | Cluny Airport": "CZY",
        "USL | Useless Loop Airport": "USL",
        "VCD | Victoria River Downs Airport": "VCD",
        "VNR | Vanrook Station Airport": "VNR",
        "WAU | Wauchope Airport": "WAU",
        "WLA | Wallal Airport": "WLA",
        "WAV | Wave Hill Airport": "WAV",
        "WMB | Warrnambool Airport": "WMB",
        "SYU | Warraber Island Airport": "SYU",
        "WIO | Wilcannia Airport": "WIO",
        "WLC | Walcha Airport": "WLC",
        "WAZ | Warwick Airport": "WAZ",
        "WND | Windarra Airport": "WND",
        "WRN | Windarling Airport": "WRN",
        "WNR | Windorah Airport": "WNR",
        "WON | Wondoola Airport": "WON",
        "MFL | Mount Full Stop Airport": "MFL",
        "WGT | Wangaratta Airport": "WGT",
        "WYA | Whyalla Airport": "WYA",
        "WSY | Whitsunday Island Airport": "WSY",
        "WIT | Wittenoom Airport": "WIT",
        "WKB | Warracknabeal Airport": "WKB",
        "WGE | Walgett Airport": "WGE",
        "NTL | Newcastle Airport": "NTL",
        "WUN | Wiluna Airport": "WUN",
        "WPK | Wrotham Park Airport": "WPK",
        "WDI | Wondai Airport": "WDI",
        "WOL | Shellharbour Airport": "WOL",
        "WLL | Wollogorang Airport": "WLL",
        "QRR | Warren Airport": "QRR",
        "SXE | West Sale Airport": "SXE",
        "WLO | Waterloo Airport": "WLO",
        "WIN | Winton Airport": "WIN",
        "WUD | Wudinna Airport": "WUD",
        "WEW | Wee Waa Airport": "WEW",
        "WRW | Warrawagine Airport": "WRW",
        "WWI | Woodie Woodie Airport": "WWI",
        "WWY | West Wyalong Airport": "WWY",
        "WYN | Wyndham Airport": "WYN",
        "BWT | Wynyard Airport": "BWT",
        "YYA | Yueyang Sanhe Airport": "YYA",
        "YLG | Yalgoo Airport": "YLG",
        "OKR | Yorke Island Airport": "OKR",
        "KYF | Yeelirrie Airport": "KYF",
        "XMY | Yam Island Airport": "XMY",
        "YUE | Yuendumu Airport": "YUE",
        "NGA | Young Airport": "NGA",
        "ORR | Yorketown Airport": "ORR",
        "KYI | Yalata Mission Airport": "KYI",
        "KKI | Akiachak Airport": "KKI",
        "BCC | Bear Creek 3 Airport": "BCC",
        "JBT | Bethel Seaplane Base": "JBT",
        "CZP | Cape Pole Seaplane Base": "CZP",
        "KBW | Chignik Bay Seaplane Base": "KBW",
        "KBC | Birch Creek Airport": "KBC",
        "CZC | Copper Center 2 Airport": "CZC",
        "ULX | Ulusaba Airport": "ULX",
        "TDT | Tanda Tula Airport": "TDT",
        "HZV | Hazyview Airport": "HZV",
        "KHO | Khoka Moya Airport": "KHO",
        "MBM | Mkambati Airport": "MBM",
        "INY | Inyati Airport": "INY",
        "TSD | Tshipise Airport": "TSD",
        "SSX | Singita Safari Lodge Airport": "SSX",
        "KIG | Koingnaas Airport": "KIG",
        "PEK | Beijing Capital International Airport": "PEK",
        "PKX | Beijing Daxing International Airport": "PKX",
        "CDE | Chengde Puning Airport": "CDE",
        "CIF | Chifeng Airport": "CIF",
        "CIH | Changzhi Airport": "CIH",
        "BPE | Qinhuangdao Beidaihe Airport": "BPE",
        "DSN | Ordos Ejin Horo Airport": "DSN",
        "DAT | Datong Yungang Airport": "DAT",
        "ERL | Erenhot Saiwusu International Airport": "ERL",
        "YIE | Arxan Yi'ershi Airport": "YIE",
        "HDG | Handan Airport": "HDG",
        "HET | Baita International Airport": "HET",
        "HUO | Holingol Huolinhe Airport": "HUO",
        "HLD | Hulunbuir Hailar Airport": "HLD",
        "LFQ | Linfen Qiaoli Airport": "LFQ",
        "LLV | Lüliang Airport": "LLV",
        "NZH | Manzhouli Xijiao Airport": "NZH",
        "BAV | Baotou Airport": "BAV",
        "SJW | Shijiazhuang Zhengding International Airport": "SJW",
        "TSN | Tianjin Binhai International Airport": "TSN",
        "TGO | Tongliao Airport": "TGO",
        "UCB | Ulanqab Jining Airport": "UCB",
        "WUA | Wuhai Airport": "WUA",
        "HLH | Ulanhot Airport": "HLH",
        "XIL | Xilinhot Airport": "XIL",
        "XNT | Xingtai Dalian Airport": "XNT",
        "WUT | Xinzhou Wutaishan Airport": "WUT",
        "YCU | Yuncheng Guangong Airport": "YCU",
        "TYN | Taiyuan Wusu Airport": "TYN",
        "RLK | Bayannur Tianjitai Airport": "RLK",
        "NZL | Chengjisihan Airport": "NZL",
        "ZDY | Delma Airport": "ZDY",
        "ZEN | Zenag Airport": "ZEN",
        "BHY | Beihai Fucheng Airport": "BHY",
        "CGD | Changde Taohuayuan Airport": "CGD",
        "HJJ | Huaihua Zhijiang Airport": "HJJ",
        "DYG | Zhangjiajie Hehua International Airport": "DYG",
        "CAN | Guangzhou Baiyun International Airport": "CAN",
        "CSX | Changsha Huanghua International Airport": "CSX",
        "HCJ | Hechi Jinchengjiang Airport": "HCJ",
        "SHF | Huayuan Airport": "SHF",
        "HNY | Hengyang Nanyue Airport": "HNY",
        "KWL | Guilin Liangjiang International Airport": "KWL",
        "LLF | Yongzhou Lingling Airport": "LLF",
        "MXZ | Meizhou Meixian Changgangji International Airport": "MXZ",
        "NNG | Nanning Wuxu Airport": "NNG",
        "SWA | Jieyang Chaoshan International Airport": "SWA",
        "ZUH | Zhuhai Jinwan Airport": "ZUH",
        "WGN | Shaoyang Wugang Airport": "WGN",
        "SZX | Shenzhen Bao'an International Airport": "SZX",
        "XIN | Xingning Air Base": "XIN",
        "YLX | Yulin Fumian Airport": "YLX",
        "LZH | Liuzhou Bailian Airport / Bailian Air Base": "LZH",
        "ZHA | Zhanjiang Xintang Airport": "ZHA",
        "AYN | Anyang Airport": "AYN",
        "CGO | Zhengzhou Xinzheng International Airport": "CGO",
        "ENH | Enshi Airport": "ENH",
        "LHK | Guangzhou MR Air Base / Guanghua Airport": "LHK",
        "WUH | Wuhan Tianhe International Airport": "WUH",
        "SHS | Jingzhou Shashi Airport": "SHS",
        "LYA | Luoyang Airport": "LYA",
        "NNY | Nanyang Jiangying Airport": "NNY",
        "HPG | Shennongjia Hongping Airport": "HPG",
        "WDS | Shiyan Wudangshan Airport": "WDS",
        "XFN | Xiangyang Liuji Airport": "XFN",
        "YIH | Yichang Sanxia Airport": "YIH",
        "ZHH | Herschel Island Field": "ZHH",
        "HAK | Haikou Meilan International Airport": "HAK",
        "BAR | Qionghai Bo'ao Airport": "BAR",
        "SYX | Sanya Phoenix International Airport": "SYX",
        "FNJ | Pyongyang Sunan International Airport": "FNJ",
        "DSO | Sondok Airport": "DSO",
        "WOS | Wonsan Kalma International Airport": "WOS",
        "AKA | Ankang Fuqiang Airport": "AKA",
        "DNH | Dunhuang Airport": "DNH",
        "HXD | Delingha Airport": "HXD",
        "GOQ | Golmud Airport": "GOQ",
        "GYU | Guyuan Liupanshan Airport": "GYU",
        "HTT | Huatugou Airport": "HTT",
        "HZG | Hanzhong Chenggu Airport": "HZG",
        "INC | Yinchuan Hedong International Airport": "INC",
        "JNG | Jining Qufu Airport": "JNG",
        "JGN | Jiayuguan Airport": "JGN",
        "LHW | Lanzhou Zhongchuan Airport": "LHW",
        "LNL | Longnan Chengzhou Airport": "LNL",
        "IQN | Qingyang Xifeng Airport": "IQN",
        "SIA | Xi'an Xiguan Airport": "SIA",
        "GXH | Gannan Xiahe Airport": "GXH",
        "XNN | Xining Caojiabu Airport": "XNN",
        "XIY | Xi'an Xianyang International Airport": "XIY",
        "UYN | Yulin Yuyang Airport": "UYN",
        "ZHY | Zhongwei Shapotou Airport": "ZHY",
        "AVK | Arvaikheer Airport": "AVK",
        "LTI | Altai Airport": "LTI",
        "BYN | Bayankhongor Airport": "BYN",
        "UGA | Bulgan Airport": "UGA",
        "UGT | Bulagtai Resort Airport": "UGT",
        "HBU | Bulgan Sum Airport": "HBU",
        "UUN | Baruun Urt Airport": "UUN",
        "COQ | Choibalsan Airport": "COQ",
        "UBN | Ulaanbaatar Chinggis Khaan International Airport": "UBN",
        "ZMD | Sena Madureira Airport": "ZMD",
        "ULZ | Donoi Airport": "ULZ",
        "DLZ | Dalanzadgad Airport": "DLZ",
        "KHR | Kharkhorin Airport": "KHR",
        "HJT | Khujirt Airport": "HJT",
        "HVD | Khovd Airport": "HVD",
        "MXV | Mörön Airport": "MXV",
        "TSZ | Tsetserleg Airport": "TSZ",
        "TNZ | Tosontsengel Airport": "TNZ",
        "ULN | Buyant-Ukhaa International Airport": "ULN",
        "ULO | Ulaangom Airport": "ULO",
        "ULG | Ölgii Mongolei Airport": "ULG",
        "ZNC | Nyac Airport": "ZNC",
        "ZNU | Namu Water Aerodrome": "ZNU",
        "CWJ | Cangyuan Washan Airport": "CWJ",
        "DLU | Dali Huangcaoba Airport": "DLU",
        "DIG | Diqing Shangri-La Airport": "DIG",
        "JHG | Xishuangbanna Gasa Airport": "JHG",
        "JMJ | Lancang Jingmai Airport": "JMJ",
        "LJG | Lijiang Sanyi International Airport": "LJG",
        "LUM | Dehong Mangshi Airport": "LUM",
        "KMG | Kunming Changshui International Airport": "KMG",
        "SYM | Pu'er Simao Airport": "SYM",
        "WNH | Wenshan Puzhehei Airport": "WNH",
        "ZAT | Zhaotong Airport": "ZAT",
        "XMN | Xiamen Gaoqi International Airport": "XMN",
        "AQG | Anqing Tianzhushan Airport / Anqing North Air Base": "AQG",
        "BFU | Bengbu Airport": "BFU",
        "CZX | Changzhou Benniu Airport": "CZX",
        "KHN | Nanchang Changbei International Airport": "KHN",
        "FUG | Fuyang Xiguan Airport": "FUG",
        "FOC | Fuzhou Changle International Airport": "FOC",
        "KOW | Ganzhou Huangjin Airport": "KOW",
        "HGH | Hangzhou Xiaoshan International Airport": "HGH",
        "JDZ | Jingdezhen Airport": "JDZ",
        "JIU | Jiujiang Lushan Airport": "JIU",
        "TNA | Jinan Yaoqiang International Airport": "TNA",
        "JUZ | Quzhou Airport": "JUZ",
        "LCX | Longyan Guanzhishan Airport": "LCX",
        "LYG | Lianyungang Baitabu Airport / Baitabu Air Base": "LYG",
        "HYN | Taizhou Luqiao Airport": "HYN",
        "LYI | Linyi Qiyang Airport": "LYI",
        "NGB | Ningbo Lishe International Airport": "NGB",
        "NKG | Nanjing Lukou International Airport": "NKG",
        "HFE | Hefei Xinqiao International Airport": "HFE",
        "PVG | Shanghai Pudong International Airport": "PVG",
        "JJN | Quanzhou Jinjiang International Airport": "JJN",
        "RUG | Rugao Air Base": "RUG",
        "RIZ | Rizhao Shanzihe Airport": "RIZ",
        "SHA | Shanghai Hongqiao International Airport": "SHA",
        "SZV | Suzhou Guangfu Airport": "SZV",
        "TXN | Tunxi International Airport": "TXN",
        "WEF | Weifang Nanyuan Airport": "WEF",
        "WEH | Weihai Dashuibo Airport": "WEH",
        "WHU | Wuhu Wanli Airport / Wuhu Air Base": "WHU",
        "WUX | Sunan Shuofang International Airport": "WUX",
        "WUS | Nanping Wuyishan Airport": "WUS",
        "WNZ | Wenzhou Longwan International Airport": "WNZ",
        "XUZ | Xuzhou Guanyin Airport / Xuzhou Daguozhang Air Base": "XUZ",
        "YTY | Yangzhou Taizhou Airport": "YTY",
        "YIC | Yichun Mingyueshan Airport": "YIC",
        "YNZ | Yancheng Airport": "YNZ",
        "YIW | Yiwu Airport": "YIW",
        "HSN | Zhoushan Airport": "HSN",
        "NGQ | Ngari Gunsa Airport": "NGQ",
        "AVA | Anshun Huangguoshu Airport": "AVA",
        "BPX | Qamdo Bangda Airport": "BPX",
        "BFJ | Bijie Feixiong Airport": "BFJ",
        "CKG | Chongqing Jiangbei International Airport": "CKG",
        "DCY | Daocheng Yading Airport": "DCY",
        "DAX | Dachuan Airport": "DAX",
        "GHN | Guanghan Airport": "GHN",
        "GYS | Guangyuan Panlong Airport": "GYS",
        "KWE | Longdongbao Airport": "KWE",
        "GZG | Garze Gesar Airport": "GZG",
        "JZH | Jiuzhai Huanglong Airport": "JZH",
        "KGT | Kangding Airport": "KGT",
        "KJH | Kaili Airport": "KJH",
        "LLB | Libo Airport": "LLB",
        "LIA | Liangping Airport": "LIA",
        "LXA | Lhasa Gonggar Airport": "LXA",
        "UNR | Öndörkhaan Airport": "UNR",
        "WMT | Zunyi Maotai Airport": "WMT",
        "MIG | Mianyang Nanjiao Airport": "MIG",
        "NAO | Nanchong Gaoping Airport": "NAO",
        "HZH | Liping Airport": "HZH",
        "LZY | Nyingchi Airport": "LZY",
        "LPF | Liupanshui Yuezhao Airport": "LPF",
        "TCZ | Tengchong Tuofeng Airport": "TCZ",
        "TFU | Chengdu Tianfu International Airport": "TFU",
        "TEN | Tongren Fenghuang Airport": "TEN",
        "CTU | Chengdu Shuangliu International Airport": "CTU",
        "WSK | Chongqing Wushan Airport": "WSK",
        "WXN | Wanxian Airport": "WXN",
        "XIC | Xichang Qingshan Airport": "XIC",
        "YBP | Yibin Caiba Airport": "YBP",
        "ACX | Xingyi Wanfenglin Airport": "ACX",
        "ZYI | Zunyi Xinzhou Airport": "ZYI",
        "AKU | Aksu Onsu Airport": "AKU",
        "BPL | Bole Alashankou Airport": "BPL",
        "IQM | Qiemo Yudu Airport": "IQM",
        "FYN | Fuyun Koktokay Airport": "FYN",
        "HMI | Hami Airport": "HMI",
        "KCA | Kuqa Qiuci Airport": "KCA",
        "KRL | Korla Airport": "KRL",
        "KRY | Karamay Airport": "KRY",
        "KJI | Kanas Airport": "KJI",
        "NLT | Xinyuan Nalati Airport": "NLT",
        "RQA | Ruoqiang Loulan Airport": "RQA",
        "QSZ | Shache Yeerqiang Airport": "QSZ",
        "KHG | Kashgar Airport": "KHG",
        "SXJ | Shanshan Airport": "SXJ",
        "TCG | Tacheng Airport": "TCG",
        "HTN | Hotan Airport": "HTN",
        "TLQ | Turpan Jiaohe Airport": "TLQ",
        "URC | Ürümqi Diwopu International Airport": "URC",
        "YIN | Yining Airport": "YIN",
        "YTW | Yutian Wanfang Airport": "YTW",
        "AOG | Anshan Teng'ao Airport / Anshan Air Base": "AOG",
        "CGQ | Longjia Airport": "CGQ",
        "CNI | Changhai Airport": "CNI",
        "CHG | Chaoyang Airport": "CHG",
        "DTU | Wudalianchi Dedu Airport": "DTU",
        "FYJ | Fuyuan Dongji Aiport": "FYJ",
        "HRB | Harbin Taiping International Airport": "HRB",
        "HEK | Heihe Aihui Airport": "HEK",
        "JIL | Jilin Ertaizi Airport": "JIL",
        "JMU | Jiamusi Airport": "JMU",
        "JSJ | Jiansanjiang Shidi Airport": "JSJ",
        "JXA | Jixi Xingkaihu Airport": "JXA",
        "LDS | Lindu Airport": "LDS",
        "YUS | Yushu Batang Airport": "YUS",
        "MDG | Mudanjiang Hailang International Airport": "MDG",
        "OHE | Gu-Lian Airport": "OHE",
        "NDG | Qiqihar Sanjiazi Airport": "NDG",
        "YSQ | Songyuan Chaganhu Airport": "YSQ",
        "DLC | Dalian Zhoushuizi International Airport": "DLC",
        "TNH | Tonghua Sanyuanpu Airport": "TNH",
        "SHE | Shenyang Taoxian International Airport": "SHE",
        "YNJ | Yanji Chaoyangchuan Airport": "YNJ",
        "YKH | Yingkou Lanqi Airport": "YKH"
    };

    return airports[iso];
}

const departures = ref([] as Array<string>)
const arrivals = ref([] as Array<string>)

const countryArrivals = ref([] as Array<string>)
const countryDepartures = ref([] as Array<string>)

const emit = defineEmits<{
    (e: 'step', id: number): void
    (e: 'update:routes', routes: Array<route>): void
    (e: 'update:targetRoutes', routes: Array<targetRoute>): void
}>()

const gotoPage = function (step: number) {
    if (step > 2) {
        if (props.targetRoutes.length < 1) {
            showError.value = true
            return
        }

        showError.value = false

        emit('step', step)
    } else {
        emit('step', step)
    }
}

onMounted(async () => {
    await routeStore.fetchTargets(props.campaignStartDate, props.campaignEndDate)

    if (targets.value != undefined) {
        departures.value = targets.value!.departures.map(c => isoToAirport(c));
        arrivals.value = targets.value!.arrivals.map(c => isoToAirport(c));

        countryArrivals.value = targets.value!.countries.arrivals.map(c => isoToCountry(c));
        countryDepartures.value = targets.value!.countries.departures.map(c => isoToCountry(c));
    }

    departures.value.sort(function (a, b) {
        var textA = a.toUpperCase();
        var textB = b.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    arrivals.value.sort(function (a, b) {
        var textA = a.toUpperCase();
        var textB = b.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
})

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>