<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800">All Offers <span class="text-slate-400 font-medium">{{ offerStore.offers.length }}</span></h2>
    </header>
    <div>

      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full divide-y divide-slate-200">
          <!-- Table header -->
          <thead class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
            <tr>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-left">Name</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-left">Advertiser</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-left">Campaign Period</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-left">Created At</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-left">Actions</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <OfferTableItem
            @delete="deleteItemModal"
            v-for="offer in offerStore.offers"
            :key="offer.id"
            :offer="offer"
            :value="offer.id"
          />
        </table>

      </div>
    </div>
  </div>

  <ModalBlank id="danger-modal" :modalOpen="deleteModalOpen" @close-modal="deleteModalOpen = false">
    <div class="p-5 flex space-x-4" v-if="deleteOfferItem != undefined">
      <!-- Icon -->
      <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
        <svg class="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
        </svg>
      </div>
      <!-- Content -->
      <div>
        <!-- Modal header -->
        <div class="mb-2">
          <div class="text-lg font-semibold text-slate-800">Delete offer {{ deleteOfferItem.name }}?</div>
        </div>
        <!-- Modal content -->
        <div class="text-sm mb-10">
          <div class="space-y-2">
            <p>Do you want to delete the offer?</p>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex flex-wrap justify-end space-x-2">
          <button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" @click.stop="deleteModalOpen = false">Cancel</button>
          <button class="btn-sm bg-rose-500 hover:bg-rose-600 text-white" @click.stop="deleteOffer()">Yes, Delete it</button>
        </div>
      </div>
    </div>
  </ModalBlank>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useOfferStore } from '@/stores/offer'
import type { offer } from '@/stores/offer'
import OfferTableItem from './OffersTableItem.vue'

import ModalBlank from '../../components/ModalBlank.vue'

const offerStore = useOfferStore();
const deleteModalOpen = ref(false)
const deleteOfferItem = ref(undefined as offer | undefined)

const deleteItemModal = (offer: offer) => {
  deleteOfferItem.value = offer;
  deleteModalOpen.value = true;
}

const deleteOffer = async () => {
  deleteModalOpen.value = false;
  if (deleteOfferItem.value != undefined && deleteOfferItem.value.id != undefined)
  {
    await offerStore.deleteOffer(deleteOfferItem.value.id)
    await offerStore.fetchOffers(true)
  }
}

onMounted(() => offerStore.fetchOffers(true))


</script>